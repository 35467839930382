import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Footer } from "../../../component/frontend/footer/footer";
import { Header } from "../../../component/frontend/header/header";
import { Loader } from "../../../component/frontend/loader/loader";
// import Swal from "sweetalert2";
import { getUserDetails } from "../../../../storage/user";
import { EmailHeader } from "../../../component/frontend/email/EmailHeader";
// import { errorNotification } from "../../../../common/notification-alert";
// import { User } from "../../../../storage";
import { getUserSocialDetails } from "../../../../routing/authService";
import { usePrevious, setImagePath } from "../../../../common/custom";
import { SubscriptionPlan } from "../profile/subscriptionPlans";
import { useDispatch, useSelector } from "react-redux";
import { getGmailAuthLink, getGmailValidToken, saveSocialAuthToken } from "../../../../duck/email/google/google.action";
import { isTokenExpired } from "../../../../common/constants";
import ERROR_ALERT_ICON from '../../../../assets/images/alert-icn.svg'
import IC_CHECK_ICON from '../../../../assets/images/ic_check-blue.svg'
import GOOGLE_LOGIN_ICON from '../../../../assets/images/google_login_icn.png';
import _ from 'lodash';
import { getOutlookAuthLink } from "../../../../duck/email/microsoft/microsoft.action";
import { EMAIL_LIST, LIST_BOOKINGS } from "../../../../routing/routeContants";
import { resetAccordionState } from "../../../../duck/email/email.action";
import BETA_INDICATOR from "../../../../assets/images/beta_indicator.png";
import { isFrombookingList } from "../../../../duck/booking/booking.action";

export const EmailAuthPage = (props) => {
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const userSocialData = getUserSocialDetails();
  const currentPlan = userData && userData.planData;
  const [state, setState] = useState({ emailType: "gmail" });
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showTokenExpiredError, setShowTokenExpiredError] = useState(false);
  const getGmailAuthLinkData = useSelector((state) => state.google.getGmailAuthLinkData);
  const prevGetGmailAuthLinkData = usePrevious({ getGmailAuthLinkData });
  const getGmailTokenData = useSelector((state) => state.google.getGmailTokenData);
  const prevGetGmailTokenData = usePrevious({ getGmailTokenData });
  const getOutlookAuthLinkData = useSelector((state) => state.microsoft.getOutlookAuthLinkData);
  const prevGetOutlookAuthLinkData = usePrevious({ getOutlookAuthLinkData });
  const isFrombooking = useSelector(state => state.booking.isFrombookingList);

  // Create Function
  // const createFunction = (e, path) => {
  //   e.preventDefault();
  //   // Add Free trial expire then working is blocked
  //   //if(currentPlan && currentPlan.plan_is_active === 0){
  //   // Free trial expire then working is fine
  //   if (
  //     currentPlan &&
  //     currentPlan.plan_is_active === 0 &&
  //     (path === "/user/add-quote" || path === "/user/add-invoice/")
  //   ) {
  //     let buttonMsg = currentPlan.subscription_product_id === 1 ? "View Plans" : "Renew Plan";
  //     let warMsg = currentPlan.subscription_product_id === 1 ? "Free Trial Expired" : "Subscription Expired";
  //     let msg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Your free trial has expired. Please subscribe to a plan to access the application. "
  //         : "Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ";
  //     Swal.fire({
  //       title: warMsg,
  //       html: msg,
  //       showCancelButton: true,
  //       confirmButtonText: buttonMsg,
  //       cancelButtonText: "Close",
  //       reverseButtons: true,
  //       showCloseButton: true,
  //       customClass: "mycustom-alert",
  //       cancelButtonClass: "cancel-alert-note",
  //     }).then((result) => {
  //       if (result.value) {
  //         setSubscriptionModalShow(true);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       }
  //     });
  //   } else {
  //     props.history.push(path);
  //   }
  // };

  useEffect(() => {
    if (prevGetGmailAuthLinkData && prevGetGmailAuthLinkData.getGmailAuthLinkData !== getGmailAuthLinkData) {
      if (getGmailAuthLinkData && _.has(getGmailAuthLinkData, "url") && getGmailAuthLinkData.url !== "") {
        window.location.replace(getGmailAuthLinkData.url);
      }
    }
  }, [prevGetGmailAuthLinkData, getGmailAuthLinkData])

  useEffect(() => {
    if (prevGetOutlookAuthLinkData && prevGetOutlookAuthLinkData.getOutlookAuthLinkData !== getOutlookAuthLinkData) {
      if (getOutlookAuthLinkData && _.has(getOutlookAuthLinkData, "url") && getOutlookAuthLinkData.url !== "") {
        window.location.replace(getOutlookAuthLinkData.url);
      }
    }
  }, [prevGetOutlookAuthLinkData, getOutlookAuthLinkData])

  useEffect(() => {
    if (getOutlookAuthLinkData && getOutlookAuthLinkData.url)
      window.location.replace(getOutlookAuthLinkData.url);
  }, [props, getOutlookAuthLinkData]);

  // useCallback(() => {
  //   if (
  //     userSocialData &&
  //     _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
  //     && isTokenExpired(userSocialData?.google?.expire_in)
  //   ) {
  //     const refreshToken = userSocialData?.google?.refresh_token
  //     dispatch(getGmailValidToken(refreshToken));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useCallback(() => {
    if (prevGetGmailTokenData && prevGetGmailTokenData.getGmailTokenData !== getGmailTokenData) {
      if ((getGmailTokenData && Object.keys(getGmailTokenData).length > 0) || (userSocialData &&
        _.has(userSocialData, "google") &&
        Object.keys(userSocialData.google).length > 0 &&
        userSocialData.google.access_token)) {
        const updateGmailAuthTokenData = {
          loginType: 1, authToken: getGmailTokenData?.access_token, refreshToken: getGmailTokenData?.refresh_token, expiresIn: getGmailTokenData?.expire_in,
          social_email: getGmailTokenData?.social_email,
          social_user_id: getGmailTokenData?.social_user_id,
          social_user_name: getGmailTokenData?.social_user_name,
        }
        const updateLocalTokenData = {
          loginType: 1, authToken: userSocialData?.google?.access_token, refreshToken: userSocialData?.google?.refresh_token, expiresIn: userSocialData?.google?.expire_in,
          social_email: userSocialData?.google?.social_email,
          social_user_id: userSocialData?.google?.social_user_id,
          social_user_name: userSocialData?.google?.social_user_name,
        }
        const updatedData = getGmailTokenData && Object.keys(getGmailTokenData).length > 0 ? updateGmailAuthTokenData : updateLocalTokenData;
        dispatch(saveSocialAuthToken(updatedData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prevGetGmailTokenData, getGmailTokenData])

  useEffect(() => {
    if ((userSocialData && _.has(userSocialData, "google") && _.has(userSocialData.google, "expire_in") && !isTokenExpired(userSocialData.google.expire_in)) && isFrombooking) {
      setShowTokenExpiredError(false)
      dispatch(isFrombookingList(false))
      props.history.push(LIST_BOOKINGS)
  }else if ((userSocialData && _.has(userSocialData, "google") && _.has(userSocialData.google, "expire_in") && !isTokenExpired(userSocialData.google.expire_in))) {
      setShowTokenExpiredError(false)
      props.history.push(EMAIL_LIST);
    } else if ((userSocialData && _.has(userSocialData, "google") && _.has(userSocialData.google, "expire_in") && isTokenExpired(userSocialData.google.expire_in))) {
      setShowTokenExpiredError(true);
      const refreshToken = userSocialData?.google?.refresh_token
      dispatch(getGmailValidToken(refreshToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSocialData])

  const handleEmailButton = () => {
    dispatch(resetAccordionState());
    if (state.emailType === "gmail")
      dispatch(getGmailAuthLink());
    else
      dispatch(getOutlookAuthLink());
  };

  const handleMicrosoftButton = () => {
    dispatch(resetAccordionState());
    if (state.emailType === "microsoft")
      dispatch(getOutlookAuthLink());
    else
      dispatch(getGmailAuthLink());
  }

  return (
    <>
      <Loader loader={loader} />
      <div className="main-site fixed--header fixed-email-header">
        <Header getMainRoute={"email"} />
        <main className="site-body">
          {/* <EmailHeader createFunction={createFunction} /> */}
          <EmailHeader noBack={true} />
          <section className="middle-section">
            <div className="container">
              <div className="row no-gutters-mbl">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="col-md-7 mx-auto">
                        <div className="empty-leads text-left">
                          <p className="w-100 mb-2 font-weight-bold"><img className="mr-2" src={setImagePath(IC_CHECK_ICON)} alt="icon" />Access your business email Inbox & Calendar</p>
                          <p className="w-100 mb-4 font-weight-bold"><img className="mr-2" src={setImagePath(IC_CHECK_ICON)} alt="icon" />Create Leads or Contacts from email details with one click.  </p>
                          {/* <p className="w-100 small-text" style={{ maxWidth: '100%' }}>
                            Learn how to integrate your <a href="#!">Gmail</a> or <a href="#!">Microsoft</a> email &
                            calendar with MyBizzHive. When ready select your email provider and follow instructions
                          </p> */}
                          <p className="w-100 small-text" style={{ maxWidth: '100%' }}>Please select your email provider and follow instructions to connect your inbox.</p>
                          <div className="d-flex align-items-center flex-wrap position-relative mb-4">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                name="emailType"
                                id="gmail"
                                value="gmail"
                                onChange={() => setState({ ...state, emailType: "gmail" })}
                                checked={state.emailType === "gmail" ? true : false}
                              />
                              <label className="custom-control-label m-0 font-weight-normal" htmlFor="gmail">
                                Gmail
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                className="custom-control-input"
                                type="radio"
                                name="emailType"
                                id="microsoft"
                                value="microsoft"
                                onChange={() => setState({ ...state, emailType: "microsoft" })}
                                checked={state.emailType === "microsoft" ? true : false}
                                disabled
                              />
                              <label className="custom-control-label m-0 font-weight-normal" htmlFor="microsoft">
                                Microsoft (Coming Soon)
                              </label>
                            </div>
                          </div>
                          {state.emailType === "gmail" ? (
                            <>
                              <p className="w-100 small-text mt-4 mb-0" style={{ maxWidth: '100%', fontSize: '12px' }}>
                                Disclosure: MyBizzHive's use and transfer to any other app of information received from Google APIs will
                                adhere to <a className="underline-text" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the <a className="underline-text" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Limited Use requirements</a>.
                              </p>
                              <button
                                className="google-login-btn my-4"
                                onClick={handleEmailButton}
                              >
                                <img src={setImagePath(GOOGLE_LOGIN_ICON)} alt="icon" />
                              </button>
                              {showTokenExpiredError ? <div className="mb-2 w-100"><img className="mb-1 mr-2" src={setImagePath(ERROR_ALERT_ICON)} alt="" /><strong>{"Note: "}</strong>{"Please re-connect your email if the Gmail token has expired. "}<a className="token-error-anchor" target="blank" href="https://www.mybizzhive.com/help/gmail_integration.html">{"What's Gmail token?"}</a></div> : ''}
                            </>
                          ) : (
                            <button
                              className="btn btn-primary my-4"
                              onClick={handleMicrosoftButton}
                            >
                              Sign in with Microsoft
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        {/* Subscription Modal*/}
        <SubscriptionPlan
          loader={(data) => setLoader(data)}
          openSubscriptionModal={subscriptionModalShow}
          closeSubscriptionModal={() => setSubscriptionModalShow(false)}
          updatePlanDetail={(data) => {
            setSubscriptionModalShow(false);
            setLoader(false);
          }}
          currentPlan={currentPlan}
        />
      </div>
    </>
  );
};

export const EmailAuth = withRouter(EmailAuthPage);
