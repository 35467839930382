import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import { fieldValidator, usePrevious, setImagePath } from '../../../../common/custom';
import { selectStyle, constants, CustomValueContainer } from '../../../../common/constants';
import { getContactListOptionValue } from '../../../../../api/sdk/contact';
import { getAssociateLeadListOptionValue } from '../../../../../api/sdk/lead';
import { validateInputs } from '../../../../common/validation';
import CALENDAR from "../../../../assets/images/calendar.png"
import AsyncSelect from 'react-select/async';
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import Select from 'react-select';
import _ from 'lodash';
import moment from 'moment'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { Editor } from '@tinymce/tinymce-react';
import { tinyConfigEmailContent } from "../../../../common/constants";

import { updateMessageBodyQuote,getMessageBodyQuote } from "../../../../duck/quote/quote.action";

export const SaveUserMessageBody = props => {

    const { saveMessageModalShow, setSaveMessageModalShow ,message,type, setLoader } = props
    const getQuoteMessageData = useSelector((state) => state.quote.messageBodyData)
    const prevQuoteMessageData = usePrevious({ getQuoteMessageData });
    const dispatch = useDispatch();

    const [editorData, setEditorData] = useState(message);
    
    // useEffect(()=>{
    //     dispatch(getMessageBodyQuote({ message_type: 'quote'}))
    // },[]);
    
    useEffect(()=>{ 
        if (prevQuoteMessageData && prevQuoteMessageData.getQuoteMessageData !== getQuoteMessageData) {
            if (getQuoteMessageData && _.has(getQuoteMessageData, 'data') && getQuoteMessageData.success === true) {
                console.log('getQuoteMessageData')
                console.log(getQuoteMessageData)
                if(getQuoteMessageData.data.message_text!=='')
                    setEditorData(getQuoteMessageData.data.message_text)
                else
                    setEditorData(message)
                setLoader(false)
                setSaveMessageModalShow(false)
                //props.history.push(LIST_QUOTES)
            }
            if (getQuoteMessageData && _.has(getQuoteMessageData, 'message') && getQuoteMessageData.success === false) {
                setLoader(false)
                console.log(getQuoteMessageData.message)
                //if (getUserMessageData.message === 'Please add organization first.') {
                   // setBusinessProfileModalShow(true)
               // } else {
                  //  setServiceMessage(sendToCustomerQuoteData.message)
                //}
            }
        }         
    },[getQuoteMessageData,prevQuoteMessageData]);
    
    const updateUserMessage = ()=>{ console.log(editorData) 
        console.log(getQuoteMessageData)
        dispatch(updateMessageBodyQuote({ message_type: type ,message_text: editorData }))
    }
   
    return (
<Modal show={saveMessageModalShow} onHide={() => {
                setSaveMessageModalShow(false)}} className="appoitment-form" size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                    Customize Default Message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Type the text that you would like to use as a default message. You can always make necessary edits before sending it to customer.</p>
                       <form autoComplete="off">
                        <div className="form-group col-md-12 p-0">
                            <div className="floating-label">
                                <Editor
                                    value={message !== '' ? message : ''}
                                    apiKey={constants.tinyAapiKey}
                                    init={tinyConfigEmailContent}
                                    onEditorChange={(data) => setEditorData(data)}
                                />                                    
                                <label>More information</label>
                            </div>
                        </div>                     
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-dark" onClick={() => setSaveMessageModalShow(false)}>Cancel</button>
                    <button type="button" onClick={() => updateUserMessage()} className="btn btn-primary">Save</button>
                </Modal.Footer>
            </Modal>
    )
}


export const SaveUserMessageBodyModel = withRouter(SaveUserMessageBody);