import {
    SUCCESS_GET_CALENDAR_LIST_DATA,
    ERROR_GET_CALENDAR_LIST_DATA,
    GET_CALENDAR_LIST_DATA,
    GET_DELETE_CALENDAR_LIST_DATA,
    GET_DELETE_BATCH_CALENDAR_LIST_DATA,
    ERROR_DELETE_GET_CALENDAR_LIST_DATA,
    SUCCESS_DELETE_GET_CALENDAR_LIST_DATA,
    EDIT_CALENDAR_LIST_DATA,
    SUCCESS_EDIT_CALENDAR_LIST_DATA,
    ERROR_EDIT_CALENDAR_LIST_DATA,
    RESET_CALENDAR_STATE,
    GET_MICROSOFT_CALENDAR_LIST_DATA,
    SUCCESS_GET_MICROSOFT_CALENDAR_LIST_DATA,
    ERROR_GET_MICROSOFT_CALENDAR_LIST_DATA,
    DELETE_MICROSOFT_EVENT,
    SUCCESS_DELETE_MICROSOFT_EVENT,
    ERROR_DELETE_MICROSOFT_EVENT,
    EDIT_MICROSOFT_CALENDAR_LIST_DATA,
    SUCCESS_MICROSOFT_EDIT_CALENDAR_LIST_DATA,
    ERROR_MICROSOFT_EDIT_CALENDAR_LIST_DATA,
    SUCCESS_DELETE_BATCH_GET_CALENDAR_LIST_DATA,
    ERROR_DELETE_BATCH_GET_CALENDAR_LIST_DATA
} from "./calendar.action";
import { DEFAULT_STATE } from "./calendar.state";


export const calendarReducer = (
    state = DEFAULT_STATE,
    action = {
        type: {},
        data: {},
    }
) => {
    switch (action.type) {
        case GET_CALENDAR_LIST_DATA:
            return { ...state, loading: true };
        case SUCCESS_GET_CALENDAR_LIST_DATA:
            const getCalendarEventListData = action.data;
            return {
                ...state,
                loading: false,
                getCalendarEventListData
            };
        case ERROR_GET_CALENDAR_LIST_DATA:
            const errorgetCalendarEventListData = action.data;
            return {
                ...state,
                loading: false,
                getCalendarEventListData: {},
                tokenErrorData: errorgetCalendarEventListData &&
                    Object.keys(errorgetCalendarEventListData).length > 0
                    ? errorgetCalendarEventListData.error
                    : {},
            };
        case GET_DELETE_CALENDAR_LIST_DATA:
            const getDeleteCalendarEventListId = action.data;
            return {
                ...state,
                loading: true,
                getDeleteCalendarEventListData: getDeleteCalendarEventListId,
            };
        case GET_DELETE_BATCH_CALENDAR_LIST_DATA:
            const getDeleteBatchCalendarEventListId = action.data;
            return {
                ...state,
                loading: true,
                getDeleteBatchCalendarEventListData: getDeleteBatchCalendarEventListId,
            };
        case SUCCESS_DELETE_BATCH_GET_CALENDAR_LIST_DATA:
            let getDeleteBatchCalendarEventListData = state.getDeleteBatchCalendarEventListData;
            getDeleteBatchCalendarEventListData = { ...getDeleteBatchCalendarEventListData, success: 'complete' };
            let eventListStateData = { ...state.getCalendarEventListData };
            let eventListDataBatch = state.getCalendarEventListData && Object.keys(state.getCalendarEventListData).length > 0 ? [...state.getCalendarEventListData?.items] : [];
            eventListDataBatch = eventListDataBatch.filter((data) => { return data.id !== getDeleteBatchCalendarEventListData.eventId })
            eventListStateData = { ...eventListStateData, items: eventListDataBatch }
            return {
                ...state,
                loading: false,
                getDeleteBatchCalendarEventListData,
                getCalendarEventListData: eventListStateData
            };
        case ERROR_DELETE_BATCH_GET_CALENDAR_LIST_DATA:
            const erroBatchrDeleteCalendarEventListData = action.data;
            return {
                ...state,
                loading: false,
                getDeleteBatchCalendarEventListData: {},
                tokenErrorData: erroBatchrDeleteCalendarEventListData &&
                    Object.keys(erroBatchrDeleteCalendarEventListData).length > 0
                    ? erroBatchrDeleteCalendarEventListData.error
                    : {},
            };
        
        case SUCCESS_DELETE_GET_CALENDAR_LIST_DATA:
            let getDeleteCalendarEventListData = state.getDeleteCalendarEventListData;
            getDeleteCalendarEventListData = { ...getDeleteCalendarEventListData, success: 'complete' };
            let eventListState = { ...state.getCalendarEventListData };
            let eventListData = state.getCalendarEventListData && Object.keys(state.getCalendarEventListData).length > 0 ? [...state.getCalendarEventListData?.items] : [];
            eventListData = eventListData.filter((data) => { return data.id !== getDeleteCalendarEventListData.eventId })
            eventListState = { ...eventListState, items: eventListData }
            return {
                ...state,
                loading: false,
                getDeleteCalendarEventListData,
                getCalendarEventListData: eventListState
            };
        case ERROR_DELETE_GET_CALENDAR_LIST_DATA:
            const errorDeleteCalendarEventListData = action.data;
            return {
                ...state,
                loading: false,
                getDeleteCalendarEventListData: {},
                tokenErrorData: errorDeleteCalendarEventListData &&
                    Object.keys(errorDeleteCalendarEventListData).length > 0
                    ? errorDeleteCalendarEventListData.error
                    : {},
            };
        case EDIT_CALENDAR_LIST_DATA:
            let updateCalendarEventData = action.data;
            if (updateCalendarEventData) {
                let updatedData = {
                    summary: updateCalendarEventData.summary,
                    start: {
                        dateTime: updateCalendarEventData.start
                    },
                    end: {
                        dateTime: updateCalendarEventData.end
                    }
                }
                updateCalendarEventData = updatedData;
            }
            return {
                ...state,
                loading: true,
                editCalendarEventData: updateCalendarEventData

            };
        case SUCCESS_EDIT_CALENDAR_LIST_DATA:
            let getEditCalendarEventData = action.data;
            let prevEditCalendarEventData = { ...state.editCalendarEventData };
            prevEditCalendarEventData = { ...prevEditCalendarEventData, success: 'complete' };
            let eventsListData = { ...state.getCalendarEventListData };
            let eventListItemsData = eventsListData && Object.keys(eventsListData).length > 0 ? [...eventsListData?.items] : [];
            const findItemIndex = eventListItemsData.findIndex((obj) => obj.id === getEditCalendarEventData.id)
            eventListItemsData[findItemIndex] = { ...eventListItemsData[findItemIndex], ...prevEditCalendarEventData }
            eventsListData = { ...eventsListData, items: eventListItemsData }
            return {
                ...state,
                loading: false,
                getCalendarEventListData: eventsListData,
                editCalendarEventData: prevEditCalendarEventData
            };
        case ERROR_EDIT_CALENDAR_LIST_DATA:
            const errorEditCalendarEventListData = action.data;
            return {
                ...state,
                loading: false,
                getCalendarEventListData,
                editCalendarEventData: {},
                tokenErrorData: errorEditCalendarEventListData &&
                    Object.keys(errorEditCalendarEventListData).length > 0
                    ? errorEditCalendarEventListData.error
                    : {},
            };
        case RESET_CALENDAR_STATE:
            return {
                ...state,
                tokenErrorData: {}
            };

        //MICROSOFT EVENTS

        case GET_MICROSOFT_CALENDAR_LIST_DATA:
            return { ...state, loading: true };
        case SUCCESS_GET_MICROSOFT_CALENDAR_LIST_DATA:
            const getMicrosoftEventListData = action.data;
            return {
                ...state,
                loading: false,
                getMicrosoftEventListData
            };
        case ERROR_GET_MICROSOFT_CALENDAR_LIST_DATA:
            const errorgetMicrosoftEventListData = action.data;
            return {
                ...state,
                loading: false,
                getMicrosoftEventListData: errorgetMicrosoftEventListData,
            };

        //Delete Microsoft event reducer

        case DELETE_MICROSOFT_EVENT:
            const getDeleteMicrosoftEventListId = action.data;
            return {
                ...state,
                loading: true,
                getDeleteMicrosoftEventListData: getDeleteMicrosoftEventListId,
            };
        case SUCCESS_DELETE_MICROSOFT_EVENT:
            let getDeleteMicrosoftEventListData = state.getDeleteMicrosoftEventListData;
            getDeleteMicrosoftEventListData = { ...getDeleteMicrosoftEventListData, success: 'complete' };
            let eventMicrosoftState = { ...state.getMicrosoftEventListData };
            let eventMicrosoftListData = [...state.getMicrosoftEventListData?.value];
            eventMicrosoftListData = eventMicrosoftListData.filter((data) => {
                return data.id !== state.getDeleteMicrosoftEventListData
            })

            eventMicrosoftState = { ...eventMicrosoftState, value: eventMicrosoftListData }
            return {
                ...state,
                loading: false,
                getDeleteMicrosoftEventListData,
                getMicrosoftEventListData: eventMicrosoftState
            };
        case ERROR_DELETE_MICROSOFT_EVENT:
            const errorDeleteMicrosoftEvent = action.data;
            return {
                ...state,
                loading: false,
                getDeleteMicrosoftEventListData: {},
                tokenErrorData: errorDeleteMicrosoftEvent &&
                    Object.keys(errorDeleteMicrosoftEvent).length > 0
                    ? errorDeleteMicrosoftEvent.error
                    : {},
            };

        //Edit Microsoft reducer

        case EDIT_MICROSOFT_CALENDAR_LIST_DATA:
            let updateMicrosoftEventData = action.data;
            if (updateMicrosoftEventData) {
                let updatedData = {
                    subject: updateMicrosoftEventData.summary,
                    start: {
                        dateTime: updateMicrosoftEventData.start,
                        timeZone: 'UTC'
                    },
                    end: {
                        dateTime: updateMicrosoftEventData.end,
                        timeZone: 'UTC'
                    }
                }
                updateMicrosoftEventData = updatedData;
            }
            return {
                ...state,
                loading: true,
                editMicrosoftEventData: updateMicrosoftEventData

            };
        case SUCCESS_MICROSOFT_EDIT_CALENDAR_LIST_DATA:
            let getEditMicrosoftEventData = action.data;
            let prevEditMicrosoftEventData = { ...state.editMicrosoftEventData };
            prevEditMicrosoftEventData = { ...prevEditMicrosoftEventData, success: 'complete' };
            let microsftEventsListData = { ...state.getCalendarEventListData };
            let eventMicrosoftListItemsData = [...state.getMicrosoftEventListData?.value]
            const findMicrosoftItemIndex = eventMicrosoftListItemsData.findIndex((obj) => obj.id === getEditMicrosoftEventData.id)
            eventMicrosoftListItemsData[findMicrosoftItemIndex] = { ...eventMicrosoftListItemsData[findMicrosoftItemIndex], ...prevEditMicrosoftEventData }
            microsftEventsListData = { ...microsftEventsListData, value: eventMicrosoftListItemsData }
            return {
                ...state,
                loading: false,
                getMicrosoftEventListData: microsftEventsListData,
                editMicrosoftEventData: prevEditMicrosoftEventData
            };
        case ERROR_MICROSOFT_EDIT_CALENDAR_LIST_DATA:
            const errorEditMicrosftEventListData = action.data;
            return {
                ...state,
                loading: false,
                getMicrosoftEventListData,
                editMicrosoftEventData: {},
                tokenErrorData: errorEditMicrosftEventListData &&
                    Object.keys(errorEditMicrosftEventListData).length > 0
                    ? errorEditMicrosftEventListData.error
                    : {},
            };
        default:
            return state;
    }
};
