import { put, takeLatest } from 'redux-saga/effects';
import {
    addBookingResponse, ADD_BOOKING, SUCCESS_ADD_BOOKING, ERROR_ADD_BOOKING,
    listBookingsResponse, SUCCESS_LIST_BOOKINGS, ERROR_LIST_BOOKINGS, LIST_BOOKINGS,
    deleteBookingResponse, SUCCESS_DELETE_BOOKING, ERROR_DELETE_BOOKING, DELETE_BOOKING,
    getBookingByIdResponse, SUCCESS_GET_BOOKING_BY_ID, ERROR_GET_BOOKING_BY_ID, GET_BOOKING_BY_ID,
    updateBookingResponse, SUCCESS_UPDATE_BOOKING, ERROR_UPDATE_BOOKING, UPDATE_BOOKING,
    addBookingNoteResponse, ADD_BOOKING_NOTE, SUCCESS_ADD_BOOKING_NOTE, ERROR_ADD_BOOKING_NOTE,
    listBookingNoteResponse, SUCCESS_LIST_BOOKING_NOTE, LIST_BOOKING_NOTE, ERROR_LIST_BOOKING_NOTE,
    deleteBookingNoteResponse, SUCCESS_DELETE_BOOKING_NOTE, DELETE_BOOKING_NOTE, ERROR_DELETE_BOOKING_NOTE,
    updateBookingNoteResponse, SUCCESS_UPDATE_BOOKING_NOTE, ERROR_UPDATE_BOOKING_NOTE, UPDATE_BOOKING_NOTE,
    addBookingTaskResponse, SUCCESS_ADD_BOOKING_TASK, ADD_BOOKING_TASK, ERROR_ADD_BOOKING_TASK,
    listBookingTaskResponse, SUCCESS_LIST_BOOKING_TASK, ERROR_LIST_BOOKING_TASK, LIST_BOOKING_TASK,
    deleteBookingTaskResponse, SUCCESS_DELETE_BOOKING_TASK, ERROR_DELETE_BOOKING_TASK, DELETE_BOOKING_TASK,
    updateBookingTaskResponse, SUCCESS_UPDATE_BOOKING_TASK, ERROR_UPDATE_BOOKING_TASK, UPDATE_BOOKING_TASK,
    CREATE_APPOINTMENT, SUCCESS_CREATE_APPOINTMENT, ERROR_CREATE_APPOINTMENT, createAppointmentResponse,
    BOOKING_LIST_VIEW, SUCCESS_BOOKING_LIST_VIEW, ERROR_BOOKING_LIST_VIEW, bookingListViewResponse,
    DELETE_APPOINTMENT, SUCCESS_DELETE_APPOINTMENT, ERROR_DELETE_APPOINTMENT, deleteAppointmentResponse,
    UPDATE_APPOINTMENT, SUCCESS_UPDATE_APPOINTMENT, ERROR_UPDATE_APPOINTMENT, updateAppointmentResponse, SUCCESS_CREATE_BOOKING_PAYMENT, ERROR_CREATE_BOOKING_PAYMENT, createBookingPaymentResponse, CREATE_BOOKING_PAYMENT
} from './booking.action';
import {
    addBookingApi, deleteBookingApi, getBookingByIdApi, listBookingsApi, updateBookingApi,
    addBookingNoteApi, listBookingNoteApi, deleteBookingNoteApi, updateBookingNoteApi,
    addBookingTaskApi, listBookingTaskApi, deleteBookingTaskApi,
    updateBookingTaskApi, createAppointmentApi, bookingListViewApi, deleteAppointmentApi,
    updateAppointmentApi
} from '../../../api';
import _ from 'lodash';
import { successNotification } from '../../common/notification-alert';
import { createBookingPaymentApi } from '../../../api/sdk/booking';

//Add Booking Data
function* addBookingRequest(data) {
    let getData = yield addBookingApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(addBookingResponse(SUCCESS_ADD_BOOKING, getData.data));
    } else {
        yield put(addBookingResponse(ERROR_ADD_BOOKING, getData.data));
    }
}

export function* addBookingWatcher() {
    yield takeLatest(ADD_BOOKING, addBookingRequest
    );
}

// List Booking Data
function* listBookingsRequest(data) {
    let getData = yield listBookingsApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(listBookingsResponse(SUCCESS_LIST_BOOKINGS, getData.data));
    } else {
        yield put(listBookingsResponse(ERROR_LIST_BOOKINGS, getData.data));
    }
}

export function* listBookingsWatcher() {
    yield takeLatest(LIST_BOOKINGS, listBookingsRequest);
}

// Delete Booking
function* deleteBookingRequest(data) {
    let getData = yield deleteBookingApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(deleteBookingResponse(SUCCESS_DELETE_BOOKING, getData.data));
    } else {
        yield put(deleteBookingResponse(ERROR_DELETE_BOOKING, getData.data));
    }
}

export function* deleteBookingWatcher() {
    yield takeLatest(DELETE_BOOKING, deleteBookingRequest);
}

// Get Booking By Id
function* getBookingByIdRequest(data) {
    let getData = yield getBookingByIdApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getBookingByIdResponse(SUCCESS_GET_BOOKING_BY_ID, getData.data));
    } else {
        yield put(getBookingByIdResponse(ERROR_GET_BOOKING_BY_ID, getData.data));
    }
}

export function* getBookingByIdWatcher() {
    yield takeLatest(GET_BOOKING_BY_ID, getBookingByIdRequest);
}

// Update Booking Data
function* updateBookingRequest(data) {
    let getData = yield updateBookingApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateBookingResponse(SUCCESS_UPDATE_BOOKING, getData.data));
    } else {
        yield put(updateBookingResponse(ERROR_UPDATE_BOOKING, getData.data));
    }
}

export function* updateBookingWatcher() {
    yield takeLatest(UPDATE_BOOKING, updateBookingRequest);
}

// Add Booking Note Data
function* addBookingNoteRequest(data) {
    let getData = yield addBookingNoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(addBookingNoteResponse(SUCCESS_ADD_BOOKING_NOTE, getData.data));
    } else {
        yield put(addBookingNoteResponse(ERROR_ADD_BOOKING_NOTE, getData.data));
    }
}

export function* addBookingNoteWatcher() {
    yield takeLatest(ADD_BOOKING_NOTE, addBookingNoteRequest);
}

// List Booking Note Data
function* listBookingNoteRequest(data) {
    let getData = yield listBookingNoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(listBookingNoteResponse(SUCCESS_LIST_BOOKING_NOTE, getData.data));
    } else {
        yield put(listBookingNoteResponse(ERROR_LIST_BOOKING_NOTE, getData.data));
    }
}

export function* listBookingNoteWatcher() {
    yield takeLatest(LIST_BOOKING_NOTE, listBookingNoteRequest);
}


// Delete Booking Note
function* deleteBookingNoteRequest(data) {
    let getData = yield deleteBookingNoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(deleteBookingNoteResponse(SUCCESS_DELETE_BOOKING_NOTE, getData.data));
    } else {
        yield put(deleteBookingNoteResponse(ERROR_DELETE_BOOKING_NOTE, getData.data));
    }
}

export function* deleteBookingNoteWatcher() {
    yield takeLatest(DELETE_BOOKING_NOTE, deleteBookingNoteRequest);
}

// Update Booking Note Data
function* updateBookingNoteRequest(data) {
    let getData = yield updateBookingNoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateBookingNoteResponse(SUCCESS_UPDATE_BOOKING_NOTE, getData.data));
    } else {
        yield put(updateBookingNoteResponse(ERROR_UPDATE_BOOKING_NOTE, getData.data));
    }
}

export function* updateBookingNoteWatcher() {
    yield takeLatest(UPDATE_BOOKING_NOTE, updateBookingNoteRequest);
}


// Add Booking Task Data
function* addBookingTaskRequest(data) {
    let getData = yield addBookingTaskApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(addBookingTaskResponse(SUCCESS_ADD_BOOKING_TASK, getData.data));
    } else {
        yield put(addBookingTaskResponse(ERROR_ADD_BOOKING_TASK, getData.data));
    }
}

export function* addBookingTaskWatcher() {
    yield takeLatest(ADD_BOOKING_TASK, addBookingTaskRequest);
}



// List Booking Task
function* listBookingTaskRequest(data) {
    let getData = yield listBookingTaskApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(listBookingTaskResponse(SUCCESS_LIST_BOOKING_TASK, getData.data));
    } else {
        yield put(listBookingTaskResponse(ERROR_LIST_BOOKING_TASK, getData.data));
    }
}

export function* listBookingTaskWatcher() {
    yield takeLatest(LIST_BOOKING_TASK, listBookingTaskRequest);
}

// Delete Booking Task
function* deleteBookingTaskRequest(data) {
    let getData = yield deleteBookingTaskApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(deleteBookingTaskResponse(SUCCESS_DELETE_BOOKING_TASK, getData.data));
    } else {
        yield put(deleteBookingTaskResponse(ERROR_DELETE_BOOKING_TASK, getData.data));
    }
}

export function* deleteBookingTaskWatcher() {
    yield takeLatest(DELETE_BOOKING_TASK, deleteBookingTaskRequest);
}


// Update Booking Task Data
function* updateBookingTaskRequest(data) {
    let getData = yield updateBookingTaskApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateBookingTaskResponse(SUCCESS_UPDATE_BOOKING_TASK, getData.data));
    } else {
        yield put(updateBookingTaskResponse(ERROR_UPDATE_BOOKING_TASK, getData.data));
    }
}

export function* updateBookingTaskWatcher() {
    yield takeLatest(UPDATE_BOOKING_TASK, updateBookingTaskRequest);
}

// Create Appointment
function* createAppointmentRequest(data) {
    let getData = yield createAppointmentApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(createAppointmentResponse(SUCCESS_CREATE_APPOINTMENT, getData.data));
    } else {
        yield put(createAppointmentResponse(ERROR_CREATE_APPOINTMENT, getData.data));
    }
}

export function* createAppointmentWatcher() {
    yield takeLatest(CREATE_APPOINTMENT, createAppointmentRequest);
}

// Booking List View
function* bookingListViewRequest(data) {
    let getData = yield bookingListViewApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        // successNotification(getData.data.message)
        yield put(bookingListViewResponse(SUCCESS_BOOKING_LIST_VIEW, getData.data));
    } else {
        yield put(bookingListViewResponse(ERROR_BOOKING_LIST_VIEW, getData.data));
    }
}

export function* bookingListViewWatcher() {
    yield takeLatest(BOOKING_LIST_VIEW, bookingListViewRequest);
}

// Delete Appointment
function* deleteAppointmentRequest(data) {
    let getData = yield deleteAppointmentApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(deleteAppointmentResponse(SUCCESS_DELETE_APPOINTMENT, getData.data));
    } else {
        yield put(deleteAppointmentResponse(ERROR_DELETE_APPOINTMENT, getData.data));
    }
}

export function* deleteAppointmentWatcher() {
    yield takeLatest(DELETE_APPOINTMENT, deleteAppointmentRequest);
}

// DUpdate Appointment
function* updateAppointmentRequest(data) {
    let getData = yield updateAppointmentApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateAppointmentResponse(SUCCESS_UPDATE_APPOINTMENT, getData.data));
    } else {
        yield put(updateAppointmentResponse(ERROR_UPDATE_APPOINTMENT, getData.data));
    }
}

export function* updateAppointmentWatcher() {
    yield takeLatest(UPDATE_APPOINTMENT, updateAppointmentRequest);
}

// Create Booking Update
function* createBookingPaymentRequest(data) {
    let getData = yield createBookingPaymentApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(createBookingPaymentResponse(SUCCESS_CREATE_BOOKING_PAYMENT, getData.data));
    } else {
        yield put(createBookingPaymentResponse(ERROR_CREATE_BOOKING_PAYMENT, getData.data));
    }
}

export function* createBookingPaymentWatcher() {
    yield takeLatest(CREATE_BOOKING_PAYMENT, createBookingPaymentRequest);
}