export const DEFAULT_STATE = {
    listInvoiceData: {},
    addInvoiceData: {},
    getInvoiceByIdData: {},
    updateInvoiceData: {},
    sendToCustomerInvoiceData: {},
    viewCustomerInvoiceData: {},
    markPaidInvoiceDataData: {},
    reviseInvoiceData: {},
    getPaidInvoiceData: {},
    deleteInvoiceData: {},
    listInvoiceTemplateData: {},
    getInvoiceTemplateByIdData: {},
    addInvoiceTemplateData: {},
    invoiceMakePaymentData: {},
    messageBodyData:{}
};