import {
    SUCCESS_LIST_QUOTE, ERROR_LIST_QUOTE,
    SUCCESS_ADD_QUOTE, ERROR_ADD_QUOTE,
    SUCCESS_ADD_QUOTE_TEMPLATE, ERROR_ADD_QUOTE_TEMPLATE,
    SUCCESS_GET_QUOTE_TEMPLATE_BY_ID, ERROR_GET_QUOTE_TEMPLATE_BY_ID,
    SUCCESS_GET_QUOTE_BY_ID, ERROR_GET_QUOTE_BY_ID,
    SUCCESS_UPDATE_QUOTE_CUSTOMER, ERROR_UPDATE_QUOTE_CUSTOMER,
    SUCCESS_UPDATE_QUOTE, ERROR_UPDATE_QUOTE,
    SUCCESS_SEND_TO_CUSTOMER_QUOTE, ERROR_SEND_TO_CUSTOMER_QUOTE,
    SUCCESS_VIEW_CUSTOMER_QUOTE, ERROR_VIEW_CUSTOMER_QUOTE,
    SUCCESS_CHANGE_QUOTE_STATUS, ERROR_CHANGE_QUOTE_STATUS,
    SUCCESS_REVISE_QUOTE, ERROR_REVISE_QUOTE,
    SUCCESS_GET_ACCEPTED_QUOTE, ERROR_GET_ACCEPTED_QUOTE,
    SUCCESS_DELETE_QUOTE, ERROR_DELETE_QUOTE,
    SUCCESS_LIST_QUOTE_TEMPLATE, ERROR_LIST_QUOTE_TEMPLATE,
    SUCCESS_UPDATE_BASIC_QUOTE, ERROR_UPDATE_BASIC_QUOTE,
    SUCCESS_UPDATE_MESSAGE_BODY_QUOTE, ERROR_UPDATE_MESSAGE_BODY_QUOTE,
    SUCCESS_GET_MESSAGE_BODY_QUOTE, ERROR_GET_MESSAGE_BODY_QUOTE,

    SUCCESS_ATTACHMENTS_BROWSE_FILE_QUOTE, ERROR_ATTACHMENTS_BROWSE_FILE_QUOTE,
    SUCCESS_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, ERROR_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, CLEAR_GET_QUOTE_BY_ID,
} from './quote.action';
import { DEFAULT_STATE } from "./quote.state";

export const quoteReducer = (state = DEFAULT_STATE, action = {
    type: {},
    data: {}
}) => {
    switch (action.type) {
        case SUCCESS_LIST_QUOTE:
            const listQuoteData = action.data;
            return { ...state, listQuoteData }
        case ERROR_LIST_QUOTE:
            const errorListQuoteData = action.data;
            return { ...state, listQuoteData: errorListQuoteData }
        case SUCCESS_ADD_QUOTE:
            const addQuoteData = action.data;
            return { ...state, addQuoteData }
        case ERROR_ADD_QUOTE:
            const errorAddQuoteData = action.data;
            return { ...state, addQuoteData: errorAddQuoteData }
        case SUCCESS_ADD_QUOTE_TEMPLATE:
            const addQuoteTemplateData = action.data;
            return { ...state, addQuoteTemplateData }
        case ERROR_ADD_QUOTE_TEMPLATE:
            const errorAddQuoteTemplateData = action.data;
            return { ...state, addQuoteTemplateData: errorAddQuoteTemplateData }
        case SUCCESS_GET_QUOTE_TEMPLATE_BY_ID:
            const getQuoteTemplateByIdData = action.data;
            return { ...state, getQuoteTemplateByIdData }
        case ERROR_GET_QUOTE_TEMPLATE_BY_ID:
            const errorGetQuoteTemplateByIdData = action.data;
            return { ...state, getQuoteTemplateByIdData: errorGetQuoteTemplateByIdData }
        case SUCCESS_GET_QUOTE_BY_ID:
            const getQuoteByIdData = action.data;
            return { ...state, getQuoteByIdData }
        case ERROR_GET_QUOTE_BY_ID:
            const errorGetQuoteByIdData = action.data;
            return { ...state, getQuoteByIdData: errorGetQuoteByIdData }
        case CLEAR_GET_QUOTE_BY_ID:
            return { ...state, getQuoteByIdData: {} }
        case SUCCESS_UPDATE_QUOTE_CUSTOMER:
            const updateQuoteCustomerData = action.data;
            return { ...state, updateQuoteCustomerData }
        case ERROR_UPDATE_QUOTE_CUSTOMER:
            const errorUpdateQuoteCustomerData = action.data;
            return { ...state, updateQuoteCustomerData: errorUpdateQuoteCustomerData }
        case SUCCESS_UPDATE_QUOTE:
            const updateQuoteData = action.data;
            return { ...state, updateQuoteData }
        case ERROR_UPDATE_QUOTE:
            const errorUpdateQuoteData = action.data;
            return { ...state, updateQuoteData: errorUpdateQuoteData }
        case SUCCESS_SEND_TO_CUSTOMER_QUOTE:
            const sendToCustomerQuoteData = action.data;
            return { ...state, sendToCustomerQuoteData }
        case ERROR_SEND_TO_CUSTOMER_QUOTE:
            const errorSendToCustomerData = action.data;
            return { ...state, sendToCustomerQuoteData: errorSendToCustomerData }
        case SUCCESS_VIEW_CUSTOMER_QUOTE:
            const viewCustomerQuoteData = action.data;
            return { ...state, viewCustomerQuoteData }
        case ERROR_VIEW_CUSTOMER_QUOTE:
            const errorViewCustomerQuoteData = action.data;
            return { ...state, viewCustomerQuoteData: errorViewCustomerQuoteData }
        case SUCCESS_CHANGE_QUOTE_STATUS:
            const changeQuoteStatusData = action.data;
            return { ...state, changeQuoteStatusData }
        case ERROR_CHANGE_QUOTE_STATUS:
            const errorChangeQuoteStatusData = action.data;
            return { ...state, changeQuoteStatusData: errorChangeQuoteStatusData }
        case SUCCESS_REVISE_QUOTE:
            const reviseQuoteData = action.data;
            return { ...state, reviseQuoteData }
        case ERROR_REVISE_QUOTE:
            const errorReviseQuoteData = action.data;
            return { ...state, reviseQuoteData: errorReviseQuoteData }
        case SUCCESS_GET_ACCEPTED_QUOTE:
            const getAcceptedQuoteData = action.data;
            return { ...state, getAcceptedQuoteData }
        case ERROR_GET_ACCEPTED_QUOTE:
            const errorGetAcceptedQuoteData = action.data;
            return { ...state, getAcceptedQuoteData: errorGetAcceptedQuoteData }
        case SUCCESS_DELETE_QUOTE:
            const deleteQuoteData = action.data;
            return { ...state, deleteQuoteData }
        case ERROR_DELETE_QUOTE:
            const errorDeleteQuoteData = action.data;
            return { ...state, deleteQuoteData: errorDeleteQuoteData }
        case SUCCESS_LIST_QUOTE_TEMPLATE:
            const listQuoteTemplateData = action.data;
            return { ...state, listQuoteTemplateData }
        case ERROR_LIST_QUOTE_TEMPLATE:
            const errorListQuoteTemplateData = action.data;
            return { ...state, listQuoteTemplateData: errorListQuoteTemplateData }
        case SUCCESS_UPDATE_BASIC_QUOTE:
            const updateBasicQuoteData = action.data;
            return { ...state, addQuoteData: updateBasicQuoteData }
        case ERROR_UPDATE_BASIC_QUOTE:
            const errorUpdateBasicQuoteData = action.data;
            return { ...state, addQuoteData: errorUpdateBasicQuoteData }

        case SUCCESS_UPDATE_MESSAGE_BODY_QUOTE:
            const messageBodyData = action.data;
            return { ...state, messageBodyData: messageBodyData }
        case ERROR_UPDATE_MESSAGE_BODY_QUOTE:
            const errorMessageBodyData = action.data;
            return { ...state, messageBodyData: errorMessageBodyData }

        case SUCCESS_GET_MESSAGE_BODY_QUOTE:
            const getMessageBodyData = action.data;
            return { ...state, messageBodyData: getMessageBodyData }
        case ERROR_GET_MESSAGE_BODY_QUOTE:
            const errorGetMessageBodyData = action.data;
            return { ...state, messageBodyData: errorGetMessageBodyData }

        // Attachments (Optional) Browse file data
        case SUCCESS_ATTACHMENTS_BROWSE_FILE_QUOTE:
            const attachmentsBrowseFileQuote = action.data;
            return { ...state, attachmentsBrowseFileQuoteData: attachmentsBrowseFileQuote }
        case ERROR_ATTACHMENTS_BROWSE_FILE_QUOTE:
            const errorAttachmentsBrowseFileQuote = action.data;
            return { ...state, attachmentsBrowseFileQuoteData: errorAttachmentsBrowseFileQuote }

        // Attachments (Optional) Browse file delete data
        case SUCCESS_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE:
            const attachmentsBrowseFileDeleteQuote = action.data;
            return { ...state, attachmentsBrowseFileDeleteQuoteData: attachmentsBrowseFileDeleteQuote }
        case ERROR_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE:
            const errorAttachmentsBrowseFileDeleteQuote = action.data;
            return { ...state, attachmentsBrowseFileDeleteQuoteData: errorAttachmentsBrowseFileDeleteQuote }

        default:
            return state;
    }
};
