import React from "react";
import { setImagePath } from "../../../../common/custom";
import { Link } from "react-router-dom";
// import MENU_DOTTED from "../../../../assets/images/menu-dotted.svg";
// import {
//   ADD_BASIC_QUOTE,
//   ADD_CONTACT,
//   ADD_INVOICE_BASE,
//   ADD_LEAD,
// } from "../../../../routing/routeContants";
import BETA_INDICATOR from "../../../../assets/images/beta_indicator.png";
import { EMAIL_LIST } from "../../../../routing/routeContants";

export const EmailHeader = (props) => {
  // const { createFunction } = props;
  const { noBack, removeAccount, handleSocialSignout } = props;

  return (
    <>
      <section className="page-title contact--header Quotesheader email-template-design">
        <div className="container">
          <div className="row">
            <div className="col-lg-auto title--col">
              <div>
                <h2 className="title">Email
                  {/* <img
                    className="beta-indicator"
                    src={setImagePath(BETA_INDICATOR)}
                    alt="BETA_INDICATOR"
                  /> */}
                </h2>
              </div>
            </div>
            {!noBack && <div className="col-lg-auto title--col justify-content-end ">
              <div>
                <Link to={EMAIL_LIST} className="btn btn-primary d-none d-lg-block back">
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="BackArrow"><g data-name="Layer 2" fill="#ffffff" class="color000000 svgShape"><path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z" data-name="arrow-ios-back" fill="#ffffff" class="color000000 svgShape"></path></g></svg>
                  Back</Link>
              </div>
            </div>}
            {(removeAccount && handleSocialSignout)
              &&
              <div className="col-lg-auto title--col justify-content-end ">
                <button
                  onClick={handleSocialSignout}
                  className="btn btn-primary"
                >
                  Remove Account
                </button>
              </div>
            }
          </div>
        </div>
      </section>

      {/* <section className="page-title contact--header">
      <div className="container">
        <div className="row">
          <div className="col-lg-auto title--col">
            <div>
              <h2 className="title">Email
                <img
                  className="beta-indicator"
                  src={setImagePath(BETA_INDICATOR)}
                  alt="BETA_INDICATOR"
                />
              </h2>
            </div>
          </div>
          <div className="col-auto ml-auto d-flex align-items-center title-elems">
            <div className="dropdown custom-dropdown d-none d-lg-block">
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Actions
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#contact"
                  onClick={(e) => createFunction(e, ADD_CONTACT)}
                >
                  Create Contact
                </a>
                <a
                  className="dropdown-item"
                  href="#lead"
                  onClick={(e) => createFunction(e, ADD_LEAD)}
                >
                  Create Lead
                </a>
                <a
                  className="dropdown-item"
                  href="#quote"
                  onClick={(e) => createFunction(e, ADD_BASIC_QUOTE)}
                >
                  Create Quote
                </a>
                <a
                  className="dropdown-item"
                  href="#invoice"
                  onClick={(e) => createFunction(e, ADD_INVOICE_BASE)}
                >
                  Create Invoice
                </a>
              </div >
            </div>
            <a
              href="#contact"
              onClick={(e) => createFunction(e, ADD_CONTACT)}
              className="btn d-lg-none btn-secondary mr-15"
            >
              New Contact
            </a>
            <a
              href="#lead"
              onClick={(e) => createFunction(e, ADD_LEAD)}
              className="btn d-lg-none btn-secondary"
            >
              New Lead
            </a>
            <div className="dropdown d-lg-none custom-dropdown dropdown-toggle--mbl">
              <button
                className="btn dropdown-toggle "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src={setImagePath(MENU_DOTTED)} alt="" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#contact"
                  onClick={(e) => createFunction(e, ADD_CONTACT)}
                >
                  Create Contact
                </a>
                <a
                  className="dropdown-item"
                  href="#lead"
                  onClick={(e) => createFunction(e, ADD_LEAD)}
                >
                  Create Lead
                </a>
                <a
                  className="dropdown-item"
                  href="#quote"
                  onClick={(e) => createFunction(e, ADD_BASIC_QUOTE)}
                >
                  Create Quote
                </a>
                <a
                  className="dropdown-item"
                  href="#invoice"
                  onClick={(e) => createFunction(e, ADD_INVOICE_BASE)}
                >
                  Create Invoice
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    </>
  );
};
