import React from "react";
import INBOX_ICON from "../../../../assets/images/inbox_FILL0_wght400_GRAD0_opsz24.svg";
import DRAFTS_ICON from "../../../../assets/images/draft_FILL0_wght400_GRAD0_opsz24.svg";
import SENT_ICON from "../../../../assets/images/send_FILL0_wght400_GRAD0_opsz24.svg";
import JUNK_ICON from "../../../../assets/images/report_FILL0_wght400_GRAD0_opsz24.svg";
import TRASH_ICON from "../../../../assets/images/delete_FILL0_wght400_GRAD0_opsz24.svg";
import { EMAIL_LIST } from "../../../../routing/routeContants";
import { Link } from "react-router-dom";
import { setImagePath } from "../../../../common/custom";

export const EmailSidebar = (props) => {
  const {
    googleErrorData,
    microsoftErrorData,
    handleSiginWithGoogle,
    handleSiginWithMicrosoft,
    accordionState,
    labelId,
    msalLabel,
    labelsList,
    handleGoogleLabels,
    handleAccordionClick,
    handleMicrosoftLabels,
    userSocialData
  } = props;

  return (
    <div className="col-sm-2 email-sidebar">
      <div className="h-100">
        <div id="accordionExample" className="accordion">
          <div className="card-outer">
            <div id="headingOne" className="email-title">
              <h2 className="mb-0">
                {googleErrorData && googleErrorData.code === 401 ?
                  <button title={"Sign in with Google"} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded={accordionState === "gmail" ? "true" : "false"}
                    aria-controls="collapseOne"
                    className="btn btn-link text-dark font-weight-bold collapsible-link" onClick={handleSiginWithGoogle}>{"Sign in with Google"}
                  </button>
                  :
                  <button title={userSocialData?.google?.social_email || "G-mail"} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded={accordionState === "gmail" ? "true" : "false"}
                    aria-controls="collapseOne"
                    className="btn btn-link text-dark font-weight-bold collapsible-link" onClick={() => handleAccordionClick("gmail")}>{userSocialData?.google?.social_email || "G-mail"}
                  </button>}
              </h2>
            </div>
            <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" className={`collapse ${accordionState === "gmail" ? "show" : ''}`}>
              <ul>
                <li className={`${labelId === "INBOX" ? "active" : ""}`}>
                  <Link
                    to={EMAIL_LIST}
                    onClick={() => {
                      handleGoogleLabels("INBOX");
                    }}
                  >
                    <img src={setImagePath(INBOX_ICON)} alt="INBOX_ICON" /> Inbox
                  </Link>
                </li>
                <li className={`${labelId === "DRAFT" ? "active" : ""}`}>
                  <Link
                    to={EMAIL_LIST}
                    onClick={() => {
                      handleGoogleLabels("DRAFT");
                    }}
                  >
                    <img src={setImagePath(DRAFTS_ICON)} alt="DRAFTS_ICON" /> Drafts{" "}
                  </Link>
                </li>
                <li className={`${labelId === "SENT" ? "active" : ""}`}>
                  <Link
                    to={EMAIL_LIST}
                    onClick={() => {
                      handleGoogleLabels("SENT");
                    }}
                  >
                    <img src={setImagePath(SENT_ICON)} alt="SENT_ICON" /> Sent{" "}
                  </Link>
                </li>
                <li className={`${labelId === "SPAM" ? "active" : ""}`}>
                  <Link
                    to={EMAIL_LIST}
                    onClick={() => {
                      handleGoogleLabels("SPAM");
                    }}
                  >
                    <img src={setImagePath(JUNK_ICON)} alt="JUNK_ICON" /> Junk
                  </Link>
                </li>
                <li className={`${labelId === "TRASH" ? "active" : ""}`}>
                  <Link
                    to={EMAIL_LIST}
                    onClick={() => {
                      handleGoogleLabels("TRASH");
                    }}
                  >
                    <img src={setImagePath(TRASH_ICON)} alt="TRASH_ICON" /> Trash{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>


          <div className="card-outer">
            <div id="headingOne" className="email-title">
              <h2 className="mb-0">
                {((microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") || !userSocialData?.microsoft?.social_email)
                  ?
                  <button title={"Sign in with Microsoft"} type="button" data-toggle="collapse" data-target="#collapsetwo" aria-expanded={accordionState === "microsoft" ? "true" : "false"}
                    aria-controls="collapsetwo"
                    className="btn btn-link text-dark font-weight-bold collapsible-link" onClick={handleSiginWithMicrosoft}>{"Sign in with Microsoft"}</button>
                  :
                  <button title={userSocialData?.microsoft?.social_email || "Outlook"} type="button" data-toggle="collapse" data-target="#collapsetwo" aria-expanded={accordionState === "microsoft" ? "true" : "false"}
                    aria-controls="collapsetwo"
                    className="btn btn-link text-dark font-weight-bold collapsible-link" onClick={() => handleAccordionClick("microsoft")}>{userSocialData?.microsoft?.social_email || "Outlook"}</button>}
              </h2>
            </div>
            <div id="collapsetwo" aria-labelledby="headingOne" data-parent="#accordionExample" className={`collapse ${accordionState === "microsoft" ? "show" : ''}`}>
              <ul>
                {labelsList && labelsList.length > 0 && labelsList.map((obj, indexx) => {
                  const labelName = obj.displayName === "Inbox" ? "Inbox" : obj.displayName === "Deleted Items" ? "Trash" : obj.displayName === "Sent Items" ? "Sent" : obj.displayName === "Junk Email" ? "Junk" : obj.displayName;
                  const imageIcon = obj.displayName === "Deleted Items" ? TRASH_ICON : obj.displayName === "Drafts" ? DRAFTS_ICON : obj.displayName === "Sent Items" ? SENT_ICON : obj.displayName === "Junk Email" ? JUNK_ICON : INBOX_ICON;
                  return (
                    <li className={`${msalLabel.id === obj.id ? "active" : ""}`} key={indexx}>
                      <Link
                        to={EMAIL_LIST}
                        onClick={() => {
                          handleMicrosoftLabels(obj);
                        }}
                      >
                        <img src={setImagePath(imageIcon)} alt="icon" /> {labelName}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};
