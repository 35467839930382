import React from "react";
import { ConvertTimeFromUTCToIST } from "../../../../common/constants";
import _ from 'lodash';
import { setImagePath } from "../../../../common/custom";
import ATTACHMENT_ICON from "../../../../assets/images/attach_file_FILL0_wght400_GRAD0_opsz24.svg";
import { Link } from "react-router-dom";
import { EMAIL_VIEW_BASE } from "../../../../routing/routeContants";

export function EmailTableListForLeadAndContact(props) {
    const { listData, loading, userName } = props;

    return (
        <div className="table-responsive table-vertical-scroll">
            <table className="table table-striped tasks--table smart-table" >
                <tbody>
                    {listData && listData.length ? (
                        _.map(listData, (message, index) => {
                            const hasAttachmentId =
                                message &&
                                message.payload &&
                                message.payload?.parts?.length &&
                                message.payload?.parts.map((data) =>
                                    _.has(data.body, "attachmentId")
                                );
                            const isUnread = message && message.labelIds &&
                                message.labelIds.length && message.labelIds.includes("UNREAD");
                            const EmailDate =
                                message &&
                                message.payload &&
                                message.payload.headers.length &&
                                message.payload.headers.find((obj) => obj.name === "Date");
                            const Subject =
                                message &&
                                message.payload &&
                                message.payload?.headers?.length &&
                                message.payload?.headers.find((obj) => obj.name === "Subject");
                            return <tr key={index}>
                                <>
                                    <td className={`task--status ${isUnread ? "font-weight-bold" : ""}`} width={"212px"}>{ConvertTimeFromUTCToIST(EmailDate?.value, "reply") || "-"}</td>
                                    <td className={`task--todo ${isUnread ? "font-weight-bold" : ""}`}>
                                        <Link
                                            to={{ pathname: EMAIL_VIEW_BASE + message.threadId, state: { uniqueViewKey: "google" } }}
                                            title={Subject?.value || "-"}
                                        >
                                            {Subject?.value || "-"}
                                        </Link></td>
                                    <td className="text-right table-action">
                                        <div className="d-flex">
                                            {hasAttachmentId && hasAttachmentId.includes(true) ? (
                                                <div className="action-btn p-0">
                                                    <img
                                                        src={setImagePath(ATTACHMENT_ICON)}
                                                        alt="ATTACHMENT_ICON"
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </td>
                                </>
                            </tr>
                        }))
                        :
                        <tr>
                            <td colSpan="6" className="bg-white">
                                <div className="no--contacts--note">
                                    <h5 className="text-secondary">{!loading ? userName ? `No Emails from ${userName || ""}` : "No Emails Found!" : "..."}</h5>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}