import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { selectStyle, constants, CustomValueContainer } from '../../../../common/constants';
import Modal from "react-bootstrap/Modal";
import _ from 'lodash';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CALENDAR from "../../../../assets/images/calendar.png"
export const EditGoogleAppointment = props => {

    const { loggedInUserEmail, handleSubmit, editGoogleEventModalShow, setEditGoogleEventModalShow, editGoogleEventData, setLoader } = props
    const [state, setState] = useState({
        start: '', end: '', summary: '', eventId: '', email: '',description:'',attendees:[], location :'',reminders:{}
    });

    const [contactState, setContactState] = useState({
        correctInput: '', wrongInput: constants.WRONG_INPUT, contactType: '1',
    });


    const startDatepickerRef = useRef();
    const endDatepickerRef = useRef();

    const startShowTimeSelection = () => {
        setTimeout(function () { startDatepickerRef.current.setOpen(true); }, 100);
    };

    const endShowTimeSelection = () => {
        setTimeout(function () { endDatepickerRef.current.setOpen(true); }, 100);
    };

    useEffect(() => {
        if (editGoogleEventData) {
            setState({
                ...state,
                start: (moment(editGoogleEventData?.start_date).toDate() || ""), end: (moment(editGoogleEventData?.end_date).toDate() || ""), summary: editGoogleEventData?.title,
                eventId: editGoogleEventData?.id, email: loggedInUserEmail ? loggedInUserEmail : (editGoogleEventData?.email || ""),description:editGoogleEventData?.detail,attendees:editGoogleEventData?.attendees, location :editGoogleEventData?.location,reminders:editGoogleEventData.reminder
            })
        }
    }, [editGoogleEventModalShow])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    const handleDateTimeOnChange = (date, name) => {
        if (date === null) {
            setState({
                ...state,
                [name]: "",
            });
        }else if(name=="start"){
            setState({
                ...state,
                ['start']: date,
                ['end']: date
            }); 
        } else {
            setState({
                ...state,
                [name]: date
            });
        }
    };

    return (
        <>
            <Modal show={editGoogleEventModalShow} onHide={() => {
                setEditGoogleEventModalShow(false)
            }} className="appoitment-form" size="lg" centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>
                    {appointmentData?.type === "googleEvents" ? "View Google Event" :''}
                        </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <form className="p-lg-2  px-4 py-lg-2 wk-input">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <h6>Event Name </h6>
                                <div className={"floating-label " + state.summary}>
                                    <input type="text" name="summary" value={state.summary || ''} onChange={(e) => handleInputChange(e)} className="floating-input" />

                                </div>
                            </div>

                            <div className="form-group col-md-3">

                                <div className={"floating-label"}>
                                    <h6>Start Date</h6>
                                    <DatePicker
                                        type="text"
                                        name="start"
                                        onChange={(date) => handleDateTimeOnChange(date, "start")}
                                        placeholderText="Start Date *"
                                        selected={state.start}
                                        ref={startDatepickerRef}
                                        popperPlacement="bottom"
                                        portalId="root-portal"
                                    />
                                    <div onClick={() => startShowTimeSelection()} className="input-google-calendar-icon"><img src={CALENDAR} alt="" width="20" height="20" /></div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <div className={"floating-label"}>
                                <h6>End Date</h6>
                                    <DatePicker
                                        type="text"
                                        name="end"
                                        onChange={(date) => handleDateTimeOnChange(date, "end")}
                                        placeholderText="End Date *"
                                        selected={state.end}
                                        ref={endDatepickerRef}
                                        popperPlacement="bottom"
                                        portalId="root-portal"
                                    />
                                    <div onClick={() => endShowTimeSelection()} className="input-google-calendar-icon"><img src={CALENDAR} alt="" width="20" height="20" /></div>
                                <div className={state.dateValueUniCls ? "floating-label my-custom-time-input " + state.dateValueUniCls : "floating-label my-custom-time-input"}>
                                    {state.dateValueUniErr ? <span className="errorValidationMessage"> {state.dateValueUniErr}</span> : ''}
                                </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <h6>From</h6>
                                <DatePicker
                                    selected={!state.start ? "" : state.start}
                                    onChange={(date) => handleDateTimeOnChange(date, "start")}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    fixedHeight
                                    timeIntervals={15}
                                    dateFormat="h:mm a"
                                    className="floating-input"
                                    onKeyDown={e => e.preventDefault()}
                                    placeholderText="From *"
                                    popperPlacement="bottom"
                                    portalId="root-portal"
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <h6>To</h6>
                                <DatePicker
                                    selected={!state.end ? "" : state.end}
                                    onChange={(date) => handleDateTimeOnChange(date, "end")}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    fixedHeight
                                    timeIntervals={15}
                                    dateFormat="h:mm a"
                                    className="floating-input"
                                    onKeyDown={e => e.preventDefault()}
                                    placeholderText="To *"
                                    popperPlacement="bottom"
                                    portalId="root-portal"
                                />
                                <div className={state.toValueUniCls ? "floating-label my-custom-time-input " + state.toValueUniCls : "floating-label my-custom-time-input"}>
                                    {state.toValueUniErr ? <span className="errorValidationMessage"> {state.toValueUniErr}</span> : ''}
                                </div>

                            </div>
                            <div className="form-group col-md-12 text-right">
                                <button className="btn btn-primary saveBtn" onClick={(e) => handleSubmit(e, state, setState,contactState)}>Save</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export const EditGoogleEvent = withRouter(EditGoogleAppointment)