import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import { fieldValidator, usePrevious, setImagePath } from '../../../../common/custom';
import { selectStyle, constants, CustomValueContainer } from '../../../../common/constants';
import { getContactListOptionValue } from '../../../../../api/sdk/contact';
import { getAssociateLeadListOptionValue } from '../../../../../api/sdk/lead';
import { validateInputs } from '../../../../common/validation';
import CALENDAR from "../../../../assets/images/calendar.png"
import AsyncSelect from 'react-select/async';
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import Select from 'react-select';
import _ from 'lodash';
import moment from 'moment'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { bookingListView, createAppointment, updateAppointment, deleteAppointment } from "../../../../duck/booking/booking.action";


export const NewCreateAppointment = props => {

    const { appointmentModalShow, setAppointmentModalShow, setLoader, appointmentData, appointmentStatus, getNewProp, contactData } = props
    const dispatch = useDispatch();
    const phoneTypeOptions = [{ value: 'Mobile', label: 'Mobile' }, { value: 'Work', label: 'Work' }, { value: 'Home', label: 'Home' }];

    const datepickerRef = useRef();
    const [state, setState] = useState({
        date: '', dateErr: '', dateCls: '', toValue: '', fromValue: '', title: '', titleErr: '', titleCls: '',
        correctInput: '', wrongInput: constants.WRONG_INPUT, address: '', location: '', locationErr: '', locationCls: '',
        lat_long: '', virtualEvent: false, meetingId: '', passcode: '', moreInformation: '', associateLeadSelectValue: '',
        associateLeadSelect: ''
    });

    //Contact Section
    const [contactState, setContactState] = useState({
        firstName: '', lastName: '', email: '', phone: '', organization: '', title: '', phoneType: { value: 'Mobile', label: 'Mobile' },
        firstNameCls: '', emailCls: '', phoneCls: '', firstNameErr: '', emailErr: '', phoneErr: '',
        correctInput: '', wrongInput: constants.WRONG_INPUT, contactType: '1',
        contactSelectValue: '', contactSelect: ''
    });
    const initialValue = [];
    const [appointmentServiceMessage, setAppointmentServiceMessage] = useState('');
    const [leadData, setLeaddata] = useState(initialValue);
    const createAppointmentData = useSelector((state) => state.booking.createAppointmentData);
    const prevCreateAppointmentData = usePrevious({ createAppointmentData });
    const updateAppointmentData = useSelector((state) => state.booking.updateAppointmentData);
    const prevUpdateAppointmentData = usePrevious({ updateAppointmentData });
    const deleteAppointmentData = useSelector(state => state.booking.deleteAppointmentData);
    const prevDeleteAppointmentData = usePrevious({ deleteAppointmentData });
    // load options using API call
    const loadOptions = async () => {
        let data = [];
        let listOption = await getContactListOptionValue({ searchField: 'first_name,last_name', fields: 'id,first_name,last_name,organization,title,email,phone', filter: contactState.contactSelect })
        if (listOption && listOption.data && _.has(listOption.data, 'data') && listOption.success === true) {
            data = listOption.data.data
        }
        return data;
    };

    // handle input change event
    const handleInputChange = value => {
        setContactState({ ...contactState, contactSelect: value, contactSelectErr: '', contactSelectCls: '' })
    };

    // Refer Handle selection
    const referHandleChange = async (value) => {
        if (value) {
            let data = [];
            let listOption = await getAssociateLeadListOptionValue({ fields: 'id,name,potential_revenue', filter: '', contact_id: value.id })
            if (listOption && listOption.data && _.has(listOption.data, 'data') && listOption.success === true) {
                data = listOption.data.data;
            }
            setLeaddata(data)
        }
        setContactState({ ...contactState, contactSelectValue: value })
    }

    useEffect(() => {
    }, [leadData])

    // Check Validation Function 
    const checkValidation = (field, value, type, maxLength, minLength, fieldType) => {
        return fieldValidator(field, value, type, state.password, maxLength, minLength, fieldType)
    }

    // Set The Input Values
    const setInputValue = (e, type, maxLength, minLength) => {
        let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength)
        setState({ ...state, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName });
        setAppointmentServiceMessage('');
    }

    // Set The Contact Input Values
    const setContactValue = (e, type, maxLength, minLength) => {
        let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength)
        setContactState({ ...contactState, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName });
        setAppointmentServiceMessage('');
    }

    // set date for booking 
    const dateForBooking = (date) => {
        if (date === null) {
            setState({ ...state, date: '', dateCls: state.wrongInput, dateErr: 'Please select date', showTime: false })
        } else {
            setState({ ...state, date: date, dateCls: '', dateErr: '', showTime: false })
        }
        setAppointmentServiceMessage('');
    }

    const showTimeSelection = () => {
        setTimeout(function () { datepickerRef.current.setOpen(true); }, 100);
    };

    const handleChangeGoogle = address => {
        setState({ ...state, location: address })
    };

    const handleSelectGoogle = async (address) => {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        let data = { lat: latLng.lat, lng: latLng.lng, place_id: results[0].place_id };
        setState({ ...state, location: address, lat_long: JSON.stringify(data) })
    };

    const saveAppointmentData = () => {
        let success = '';
        let error = contactState.wrongInput;
        let name = state.title, titleErr = '', titleCls = '', date = state.date, dateErr = '', dateCls = '', fromValue = state.fromValue, associateLeadSelectValue = state.associateLeadSelectValue,
            toValue = state.toValue, address = state.address, addressErr = '', addressCls = '', contactSelectValue = contactState.contactSelectValue,
            contactType = contactState.contactType, firstName = contactState.firstName, lastName = contactState.lastName, email = contactState.email,
            phone = contactState.phone, organization = contactState.organization, phoneType = contactState.phoneType.value, firstNameCls = success, emailCls = '', phoneCls = '',
            firstNameErr = '', emailErr = '', organizationErr = '', contactSelectCls = '', contactSelectErr = '', getError = false;
        let meetingId = state.meetingId, meetingIdErr = '', meetingIdCls = '';
        let passcode = state.passcode, passcodeErr = '', passcodeCls = '';
        let fromValueUni = Date.parse(state.fromValue)
        let toValueUni = Date.parse(state.toValue)
        let fromValueUniErr = '', fromValueUniCls = '';
        let toValueUniErr = '', toValueUniCls = '';
        let toValueErr = '', toValueCls = '';

        if (validateInputs('string', name) === 'empty') {
            titleErr = 'Please enter title';
            titleCls = error
            getError = true;
        } else if (validateInputs('string', name) === false) {
            titleErr = 'Please enter valid title';
            titleCls = error
            getError = true;
        } else if (name.length > 50) {
            titleErr = 'Please enter maximum 50 characters';
            titleCls = error
            getError = true;
        }

        if (validateInputs('required', (date !== '' ? (date.getDate() + ' ' + date.getMonth()) : '')) === 'empty') {
            dateErr = 'Please select  date';
            dateCls = error
            getError = true;
        }

        if (state.virtualEvent) {
            if (validateInputs('required', address) === 'empty') {
                addressErr = 'Please enter virtual event url';
                addressCls = error
                getError = true;
            } else if (validateInputs('required', address) === false) {
                addressErr = 'Please enter valid virtual event url';
                addressCls = error
                getError = true;
            } else if (address.length > 100) {
                addressErr = 'Please enter maximum 100 characters';
                addressCls = error
                getError = true;
            }
            // if (validateInputs('required', meetingId) === 'empty') {
            //     meetingIdErr = 'Please enter virtual event meetingId.';
            //     meetingIdCls = error
            //     getError = true;
            // }else if (validateInputs('required', meetingId) === false) {
            //     meetingIdErr = 'Please enter valid virtual event meetingId.';
            //     meetingIdCls = error
            //     getError = true;
            // } else if (meetingId.length > 100) {
            //     meetingIdErr = 'Please enter maximum 100 characters.';
            //     meetingIdCls = error
            //     getError = true;
            // } 
            // if (validateInputs('required', passcode) === 'empty') {
            //     passcodeErr = 'Please enter valid virtual event passcode.';
            //     passcodeCls = error
            //     getError = true;
            // }else if (validateInputs('required', passcode) === false) {
            //     passcodeErr = 'Please enter valid virtual event url.';
            //     passcodeCls = error
            //     getError = true;
            // } else if (passcode.length > 100) {
            //     passcodeErr = 'Please enter maximum 100 characters.';
            //     passcodeCls = error
            //     getError = true;
            // }  
        }


        let current_time = moment(fromValue).format("HH:mm:ss");
        let current_date = moment(date).format("YYYY-MM-DD");
        var momentDate = moment("" + current_date + " " + current_time + "");

        let fromValueTime = Date.parse(momentDate.toDate());

        current_time = moment(toValue).format("HH:mm:ss");
        current_date = moment(date).format("YYYY-MM-DD");
        momentDate = moment("" + current_date + " " + current_time + "");
        let toValueTime = Date.parse(momentDate.toDate());

        const d = new Date();
        current_time = d.getTime();

        if (isNaN(fromValueUni)) {
            fromValueUniErr = 'Please select start time';
            fromValueUniCls = error
            getError = true;
        } else if (fromValueTime < current_time) {
            fromValueUniErr = 'Selected Date Time is a past date';
            fromValueUniCls = error
            getError = true;
        }
        if (isNaN(toValueUni)) {
            toValueUniErr = 'Please select end time';
            toValueUniCls = error
            getError = true;
        } else if (toValueTime < current_time) {
            toValueUniErr = 'Selected Date Time is a past date';
            toValueUniCls = error
            getError = true;
        }

        if (fromValueUni >= toValueUni) {
            toValueUniErr = 'End time should be greater than start time';
            toValueUniCls = error
            getError = true;
        }

        if (contactType === "1") {
            if (validateInputs('string', firstName) === 'empty') {
                firstNameErr = 'Please enter first name';
                firstNameCls = error
                getError = true;
            } else if (validateInputs('string', firstName) === false) {
                firstNameErr = 'Please enter valid first name';
                firstNameCls = error
                getError = true;
            } else if (firstName.length > 50) {
                firstNameErr = 'Please enter maximum 50 characters';
                firstNameCls = error
                getError = true;
            }

            if (validateInputs('email', email) === false) {
                emailErr = 'Please enter valid email';
                emailCls = error
                getError = true;
            }
        } else {
            if (!(contactSelectValue && contactSelectValue.id)) {
                contactSelectErr = 'Please select contact';
                contactSelectCls = error
                getError = true;
            }
        }

        setState({
            ...state, titleErr, titleCls, dateCls, dateErr, addressErr, addressCls, meetingIdErr, meetingIdCls, passcodeErr, passcodeCls, toValueUniErr, toValueUniCls, fromValueUniErr, fromValueUniCls
        })
        setContactState({
            ...contactState, firstNameCls, emailCls, phoneCls, firstNameErr, emailErr, organizationErr, contactSelectErr, contactSelectCls
        })

        if (getError === false && emailErr === '' && firstNameErr === '' && addressErr === '' && titleErr === '' && dateErr === '') {
            setLoader(true)
            let getdate = moment(date).format("YYYY-MM-DD")
            let from = fromValue ? moment(fromValue).format("HH:mm:ss") : moment().format("00:00:00")
            let to = toValue ? moment(toValue).format("HH:mm:ss") : moment().format("00:00:00")
            let start_date = moment(getdate + " " + from);
            let end_date = moment(getdate + " " + to);

            if (appointmentData && appointmentData.id) {
                let appointmentFormData = {
                    name: name,
                    start_date: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
                    end_date: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
                    location: state.virtualEvent ? address : state.location,
                    detail: state.moreInformation,
                    new_contact: contactType,
                    lat_long: state.lat_long,
                    virtual_event: state.virtualEvent ? 1 : 0,
                    meeting_id: state.meetingId,
                    passcode: state.passcode,
                    id: appointmentData.id
                };

                if (contactType === '1') {
                    appointmentFormData.first_name = firstName
                    appointmentFormData.last_name = lastName
                    appointmentFormData.phone_type = phoneType
                    appointmentFormData.organization = organization
                    appointmentFormData.phone = phone
                    appointmentFormData.title = contactState.title
                    appointmentFormData.email = email

                } else {
                    if (contactSelectValue && contactSelectValue.id) {
                        appointmentFormData.contact_id = contactSelectValue.id;
                    }
                    if (associateLeadSelectValue && associateLeadSelectValue.id) {
                        appointmentFormData.lead_id = associateLeadSelectValue.id;
                    }
                }
                dispatch(updateAppointment(appointmentFormData))
            } else {
                let appointmentFormData = {
                    name: name,
                    start_date: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
                    end_date: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
                    location: state.virtualEvent ? address : state.location,
                    detail: state.moreInformation,
                    new_contact: appointmentData && appointmentData.id ? 0 : contactType,
                    lat_long: state.lat_long,
                    virtual_event: state.virtualEvent ? 1 : 0,
                    meeting_id: state.meetingId,
                    passcode: state.passcode,
                    id: appointmentData && appointmentData.id ? appointmentData.id : ''
                };

                if (contactType === '1') {
                    appointmentFormData.first_name = firstName
                    appointmentFormData.last_name = lastName
                    appointmentFormData.phone_type = phoneType
                    appointmentFormData.organization = organization
                    appointmentFormData.phone = phone
                    appointmentFormData.title = contactState.title
                    appointmentFormData.email = email

                } else {
                    if (contactSelectValue && contactSelectValue.id) {
                        appointmentFormData.contact_id = contactSelectValue.id;
                    }
                    if (associateLeadSelectValue && associateLeadSelectValue.id) {
                        appointmentFormData.lead_id = associateLeadSelectValue.id;
                    }
                }

                dispatch(createAppointment(appointmentFormData))
            }
        }
    }

    // Add Appointment Data Props
    useEffect(() => {
        if (prevCreateAppointmentData && prevCreateAppointmentData.createAppointmentData !== createAppointmentData) {
            if (createAppointmentData && _.has(createAppointmentData, "data") && createAppointmentData.success === true) {
                setAppointmentServiceMessage("");
                setAppointmentModalShow(false);
                getNewProp();
                setLoader(false);
            }
            if (createAppointmentData && _.has(createAppointmentData, "message") && createAppointmentData.success === false) {
                setAppointmentServiceMessage(createAppointmentData.message);
                setLoader(false);
            }
        }
        if (prevUpdateAppointmentData && prevUpdateAppointmentData.updateAppointmentData !== updateAppointmentData) {
            if (updateAppointmentData && _.has(updateAppointmentData, "data") && updateAppointmentData.success === true) {
                // setAppointmentServiceMessage("");
                setAppointmentModalShow(false)
                getNewProp();
                setLoader(false);
            }
            if (updateAppointmentData && _.has(updateAppointmentData, "message") && updateAppointmentData.success === false) {
                setAppointmentServiceMessage(updateAppointmentData.message);
                setLoader(false);
            }
        }
        if (prevDeleteAppointmentData && prevDeleteAppointmentData.deleteAppointmentData !== deleteAppointmentData) {
            if (deleteAppointmentData && _.has(deleteAppointmentData, 'data') && deleteAppointmentData.success === true) {
                setAppointmentModalShow(false);
                getNewProp();
            }
            if (deleteAppointmentData && _.has(deleteAppointmentData, 'message') && deleteAppointmentData.success === false) {
                setLoader(false)
                setAppointmentServiceMessage(updateAppointmentData.message);
                // errorPopUp(deleteAppointmentData.message)
            }
        }
    }, [createAppointmentData, prevCreateAppointmentData, updateAppointmentData, prevUpdateAppointmentData, deleteAppointmentData, prevDeleteAppointmentData]);// eslint-disable-line react-hooks/exhaustive-deps


    // Onload Empty Form 
    useEffect(() => {
        if (appointmentStatus) {
            setState({
                date: '', dateErr: '', dateCls: '', toValue: '', fromValue: '', title: '', titleErr: '', titleCls: '',
                correctInput: '', wrongInput: constants.WRONG_INPUT, address: '', location: '', locationErr: '', locationCls: '',
                lat_long: '', virtualEvent: false, meetingId: '', passcode: '', moreInformation: '', associateLeadSelectValue: '',
                associateLeadSelect: ''
            })
            setContactState({
                firstName: '', lastName: '', email: '', phone: '', organization: '', title: '', phoneType: { value: 'Mobile', label: 'Mobile' },
                firstNameCls: '', emailCls: '', phoneCls: '', firstNameErr: '', emailErr: '', phoneErr: '',
                correctInput: '', wrongInput: constants.WRONG_INPUT, contactType: '1',
                contactSelectValue: '', contactSelect: ''
            })
        }
        else if (appointmentData && appointmentData.id) {

            setLeaddata(initialValue);
            let data = [];
            if (appointmentData && appointmentData.contact_id) {
                const getLeadDataViaContact = async (contact_id) => {
                    if (contact_id) {
                        let listOption = await getAssociateLeadListOptionValue({ fields: 'id,name,potential_revenue', filter: '', contact_id: contact_id })
                        if (listOption && listOption.data && _.has(listOption.data, 'data') && listOption.success === true) {
                            data = listOption.data.data;
                            setLeaddata(data)
                        }
                    }
                }
                getLeadDataViaContact(appointmentData.contact_id)
            }
            setState({
                date: appointmentData && appointmentData.start_date ? moment(appointmentData.start_date).toDate() : '', dateErr: '', dateCls: '', toValue: appointmentData && appointmentData.end_date ? new Date(appointmentData.end_date) : '', fromValue: appointmentData && appointmentData.start_date ? new Date(appointmentData.start_date) : '', title: appointmentData && appointmentData.name ? appointmentData.name : '', titleErr: '', titleCls: '',
                correctInput: '', wrongInput: constants.WRONG_INPUT, address: appointmentData && appointmentData.location ? appointmentData.location : '', location: appointmentData && appointmentData.location ? appointmentData.location : '', locationErr: '', locationCls: '',
                lat_long: appointmentData && appointmentData.lat_long ? appointmentData.lat_long : "", virtualEvent: appointmentData && appointmentData.virtual_event ? appointmentData.virtual_event : false, meetingId: appointmentData && appointmentData.meeting_id ? appointmentData.meeting_id : '', passcode: appointmentData && appointmentData.passcode ? appointmentData.passcode : '', moreInformation: appointmentData && appointmentData.detail ? appointmentData.detail : '', associateLeadSelectValue: appointmentData && appointmentData.lead ? appointmentData.lead : '',
                associateLeadSelect: appointmentData && appointmentData.lead_id ? { name: appointmentData.name, id: appointmentData.lead_id } : ''
            })
            setContactState({
                firstName: appointmentData && appointmentData.first_name ? appointmentData.first_name : '', lastName: appointmentData && appointmentData.last_name ? appointmentData.last_name : '', email: appointmentData && appointmentData.email ? appointmentData.email : '', phone: appointmentData && appointmentData.phone ? appointmentData.phone : '', organization: appointmentData && appointmentData.organization ? appointmentData.organization : '', title: appointmentData && appointmentData.title ? appointmentData.title : '', phoneType: { value: 'Mobile', label: 'Mobile' },
                firstNameCls: '', emailCls: '', phoneCls: '', firstNameErr: '', emailErr: '', phoneErr: '',
                correctInput: '', wrongInput: constants.WRONG_INPUT, contactType: '0',
                contactSelectValue: appointmentData && appointmentData.contact_id ? { first_name: appointmentData.first_name, last_name: appointmentData.last_name, phone: appointmentData.phone, email: appointmentData.email, id: appointmentData.contact_id } : '', contactSelect: ''
            })

        }

    }, [appointmentModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

    // handle input change event
    const handleLeadInputChange = value => {
        setState({ ...state, associateLeadSelect: value })
    };

    // handle selection
    const handleLeadChange = e => {
        setState({ ...state, associateLeadSelectValue: { id: e.target.value } })
    }

    // load options using API call
    const loadLeadOptions = async () => {
        let data = [];
        //console.log(contactState.contactSelectValue.id)
        let listOption = await getAssociateLeadListOptionValue({ fields: 'id,name,potential_revenue', filter: state.associateLeadSelect, contact_id: contactState.contactSelectValue.id })
        if (listOption && listOption.data && _.has(listOption.data, 'data') && listOption.success === true) {
            data = listOption.data.data;

        }
        return data;
    };

    // Delete Booking Data
    const deleteContactFunction = (e) => {
        e.preventDefault();
        let msg = 'Are you sure you want to delete this appointment?';
        Swal.fire({
            title: 'Are you sure?',
            text: msg,
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showCloseButton: true,
            customClass: "mycustom-alert",
            cancelButtonClass: 'cancel-alert-note',
        }).then((result) => {
            if (result.value) {
                setLoader(true)
                const idData = [{
                    id: appointmentData.id,
                    type: "a"
                }]
                dispatch(deleteAppointment({ ids: JSON.stringify(idData) }))
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        })
    }

    return (
        <>
            <Modal show={appointmentModalShow} onHide={() => {
                setAppointmentModalShow(false)
            }} className="appoitment-form" size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {appointmentStatus ? "New Appointment" : "Edit Appointment"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {appointmentServiceMessage ? <div className="errorCls errCommonCls mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{appointmentServiceMessage}</div> : ''}
                    <form autoComplete="off">
                        <div className="row">
                            <div className="form-group col-md-9">
                                <div className={"floating-label"}>
                                    <input placeholder="Title/Subject *" type="text" name="title" value={state.title || ''} onChange={(e) => setInputValue(e, 'string', 50, null)} className="floating-input" />
                                    <label>Title/Subject *</label>
                                    {state.titleErr ? <span className="errorValidationMessage"> {state.titleErr}</span> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-4">

                                <div className={"floating-label" + state.dateCls}>
                                    <DatePicker
                                        type="text"
                                        name="date"
                                        className={state.dateCls ? "floating-input " + state.dateCls : "floating-input"}
                                        selected={state.date}
                                        onChange={(date) => dateForBooking(date)}
                                        minDate={moment().toDate()}
                                        placeholderText="Date *"
                                        ref={datepickerRef}

                                    />
                                    <div onClick={() => showTimeSelection()} className="input-calendar-icon"><img src={CALENDAR} alt="" width="20" height="20" /></div>
                                    {state.dateErr ? <span className="errorValidationMessage"> {state.dateErr}</span> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-4">
                                <div className={state.fromValueUniCls ? "floating-label my-custom-time-input " + state.fromValueUniCls : "floating-label my-custom-time-input"}>
                                    <DatePicker
                                        selected={state.fromValue}
                                        onChange={date => setState({ ...state, fromValue: date })}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        fixedHeight
                                        timeIntervals={30}
                                        dateFormat="h:mm a"
                                        className="floating-input"
                                        onKeyDown={e => e.preventDefault()}
                                        placeholderText="From *"
                                    />
                                    {state.fromValueUniErr ? <span className="errorValidationMessage"> {state.fromValueUniErr}</span> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-4">
                                <div className={state.toValueUniCls ? "floating-label my-custom-time-input " + state.toValueUniCls : "floating-label my-custom-time-input"}>
                                    <DatePicker
                                        selected={state.toValue}
                                        onChange={date => setState({ ...state, toValue: date })}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        fixedHeight
                                        timeIntervals={30}
                                        dateFormat="h:mm a"
                                        className="floating-input"
                                        onKeyDown={e => e.preventDefault()}
                                        placeholderText="To *"
                                    />
                                    {state.toValueUniErr ? <span className="errorValidationMessage"> {state.toValueUniErr}</span> : ''}
                                </div>
                            </div>
                            {state.virtualEvent ?
                                <div className="form-group col-md-8">
                                    <div className={state.addressCls ? "floating-label " + state.addressCls : "floating-label"}>
                                        <input placeholder="Zoom, Google, WebEx, etc. link" name="address" value={state.address || ""}
                                            onChange={(e) => { setState({ ...state, address: e.target.value, addressErr: '', addressCls: '' }) }} className="floating-input" />
                                        <label>Location *</label>
                                        {state.addressErr ? <span className="errorValidationMessage"> {state.addressErr}</span> : ''}
                                    </div>
                                </div>
                                :
                                <div className="form-group col-md-8">
                                    <PlacesAutocomplete
                                        value={state.location}
                                        onChange={handleChangeGoogle}
                                        onSelect={handleSelectGoogle}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="floating-label " >
                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Location (Address or Name)',
                                                        className: 'location-search-input floating-input',
                                                    })}
                                                />
                                                <label>Location (Address or Name)</label>
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map((suggestion, key) => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                                key={key}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>}
                            <div className="form-group col-md-4">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" checked={state.virtualEvent} onChange={(e) => setState({ ...state, virtualEvent: e.target.checked, address: '', lat_long: '' })} className="custom-control-input" id="customCheck1" />
                                    <label className="custom-control-label mt-0" htmlFor="customCheck1"><strong>Virtual Event</strong></label>
                                </div>
                            </div>
                            {state.virtualEvent ?
                                <>
                                    <div className="form-group col-md-4">
                                        <div className={state.meetingIdCls ? "floating-label " + state.meetingIdCls : "floating-label"}>
                                            <input placeholder="Meeting Id" name="meetingId" value={state.meetingId || ""}
                                                onChange={(e) => { setState({ ...state, meetingId: e.target.value }) }} className="floating-input" />
                                            <label>Meeting Id</label>
                                            {state.meetingIdErr ? <span className="errorValidationMessage"> {state.meetingIdErr}</span> : ''}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <div className={state.passcodeCls ? "floating-label " + state.passcodeCls : "floating-label"}>
                                            <input placeholder="Passcode" name="passcode" value={state.passcode || ""}
                                                onChange={(e) => { setState({ ...state, passcode: e.target.value }) }} className="floating-input" />
                                            <label>Passcode</label>
                                            {state.passcodeErr ? <span className="errorValidationMessage"> {state.passcodeErr}</span> : ''}
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                        <div className="row mb-3">
                            <div className="form-group col-md-12">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline3" onChange={() => setContactState({ ...contactState, contactType: '1' })} checked={contactState.contactType === '1' ? true : false} name="contactType" className="custom-control-input" value="1" />
                                    <label className="custom-control-label" htmlFor="customRadioInline3">Create New</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline4" onChange={() => setContactState({ ...contactState, contactType: '0' })} checked={contactState.contactType === '0' ? true : false} name="contactType" className="custom-control-input" value="0" />
                                    <label className="custom-control-label" htmlFor="customRadioInline4">Select Existing</label>
                                </div>
                            </div>
                        </div>
                        {contactState.contactType === '1'
                            ?
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div className="floating-label">
                                        <input placeholder="First Name *" type="text" name="firstName" value={contactState.firstName || ''} onChange={(e) => setContactValue(e, 'string', 50, null)} className="floating-input" />
                                        <label>First Name *</label>
                                        {contactState.firstNameErr ? <span className="errorValidationMessage"> {contactState.firstNameErr}</span> : ''}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="floating-label">
                                        <input placeholder="Last Name" type="text" name="lastName" value={contactState.lastName || ''} onChange={(e) => { setContactState({ ...contactState, lastName: e.target.value }); setAppointmentServiceMessage('') }} className="floating-input" />
                                        <label>Last Name</label>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="form-row">
                                        <div className="col-5">
                                            <div className="floating-label phone-type">
                                                <Select
                                                    styles={selectStyle}
                                                    className="floating-select"
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    value={contactState.phoneType}
                                                    isSearchable={false}
                                                    defaultValue={contactState.phoneType}
                                                    options={phoneTypeOptions}
                                                    placeholder=""
                                                    onChange={data => { setContactState({ ...contactState, phoneType: data }); setAppointmentServiceMessage('') }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="floating-label">
                                                <input placeholder="000-000-0000" type="text" name="phone" value={contactState.phone || ''} onChange={(e) => { setContactState({ ...contactState, phone: e.target.value }); setAppointmentServiceMessage('') }} className="floating-input" />
                                                <label>Phone Number</label>
                                                {/* {contactState.phoneErr ? <span className="errorValidationMessage"> {contactState.phoneErr}</span> : ''} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="floating-label">
                                        <input placeholder="Email Address" type="email" name="email" value={contactState.email || ''} onChange={(e) => { setContactState({ ...contactState, email: e.target.value, emailCls: '', emailErr: '' }); setAppointmentServiceMessage('') }} className="floating-input" />
                                        <label>Email Address</label>
                                        {contactState.emailErr ? <span className="errorValidationMessage"> {contactState.emailErr}</span> : ''}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="floating-label">
                                        <input placeholder="Organization Name" type="text" name="organization" value={contactState.organization || ''} onChange={(e) => { setContactState({ ...contactState, organization: e.target.value }); setAppointmentServiceMessage('') }} className="floating-input" />
                                        <label>Organization Name</label>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="floating-label">
                                        <input placeholder="Title" type="text" name="title" value={contactState.title || ''} onChange={(e) => { setContactState({ ...contactState, title: e.target.value }); setAppointmentServiceMessage('') }} className="floating-input" />
                                        <label>Title</label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="floating-label">
                                        <textarea placeholder="Add any additional information or notes here.." className="floating-input" id="exampleFormControlTextarea1"
                                            name="moreInformation" value={state.moreInformation || ''} onChange={(e) => { setState({ ...state, moreInformation: e.target.value }); setAppointmentServiceMessage('') }} rows="5"></textarea>
                                        <label>More information</label>
                                    </div>
                                </div>
                            </div>
                            :
                            appointmentStatus && contactData ?
                                <>
                                    <div className="form-group col-12">
                                        <div className="form-group">
                                            {contactData && contactData.firstName !== null ? <div className="field-text">{contactData.firstName + " " + contactData.lastName}</div> : ''}
                                            <div className="field-text">{contactData && contactData.phone}</div>
                                            <div className="field-text"><a href="#lead" onClick={(e) => e.preventDefault()}>{contactData && contactData.email}</a></div>
                                            <div className="field-text">{contactData && contactData.organization}</div>
                                            <div className="field-text">{contactData && contactData.title ? <small>({contactData.title})</small> : ''}</div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 mb-lg-2">
                                        {/*  <div className="floating-label">
                                <AsyncSelect
                                    className="floating-input"
                                    styles={selectStyle}
                                    onFocus={e => {
                                        if (e.target.autocomplete) {
                                            e.target.autocomplete = "nope";
                                        }
                                    }}
                                    isClearable
                                    placeholder="Select Lead - Optional"
                                    value={state.associateLeadSelectValue}
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e.id}
                                    loadOptions={(e) => loadLeadOptions(e)}
                                    onInputChange={(e) => handleLeadInputChange(e)}
                                    onChange={(e) => handleLeadChange(e)}
                                />

                                <label>Related To</label>
                            </div> */}
                                        <div className="floating-label leadoptionselect">
                                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                                            <select className="floating-input"
                                                value={state.associateLeadSelectValue.id}
                                                onChange={(e) => handleLeadChange(e)}
                                            >
                                                <option value="">Select Lead - Optional</option>
                                                {leadData.map((lead, index) => {
                                                    return <option value={lead.id}>{lead.name}</option>;
                                                })}s
                                            </select>
                                            <label>Related To</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <div className="floating-label">
                                            <textarea placeholder="Add any additional information or notes here.." className="floating-input" id="exampleFormControlTextarea1"
                                                name="moreInformation" value={state.moreInformation || ''} onChange={(e) => { setState({ ...state, moreInformation: e.target.value }); setAppointmentServiceMessage('') }} rows="5"></textarea>
                                            <label>More information</label>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <div className={"floating-label " + contactState.contactSelectCls}>
                                            <AsyncSelect
                                                className="floating-input"
                                                styles={selectStyle}
                                                onFocus={e => {
                                                    if (e.target.autocomplete) {
                                                        e.target.autocomplete = "nope";
                                                    }
                                                }}
                                                isClearable
                                                placeholder=""
                                                noOptionsMessage={() => "No results found"}
                                                value={contactState.contactSelectValue}
                                                getOptionLabel={e => e.first_name + (e && e.last_name ? " " + e.last_name : '')}
                                                getOptionValue={e => e.id}
                                                loadOptions={(e) => loadOptions(e)}
                                                onInputChange={(e) => handleInputChange(e)}
                                                onChange={(e) => referHandleChange(e)}
                                            />
                                            <label>Type here to search</label>
                                            {contactState.contactSelectErr ? <span className="errorValidationMessage"> {contactState.contactSelectErr}</span> : ''}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div className="form-group">
                                            {contactState.contactSelectValue && contactState.contactSelectValue.phone !== null ? <div className="field-text">{contactState.contactSelectValue.phone}</div> : ''}
                                            <div className="field-text"><a href="#lead" onClick={(e) => e.preventDefault()}>{contactState.contactSelectValue && contactState.contactSelectValue.email}</a></div>
                                            <div className="field-text">{contactState.contactSelectValue && contactState.contactSelectValue.organization}</div>
                                            <div className="field-text">{contactState.contactSelectValue && contactState.contactSelectValue.title ? <small>({contactState.contactSelectValue.title})</small> : ''}</div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        {/*                                     <div className="floating-label">
                                        <AsyncSelect
                                            className="floating-input"
                                            styles={selectStyle}
                                            onFocus={e => {
                                                if (e.target.autocomplete) {
                                                    e.target.autocomplete = "nope";
                                                }
                                            }}
                                            isClearable
                                            placeholder="Select Lead - Optional"
                                            value={state.associateLeadSelectValue}
                                            getOptionLabel={e => e.name}
                                            getOptionValue={e => e.id}
                                            loadOptions={(e) => loadLeadOptions(e)}
                                            onInputChange={(e) => handleLeadInputChange(e)}
                                            onChange={(e) => handleLeadChange(e)}
                                        />
                                        <label>Related To</label>
                                    </div> */}
                                        <div className="floating-label leadoptionselect ">
                                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                                            <select className="floating-input"
                                                value={state.associateLeadSelectValue.id}
                                                onChange={(e) => handleLeadChange(e)}
                                            >
                                                <option value="">Select Lead - Optional</option>
                                                {leadData.map((lead, index) => {
                                                    return <option key={lead.id} value={lead.id}>{lead.name}</option>;
                                                })}
                                            </select>
                                            <label>Related To</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <div className="floating-label">
                                            <textarea placeholder="Add any additional information or notes here.." className="floating-input" id="exampleFormControlTextarea1"
                                                name="moreInformation" value={state.moreInformation || ''} onChange={(e) => { setState({ ...state, moreInformation: e.target.value }); setAppointmentServiceMessage('') }} rows="5"></textarea>
                                            <label>More information</label>
                                        </div>
                                    </div>
                                </div>}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {appointmentStatus ? "" : <button type="button" href="#google" onClick={(e) => deleteContactFunction(e)} className="btn btn-danger">Delete</button>}
                    <button type="button" className="btn btn-dark" onClick={() => setAppointmentModalShow(false)}>Cancel</button>
                    <button type="button" onClick={() => saveAppointmentData()} className="btn btn-primary">{appointmentStatus ? "Create Appointment" : "Save"}</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export const CreateAppointment = withRouter(NewCreateAppointment)