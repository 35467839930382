// Get Outlook Auth Link
export const GET_OUTLOOK_AUTH_LINK = 'GET_OUTLOOK_AUTH_LINK';
export const getOutlookAuthLink = (data) => ({ type: GET_OUTLOOK_AUTH_LINK, data });
export const SUCCESS_GET_OUTLOOK_AUTH_LINK = 'SUCCESS_GET_OUTLOOK_AUTH_LINK';
export const ERROR_GET_OUTLOOK_AUTH_LINK = 'ERROR_GET_OUTLOOK_AUTH_LINK';
export const getOutlookAuthLinkResponse = (type, data) => ({ type, data });

// Get Outlook Auth Token
export const GET_OUTLOOK_AUTH_TOKEN = 'GET_OUTLOOK_AUTH_TOKEN';
export const getOutlookAuthToken= (data) => ({ type: GET_OUTLOOK_AUTH_TOKEN, data });
export const SUCCESS_GET_OUTLOOK_AUTH_TOKEN = 'SUCCESS_GET_OUTLOOK_AUTH_TOKEN';
export const ERROR_GET_OUTLOOK_AUTH_TOKEN = 'ERROR_GET_OUTLOOK_AUTH_TOKEN';
export const getOutlookAuthTokenResponse = (type, data) => ({ type, data });

// Get All Outlook MailFolders
export const GET_ALL_OUTLOOK_MAILFOLDERS = 'GET_ALL_OUTLOOK_MAILFOLDERS';
export const getAllOutlookMailFolders = (data) => ({ type: GET_ALL_OUTLOOK_MAILFOLDERS, data });
export const SUCCESS_GET_ALL_OUTLOOK_MAILFOLDERS = 'SUCCESS_GET_ALL_OUTLOOK_MAILFOLDERS';
export const ERROR_GET_ALL_OUTLOOK_MAILFOLDERS = 'ERROR_GET_ALL_OUTLOOK_MAILFOLDERS';
export const getAllOutlookMailFoldersResponse = (type, data) => ({ type, data });

// Get All Outlook Messages
export const GET_ALL_OUTLOOK_MESSAGES = 'GET_ALL_OUTLOOK_MESSAGES';
export const getAllOutlookMessages = (data) => ({ type: GET_ALL_OUTLOOK_MESSAGES, data });
export const SUCCESS_GET_ALL_OUTLOOK_MESSAGES = 'SUCCESS_GET_ALL_OUTLOOK_MESSAGES';
export const ERROR_GET_ALL_OUTLOOK_MESSAGES = 'ERROR_GET_ALL_OUTLOOK_MESSAGES';
export const getAllOutlookMessagesResponse = (type, data) => ({ type, data });

// Get Outlook Message By Id
export const GET_OUTLOOK_MESSAGE_BY_ID = 'GET_OUTLOOK_MESSAGE_BY_ID';
export const getOutlookMessageById = (data) => ({ type: GET_OUTLOOK_MESSAGE_BY_ID, data });
export const SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID = 'SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID';
export const ERROR_GET_OUTLOOK_MESSAGE_BY_ID = 'ERROR_GET_OUTLOOK_MESSAGE_BY_ID';
export const getOutlookMessageByIdResponse = (type, data) => ({ type, data });

// Get Outlook Message By Id Attachments
export const GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS = 'GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS';
export const getOutlookMessageByIdAttachments = (data) => ({ type: GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS, data });
export const SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS = 'SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS';
export const ERROR_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS = 'ERROR_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS';
export const getOutlookMessageByIdAttachmentsResponse = (type, data) => ({ type, data });

// Send Reply to Outlook Message By Id
export const SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID = 'SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID';
export const sendReplyToOutlookMessageById = (data) => ({ type: SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID, data });
export const SUCCESS_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID = 'SUCCESS_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID';
export const ERROR_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID = 'ERROR_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID';
export const sendReplyToOutlookMessageByIdResponse = (type, data) => ({ type, data });

// Send Reply to All Outlook Message By Id
export const SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID = 'SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID';
export const sendReplyToAllOutlookMessageById = (data) => ({ type: SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID, data });
export const SUCCESS_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID = 'SUCCESS_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID';
export const ERROR_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID = 'ERROR_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID';
export const sendReplyToAllOutlookMessageByIdResponse = (type, data) => ({ type, data });

// Send Forward Outlook Message By Id
export const SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID = 'SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID';
export const sendForwardOutlookMessageById = (data) => ({ type: SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID, data });
export const SUCCESS_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID = 'SUCCESS_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID';
export const ERROR_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID = 'ERROR_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID';
export const sendForwardOutlookMessageByIdResponse = (type, data) => ({ type, data });

// Send Outlook Message
export const SEND_OUTLOOK_MESSAGE = 'SEND_OUTLOOK_MESSAGE';
export const sendOutlookMessage = (data) => ({ type: SEND_OUTLOOK_MESSAGE, data });
export const SUCCESS_SEND_OUTLOOK_MESSAGE = 'SUCCESS_SEND_OUTLOOK_MESSAGE';
export const ERROR_SEND_OUTLOOK_MESSAGE = 'ERROR_SEND_OUTLOOK_MESSAGE';
export const sendOutlookMessageResponse = (type, data) => ({ type, data });

// Create Draft Outlook Message
export const CREATE_OUTLOOK_DRAFT_MESSAGE = 'CREATE_OUTLOOK_DRAFT_MESSAGE';
export const createOutlookDraftMessage = (data) => ({ type: CREATE_OUTLOOK_DRAFT_MESSAGE, data });
export const SUCCESS_CREATE_OUTLOOK_DRAFT_MESSAGE = 'SUCCESS_CREATE_OUTLOOK_DRAFT_MESSAGE';
export const ERROR_CREATE_OUTLOOK_DRAFT_MESSAGE = 'ERROR_CREATE_OUTLOOK_DRAFT_MESSAGE';
export const createOutlookDraftMessageResponse = (type, data) => ({ type, data });

// Update Outlook Message
export const UPDATE_OUTLOOK_MESSAGE = 'UPDATE_OUTLOOK_MESSAGE';
export const updateOutlookMessage = (data) => ({ type: UPDATE_OUTLOOK_MESSAGE, data });
export const SUCCESS_UPDATE_OUTLOOK_MESSAGE = 'SUCCESS_UPDATE_OUTLOOK_MESSAGE';
export const ERROR_UPDATE_OUTLOOK_MESSAGE = 'ERROR_UPDATE_OUTLOOK_MESSAGE';
export const updateOutlookMessageResponse = (type, data) => ({ type, data });

// Delete Outlook Message
export const DELETE_OUTLOOK_MESSAGE = 'DELETE_OUTLOOK_MESSAGE';
export const deleteOutlookMessage = (data) => ({ type: DELETE_OUTLOOK_MESSAGE, data });
export const SUCCESS_DELETE_OUTLOOK_MESSAGE = 'SUCCESS_DELETE_OUTLOOK_MESSAGE';
export const ERROR_DELETE_OUTLOOK_MESSAGE = 'ERROR_DELETE_OUTLOOK_MESSAGE';
export const deleteOutlookMessageResponse = (type, data) => ({ type, data });

// Move Outlook Message
export const MOVE_OUTLOOK_MESSAGE = 'MOVE_OUTLOOK_MESSAGE';
export const moveOutlookMessage = (data) => ({ type: MOVE_OUTLOOK_MESSAGE, data });
export const SUCCESS_MOVE_OUTLOOK_MESSAGE = 'SUCCESS_MOVE_OUTLOOK_MESSAGE';
export const ERROR_MOVE_OUTLOOK_MESSAGE = 'ERROR_MOVE_OUTLOOK_MESSAGE';
export const moveOutlookMessageResponse = (type, data) => ({ type, data });

// Mark as read Message
export const MARK_AS_READ_OUTLOOK_MESSAGE = 'MARK_AS_READ_OUTLOOK_MESSAGE';
export const markAsReadOutlookMessage = (data) => ({ type: MARK_AS_READ_OUTLOOK_MESSAGE, data });
export const SUCCESS_MARK_AS_READ_OUTLOOK_MESSAGE = 'SUCCESS_MARK_AS_READ_OUTLOOK_MESSAGE';
export const ERROR_MARK_AS_READ_OUTLOOK_MESSAGE = 'ERROR_MARK_AS_READ_OUTLOOK_MESSAGE';
export const markAsReadOutlookMessageResponse = (type, data) => ({ type, data });

// Mark as unread Message
export const MARK_AS_UNREAD_OUTLOOK_MESSAGE = 'MARK_AS_UNREAD_OUTLOOK_MESSAGE';
export const markAsUnreadOutlookMessage = (data) => ({ type: MARK_AS_UNREAD_OUTLOOK_MESSAGE, data });
export const SUCCESS_MARK_AS_UNREAD_OUTLOOK_MESSAGE = 'SUCCESS_MARK_AS_UNREAD_OUTLOOK_MESSAGE';
export const ERROR_MARK_AS_UNREAD_OUTLOOK_MESSAGE = 'ERROR_MARK_AS_UNREAD_OUTLOOK_MESSAGE';
export const markAsUnreadOutlookMessageResponse = (type, data) => ({ type, data });

// Reset Microsoft State
export const RESET_MICROSOFT_STATE = 'RESET_MICROSOFT_STATE';
export const resetMicrosoftState = (data) => ({ type: RESET_MICROSOFT_STATE, data });