import {
  SUCCESS_ADD_BOOKING, ERROR_ADD_BOOKING,
  SUCCESS_LIST_BOOKINGS, ERROR_LIST_BOOKINGS,
  SUCCESS_DELETE_BOOKING, ERROR_DELETE_BOOKING,
  SUCCESS_GET_BOOKING_BY_ID, ERROR_GET_BOOKING_BY_ID,
  SUCCESS_UPDATE_BOOKING, ERROR_UPDATE_BOOKING,
  SUCCESS_ADD_BOOKING_NOTE, ERROR_ADD_BOOKING_NOTE,
  SUCCESS_LIST_BOOKING_NOTE, ERROR_LIST_BOOKING_NOTE,
  SUCCESS_DELETE_BOOKING_NOTE, ERROR_DELETE_BOOKING_NOTE,
  SUCCESS_UPDATE_BOOKING_NOTE, ERROR_UPDATE_BOOKING_NOTE,
  SUCCESS_ADD_BOOKING_TASK, ERROR_ADD_BOOKING_TASK,
  SUCCESS_LIST_BOOKING_TASK, ERROR_LIST_BOOKING_TASK,
  SUCCESS_DELETE_BOOKING_TASK, ERROR_DELETE_BOOKING_TASK,
  SUCCESS_UPDATE_BOOKING_TASK, ERROR_UPDATE_BOOKING_TASK,
  SUCCESS_CREATE_APPOINTMENT, ERROR_CREATE_APPOINTMENT,
  SUCCESS_BOOKING_LIST_VIEW, ERROR_BOOKING_LIST_VIEW,
  SUCCESS_DELETE_APPOINTMENT, ERROR_DELETE_APPOINTMENT,
  SUCCESS_UPDATE_APPOINTMENT, ERROR_UPDATE_APPOINTMENT, SUCCESS_CREATE_BOOKING_PAYMENT, ERROR_CREATE_BOOKING_PAYMENT,IS_FROM_BOOKING_LIST
} from './booking.action';
import { DEFAULT_STATE } from './booking.state';

export const bookingReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
  switch (action.type) {
    case SUCCESS_ADD_BOOKING:
      const addBookingData = action.data;
      return { ...state, addBookingData }
    case ERROR_ADD_BOOKING:
      const errorAddBookingData = action.data;
      return { ...state, addBookingData: errorAddBookingData }
    case SUCCESS_LIST_BOOKINGS:
      const listBookingData = action.data;
      return { ...state, listBookingData }
    case ERROR_LIST_BOOKINGS:
      const errorListBookingsData = action.data;
      return { ...state, listBookingData: errorListBookingsData }
    case SUCCESS_DELETE_BOOKING:
      const deleteBookingData = action.data;
      return { ...state, deleteBookingData }
    case ERROR_DELETE_BOOKING:
      const errorDeleteBookingData = action.data;
      return { ...state, deleteBookingData: errorDeleteBookingData }
    case SUCCESS_GET_BOOKING_BY_ID:
      const getBookingByIdData = action.data;
      return { ...state, getBookingByIdData }
    case ERROR_GET_BOOKING_BY_ID:
      const errorGetBookingByIdData = action.data;
      return { ...state, getBookingByIdData: errorGetBookingByIdData }
    case SUCCESS_UPDATE_BOOKING:
      const updateBookingData = action.data;
      return { ...state, addBookingData: updateBookingData }
    case ERROR_UPDATE_BOOKING:
      const errorUpdateBookingData = action.data;
      return { ...state, addBookingData: errorUpdateBookingData }
    case SUCCESS_ADD_BOOKING_NOTE:
      const addBookingNoteData = action.data;
      return { ...state, addBookingNoteData }
    case ERROR_ADD_BOOKING_NOTE:
      const errorAddBookingNoteData = action.data;
      return { ...state, addBookingNoteData: errorAddBookingNoteData }
    case SUCCESS_LIST_BOOKING_NOTE:
      const listBookingNoteData = action.data;
      return { ...state, listBookingNoteData }
    case ERROR_LIST_BOOKING_NOTE:
      const errorListBookingNoteData = action.data;
      return { ...state, listBookingNoteData: errorListBookingNoteData }
    case SUCCESS_DELETE_BOOKING_NOTE:
      const deleteBookingNoteData = action.data;
      return { ...state, deleteBookingNoteData }
    case ERROR_DELETE_BOOKING_NOTE:
      const errorDeleteBookingNoteData = action.data;
      return { ...state, deleteBookingNoteData: errorDeleteBookingNoteData }
    case SUCCESS_UPDATE_BOOKING_NOTE:
      const updateBookingNoteData = action.data;
      return { ...state, addBookingNoteData: updateBookingNoteData }
    case ERROR_UPDATE_BOOKING_NOTE:
      const errorUpdateBookingNoteData = action.data;
      return { ...state, addBookingNoteData: errorUpdateBookingNoteData }
    case SUCCESS_ADD_BOOKING_TASK:
      const addBookingTaskData = action.data;
      return { ...state, addBookingTaskData }
    case ERROR_ADD_BOOKING_TASK:
      const errorAddBookingTaskData = action.data;
      return { ...state, addBookingTaskData: errorAddBookingTaskData }
    case SUCCESS_LIST_BOOKING_TASK:
      const listBookingTaskData = action.data;
      return { ...state, listBookingTaskData }
    case ERROR_LIST_BOOKING_TASK:
      const errorBookingListTaskData = action.data;
      return { ...state, listBookingTaskData: errorBookingListTaskData }
    case SUCCESS_DELETE_BOOKING_TASK:
      const deleteBookingTaskData = action.data;
      return { ...state, deleteBookingTaskData }
    case ERROR_DELETE_BOOKING_TASK:
      const errorDeleteBookingTaskData = action.data;
      return { ...state, deleteBookingTaskData: errorDeleteBookingTaskData }
    case SUCCESS_UPDATE_BOOKING_TASK:
      const updateBookingTaskData = action.data;
      return { ...state, addBookingTaskData: updateBookingTaskData }
    case ERROR_UPDATE_BOOKING_TASK:
      const errorUpdateBookingTaskData = action.data;
      return { ...state, addBookingTaskData: errorUpdateBookingTaskData }
    case SUCCESS_CREATE_APPOINTMENT:
      const createAppointmentData = action.data;
      return { ...state, createAppointmentData }
    case ERROR_CREATE_APPOINTMENT:
      const errorCreateAppointmentData = action.data;
      return { ...state, createAppointmentData: errorCreateAppointmentData }
    case SUCCESS_BOOKING_LIST_VIEW:
      const bookingListViewData = action.data;
      return { ...state, bookingListViewData }
    case ERROR_BOOKING_LIST_VIEW:
      const errorBookingListViewData = action.data;
      return { ...state, bookingListViewData: errorBookingListViewData }
    case SUCCESS_DELETE_APPOINTMENT:
      const deleteAppointmentData = action.data;
      return { ...state, deleteAppointmentData }
    case ERROR_DELETE_APPOINTMENT:
      const errorDeleteAppointmentData = action.data;
      return { ...state, deleteAppointmentData: errorDeleteAppointmentData }
    case SUCCESS_UPDATE_APPOINTMENT:
      const updateAppointmentData = action.data;
      return { ...state, updateAppointmentData }
    case ERROR_UPDATE_APPOINTMENT:
      const errorUpdateAppointmentData = action.data;
      return { ...state, updateAppointmentData: errorUpdateAppointmentData }

    case SUCCESS_CREATE_BOOKING_PAYMENT:
      const createBookingPaymentData = action.data;
      return { ...state, createBookingPaymentData }
    case ERROR_CREATE_BOOKING_PAYMENT:
      const errorCreateBookingPaymentData = action.data;
      return { ...state, createBookingPaymentData: errorCreateBookingPaymentData }
    case IS_FROM_BOOKING_LIST:
      const IS_FROM_BOOKING_LIST_DATA = action.data;
      return { ...state, isFrombookingList: IS_FROM_BOOKING_LIST_DATA }
    default:
      return state;
  }
};