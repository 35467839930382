// Update Google Label on Click
export const UPDATE_GOOGLE_LABEL = 'UPDATE_GOOGLE_LABEL';
export const updateGoogleLabel = (data) => ({ type: UPDATE_GOOGLE_LABEL, data });
// Update Microsoft Label on Click
export const UPDATE_MICROSOFT_LABEL = 'UPDATE_MICROSOFT_LABEL';
export const updateMicrosoftLabel = (data) => ({ type: UPDATE_MICROSOFT_LABEL, data });
// Update Accordion Label on Click
export const UPDATE_ACCORDION_LABEL = 'UPDATE_ACCORDION_LABEL';
export const updateAccordionLabel = (data) => ({ type: UPDATE_ACCORDION_LABEL, data });
// Reset Accordion State
export const RESET_ACCORDION_STATE = 'RESET_ACCORDION_STATE';
export const resetAccordionState = (data) => ({ type: RESET_ACCORDION_STATE, data });
// Reset Email State
export const RESET_STATE = 'RESET_STATE';
export const resetAllState = (data) => ({ type: RESET_STATE, data });