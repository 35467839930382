import {
  CATEGORY_LIST,
  CATEGORY_CREATE,
  EXPENSE_LIST,
  EXPENSE_CREATE,
  EXPENSE_UPDATE,
  EXPENSE_DELETE,
  EXPENSE_DOWNLOAD,
} from '../routing/route';
import { request } from '../request/axios.request'

// Category List
export async function categoryListApi(data) {
  let categoryListData = data.data;
  return request({ url: CATEGORY_LIST, method: 'post', data: categoryListData })
}

// Category Create
export async function categoryCreateApi(data) {
  let categoryCreateteData = data.data;
  return request({ url: CATEGORY_CREATE, method: 'post', data: categoryCreateteData })
}

// Expenses List
export async function expenseListApi(data) {
  let expenseListData = data.data;
  return request({ url: EXPENSE_LIST, method: 'post', data: expenseListData })
}

// Expenses Create
export async function expenseCreateApi(data) {
  let expenseCreateData = data.data;
  return request({ url: EXPENSE_CREATE, method: 'post', data: expenseCreateData })
}

// Expense Update
export async function expenseUpdateApi(data) {
  let expenseUpdateData = data.data;
  return request({ url: EXPENSE_UPDATE, method: 'post', data: expenseUpdateData })
}

// Expenses Delete
export async function expenseDeleteApi(data) {
  let expenseDeleteData = data.data;
  return request({ url: EXPENSE_DELETE, method: 'post', data: expenseDeleteData })
}

// Expenses Download
export async function expenseDownloadApi(data) {
  let expenseDownloadData = data.data;
  return request({ url: EXPENSE_DOWNLOAD, method: 'get', params: expenseDownloadData, responseType: "blob" })
}
