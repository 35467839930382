export const DEFAULT_STATE = {
  addServiceData: {},
  listServiceWithSourceData: {},
  addSourceData: {},
  listLeadStatusData: {},
  addLeadData: {},
  getLeadByIdData: {},
  deleteLeadData: {},
  addLeadNoteData: {},
  listLeadNoteData: {},
  deleteLeadNoteData: {},
  listLeadData: {},
  addleadTaskData: {},
  listleadTaskData: {},
  deleteleadTaskData: {},
  updateLeadStatusData: {},
  listLeadWithPositionData: {},
  addLostReasonData: {},
  listLostReasonData: {},
  markLeadStatusData: {},
  getAllCompletedLeadData: {},
  customizeLeadStageData: {},
  getAllGmailMessagesIdByEmail: {},
  getGmailMessagesDataByEmail: [],
  emailTokenErrorData: {},
  leadEmailLoading: true,
};