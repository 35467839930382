import { put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  getAllGmailMessagesResponse,
  GET_ALL_GMAIL_MESSAGES,
  SUCCESS_GET_ALL_GMAIL_MESSAGES,
  ERROR_GET_ALL_GMAIL_MESSAGES,
  getGmailMessageResponse,
  GET_GMAIL_MESSAGE,
  SUCCESS_GET_GMAIL_MESSAGE,
  ERROR_GET_GMAIL_MESSAGE,
  RESET_EMAIL_STATE,
  SUCCESS_RESET_EMAIL_STATE,
  ERROR_RESET_EMAIL_STATE,
  resetEmailStateResponse,
  DELETE_GMAIL_MESSAGE,
  SUCCESS_DELETE_GMAIL_MESSAGE,
  ERROR_DELETE_GMAIL_MESSAGE,
  deleteGmailMessageResponse,
  GET_GMAIL_MESSAGE_BY_ID,
  SUCCESS_GET_GMAIL_MESSAGE_BY_ID,
  ERROR_GET_GMAIL_MESSAGE_BY_ID,
  getGmailMessageByIdResponse,
  SEND_GMAIL_MESSAGE,
  SUCCESS_SEND_GMAIL_MESSAGE,
  ERROR_SEND_GMAIL_MESSAGE,
  sendGmailMessageResponse,
  getGmailMessageAttachmentByIdResponse,
  GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
  SUCCESS_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
  ERROR_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
  SUCCESS_MODIFY_GMAIL_MESSAGE,
  ERROR_MODIFY_GMAIL_MESSAGE,
  MODIFY_GMAIL_MESSAGE,
  modifyGmailMessageResponse,
  sendGmailMessageWithAttachmentResponse,
  SUCCESS_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
  ERROR_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
  SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
  getGmailAuthLinkResponse,
  GET_GMAIL_AUTH_LINK,
  SUCCESS_GET_GMAIL_AUTH_LINK,
  ERROR_GET_GMAIL_AUTH_LINK,
  getGmailAuthTokenResponse,
  GET_GMAIL_AUTH_TOKEN,
  SUCCESS_GET_GMAIL_AUTH_TOKEN,
  ERROR_GET_GMAIL_AUTH_TOKEN,
  getGmailValidTokenResponse,
  SUCCESS_GET_GMAIL_VALID_TOKEN,
  ERROR_GET_GMAIL_VALID_TOKEN,
  GET_GMAIL_VALID_TOKEN,
  SET_GMAIL_MESSAGES,
  SUCCESS_SET_GMAIL_MESSAGE,
  ERROR_SET_GMAIL_MESSAGE,
  setGmailMessageResponse,
  createDraftGmailMessageResponse,
  CREATE_DRAFT_GMAIL_MESSAGE,
  SUCCESS_CREATE_DRAFT_GMAIL_MESSAGE,
  ERROR_CREATE_DRAFT_GMAIL_MESSAGE,
  deleteDraftGmailMessageResponse,
  SUCCESS_DELETE_DRAFT_GMAIL_MESSAGE,
  ERROR_DELETE_DRAFT_GMAIL_MESSAGE,
  DELETE_DRAFT_GMAIL_MESSAGE,
  GET_ALL_DRAFT_GMAIL_MESSAGES,
  SUCCESS_GET_ALL_DRAFT_GMAIL_MESSAGES,
  ERROR_GET_ALL_DRAFT_GMAIL_MESSAGES,
  getAllDraftGmailMessagesResponse,
  GET_DRAFT_GMAIL_MESSAGE_BY_ID,
  SUCCESS_GET_DRAFT_GMAIL_MESSAGE_BY_ID,
  ERROR_GET_DRAFT_GMAIL_MESSAGE_BY_ID,
  getDraftGmailMessageByIdResponse,
  getDraftGmailMessageResponse,
  GET_DRAFT_GMAIL_MESSAGE,
  SUCCESS_GET_DRAFT_GMAIL_MESSAGE,
  ERROR_GET_DRAFT_GMAIL_MESSAGE,
  markGmailMessageAsSpamResponse,
  MARK_AS_SPAM,
  SUCCESS_MARK_AS_SPAM,
  ERROR_MARK_AS_SPAM,
  MARK_AS_TRASH,
  SUCCESS_MARK_AS_TRASH,
  ERROR_MARK_AS_TRASH,
  markGmailMessageAsTrashResponse,
  MARK_AS_UNREAD,
  SUCCESS_MARK_AS_UNREAD,
  ERROR_MARK_AS_UNREAD,
  markGmailMessageAsUnreadResponse,
  MARK_AS_INBOX,
  markGmailMessageAsInboxResponse,
  SUCCESS_MARK_AS_INBOX,
  ERROR_MARK_AS_INBOX,
  SAVE_SOCIAL_AUTH_TOKEN,
  SUCCESS_SAVE_SOCIAL_AUTH_TOKEN,
  ERROR_SAVE_SOCIAL_AUTH_TOKEN,
  saveSocialAuthTokenResponse,
  UPDATE_DRAFT_GMAIL_MESSAGE,
  SUCCESS_UPDATE_DRAFT_GMAIL_MESSAGE,
  ERROR_UPDATE_DRAFT_GMAIL_MESSAGE,
  EMPTY_EMAIL_STATE,
  GMAIL_ACCOUNT_DELETE,
  SUCCESS_GMAIL_ACCOUNT_DELETE,
  ERROR_GMAIL_ACCOUNT_DELETE,
  gmailAccountDeleteResponse,
} from "./google.action";
import {
  getAllGmailMessagesApi,
  getGmailMessageApi,
  deleteGmailMessageApi,
  resetEmailStateApi,
  getGmailMessageByIdApi,
  composeEmailApi,
  getGmailMessageAttachmentApi,
  modifyGmailMessageApi,
  composeEmailWithAttachmentApi,
  getGoogleAuthLinkApi,
  getGoogleAuthTokenApi,
  getGoogleValidTokenApi,
  createGmailDraftMessageApi,
  deleteGmailDraftMessageApi,
  getAllGmailDraftMessagesApi,
  getGmailDraftMessageApi,
  saveSocialAuthTokenApi,
  updateGmailDraftMessageApi,
  gmailAccountDeleteApi,
} from "../../../../api/index";
import _ from "lodash";
import { successNotification } from "../../../common/notification-alert";
import { onSocialLogout } from "../../../routing/authService";

// Get Gmail Auth Link Data
function* getGmailAuthLinkRequest() {
  let getData = yield getGoogleAuthLinkApi();
  if (getData.success && _.has(getData, "data")) {
    yield put(
      getGmailAuthLinkResponse(SUCCESS_GET_GMAIL_AUTH_LINK, getData.data)
    );
  } else {
    yield put(
      getGmailAuthLinkResponse(ERROR_GET_GMAIL_AUTH_LINK, getData.data)
    );
  }
}

export function* getGmailAuthLinkWatcher() {
  yield takeLatest(GET_GMAIL_AUTH_LINK, getGmailAuthLinkRequest);
}

// Get Gmail Auth Token Data
function* getGmailAuthTokenRequest(data) {
  let getData = yield getGoogleAuthTokenApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      getGmailAuthTokenResponse(SUCCESS_GET_GMAIL_AUTH_TOKEN, getData.data)
    );
  } else {
    yield put(
      getGmailAuthTokenResponse(ERROR_GET_GMAIL_AUTH_TOKEN, getData.data)
    );
    onSocialLogout();
  }
}

export function* getGmailAuthTokenWatcher() {
  yield takeLatest(GET_GMAIL_AUTH_TOKEN, getGmailAuthTokenRequest);
}

// Get Gmail Valid Token Data
function* getGmailValidTokenRequest(data) {
  let getData = yield getGoogleValidTokenApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      getGmailValidTokenResponse(SUCCESS_GET_GMAIL_VALID_TOKEN, getData.data)
    );
  } else {
    yield put(
      getGmailValidTokenResponse(ERROR_GET_GMAIL_VALID_TOKEN, getData.data)
    );
  }
}

export function* getGmailValidTokenWatcher() {
  yield takeLatest(GET_GMAIL_VALID_TOKEN, getGmailValidTokenRequest);
}

// Get All Gmail Messages Data
function* getAllGmailMessagesRequest(data) {
  let getData = yield getAllGmailMessagesApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      getAllGmailMessagesResponse(SUCCESS_GET_ALL_GMAIL_MESSAGES, getData.data)
    );
  } else {
    yield put(
      getAllGmailMessagesResponse(ERROR_GET_ALL_GMAIL_MESSAGES, getData.data)
    );
  }
}

export function* getAllGmailMessagesWatcher() {
  yield takeLatest(GET_ALL_GMAIL_MESSAGES, getAllGmailMessagesRequest);
}
// Get Gmail Message Data
function* getGmailMessageRequest(data) {
  let getData = yield getGmailMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(getGmailMessageResponse(SUCCESS_GET_GMAIL_MESSAGE, getData.data));
  } else {
    yield put(getGmailMessageResponse(ERROR_GET_GMAIL_MESSAGE, getData.data));
  }
}

export function* getGmailMessageWatcher() {
  yield takeLatest(GET_GMAIL_MESSAGE, getGmailMessageRequest);
}

// Get Gmail Message By Id Data
function* getGmailMessageByIdRequest(data) {
  let getData = yield getGmailMessageByIdApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      getGmailMessageByIdResponse(SUCCESS_GET_GMAIL_MESSAGE_BY_ID, getData.data)
    );
  } else {
    yield put(
      getGmailMessageByIdResponse(ERROR_GET_GMAIL_MESSAGE_BY_ID, getData.data)
    );
  }
}

export function* getGmailMessageByIdWatcher() {
  yield takeLatest(GET_GMAIL_MESSAGE_BY_ID, getGmailMessageByIdRequest);
}

// Delete Gmail Message
function* deleteGmailMessageRequest(data) {
  let getData = yield deleteGmailMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      deleteGmailMessageResponse(SUCCESS_DELETE_GMAIL_MESSAGE, getData.data)
    );
  } else {
    yield put(
      deleteGmailMessageResponse(ERROR_DELETE_GMAIL_MESSAGE, getData.data)
    );
  }
}

export function* deleteGmailMessageWatcher() {
  yield takeEvery(DELETE_GMAIL_MESSAGE, deleteGmailMessageRequest);
}

// Modify Gmail Message
function* modifyGmailMessageRequest(data) {
  let getData = yield modifyGmailMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      modifyGmailMessageResponse(SUCCESS_MODIFY_GMAIL_MESSAGE, getData.data)
    );
  } else {
    yield put(
      modifyGmailMessageResponse(ERROR_MODIFY_GMAIL_MESSAGE, getData.data)
    );
  }
}

export function* modifyGmailMessageWatcher() {
  yield takeEvery(MODIFY_GMAIL_MESSAGE, modifyGmailMessageRequest);
}

function* markGmailMessageAsSpamRequest(data) {
  let getData = yield modifyGmailMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      markGmailMessageAsSpamResponse(SUCCESS_MARK_AS_SPAM, getData.data)
    );
  } else {
    yield put(
      markGmailMessageAsSpamResponse(ERROR_MARK_AS_SPAM, getData.data)
    );
  }
}

export function* markGmailMessageAsSpamWatcher() {
  yield takeEvery(MARK_AS_SPAM, markGmailMessageAsSpamRequest);
}

function* markGmailMessageAsTrashRequest(data) {
  let getData = yield modifyGmailMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      markGmailMessageAsTrashResponse(SUCCESS_MARK_AS_TRASH, getData.data)
    );
  } else {
    yield put(
      markGmailMessageAsTrashResponse(ERROR_MARK_AS_TRASH, getData.data)
    );
  }
}

export function* markGmailMessageAsTrashWatcher() {
  yield takeEvery(MARK_AS_TRASH, markGmailMessageAsTrashRequest);
}

function* markGmailMessageAsUnreadRequest(data) {
  let getData = yield modifyGmailMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      markGmailMessageAsUnreadResponse(SUCCESS_MARK_AS_UNREAD, getData.data)
    );
  } else {
    yield put(
      markGmailMessageAsUnreadResponse(ERROR_MARK_AS_UNREAD, getData.data)
    );
  }
}

export function* markGmailMessageAsUnreadWatcher() {
  yield takeEvery(MARK_AS_UNREAD, markGmailMessageAsUnreadRequest);
}

function* markGmailMessageAsInboxRequest(data) {
  let getData = yield modifyGmailMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      markGmailMessageAsInboxResponse(SUCCESS_MARK_AS_INBOX, getData.data)
    );
  } else {
    yield put(
      markGmailMessageAsInboxResponse(ERROR_MARK_AS_INBOX, getData.data)
    );
  }
}

export function* markGmailMessageAsInboxWatcher() {
  yield takeEvery(MARK_AS_INBOX, markGmailMessageAsInboxRequest);
}

// Send Gmail Message
function* sendGmailMessageRequest(data) {
  let getData = yield composeEmailApi(data);
  if (getData.success && _.has(getData, "data")) {
    successNotification("Email sent successfully!")
    yield put(
      sendGmailMessageResponse(SUCCESS_SEND_GMAIL_MESSAGE, getData.data)
    );
  } else {
    yield put(sendGmailMessageResponse(ERROR_SEND_GMAIL_MESSAGE, getData.data));
  }
}

export function* sendGmailMessageWatcher() {
  yield takeLatest(SEND_GMAIL_MESSAGE, sendGmailMessageRequest);
}

// Send Gmail Message With Attachment
function* sendGmailMessageWithAttachmentRequest(data) {
  let getData = yield composeEmailWithAttachmentApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      sendGmailMessageWithAttachmentResponse(
        SUCCESS_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
        getData.data
      )
    );
  } else {
    yield put(
      sendGmailMessageWithAttachmentResponse(
        ERROR_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
        getData.data
      )
    );
  }
}

export function* sendGmailMessageWithAttachmentWatcher() {
  yield takeLatest(
    SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
    sendGmailMessageWithAttachmentRequest
  );
}

// Get Gmail Message Attachment By Id Data
function* getGmailMessageAttachmentByIdRequest(data) {
  let getData = yield getGmailMessageAttachmentApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      getGmailMessageAttachmentByIdResponse(
        SUCCESS_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
        getData.data
      )
    );
  } else {
    yield put(
      getGmailMessageAttachmentByIdResponse(
        ERROR_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
        getData.data
      )
    );
  }
}

export function* getGmailMessageAttachmentByIdWatcher() {
  yield takeEvery(
    GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
    getGmailMessageAttachmentByIdRequest
  );
}

// Reset Email State
function* resetEmailStateRequest(data) {
  let getData = yield resetEmailStateApi(data);
  if (getData.success) {
    yield put(resetEmailStateResponse(SUCCESS_RESET_EMAIL_STATE, getData.data));
  } else {
    yield put(resetEmailStateResponse(ERROR_RESET_EMAIL_STATE, getData.data));
  }
}

export function* resetEmailStateWatcher() {
  yield takeLatest(RESET_EMAIL_STATE, resetEmailStateRequest);
}

// Get Gmail Message Data
function* setGmailMessageRequest(data) {
  if (data.data.success && _.has(data, "data.data")) {
    yield put(setGmailMessageResponse(SUCCESS_SET_GMAIL_MESSAGE, data.data.data));
  } else {
    yield put(setGmailMessageResponse(ERROR_SET_GMAIL_MESSAGE, data.data.data));
  }
}

export function* setGmailMessageWatcher() {
  yield takeLatest(SET_GMAIL_MESSAGES, setGmailMessageRequest);
}

// Get All Draft Gmail Messages Data
function* getAllDraftGmailMessagesRequest(data) {
  let getData = yield getAllGmailDraftMessagesApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(
      getAllDraftGmailMessagesResponse(SUCCESS_GET_ALL_DRAFT_GMAIL_MESSAGES, getData.data)
    );
  } else {
    yield put(
      getAllDraftGmailMessagesResponse(ERROR_GET_ALL_DRAFT_GMAIL_MESSAGES, getData.data)
    );
  }
}

export function* getAllDraftGmailMessagesWatcher() {
  yield takeLatest(GET_ALL_DRAFT_GMAIL_MESSAGES, getAllDraftGmailMessagesRequest);
}

// Get Draft Gmail Message Data
function* getDraftGmailMessageRequest(data) {
  let getData = yield getGmailDraftMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(getDraftGmailMessageResponse(SUCCESS_GET_DRAFT_GMAIL_MESSAGE, getData.data));
  } else {
    yield put(getDraftGmailMessageResponse(ERROR_GET_DRAFT_GMAIL_MESSAGE, getData.data));
  }
}

export function* getDraftGmailMessageWatcher() {
  yield takeLatest(GET_DRAFT_GMAIL_MESSAGE, getDraftGmailMessageRequest);
}

// Get Draft Gmail Message Data
function* getDraftGmailMessageByIdRequest(data) {
  if (data.data.success && _.has(data, "data.data")) {
    yield put(getDraftGmailMessageByIdResponse(SUCCESS_GET_DRAFT_GMAIL_MESSAGE_BY_ID, data.data.data));
  } else {
    yield put(getDraftGmailMessageByIdResponse(ERROR_GET_DRAFT_GMAIL_MESSAGE_BY_ID, data.data.data));
  }
}

export function* getDraftGmailMessageByIdWatcher() {
  yield takeLatest(GET_DRAFT_GMAIL_MESSAGE_BY_ID, getDraftGmailMessageByIdRequest);
}

// Create Draft Gmail Message Data
function* createDraftGmailMessageRequest(data) {
  let getData = yield createGmailDraftMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(createDraftGmailMessageResponse(SUCCESS_CREATE_DRAFT_GMAIL_MESSAGE, getData.data));
  } else {
    yield put(createDraftGmailMessageResponse(ERROR_CREATE_DRAFT_GMAIL_MESSAGE, getData.data));
  }
}

export function* createDraftGmailMessageWatcher() {
  yield takeLatest(CREATE_DRAFT_GMAIL_MESSAGE, createDraftGmailMessageRequest);
}

// update Draft Gmail Message Data
function* updateDraftGmailMessageRequest(data) {
  let getData = yield updateGmailDraftMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(createDraftGmailMessageResponse(SUCCESS_UPDATE_DRAFT_GMAIL_MESSAGE, getData.data));
  } else {
    yield put(createDraftGmailMessageResponse(ERROR_UPDATE_DRAFT_GMAIL_MESSAGE, getData.data));
  }
}

export function* updateDraftGmailMessageWatcher() {
  yield takeLatest(UPDATE_DRAFT_GMAIL_MESSAGE, updateDraftGmailMessageRequest);
}

// Delete Draft Gmail Message Data
function* deleteDraftGmailMessageRequest(data) {
  let getData = yield deleteGmailDraftMessageApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(deleteDraftGmailMessageResponse(SUCCESS_DELETE_DRAFT_GMAIL_MESSAGE, getData.data));
  } else {
    yield put(deleteDraftGmailMessageResponse(ERROR_DELETE_DRAFT_GMAIL_MESSAGE, getData.data));
  }
}

export function* deleteDraftGmailMessageWatcher() {
  yield takeEvery(DELETE_DRAFT_GMAIL_MESSAGE, deleteDraftGmailMessageRequest);
}

// Save Social Auth Token Data
function* saveSocialAuthTokenRequest(data) {
  let getData = yield saveSocialAuthTokenApi(data);
  if (getData.success && _.has(getData, "data.social_login")) {
    yield put(saveSocialAuthTokenResponse(SUCCESS_SAVE_SOCIAL_AUTH_TOKEN, getData.data));
  } else {
    yield put(saveSocialAuthTokenResponse(ERROR_SAVE_SOCIAL_AUTH_TOKEN, getData.data));
  }
}

export function* saveSocialAuthTokenWatcher() {
  yield takeEvery(SAVE_SOCIAL_AUTH_TOKEN, saveSocialAuthTokenRequest);
}

// Empty Email State Request
function* emptyEmailStateRequest() {
  yield onSocialLogout();
}

export function* emptyEmailStateWatcher() {
  yield takeLatest(EMPTY_EMAIL_STATE, emptyEmailStateRequest);
}

// Gmail Account Delete
function* gmailAccountDeleteRequest(data) {
  let getData = yield gmailAccountDeleteApi(data);
  if (getData.success && _.has(getData, 'data.data')) {
    successNotification(getData.data.message)
    yield put(gmailAccountDeleteResponse(SUCCESS_GMAIL_ACCOUNT_DELETE, getData.data));
  } else {
    yield put(gmailAccountDeleteResponse(ERROR_GMAIL_ACCOUNT_DELETE, getData.data));
  }
}

export function* gmailAccountDeleteWatcher() {
  yield takeLatest(GMAIL_ACCOUNT_DELETE, gmailAccountDeleteRequest);
}
