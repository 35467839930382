// View Profile Detail
export const GET_PROFILE_DETAIL = "GET_PROFILE_DETAIL";
export const getProfileDetail = (data) => ({ type: GET_PROFILE_DETAIL, data })
export const SUCCESS_GET_PROFILE_DETAIL = 'SUCCESS_GET_PROFILE_DETAIL';
export const ERROR_GET_PROFILE_DETAIL = 'ERROR_GET_PROFILE_DETAIL';
export const getProfileDetailResponse = (type, data) => ({ type, data });

// Update Owner Detail
export const UPDATE_OWNER_PROFILE_DETAIL = "UPDATE_OWNER_PROFILE_DETAIL";
export const updateOwnerProfileDetail = (data) => ({ type: UPDATE_OWNER_PROFILE_DETAIL, data })
export const SUCCESS_UPDATE_OWNER_PROFILE_DETAIL = 'SUCCESS_UPDATE_OWNER_PROFILE_DETAIL';
export const ERROR_UPDATE_OWNER_PROFILE_DETAIL = 'ERROR_UPDATE_OWNER_PROFILE_DETAIL';
export const updateOwnerProfileDetailResponse = (type, data) => ({ type, data });

// Update Password
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const updatePassword = (data) => ({ type: UPDATE_PASSWORD, data })
export const SUCCESS_UPDATE_PASSWORD = 'SUCCESS_UPDATE_PASSWORD';
export const ERROR_UPDATE_PASSWORD = 'ERROR_UPDATE_PASSWORD';
export const updatePasswordResponse = (type, data) => ({ type, data });

// Update Business Detail
export const UPDATE_BUSINESS_PROFILE_DETAIL = "UPDATE_BUSINESS_PROFILE_DETAIL";
export const updateBusinessProfileDetail = (data) => ({ type: UPDATE_BUSINESS_PROFILE_DETAIL, data })
export const SUCCESS_UPDATE_BUSINESS_PROFILE_DETAIL = 'SUCCESS_UPDATE_BUSINESS_PROFILE_DETAIL';
export const ERROR_UPDATE_BUSINESS_PROFILE_DETAIL = 'ERROR_UPDATE_BUSINESS_PROFILE_DETAIL';
export const updateBusinessProfileDetailResponse = (type, data) => ({ type, data });

// Get All Menu Count   
export const GET_MENU_COUNT = "GET_MENU_COUNT";
export const getMenuCount = (data) => ({ type: GET_MENU_COUNT, data })
export const SUCCESS_GET_MENU_COUNT = 'SUCCESS_GET_MENU_COUNT';
export const ERROR_GET_MENU_COUNT = 'ERROR_GET_MENU_COUNT';
export const getMenuCountResponse = (type, data) => ({ type, data });

// Connect with paypal 
export const CONNECT_WITH_PAYPAL = "CONNECT_WITH_PAYPAL";
export const connectWithPaypal = (data) => ({ type: CONNECT_WITH_PAYPAL, data })
export const SUCCESS_CONNECT_WITH_PAYPAL = 'SUCCESS_CONNECT_WITH_PAYPAL';
export const ERROR_CONNECT_WITH_PAYPAL = 'ERROR_CONNECT_WITH_PAYPAL';
export const connectWithPaypalResponse = (type, data) => ({ type, data });

// Paypal Test Payment 
export const MAKE_TEST_PAYMENT = "MAKE_TEST_PAYMENT";
export const makeTestPayment = (data) => ({ type: MAKE_TEST_PAYMENT, data })
export const SUCCESS_MAKE_TEST_PAYMENT = 'SUCCESS_MAKE_TEST_PAYMENT';
export const ERROR_MAKE_TEST_PAYMENT = 'ERROR_MAKE_TEST_PAYMENT';
export const makeTestPaymentResponse = (type, data) => ({ type, data });

// Delete Payment Account
export const DELETE_PAYMENT_ACCOUNT = "DELETE_PAYMENT_ACCOUNT";
export const deletePaymentAccount = (data) => ({ type: DELETE_PAYMENT_ACCOUNT, data })
export const SUCCESS_DELETE_PAYMENT_ACCOUNT = 'SUCCESS_DELETE_PAYMENT_ACCOUNT';
export const ERROR_DELETE_PAYMENT_ACCOUNT = 'ERROR_DELETE_PAYMENT_ACCOUNT';
export const deletePaymentAccountResponse = (type, data) => ({ type, data });

// Get Subscription Plan
export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN";
export const getSubscritpionPlan = (data) => ({ type: GET_SUBSCRIPTION_PLAN, data })
export const SUCCESS_GET_SUBSCRIPTION_PLAN = 'SUCCESS_GET_SUBSCRIPTION_PLAN';
export const ERROR_GET_SUBSCRIPTION_PLAN = 'ERROR_GET_SUBSCRIPTION_PLAN';
export const getSubscritpionPlanResponse = (type, data) => ({ type, data });

// Create Subscription Plan
export const CREATE_SUBSCRIPTION_PLAN = "CREATE_SUBSCRIPTION_PLAN";
export const createSubscritpionPlan = (data) => ({ type: CREATE_SUBSCRIPTION_PLAN, data })
export const SUCCESS_CREATE_SUBSCRIPTION_PLAN = 'SUCCESS_CREATE_SUBSCRIPTION_PLAN';
export const ERROR_CREATE_SUBSCRIPTION_PLAN = 'ERROR_CREATE_SUBSCRIPTION_PLAN';
export const createSubscritpionPlanResponse = (type, data) => ({ type, data });

// Apply Plan Coupon
export const PLAN_APPLY_COUPON = "PLAN_APPLY_COUPON";
export const planApplyCoupon = (data) => ({ type: PLAN_APPLY_COUPON, data })
export const SUCCESS_PLAN_APPLY_COUPON = 'SUCCESS_PLAN_APPLY_COUPON';
export const ERROR_PLAN_APPLY_COUPON = 'ERROR_PLAN_APPLY_COUPON';
export const planApplyCouponResponse = (type, data) => ({ type, data });

// Get Tax State
export const GET_STATE_TAX = "GET_STATE_TAX";
export const getStateTax = (data) => ({ type: GET_STATE_TAX, data })
export const SUCCESS_GET_STATE_TAX = 'SUCCESS_GET_STATE_TAX';
export const ERROR_GET_STATE_TAX = 'ERROR_GET_STATE_TAX';
export const getStateTaxResponse = (type, data) => ({ type, data });

// update Subscription Plan
export const UPDATE_SUBSCRIPTION_PLAN = "UPDATE_SUBSCRIPTION_PLAN";
export const updateSubscritpionPlan = (data) => ({ type: UPDATE_SUBSCRIPTION_PLAN, data })
export const SUCCESS_UPDATE_SUBSCRIPTION_PLAN = 'SUCCESS_UPDATE_SUBSCRIPTION_PLAN';
export const ERROR_UPDATE_SUBSCRIPTION_PLAN = 'ERROR_UPDATE_SUBSCRIPTION_PLAN';
export const updateSubscritpionPlanResponse = (type, data) => ({ type, data });

// cancel Subscription Plan
export const CANCEL_SUBSCRIPTION_PLAN = "CANCEL_SUBSCRIPTION_PLAN";
export const cancelSubscritpionPlan = (data) => ({ type: CANCEL_SUBSCRIPTION_PLAN, data })
export const SUCCESS_CANCEL_SUBSCRIPTION_PLAN = 'SUCCESS_CANCEL_SUBSCRIPTION_PLAN';
export const ERROR_CANCEL_SUBSCRIPTION_PLAN = 'ERROR_CANCEL_SUBSCRIPTION_PLAN';
export const cancelSubscritpionPlanResponse = (type, data) => ({ type, data });

// cancel Subscription Plan
export const ADD_PROFILE_SERVICE = "ADD_PROFILE_SERVICE";
export const addProfileService = (data) => ({ type: ADD_PROFILE_SERVICE, data })
export const SUCCESS_ADD_PROFILE_SERVICE = 'SUCCESS_ADD_PROFILE_SERVICE';
export const ERROR_ADD_PROFILE_SERVICE = 'ERROR_ADD_PROFILE_SERVICE';
export const addProfileServiceResponse = (type, data) => ({ type, data });


//DELETE ALL DUMMY DATA
export const DELETE_ALL_DUMMY_DATA = "DELETE_ALL_DUMMY_DATA";
export const deleteAllDummyData = (data) => ({ type: DELETE_ALL_DUMMY_DATA, data })
export const SUCCESS_DELETE_ALL_DUMMY_DATA = 'SUCCESS_DELETE_ALL_DUMMY_DATA';
export const ERROR_DELETE_ALL_DUMMY_DATA = 'ERROR_DELETE_ALL_DUMMY_DATA';
export const deleteAllDummyDataResponse = (type, data) => ({ type, data });

// Get State
export const GET_STATE = "GET_STATE";
export const getState = (data) => ({ type: GET_STATE, data })
export const SUCCESS_GET_STATE = 'SUCCESS_GET_STATE';
export const ERROR_GET_STATE = 'ERROR_GET_STATE';
export const getStateResponse = (type, data) => ({ type, data });


// Get Qr code State
export const SETUP_2FA = "SETUP_2FA";
export const setUp2FA = (data) => ({ type: SETUP_2FA, data });
export const SUCCESS_SETUP_2FA = 'SUCCESS_SETUP_2FA';
export const ERROR_SETUP_2FA = 'ERROR_SETUP_2FA';
export const setUp2FAResponse = (type, data) => ({ type, data });

// Get State 2FA ENABLE
export const VERIFY_2FA = "VERIFY_2FA";
export const getVerify = (data) => ({ type: VERIFY_2FA, data, })
export const SUCCESS_VERIFY_2FA = 'SUCCESS_VERIFY_2FA';
export const ERROR_VERIFY_2FA = 'ERROR_VERIFY_2FA';
export const getVerifyResponse = (type, data) => ({ type, data });


// Get State 2FA disable
export const DISABLE_2FA = "DISABLE_2FA";
export const disable2FA = (data) => ({ type: DISABLE_2FA, data })
export const SUCCESS_DISABLE_2FA = 'SUCCESS_DISABLE_2FA';
export const ERROR_DISABLE_2FA = 'ERROR_DISABLE_2FA';
export const getDisableVerifyResponse = (type, data) => ({ type, data });


// GET AUTO REPLY DATA
export const GET_AUTO_REPLY_DATA = "GET_AUTO_REPLY_DATA";
export const getAutoReplyData = () => ({ type: GET_AUTO_REPLY_DATA })
export const SUCCESS_GET_AUTO_REPLY_DATA = 'SUCCESS_GET_AUTO_REPLY_DATA';
export const ERROR_GET_AUTO_REPLY_DATA = 'ERROR_GET_AUTO_REPLY_DATA';
export const getAutoReplyDataResponse = (type, data) => ({ type, data });

// UPDATE AUTO REPLY DATA
export const UPDATE_AUTO_REPLY_DATA = "UPDATE_AUTO_REPLY_DATA";
export const updateAutoReplyData = (data) => ({ type: UPDATE_AUTO_REPLY_DATA, data })
export const SUCCESS_UPDATE_AUTO_REPLY_DATA = 'SUCCESS_UPDATE_AUTO_REPLY_DATA';
export const ERROR_UPDATE_AUTO_REPLY_DATA = 'ERROR_UPDATE_AUTO_REPLY_DATA';
export const updateAutoReplyDataResponse = (type, data) => ({ type, data });

// Delete Business Banner
export const DELETE_HEADER_IMAGE = "DELETE_HEADER_IMAGE";
export const deleteHeaderImage = (data) => ({ type: DELETE_HEADER_IMAGE, data })
export const SUCCESS_DELETE_HEADER_IMAGE = 'SUCCESS_DELETE_HEADER_IMAGE';
export const ERROR_DELETE_HEADER_IMAGE = 'ERROR_DELETE_HEADER_IMAGE';
export const deleteHeaderImageResponse = (type, data) => ({ type, data });

// Manage colunms List Configuration
export const ADD_CONFIGURATION = 'ADD_CONFIGURATION';
export const addConfiguration = (data) => ({ type: ADD_CONFIGURATION, data });
export const SUCCESS_ADD_CONFIGURATION = 'SUCCESS_ADD_CONFIGURATION';
export const ERROR_ADD_CONFIGURATION = 'ERROR_ADD_CONFIGURATION';
export const addConfigurationResponse = (type, data) => ({ type, data });
