export const saveToLocalStorage = (state) => {
    try {
      localStorage.setItem('email_state', JSON.stringify(state));
    } catch (e) {
      console.error(e);
    }
  };
  
export const loadFromLocalStorage = () => {
    try {
      const socialGmailState = localStorage.getItem('email_state');
      const gmailState = socialGmailState ? JSON.parse(socialGmailState) : undefined;
      return gmailState;
    } catch (e) {
      console.error(e);
      return undefined;
    }
};