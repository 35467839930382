export const DEFAULT_STATE = {
    addBookingData: {},
    listBookingData: {},
    deleteBookingData: {},
    getBookingByIdData: {},
    addBookingNoteData: {},
    listBookingNoteData: {},
    deleteBookingNoteData: {},
    addBookingTaskData: {},
    listBookingTaskData: {},
    deleteBookingTaskData: {},
    createAppointmentData: {},
    bookingListViewData: {},
    deleteAppointmentData: {},
    updateAppointmentData: {},
    createBookingPaymentData: {},
    isFrombookingList: false,
}