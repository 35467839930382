// List Invoice
export const LIST_INVOICE = 'LIST_INVOICE';
export const listInvoice = (data) => ({ type: LIST_INVOICE, data });
export const SUCCESS_LIST_INVOICE = 'SUCCESS_LIST_INVOICE';
export const ERROR_LIST_INVOICE = 'ERROR_LIST_INVOICE';
export const listInvoiceResponse = (type, data) => ({ type, data });

// Add Invoice
export const ADD_INVOICE = 'ADD_INVOICE';
export const addInvoice = (data) => ({ type: ADD_INVOICE, data });
export const SUCCESS_ADD_INVOICE = 'SUCCESS_ADD_INVOICE';
export const ERROR_ADD_INVOICE = 'ERROR_ADD_INVOICE';
export const addInvoiceResponse = (type, data) => ({ type, data });

// View Invoice By ID
export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID';
export const getInvoiceById = (data) => ({ type: GET_INVOICE_BY_ID, data });
export const SUCCESS_GET_INVOICE_BY_ID = 'SUCCESS_GET_INVOICE_BY_ID';
export const ERROR_GET_INVOICE_BY_ID = 'ERROR_GET_INVOICE_BY_ID';
export const getInvoiceByIdResponse = (type, data) => ({ type, data });

// Update Invoice
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const updateInvoice = (data) => ({ type: UPDATE_INVOICE, data });
export const SUCCESS_UPDATE_INVOICE = 'SUCCESS_UPDATE_INVOICE';
export const ERROR_UPDATE_INVOICE = 'ERROR_UPDATE_INVOICE';
export const updateInvoiceResponse = (type, data) => ({ type, data });

// Update Invoice
export const SEND_TO_CUSTOMER_INVOICE = 'SEND_TO_CUSTOMER_INVOICE';
export const sendToCustomerInvoice = (data) => ({ type: SEND_TO_CUSTOMER_INVOICE, data });
export const SUCCESS_SEND_TO_CUSTOMER_INVOICE = 'SUCCESS_SEND_TO_CUSTOMER_INVOICE';
export const ERROR_SEND_TO_CUSTOMER_INVOICE = 'ERROR_SEND_TO_CUSTOMER_INVOICE';
export const sendToCustomerInvoiceResponse = (type, data) => ({ type, data });

//Delete Invoice Data 
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const deleteInvoice = (data) => ({ type: DELETE_INVOICE, data });
export const SUCCESS_DELETE_INVOICE = 'SUCCESS_DELETE_INVOICE';
export const ERROR_DELETE_INVOICE = 'ERROR_DELETE_INVOICE';
export const deleteInvoiceResponse = (type, data) => ({ type, data });

// Update Basic Invoice
export const UPDATE_BASIC_INVOICE = 'UPDATE_BASIC_INVOICE';
export const updateBasicInvoice = (data) => ({ type: UPDATE_BASIC_INVOICE, data });
export const SUCCESS_UPDATE_BASIC_INVOICE = 'SUCCESS_UPDATE_BASIC_INVOICE';
export const ERROR_UPDATE_BASIC_INVOICE = 'ERROR_UPDATE_BASIC_INVOICE';
export const updateBasicInvoiceResponse = (type, data) => ({ type, data });

// View Customer View
export const VIEW_CUSTOMER_INVOICE = 'VIEW_CUSTOMER_INVOICE';
export const viewCustomerInvoice = (data) => ({ type: VIEW_CUSTOMER_INVOICE, data });
export const SUCCESS_VIEW_CUSTOMER_INVOICE = 'SUCCESS_VIEW_CUSTOMER_INVOICE';
export const ERROR_VIEW_CUSTOMER_INVOICE = 'ERROR_VIEW_CUSTOMER_INVOICE';
export const viewCustomerInvoiceResponse = (type, data) => ({ type, data });

// Mark as Paid Invoice
export const MARK_PAID_INVOICE = 'MARK_PAID_INVOICE';
export const markPaidInvoice = (data) => ({ type: MARK_PAID_INVOICE, data });
export const SUCCESS_MARK_PAID_INVOICE = 'SUCCESS_MARK_PAID_INVOICE';
export const ERROR_MARK_PAID_INVOICE = 'ERROR_MARK_PAID_INVOICE';
export const markPaidInvoiceResponse = (type, data) => ({ type, data });

// Revise Invoice
export const INVOICE_MAKE_PAYMENT = 'INVOICE_MAKE_PAYMENT';
export const invoiceMakePayment = (data) => ({ type: INVOICE_MAKE_PAYMENT, data });
export const SUCCESS_INVOICE_MAKE_PAYMENT = 'SUCCESS_INVOICE_MAKE_PAYMENT';
export const ERROR_INVOICE_MAKE_PAYMENT = 'ERROR_INVOICE_MAKE_PAYMENT';
export const invoiceMakePaymentResponse = (type, data) => ({ type, data });

// Get Paid Invoice
export const GET_PAID_INVOICE = 'GET_PAID_INVOICE';
export const getPaidInvoice = (data) => ({ type: GET_PAID_INVOICE, data });
export const SUCCESS_GET_PAID_INVOICE = 'SUCCESS_GET_PAID_INVOICE';
export const ERROR_GET_PAID_INVOICE = 'ERROR_GET_PAID_INVOICE';
export const getPaidInvoiceResponse = (type, data) => ({ type, data });

// List Invoice Template
export const LIST_INVOICE_TEMPLATE = 'LIST_INVOICE_TEMPLATE';
export const listInvoiceTemplate = (data) => ({ type: LIST_INVOICE_TEMPLATE, data });
export const SUCCESS_LIST_INVOICE_TEMPLATE = 'SUCCESS_LIST_INVOICE_TEMPLATE';
export const ERROR_LIST_INVOICE_TEMPLATE = 'ERROR_LIST_INVOICE_TEMPLATE';
export const listInvoiceTemplateResponse = (type, data) => ({ type, data });

// Add Invoice Template
export const ADD_INVOICE_TEMPLATE = 'ADD_INVOICE_TEMPLATE';
export const addInvoiceTemplate = (data) => ({ type: ADD_INVOICE_TEMPLATE, data });
export const SUCCESS_ADD_INVOICE_TEMPLATE = 'SUCCESS_ADD_INVOICE_TEMPLATE';
export const ERROR_ADD_INVOICE_TEMPLATE = 'ERROR_ADD_INVOICE_TEMPLATE';
export const addInvoiceTemplateResponse = (type, data) => ({ type, data });

// Get Invoice Template By Id
export const GET_INVOICE_TEMPLATE_BY_ID = 'GET_INVOICE_TEMPLATE_BY_ID';
export const getInvoiceTemplateById = (data) => ({ type: GET_INVOICE_TEMPLATE_BY_ID, data });
export const SUCCESS_GET_INVOICE_TEMPLATE_BY_ID = 'SUCCESS_GET_INVOICE_TEMPLATE_BY_ID';
export const ERROR_GET_INVOICE_TEMPLATE_BY_ID = 'ERROR_GET_INVOICE_TEMPLATE_BY_ID';
export const getInvoiceTemplateByIdResponse = (type, data) => ({ type, data });

// Update Invoice User Email Body
export const UPDATE_MESSAGE_BODY_INVOICE = 'UPDATE_MESSAGE_BODY_INVOICE';
export const updateMessageBodyInvoice = (data) => ({ type: UPDATE_MESSAGE_BODY_INVOICE, data });
export const SUCCESS_UPDATE_MESSAGE_BODY_INVOICE = 'SUCCESS_UPDATE_MESSAGE_BODY_INVOICE';
export const ERROR_UPDATE_MESSAGE_BODY_INVOICE = 'ERROR_UPDATE_MESSAGE_BODY_INVOICE';
export const updateMessageBodyResponse = (type, data) => ({ type, data });

// get Invoice User Email Body
export const GET_MESSAGE_BODY_INVOICE = 'GET_MESSAGE_BODY_INVOICE';
export const getMessageBodyInvoice = (data) => ({ type: GET_MESSAGE_BODY_INVOICE, data });
export const SUCCESS_GET_MESSAGE_BODY_INVOICE = 'SUCCESS_GET_MESSAGE_BODY_INVOICE';
export const ERROR_GET_MESSAGE_BODY_INVOICE = 'ERROR_GET_MESSAGE_BODY_INVOICE';
export const getMessageBodyResponse = (type, data) => ({ type, data });
