import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DOWNLOAD from "../../../../assets/images/ic_get_app.svg";
import { setImagePath } from '../../../../common/custom';
import { baseUrl } from '../../../../common/constants';
import _ from 'lodash';
import ORANGE_ARROW from '../../../../assets/images/orange-arrow.svg'
import EMAIL_HEADER_IMG from "../../../../assets/images/email_header_img.png";
import { getUserDetails } from '../../../../storage/user';
import Modal from "react-bootstrap/Modal";

function SendQuote({ openModal, setOpenModal, profileDetail, BIZZ_LOGO }) {

  const userData = getUserDetails();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isCollapse, setIsCollapse] = useState('');
  const [declineModalShow, setDeclineModalShow] = useState(false);
  const [acceptQuoteModalShow, setAcceptQuoteModalShow] = useState(false);
  const [quoteRevisionModalShow, setQuoteRevisionModalShow] = useState(false);
  const [contactData, setContactData] = useState('');
  const [state, setState] = useState({
    quoteName: '', timeValue: '', location: '', internalNotes: '',
    timeShiftValue: '', customDuration: '', lat_long: '', totalAmount: 0, deposite: 0, date: '',
    lineItem: '', itemName: '', discription: '', charge: '', qty: '', rate: '', validThrough: '', latLngUrl: '',
    status: '', depositRequired: 0, depositOnline: 0, paymentReceive: 0, tentative: false
  });

  return (
    <>
      <Modal show={openModal} onHide={() => setOpenModal(false)} size="xl" >
        <main className="site-body" style={{ marginLeft: "0px" }}>
          {/* <div className="email-modal-contain mt-4" style={{ marginBottom: "-14px" }}>Preview: Quote Details (Sample)</div>
          <Modal.Header closeButton className="d-inline">
          </Modal.Header> */}
          <Modal.Header closeButton className="email-modal-contain">
            <Modal.Title>Preview: Quote Details (Sample)</Modal.Title>
          </Modal.Header>
          <section className="middle-section p-0">
            <div className="container">
              {profileDetail?.organization?.header_image &&
                <div className="">
                  <img src={profileDetail?.organization?.header_image ? setImagePath(baseUrl[0] + profileDetail?.organization?.header_image) : setImagePath(EMAIL_HEADER_IMG)} alt="" className="img-fluid" style={{ height: '225px', width: '100%' }} />
                </div>
              }
              <div className="row no-gutters-mbl mt-lg-4">
                <div className="col-lg-12">
                  <div className="main-card create-qoute--cards">
                    <div className="card">
                      <div className="card-body px-4">
                        <div className="row align-items-center">
                          <div className="col-lg-7">
                            <div className="quote-bizzlogo--name">
                              <div className="quote-bizzlogo d-flex align-items-center justify-content-center">
                                <div className="mx-auto">
                                  {profileDetail?.organization && profileDetail?.organization?.name !== 'Default'
                                    ?
                                    <div className="">
                                      <img src={profileDetail?.organization?.company_logo ?
                                        setImagePath(baseUrl[0] + profileDetail?.organization?.company_logo)
                                        :
                                        setImagePath(BIZZ_LOGO)
                                      }
                                        alt="" className="p-0 m-0"
                                      />
                                    </div>
                                    :
                                    <img src={setImagePath(BIZZ_LOGO)} alt="" style={{ height: 85 }} />
                                  }
                                </div>
                              </div>
                              <div>
                                <div className="quote-bizzname">{profileDetail?.organization?.name === "" || profileDetail?.organization?.name === "Default" ? "Your Business Name" : profileDetail?.organization?.name}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="quote-bizz-details">
                              <div className="field-text">{profileDetail?.organization?.street_address === "" || profileDetail?.organization?.name === "Default" ? "Street Address" : profileDetail?.organization?.street_address}</div>
                              <div className="field-text">{profileDetail?.organization?.city === "" || profileDetail?.organization?.name === "Default" ? "City, Zip/Pin" : profileDetail?.organization?.city}</div>
                              <div className="field-text">{profileDetail?.organization?.state === "" || profileDetail?.organization?.name === "Default" ? "State" : profileDetail?.organization?.state}, {profileDetail?.organization?.country === "" || profileDetail?.organization?.name === "Default" ? "USA" : profileDetail?.organization?.country}</div>
                              <div className="field-text">{profileDetail?.organization?.phone === "" || profileDetail?.organization?.name === "Default" ? "123-456-7890" : profileDetail?.organization?.phone}</div>
                              <div className="field-text"><a href="#quote">{profileDetail?.organization?.email === "" || profileDetail?.organization?.name === "Default" ? "business_email@gmail.com" : profileDetail?.organization?.email}</a></div>
                              <div className="field-text"><a href="#quote">{profileDetail?.organization?.website === "" || profileDetail?.organization?.name === "Default" ? "www.yourbusinesswebsite.com" : profileDetail?.organization?.website}</a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters-mbl mt-lg-4">
                <div className="col-lg-3">
                  <div className="main-card create-qoute--cards">
                    <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#CustomerCollapse" aria-expanded="true" aria-controls="CustomerCollapse">Customer <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                    <div className={"card main-card--collapse show " + isCollapse} id="CustomerCollapse">
                      <div className="card-body px-4">
                        <div className="row">
                          <div className="field-text">Customer Name</div>
                          <div className="form-group mb-0">
                            <div className="field-text">415-999-0000</div>
                            <div className="field-text"><Link>customer@email..com</Link></div>
                            <div className="field-text">Optional Address</div>
                            <div className="field-text">Customer Title</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="main-card create-qoute--cards">
                    <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#EventCollapse" aria-expanded="true" aria-controls="EventCollapse">Event Details <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                    <div className={"card main-card--collapse show " + isCollapse} id="EventCollapse">
                      <div className="card-body px-4">
                        <div className="row">
                          <div className="form-group col-6">
                            <label>Service Date</label>
                            <div className="field-text">Tue, Mar 20, 2025</div>
                          </div>
                          <div className="form-group col-6">
                            <label>Start Time</label>
                            <div className="field-text"><strong>12.30 PM IST (3 Hours)</strong>
                            </div>
                          </div>
                          <div className="form-group col-md-12 mb-0">
                            <label>Location</label>
                            <div className="field-text">Customer Address
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="main-card create-qoute--cards">
                    <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#QuoteCollapse" aria-expanded="true" aria-controls="QuoteCollapse">Quote Amount <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                    <div className={"card main-card--collapse show " + isCollapse} id="QuoteCollapse">
                      <div className="card-body px-4">
                        <div className="row">
                          <div className="form-group col-6">
                            <label>Valid Until</label>
                            <div className="field-text">Tue, Mar 15, 2025</div>
                          </div>
                          <div className="form-group col-6 mb-0">
                            <label>Total Amount </label>
                            <div className="field-text bizzamount-price">$000.00</div>
                          </div>
                          <div className="form-group col-6">
                            <label>ID</label>
                            <div className="field-text">100009</div>
                          </div>
                          <div className="form-group col-6 mb-0">
                            <label>Deposit Required</label>
                            <div className="field-text bizzamount-price">$0</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters-mbl mt-lg-4">
                <div className="col-lg-12">
                  <div className="main-card create-qoute--cards create-form">
                    <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#LineItemsCollapse" aria-expanded="true" aria-controls="LineItemsCollapse">
                      Table Data
                      <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                    <div className={"card main-card--collapse show " + isCollapse} id="LineItemsCollapse">
                      <div className="card-header d-none d-lg-flex justify-content-between align-items-center">
                        <h4 className="quote-heading"> {state.lineItem || ''}</h4>
                      </div>
                      <div className="card-body">
                        <table className="table table-striped smart-table mb-0">
                          <thead>
                            <tr>
                              <th>{state.itemName || 'Service Name'}</th>
                              <th>{state.qty || 'Description'}</th>
                              <th>{state.charge || 'Fees'}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="titleName"><strong>Sample Service 1</strong><p></p></td>
                              <td className="qt-fees fees">Lorem ipsum dolor sit amet consectetur. Vitae at laoreet risus sed vestibulum facilisi. Integer elit mattis pulvinar varius elit pellentesque sapien curabitur odio. Neque sed nunc sit arcu ut vehicula.</td>
                              <td className=""><strong>$100.00</strong></td>
                            </tr>
                            <tr>
                              <td className="titleName"><strong>Sample Service 1</strong><p></p></td>
                              <td className="qt-fees fees">Lorem ipsum dolor sit amet consectetur. Vitae at laoreet risus sed vestibulum facilisi. Integer elit mattis pulvinar varius elit pellentesque sapien curabitur odio. Neque sed nunc sit arcu ut vehicula.</td>
                              <td className=""><strong>$100.00</strong></td>
                            </tr>
                            <tr className="quote-total-row">
                              <td colSpan="3" className="p-0">
                                <table className="table m-0">
                                  <tbody>
                                    <tr>
                                      <td className="qt-colblank"></td>
                                      <td className="qt-qttotal qt-subtotal totl">Subtotal</td>
                                      <td className="qt-fees amt">$100.00</td>
                                    </tr>
                                    <tr>
                                      <td className="qt-colblank"></td>
                                      <td className="qt-qttotal totl">Travel Cost</td>
                                      <td className="qt-fees amt"><span>$30.00</span></td>
                                    </tr>
                                    <tr>
                                      <td className="qt-colblank"></td>
                                      <td className="qt-qttotal totl">Service Charges</td>
                                      <td className="qt-fees amt"><span>$30.00</span></td>
                                    </tr>
                                    <tr>
                                      <td className="qt-colblank"></td>
                                      <td className="qt-qttotal totl">Website Promotion</td>
                                      <td className="qt-fees amt"><span>-$25.00</span></td>
                                    </tr>
                                    <tr>
                                      <td className="qt-colblank"></td>
                                      <td className="qt-qttotal qt-total totl"><strong>Total</strong></td>
                                      <td className="qt-fees amt"><strong>$335.00</strong></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters-mbl mt-lg-4">
                <div className="col-lg-12">
                  <div className="main-card  create-qoute--cards create-form">
                    <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target={"#TermsCondCollapse"} aria-expanded="false" aria-controls={"TermsCondCollapse"}>
                      Terms & Conditions
                      <img src={setImagePath(ORANGE_ARROW)} alt="" />
                    </button>
                    <div className={"card main-card--collapse " + isCollapse} id={"TermsCondCollapse"}>
                      <div className="card-header d-none d-lg-flex justify-content-between align-items-center">
                        <h4 className="quote-heading">Terms & Conditions</h4>
                      </div>
                      <div className="card-body px-4">
                        <div className="term-and-condirion">Lorem ipsum dolor sit amet consectetur. Molestie erat vitae aliquet elit velit viverra. A ut purus at quis commodo ante ut fusce adipiscing. Eget fermentum fusce etiam blandit amet. Dui iaculis sed viverra sed. Dui sollicitudin sed nisi malesuada feugiat proin in sed adipiscing. Nibh enim eu pellentesque neque maecenas sit eu dictumst urna. Tellus urna.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters-mbl mt-lg-4 mb-3">
                <div className="col-lg-12">
                  <div className="main-card reviewQuote--ftr h-auto">
                    <div className="card">
                      <div className="card-body">
                        <div className="rvwqt-row">
                          <div className="rvwqt-name">
                            <h4>Customer Name</h4>
                            <p><strong>{contactData.organization}</strong> {contactData.title ? "(" + contactData.title + ")" : ''}</p>
                          </div>
                          <div className="rvwqt-deposit">
                            <h4>$000.00</h4>
                            <p><strong>Deposit: $00</strong></p>
                          </div>
                          <div className="rvwqt-download">
                            <a href={baseUrl[0] + 'download-invoice/'} target="_blank" rel="noopener noreferrer"><img src={setImagePath(DOWNLOAD)} alt="" /> Download</a>
                          </div>
                          <div className="rvwqt-btns">
                            <button onClick={(e) => setDeclineModalShow(true)} className="btn btn-danger">Decline</button>
                            <button onClick={(e) => setQuoteRevisionModalShow(true)} className="btn btn-secondary">Request Revision</button>
                            <button onClick={() => setAcceptQuoteModalShow(true)} className="btn btn-primary">Accept</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row no-gutter justify-content-between align-items-center">
                    <div className="col-auto">
                      <p className="rvwqt-note"><b>Note:</b> Both parties will receive an electronically signed copy of quote via email after the customer has accepted the quote.</p>
                    </div>
                    <div className="col-auto">
                      <div className="customer-view-hdr-logo">
                        <a className="navbar-brand" href="#navbar" onClick={(e) => e.preventDefault()}><span className="em">Powered By</span> MyBizz<span>Hive</span></a>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </Modal >
    </>
  )
}

export default SendQuote