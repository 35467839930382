import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import _ from 'lodash';
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file';
import { ADD_CONTACT, VIEW_CONTACT_BASE, VIEW_LEAD_BASE, VIEW_BOOKING_BASE, VIEW_INVOICE_BASE, VIEW_INVOICE_DETAIL_BASE } from "../../../../routing/routeContants";
import { Header } from '../../../component/frontend/header/header'
import { Footer } from '../../../component/frontend/footer/footer'
import { Loader } from '../../../component/frontend/loader/loader'
import { usePrevious, capFirst } from '../../../../common/custom';
import { setImagePath } from '../../../../common/custom'
import { PaginationFooter } from '../../../../common/paginationFooter'
import Select from 'react-select';
import { constants } from "../../../../common/constants";
import { errorPopUp, errorContactDeletePopUp } from '../../../../common/notification-alert';
import { SubscriptionPlan } from "../profile/subscriptionPlans"
import PLUS_ICON from '../../../../assets/images/ic_plus-circle.svg'
import SEARCH_ICON from '../../../../assets/images/search-icn.svg'
import CALL_ICON from '../../../../assets/images/ic_call.svg'
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import SAMPLE_IMPORT_FILE from '../../../../assets/document/sample_import_file.xlsx'
import { getUserDetails } from '../../../../storage/user';
import { listContact, deleteContact, contactImport } from '../../../../duck/contact/contact.action';
import { addConfiguration } from '../../../../duck/profile/profile.action';

const commonFields = {
    email: 'Email Address',
    phone: 'Phone',
    organization: 'Organization',
    title: 'Title',
    website: 'Website',
    birthday: 'Birthday',
    address: 'Address',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    other_1: 'Other 1',
    other_2: 'Other 2',
    other_3: 'Other 3',
    other_4: 'Other 4',
}

const myBizzHiveContactColumnNames = {
    first_name: 'First Name',
    last_name: 'Last Name',
    full_name: 'Full Name',
    ...commonFields
}

const manageColumnFields = {
    name: 'Name',
    ...commonFields
}

const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'grey' : (state.isDisabled ? 'white' : "grey"),
        "&:active": {
            backgroundColor: '#30CDCC',
            color: '#fff',
        },
        color: state.isSelected ? '#fff' : (state.isFocused ? '#000' : state.isDisabled ? '#c0c0c0' : "#000"),
        fontStyle: state.isDisabled ? 'italic' : 'normal',
    }),
    input: (provided) => ({
        ...provided,
        color: "#817F7F",
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 10
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible",
        padding: "0 16px",
        height: '35px',
    }),
}

export const ListContactsPage = props => {
    const focusOutSearch = useRef();
    const dispatch = useDispatch();
    const userData = getUserDetails();
    const currentPlan = userData && userData.planData
    const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
    const listContactData = useSelector(state => state.contact.listContactData);
    const deleteContactData = useSelector(state => state.contact.deleteContactData);
    const prevListContactData = usePrevious({ listContactData });
    const prevDeleteContactData = usePrevious({ deleteContactData });

    // Set initial State Value  
    const [page, setPage] = useState(1);
    const [serviceMessage, setServiceMessage] = useState('');
    const [fetchList, setfetchList] = useState(true);
    const [limit] = useState(constants.PAGE_LIMIT);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [search, setSearch] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [loader, setLoader] = useState(false);
    const [sortingOrder, setSortingOrder] = useState('ASC');
    const [sortingField, setSortingField] = useState('first_name');
    const [allCheckedValue, setAllCheckedValue] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);

    // Set The State Value For Import Contact 
    const fileInput = useRef(null);
    const [contactImportFile, setContactImportFile] = useState([]);
    const [contactImportFileError, setContactImportFileError] = useState('');
    const contactImportData = useSelector(state => state.contact.contactImportData);

    // Manage colunms List Configuration
    const addconfigurationData = useSelector(state => state?.profile?.addConfigurationData?.data?.value);
    const columnValues = useSelector(state => state?.contact?.listContactData?.configuration?.[0]?.value);

    // state for import contact
    const [importContactModalShow, setImportContactModalShow] = useState(false);
    const [mapContactFieldModalShow, setMapContactFieldModalShow] = useState(false);
    const [selectedFileHeaders, setSelectedFileHeaders] = useState(false);
    const [contactMappedObject, setContactMappedObject] = useState({});
    const [manageColumnModalShow, setManageColumnModalShow] = useState(false);
    const [columnVisibility, setColumnVisibility] = useState({
        name: true,
        email: true,
        phone: true,
        organization: true,
        title: true,
        birthday: true,
        website: true,
        address: true,
        other_1: true,
        other_2: true,
        other_3: true,
        other_4: true,
    })
    const [visibleColumns, setVisibleColumns] = useState(columnVisibility);

    // On Load Get Contact
    useEffect(() => {
        setLoader(true)
        dispatch(listContact({ page, limit, sortingField, sortingOrder }))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Manage colunms List Configuration
    useEffect(() => {
        if (addconfigurationData || columnValues) {
            const parsedValue = addconfigurationData || columnValues;
            const newVisibility = {};
            Object.keys(columnVisibility).forEach(key => {
                newVisibility[key] = parsedValue?.includes(key);
            });
            setColumnVisibility(newVisibility);
            setVisibleColumns(newVisibility);
        }
    }, [columnValues, addconfigurationData]);

    // List Contact Data
    useEffect(() => {
        if (prevListContactData && prevListContactData.listContactData !== listContactData) {
            if (listContactData && _.has(listContactData, 'data') && listContactData.success === true) {
                setContactList(listContactData.data)
                setTotalRecords(listContactData.total)
                setLoader(false)
                setfetchList(false);
            }
            if (listContactData && _.has(listContactData, 'message') && listContactData.success === false) {
                setLoader(false)
                setfetchList(false);
                errorPopUp(listContactData.message)
            }
        }
    }, [listContactData, prevListContactData]);

    // Data Get By Pagination
    const getPageData = (page) => {
        setPage(page);
        setLoader(true)
        setAllCheckedValue([]);
        setCheckedAll(false);
        setServiceMessage('');
        dispatch(listContact({ page, limit, filter: search, sortingOrder, sortingField, searchField: "first_name,last_name,email,organization" }))
    }

    //Data Get By Search
    const onSearchResult = (search) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setPage(1);
        setSearch(search);
        setAllCheckedValue([]);
        setCheckedAll(false);
        setfetchList(true);
        setServiceMessage('');
        setTypingTimeout(setTimeout(function () {
            dispatch(listContact({ page, limit, filter: search, sortingField, sortingOrder, searchField: "first_name,last_name,email,organization" }))
        }, 300))
    }

    const keyPressDownEvent = (e) => {
        if (e.key === "Enter") {
            focusOutSearch.current.blur();
        }
    }

    // On change select checkbox value
    const onCheckedValue = (id) => {
        let newArr;
        setServiceMessage('');
        if (_.includes(allCheckedValue, id)) {
            newArr = _.filter(allCheckedValue, (data) => data !== id)
        } else {
            newArr = [...allCheckedValue, id]
        }
        if (newArr.length === contactList.length) {
            setCheckedAll(true)
            setAllCheckedValue(newArr)
        } else {
            setCheckedAll(false)
            setAllCheckedValue(newArr)
        }
    }

    // Delete Contact Data
    const deleteContactFunction = (e) => {
        e.preventDefault();
        setServiceMessage('');
        if (allCheckedValue.length === 0) {
            Swal.fire({
                text: "Please select at least one contact to delete.",
                showConfirmButton: false,
                showCancelButton: true,
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note primeryBtnO',
                cancelButtonText: 'Ok',
            }).then((result) => {

            })
        } else {
            let msg = 'If you delete the contact, all associated Notes, Tasks and references will be lost. Are you sure you want to delete the contact?';
            if (allCheckedValue.length > 1) {
                msg = 'If you delete the contact, all associated Notes, Tasks and references will be lost. Are you sure you want to delete all the selected contacts?';
            }
            Swal.fire({
                title: 'Are you sure?',
                text: msg,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it',
                cancelButtonText: 'No, keep it',
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note',
            }).then((result) => {
                if (result.value) {
                    setLoader(true)
                    dispatch(deleteContact({ contact_id: allCheckedValue.join(',') }))
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // console.log('cancel')
                }
            })
        }
    }

    // Get Delete Contact Data Props
    useEffect(() => {
        if (prevDeleteContactData && prevDeleteContactData.deleteContactData !== deleteContactData) {
            if (deleteContactData && _.has(deleteContactData, 'data') && deleteContactData.success === true) {
                setAllCheckedValue([]);
                if (checkedAll === true) {
                    setCheckedAll(false);
                    setPage(1)
                    dispatch(listContact({ page: 1, limit, filter: search, sortingOrder, sortingField, searchField: "first_name,last_name,email,organization" }))
                } else {
                    setCheckedAll(false);
                    dispatch(listContact({ page, limit, filter: search, sortingOrder, sortingField, searchField: "first_name,last_name,email,organization" }))
                }
            }
            if (deleteContactData && _.has(deleteContactData, 'message') && deleteContactData.success === false) {
                setLoader(false)
                errorContactDeletePopUp(deleteContactData.message)
            }
        }
    }, [deleteContactData, prevDeleteContactData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Sort The Contact List 
    const sortTheData = (e, field, orderValue) => {
        let order = orderValue === 'DESC' ? 'ASC' : (orderValue === 'ASC' ? "DESC" : 'DESC');
        e.preventDefault();
        setPage(1);
        setSortingField(field === "name" ? "first_name" : field);
        setSortingOrder(order);
        setLoader(true)
        setAllCheckedValue([])
        setCheckedAll(false)
        setServiceMessage('');
        dispatch(listContact({ page: 1, limit, filter: search, sortingField: field === "name" ? "first_name" : field, sortingOrder: order, searchField: "first_name,last_name,email,organization" }))
    }

    const checkedAllCheckbox = (data) => {
        setServiceMessage('');
        if (data) {
            let allCheck = _.map(contactList, 'id');
            setAllCheckedValue(allCheck)
        } else {
            setAllCheckedValue([])
        }
        setCheckedAll(data)
    }

    const triggerInputFile = () => {
        fileInput.current.click();
    }

    const handleRequestDocuments = (e) => {
        setServiceMessage('');
        if (e && e.target.value.length !== 0) {
            setContactImportFile(e.target.files)
            setContactImportFileError('')
        }
    }

    const removeSelectedFile = (e) => {
        e.preventDefault();
        setContactImportFile([])
    }

    // Create Contact 
    const createContact = (e) => {
        e.preventDefault()
        // Add Free trial expire then working is blocked
        //if(currentPlan && currentPlan.plan_is_active === 0){
        // Free trial expire then working is fine    
        /* if(currentPlan && currentPlan.plan_is_active === 0 && currentPlan.subscription_product_id !== 1){
            let buttonMsg = currentPlan.subscription_product_id === 1 ? 'View Plans' : 'Renew Plan'
            let warMsg = currentPlan.subscription_product_id === 1 ? 'Free Trial Expired' : 'Subscription Expired'
            let  msg = currentPlan.subscription_product_id === 1 ? 'Your free trial has expired. Please subscribe to a plan to access the application. ' : 'Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ';
            Swal.fire({
                title: warMsg,
                html: msg,
                showCancelButton: true,
                confirmButtonText: buttonMsg,
                cancelButtonText: 'Close',
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note',
            }).then((result) => {
                if (result.value) {
                    setSubscriptionModalShow(true)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // console.log('cancel')
                }
            })
        }else{
            props.history.push(ADD_CONTACT)
        } */
        props.history.push(ADD_CONTACT)
    }

    const handleMapValueSelect = (fileHeaderName, myBizzHiveColumnName) => {
        const obj = contactMappedObject;
        if (obj.hasOwnProperty(fileHeaderName)) {
            obj[fileHeaderName] = myBizzHiveColumnName;
        } else {
            obj[fileHeaderName] = myBizzHiveColumnName;
        }
        setContactMappedObject({ ...obj });
    };

    const handleCancelClick = () => {
        setMapContactFieldModalShow(false);
        setContactMappedObject({});
    };

    const handleContactMapContinueClick = () => {
        setServiceMessage('');
        const replaceFullName = (fields) => {
            const transformedFields = {};
            for (const key in fields) {
                if (Object.hasOwnProperty.call(fields, key)) {
                    const value = fields[key];
                    transformedFields[key] = value === "full_name" ? "first_name" : value;
                }
            }
            return transformedFields;
        };
        const mappedFields = replaceFullName(contactMappedObject);
        const setFilesdata = new FormData();
        setFilesdata.append("import_file", contactImportFile[0]);
        setFilesdata.append("column_mapping", JSON.stringify(mappedFields));
        setLoader(true);
        dispatch(contactImport(setFilesdata));
        setMapContactFieldModalShow(false);
        setContactMappedObject({});
    }
    useEffect(() => {
        if (contactImportData?.success) {
            setLoader(false);
            setContactImportFile([]);
            setImportContactModalShow(false);
            dispatch(listContact({ page, limit, sortingField, sortingOrder }))
        }
    }, [contactImportData]);

    useEffect(() => {
        const select = document.getElementById('floating-select');
        if (select) {
            select.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, []);

    const onManageColumnsCheckedValue = (key, isChecked) => {
        setServiceMessage('');
        setColumnVisibility(prevState => ({
            ...prevState,
            [key]: isChecked,
        }));
    }

    const handleImportContactData = () => {
        setMapContactFieldModalShow(true);
        setServiceMessage('');
        if (contactImportFile.length > 0) {
            const file = contactImportFile[0];
            const extension = file.name.split('.').pop().toLowerCase();
            if (extension === 'xlsx') {
                readXlsxFile(file).then((rows) => {
                    setSelectedFileHeaders(rows[0])
                }).catch((error) => {
                    setContactImportFileError('Error reading the XLSX file. Please check the file format.');
                });
            } else if (extension === 'xls') {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    setSelectedFileHeaders(rows[0])
                };
                reader.readAsArrayBuffer(file);
            } else if (extension === 'csv') {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const rows = e.target.result.split('\n').map(row => row.split(','));
                    setSelectedFileHeaders(rows[0])
                };
                reader.readAsText(file);
            } else {
                setContactImportFileError('Unsupported file format. Please upload a .xlsx, .xls, or .csv file.');
            }
            setImportContactModalShow(true);
        } else {
            setContactImportFileError('Please select a file to import.');
        }
    };

    const handleImportContactCancel = () => {
        setContactImportFile([])
        setImportContactModalShow(false)
    }

    const handleCancelApplyClick = () => {
        setManageColumnModalShow(false);
        if (addconfigurationData || columnValues) {
            const parsedValue = addconfigurationData || columnValues;
            const newVisibility = {};
            Object.keys(columnVisibility).forEach(key => {
                newVisibility[key] = parsedValue?.includes(key);
            });
            setColumnVisibility(newVisibility);
        }
    }

    const handleApplyClick = () => {
        const configurationColumns = Object.entries(columnVisibility)
            .filter(([key, value]) => value)
            .map(([key]) => `${key}`)
            .join(', ')
        const configurationData = new FormData();
        configurationData.append('type', 'contact_column');
        configurationData.append('value', configurationColumns);
        dispatch(addConfiguration(configurationData));
        setManageColumnModalShow(false);
    }

    const anyTrue = Object?.values(columnVisibility)?.some(value => value);

    return (
        <>
            <Loader loader={loader} />
            <div className="main-site fixed--header">
                <Header getMainRoute={'contacts'} />
                <main className="site-body">
                    <section className="page-title contact--header contact-header Contactsheader">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-auto title--col">
                                    <div>
                                        <h2 className="title">Contacts</h2>
                                        <p className="m-0">Total <span>{totalRecords}</span></p>
                                    </div>
                                    <div className="dropdown--search d-lg-none">
                                        <a className="btn dropdown--search_btn" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                            <img src={setImagePath(SEARCH_ICON)} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="collapse d-lg-none col-12 dropdown--search_wrapper" id="collapseExample">
                                    <input type="text" name="search" ref={focusOutSearch} onKeyDown={(e) => keyPressDownEvent(e)} value={search} onChange={(e) => onSearchResult(e.target.value)} placeholder="Search Contact" className="form-control mr-15" />
                                </div>
                                <div className="col-xl-8 col-lg-9 ml-auto d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center title-elems">
                                    <input type="text" name="search" value={search} onChange={(e) => onSearchResult(e.target.value)} placeholder="Search Contact" className="form-control d-none d-lg-block mr-15" />
                                    <button type="button" className="btn text-link mr-15 d-block" onClick={() => setManageColumnModalShow(true)}>Manage Columns</button>
                                    {/* // Add Free trial expire then working is blocked */}
                                    {/* <button type="button" disabled={currentPlan==='' || (currentPlan && currentPlan.plan_is_active===0) ? true : false} onClick={(e) => deleteContactFunction(e)} className="btn btn-dark mr-15">Delete</button> */}
                                    {/* // Free trial expire then working is fine */}
                                    {/* <button type="button" disabled={currentPlan==='' || (currentPlan && currentPlan.plan_is_active===0 && currentPlan.subscription_product_id !== 1) ? true : false} onClick={(e) => deleteContactFunction(e)} className="btn btn-dark mr-15">Delete</button> */}
                                    <button type="button" onClick={(e) => deleteContactFunction(e)} className="btn btn-dark mr-15 trashbtn">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="vuesax/bold/trash">
                                                <g id="trash">
                                                    <path
                                                        id="Vector"
                                                        d="M21.0699 5.23C19.4599 5.07 17.8499 4.95 16.2299 4.86V4.85L16.0099 3.55C15.8599 2.63 15.6399 1.25 13.2999 1.25H10.6799C8.34991 1.25 8.12991 2.57 7.96991 3.54L7.75991 4.82C6.82991 4.88 5.89991 4.94 4.96991 5.03L2.92991 5.23C2.50991 5.27 2.20991 5.64 2.24991 6.05C2.28991 6.46 2.64991 6.76 3.06991 6.72L5.10991 6.52C10.3499 6 15.6299 6.2 20.9299 6.73C20.9599 6.73 20.9799 6.73 21.0099 6.73C21.3899 6.73 21.7199 6.44 21.7599 6.05C21.7899 5.64 21.4899 5.27 21.0699 5.23Z"
                                                        fill="#DB0200"
                                                    />
                                                    <path
                                                        id="Vector_2"
                                                        d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z"
                                                        fill="#DB0200"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                        Delete
                                    </button>
                                    <div className="btn-divider mr-15 d-none d-lg-flex"></div>
                                    <button type="button" onClick={() => setImportContactModalShow(true)} className="btn mr-15 btn-secondary">
                                        Import Contacts
                                    </button>
                                    <button onClick={(e) => createContact(e)} className="btn btn-primary" style={{ padding: "14px 18px" }}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="vuesax/bold/add-square">
                                                <g id="add-square">
                                                    <path
                                                        id="Vector"
                                                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z"
                                                        fill="white"
                                                    />
                                                </g>
                                            </g>
                                        </svg>{" "}
                                        Create Contact
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="middle-section">
                        <div className="container">
                            {serviceMessage ? <div className="errorCls errCommonCls mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{serviceMessage}</div> : ''}
                            <div className="row no-gutters-mbl">
                                <div className="col-12">
                                    <div className="main-card">
                                        <div className="card">
                                            <div className="card-body pt-0">
                                                <div className="table-responsive">
                                                    <table className="table table-lg table-striped contacts--table smart-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    {anyTrue && contactList.length > 0 ?
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" name="check_all" onChange={(e) => checkedAllCheckbox(e.target.checked)} checked={checkedAll} className="custom-control-input" id={'checkAll'} />
                                                                            <label className="custom-control-label" htmlFor={'checkAll'}></label>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </th>
                                                                {visibleColumns && Object.keys(visibleColumns)?.map((data, index) => (
                                                                    visibleColumns[data] && (
                                                                        <th key={index} style={{ paddingLeft: `${data === "name" && "0"}`, padding: `${data !== "name" ? "10px" : "10px 10px 10px 0"}` }}>
                                                                            <div className="table--sorting">
                                                                                {manageColumnFields[data]}
                                                                                <div className="sorting-icn">
                                                                                    <a href="#desc" className={sortingField === data ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, data, sortingOrder)}>
                                                                                        <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                                                                        </svg>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                    )
                                                                ))}
                                                                <th scope="col">Related to</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(anyTrue && contactList?.length > 0) ?
                                                                _.map(contactList, (data) => {
                                                                    return <tr key={data.id}>
                                                                        {
                                                                            <td>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={data.id}
                                                                                        onChange={(e) => onCheckedValue(data.id, e.target.checked)}
                                                                                        checked={_.includes(allCheckedValue, data.id)}
                                                                                        className="custom-control-input"
                                                                                        id={'check' + data.id}
                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor={'check' + data.id}></label>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        {visibleColumns && Object.keys(visibleColumns)?.map((header, index) => {
                                                                            if (visibleColumns[header]) {
                                                                                let cellData = '';
                                                                                switch (header) {
                                                                                    case 'name':
                                                                                        cellData = (
                                                                                            <td className="contact--name" style={{ paddingLeft: "0", minWidth: "200px" }}>
                                                                                                {data?.first_name || data?.last_name ? (
                                                                                                    <Link to={VIEW_CONTACT_BASE + data.id} className="text-link">
                                                                                                        {(data?.first_name ? capFirst(data?.first_name) : '') + ' ' + (data?.last_name ? capFirst(data?.last_name) : '')}
                                                                                                    </Link>
                                                                                                ) : (
                                                                                                    '-'
                                                                                                )}
                                                                                                <div className="contact--id_mbl">{data.email}</div>
                                                                                            </td>
                                                                                        );
                                                                                        break;
                                                                                    case 'phone':
                                                                                        cellData = <td className="contact--no" style={{ minWidth: "150px" }}><span>{data.phone || '-'}</span><a href={"Tel:" + (data.phone || '-')}><img src={setImagePath(CALL_ICON)} alt="" /></a></td>;
                                                                                        break;
                                                                                    case 'address':
                                                                                        const addressLine1 = data.address_line_1 || '';
                                                                                        const addressLine2 = data.address_line_2 ? ', ' + data.address_line_2 : '';
                                                                                        const city = data.city || '';
                                                                                        const state = data.state || '';
                                                                                        const zip = data.zip || '';
                                                                                        const fullAddress = `${addressLine1}${addressLine2} ${city} ${state} ${zip}`;
                                                                                        cellData = (
                                                                                            <td>
                                                                                                {fullAddress.trim() ? fullAddress : "-"}
                                                                                            </td>
                                                                                        );
                                                                                        break;
                                                                                    default:
                                                                                        cellData = <td>{data[header] || '-'}</td>;
                                                                                        break;
                                                                                }
                                                                                return <React.Fragment key={index}>{cellData}</React.Fragment>;
                                                                            }
                                                                        })}
                                                                        <td className="lead--name" style={{ minWidth: "120px" }}>
                                                                            {/* {(data.leads).length > 0 ?
                                                                                _.map(data.leads, (lead, k) => {
                                                                                    return <React.Fragment key={k}><Link to={VIEW_LEAD_BASE + lead.id} className="text-link" >{lead.name}</Link>{(data.leads).length === k + 1 ? '' : ', '}</React.Fragment>
                                                                                })
                                                                                : ''} */}
                                                                            {(data.bookings).length > 0 ?
                                                                                _.map(data.bookings, (book, k) => {
                                                                                    return <React.Fragment key={k}>{(data.leads).length === 1 ? ', ' : ''}<Link to={VIEW_BOOKING_BASE + book.id} className="text-link" >{book.name}</Link>{(data.bookings).length === k + 1 ? '' : ', '}</React.Fragment>
                                                                                })
                                                                                : ''}
                                                                            {/* {(data.invoices).length > 0 ?
                                                                                _.map(data.invoices, (invoice, k) => {
                                                                                    return <React.Fragment key={k}>{(data.bookings).length === 1 ? ', ' : ''}<Link to={(invoice.invoice_status_type_id !== 1 ? VIEW_INVOICE_DETAIL_BASE : VIEW_INVOICE_BASE) + invoice.id} className="text-link" >{invoice.name}</Link>{(data.invoices).length === k + 1 ? '' : ', '}</React.Fragment>
                                                                                })
                                                                                : ''} */}
                                                                            {(data.bookings).length === 0 && (data.leads).length === 0 && (data.invoices).length === 0 ? '-' : ''}
                                                                        </td>
                                                                    </tr>;
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan="6" className="bg-white">
                                                                        {fetchList ?
                                                                            ''
                                                                            :
                                                                            <div className="no--contacts">
                                                                                {search && search !== ''
                                                                                    ?
                                                                                    <h5>No matching records were found. Please check your keyword and try again.</h5>
                                                                                    :
                                                                                    <>
                                                                                        <h5>You don’t have any contacts yet! </h5>
                                                                                        <h5>Create new contact or Import contacts from email and manage them in all in one place. </h5>
                                                                                        <p>Learn how to export contacts from, <a href="https://knowledgebase.constantcontact.com/articles/KnowledgeBase/5702-export-contacts-from-microsoft-outlook?lang=en_US" rel="noopener noreferrer" target="_blank" ><strong>Outlook to Excel</strong></a> or <a href="https://www.clientlook.com/export-your-google-contacts" rel="noopener noreferrer" target="_blank"><strong>Gmail to Excel</strong></a></p>
                                                                                        <button onClick={(e) => createContact(e)} className="btn btn-primary mt-5">Create Contact</button>
                                                                                    </>
                                                                                }
                                                                            </div>}
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {anyTrue && totalRecords ? <PaginationFooter getPageData={(data) => getPageData(data)} pageNo={page} totalRecords={totalRecords} limit={limit} /> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                {/* IMPORT CONTACTS POP-UP */}
                <Modal size="lg" className="" centered show={importContactModalShow} onHide={handleImportContactCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Import Contacts
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {contactImportFileError ?
                            (contactImportData.message === 'unableToImport') ?
                                <div className="errorCls errCommonCls mb-3 error-unable-to-import"><img src={setImagePath(ERROR_ICON)} alt="" />Sorry, we could not import your contacts. Please make sure your file<br /> - format is supported and, <br />- has column ‘names’ exactly matching with the labels listed below</div>
                                :
                                <div className="errorCls errCommonCls mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{contactImportFileError}</div>
                            : ''}
                        <p><strong>Upload file with your contacts.</strong></p>
                        <div className="browe--file">
                            {contactImportFile.length > 0 ?
                                _.map(contactImportFile, (docV, docK) => {
                                    return <div className="file-added" key={docK}>
                                        {docV.name}
                                        <a href="#removeSelectedFile" onClick={(e) => removeSelectedFile(e)} className="edit-icn ml-3">
                                            <svg width="20px" height="20px" fill="var(--danger)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 174.239 174.239" style={{ "enableBackground": "new 0 0 174.239 174.239" }} xmlSpace="preserve">
                                                <g>
                                                    <path d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
                                        c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
                                                    />
                                                    <path d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
                                        s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
                                        c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
                                        c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"/>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                })
                                :
                                <>
                                    <input
                                        ref={fileInput}
                                        type="file"
                                        className="hiddenInputTypeFile"
                                        onChange={(e) => handleRequestDocuments(e)}
                                        accept='.xlsx, .xls, .csv'
                                        style={{ "display": "none" }}
                                    />
                                    <button type="button" className="btn btn-secondary bg-white mb-2" onClick={() => triggerInputFile()}>Browse File</button>
                                </>
                            }
                            <p className="mb-3 mt-2">Accepted formats: <strong>.CSV, .XLS, .XLSX</strong></p>
                            <p className="mb-0">Download a <strong><a href={SAMPLE_IMPORT_FILE} download="sample_import_file.xlsx">Sample File</a></strong></p>
                        </div>
                        <p className="mb-4">Learn how to export contacts from, <a href="https://knowledgebase.constantcontact.com/articles/KnowledgeBase/5702-export-contacts-from-microsoft-outlook?lang=en_US" rel="noopener noreferrer" target="_blank" ><strong>Outlook to Excel</strong></a> or <a href="https://www.clientlook.com/export-your-google-contacts" rel="noopener noreferrer" target="_blank"><strong>Gmail to Excel</strong></a></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-dark" onClick={handleImportContactCancel}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={handleImportContactData} disabled={!contactImportFile.length}>Import</button>
                    </Modal.Footer>
                </Modal>

                {/* IMPORT CONTACT MORE FIELD'S MODEL */}
                <Modal size="lg" className="" centered show={mapContactFieldModalShow} onHide={() => setMapContactFieldModalShow(false)} >
                    <Modal.Header closeButton className="flex-column pb-2">
                        <Modal.Title>
                            Match Columns/Fields
                        </Modal.Title>
                        <p className="mb-0">
                            Please match the columns from the <b>{contactImportFile[0]?.name}</b> with fields and hit submit to import contacts.
                        </p>
                    </Modal.Header>
                    <div style={{ overflowY: "visible", marginTop: "4px" }}>
                        <Modal.Body className="pb-3">
                            <div className="row">
                                {selectedFileHeaders?.length && selectedFileHeaders?.map((value, index) => (
                                    <div className="col-6 row m-0 p-0" key={index}>
                                        <div className="col-4">
                                            <div className="mt-2 importContactSelectheading">
                                                {value}
                                            </div>
                                        </div>
                                        <div className="floating-label col-7 px-0" style={{ marginTop: "5px", height: "49px" }}>
                                            <Select
                                                styles={selectStyle}
                                                className="floating-select"
                                                name={value}
                                                placeholder="Choose..."
                                                id="floating-select"
                                                isSearchable={false}
                                                options={Object.entries(myBizzHiveContactColumnNames).map(([key, fieldValue]) => {
                                                    return (
                                                        {
                                                            value: key,
                                                            label: fieldValue,
                                                            isDisabled:
                                                                (
                                                                    (fieldValue === 'Full Name' && (Object.values(contactMappedObject).includes('first_name') || Object.values(contactMappedObject).includes('last_name'))) ||
                                                                    (fieldValue === 'First Name' && (Object.values(contactMappedObject).includes('full_name'))) ||
                                                                    (fieldValue === 'Last Name' && (Object.values(contactMappedObject).includes('full_name'))) ||
                                                                    (fieldValue === 'Address' && (Object.values(contactMappedObject).includes('address_line_1') || Object.values(contactMappedObject).includes('address_line_2') || Object.values(contactMappedObject).includes('state') || Object.values(contactMappedObject).includes('city') || Object.values(contactMappedObject).includes('zip'))) ||
                                                                    (fieldValue === 'Address Line 1' && (Object.values(contactMappedObject).includes('address'))) ||
                                                                    (fieldValue === 'Address Line 2' && (Object.values(contactMappedObject).includes('address'))) ||
                                                                    (fieldValue === 'State' && (Object.values(contactMappedObject).includes('address'))) ||
                                                                    (fieldValue === 'City' && (Object.values(contactMappedObject).includes('address'))) ||
                                                                    (fieldValue === 'Zip' && (Object.values(contactMappedObject).includes('address'))) ||
                                                                    Object.values(contactMappedObject).includes(key)
                                                                )
                                                        })
                                                })}
                                                onChange={(e) => handleMapValueSelect(value, e.value)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                    </div>
                    <Modal.Footer style={{ paddingTop: "1rem" }}>
                        <button type="button" className="btn btn-dark" onClick={handleCancelClick}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={handleContactMapContinueClick} disabled={!Object.values(contactMappedObject).includes('first_name') && !Object.values(contactMappedObject).includes('full_name')}>Continue</button>
                    </Modal.Footer>
                </Modal>

                {/* IMPORT MANAGE COLUMNS POP-UP */}
                <Modal size="md" className="" centered show={manageColumnModalShow} onHide={handleCancelApplyClick} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Show/Hide Columns
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container pl-5">
                            {Object.entries(visibleColumns).map(([key]) => (
                                <div className="custom-control custom-checkbox col-6 row m-0 p-0" key={key}>
                                    <input
                                        type="checkbox"
                                        name={key || ''}
                                        onChange={(e) => onManageColumnsCheckedValue(key, e.target.checked)}
                                        checked={columnVisibility[key]}
                                        className="custom-control-input"
                                        id={'check' + key}
                                        disabled={key === 'name'}
                                    />
                                    <label
                                        className="custom-control-label custom-cursor mb-2"
                                        htmlFor={'check' + key}
                                    >
                                        {manageColumnFields[key]}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ paddingTop: "1rem" }}>
                        <button type="button" className="btn btn-dark" onClick={handleCancelApplyClick}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={handleApplyClick} disabled={!Object.values(columnVisibility).some(value => value)}>Apply</button>
                    </Modal.Footer>
                </Modal>
                <Footer />
            </div >
            {/* Subscription Modal*/}
            < SubscriptionPlan loader={(data) => setLoader(data)}
                openSubscriptionModal={subscriptionModalShow}
                closeSubscriptionModal={() => setSubscriptionModalShow(false)}
                updatePlanDetail={(data) => { setSubscriptionModalShow(false); setLoader(false) }}
                currentPlan={currentPlan}
            />
        </>
    );
}

export const ListContacts = withRouter(ListContactsPage)
