// Add Booking
export const ADD_BOOKING = 'ADD_BOOKING';
export const addBooking = (data) => ({ type: ADD_BOOKING, data });
export const SUCCESS_ADD_BOOKING = 'SUCCESS_ADD_BOOKING';
export const ERROR_ADD_BOOKING = 'ERROR_ADD_BOOKING';
export const addBookingResponse = (type, data) => ({ type, data });

// List Booking
export const LIST_BOOKINGS = 'LIST_BOOKINGS';
export const listBookings = (data) => ({ type: LIST_BOOKINGS, data });
export const SUCCESS_LIST_BOOKINGS = 'SUCCESS_LIST_BOOKINGS';
export const ERROR_LIST_BOOKINGS = 'ERROR_LIST_BOOKINGS';
export const listBookingsResponse = (type, data) => ({ type, data });
export const IS_FROM_BOOKING_LIST = 'IS_FROM_BOOKING_LIST';
export const isFrombookingList = (data) => ({ type: 'IS_FROM_BOOKING_LIST', data });

//Delete Booking Data export const listBookings = (data) => ({ type: LIST_BOOKINGS, data });
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const deleteBooking = (data) => ({ type: DELETE_BOOKING, data });
export const SUCCESS_DELETE_BOOKING = 'SUCCESS_DELETE_BOOKING';
export const ERROR_DELETE_BOOKING = 'ERROR_DELETE_BOOKING';
export const deleteBookingResponse = (type, data) => ({ type, data });

//Get Booking Data By Id 
export const GET_BOOKING_BY_ID = 'GET_BOOKING_BY_ID';
export const getBookingById = (data) => ({ type: GET_BOOKING_BY_ID, data });
export const SUCCESS_GET_BOOKING_BY_ID = 'SUCCESS_GET_BOOKING_BY_ID';
export const ERROR_GET_BOOKING_BY_ID = 'ERROR_GET_BOOKING_BY_ID';
export const getBookingByIdResponse = (type, data) => ({ type, data });

// Update Booking
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const updateBooking = (data) => ({ type: UPDATE_BOOKING, data });
export const SUCCESS_UPDATE_BOOKING = 'SUCCESS_UPDATE_BOOKING';
export const ERROR_UPDATE_BOOKING = 'ERROR_UPDATE_BOOKING';
export const updateBookingResponse = (type, data) => ({ type, data });

// Add Booking Note
export const ADD_BOOKING_NOTE = 'ADD_BOOKING_NOTE';
export const addBookingNote = (data) => ({ type: ADD_BOOKING_NOTE, data });
export const SUCCESS_ADD_BOOKING_NOTE = 'SUCCESS_ADD_BOOKING_NOTE';
export const ERROR_ADD_BOOKING_NOTE = 'ERROR_ADD_BOOKING_NOTE';
export const addBookingNoteResponse = (type, data) => ({ type, data });

// List Booking Note
export const LIST_BOOKING_NOTE = 'LIST_BOOKING_NOTE';
export const listBookingNote = (data) => ({ type: LIST_BOOKING_NOTE, data });
export const SUCCESS_LIST_BOOKING_NOTE = 'SUCCESS_LIST_BOOKING_NOTE';
export const ERROR_LIST_BOOKING_NOTE = 'ERROR_LIST_BOOKING_NOTE';
export const listBookingNoteResponse = (type, data) => ({ type, data });

//Delete Booking Note Data 
export const DELETE_BOOKING_NOTE = 'DELETE_BOOKING_NOTE';
export const deleteBookingNote = (data) => ({ type: DELETE_BOOKING_NOTE, data });
export const SUCCESS_DELETE_BOOKING_NOTE = 'SUCCESS_DELETE_BOOKING_NOTE';
export const ERROR_DELETE_BOOKING_NOTE = 'ERROR_DELETE_BOOKING_NOTE';
export const deleteBookingNoteResponse = (type, data) => ({ type, data });

// Update Booking Note Data 
export const UPDATE_BOOKING_NOTE = 'UPDATE_BOOKING_NOTE';
export const updateBookingNote = (data) => ({ type: UPDATE_BOOKING_NOTE, data });
export const SUCCESS_UPDATE_BOOKING_NOTE = 'SUCCESS_UPDATE_BOOKING_NOTE';
export const ERROR_UPDATE_BOOKING_NOTE = 'ERROR_UPDATE_BOOKING_NOTE';
export const updateBookingNoteResponse = (type, data) => ({ type, data });

// Add Booking Note
export const ADD_BOOKING_TASK = 'ADD_BOOKING_TASK';
export const addBookingTask = (data) => ({ type: ADD_BOOKING_TASK, data });
export const SUCCESS_ADD_BOOKING_TASK = 'SUCCESS_ADD_BOOKING_TASK';
export const ERROR_ADD_BOOKING_TASK = 'ERROR_ADD_BOOKING_TASK';
export const addBookingTaskResponse = (type, data) => ({ type, data });

// List Booking Task
export const LIST_BOOKING_TASK = 'LIST_BOOKING_TASK';
export const listBookingTask = (data) => ({ type: LIST_BOOKING_TASK, data });
export const SUCCESS_LIST_BOOKING_TASK = 'SUCCESS_LIST_BOOKING_TASK';
export const ERROR_LIST_BOOKING_TASK = 'ERROR_LIST_BOOKING_TASK';
export const listBookingTaskResponse = (type, data) => ({ type, data });

//Delete Booking Task Data 
export const DELETE_BOOKING_TASK = 'DELETE_BOOKING_TASK';
export const deleteBookingTask = (data) => ({ type: DELETE_BOOKING_TASK, data });
export const SUCCESS_DELETE_BOOKING_TASK = 'SUCCESS_DELETE_BOOKING_TASK';
export const ERROR_DELETE_BOOKING_TASK = 'ERROR_DELETE_BOOKING_TASK';
export const deleteBookingTaskResponse = (type, data) => ({ type, data });

// Update Booking Task Data 
export const UPDATE_BOOKING_TASK = 'UPDATE_BOOKING_TASK';
export const updateBookingTask = (data) => ({ type: UPDATE_BOOKING_TASK, data });
export const SUCCESS_UPDATE_BOOKING_TASK = 'SUCCESS_UPDATE_BOOKING_TASK';
export const ERROR_UPDATE_BOOKING_TASK = 'ERROR_UPDATE_BOOKING_TASK';
export const updateBookingTaskResponse = (type, data) => ({ type, data });

// Create Appointment
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const createAppointment = (data) => ({ type: CREATE_APPOINTMENT, data });
export const SUCCESS_CREATE_APPOINTMENT = 'SUCCESS_CREATE_APPOINTMENT';
export const ERROR_CREATE_APPOINTMENT = 'ERROR_CREATE_APPOINTMENT';
export const createAppointmentResponse = (type, data) => ({ type, data });

// Booking List View
export const BOOKING_LIST_VIEW = 'BOOKING_LIST_VIEW';
export const bookingListView = (data) => ({ type: BOOKING_LIST_VIEW, data });
export const SUCCESS_BOOKING_LIST_VIEW = 'SUCCESS_BOOKING_LIST_VIEW';
export const ERROR_BOOKING_LIST_VIEW = 'ERROR_BOOKING_LIST_VIEW';
export const bookingListViewResponse = (type, data) => ({ type, data });

// Delete appointment
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const deleteAppointment = (data) => ({ type: DELETE_APPOINTMENT, data });
export const SUCCESS_DELETE_APPOINTMENT = 'SUCCESS_DELETE_APPOINTMENT';
export const ERROR_DELETE_APPOINTMENT = 'ERROR_DELETE_APPOINTMENT';
export const deleteAppointmentResponse = (type, data) => ({ type, data });

// Update appointment
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const updateAppointment = (data) => ({ type: UPDATE_APPOINTMENT, data });
export const SUCCESS_UPDATE_APPOINTMENT = 'SUCCESS_UPDATE_APPOINTMENT';
export const ERROR_UPDATE_APPOINTMENT = 'ERROR_UPDATE_APPOINTMENT';
export const updateAppointmentResponse = (type, data) => ({ type, data });


// Create Booking Payment 
export const CREATE_BOOKING_PAYMENT = 'CREATE_BOOKING_PAYMENT';
export const createBookingPayment = (data) => ({ type: CREATE_BOOKING_PAYMENT, data });
export const SUCCESS_CREATE_BOOKING_PAYMENT = 'SUCCESS_CREATE_BOOKING_PAYMENT';
export const ERROR_CREATE_BOOKING_PAYMENT = 'ERROR_CREATE_BOOKING_PAYMENT';
export const createBookingPaymentResponse = (type, data) => ({ type, data });