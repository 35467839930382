export const DEFAULT_STATE = {
    listQuoteData: {},
    addQuoteData: {},
    addQuoteTemplateData: {},
    getQuoteTemplateByIdData: {},
    getQuoteByIdData: {},
    updateQuoteCustomerData: {},
    updateQuoteData: {},
    sendToCustomerQuoteData: {},
    viewCustomerQuoteData: {},
    changeQuoteStatusData: {},
    reviseQuoteData: {},
    getAcceptedQuoteData: {},
    deleteQuoteData: {},
    listQuoteTemplateData: {},
    messageBodyData: {},
    attachmentsBrowseFileQuoteData: {},
    attachmentsBrowseFileDeleteQuoteData: {},
};
