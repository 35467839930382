export const DEFAULT_STATE = {
  loading: false,
  errorTokenData: {},
  getGmailAuthLinkData: {},
  getGmailAuthTokenData: {},
  getGmailTokenData: {},
  getAllGmailMessagesData: {},
  getGmailMessageData: [],
  getGmailMessageByIdData: {},
  sendEmailResponseData: {},
  getGmailMessageAttachmentData: {},
  deleteGmailMessageData: {},
  getAllDraftGmailMessagesData: {},
  getDraftGmailMessageData: [],
  getDraftGmailMessageByIdData: {},
  createDraftMessageData: {},
  updateDraftMessageData: {},
  deleteDraftMessageData: {},
  saveSocialAuthTokenData: {},
  gmailAccountDeleteData: {},
};
