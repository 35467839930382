export const DEFAULT_STATE = {
    getProfileDetailData: {},
    ownerProfileDetailData: {},
    updatePasswordData: {},
    businessProfileDetailData: {},
    getMenuCountData: {},
    connectWithPaypalData: {},
    makeTestPaymentData: {},
    deletePaymentAccountData: {},
    getSubscriptionPlanData: {},
    createSubscriptionPlanData: {},
    planApplyCouponData: {},
    getStateTaxData: {},
    cancelSubscriptionPlanData: {},
    profileServiceData: {},
    dummyDeleteData: {},
    state_data: {},
    auto_reply_data: {},
    disableAuthentication2FAData: {},
    verifyAuthentication2FAData: {},
    setupAuthentication2FAData: {},
    deleteHeaderImageData: {},
    // Manage colunms List Configuration
    addConfigurationData: {},
}