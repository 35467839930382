// Get google Calendar event list Data
export const GET_CALENDAR_LIST_DATA = 'GET_CALENDAR_LIST_DATA';
export const getCalendarListData = (data) => ({ type: GET_CALENDAR_LIST_DATA, data });
export const SUCCESS_GET_CALENDAR_LIST_DATA = 'SUCCESS_GET_CALENDAR_LIST_DATA';
export const ERROR_GET_CALENDAR_LIST_DATA = 'ERROR_GET_CALENDAR_LIST_DATA';
export const getCalendarListResponse = (type, data) => ({ type, data });

// Delete google Calendar event list Data
export const GET_DELETE_CALENDAR_LIST_DATA = 'GET_DELETE_CALENDAR_LIST_DATA';
export const deleteCalendarListData = (data) => ({ type: GET_DELETE_CALENDAR_LIST_DATA, data });
export const SUCCESS_DELETE_GET_CALENDAR_LIST_DATA = 'SUCCESS_DELETE_GET_CALENDAR_LIST_DATA';
export const ERROR_DELETE_GET_CALENDAR_LIST_DATA = 'ERROR_DELETE_GET_CALENDAR_LIST_DATA';
export const getDeleteCalendarListResponse = (type, data) => ({ type, data });
export const GET_DELETE_BATCH_CALENDAR_LIST_DATA = 'GET_DELETE_BATCH_CALENDAR_LIST_DATA';   
export const deleteBatchCalendarListData = (data) => ({ type: GET_DELETE_BATCH_CALENDAR_LIST_DATA, data });
export const SUCCESS_DELETE_BATCH_GET_CALENDAR_LIST_DATA = 'SUCCESS_DELETE_BATCH_GET_CALENDAR_LIST_DATA';
export const ERROR_DELETE_BATCH_GET_CALENDAR_LIST_DATA = 'ERROR_DELETE_BATCH_GET_CALENDAR_LIST_DATA';
export const getBacthDeleteCalendarListResponse = (type, data) => ({ type, data });

// Edit google Calendar event list Data
export const EDIT_CALENDAR_LIST_DATA = 'EDIT_CALENDAR_LIST_DATA';
export const editCalendarListData = (data) => ({ type: EDIT_CALENDAR_LIST_DATA, data });
export const SUCCESS_EDIT_CALENDAR_LIST_DATA = 'SUCCESS_EDIT_CALENDAR_LIST_DATA';
export const ERROR_EDIT_CALENDAR_LIST_DATA = 'ERROR_EDIT_CALENDAR_LIST_DATA';
export const getEditCalendarListResponse = (type, data) => ({ type, data });

// Reset State
export const RESET_CALENDAR_STATE = 'RESET_CALENDAR_STATE';
export const resetCalendarState = (data) => ({ type: RESET_CALENDAR_STATE, data });


// Get Microsoft Calendar event list Data

export const GET_MICROSOFT_CALENDAR_LIST_DATA = 'GET_MICROSOFT_CALENDAR_LIST_DATA';
export const getMicrosoftCalendarListData = (data) => ({ type: GET_MICROSOFT_CALENDAR_LIST_DATA, data });
export const SUCCESS_GET_MICROSOFT_CALENDAR_LIST_DATA = 'SUCCESS_GET_MICROSOFT_CALENDAR_LIST_DATA';
export const ERROR_GET_MICROSOFT_CALENDAR_LIST_DATA = 'ERROR_GET_MICROSOFT_CALENDAR_LIST_DATA';
export const getMicrosoftCalendarListResponse = (type, data) => ({ type, data });

// Delete Microsoft Calendar event list Data
export const DELETE_MICROSOFT_EVENT = 'DELETE_MICROSOFT_EVENT';
export const deleteMicrosoftEventData = (data) => ({ type: DELETE_MICROSOFT_EVENT, data });
export const SUCCESS_DELETE_MICROSOFT_EVENT = 'SUCCESS_DELETE_MICROSOFT_EVENT';
export const ERROR_DELETE_MICROSOFT_EVENT = 'ERROR_DELETE_MICROSOFT_EVENT';
export const getDeleteMicrosoftEventResponse = (type, data) => ({ type, data });

// Edit Microsoft Calendar event list Data

export const EDIT_MICROSOFT_CALENDAR_LIST_DATA = 'EDIT_MICROSOFT_CALENDAR_LIST_DATA';
export const editMicrosoftCalendarListData = (data) => ({ type: EDIT_MICROSOFT_CALENDAR_LIST_DATA, data });
export const SUCCESS_MICROSOFT_EDIT_CALENDAR_LIST_DATA = 'SUCCESS_MICROSOFT_EDIT_CALENDAR_LIST_DATA';
export const ERROR_MICROSOFT_EDIT_CALENDAR_LIST_DATA = 'ERROR_MICROSOFT_EDIT_CALENDAR_LIST_DATA';
export const getEditMicrosoftCalendarListResponse = (type, data) => ({ type, data });