import {
    SUCCESS_CATEGORY_LIST, ERROR_CATEGORY_LIST,
    SUCCESS_CATEGORY_CREATE, ERROR_CATEGORY_CREATE,
    SUCCESS_EXPENSE_LIST, ERROR_EXPENSE_LIST,
    SUCCESS_EXPENSE_DELETE, ERROR_EXPENSE_DELETE,
    SUCCESS_EXPENSE_CREATE, ERROR_EXPENSE_CREATE,
    SUCCESS_EXPENSE_DOWNLOAD, ERROR_EXPENSE_DOWNLOAD,
} from './expenses.action';
import { DEFAULT_STATE } from "./expenses.state";

export const expenseReducer = (state = DEFAULT_STATE, action = {
    type: {},
    data: {}
}) => {
    switch (action.type) {
        // Category List
        case SUCCESS_CATEGORY_LIST:
            const categoryListData = action.data;
            return { ...state, categoryListData }
        case ERROR_CATEGORY_LIST:
            const errorCategoryListData = action.data;
            return { ...state, categoryListData: errorCategoryListData }

        // Category Create
        case SUCCESS_CATEGORY_CREATE:
            const categoryCreateteData = action.data.data;
            return { ...state, expenseCreateData: { value: categoryCreateteData.name, label: categoryCreateteData.name, id: categoryCreateteData.id }, categoryListData: { ...state?.categoryListData, data: [...state?.categoryListData?.data, categoryCreateteData] } }
        case ERROR_CATEGORY_CREATE:
            const errorCategoryCreateteData = action.data;
            return { ...state, categoryCreateteData: errorCategoryCreateteData }

        // Expenses List
        case SUCCESS_EXPENSE_LIST:
            const expenseListData = action.data;
            return { ...state, expenseListData }
        case ERROR_EXPENSE_LIST:
            const errorExpenseListData = action.data;
            return { ...state, expenseListData: errorExpenseListData }
        case "RESET_EXPANCE_LIST":
            return { ...state, expenseListData: null }

        // Expenses Create
        case SUCCESS_EXPENSE_CREATE:
            const expenseCreateData = action.data.data;
            return { ...state, expenseListData: { ...state.expenseListData, data: [...state.expenseListData.data, expenseCreateData] } }
        case ERROR_EXPENSE_CREATE:
            const errorExpenseCreateData = action.data;
            return { ...state, expenseCreateData: errorExpenseCreateData }

        // Expenses Delete
        case SUCCESS_EXPENSE_DELETE:
            const expenseDeleteData = action.data;
            return { ...state, expenseDeleteData }
        case ERROR_EXPENSE_DELETE:
            const errorExpenseDeleteData = action.data;
            return { ...state, expenseDeleteData: errorExpenseDeleteData }

        // Expenses Download
        case SUCCESS_EXPENSE_DOWNLOAD:
            const expenseDownloadData = action.data;
            return { ...state, expenseDownloadData }
        case ERROR_EXPENSE_DOWNLOAD:
            const errorExpenseDownloadData = action.data;
            return { ...state, expenseDownloadData: errorExpenseDownloadData }

        default:
            return state;
    }
};
