import {
    getCalenderEventsListApi,deleteCalenderEventListApi,editCalenderEventListApi,getMicrosoftEventsListApi,deleteMicrosoftEventApi,editMicrosoftEventApi,deleteBatchCalendarEventListApi
  } from "../../../api/index";
import { successNotification,errorNotification } from "../../common/notification-alert";

  import {
    getCalendarListResponse,
    SUCCESS_GET_CALENDAR_LIST_DATA,
    ERROR_GET_CALENDAR_LIST_DATA,
    GET_CALENDAR_LIST_DATA,
    getDeleteCalendarListResponse,
    GET_DELETE_CALENDAR_LIST_DATA,
    SUCCESS_DELETE_GET_CALENDAR_LIST_DATA,
    ERROR_DELETE_GET_CALENDAR_LIST_DATA,
    getEditCalendarListResponse,
    EDIT_CALENDAR_LIST_DATA,
    SUCCESS_EDIT_CALENDAR_LIST_DATA,
    ERROR_EDIT_CALENDAR_LIST_DATA,
    GET_MICROSOFT_CALENDAR_LIST_DATA,
    SUCCESS_GET_MICROSOFT_CALENDAR_LIST_DATA,
    ERROR_GET_MICROSOFT_CALENDAR_LIST_DATA,
    getMicrosoftCalendarListResponse,
    DELETE_MICROSOFT_EVENT,
    SUCCESS_DELETE_MICROSOFT_EVENT,
    ERROR_DELETE_MICROSOFT_EVENT,
    getDeleteMicrosoftEventResponse,
    EDIT_MICROSOFT_CALENDAR_LIST_DATA,
    SUCCESS_MICROSOFT_EDIT_CALENDAR_LIST_DATA,
    ERROR_MICROSOFT_EDIT_CALENDAR_LIST_DATA,
    getEditMicrosoftCalendarListResponse,
    getBacthDeleteCalendarListResponse,
    SUCCESS_DELETE_BATCH_GET_CALENDAR_LIST_DATA,
    ERROR_DELETE_BATCH_GET_CALENDAR_LIST_DATA,
    GET_DELETE_BATCH_CALENDAR_LIST_DATA
  } from "./calendar.action";

  import _ from "lodash";
  
  import { put, takeLatest } from "redux-saga/effects";

// Get Calendar event list Data
function* getCalendarEventListRequest(data) {
    let getData = yield getCalenderEventsListApi(data);
    if (getData.success && _.has(getData, "data")) {
      yield put(getCalendarListResponse(SUCCESS_GET_CALENDAR_LIST_DATA, getData.data));
    } else {
      yield put(getCalendarListResponse(ERROR_GET_CALENDAR_LIST_DATA, getData.data));
    }
  }

  export function* getCalendarEventListWatcher() {
    yield takeLatest(GET_CALENDAR_LIST_DATA, getCalendarEventListRequest);
  }

  // delete Calendar google event list Data
function* deleteCalendarEventListRequest(data) {
  let getData = yield deleteCalenderEventListApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(getDeleteCalendarListResponse(SUCCESS_DELETE_GET_CALENDAR_LIST_DATA, getData.data));
  } else {
    yield put(getDeleteCalendarListResponse(ERROR_DELETE_GET_CALENDAR_LIST_DATA, getData.data));
  }
}
export function* deleteCalendarEventListWatcher() {
  yield takeLatest(GET_DELETE_CALENDAR_LIST_DATA, deleteCalendarEventListRequest);
}

// delete Calendar google event list Data
function* deleteBatchCalendarEventListRequest(data) {
  let getData = yield deleteBatchCalendarEventListApi(data);
  if (getData?.success && _.has(getData, "data")) {
    successNotification("Records deleted successfully.")
    yield put(getBacthDeleteCalendarListResponse(SUCCESS_DELETE_BATCH_GET_CALENDAR_LIST_DATA, getData.data));
  } else {
    yield put(getBacthDeleteCalendarListResponse(ERROR_DELETE_BATCH_GET_CALENDAR_LIST_DATA, getData.data));
  }
}

export function* deleteBatchCalendarEventListWatcher() {
  yield takeLatest(GET_DELETE_BATCH_CALENDAR_LIST_DATA, deleteBatchCalendarEventListRequest);
}

// edit Calendar google event list Data
function* editCalendarEventListRequest(data) {
  let getData = yield editCalenderEventListApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(getEditCalendarListResponse(SUCCESS_EDIT_CALENDAR_LIST_DATA, getData.data));
  } else {
    errorNotification(getData.data.error.message)
    yield put(getEditCalendarListResponse(ERROR_EDIT_CALENDAR_LIST_DATA, getData.data));
  }
  }
  
  export function* editCalendarEventListWatcher() {
    yield takeLatest(EDIT_CALENDAR_LIST_DATA, editCalendarEventListRequest);
  }

  //MICROSOFT EVENTS

  function* getMicrosoftEventListRequest(data) {
    let getData = yield getMicrosoftEventsListApi(data);
    if (getData.success && _.has(getData, "data")) {
      yield put(getMicrosoftCalendarListResponse(SUCCESS_GET_MICROSOFT_CALENDAR_LIST_DATA, getData.data));
    } else {
      yield put(getMicrosoftCalendarListResponse(ERROR_GET_MICROSOFT_CALENDAR_LIST_DATA, getData.data));
    }
  }
  
  export function* getMicrosoftEventListWatcher() {
    yield takeLatest(GET_MICROSOFT_CALENDAR_LIST_DATA, getMicrosoftEventListRequest);
  }  
  


  // delete Microsoft event
function* deleteMicrosoftEventRequest(data) {
  let getData = yield deleteMicrosoftEventApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(getDeleteMicrosoftEventResponse(SUCCESS_DELETE_MICROSOFT_EVENT, getData.data));
  } else {
    yield put(getDeleteMicrosoftEventResponse(ERROR_DELETE_MICROSOFT_EVENT, getData.data));
  }
}

export function* deleteMicrosoftEventWatcher() {
  yield takeLatest(DELETE_MICROSOFT_EVENT, deleteMicrosoftEventRequest);
}


// edit Calendar google event list Data
function* editMicrosoftEventListRequest(data) {
  let getData = yield editMicrosoftEventApi(data);
  if (getData.success && _.has(getData, "data")) {
    yield put(getEditMicrosoftCalendarListResponse(SUCCESS_MICROSOFT_EDIT_CALENDAR_LIST_DATA, getData.data));
  } else {
    yield put(getEditMicrosoftCalendarListResponse(ERROR_MICROSOFT_EDIT_CALENDAR_LIST_DATA, getData.data));
  }
  }
  
  export function* editMicrosoftEventListWatcher() {
    yield takeLatest(EDIT_MICROSOFT_CALENDAR_LIST_DATA, editMicrosoftEventListRequest);
  }