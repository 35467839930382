import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../../component/frontend/header/header";
import { Footer } from "../../../component/frontend/footer/footer";
import { Link, withRouter } from "react-router-dom";
import { Loader } from "../../../component/frontend/loader/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CALENDAR from "../../../../assets/images/calendar.png";
import ERROR_ICON from "../../../../assets/images/error-icn.svg";
import {
  constants,
  selectStyle,
  CustomValueContainer,
} from "../../../../common/constants";
import {
  fieldValidator,
  usePrevious,
  setImagePath,
} from "../../../../common/custom";
import {
  addQuote,
  updateBasicQuote,
  deleteQuote,
} from "../../../../duck/quote/quote.action";
import { listServiceWithSource } from "../../../../duck/lead/lead.action";
import {
  LIST_CONTRACTS,
  ADD_CONTRACTS_BASE,
} from "../../../../routing/routeContants";
import ORANGE_ARROW from "../../../../assets/images/orange-arrow.svg";
import INFO_IMAGE from "../../../../assets/images/infoicon.png";
import { validateInputs } from "../../../../common/validation";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import AsyncSelect from "react-select/async";
import { getContactListOptionValue } from "../../../../../api/sdk/contact";
import Swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import { AddOrganization } from "./addOrganization";
import { AddService } from "./addService";
import MENU_DOTTED from "../../../../assets/images/menu-dotted.svg";

export const NewAddBasicQuote = (props) => {
  const dispatch = useDispatch();
  const datepickerRef = useRef();
  const [loader, setLoader] = useState(false);
  const [isCollapse, setIsCollapse] = useState("");
  const [serviceMessage, setServiceMessage] = useState("");
  const phoneTypeOptions = [
    { value: "Mobile", label: "Mobile" },
    { value: "Work", label: "Work" },
    { value: "Home", label: "Home" },
  ];
  const [state, setState] = useState({
    correctInput: "",
    wrongInput: constants.WRONG_INPUT,
    quoteName: "contract:",
    timeValue: "",
    location: "",
    internalNotes: "",
    timeShiftValue: { value: "AM", label: "AM" },
    timeShiftOptions: [
      { value: "AM", label: "AM" },
      { value: "PM", label: "PM" },
    ],
    durationValue: "",
    durationOptions: [
      { value: "1 Hours", label: "1 Hours" },
      { value: "2 Hours", label: "2 Hours" },
      { value: "3 Hours", label: "3 Hours" },
      { value: "4 Hours", label: "4 Hours" },
      { value: "5 Hours", label: "5 Hours" },
      { value: "6 Hours", label: "6 Hours" },
      { value: "7 Hours", label: "7 Hours" },
      { value: "8 Hours", label: "8 Hours" },
    ],
    customDuration: "",
    lat_long: "",
    totalAmount: 0,
    deposite: 0,
    date: "",
    dateErr: "",
    dateCls: "",
    validThrough: { value: 7, label: "7 Days" },
    validThrouhOptions: [
      { value: 3, label: "3 Days" },
      { value: 7, label: "7 Days" },
      { value: 15, label: "15 Days" },
      { value: 30, label: "30 Days" },
    ],
    validThroughErr: "",
    validThroughCls: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    organization: "",
    title: "",
    phoneType: { value: "Mobile", label: "Mobile" },
    firstNameCls: "",
    emailCls: "",
    phoneCls: "",
    firstNameErr: "",
    emailErr: "",
    phoneErr: "",
    quoteSerialNo: "",
    selectTemplate: "",
    redirectPage: false,
    itemHeadingDisabled: true,
    itemNameDisabled: true,
    itemDiscriptionDisabled: true,
    itemChargeDisabled: true,
    depositRequired: 0,
    depositOnline: 0,
    quoteNameCls: "",
    quoteNameErr: "",
    servicesListOptions: [],
    selectService: "",
    selectServiceErr: "",
    selectServiceCls: "",
    new_contact: "1",
    quoteId: "",
    leadId: "",
    tentative: false,
    tbd: false,
    address_line_1: "",
    addressOneErr: "",
    addressOneCls: "",
    address_line_2: "",
    addressTwoErr: "",
    addressTwoCls: "",
    city: "",
    cityErr: "",
    cityCls: "",
    state: "",
    statesErr: "",
    statesCls: "",
    zip: "",
    zipErr: "",
    zipCls: "",
    website: "",
    websiteErr: "",
    websiteCls: "",
    other_1: "",
    other_1Err: "",
    other_1Cls: "",
    other_2: "",
    other_2Err: "",
    other_2Cls: "",
    other_3: "",
    other_3Err: "",
    other_3Cls: "",
    birthday: "",
    birthdayErr: "",
    birthdayCls: "",
    address_line_1: "",
    addressOneErr: "",
    addressOneCls: "",
    address_line_2: "",
    addressTwoErr: "",
    addressTwoCls: "",
    city: "",
    cityErr: "",
    cityCls: "",
    state: "",
    statesErr: "",
    statesCls: "",
    zip: "",
    zipErr: "",
    zipCls: "",
    website: "",
    websiteErr: "",
    websiteCls: "",
    other_1: "",
    other_1Err: "",
    other_1Cls: "",
    other_2: "",
    other_2Err: "",
    other_2Cls: "",
    other_3: "",
    other_3Err: "",
    other_3Cls: "",
    birthday: "",
    birthdayErr: "",
    birthdayCls: "",
  });

  const addQuoteData = useSelector((state) => state.quote.addQuoteData);
  const prevAddQuoteData = usePrevious({ addQuoteData });
  const deleteQuoteData = useSelector((state) => state.quote.deleteQuoteData);
  const prevDeleteQuoteData = usePrevious({ deleteQuoteData });
  const listServiceWithSourceData = useSelector(
    (state) => state.lead.listServiceWithSourceData
  );
  const prevListServiceWithSourceData = usePrevious({
    listServiceWithSourceData,
  });
  // Organization Check
  const [businessProfileModalShow, setBusinessProfileModalShow] =
    useState(false);

  // Add Template
  const [serviceModalShow, setServiceModalShow] = useState(false);

  const addExtraService = ({ innerRef, innerProps, isDisabled, children }) =>
    !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
        {children}
        <button
          type="button"
          className="btn text-link text-left btn-sm btn-block"
          onClick={(e) => setServiceModalShow(true)}
        >
          Add New Service
        </button>
      </div>
    ) : null;

  // Set Mobile View
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      if (window.innerWidth < 991) {
        setIsCollapse("collapse");
      } else {
        setIsCollapse("");
      }
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);
    resizeListener();
    if (
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.quoteDataState
    ) {
      const QuoteData = props.history.location.state.quoteDataState.quote;
      const ServiceType =
        props.history.location.state.quoteDataState.service_types;
      let services = _.map(ServiceType, (data) => {
        return { value: data.id, label: data.name };
      });
      _.remove(services, function (opt) {
        return opt.label === "Magic and Comedy Show (sample)";
      });
      let durationValue, customDuration;
      if (
        _.some(state.durationOptions, {
          value: QuoteData.event_duration,
          label: QuoteData.event_duration,
        })
      ) {
        durationValue = {
          value: QuoteData.event_duration,
          label: QuoteData.event_duration,
        };
        customDuration = "";
      } else {
        if (QuoteData.event_duration !== null) {
          durationValue = { value: "Custom", label: "Custom" };
          customDuration = QuoteData.event_duration;
        } else {
          durationValue = "";
        }
      }
      setState({
        ...state,
        new_contact: "0",
        contactSelectValue: QuoteData.contact,
        quoteName: QuoteData.name,
        location:
          QuoteData.event_location !== null ? QuoteData.event_location : "",
        lat_long:
          QuoteData.event_lat_long !== null ? QuoteData.event_lat_long : "",
        validThrough:
          QuoteData.valid_through_days !== 0
            ? {
              value: QuoteData.valid_through_days,
              label: QuoteData.valid_through_days + " Days",
            }
            : "",
        date:
          QuoteData.start_date !== null
            ? moment(QuoteData.start_date).toDate()
            : "",
        timeValue:
          QuoteData.start_date !== null
            ? moment(QuoteData.start_date).toDate()
            : "",
        durationValue,
        customDuration,
        quoteId: QuoteData.id,
        firstName:
          QuoteData.contact && QuoteData.contact.first_name
            ? QuoteData.contact.first_name
            : "",
        lastName:
          QuoteData.contact && QuoteData.contact.last_name
            ? QuoteData.contact.last_name
            : "",
        email:
          QuoteData.contact && QuoteData.contact.email
            ? QuoteData.contact.email
            : "",
        phone:
          QuoteData.contact && QuoteData.contact.phone
            ? QuoteData.contact.phone
            : "",
        phoneType:
          QuoteData.contact && QuoteData.contact.phone_type
            ? {
              value: QuoteData.contact.phone_type,
              label: QuoteData.contact.phone_type,
            }
            : "",
        organization:
          QuoteData.contact && QuoteData.contact.organization
            ? QuoteData.contact.organization
            : "",
        title:
          QuoteData.contact && QuoteData.contact.title
            ? QuoteData.contact.title
            : "",
        servicesListOptions: services,
        tentative: QuoteData.tentative === 1 ? true : false,
        selectService:
          QuoteData.service_type && QuoteData.service_type.id
            ? {
              value: QuoteData.service_type.id,
              label: QuoteData.service_type.name,
            }
            : "",
        address_line_1: QuoteData.contact && QuoteData.contact.address_line_1 ? QuoteData.contact.address_line_1 : "",
        address_line_2: QuoteData.contact && QuoteData.contact.address_line_2 ? QuoteData.contact.address_line_2 : "",
        city: QuoteData.contact && QuoteData.contact.city ? QuoteData.contact.city : "",
        state: QuoteData.contact && QuoteData.contact.state ? QuoteData.contact.state : "",
        zip: QuoteData.contact && QuoteData.contact.zip ? QuoteData.contact.zip : "",
        birthday: QuoteData.contact && QuoteData.contact.birthday ? QuoteData.contact.birthday : "",
        website: QuoteData.contact && QuoteData.contact.website ? QuoteData.contact.website : "",
        other_1: QuoteData.contact && QuoteData.contact.other_1 ? QuoteData.contact.other_1 : "",
        other_2: QuoteData.contact && QuoteData.contact.other_2 ? QuoteData.contact.other_2 : "",
        other_3: QuoteData.contact && QuoteData.contact.other_3 ? QuoteData.contact.other_3 : "",
      });
    } else if (
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.quoteLeadData
    ) {
      const leadData = props.history.location.state.quoteLeadData;
      const ServiceType =
        props.history.location.state.quoteLeadData.service_type;
      let services = _.map(ServiceType, (data) => {
        return { value: data.id, label: data.name };
      });
      _.remove(services, function (opt) {
        return opt.label === "Magic and Comedy Show (sample)";
      });
      setState({
        ...state,
        new_contact: "0",
        contactSelectValue: leadData.contact,
        leadId: leadData.id,
        quoteName: "contract:" + leadData.name,
        firstName:
          leadData.contact && leadData.contact.first_name
            ? leadData.contact.first_name
            : "",
        lastName:
          leadData.contact && leadData.contact.last_name
            ? leadData.contact.last_name
            : "",
        email:
          leadData.contact && leadData.contact.email
            ? leadData.contact.email
            : "",
        phone:
          leadData.contact && leadData.contact.phone
            ? leadData.contact.phone
            : "",
        phoneType:
          leadData.contact && leadData.contact.phone_type
            ? {
              value: leadData.contact.phone_type,
              label: leadData.contact.phone_type,
            }
            : "",
        organization:
          leadData.contact && leadData.contact.organization
            ? leadData.contact.organization
            : "",
        title:
          leadData.contact && leadData.contact.title
            ? leadData.contact.title
            : "",
        servicesListOptions: services,
        selectService:
          leadData.service_type && leadData.service_type.id
            ? {
              value: leadData.service_type.id,
              label: leadData.service_type.name,
            }
            : "",
        address_line_1: leadData.contact && leadData.contact.address_line_1 ? leadData.contact.address_line_1 : "",
        address_line_2: leadData.contact && leadData.contact.address_line_2 ? leadData.contact.address_line_2 : "",
        city: leadData.contact && leadData.contact.city ? leadData.contact.city : "",
        state: leadData.contact && leadData.contact.state ? leadData.contact.state : "",
        zip: leadData.contact && leadData.contact.zip ? leadData.contact.zip : "",
        birthday: leadData.contact && leadData.contact.birthday ? leadData.contact.birthday : "",
        website: leadData.contact && leadData.contact.website ? leadData.contact.website : "",
        other_1: leadData.contact && leadData.contact.other_1 ? leadData.contact.other_1 : "",
        other_2: leadData.contact && leadData.contact.other_2 ? leadData.contact.other_2 : "",
        other_3: leadData.contact && leadData.contact.other_3 ? leadData.contact.other_3 : "",
      });
      setLoader(true);
      dispatch(listServiceWithSource());
    } else {
      setLoader(true);
      dispatch(listServiceWithSource());
    }
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      prevListServiceWithSourceData &&
      prevListServiceWithSourceData.listServiceWithSourceData !==
      listServiceWithSourceData
    ) {
      if (
        listServiceWithSourceData &&
        _.has(listServiceWithSourceData, "data") &&
        listServiceWithSourceData.success === true
      ) {
        setLoader(false);
        let serviceOption = _.map(
          listServiceWithSourceData.data.service_types,
          (data) => {
            return { value: data.id, label: data.name };
          }
        );
        _.remove(serviceOption, function (opt) {
          return opt.label === "Magic and Comedy Show (sample)";
        });
        setState({ ...state, servicesListOptions: serviceOption });
      }
      if (
        listServiceWithSourceData &&
        _.has(listServiceWithSourceData, "message") &&
        listServiceWithSourceData.success === false
      ) {
        setLoader(false);
        setServiceMessage(listServiceWithSourceData.message);
      }
    }
    if (prevAddQuoteData && prevAddQuoteData.addQuoteData !== addQuoteData) {
      if (
        addQuoteData &&
        _.has(addQuoteData, "data") &&
        addQuoteData.success === true
      ) {
        setLoader(false);
        console.log(
          state.redirectPage,
          "redirection****************************"
        );
        if (state.redirectPage) {
          props.history.push(ADD_CONTRACTS_BASE + addQuoteData.data.id);
        } else {
          props.history.push(LIST_CONTRACTS);
        }

        // setLoader(false)
      }
      if (
        addQuoteData &&
        _.has(addQuoteData, "message") &&
        addQuoteData.success === false
      ) {
        setLoader(false);
        if (addQuoteData.message === "Please add organization first.") {
          setBusinessProfileModalShow(true);
        } else {
          setServiceMessage(addQuoteData.message);
        }
      }
    }
    if (
      prevDeleteQuoteData &&
      prevDeleteQuoteData.deleteQuoteData !== deleteQuoteData
    ) {
      if (
        deleteQuoteData &&
        _.has(deleteQuoteData, "data") &&
        deleteQuoteData.success === true
      ) {
        setLoader(false);
        props.history.push(LIST_CONTRACTS);
      }
      if (
        deleteQuoteData &&
        _.has(deleteQuoteData, "message") &&
        deleteQuoteData.success === false
      ) {
        setLoader(false);
        setServiceMessage(deleteQuoteData.message);
      }
    }
  }, [
    listServiceWithSourceData,
    prevListServiceWithSourceData,
    prevAddQuoteData,
    addQuoteData,
    prevDeleteQuoteData,
    deleteQuoteData,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  //Set Address
  const handleChangeGoogle = (address) => {
    setState({ ...state, location: address });
  };

  const handleSelectGoogle = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    let data = {
      lat: latLng.lat,
      lng: latLng.lng,
      place_id: results[0].place_id,
    };
    //setState({ ...state, location: results[0].formatted_address, lat_long: JSON.stringify(data) })
    setState({ ...state, location: address, lat_long: JSON.stringify(data) });
  };

  // Set The Input Values
  const setInputValue = (e, type, maxLength, minLength) => {
    let error = checkValidation(
      e.target.name,
      e.target.value,
      type,
      maxLength,
      minLength
    );
    setState({
      ...state,
      [e.target.name]: e.target.value,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
    setServiceMessage("");
  };

  // Check Validation Function
  const checkValidation = (
    field,
    value,
    type,
    maxLength,
    minLength,
    fieldType
  ) => {
    return fieldValidator(
      field,
      value,
      type,
      null,
      maxLength,
      minLength,
      fieldType
    );
  };

  // set date for booking
  const dateForBooking = (date) => {
    if (date === null) {
      setState({
        ...state,
        date: "",
        dateCls: state.wrongInput,
        dateErr: "Please select date",
      });
    } else {
      setState({ ...state, date: date, dateCls: "", dateErr: "" });
      setServiceMessage("");
    }
  };

  const showTimeSelection = () => {
    setTimeout(function () {
      datepickerRef.current.setOpen(true);
    }, 100);
  };

  // Save Quote Function
  const saveQuoteData = (status) => {
    let error = state.wrongInput;
    let redirectPage = status ? true : false;
    let date = state.date,
      dateErr = "",
      dateCls = "",
      validThroughCls = "",
      validThroughErr = "",
      validThrough = state.validThrough,
      quoteName = state.quoteName,
      quoteNameErr = "",
      quoteNameCls = "",
      new_contact = state.new_contact,
      contactSelectValue = state.contactSelectValue,
      firstName = state.firstName,
      lastName = state.lastName,
      email = state.email,
      phone = state.phone,
      organization = state.organization,
      title = state.title,
      phoneType = state.phoneType.value,
      firstNameCls = "",
      emailCls = "",
      phoneCls = "",
      firstNameErr = "",
      emailErr = "",
      phoneErr = "",
      organizationErr = "",
      contactSelectErr = "",
      contactSelectCls = "",
      selectServiceCls = "",
      selectServiceErr = "",
      selectService = state.selectService,
      address_line_1 = state.address_line_1,
      addressOneErr = "",
      addressOneCls = "",
      address_line_2 = state.address_line_2,
      addressTwoErr = "",
      addressTwoCls = "",
      city = state.city,
      cityErr = "",
      cityCls = "",
      state_name = state.state,
      statesErr = "",
      statesCls = "",
      zip = state.zip,
      zipErr = "",
      zipCls = "",
      website = state.website,
      websiteErr = "",
      websiteCls = "",
      other_1 = state.other_1,
      other_1Err = "",
      other_1Cls = "",
      other_2 = state.other_2,
      other_2Err = "",
      other_2Cls = "",
      other_3 = state.other_3,
      other_3Err = "",
      other_3Cls = "",
      birthday = state.birthday,
      birthdayErr = "",
      birthdayCls = "",
      getError = false;

    if (state.tbd === true) {
    } else {
      if (
        validateInputs(
          "required",
          date !== "" ? date.getDate() + " " + date.getMonth() : ""
        ) === "empty"
      ) {
        dateErr = "Please select  date.";
        dateCls = error;
        getError = true;
      }
    }

    if (!(validThrough && validThrough.value)) {
      validThroughErr = "Please select  valid for.";
      validThroughCls = error;
      getError = true;
    }
    if (validateInputs("string", quoteName) === "empty") {
      quoteNameErr = "Please enter contract For.";
      quoteNameCls = error;
      getError = true;
    } else if (validateInputs("string", quoteName) === false) {
      quoteNameErr = "Please enter valid contract For.";
      quoteNameCls = error;
      getError = true;
    } else if (quoteName.length > 50) {
      quoteNameErr = "Please enter maximum 50 characters.";
      quoteNameCls = error;
      getError = true;
    }

    if (validateInputs("string", firstName) === "empty") {
      firstNameErr = "Please enter first name.";
      firstNameCls = error;
      getError = true;
    } else if (validateInputs("string", firstName) === false) {
      firstNameErr = "Please enter valid first name.";
      firstNameCls = error;
      getError = true;
    } else if (firstName.length > 50) {
      firstNameErr = "Please enter maximum 50 characters.";
      firstNameCls = error;
      getError = true;
    }

    if (validateInputs("email", email) === "empty") {
      emailErr = "Please enter email.";
      emailCls = error;
      getError = true;
    } else if (validateInputs("email", email) === false) {
      emailErr = "Please enter valid email.";
      emailCls = error;
      getError = true;
    }

    // if (validateInputs('phoneNumberHyphon', phone) === false) {
    //     phoneErr = 'Please enter valid phone.';
    //     phoneCls = error
    //     getError = true;
    // }
    // if (phone && phone.length > 1 && phone.length > 15) {
    //     phoneErr = 'Please enter maximum 15 digits.';
    //     phoneCls = error
    //     getError = true;
    // }
    if (new_contact === "1") {
    } else {
      if (!(contactSelectValue && contactSelectValue.id)) {
        contactSelectErr = "Please select contact.";
        contactSelectCls = error;
        getError = true;
      }
    }

    if (validateInputs("required", state.selectService) === "empty") {
      selectServiceErr = "Please select Interested In.";
      selectServiceCls = error;
      getError = true;
    }

    setState({
      ...state,
      redirectPage,
      dateErr,
      dateCls,
      validThroughErr,
      validThroughCls,
      quoteNameErr,
      quoteNameCls,
      selectServiceCls,
      selectServiceErr,
      firstNameCls,
      emailCls,
      phoneCls,
      firstNameErr,
      emailErr,
      phoneErr,
      organizationErr,
      contactSelectCls,
      contactSelectErr,
      addressOneErr,
      addressOneCls,
      addressTwoErr,
      addressTwoCls,
      cityErr,
      cityCls,
      statesErr,
      statesCls,
      zipErr,
      zipCls,
      websiteErr,
      websiteCls,
      other_1Err,
      other_1Cls,
      other_2Err,
      other_2Cls,
      other_3Err,
      other_3Cls,
      birthdayErr,
      birthdayCls,
    });

    if (
      getError === false &&
      dateErr === "" &&
      validThroughErr === "" &&
      emailErr === "" &&
      firstNameErr === "" &&
      phoneErr === "" &&
      quoteNameErr === "" &&
      contactSelectErr === "" &&
      selectServiceErr === ""
    ) {
      setLoader(true);
      let datetimeA;
      if (state.tbd === true) {
        datetimeA = null;
      } else {
        let getdate = moment(state.date).format("YYYY-MM-DD");
        let time = state.timeValue
          ? moment(state.timeValue).format("HH:mm:ss")
          : moment().format("00:00:00");
        datetimeA = moment(getdate + " " + time);
      }

      const quoteData = {
        is_contract: 1,
        name: quoteName,
        new_contact,
        event_location: state.location,
        event_lat_long: state.lat_long,
        valid_through:
          state.validThrough && state.validThrough.value
            ? state.validThrough.value
            : "",
        first_name: firstName,
        last_name: lastName,
        phone_type: phoneType,
        organization: organization,
        phone: phone,
        title: title,
        email: email,
        tentative: state.tentative,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        city: city,
        state_name: state.state,
        zip: zip,
        website: website,
        other_1: other_1,
        other_2: other_2,
        other_3: other_3,
        birthday: birthday,
      };
      if (new_contact === "1") {
      } else {
        if (contactSelectValue && contactSelectValue.id) {
          quoteData.contact_id = contactSelectValue.id;
        }
      }

      if (selectService && selectService.value) {
        quoteData.service_type_id = selectService.value;
      }

      if (state.date !== "" && state.tbd === false) {
        quoteData.start_date = moment(datetimeA).format("YYYY-MM-DD HH:mm:ss");
      }
      if (state.selectTemplate && state.selectTemplate.value) {
        quoteData.quote_template_id = state.selectTemplate.value;
      }
      if (state.durationValue && state.durationValue.value !== "Custom") {
        quoteData.event_duration = state.durationValue.value;
      } else {
        quoteData.event_duration = state.customDuration;
      }
      if (state.quoteId !== "") {
        quoteData.id = state.quoteId;
        dispatch(updateBasicQuote(quoteData));
      } else {
        if (state.leadId !== "") {
          quoteData.lead_id = state.leadId;
        }
        dispatch(addQuote(quoteData));
      }
    } else {
      setServiceMessage("Please enter all required details.");
    }
  };

  // On Cancel
  const CancelForm = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: " You will lose all the changes if you navigate away",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it",
      cancelButtonText: "No, keep it",
      reverseButtons: true,
      showCloseButton: true,
      customClass: "mycustom-alert",
      cancelButtonClass: "cancel-alert-note",
    }).then((result) => {
      if (result.value) {
        props.history.push(LIST_CONTRACTS);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // console.log('cancel')
      }
    });
  };

  // handle selection
  const handleChange = (value) => {
    setServiceMessage("");
    setTimeout(() => {
      setState({
        ...state,
        contactSelectValue: value,
        firstName: value && value.first_name ? value.first_name : "",
        lastName: value && value.last_name ? value.last_name : "",
        email: value && value.email ? value.email : "",
        phone: value && value.phone ? value.phone : "",
        organization: value && value.organization ? value.organization : "",
        title: value && value.title ? value.title : "",
        firstNameCls: "",
        emailCls: "",
        phoneCls: "",
        firstNameErr: "",
        emailErr: "",
        phoneErr: "",
      });
    }, 0);
  };

  // load options using API call
  const loadOptions = async () => {
    let data = [];
    let listOption = await getContactListOptionValue({
      searchField: "first_name,last_name",
      fields: "id,first_name,last_name,organization,title,email,phone",
      filter: state.contactSelect,
    });
    if (
      listOption &&
      listOption.data &&
      _.has(listOption.data, "data") &&
      listOption.success === true
    ) {
      data = listOption.data.data;
    }
    return data;
  };

  // handle input change event
  const handleInputChange = (value) => {
    setState({
      ...state,
      contactSelect: value,
      contactSelectErr: "",
      contactSelectCls: "",
    });
  };

  // Delete CONTRACT Data
  const deleteQuoteFunction = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this contract?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, keep it",
      reverseButtons: true,
      showCloseButton: true,
      customClass: "mycustom-alert",
      cancelButtonClass: "cancel-alert-note",
    }).then((result) => {
      if (result.value) {
        setLoader(true);
        dispatch(deleteQuote({ id: state.quoteId }));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // console.log('cancel')
      }
    });
  };

  // On Change Contact
  const onchangeContact = (status) => {
    if (status === "1") {
      setState({
        ...state,
        new_contact: status,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        organization: "",
        title: "",
        firstNameCls: "",
        emailCls: "",
        phoneCls: "",
        firstNameErr: "",
        emailErr: "",
        phoneErr: "",
      });
    } else {
      setState({
        ...state,
        new_contact: status,
        firstName:
          state.contactSelectValue && state.contactSelectValue.first_name
            ? state.contactSelectValue.first_name
            : "",
        lastName:
          state.contactSelectValue && state.contactSelectValue.last_name
            ? state.contactSelectValue.last_name
            : "",
        email:
          state.contactSelectValue && state.contactSelectValue.email
            ? state.contactSelectValue.email
            : "",
        phone:
          state.contactSelectValue && state.contactSelectValue.phone
            ? state.contactSelectValue.phone
            : "",
        phoneType:
          state.contactSelectValue && state.contactSelectValue.phone_type
            ? {
              value: state.contactSelectValue.phone_type,
              label: state.contactSelectValue.phone_type,
            }
            : "",
        organization:
          state.contactSelectValue && state.contactSelectValue.organization
            ? state.contactSelectValue.organization
            : "",
        title:
          state.contactSelectValue && state.contactSelectValue.title
            ? state.contactSelectValue.title
            : "",
        firstNameCls: "",
        emailCls: "",
        phoneCls: "",
        firstNameErr: "",
        emailErr: "",
        phoneErr: "",
      });
    }
  };

  // Add New Service Data
  const addNewServiceData = (data) => {
    let allOption = state.servicesListOptions;
    allOption.push(data);
    setState({ ...state, servicesListOptions: allOption });
    setState({
      ...state,
      selectService: data,
      selectServiceCls: "",
      selectServiceErr: "",
    });
  };
  let disabledval = state.tbd === true ? true : false;

  return (
    <>
      <Loader loader={loader} />
      <div className="main-site fixed--header lead-page-hdr unfixed-page-title">
        <Header getMainRoute={"contracts"} />
        <main className="site-body">
          <section className="page-title addbasiccontractheader">
            <div className="container">
              <div className="row">
                <div className="col-lg-auto title--col">
                  <div>
                    <ol className="breadcrumb d-none d-lg-flex">
                      <li className="breadcrumb-item">
                        <Link to={LIST_CONTRACTS}>Contracts</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Create New Contract
                      </li>
                    </ol>
                    <h2 className="title">New Contract</h2>
                  </div>
                </div>
                {state.quoteId !== "" ? (
                  <div className="col-auto ml-auto d-flex align-items-center title-elems">
                    <button
                      type="button"
                      onClick={(e) => deleteQuoteFunction(e)}
                      className="btn btn-danger mr-15 trashbtn"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="vuesax/bold/trash">
                          <g id="trash">
                            <path
                              id="Vector"
                              d="M21.0699 5.23C19.4599 5.07 17.8499 4.95 16.2299 4.86V4.85L16.0099 3.55C15.8599 2.63 15.6399 1.25 13.2999 1.25H10.6799C8.34991 1.25 8.12991 2.57 7.96991 3.54L7.75991 4.82C6.82991 4.88 5.89991 4.94 4.96991 5.03L2.92991 5.23C2.50991 5.27 2.20991 5.64 2.24991 6.05C2.28991 6.46 2.64991 6.76 3.06991 6.72L5.10991 6.52C10.3499 6 15.6299 6.2 20.9299 6.73C20.9599 6.73 20.9799 6.73 21.0099 6.73C21.3899 6.73 21.7199 6.44 21.7599 6.05C21.7899 5.64 21.4899 5.27 21.0699 5.23Z"
                              fill="#DB0200"
                            />
                            <path
                              id="Vector_2"
                              d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z"
                              fill="#DB0200"
                            />
                          </g>
                        </g>
                      </svg>
                      Delete
                    </button>
                    <button
                      onClick={(e) => CancelForm(e)}
                      className="btn btn-dark mr-15 d-none d-lg-flex"
                    >
                      {/* <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="vuesax/linear/add">
                        <g id="add">
                          <g id="Group 33824">
                            <path
                              id="Vector"
                              d="M5.00098 5L19 18.9991"
                              stroke="#818186"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M4.99996 18.9991L18.999 5"
                              stroke="#818186"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </g>
                    </svg> */}
                      Cancel
                    </button>
                    <div className="btn-divider mr-15 d-none d-lg-flex"></div>
                    <button
                      onClick={() => saveQuoteData()}
                      className="btn btn-secondary mr-15 d-none d-lg-flex"
                    >
                      Save for Later
                    </button>
                    <button
                      onClick={() => saveQuoteData(true)}
                      className="btn btn-primary"
                    >
                      Continue
                    </button>
                    <div className="dropdown d-lg-none custom-dropdown dropdown-toggle--mbl">
                      <button
                        className="btn dropdown-toggle "
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={setImagePath(MENU_DOTTED)} alt="" />
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          href="#cancel"
                          onClick={(e) => CancelForm(e)}
                        >
                          Cancel
                        </a>
                        <a
                          className="dropdown-item"
                          href="#saveAsDraft"
                          onClick={() => saveQuoteData()}
                        >
                          Save for Later
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-auto ml-auto d-flex align-items-center title-elems">
                    <button
                      onClick={() => saveQuoteData()}
                      className="btn btn-secondary mr-15 "
                    >
                      Save for Later
                    </button>
                    <button
                      onClick={(e) => CancelForm(e)}
                      className="btn btn-dark mr-15 "
                    >
                      {/* <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="vuesax/linear/add">
                      <g id="add">
                        <g id="Group 33824">
                          <path
                            id="Vector"
                            d="M5.00098 5L19 18.9991"
                            stroke="#818186"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M4.99996 18.9991L18.999 5"
                            stroke="#818186"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </g>
                  </svg> */}
                      Cancel
                    </button>
                    <button
                      onClick={() => saveQuoteData(true)}
                      className="btn btn-primary"
                    >
                      Continue
                    </button>
                    {/* <div className="dropdown d-lg-none custom-dropdown dropdown-toggle--mbl">
                                            <button className="btn dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src={setImagePath(MENU_DOTTED)} alt="" />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" href="#cancel" onClick={(e) => CancelForm(e)}>Cancel</a>
                                                <a className="dropdown-item" href="#saveAsDraft" onClick={() => saveQuoteData()}>Save for Later</a>
                                            </div>
                                        </div> */}
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="middle-section pt-0">
            <div className="container">
              {serviceMessage ? (
                <div className="errorCls errCommonCls mb-3">
                  <img src={setImagePath(ERROR_ICON)} alt="" />
                  {serviceMessage}
                </div>
              ) : (
                ""
              )}
              <div className="row no-gutters-mbl mb-lg-4">
                <div className="col-12">
                  <div className="main-card">
                    <div className="card w-100">
                      <div className="card-body p-0">
                        <div className="new-lead-timeline">
                          <div className="timeline_row">
                            <div className="timeline-cols active">
                              <h5>
                                <em className="d-none d-lg-flex">
                                  <p>1</p> Basic Details
                                </em>{" "}
                                <i className="d-lg-none">1</i>
                              </h5>
                              <span></span>
                            </div>
                            <div className="timeline-cols">
                              <h5>
                                <em className="d-none d-lg-flex">
                                  <p>2</p> Contract Details
                                </em>{" "}
                                <i className="d-lg-none">2</i>
                              </h5>
                              <span></span>
                            </div>
                            <div className="timeline-cols">
                              <h5>
                                <em className="d-none d-lg-flex">
                                  <p>3</p> Preview Contract
                                </em>{" "}
                                <i className="d-lg-none">3</i>
                              </h5>
                              <span></span>
                            </div>
                            <div className="timeline-cols">
                              <h5>
                                <em className="d-none d-lg-flex">
                                  <p>4</p> Message to Customer
                                </em>{" "}
                                <i className="d-lg-none">4</i>
                              </h5>
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters-mbl mt-3">
                <div className="col-lg-6">
                  <div className="main-card create-qoute--cards">
                    <button
                      className="btn btn-block btn--card-collapse"
                      type="button"
                      data-toggle="collapse"
                      data-target="#QuoteCollapse"
                      aria-expanded="true"
                      aria-controls="QuoteCollapse"
                    >
                      Quote Details{" "}
                      <img src={setImagePath(ORANGE_ARROW)} alt="" />
                    </button>
                    <div
                      className={"card main-card--collapse show " + isCollapse}
                      id="QuoteCollapse"
                    >
                      <div className="card-header py-3 d-none d-lg-flex justify-content-between align-items-center">
                        <h5>Contract</h5>
                      </div>
                      <div className="card-body pt-lg-0 pb-0">
                        <form className="p-lg-2  px-4 py-lg-2">
                          <div className="row">
                            <div className="form-group col-md-10 mb-lg-4">
                              <div className="floating-label">
                                <input
                                  placeholder="Event, Service, Customer name etc."
                                  type="text"
                                  name="quoteName"
                                  value={state.quoteName || ""}
                                  onChange={(e) =>
                                    setInputValue(e, "string", 50, null)
                                  }
                                  className="floating-input"
                                />
                                <label>
                                  Contract For *
                                </label>
                                {state.quoteNameErr ? (
                                  <span className="errorValidationMessage">
                                    {" "}
                                    {state.quoteNameErr}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-group col-md-8">
                              <div className="floating-label">
                                <Select
                                  styles={selectStyle}
                                  className="floating-select"
                                  placeholder="Service/Package Name *"
                                  components={{
                                    MenuList: addExtraService,
                                    ValueContainer: CustomValueContainer,
                                    NoOptionsMessage: () => null,
                                  }}
                                  options={state.servicesListOptions}
                                  value={state.selectService}
                                  /* menuIsOpen={true} */
                                  onChange={(data) =>
                                    setState({
                                      ...state,
                                      selectService: data,
                                      selectServiceCls: "",
                                      selectServiceErr: "",
                                    })
                                  }
                                />
                                {state.selectServiceErr ? (
                                  <span className="errorValidationMessage">
                                    {" "}
                                    {state.selectServiceErr}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <p className="mb-0">
                                <strong>Note:</strong> For your purpose only,
                                not shared with customer.
                              </p>
                            </div>
                            <div className="addBasicContracts-tooltip form-group col-md-4 infoICon">
                              <div>
                                <div className="floating-label">
                                  <Select
                                    styles={selectStyle}
                                    className="floating-select"
                                    components={{
                                      ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder="Valid For *"
                                    value={state.validThrough}
                                    isSearchable={false}
                                    options={state.validThrouhOptions}
                                    onChange={(data) => {
                                      setState({
                                        ...state,
                                        validThrough: data,
                                        validThroughCls: "",
                                        validThroughErr: "",
                                      });
                                      setServiceMessage("");
                                    }}
                                  />
                                  {state.validThroughErr ? (
                                    <div className="quoteDepositErr">
                                      {" "}
                                      {state.validThroughErr}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div
                                    class="tool-tip"
                                    // title="Customer must respond within this period.The link to Contract will expire at the end of this duration."
                                  >
                                    <img
                                      src={setImagePath(INFO_IMAGE)}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <i class="fas fa-info-circle    "></i> */}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="main-card create-qoute--cards">
                    <button
                      className="btn btn-block btn--card-collapse"
                      type="button"
                      data-toggle="collapse"
                      data-target="#EventCollapse"
                      aria-expanded="true"
                      aria-controls="EventCollapse"
                    >
                      Service Date & Location{" "}
                      <img src={setImagePath(ORANGE_ARROW)} alt="" />
                    </button>
                    <div
                      className={"card main-card--collapse show " + isCollapse}
                      id="EventCollapse"
                    >
                      <div className="card-header py-3 d-none d-lg-flex justify-content-between align-items-center">
                        <h5>Service Date & Location </h5>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setState({
                                ...state,
                                tentative: e.target.checked,
                                tbd: false,
                              })
                            }
                            checked={state.tentative}
                            className="custom-control-input"
                            id="customCheck3"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck3"
                          >
                            {" "}
                            <strong>Tentative</strong>
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox col-lg-4 col-md-6">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setState({
                                ...state,
                                tbd: e.target.checked,
                                tentative: false,
                              })
                            }
                            checked={state.tbd}
                            className="custom-control-input"
                            id="customCheck4"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck4"
                          >
                            {" "}
                            <strong>TBD</strong>
                          </label>
                        </div>
                      </div>
                      <div className="card-body pt-lg-0 pb-0">
                        <form className="p-lg-2 px-4 py-lg-2">
                          <div className="row">
                            <div className="form-group col-md-4">
                              <div
                                className={"floating-label " + state.dateCls}
                              >
                                <DatePicker
                                  disabled={disabledval}
                                  type="text"
                                  name="date"
                                  className={
                                    state.dateCls
                                      ? "floating-input " + state.dateCls
                                      : "floating-input"
                                  }
                                  selected={state.date}
                                  onChange={(date) => dateForBooking(date)}
                                  minDate={moment().toDate()}
                                  placeholderText="Date *"
                                  ref={datepickerRef}
                                  autoComplete="off"
                                />
                                <div
                                  onClick={() => showTimeSelection()}
                                  className="input-calendar-icon"
                                >
                                  <img
                                    src={CALENDAR}
                                    alt=""
                                    width="20"
                                    height="20"
                                  />
                                </div>
                                {state.dateErr ? (
                                  <span className="errorValidationMessage">
                                    {" "}
                                    {state.dateErr}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <div className="floating-label my-custom-time-input">
                                <DatePicker
                                  disabled={disabledval}
                                  selected={state.timeValue}
                                  onChange={(date) =>
                                    setState({ ...state, timeValue: date })
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  fixedHeight
                                  timeIntervals={30}
                                  dateFormat="h:mm a"
                                  className="floating-input"
                                  onKeyDown={(e) => e.preventDefault()}
                                  placeholderText="Start Time"
                                />
                              </div>
                            </div>

                            <div className="form-group col-md-4">
                              <div className="floating-label">
                                <Select
                                  isDisabled={disabledval}
                                  styles={selectStyle}
                                  className="floating-select"
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  value={state.durationValue}
                                  isSearchable={false}
                                  options={state.durationOptions}
                                  placeholder="Duration"
                                  onChange={(data) => {
                                    setState({ ...state, durationValue: data });
                                  }}
                                />
                                {/* <label>Duration</label> */}
                              </div>
                            </div>

                            {state.durationValue &&
                              state.durationValue.value === "Custom" ? (
                              <div className="form-group col-md-5">
                                <label>Custom Duration</label>
                                <div
                                  className={
                                    "floating-label " + state.customDurationCls
                                  }
                                >
                                  <input
                                    isDisabled={disabledval}
                                    placeholder="Custom Duration"
                                    type="text"
                                    name="customDuration"
                                    className="floating-input"
                                    value={state.customDuration || ""}
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        customDuration: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div
                              className={
                                state.durationValue.value === "Custom"
                                  ? "form-group col-md-7"
                                  : "form-group col-md-12"
                              }
                            >

                              <PlacesAutocomplete
                                value={state.location}
                                onChange={handleChangeGoogle}
                                onSelect={handleSelectGoogle}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div className="floating-label">
                                    <input
                                      {...getInputProps({
                                        disabled: disabledval,
                                        placeholder:
                                          "Name of the location, Extract address etc.",
                                        className:
                                          "location-search-input floating-input",
                                      })}
                                    />
                                    <label class="bold-text">Location</label>

                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion, key) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                          : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                            key={key}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters-mbl mt-lg-4">
                <div className="col-lg-12">
                  <div className="main-card">
                    <button
                      className="btn btn-block btn--card-collapse"
                      type="button"
                      data-toggle="collapse"
                      data-target="#contactDetail"
                      aria-expanded="true"
                      aria-controls="contactDetail"
                    >
                      Contact Details{" "}
                      <img src={setImagePath(ORANGE_ARROW)} alt="" />
                    </button>
                    <div
                      className={"card main-card--collapse show " + isCollapse}
                      id="contactDetail"
                    >
                      <div className="card-header py-4  padd0 d-flex justify-content-between align-items-center">
                        <h2>Customer</h2>
                      </div>
                      <div className="card-body pt-0 pb-0">
                        <form className="px-3">
                          <div className="row">
                            <div className="form-group  col-lg-4 col-md-6">
                              {/* <label className="single-label">Event Type</label> */}
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  className="custom-control-input"
                                  onChange={() => onchangeContact("1")}
                                  checked={
                                    state.new_contact === "1" ? true : false
                                  }
                                  type="radio"
                                  name="new_contact"
                                  id="newRadio"
                                  value="1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="newRadio"
                                >
                                  New
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  className="custom-control-input"
                                  onChange={() => onchangeContact("0")}
                                  checked={
                                    state.new_contact === "0" ? true : false
                                  }
                                  type="radio"
                                  name="new_contact"
                                  id="existingRadio"
                                  value="0"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="existingRadio"
                                >
                                  Select Existing
                                </label>
                              </div>
                            </div>
                          </div>
                          {state.new_contact === "1" ? (
                            ""
                          ) : (
                            <>
                              <div className="row">
                                <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                                  <div>
                                    <label class="bold-text">
                                      Type here to search
                                    </label>
                                    <AsyncSelect
                                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                      className="floating-input"
                                      styles={selectStyle}
                                      onFocus={(e) => {
                                        if (e.target.autocomplete) {
                                          e.target.autocomplete = "nope";
                                        }
                                      }}
                                      isClearable
                                      placeholder=""
                                      noOptionsMessage={() => state.contactSelect ? "No results found" : "Results will appear here"}
                                      value={state.contactSelectValue}
                                      getOptionLabel={(e) =>
                                        e.first_name +
                                        (e && e.last_name
                                          ? " " + e.last_name
                                          : "")
                                      }
                                      getOptionValue={(e) => e.id}
                                      loadOptions={(e) => loadOptions(e)}
                                      onInputChange={(e) => handleInputChange(e)}
                                      onChange={(e) => handleChange(e)}
                                    />
                                    {state.contactSelectErr ? (
                                      <span className="errorValidationMessage">
                                        {" "}
                                        {state.contactSelectErr}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-lg-5" style={{ borderBottom: "1px solid" }}></div>
                            </>
                          )}
                          <div className="row">
                            <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                              <div className="floating-label">
                                <input
                                  placeholder="First Name"
                                  type="text"
                                  name="firstName"
                                  value={state.firstName || ""}
                                  onChange={(e) =>
                                    setInputValue(e, "string", 50, null)
                                  }
                                  className="floating-input"
                                />
                                <label class="bold-text">First Name *</label>
                                {state.firstNameErr ? (
                                  <span className="errorValidationMessage">
                                    {" "}
                                    {state.firstNameErr}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                              <div className="floating-label">
                                <input
                                  placeholder="Last Name"
                                  type="text"
                                  name="lastName"
                                  value={state.lastName || ""}
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      lastName: e.target.value,
                                    });
                                    setServiceMessage("");
                                  }}
                                  className="floating-input"
                                />
                                <label class="bold-text">Last Name</label>
                              </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                              <div className="floating-label">
                                <input
                                  placeholder="Email Address"
                                  type="email"
                                  name="email"
                                  value={state.email || ""}
                                  onChange={(e) =>
                                    setInputValue(e, "email", null, null)
                                  }
                                  className="floating-input"
                                />
                                <label class="bold-text">Email Address *</label>
                                {state.emailErr ? (
                                  <span className="errorValidationMessage">
                                    {" "}
                                    {state.emailErr}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 mb-lg-4">
                              <div className="form-row">
                                <div className="col-5">
                                  <div className="floating-label">
                                    <Select
                                      styles={selectStyle}
                                      className="floating-select"
                                      value={state.phoneType}
                                      isSearchable={false}
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      defaultValue={state.phoneType}
                                      options={phoneTypeOptions}
                                      placeholder="Type"
                                      onChange={(data) => {
                                        setState({ ...state, phoneType: data });
                                        setServiceMessage("");
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-7">
                                  <div className="floating-label">
                                    <input
                                      placeholder="000-000-0000"
                                      type="text"
                                      name="phone"
                                      value={state.phone || ""}
                                      onChange={(e) => {
                                        setState({
                                          ...state,
                                          phone: e.target.value,
                                        });
                                        setServiceMessage("");
                                      }}
                                      className="floating-input"
                                    />
                                    <label class="bold-text">
                                      Phone Number
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                              <div className="floating-label">
                                <input
                                  placeholder="Organization Name"
                                  type="text"
                                  name="organization"
                                  value={state.organization || ""}
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      organization: e.target.value,
                                    });
                                    setServiceMessage("");
                                  }}
                                  className="floating-input"
                                />
                                <label class="bold-text">
                                  Organization Name
                                </label>
                              </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                              <div className="floating-label">
                                <input
                                  placeholder="Sr. Director"
                                  type="text"
                                  name="title"
                                  value={state.title || ""}
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      title: e.target.value,
                                    });
                                    setServiceMessage("");
                                  }}
                                  className="floating-input"
                                />
                                <label class="bold-text">Title</label>
                              </div>
                            </div>
                            <div className="col-md-6 row mb-lg-5 p-0 m-0">
                              <div className="form-group col-md-6 mb-lg-0">
                                <div className="floating-label">
                                  <input placeholder="Address Line 1" type="text" name="address_line_1" value={state?.address_line_1 || ''} onChange={(e) => { setState({ ...state, address_line_1: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                  <label class="bold-text">Address Line 1</label>
                                </div>
                              </div>
                              <div className="form-group col-md-6 mb-lg-0">
                                <div className="floating-label">
                                  <input placeholder="Address Line 2" type="text" name="address_line_2" value={state?.address_line_2 || ''} onChange={(e) => { setState({ ...state, address_line_2: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                  <label class="bold-text">Address Line 2</label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-md-2 mb-lg-5">
                              <div className="floating-label">
                                <input placeholder="City" type="text" name="city" value={state?.city || ''} onChange={(e) => { setState({ ...state, city: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">City</label>
                              </div>
                            </div>
                            <div className="form-group col-md-2 mb-lg-5">
                              <div className="floating-label">
                                <input placeholder="State" type="text" name="state" value={state?.state || ''} onChange={(e) => { setState({ ...state, state: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">State</label>
                              </div>
                            </div>
                            <div className="form-group col-md-2 mb-lg-5">
                              <div className="floating-label">
                                <input placeholder="Zip" type="text" name="zip" value={state?.zip || ''} onChange={(e) => { setState({ ...state, zip: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">Zip</label>
                              </div>
                            </div>
                            <div className="form-group col-md-4 mb-lg-5">
                              <div className="floating-label">
                                <input placeholder="Birthday" type="text" name="birthday" value={state?.birthday || ''} onChange={(e) => { setState({ ...state, birthday: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">Birthday</label>
                              </div>
                            </div>
                            <div className="form-group col-md-4 mb-lg-5">
                              <div className="floating-label">
                                <input placeholder="Website" type="text" name="website" value={state?.website || ''} onChange={(e) => { setState({ ...state, website: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">Website</label>
                              </div>
                            </div>
                            <div className="form-group col-md-4 mb-lg-5">
                              <div className="floating-label">
                                <input placeholder="Other 1" type="text" name="other_1" value={state?.other_1 || ''} onChange={(e) => { setState({ ...state, other_1: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">Other 1</label>
                              </div>
                            </div>
                            <div className="form-group col-md-4 mb-lg-2">
                              <div className="floating-label">
                                <input placeholder="Other 2" type="text" name="other_2" value={state?.other_2 || ''} onChange={(e) => { setState({ ...state, other_2: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">Other 2</label>
                              </div>
                            </div>
                            <div className="form-group col-md-4 mb-lg-2">
                              <div className="floating-label">
                                <input placeholder="Other 3" type="text" name="other_3" value={state.other_3 || ''} onChange={(e) => { setState({ ...state, other_3: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                <label class="bold-text">Other 3</label>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Add Organization*/}
          <AddOrganization
            loader={(data) => setLoader(data)}
            openOrg={businessProfileModalShow}
            sentToCustomerCall={(data) => saveQuoteData(true)}
            closeOrg={() => setBusinessProfileModalShow(false)}
          />

          {/* Add Service Modal*/}
          <AddService
            loader={(data) => setLoader(data)}
            openService={serviceModalShow}
            addServiceInList={(data) => addNewServiceData(data)}
            closeService={() => setServiceModalShow(false)}
          />
        </main>
        <Footer />
      </div>
    </>
  );
};

export const AddBasicContracts = withRouter(NewAddBasicQuote);
