import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { selectStyle, constants, CustomValueContainer, convertGoogleReminderTime } from '../../../../common/constants';
import Modal from "react-bootstrap/Modal";
import _ from 'lodash';
import moment from 'moment'
import BellIcon from '../../../../assets/images/bellIcon.svg';
import UserIcon from '../../../../assets/images/userIcon.svg';
import { setImagePath } from "../../../../common/custom";
import DELETE_ICON from "../../../../assets/images/delete_FILL0_wght400_GRAD0_opsz24.svg";
import COMPOSE_ICON from "../../../../assets/images/edit_FILL0_wght400_GRAD0_opsz24.svg";
import Location from '../../../../assets/images/eventLocation.svg'
import Description from '../../../../assets/images/eventDescription.svg'
import GuestIcon from '../../../../assets/images/eventGuest.svg'


export const ViewGoogleAppointment = props => {

    const { handleEventEdit, handleEventDelete, googleEventModalShow, setGoogleEventModalShow, setLoader, appointmentData, appointmentStatus, getNewProp, contactData } = props

    const [state, setState] = useState({
        startDate: '', dateErr: '', dateCls: '', toValue: '', fromValue: '', title: '', titleErr: '', titleCls: '',
        correctInput: '', wrongInput: constants.WRONG_INPUT, address: '', location: '', locationErr: '', locationCls: '',
        lat_long: '', virtualEvent: false, meetingId: '', passcode: '', moreInformation: '', associateLeadSelectValue: '',
        associateLeadSelect: '', creatorName: '', endDate: '', description: '', Attendees: [], location: '', reminder: {}
    });

    useEffect(() => {
        if (appointmentData) {
            setState({
                reminder: appointmentData && appointmentData?.reminder,
                location: appointmentData && appointmentData?.location, Attendees: appointmentData && appointmentData?.attendees,
                description: appointmentData && appointmentData?.detail, creatorName: appointmentData && appointmentData?.created_by,
                endDate: appointmentData && appointmentData.end_date ? moment(appointmentData.end_date).toDate() : '',
                startDate: appointmentData && appointmentData.start_date ? moment(appointmentData.start_date).toDate() : '', dateErr: '',
                dateCls: '', toValue: appointmentData && appointmentData.end_date ? new Date(appointmentData.end_date) : '',
                fromValue: appointmentData && appointmentData.start_date ? new Date(appointmentData.start_date) : '',
                title: appointmentData && appointmentData.name ? appointmentData.name : '', titleErr: '', titleCls: '',
                correctInput: '', wrongInput: constants.WRONG_INPUT, address: appointmentData && appointmentData.location ? appointmentData.location : '',
                location: appointmentData && appointmentData.location ? appointmentData.location : '', locationErr: '', locationCls: '',
                lat_long: appointmentData && appointmentData.lat_long ? appointmentData.lat_long : "", virtualEvent: appointmentData && appointmentData.virtual_event ? appointmentData.virtual_event : false,
                meetingId: appointmentData && appointmentData.meeting_id ? appointmentData.meeting_id : '', passcode: appointmentData && appointmentData.passcode ? appointmentData.passcode : '', moreInformation: appointmentData && appointmentData.detail ? appointmentData.detail : '', associateLeadSelectValue: appointmentData && appointmentData.lead ? appointmentData.lead : '',
                associateLeadSelect: appointmentData && appointmentData.lead_id ? { name: appointmentData.name, id: appointmentData.lead_id, } : ''
            })
        }
    }, [googleEventModalShow])

    return (
        <>
            <Modal show={googleEventModalShow} onHide={() => {
                setGoogleEventModalShow(false)
            }} className="appoitment-form" size="lg" centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>
                    {appointmentData?.type === "googleEvents" ? "View Google Event" :''}
                        </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="events">
                        <div className="action-button">
                            <button onClick={() => handleEventDelete(appointmentData?.id)}> <img
                                src={setImagePath(DELETE_ICON)}
                                alt="DELETE_ICON"
                            /></button>
                            <button onClick={() => handleEventEdit(appointmentData)}>
                                <img
                                    src={setImagePath(COMPOSE_ICON)}
                                    alt="EDIT_ICON"
                                />
                            </button>

                        </div>
                        <div className="events-heading"> <h4>{state.title}</h4>
                            <div className="event-date">  {
                                moment(state.startDate).format('MMMM Do YYYY') === moment(state.endDate).format('MMMM Do YYYY') ?
                                    <span>
                                        {moment(state.startDate).format('MMMM Do YYYY, h:mm a')} - {moment(state.endDate).format('h:mm a')}
                                    </span>
                                    :
                                    <span>
                                        {moment(state.startDate).format('MMMM Do YYYY, h:mm a')} - {moment(state.endDate).format('MMMM Do YYYY, h:mm a')}
                                    </span>
                            }</div>
                        </div>

                        <div className="mt-2">
                            {state?.description ?
                                <>
                                    <span className="icn"><img src={setImagePath(Description)}></img></span>
                                    <span className="desc">   {state?.description}</span>
                                </>
                                : ''}
                        </div>
                        <div className="mt-2">
                            {state?.location ?
                                <>
                                    <span className="icn"><img src={setImagePath(Location)}></img></span>
                                    <span className="desc">{state?.location}</span></>
                                : ''}

                        </div>
                        <div className="mt-2">
                            {state?.Attendees ?
                                <>

                                    <span className="icn"><img src={setImagePath(GuestIcon)}></img></span> <span className="desc">guests ({state?.Attendees?.length})</span>
                                </>
                                : ''}

                            <div className="detail_wrap">
                                {state?.Attendees ? state?.Attendees.map((data, i) => { return <div className="inner_detail" key={i}><h6 id={i}>{data?.email}</h6> <span>{data?.responseStatus === "accepted" ? "yes" : 'awaited'}</span></div> }) : ''}
                            </div>
                        </div>

                        <div className="mt-2">
                            <span className="icn"><img src={setImagePath(BellIcon)}></img></span>
                            <span className="desc">
                                {!state?.reminder?.useDefault ?
                                <>
                                    <div className="reminder_detail_wrap">
                                        {state?.reminder?.overrides?.length ? state?.reminder?.overrides.map((data, i) => {
                                            return (
                                               <span className="d-block mb-1" key={i}>
                                                    {convertGoogleReminderTime(data?.minutes)}
                                                </span>
                                                )}) : ''}
                                    </div>
                                </>
                                : "30 minutes before"}
                            </span>
                            {state.creatorName ? <h5><img src={setImagePath(UserIcon)} alt="user_icon"></img>  {state.creatorName}</h5> : ''}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export const ViewGoogleEvent = withRouter(ViewGoogleAppointment)