// Category List
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const categoryList = (data) => ({ type: CATEGORY_LIST, data });
export const SUCCESS_CATEGORY_LIST = 'SUCCESS_CATEGORY_LIST';
export const ERROR_CATEGORY_LIST = 'ERROR_CATEGORY_LIST';
export const categoryListResponse = (type, data) => ({ type, data });

// Category Create
export const CATEGORY_CREATE = 'CATEGORY_CREATE';
export const categoryCreate = (data) => ({ type: CATEGORY_CREATE, data });
export const SUCCESS_CATEGORY_CREATE = 'SUCCESS_CATEGORY_CREATE';
export const ERROR_CATEGORY_CREATE = 'ERROR_CATEGORY_CREATE';
export const categoryCreateResponse = (type, data) => ({ type, data });

// Expenses List
export const EXPENSE_LIST = 'EXPENSE_LIST';
export const expenseList = (data) => ({ type: EXPENSE_LIST, data });
export const SUCCESS_EXPENSE_LIST = 'SUCCESS_EXPENSE_LIST';
export const ERROR_EXPENSE_LIST = 'ERROR_EXPENSE_LIST';
export const expenseListResponse = (type, data) => ({ type, data });

// Expense Create
export const EXPENSE_CREATE = 'EXPENSE_CREATE';
export const expenseCreate = (data) => ({ type: EXPENSE_CREATE, data });
export const SUCCESS_EXPENSE_CREATE = 'SUCCESS_EXPENSE_CREATE';
export const ERROR_EXPENSE_CREATE = 'ERROR_EXPENSE_CREATE';
export const expenseCreateResponse = (type, data) => ({ type, data });

// Expense Update
export const EXPENSE_UPDATE = 'EXPENSE_UPDATE';
export const expenseUpdate = (data) => ({ type: EXPENSE_UPDATE, data });
export const SUCCESS_EXPENSE_UPDATE = 'SUCCESS_EXPENSE_UPDATE';
export const ERROR_EXPENSE_UPDATE = 'ERROR_EXPENSE_UPDATE';
export const expenseUpdateResponse = (type, data) => ({ type, data });

// Expenses Delete
export const EXPENSE_DELETE = 'EXPENSE_DELETE';
export const expenseDelete = (data) => ({ type: EXPENSE_DELETE, data });
export const SUCCESS_EXPENSE_DELETE = 'SUCCESS_EXPENSE_DELETE';
export const ERROR_EXPENSE_DELETE = 'ERROR_EXPENSE_DELETE';
export const expenseDeleteResponse = (type, data) => ({ type, data });

// Expenses Download
export const EXPENSE_DOWNLOAD = 'EXPENSE_DOWNLOAD';
export const expenseDownload = (data) => ({ type: EXPENSE_DOWNLOAD, data });
export const SUCCESS_EXPENSE_DOWNLOAD = 'SUCCESS_EXPENSE_DOWNLOAD';
export const ERROR_EXPENSE_DOWNLOAD = 'ERROR_EXPENSE_DOWNLOAD';
export const expenseDownloadResponse = (type, data) => ({ type, data });
