import { CREATE_BUSINESS, PUBLISH_BUSINESS_FORM, GET_BUSINESS_DETAIL, GET_BUSINESS_FORM_LIST } from '../routing/route';
import { request } from '../request/axios.request';

export async function createBusinessApi(data) {
    const businessData = data;
    return request({url: CREATE_BUSINESS, method: 'post', data: (businessData)});
}

export async function publishBusinessApi(data) {
    return request({
        url: PUBLISH_BUSINESS_FORM,
        method: 'get', 
    });
}

export async function getBusinessDetailApi(data) {
    return request({
        url: `${GET_BUSINESS_DETAIL}`, 
        method: 'get'
    });
}

export async function getBusinessListApi(id) {
    console.log("id",id)
    return request({
        url: `${GET_BUSINESS_FORM_LIST}/${id}`, 
        method: 'get'
    });
}
