export const DEFAULT_STATE = {
  addContactData: {},
  listContactData: {},
  deleteContactData: {},
  getContactByIdData: {},
  addContactNoteData: {},
  listContactNoteData: {},
  deleteContactNoteData: {},
  addContactTaskData: {},
  contactImportData: {},
  listContactTaskData: {},
  deleteContactTaskData: {},
  appointmentListData: {},
  getAllGmailMessagesIdByEmail: {},
  getGmailMessagesDataByEmail: [],
  emailTokenErrorData: {},
  contactEmailLoading: true,
};