import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious, fieldValidator, floatingWithTwoDecimal } from '../../../../common/custom';
import { Header } from '../../../component/frontend/header/header'
import { Footer } from '../../../component/frontend/footer/footer'
import {
    getBookingById, deleteBooking, addBookingNote, listBookingNote, deleteBookingNote, updateBookingNote,
    listBookingTask, addBookingTask, deleteBookingTask, updateBookingTask, createBookingPayment
} from '../../../../duck/booking/booking.action';
import { Link, withRouter } from "react-router-dom";
import { LIST_BOOKINGS, EDIT_BOOKING_BASE, VIEW_CONTACT_BASE, ADD_BASIC_INVOICE } from "../../../../routing/routeContants";
import _ from 'lodash';
import ORANGE_ARROW from '../../../../assets/images/orange-arrow-left.svg'
import Swal from 'sweetalert2'
import Modal from "react-bootstrap/Modal";
import { validateInputs } from '../../../../common/validation';
import { constants, selectStyle } from '../../../../common/constants';
import CALENDAR from "../../../../assets/images/calendar.png"
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Loader } from '../../../component/frontend/loader/loader'
import ShowMoreText from 'react-show-more-text';
import { setImagePath } from '../../../../common/custom'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getUserDetails } from '../../../../storage/user';
import { SubscriptionPlan } from "../profile/subscriptionPlans"
import { BookingPayment } from "./bookingPaymentsModal";

const BACKEND_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const NewViewBooking = props => {
    let bookingId;
    if (props.match && _.has(props.match, 'params') && _.has(props.match.params, 'id')) {
        bookingId = props.match.params.id
    }
    const userData = getUserDetails();
    const currentPlan = userData && userData.planData
    const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
    const [bookingData, setBookingData] = useState({});

    const textAreaRef = useRef();
    const textAreaTwoRef = useRef();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const getBookingByIdData = useSelector(state => state.booking.getBookingByIdData);
    const prevGetBookingByIdData = usePrevious({ getBookingByIdData });
    const deleteBookingData = useSelector(state => state.booking.deleteBookingData);
    const prevDeleteBookingData = usePrevious({ deleteBookingData });

    // Set initial State Value For View Detail
    const [leadSource, setLeadSource] = useState('-')
    const [state, setState] = useState({
        email: '', organization: '', phone: '', refferBy: '', firstName: '', lastName: '',
        correctInput: constants.RIGHT_INPUT, wrongInput: constants.WRONG_INPUT, duration: '',
        firstNameInformation: '', location: '', detail: '', start_Date: '', event_type: '', service_type: '',
        latLngUrl: '', name: '', contactId: '', virtualEvent: false, meetingId: '', passcode: '',
        totalAmount: 0, date: '', dateErr: '', dateCls: '', booking_invoice_payment_total: 0,
        address_line_1: "",
        addressOneErr: "",
        addressOneCls: "",
        address_line_2: "",
        addressTwoErr: "",
        addressTwoCls: "",
        city: "",
        cityErr: "",
        cityCls: "",
        state: "",
        statesErr: "",
        statesCls: "",
        zip: "",
        zipErr: "",
        zipCls: "",
        other_1: "",
        other_1Err: "",
        other_1Cls: "",
        other_2: "",
        other_2Err: "",
        other_2Cls: "",
        other_3: "",
        other_3Err: "",
        other_3Cls: "",
        birthday: "",
        birthdayErr: "",
        birthdayCls: "",
    });
    //set The State value of Payments
    const datepickerRef = useRef();
    const PaymentMethodTypeOption = [{ value: 'Online payment', label: 'Online payment' }, { value: 'Credit Card ', label: 'Credit Card ' }, { value: 'Cash', label: 'Cash' }, { value: 'Bank Check', label: 'Bank Check' }, { value: 'Square ', label: 'Square ' }, { value: 'Stripe', label: 'Stripe' }, { value: 'Venmo', label: 'Venmo' }, { value: 'PayPal', label: 'PayPal' }];
    const [paymentModalShow, setPaymentModalShow] = useState(false)

    //Booking Payment State
    const [paymentItems, setPaymentItems] = useState([
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: 0 },
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: 0 },
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: 0 },
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: 0 }
    ]);
    // Set The State Value Of Contact Note 
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [noteServiceMessage, setNoteServiceMessage] = useState('');
    const [noteState, setNoteState] = useState({
        note: '', noteCls: '', noteErr: '', page: 1, limit: 10, totalNoteRecord: 0,
        notesList: [], noteId: '', hasMore: true
    });
    const addBookingNoteData = useSelector(state => state.booking.addBookingNoteData);
    const prevAddBookingNoteData = usePrevious({ addBookingNoteData });
    const listBookingNoteData = useSelector(state => state.booking.listBookingNoteData);
    const prevListBookingNoteData = usePrevious({ listBookingNoteData });
    const deleteBookingNoteData = useSelector(state => state.booking.deleteBookingNoteData);
    const prevDeleteBookingNoteData = usePrevious({ deleteBookingNoteData });

    // Set The State Value Of Task 
    const taskDueTypeOption = [{ value: 'Due in 1 Day', label: 'Due in 1 Day' }, { value: 'Due in 3 Days', label: 'Due in 3 Days' }, { value: 'Due in 1 Week', label: 'Due in 1 Week' }, { value: 'Due in 1 Month', label: 'Due in 1 Month' }, { value: 'Custom', label: 'Custom' }, { value: 'No due date', label: 'No due date' }];
    const taskTypeOption = [{ value: 'To-do', label: 'To-do' }, { value: 'Follow up', label: 'Follow up' }];
    const taskViewOptions = [{ value: '', label: 'All' }, { value: 0, label: 'Open' }, { value: 1, label: 'Closed' }];
    const [taskModalShow, setTaskModalShow] = useState(false);
    const [taskFilter, setTaskFilter] = useState(0);
    const [taskFilterSelect, setTaskFilterSelect] = useState({ value: 0, label: 'Open' });
    const [taskServiceMessage, setTaskServiceMessage] = useState('');
    const [bookingPaymentsServiceMessage, setBookingPaymentsServiceMessage] = useState('');
    const [datePayment, setDatePayment] = useState({
        dateCls: '', dateErr: '', totalCls: '', totalErr: ''
    })
    const [taskState, setTaskState] = useState({
        taskName: '', taskNameCls: '', taskNameErr: '',
        taskType: 'To-do', taskTypeErr: '', taskTypeCls: '',
        taskDueType: 'Due in 1 Day', taskDueTypeErr: '', taskDueTypeCls: '',
        customDate: new Date(), customDateErr: '', customDateCls: '',
        page: 1, limit: 10, totalTaskRecord: 0, taskDueTypeSelect: { value: 'Due in 1 Day', label: 'Due in 1 Day' },
        tasksList: [], taskId: '', hasMore: true, taskTypeSelect: { value: 'To-do', label: 'To-do' }, associateLeadSelect: '', associateLeadSelectValue: ''
    });
    const [getBookingByIdpaymentsData, setGetBookingByIdpaymentsData] = useState([]);
    const [getBookingPaymentStatus, setgetBookingPaymentStatus] = useState(false)
    const listBookingTaskData = useSelector(state => state.booking.listBookingTaskData);
    const prevListBookingTaskData = usePrevious({ listBookingTaskData });
    const addBookingTaskData = useSelector(state => state.booking.addBookingTaskData);
    const prevAddBookingTaskData = usePrevious({ addBookingTaskData });
    const deleteBookingTaskData = useSelector(state => state.booking.deleteBookingTaskData);
    const prevDeleteBookingTaskData = usePrevious({ deleteBookingTaskData });

    const createBookingPaymentData = useSelector(state => state.booking.createBookingPaymentData);
    const prevCreateBookingPaymentData = usePrevious({ createBookingPaymentData });

    const [attachments, setAttachments] = useState();

    // Show more/less state
    const [showMoreFields, setShowMoreFields] = useState(false);

    // Check Validation Function 
    const checkValidation = (field, value, type, maxLength, minLength, fieldType) => {
        return fieldValidator(field, value, type, state.password, maxLength, minLength, fieldType)
    }

    // Set The Note Input Values
    const setNoteValue = (e, type, maxLength, minLength) => {
        let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength)
        setNoteState({ ...noteState, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName });
        setNoteServiceMessage('');
    }

    // Set The Task Input Values
    const setTaskValue = (e, type, maxLength, minLength, fieldType) => {
        let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength, fieldType)
        if (e.target.name === 'taskDueType') {
            setTaskState({
                ...taskState, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName,
                customDate: '', customDateErr: '', customDateCls: ''
            });
        } else {
            setTaskState({ ...taskState, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName });
        }
        setTaskServiceMessage('');
    }

    // On Load Get Contact
    useEffect(() => {
        setLoader(true)
        dispatch(getBookingById({ id: bookingId }))
        dispatch(listBookingNote({ booking_id: bookingId, limit: noteState.limit, page: noteState.page }))
        dispatch(listBookingTask({ booking_id: bookingId, limit: taskState.limit, page: taskState.page, status: taskFilter }))
    }, [bookingId]); // eslint-disable-line react-hooks/exhaustive-deps

    // List Contact Data and Note Data 
    useEffect(() => {
        if (prevGetBookingByIdData && prevGetBookingByIdData.getBookingByIdData !== getBookingByIdData) {
            if (getBookingByIdData && _.has(getBookingByIdData, 'data') && getBookingByIdData.success === true) {
                setLoader(false)
                if (getBookingByIdData.data && getBookingByIdData.data.id) {
                    setBookingData(getBookingByIdData.data)
                    let leadSource = '-';
                    if (getBookingByIdData.data && getBookingByIdData.data.website !== null) {
                        leadSource = getBookingByIdData.data.website
                    }
                    if (getBookingByIdData.data && getBookingByIdData.data.source_type && getBookingByIdData.data.source_type.id) {
                        leadSource = getBookingByIdData.data.source_type.name
                    }
                    if (getBookingByIdData.data && getBookingByIdData.data.referred_by && getBookingByIdData.data.referred_by.id) {
                        leadSource = getBookingByIdData.data.referred_by.first_name + ' ' + (getBookingByIdData.data.referred_by && getBookingByIdData.data.referred_by.last_name !== null ? getBookingByIdData.data.referred_by.last_name : '')
                    }
                    setLeadSource(leadSource)
                    let latLngUrl = '';
                    if (getBookingByIdData.data.lat_long && getBookingByIdData.data.lat_long !== null) {
                        let placeData = JSON.parse(getBookingByIdData.data.lat_long);
                        latLngUrl = placeData.lat + ',' + placeData.lng + '&query_place_id=' + placeData.place_id
                    }
                    setState({
                        ...state, email: getBookingByIdData.data.contact.email ? getBookingByIdData.data.contact.email : '', organization: getBookingByIdData.data.organization,
                        name: getBookingByIdData.data.name ? getBookingByIdData.data.name : '',
                        firstName: getBookingByIdData.data.contact.first_name ? getBookingByIdData.data.contact.first_name : '', lastName: getBookingByIdData.data.contact.last_name ? getBookingByIdData.data.contact.last_name : '',
                        latLngUrl: latLngUrl,
                        refferBy: (getBookingByIdData.data && getBookingByIdData.data.referred_by !== null ? { value: getBookingByIdData.data.referred_by.id, label: getBookingByIdData.data.referred_by.first_name + ' ' + (getBookingByIdData.data.referred_by && getBookingByIdData.data.referred_by.last_name ? getBookingByIdData.data.referred_by.last_name : '') } : ''),
                        firstNameInformation: getBookingByIdData.data.first_name_information, location: getBookingByIdData.data.location ? getBookingByIdData.data.location : '', detail: getBookingByIdData.data.detail ? getBookingByIdData.data.detail : '',
                        phone: getBookingByIdData.data.contact.phone ? getBookingByIdData.data.contact.phone : '',
                        start_Date: getBookingByIdData.data && getBookingByIdData.data.start_date ? getBookingByIdData.data.start_date : '', amount: getBookingByIdData.data.amount ? getBookingByIdData.data.amount : null, received_amount: getBookingByIdData.data.received_amount ? getBookingByIdData.data.received_amount : 0, event_type: getBookingByIdData.data.event_type,
                        virtualEvent: getBookingByIdData.data.virtual_event === 0 ? false : true,
                        meetingId: getBookingByIdData.data.meeting_id !== null ? getBookingByIdData.data.meeting_id : '',
                        passcode: getBookingByIdData.data.passcode !== null ? getBookingByIdData.data.passcode : '',
                        service_type: getBookingByIdData.data && getBookingByIdData.data.service_type && getBookingByIdData.data.service_type.id ? getBookingByIdData.data.service_type.name : '', duration: getBookingByIdData.data ? getBookingByIdData.data.duration : '', contactId: getBookingByIdData.data.contact.id ? getBookingByIdData.data.contact.id : '',
                        booking_invoice_payment_total: getBookingByIdData.data.booking_invoice_payment_total ? getBookingByIdData.data.booking_invoice_payment_total : 0,

                        firstNameInformation: getBookingByIdData?.data?.contact?.first_name_information,
                        allLeads: getBookingByIdData?.data?.contact?.leads,
                        address: getBookingByIdData?.data?.contact?.address,
                        address_line_1: getBookingByIdData?.data?.contact?.address_line_1,
                        address_line_2: getBookingByIdData?.data?.contact?.address_line_2,
                        state: getBookingByIdData?.data?.contact?.state,
                        city: getBookingByIdData?.data?.contact?.city,
                        zip: getBookingByIdData?.data?.contact?.zip,
                        birthday: getBookingByIdData?.data?.contact?.birthday,
                        other_1: getBookingByIdData?.data?.contact?.other_1,
                        other_2: getBookingByIdData?.data?.contact?.other_2,
                        other_3: getBookingByIdData?.data?.contact?.other_3,
                    })
                    if (getBookingByIdData.data.payments.length > 0) {
                        setgetBookingPaymentStatus(true);
                        setGetBookingByIdpaymentsData(getBookingByIdData.data.payments);
                    }
                } else {
                    props.history.push(LIST_BOOKINGS)
                }
            }
            if (getBookingByIdData && _.has(getBookingByIdData, 'message') && getBookingByIdData.success === false) {
                setLoader(false)
            }
        }
        if (prevListBookingNoteData && prevListBookingNoteData.listBookingNoteData !== listBookingNoteData) {
            if (listBookingNoteData && _.has(listBookingNoteData, 'data') && listBookingNoteData.success === true) {
                setLoader(false)
                let mergeNote = noteState.notesList.concat(listBookingNoteData.data)
                if (mergeNote.length === listBookingNoteData.total) {
                    setNoteState({ ...noteState, notesList: mergeNote, totalNoteRecord: listBookingNoteData.total, hasMore: false })
                } else {
                    setNoteState({ ...noteState, notesList: mergeNote, totalNoteRecord: listBookingNoteData.total, hasMore: true })
                }
            }
            if (listBookingNoteData && _.has(listBookingNoteData, 'message') && listBookingNoteData.success === false) {
                setLoader(false)
                setNoteModalShow(false)
            }
        }
        if (prevListBookingTaskData && prevListBookingTaskData.listBookingTaskData !== listBookingTaskData) {
            if (listBookingTaskData && _.has(listBookingTaskData, 'data') && listBookingTaskData.success === true) {
                setLoader(false)
                let mergeTask = taskState.tasksList.concat(listBookingTaskData.data)
                if (mergeTask.length === listBookingTaskData.total) {
                    setTaskState({ ...taskState, tasksList: mergeTask, totalTaskRecord: listBookingTaskData.total, hasMore: false })
                } else {
                    setTaskState({ ...taskState, tasksList: mergeTask, totalTaskRecord: listBookingTaskData.total, hasMore: true })
                }

            }
            if (listBookingTaskData && _.has(listBookingTaskData, 'message') && listBookingTaskData.success === false) {
                setLoader(false)
                setTaskModalShow(false)
            }
        }
        setAttachments(getBookingByIdData)
    }, [getBookingByIdData, prevGetBookingByIdData, listBookingNoteData, prevListBookingNoteData, prevListBookingTaskData, listBookingTaskData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Delete Contact Data 
    const deleteContactFunction = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'If you delete this booking, all associated Notes, Tasks and references will be lost. Are you sure you want to delete this booking?',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showCloseButton: true,
            customClass: "mycustom-alert",
            cancelButtonClass: 'cancel-alert-note',
        }).then((result) => {
            if (result.value) {
                setLoader(true)
                dispatch(deleteBooking({ booking_id: bookingId }))
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
            }
        })
    }

    // Get Delete Contact Data Props
    useEffect(() => {
        if (prevDeleteBookingData && prevDeleteBookingData.deleteBookingData !== deleteBookingData) {
            if (deleteBookingData && _.has(deleteBookingData, 'data') && deleteBookingData.success === true) {
                setLoader(false)
                props.history.push(LIST_BOOKINGS)
            }
            if (deleteBookingData && _.has(deleteBookingData, 'message') && deleteBookingData.success === false) {
                setLoader(false)
            }
        }
    }, [deleteBookingData, prevDeleteBookingData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Add Contact Note
    const showContactNoteModal = (e) => {
        e.currentTarget.blur();
        setNoteModalShow(true);
        setNoteServiceMessage('');
        setTimeout(function () { textAreaRef.current.focus(); }, 300);
        setNoteState({ ...noteState, noteCls: '', note: '', noteErr: '', noteId: '' })
    }

    // Save Contact Note 
    const saveContactNote = () => {
        let success = '';
        let error = state.wrongInput;
        let note = noteState.note, noteErr = '', noteCls = success, getError = false;

        if (validateInputs('required', note) === 'empty') {
            noteErr = 'Please enter note.';
            noteCls = error
            getError = true;
        }

        setNoteState({
            ...noteState, noteCls, noteErr
        })

        if (getError === false && noteErr === '') {
            setLoader(true)
            if (noteState.noteId) {
                dispatch(updateBookingNote({ booking_id: bookingId, detail: note, id: noteState.noteId }));
            } else {
                dispatch(addBookingNote({ booking_id: bookingId, detail: note }));
            }
        }
    }

    // After Add Contact Note Data
    useEffect(() => {
        if (prevAddBookingNoteData && prevAddBookingNoteData.addBookingNoteData !== addBookingNoteData) {
            if (addBookingNoteData && _.has(addBookingNoteData, 'data') && addBookingNoteData.success === true) {
                setNoteModalShow(false)
                setLoader(false)
                if (addBookingNoteData.data && addBookingNoteData.data.id) {
                    let existNoteList = noteState.notesList;
                    let index = _.findIndex(existNoteList, { id: addBookingNoteData.data.id });
                    existNoteList.splice(index, 1, addBookingNoteData.data);

                } else {
                    setNoteState({ ...noteState, page: 1, notesList: [] })
                    dispatch(listBookingNote({ booking_id: bookingId, limit: noteState.limit, page: 1 }))
                }
            }
            if (addBookingNoteData && _.has(addBookingNoteData, 'message') && addBookingNoteData.success === false) {
                setLoader(false)
                setNoteServiceMessage(addBookingNoteData.message)
            }
        }
    }, [addBookingNoteData, prevAddBookingNoteData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Note Data  By Pagination
    const getNotePageData = () => {
        let page = (noteState.page) + 1
        setNoteState({ ...noteState, page: page })
        dispatch(listBookingNote({ booking_id: bookingId, limit: noteState.limit, page: page }))
    }

    // Delete Contact Note Data 
    const deleteContactNoteFunction = (e, id) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this note!',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showCloseButton: true,
            customClass: "mycustom-alert",
            cancelButtonClass: 'cancel-alert-note',
        }).then((result) => {
            if (result.value) {
                setLoader(true)
                dispatch(deleteBookingNote({ booking_id: bookingId, id: id }))
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
            }
        })
    }

    // Get Delete Contact Note Data Props
    useEffect(() => {
        if (prevDeleteBookingNoteData && prevDeleteBookingNoteData.deleteBookingNoteData !== deleteBookingNoteData) {
            if (deleteBookingNoteData && _.has(deleteBookingNoteData, 'data') && deleteBookingNoteData.success === true) {
                setNoteState({ ...noteState, page: 1, notesList: [] })
                dispatch(listBookingNote({ booking_id: bookingId, limit: noteState.limit, page: 1 }))
            }
            if (deleteBookingNoteData && _.has(deleteBookingNoteData, 'message') && deleteBookingNoteData.success === false) {
                setLoader(false)
            }
        }
    }, [deleteBookingNoteData, prevDeleteBookingNoteData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Show Updated Note Data 
    const showUpdateNoteData = (e, id, detail) => {
        e.preventDefault();
        setNoteState({ ...noteState, noteId: id, note: detail })
        setNoteModalShow(true)
    }

    // Show Contact Task
    const showContactTaskModal = (e) => {
        e.currentTarget.blur();
        setTaskModalShow(true);
        setTaskServiceMessage('');
        setTaskState({
            ...taskState, taskName: '', taskNameCls: '', taskNameErr: '',
            taskType: 'To-do', taskTypeErr: '', taskTypeCls: '', taskTypeSelect: { value: 'To-do', label: 'To-do' },
            taskDueType: 'Due in 1 Day', taskDueTypeErr: '', taskDueTypeCls: '', taskDueTypeSelect: { value: 'Due in 1 Day', label: 'Due in 1 Day' },
            customDate: new Date(), customDateErr: '', customDateCls: '', taskId: '', associateLeadSelect: '', associateLeadSelectValue: ''
        })
        setTimeout(function () { textAreaTwoRef.current.focus(); }, 300);
    }

    // set date for custom 
    const dateForCustom = (date) => {
        if (date === null) {
            setTaskState({ ...taskState, customDate: '', customDateCls: state.wrongInput, customDateErr: 'Please select custom date' })
        } else {
            setTaskState({ ...taskState, customDate: date, customDateCls: '', customDateErr: '' })
        }
        setTaskServiceMessage('');
    }

    // Save Contact Task 
    const saveContactTask = (status) => {
        let success = '';
        let error = state.wrongInput;
        let taskName = taskState.taskName, taskNameErr = '', taskNameCls = success,
            taskDueType = taskState.taskDueType, taskDueTypeErr = '', taskDueTypeCls = success,
            taskType = taskState.taskType, taskTypeErr = '', taskTypeCls = success,
            customDate = taskState.customDate, customDateErr = '', customDateCls = success,
            getError = false;

        if (validateInputs('required', taskName) === 'empty') {
            taskNameErr = 'Please enter task name';
            taskNameCls = error
            getError = true;
        }

        if (validateInputs('required', taskDueType) === 'empty') {
            taskDueTypeErr = 'Please select task due type';
            taskDueTypeCls = error
            getError = true;
        }

        if (validateInputs('required', taskType) === 'empty') {
            taskTypeErr = 'Please select task type';
            taskTypeCls = error
            getError = true;
        }

        if (taskDueType === 'Custom') {
            if (validateInputs('required', (customDate !== '' ? (customDate.getDate() + ' ' + customDate.getMonth()) : '')) === 'empty') {
                customDateErr = 'Please select custom date.';
                customDateCls = error
                getError = true;
            }
            setTaskState({
                ...taskState, taskNameErr, taskNameCls, taskTypeCls, taskTypeErr, taskDueTypeCls, taskDueTypeErr,
                customDateCls, customDateErr
            })
        } else {
            setTaskState({
                ...taskState, taskNameErr, taskNameCls, taskTypeCls, taskTypeErr, taskDueTypeCls, taskDueTypeErr
            })
        }

        setTaskServiceMessage('')

        if (taskDueType === 'Custom') {
            if (getError === false && taskNameErr === '' && taskTypeErr === '' && taskDueTypeErr === '' && customDateErr === '') {
                setLoader(true)
                if (taskState.taskId) {
                    let taskData = {
                        booking_id: bookingId,
                        detail: taskName,
                        task_due_type: taskDueType,
                        task_type: taskType,
                        custom_date: moment(customDate).format('YYYY-MM-DD'),
                        id: taskState.taskId
                    }
                    if (taskState.associateLeadSelectValue && taskState.associateLeadSelectValue.id) {
                        taskData.refer_to = taskState.associateLeadSelectValue.id
                    }
                    if (status === 1) {
                        taskData.status = 1
                    }
                    dispatch(updateBookingTask(taskData));

                } else {
                    let taskData = {
                        booking_id: bookingId,
                        detail: taskName,
                        task_due_type: taskDueType,
                        task_type: taskType,
                        custom_date: moment(customDate).format('YYYY-MM-DD'),
                    };
                    if (taskState.associateLeadSelectValue && taskState.associateLeadSelectValue.id) {
                        taskData.refer_to = taskState.associateLeadSelectValue.id
                    }
                    dispatch(addBookingTask(taskData));
                }

            }
        } else {
            if (getError === false && taskNameErr === '' && taskTypeErr === '' && taskDueTypeErr === '') {
                setLoader(true)
                if (taskState.taskId) {
                    let taskData = {
                        booking_id: bookingId,
                        detail: taskName,
                        task_due_type: taskDueType,
                        task_type: taskType,
                        id: taskState.taskId
                    }
                    if (taskState.associateLeadSelectValue && taskState.associateLeadSelectValue.id) {
                        taskData.refer_to = taskState.associateLeadSelectValue.id
                    }
                    if (status === 1) {
                        taskData.status = 1
                    }
                    dispatch(updateBookingTask(taskData));

                } else {
                    let taskData = {
                        booking_id: bookingId,
                        detail: taskName,
                        task_due_type: taskDueType,
                        task_type: taskType
                    };
                    if (taskState.associateLeadSelectValue && taskState.associateLeadSelectValue.id) {
                        taskData.refer_to = taskState.associateLeadSelectValue.id
                    }
                    dispatch(addBookingTask(taskData));
                }

            }
        }
        //
    }

    // After Add Contact Task Data
    useEffect(() => {
        if (prevAddBookingTaskData && prevAddBookingTaskData.addBookingTaskData !== addBookingTaskData) {
            if (addBookingTaskData && _.has(addBookingTaskData, 'data') && addBookingTaskData.success === true) {
                setTaskModalShow(false)
                if (taskState.taskId !== '') {

                    let existTaskList = taskState.tasksList;
                    if (taskFilter === 0 && addBookingTaskData.data.status === 1) {
                        _.remove(existTaskList, function (task) {
                            return task.id === addBookingTaskData.data.id
                        });
                    } else {
                        let index = _.findIndex(existTaskList, { id: addBookingTaskData.data.id });
                        existTaskList.splice(index, 1, addBookingTaskData.data);
                    }
                    setLoader(false)
                } else {
                    setTaskState({ ...taskState, page: 1, tasksList: [] })
                    let condition;
                    if (taskFilter === 0 || taskFilter === 1) {
                        condition = { booking_id: bookingId, limit: taskState.limit, page: 1, status: taskFilter }
                    } else {
                        condition = { booking_id: bookingId, limit: taskState.limit, page: 1 }
                    }
                    dispatch(listBookingTask(condition))
                }
            }
            if (addBookingTaskData && _.has(addBookingTaskData, 'message') && addBookingTaskData.success === false) {
                setLoader(false)
                setTaskServiceMessage(addBookingTaskData.message)
            }
        }
    }, [addBookingTaskData, prevAddBookingTaskData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Task Data  By Pagination
    const getTaskPageData = () => {
        let page = (taskState.page) + 1
        setTaskState({ ...taskState, page: page })
        let condition;
        if (taskFilter) {
            condition = { booking_id: bookingId, limit: taskState.limit, page: page, status: taskFilter }
        } else {
            condition = { booking_id: bookingId, limit: taskState.limit, page: page }
        }
        dispatch(listBookingTask(condition))
    }

    // Delete Contact Task Data 
    const deleteContactTaskFunction = (e, id) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this task!',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showCloseButton: true,
            customClass: "mycustom-alert",
            cancelButtonClass: 'cancel-alert-note',
        }).then((result) => {
            if (result.value) {
                setLoader(true)
                dispatch(deleteBookingTask({ booking_id: bookingId, id: id }))
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
            }
        })
    }

    // Get Delete Contact Task Data Props
    useEffect(() => {
        if (prevDeleteBookingTaskData && prevDeleteBookingTaskData.deleteBookingTaskData !== deleteBookingTaskData) {
            if (deleteBookingTaskData && _.has(deleteBookingTaskData, 'data') && deleteBookingTaskData.success === true) {
                let condition;
                setTaskState({ ...taskState, page: 1, tasksList: [] })
                if (taskFilter) {
                    condition = { booking_id: bookingId, limit: taskState.limit, page: 1, status: taskFilter }
                } else {
                    condition = { booking_id: bookingId, limit: taskState.limit, page: 1 }
                }
                dispatch(listBookingTask(condition))
            }
            if (deleteBookingTaskData && _.has(deleteBookingTaskData, 'message') && deleteBookingTaskData.success === false) {
                setLoader(false)
            }
        }
    }, [deleteBookingTaskData, prevDeleteBookingTaskData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Show Updated Task Data 
    const showUpdateTaskData = (e, data) => {
        e.preventDefault();
        let arr = ["Due in 1 Day", "Due in 3 Days", "Due in 1 Week", "Due in 1 Month", "Custom", "No due date"];
        if (_.includes(arr, data.task_due_type)) {
            setTaskState({
                ...taskState, taskName: data.detail, taskNameCls: '', taskNameErr: '',
                taskType: data.task_type, taskTypeErr: '', taskTypeCls: '', taskTypeSelect: { value: data.task_type, label: data.task_type },
                taskDueType: data.task_due_type, taskDueTypeErr: '', taskDueTypeCls: '', taskDueTypeSelect: { value: data.task_due_type, label: data.task_due_type },
                customDate: data.custom_date !== null ? moment(data.custom_date).toDate() : new Date(), customDateErr: '', customDateCls: '', taskId: data.id,
                associateLeadSelectValue: (data && data.refer_to !== null ? data.refer_to : '')
            })
        } else {
            setTaskState({
                ...taskState, taskName: data.detail, taskNameCls: '', taskNameErr: '',
                taskType: data.task_type, taskTypeErr: '', taskTypeCls: '', taskTypeSelect: { value: data.task_type, label: data.task_type },
                taskDueType: 'Custom', taskDueTypeErr: '', taskDueTypeCls: '', taskDueTypeSelect: { value: 'Custom', label: 'Custom' },
                customDate: data.custom_date !== null ? moment(data.custom_date).toDate() : new Date(), customDateErr: '', customDateCls: '', taskId: data.id,
                associateLeadSelectValue: (data && data.refer_to !== null ? data.refer_to : '')
            })
        }
        setTaskModalShow(true)
    }

    // On Change Task Filter 
    const onChangeTaskFilter = (data) => {
        setTaskFilterSelect(data)
        setTaskFilter(data.value)
        setTaskState({ ...taskState, page: 1, tasksList: [] })
        let condition;
        if (data.value) {
            condition = { booking_id: bookingId, limit: taskState.limit, page: 1, status: data.value }
        } else {
            condition = { booking_id: bookingId, limit: taskState.limit, page: 1 }
        }
        dispatch(listBookingTask(condition))
    }

    // Check Due Task
    /* const checkDueTask = (data) => {
        if(data.task_due_type==='Due in 3 Days'){
            return moment().isBefore(moment(data.created_at).add(3, 'days'))
        } else if(data.task_due_type==='Due in 1 Day'){
            return moment().isBefore(moment(data.created_at).add(1, 'days'))
        } else if(data.task_due_type==='Due in 1 Week'){
            return moment().isBefore(moment(data.created_at).add(1, 'week'))
        } else if(data.task_due_type==='Due in 1 Month'){
            return moment().isBefore(moment(data.created_at).add(1, 'months'))
        } else if(data.task_due_type==='No due date'){
            return true
        }else if(data.task_due_type==='Custom'){
            return moment().isBefore(moment(data.custom_date))
        }
    } */

    // Check Scroll Note
    const noteScrollList = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && noteState.hasMore) {
            getNotePageData()
        }
    }

    // Check Scroll Task
    const taskScrollList = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && taskState.hasMore) {
            getTaskPageData()
        }
    }

    const BookingPaymentModal = () => {
        let arr = [];
        if (getBookingByIdpaymentsData.length > 0) {
            _.map(getBookingByIdpaymentsData, (val, ind) => {
                arr.push({ payment_date: val.payment_date ? moment(val.payment_date).toDate() : '', payment_method: val.payment_method ? { value: val.payment_method, label: val.payment_method } : { value: 'Online payment', label: 'Online payment' }, payment_amount: val.payment_amount ? val.payment_amount : 0 })
            })
            for (let i = 1; i => 4; i++) {
                if (arr.length < 4) {
                    arr.push({ payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' })
                }
                else {
                    break;
                }
            }
            setPaymentItems(arr)
        } else {
            setPaymentItems([
                { payment_date: getBookingByIdData && getBookingByIdData.data && getBookingByIdData.data.received_amount && getBookingByIdData.data.created_at ? moment(getBookingByIdData.data.created_at).toDate() : '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: getBookingByIdData && getBookingByIdData.data && getBookingByIdData.data.received_amount ? getBookingByIdData.data.received_amount : '' },
                { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' },
                { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' },
                { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' }
            ]);
        }
        setDatePayment({ dateCls: '', dateErr: '', totalCls: '', totalErr: '' })
        setBookingPaymentsServiceMessage('');
        setPaymentModalShow(true);
    }

    // handle click for add item
    const handleAddPaymentClick = (e) => {
        e.preventDefault();
        setPaymentItems([...paymentItems, { payment_date: '', payment_method: 'Online payment', payment_amount: 0 },
        ]);
    };

    // Line Item Value Set
    const PaymentItemSetValue = (i, event) => {
        const { name, value } = event.target;
        let itm = [...paymentItems];
        itm[i] = { ...itm[i], [name]: value };
        setPaymentItems(itm);
    }
    // set date for booking 
    const dateForBooking = (date) => {
        if (date === null) {
            setState({ ...state, date: '', dateCls: state.wrongInput, dateErr: 'Please select date', showTime: false })
        } else {
            setState({ ...state, date: date, dateCls: '', dateErr: '', showTime: false })
        }
        setBookingPaymentsServiceMessage('');
    }

    const PaymentItemSetData = (i, event, name) => {
        let itm = [...paymentItems];
        itm[i] = { ...itm[i], [name]: event };
        setPaymentItems(itm);
        setBookingPaymentsServiceMessage('');
    }

    // Create invoice by quote 
    const createInvoiceByQuote = (e) => {
        e.currentTarget.blur()
        // Add Free trial expire then working is blocked
        if (currentPlan && currentPlan.plan_is_active === 0) {
            // Free trial expire then working is fine   
            //if (currentPlan && currentPlan.plan_is_active === 0 && currentPlan.subscription_product_id !== 1) {
            let buttonMsg = currentPlan.subscription_product_id === 1 ? 'View Plans' : 'Renew Plan'
            let warMsg = currentPlan.subscription_product_id === 1 ? 'Free Trial Expired' : 'Subscription Expired'
            let msg = currentPlan.subscription_product_id === 1 ? 'Your free trial has expired. Please subscribe to a plan to access the application. ' : 'Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ';
            Swal.fire({
                title: warMsg,
                html: msg,
                showCancelButton: true,
                confirmButtonText: buttonMsg,
                cancelButtonText: 'Close',
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note',
            }).then((result) => {
                if (result.value) {
                    setSubscriptionModalShow(true)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // console.log('cancel')
                }
            })
        } else {
            props.history.push({
                pathname: ADD_BASIC_INVOICE,
                state: { invoiceBookingData: bookingData }
            })
        }
    }

    // Line Item Remove
    const removePaymentItem = (e, index) => {
        e.preventDefault();
        const item = [...paymentItems];
        item.splice(index, 1);
        setPaymentItems(item);
    }
    //Calculate Total Amount
    useEffect(() => {
        const calculateTotalAmount = () => {
            const mainFee = _.filter(paymentItems, (dd) => _.has(dd, 'payment_amount'));
            const totalFee = _.sumBy(mainFee, fee => {
                return (parseFloat(fee.payment_amount) ? parseFloat(fee.payment_amount) : 0);
            });
            setState({ ...state, totalAmount: (parseFloat(totalFee)) })
        }
        calculateTotalAmount()
    }, [paymentItems])// eslint-disable-line react-hooks/exhaustive-deps

    const showTimeSelection = () => {
        setTimeout(function () { datepickerRef.current.setOpen(true); }, 100);
    };

    const handleBookingPayments = () => {
        if (currentPlan && currentPlan.plan_is_active === 1) {
            let success = '';
            let error = state.wrongInput;
            let dateErr = '', dateCls = success, getError = false, totalErr = '', totalCls = success;
            let paymentarr = [];
            let paymentData;
            _.map(paymentItems, (val, ind) => {
                if (val.payment_amount && val.payment_date) {
                    paymentarr.push(
                        { "payment_date": val.payment_date ? moment(val.payment_date).format('DD-MM-YYYY') : '', "payment_amount": val.payment_amount ? val.payment_amount : 0, "payment_method": val.payment_method.value }
                    )
                }
                if (paymentarr.length == 0) {
                    totalErr = 'At least fill one row of amount data ';
                    totalCls = error;
                    getError = true;
                }
                if (val.payment_amount) {
                    if (validateInputs('required', (val.payment_date)) === 'empty' || val.payment_date === null) {
                        dateErr = 'Please select date ';
                        dateCls = error;
                        getError = true;
                    }
                }
            })

            /* if(state.received_amount < state.totalAmount ){
                totalErr = 'Total Amount more than deposit amount.';
                totalCls = error
                getError = true;
            } */

            if (state.totalAmount < 0) {
                totalErr = 'Total Amount is negative.';
                totalCls = error
                getError = true;
            }
            setDatePayment({
                ...datePayment, dateCls, dateErr, totalErr, totalCls
            })
            paymentData = JSON.stringify({ payments: paymentarr })
            setBookingPaymentsServiceMessage('')
            if (getError === false && dateErr === '' && totalErr === '') {
                setLoader(true)
                let bookingPaymentData = {
                    booking_id: bookingId,
                    payments: paymentData
                };
                dispatch(createBookingPayment(bookingPaymentData))
            }
        } else {
            Swal.fire({
                title: 'Upgrade Required',
                html: 'Your current plan doesn’t have access to this feature. Please upgrade your account and try again.',
                showCancelButton: true,
                confirmButtonText: 'View Plans',
                cancelButtonText: 'Close',
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note',
            }).then((result) => {
                if (result.value) {
                    setSubscriptionModalShow(true)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // console.log('cancel')
                }
            })
        }
    }
    //After Create Booking Payments
    useEffect(() => {
        if (prevCreateBookingPaymentData && prevCreateBookingPaymentData.createBookingPaymentData !== createBookingPaymentData) {
            if (createBookingPaymentData && _.has(createBookingPaymentData, 'data') && createBookingPaymentData.success === true) {
                setPaymentModalShow(false)
                setLoader(false)
                dispatch(getBookingById({ id: bookingId }))
            }
            if (createBookingPaymentData && _.has(createBookingPaymentData, 'message') && createBookingPaymentData.success === false) {
                //setPaymentModalShow(false)
                setLoader(false)
                setBookingPaymentsServiceMessage(createBookingPaymentData.message)
            }
        }
    }, [createBookingPaymentData, prevCreateBookingPaymentData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Show more/less Fiedls handling
    const handleMoreFields = () => {
        if (showMoreFields) {
            setShowMoreFields(false)
        } else {
            setShowMoreFields(true)
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="main-site fixed--header">
                <Header getMainRoute={"bookings"} />
                <main className="site-body">
                    <section className="page-title contact--header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-auto title--col">
                                    <div>
                                        <ol className="breadcrumb d-none d-lg-flex">
                                            <li className="breadcrumb-item"><Link to={LIST_BOOKINGS}>Bookings</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{state.name}</li>
                                        </ol>
                                        {/* <h2 className="title"><Link to={LIST_BOOKINGS} className="d-lg-none mr-2"><img src={setImagePath(ORANGE_ARROW)} alt="" /></Link> {state.service_type}</h2><div>{"(" + state.event_type + ")"}</div> */}
                                        <h2 className="title"><Link to={LIST_BOOKINGS} className="d-lg-none mr-2" ><img src={setImagePath(ORANGE_ARROW)} alt="" /></Link><span className="title--text"> {state.name}</span> <small className="font-small">{"(" + state.event_type + ")"}</small></h2>
                                    </div>
                                    {/* <div className="dropdown d-lg-none custom-dropdown dropdown-toggle--mbl">
                                        <button className="btn dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={setImagePath(MENU_DOTTED)} alt="" />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Link to={ADD_BOOKING} className="dropdown-item" >Create Booking</Link>
                                            <Link to={ADD_LEAD} className="dropdown-item" >Create Lead</Link>
                                            <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Create Quote</a>
                                            <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Create Invoice</a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-auto ml-auto d-flex align-items-center title-elems">
                                    <button type="button" onClick={(e) => deleteContactFunction(e)} className="btn btn-danger mr-15 trashbtn">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="vuesax/bold/trash">
                                                <g id="trash">
                                                    <path
                                                        id="Vector"
                                                        d="M21.0699 5.23C19.4599 5.07 17.8499 4.95 16.2299 4.86V4.85L16.0099 3.55C15.8599 2.63 15.6399 1.25 13.2999 1.25H10.6799C8.34991 1.25 8.12991 2.57 7.96991 3.54L7.75991 4.82C6.82991 4.88 5.89991 4.94 4.96991 5.03L2.92991 5.23C2.50991 5.27 2.20991 5.64 2.24991 6.05C2.28991 6.46 2.64991 6.76 3.06991 6.72L5.10991 6.52C10.3499 6 15.6299 6.2 20.9299 6.73C20.9599 6.73 20.9799 6.73 21.0099 6.73C21.3899 6.73 21.7199 6.44 21.7599 6.05C21.7899 5.64 21.4899 5.27 21.0699 5.23Z"
                                                        fill="#DB0200"
                                                    />
                                                    <path
                                                        id="Vector_2"
                                                        d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z"
                                                        fill="#DB0200"
                                                    />
                                                </g>
                                            </g>
                                        </svg>Delete</button>
                                    <Link to={EDIT_BOOKING_BASE + bookingId} className="btn btn-secondary d-lg-none">Edit</Link>
                                    {/* <div className="dropdown custom-dropdown d-none d-lg-block mr-15">
                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Actions
                                    </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Link to={ADD_BOOKING} className="dropdown-item">Create Booking</Link>
                                            <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Create Lead</a>
                                            <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Create Quote</a>
                                            <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Create Invoice</a>
                                        </div>
                                    </div> */}
                                    {/* <Link to={ADD_BOOKING} className="btn btn-secondary mr-15 d-none d-lg-block">Create Booking </Link> */}
                                    <div className="btn-divider mr-15"></div>
                                    <button onClick={(e) => createInvoiceByQuote(e)} className="btn btn-secondary mr-15 d-none d-lg-flex">
                                        Create Invoice</button>
                                    <Link to={LIST_BOOKINGS} className="btn btn-primary d-none d-lg-block back">
                                        {/* <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="vuesax/linear/arrow-left">
                        <g id="arrow-left">
                          <path
                            id="Vector"
                            d="M15.0001 19.92L8.48009 13.4C7.71009 12.63 7.71009 11.37 8.48009 10.6L15.0001 4.07996"
                            stroke="#818186"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg> */}
                                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="BackArrow"><g data-name="Layer 2" fill="#ffffff" class="color000000 svgShape"><path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z" data-name="arrow-ios-back" fill="#ffffff" class="color000000 svgShape"></path></g></svg>
                                        Back</Link>
                                    {/* <div className="dropdown d-lg-none custom-dropdown dropdown-toggle--mbl">
                                        <button className="btn dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={setImagePath(MENU_DOTTED)} alt="" />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Link to={ADD_BOOKING} className="dropdown-item">Create Booking</Link>
                                            <Link to={ADD_LEAD} className="dropdown-item">Create Lead</Link>
                                            <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Create Quote</a>
                                            <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Create Invoice</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="middle-section">
                        <div className="container">
                            {state.start_Date !== '' && (moment().diff(state.start_Date, 'days') > 0) ?
                                <div className="row no-gutters-mbl">
                                    <div className="col-12">
                                        <div className="notify-completed d-inline-block w-100">Completed</div>
                                    </div>
                                </div>
                                : ''}

                            <div className="row no-gutters-mbl mb-lg-4">
                                <div className="col-12">
                                    <div className="main-card">
                                        <div className="card">
                                            <div className="card-header py-4 d-flex justify-content-between align-items-center">
                                                <h2>Booking Details</h2>
                                                <div className="card-header_btns d-flex justify-content-end align-items-center">
                                                    <Link to={EDIT_BOOKING_BASE + bookingId} className="btn btn-secondary d-none d-lg-block">Edit</Link>
                                                </div>
                                            </div>
                                            <div className="card-body pt-1">
                                                <div className="contact-detail--wrap">
                                                    <div className="row no-gutters-mbl">
                                                        <div className="col-xl-3 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Customer</label>
                                                                <div className="field-text">
                                                                    {state.firstName + ' ' + state.lastName}</div>
                                                                <div className="field-text">
                                                                    {state.phone}</div>
                                                                {/* <div className="field-text">
                                                                    {state.email}</div> */}
                                                                <div className="field-text">{bookingId ? <Link to={VIEW_CONTACT_BASE + state.contactId}>{state.email}</Link> : ''}</div>
                                                            </div>

                                                        </div>
                                                        <div className="col-xl-9 col-lg-8">
                                                            <div className="row no-gutters-mbl">
                                                                <div className="col-xl-4 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label>Date</label>
                                                                        {/* <div className="field-text">{moment(state.start_date).format('ddd, MMM DD YYYY')} </div> */}
                                                                        <div className="field-text">{moment(state.start_Date).format('ddd, MMM DD YYYY')}  </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label>Start Time (Duration)</label>
                                                                        <div ><strong>{moment(state.start_Date).format('HH:mm') !== '00:00' ? moment(state.start_Date).format("LT") + '' : 'TBD'}</strong>{' '}({state.duration ? state.duration : "TBD"}) </div>
                                                                        {/* <div ><strong>{moment(state.start_Date).format('HH:mm') !== '00:00' ? moment(state.start_Date).format("LT") + ' PST' : 'TBD'}</strong>{' '}({state.duration ? state.duration : "TBD"}) </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label>Payments <strong>(Balance)</strong></label>
                                                                        <div>   <a className="" onClick={() => { BookingPaymentModal() }}>
                                                                            <strong>{state.booking_invoice_payment_total === 0 ? userData.planData.country_currency_symbol + state.received_amount : userData.planData.country_currency_symbol + state.booking_invoice_payment_total}</strong>{' '}
                                                                        </a>
                                                                            <strong>
                                                                                {state.amount !== null ?
                                                                                    state.booking_invoice_payment_total === 0 ? state.received_amount <= state.amount ? '(' + userData.planData.country_currency_symbol + (parseFloat(state.amount) - parseFloat(state.received_amount)) + ")" : '(' + userData.planData.country_currency_symbol + '0)'
                                                                                        : state.booking_invoice_payment_total <= state.amount ? '(' + userData.planData.country_currency_symbol + (parseFloat(state.amount) - parseFloat(state.booking_invoice_payment_total)) + ")" : '(' + userData.planData.country_currency_symbol + '0)'
                                                                                    : ''}
                                                                            </strong>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label>Location</label>
                                                                        {state.virtualEvent ?
                                                                            <div className="field-text">
                                                                                <a href={/^https?:\/\//.test(state.location) ? `${state.location}` : `//${state.location}`} rel="noopener noreferrer" target="_blank" className="text-link">{state.location}</a>
                                                                                <p> {state.meetingId !== null ? state.meetingId : 'N/A'} <span className="font-weight-normal">(Meeting Id)</span> <br /> {state.passcode !== null ? state.passcode : 'N/A'} <span className="font-weight-normal">(Passcode)</span></p>
                                                                            </div>
                                                                            :
                                                                            <div className="field-text">{(state.location === "null" || !state.location) ? 'TBD' : state.location}
                                                                                {state.latLngUrl !== '' ?
                                                                                    <a href={"https://www.google.com/maps/search/?api=1&query=" + state.latLngUrl} rel="noopener noreferrer" target="_blank" className="text-link ml-1">(Map)</a>

                                                                                    : ''}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-4">
                                                                    <div className="form-group">
                                                                        <label>Address</label>
                                                                        <div className="field-text">
                                                                            {state?.address_line_1 || state?.address_line_2 || state?.state_name || state?.city || state?.zip ? `${state?.address_line_1 || ''}, ${state?.address_line_2 || ''}, ${state?.state || ''}, ${state?.city || ''}, ${state?.zip || ''}` : "-"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label>More information about {state.name}</label>
                                                                        <div className="field-text">{(state.detail === "null" || !state.detail) ? '-' : state.detail}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12">
                                                            <button className="show-less-btn" onClick={handleMoreFields}>
                                                                {showMoreFields ? '- Show Less' : '+ Show More'}
                                                            </button>
                                                        </div>
                                                        {showMoreFields && (
                                                            <>
                                                                <div className="col-xl-3 col-lg-4">
                                                                    <div className="form-group">
                                                                        <label>Birthday</label>
                                                                        <div className="field-text">{state?.birthday || "-"}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-4">
                                                                    <div className="form-group">
                                                                        <label>Other 1</label>
                                                                        <div className="field-text">{state?.other_1 || "-"}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-4">
                                                                    <div className="form-group">
                                                                        <label>Other 2</label>
                                                                        <div className="field-text">{state?.other_2 || "-"}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-4">
                                                                    <div className="form-group">
                                                                        <label>Other 3</label>
                                                                        <div className="field-text">{state?.other_3 || "-"}</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex">
                                                        <label className="">Attachments</label>
                                                        <div className="d-flex flex-wrap justify-content-start align-items-center ml-2">
                                                            {attachments?.data?.attachment?.length ? (
                                                                attachments.data.attachment.map((item, index) => (
                                                                    <a href={BACKEND_BASE_URL.slice(0, -6) + item?.attachment} target="_blank" rel="noopener noreferrer" key={item?.filename}>
                                                                        <div className="pdf-upload-divBooking d-flex justify-content-between align-items-center mb-2 ml-0">
                                                                            <div className="d-flex align-items-center upload-booking-file ml-4">{`${index + 1}_${item?.filename}`}</div>
                                                                        </div>
                                                                    </a>
                                                                ))
                                                            ) : (
                                                                <small>No Attachments</small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="leader--source">
                                                            Lead Source {leadSource === '-' ? '-' : (state.refferBy && state.refferBy.value ? <Link to={VIEW_CONTACT_BASE + state.refferBy.value}>{leadSource}</Link> : <a href="#leadSource" onClick={(e) => e.preventDefault()}>{leadSource}</a>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters-mbl">
                                <nav className="col-12 d-lg-none">
                                    <div className="nav nav-tabs notes-tasks_nav" id="nav-tab" role="tablist">
                                        <a className="nav-link active" id="nav-Notes-tab" data-toggle="tab" href="#nav-Notes" role="tab" aria-controls="nav-Notes" aria-selected="true">Notes</a>
                                        <a className="nav-link" id="nav-Tasks-tab" data-toggle="tab" href="#nav-Tasks" role="tab" aria-controls="nav-Tasks" aria-selected="false">Tasks</a>
                                    </div>
                                </nav>
                                <div className="col-12">
                                    <div className="row no-gutters-mbl tab-content" id="nav-tabContent">
                                        <div className="mbl-tabbing tab-pane col-lg-6 fade show active" id="nav-Notes" role="tabpanel" aria-labelledby="nav-Notes-tab">
                                            <div className="main-card">
                                                <div className="card ">
                                                    <div className="card-header py-4 d-flex justify-content-between align-items-center">
                                                        <h2>Notes</h2>
                                                        <div className="card-header_btns d-flex justify-content-end align-items-center">
                                                            <button type="button" onClick={(e) => showContactNoteModal(e)} className="btn btn-secondary" >Add Note</button>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">

                                                        <div className="table-responsive table-vertical-scroll" onScroll={(e) => noteScrollList(e)}>
                                                            <table className="table table-striped notes--table smart-table">
                                                                <tbody>
                                                                    {(noteState.notesList && noteState.notesList.length > 0) ?
                                                                        _.map(noteState.notesList, (data) => {
                                                                            return <tr key={"note" + data.id}>
                                                                                <td><a href="#updateNote" onClick={(e) => showUpdateNoteData(e, data.id, data.detail)} >{moment(data.created_at).format("ll")}</a></td>
                                                                                <td>
                                                                                    <ShowMoreText
                                                                                        lines={4}
                                                                                        more='Show More'
                                                                                        less='Show Less'
                                                                                        anchorClass=''
                                                                                    >
                                                                                        {data.detail}
                                                                                    </ShowMoreText>
                                                                                </td>
                                                                                <td className="text-right table-action">
                                                                                    <div className="d-flex">
                                                                                        {/* <a href="#updateNote" data-toggle="tooltip" data-placement="top" title="Edit" onClick={(e) => showUpdateNoteData(e, data.id, data.detail)} className="edit-icn mr-3">
                                                                                            <svg version="1.1" fill="#817F80" width="17px" height="17px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                                                viewBox="0 0 477.873 477.873" style={{ "enableBackground": "new 0 0 477.873 477.873" }} xmlSpace="preserve">
                                                                                                <g>
                                                                                                    <g>
                                                                                                        <path d="M392.533,238.937c-9.426,0-17.067,7.641-17.067,17.067V426.67c0,9.426-7.641,17.067-17.067,17.067H51.2
                                                                                                    c-9.426,0-17.067-7.641-17.067-17.067V85.337c0-9.426,7.641-17.067,17.067-17.067H256c9.426,0,17.067-7.641,17.067-17.067
                                                                                                    S265.426,34.137,256,34.137H51.2C22.923,34.137,0,57.06,0,85.337V426.67c0,28.277,22.923,51.2,51.2,51.2h307.2
                                                                                                    c28.277,0,51.2-22.923,51.2-51.2V256.003C409.6,246.578,401.959,238.937,392.533,238.937z"/>
                                                                                                    </g>
                                                                                                </g>
                                                                                                <g>
                                                                                                    <g>
                                                                                                        <path d="M458.742,19.142c-12.254-12.256-28.875-19.14-46.206-19.138c-17.341-0.05-33.979,6.846-46.199,19.149L141.534,243.937
                                                                                                    c-1.865,1.879-3.272,4.163-4.113,6.673l-34.133,102.4c-2.979,8.943,1.856,18.607,10.799,21.585
                                                                                                    c1.735,0.578,3.552,0.873,5.38,0.875c1.832-0.003,3.653-0.297,5.393-0.87l102.4-34.133c2.515-0.84,4.8-2.254,6.673-4.13
                                                                                                    l224.802-224.802C484.25,86.023,484.253,44.657,458.742,19.142z"/>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                        </a> */}
                                                                                        <a href="#deleteNote" data-toggle="tooltip" data-placement="top" title="Delete" onClick={(e) => deleteContactNoteFunction(e, data.id)} className="close-icn">
                                                                                            <svg width="17px" height="17px" fill="var(--danger)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                                                viewBox="0 0 174.239 174.239" style={{ "enableBackground": "new 0 0 174.239 174.239" }} xmlSpace="preserve">
                                                                                                <g>
                                                                                                    <path d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
                                                                                            c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
                                                                                                    />
                                                                                                    <path d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
                                                                                            s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
                                                                                            c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
                                                                                            c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"/>
                                                                                                </g>
                                                                                            </svg>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                        :
                                                                        <tr>
                                                                            <td colSpan="6" className="bg-white">
                                                                                <div className="no--contacts--note">
                                                                                    <h5 className="text-secondary">This booking doesn’t have any notes</h5>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mbl-tabbing" id="nav-Tasks" role="tabpanel" aria-labelledby="nav-Tasks-tab">
                                            <div className="main-card">
                                                <div className="card">
                                                    <div className="card-header py-4 d-flex justify-content-between align-items-center">
                                                        <h2>Tasks</h2>
                                                        <div className="card-header_btns d-flex justify-content-end align-items-center">
                                                            {/* <span className="mr-15">View</span> */}
                                                            <Select
                                                                styles={selectStyle}
                                                                isSearchable={false}
                                                                className="task-view-filter"
                                                                components={makeAnimated()}
                                                                value={taskFilterSelect}
                                                                defaultValue={taskFilterSelect}
                                                                options={taskViewOptions}
                                                                onChange={(data) => onChangeTaskFilter(data)}
                                                            />
                                                            {/* <select className="form-control form-control-lg" value={taskFilter} onChange={(e) => onChangeTaskFilter(e)} >
                                                            <option value="">All</option>
                                                            <option value="0">Open</option>
                                                            <option value="1">Closed</option>
                                                        </select> */}
                                                            {/* <div className="dropdown custom-dropdown">
                                                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                Open
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Open</a>
                                                                <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>Close</a>
                                                                <a className="dropdown-item" href="#google" onClick={(e) => e.preventDefault()}>---</a>
                                                            </div>
                                                        </div> */}
                                                            <button type="button" onClick={(e) => showContactTaskModal(e)} className="btn btn-secondary ml-15">Add Task </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                        <div className="table-responsive table-vertical-scroll" onScroll={(e) => taskScrollList(e)}>
                                                            <table className="table table-striped tasks--table smart-table" >
                                                                <tbody>
                                                                    {/* <tr>
                                                                    <td className="task--status"><s>Completed</s></td>
                                                                    <td className="task--todo"><s>To Do</s></td>
                                                                    <td className="task--subject"><s>Lorem ipsum dolor sit amet, consectetur adipiscing</s></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="task--status error">Overdue</td>
                                                                    <td className="task--todo">Reminder</td>
                                                                    <td className="task--subject">Lorem ipsum dolor sit amet, consectetur adipiscing</td>
                                                                </tr> */}
                                                                    {(taskState.tasksList && taskState.tasksList.length > 0) ?
                                                                        _.map(taskState.tasksList, (data) => {
                                                                            return <tr key={data.id}>
                                                                                {data.status === 0 ?
                                                                                    <>
                                                                                        {/* <td className="task--status">{checkDueTask(data) ? data.task_due_type : <span className="text-danger">Overdue</span>}</td> */}
                                                                                        <td className="task--status">{data.task_due_type === 'Overdue' ? <span className="text-danger">{data.task_due_type}</span> : (data.task_due_type === 'Due in 1 Day' ? 'Due Tomorrow' : data.task_due_type)}</td>
                                                                                        <td className="">{data.task_type}</td>
                                                                                        <td className="task--subject">
                                                                                            <a href="#updateTask" className="updateTask" onClick={(e) => showUpdateTaskData(e, data)} >
                                                                                                <ShowMoreText
                                                                                                    lines={4}
                                                                                                    more='Show More'
                                                                                                    less='Show Less'
                                                                                                    anchorClass=''
                                                                                                >
                                                                                                    {data.detail}
                                                                                                </ShowMoreText>
                                                                                            </a>
                                                                                        </td>
                                                                                        <td className="text-right table-action">
                                                                                            <div className="d-flex">
                                                                                                {/* <a href="#updateTask" data-toggle="tooltip" data-placement="top" title="Edit" onClick={(e) => showUpdateTaskData(e, data)} className="edit-icn mr-3">
                                                                                                    <svg version="1.1" fill="#817F80" width="17px" height="17px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                                                        viewBox="0 0 477.873 477.873" style={{ "enableBackground": "new 0 0 477.873 477.873" }} xmlSpace="preserve">
                                                                                                        <g>
                                                                                                            <g>
                                                                                                                <path d="M392.533,238.937c-9.426,0-17.067,7.641-17.067,17.067V426.67c0,9.426-7.641,17.067-17.067,17.067H51.2
                                                                                                    c-9.426,0-17.067-7.641-17.067-17.067V85.337c0-9.426,7.641-17.067,17.067-17.067H256c9.426,0,17.067-7.641,17.067-17.067
                                                                                                    S265.426,34.137,256,34.137H51.2C22.923,34.137,0,57.06,0,85.337V426.67c0,28.277,22.923,51.2,51.2,51.2h307.2
                                                                                                    c28.277,0,51.2-22.923,51.2-51.2V256.003C409.6,246.578,401.959,238.937,392.533,238.937z"/>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                        <g>
                                                                                                            <g>
                                                                                                                <path d="M458.742,19.142c-12.254-12.256-28.875-19.14-46.206-19.138c-17.341-0.05-33.979,6.846-46.199,19.149L141.534,243.937
                                                                                                    c-1.865,1.879-3.272,4.163-4.113,6.673l-34.133,102.4c-2.979,8.943,1.856,18.607,10.799,21.585
                                                                                                    c1.735,0.578,3.552,0.873,5.38,0.875c1.832-0.003,3.653-0.297,5.393-0.87l102.4-34.133c2.515-0.84,4.8-2.254,6.673-4.13
                                                                                                    l224.802-224.802C484.25,86.023,484.253,44.657,458.742,19.142z"/>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </a> */}
                                                                                                <a href="#deleteTask" data-toggle="tooltip" data-placement="top" title="Delete" onClick={(e) => deleteContactTaskFunction(e, data.id)} className="close-icn">
                                                                                                    <svg width="17px" height="17px" fill="var(--danger)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                                                        viewBox="0 0 174.239 174.239" style={{ "enableBackground": "new 0 0 174.239 174.239" }} xmlSpace="preserve">
                                                                                                        <g>
                                                                                                            <path d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
                                                                                            c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
                                                                                                            />
                                                                                                            <path d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
                                                                                            s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
                                                                                            c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
                                                                                            c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"/>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </a>
                                                                                            </div>
                                                                                        </td>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <td className="task--status"><s>{data.task_due_type}</s></td>
                                                                                        <td className="task--todo"><s>{data.task_type}</s></td>
                                                                                        <td className="task--subject"><s>{data.detail}</s></td>
                                                                                        <td className="text-right table-action">
                                                                                            <div className="d-flex">
                                                                                                <a href="#deleteTask" onClick={(e) => deleteContactTaskFunction(e, data.id)} className="close-icn">
                                                                                                    <svg width="17px" height="17px" fill="var(--danger)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                                                        viewBox="0 0 174.239 174.239" style={{ "enableBackground": "new 0 0 174.239 174.239" }} xmlSpace="preserve">
                                                                                                        <g>
                                                                                                            <path d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
                                                                                            c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
                                                                                                            />
                                                                                                            <path d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
                                                                                            s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
                                                                                            c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
                                                                                            c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"/>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </a>
                                                                                            </div>
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                            </tr>
                                                                        })
                                                                        :
                                                                        <tr>
                                                                            <td colSpan="6" className="bg-white">
                                                                                <div className="no--contacts--note">
                                                                                    <h5 className="text-secondary">This booking doesn’t have any tasks</h5>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Note Modal*/}
                    <Modal show={noteModalShow} onHide={() => setNoteModalShow(false)} size="lg" className="" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {noteState.noteId ? 'Update' : 'Add New'} Note
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noteServiceMessage ? <div className="errorCls errCommonCls  mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{noteServiceMessage}</div> : ''}
                            <form>
                                <div className="floating-label">
                                    <textarea ref={textAreaRef} className="new-input floating-input floating-textarea" name="note" value={noteState.note || ''} onChange={(e) => setNoteValue(e, 'required', null, null)} placeholder="Type your notes here…" rows="5"></textarea>
                                    <label class="bold-text">Type your notes here…</label>
                                    {noteState.noteErr ? <span className="errorValidationMessage"> {noteState.noteErr}</span> : ''}
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-dark" onClick={() => setNoteModalShow(false)}>Cancel</button>
                            <button type="button" onClick={() => saveContactNote()} className="btn btn-primary">{noteState.noteId ? 'Update' : 'Add'}</button>
                        </Modal.Footer>
                    </Modal>

                    {/* Task Modal*/}
                    <Modal show={taskModalShow} onHide={() => setTaskModalShow(false)} size="lg" className="" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {taskState.taskId ? 'Task Details' : 'Add New Task'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {taskServiceMessage ? <div className="errorCls errCommonCls  mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{taskServiceMessage}</div> : ''}
                            <form>
                                <div className="floating-label">
                                    <textarea
                                        ref={textAreaTwoRef}
                                        className="new-input floating-textarea floating-input"
                                        name="taskName"
                                        value={taskState.taskName || ''}
                                        onChange={(e) => setTaskValue(e, 'required', null, null)}
                                        placeholder="Task Name" rows="5"></textarea>
                                    <label class="">Task Name</label>
                                    {taskState.taskNameErr ? <span className="errorValidationMessage"> {taskState.taskNameErr}</span> : ''}
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 mb-0">
                                        <div className={"floating-label " + taskState.taskDueTypeCls}>
                                            <Select
                                                styles={selectStyle}
                                                className="floating-select"
                                                components={makeAnimated()}
                                                isSearchable={false}
                                                value={taskState.taskDueTypeSelect}
                                                defaultValue={taskState.taskDueTypeSelect}
                                                options={taskDueTypeOption}
                                                placeholder="Select"
                                                onChange={data => setTaskState({ ...taskState, taskDueType: data.value, taskDueTypeSelect: data })}
                                            />
                                            {taskState.taskDueTypeErr ? <span className="errorValidationMessage"> {taskState.taskDueTypeErr}</span> : ''}
                                        </div>
                                    </div>
                                    {taskState.taskDueType === "Custom" ? <div className="form-group col-md-4 mb-0">
                                        <div className={"floating-label " + taskState.customDateCls}>
                                            <DatePicker
                                                type="text"
                                                name="customDate"
                                                className={taskState.customDateCls ? "floating-input " + taskState.customDateCls : "floating-input"}
                                                placeholder="" selected={taskState.customDate}
                                                onChange={(date) => dateForCustom(date)}
                                                minDate={moment().toDate()}
                                                placeholderText="Select a date"
                                            />
                                            {/* <label>Select a date</label> */}
                                            {taskState.customDateErr ? <span className="errorValidationMessage"> {taskState.customDateErr}</span> : ''}
                                        </div>
                                    </div> : ''}
                                    <div className="form-group col-md-4 mb-0">
                                        <div className={"floating-label " + taskState.taskTypeCls}>
                                            <Select
                                                styles={selectStyle}
                                                className="floating-select"
                                                components={makeAnimated()}
                                                isSearchable={false}
                                                value={taskState.taskTypeSelect}
                                                defaultValue={taskState.taskTypeSelect}
                                                options={taskTypeOption}
                                                onChange={data => setTaskState({ ...taskState, taskType: data.value, taskTypeSelect: data })}
                                            />
                                            {taskState.taskTypeErr ? <span className="errorValidationMessage"> {taskState.taskTypeErr}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            {taskState.taskId ?
                                <button type="button" className="btn btn-secondary " onClick={() => saveContactTask(1)}>Mark as Completed</button>
                                :
                                <button type="button" className="btn btn-dark" onClick={() => setTaskModalShow(false)}>Cancel</button>
                            }
                            <button type="button" onClick={() => saveContactTask(0)} className="btn btn-primary">{taskState.taskId ? 'Save' : 'Add'}</button>
                        </Modal.Footer>
                    </Modal>

                    {/* Subscription Modal*/}
                    <SubscriptionPlan loader={(data) => setLoader(data)}
                        openSubscriptionModal={subscriptionModalShow}
                        closeSubscriptionModal={() => setSubscriptionModalShow(false)}
                        updatePlanDetail={(data) => { setSubscriptionModalShow(false); setLoader(false); }}
                        currentPlan={currentPlan}
                    />

                    <BookingPayment
                        paymentModalShow={paymentModalShow}
                        setPaymentModalShow={() => setPaymentModalShow(false)}
                        name={state.name}
                        datePayment={datePayment}
                        bookingPaymentsServiceMessage={bookingPaymentsServiceMessage}
                        paymentItems={paymentItems}
                        PaymentItemSetData={(i, event, name) => { PaymentItemSetData(i, event, name) }}
                        PaymentItemSetValue={(i, event) => PaymentItemSetValue(i, event)}
                        handleBookingPayments={() => handleBookingPayments()}
                        totalAmount={state.totalAmount}
                        getBookingPaymentStatus={getBookingPaymentStatus}
                        currentPlan={currentPlan}
                    />
                </main>
                <Footer />
            </div >
        </>
    );
}

export const ViewBooking = withRouter(NewViewBooking)
