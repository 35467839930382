import React from "react";
import { setImagePath } from "../../../../../common/custom";
import ATTACHMENT_ICON from "../../../../../assets/images/attach_file_FILL0_wght400_GRAD0_opsz24.svg";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  EMAIL_VIEW_BASE,
} from "../../../../../routing/routeContants";
import { ConvertTimeFromUTCToIST } from "../../../../../common/constants";

export function MicrosoftTableList(props) {
  const {
    loading,
    search,
    msalLabel,
    emailListData,
    onCheckedValue,
    allCheckedValue,
    checkedAll,
    checkedAllCheckbox,
  } = props;
  return (
    <table className="table table-bordered wktable">
      <thead>
        <tr>
          <th className="p-0 wk-custom-label" width="10">
            <div className="custom-control custom-checkbox d-block">
              <input
                type="checkbox"
                name="check_all"
                onChange={(e) => checkedAllCheckbox(e.target.checked)}
                checked={checkedAll}
                className="custom-control-input"
                id={"checkAll"}
              />
              <label
                className="custom-control-label custom-cursor"
                htmlFor={"checkAll"}
              ></label>
            </div>
          </th>
          <th>Subject</th>
          <th>{["Sent Items", "Drafts"].includes(msalLabel && msalLabel?.displayName) ? "To" : "From"}</th>
          <th width="142px">Date</th>
          <th>Size</th>
          <th width="30">
            <img
              className="h-18"
              src={setImagePath(ATTACHMENT_ICON)}
              alt="ATTACHMENT_ICON"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {emailListData && emailListData?.value?.length ? (
          _.map(emailListData?.value, (message, index) => {
            const toRecipients = message?.toRecipients?.length && message?.toRecipients?.map(obj => obj.emailAddress.address).join(",");
            return (
              <tr
                key={index}
                className="custom-email-tr"
              >
                <td className="p-0 wk-custom-label">
                  <div className="custom-control custom-checkbox d-block">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name={message.id}
                      checked={_.includes(allCheckedValue, message.id)}
                      onChange={(e) => onCheckedValue(message.id, e.target.checked)}
                      id={"check" + message.id}
                    />
                    <label
                      className="custom-control-label custom-cursor"
                      htmlFor={"check" + message.id}
                    ></label>
                  </div>
                </td>
                <td className={`${message?.isRead ? "" : "font-weight-bold"}`}>
                  <Link
                    to={{ pathname: EMAIL_VIEW_BASE + message.id, state: { uniqueViewKey: "microsoft" } }}
                    className="custom-link-text custom-link-text-new"
                    title={message?.subject || "N/A"}
                  >
                    {message?.subject || "N/A"}
                  </Link>
                </td>
                <td className={`${message?.isRead ? "" : "font-weight-bold"}`}>{["Sent Items", "Drafts"].includes(msalLabel && msalLabel?.displayName) ? (toRecipients || "N/A") : message?.from?.emailAddress?.address || "N/A"}</td>
                <td className={`${message?.isRead ? "" : "font-weight-bold"}`}>
                  {ConvertTimeFromUTCToIST(message?.sentDateTime, "microsoft-list") || "-"}
                </td>
                <td className={`${message?.isRead ? "" : "font-weight-bold"}`}>
                  {message.sizeEstimate || "-"}
                </td>
                <td className="text-center">
                  {message?.hasAttachments ? (
                    <div className="action-btn p-0">
                      <img
                        src={setImagePath(ATTACHMENT_ICON)}
                        alt="ATTACHMENT_ICON"
                      />
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={8} className="text-center">
              {!loading
                ? search
                  ? "No Searched Results"
                  : "No Emails Found!"
                : "..."}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
