import { put, takeLatest } from 'redux-saga/effects';
import {
    listQuoteResponse, SUCCESS_LIST_QUOTE, ERROR_LIST_QUOTE, LIST_QUOTE,
    addQuoteResponse, SUCCESS_ADD_QUOTE, ERROR_ADD_QUOTE, ADD_QUOTE,
    addQuoteTemplateResponse, SUCCESS_ADD_QUOTE_TEMPLATE, ERROR_ADD_QUOTE_TEMPLATE, ADD_QUOTE_TEMPLATE,
    getQuoteTemplateByIdResponse, SUCCESS_GET_QUOTE_TEMPLATE_BY_ID, ERROR_GET_QUOTE_TEMPLATE_BY_ID, GET_QUOTE_TEMPLATE_BY_ID,
    getQuoteByIdResponse, SUCCESS_GET_QUOTE_BY_ID, ERROR_GET_QUOTE_BY_ID, GET_QUOTE_BY_ID,
    updateQuoteCustomerResponse, SUCCESS_UPDATE_QUOTE_CUSTOMER, ERROR_UPDATE_QUOTE_CUSTOMER, UPDATE_QUOTE_CUSTOMER,
    updateQuoteResponse, SUCCESS_UPDATE_QUOTE, ERROR_UPDATE_QUOTE, UPDATE_QUOTE,
    sendToCustomerQuoteResponse, SUCCESS_SEND_TO_CUSTOMER_QUOTE, ERROR_SEND_TO_CUSTOMER_QUOTE, SEND_TO_CUSTOMER_QUOTE,
    viewCustomerQuoteResponse, SUCCESS_VIEW_CUSTOMER_QUOTE, ERROR_VIEW_CUSTOMER_QUOTE, VIEW_CUSTOMER_QUOTE,
    changeQuoteStatusResponse, SUCCESS_CHANGE_QUOTE_STATUS, ERROR_CHANGE_QUOTE_STATUS, CHANGE_QUOTE_STATUS,
    reviseQuoteResponse, SUCCESS_REVISE_QUOTE, ERROR_REVISE_QUOTE, REVISE_QUOTE,
    getAcceptedQuoteResponse, SUCCESS_GET_ACCEPTED_QUOTE, ERROR_GET_ACCEPTED_QUOTE, GET_ACCEPTED_QUOTE,
    deleteQuoteResponse, SUCCESS_DELETE_QUOTE, ERROR_DELETE_QUOTE, DELETE_QUOTE,
    listQuoteTemplateResponse, SUCCESS_LIST_QUOTE_TEMPLATE, ERROR_LIST_QUOTE_TEMPLATE, LIST_QUOTE_TEMPLATE,
    updateBasicQuoteResponse, SUCCESS_UPDATE_BASIC_QUOTE, ERROR_UPDATE_BASIC_QUOTE, UPDATE_BASIC_QUOTE,
    updateMessageBodyResponse, SUCCESS_UPDATE_MESSAGE_BODY_QUOTE, ERROR_UPDATE_MESSAGE_BODY_QUOTE, UPDATE_MESSAGE_BODY_QUOTE,
    getMessageBodyResponse, SUCCESS_GET_MESSAGE_BODY_QUOTE, ERROR_GET_MESSAGE_BODY_QUOTE, GET_MESSAGE_BODY_QUOTE,

    attachmentsBrowseFileResponse, SUCCESS_ATTACHMENTS_BROWSE_FILE_QUOTE, ERROR_ATTACHMENTS_BROWSE_FILE_QUOTE, ATTACHMENTS_BROWSE_FILE_QUOTE,
    attachmentsBrowseFileDeleteResponse, SUCCESS_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, ERROR_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE,
} from './quote.action';
import {
    listQuoteApi, addQuoteApi, addQuoteTemplateApi, getQuoteTemplateByIdApi, getQuoteByIdApi,
    updateQuoteCustomerApi, updateQuoteApi, sendToCustomerQuoteApi, viewCustomerQuoteApi,
    changeQuoteStatusApi, reviseQuoteApi, getAcceptedQuoteApi, deleteQuoteApi, listQuoteTemplateApi,
    updateBasicQuoteApi, updateMessageBodyQuoteApi, getMessageBodyQuoteApi, attachmentsBrowseFileQuoteApi,
    attachmentsBrowseFileDeleteQuoteApi,
} from '../../../api/index';
import _ from 'lodash'
import { successNotification } from '../../common/notification-alert';

// List Quote Data
function* listQuoteRequest(data) {
    let getData = yield listQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(listQuoteResponse(SUCCESS_LIST_QUOTE, getData.data));
    } else {
        yield put(listQuoteResponse(ERROR_LIST_QUOTE, getData.data));
    }
}

export function* listQuoteWatcher() {
    yield takeLatest(LIST_QUOTE, listQuoteRequest);
}

// Add Quote Data
function* addQuoteRequest(data) {
    let getData = yield addQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(addQuoteResponse(SUCCESS_ADD_QUOTE, getData.data));
    } else {
        yield put(addQuoteResponse(ERROR_ADD_QUOTE, getData.data));
    }
}

export function* addQuoteWatcher() {
    yield takeLatest(ADD_QUOTE, addQuoteRequest);
}

// Add Quote Template Data
function* addQuoteTemplateRequest(data) {
    let getData = yield addQuoteTemplateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(addQuoteTemplateResponse(SUCCESS_ADD_QUOTE_TEMPLATE, getData.data));
    } else {
        yield put(addQuoteTemplateResponse(ERROR_ADD_QUOTE_TEMPLATE, getData.data));
    }
}

export function* addQuoteTemplateWatcher() {
    yield takeLatest(ADD_QUOTE_TEMPLATE, addQuoteTemplateRequest);
}

// List Quote Template
function* getQuoteTemplateByIdRequest(data) {
    let getData = yield getQuoteTemplateByIdApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getQuoteTemplateByIdResponse(SUCCESS_GET_QUOTE_TEMPLATE_BY_ID, getData.data));
    } else {
        yield put(getQuoteTemplateByIdResponse(ERROR_GET_QUOTE_TEMPLATE_BY_ID, getData.data));
    }
}

export function* getQuoteTemplateByIdWatcher() {
    yield takeLatest(GET_QUOTE_TEMPLATE_BY_ID, getQuoteTemplateByIdRequest);
}

// Get Quote By Id
function* getQuoteByIdRequest(data) {
    let getData = yield getQuoteByIdApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getQuoteByIdResponse(SUCCESS_GET_QUOTE_BY_ID, getData.data));
    } else {
        yield put(getQuoteByIdResponse(ERROR_GET_QUOTE_BY_ID, getData.data));
    }
}

export function* getQuoteByIdWatcher() {
    yield takeLatest(GET_QUOTE_BY_ID, getQuoteByIdRequest);
}

// Update Quote Customer Data
function* updateQuoteCustomerRequest(data) {
    let getData = yield updateQuoteCustomerApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(updateQuoteCustomerResponse(SUCCESS_UPDATE_QUOTE_CUSTOMER, getData.data));
    } else {
        yield put(updateQuoteCustomerResponse(ERROR_UPDATE_QUOTE_CUSTOMER, getData.data));
    }
}

export function* updateQuoteCustomerWatcher() {
    yield takeLatest(UPDATE_QUOTE_CUSTOMER, updateQuoteCustomerRequest);
}

// Update Quote
function* updateQuoteRequest(data) {
    let getData = yield updateQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(updateQuoteResponse(SUCCESS_UPDATE_QUOTE, getData.data));
    } else {
        yield put(updateQuoteResponse(ERROR_UPDATE_QUOTE, getData.data));
    }
}

export function* updateQuoteWatcher() {
    yield takeLatest(UPDATE_QUOTE, updateQuoteRequest);
}

// Send To Customer Quote
function* sendToCustomerQuoteRequest(data) {
    let getData = yield sendToCustomerQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(sendToCustomerQuoteResponse(SUCCESS_SEND_TO_CUSTOMER_QUOTE, getData.data));
    } else {
        yield put(sendToCustomerQuoteResponse(ERROR_SEND_TO_CUSTOMER_QUOTE, getData.data));
    }
}

export function* sendToCustomerQuoteWatcher() {
    yield takeLatest(SEND_TO_CUSTOMER_QUOTE, sendToCustomerQuoteRequest);
}

// View Customer Quote
function* viewCustomerQuoteRequest(data) {
    let getData = yield viewCustomerQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(viewCustomerQuoteResponse(SUCCESS_VIEW_CUSTOMER_QUOTE, getData.data));
    } else {
        yield put(viewCustomerQuoteResponse(ERROR_VIEW_CUSTOMER_QUOTE, getData.data));
    }
}

export function* viewCustomerQuoteWatcher() {
    yield takeLatest(VIEW_CUSTOMER_QUOTE, viewCustomerQuoteRequest);
}

// Change Quote Status
function* changeQuoteStatusRequest(data) {
    let getData = yield changeQuoteStatusApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        if (data.data.status === 'accept' || data.data.status === 'reject' || data.data.status === 'revision') {
            successNotification(getData.data.message)
        }
        yield put(changeQuoteStatusResponse(SUCCESS_CHANGE_QUOTE_STATUS, getData.data));
    } else {
        yield put(changeQuoteStatusResponse(ERROR_CHANGE_QUOTE_STATUS, getData.data));
    }
}

export function* changeQuoteStatusWatcher() {
    yield takeLatest(CHANGE_QUOTE_STATUS, changeQuoteStatusRequest);
}

// Revise Quote
function* reviseQuoteRequest(data) {
    let getData = yield reviseQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(reviseQuoteResponse(SUCCESS_REVISE_QUOTE, getData.data));
    } else {
        yield put(reviseQuoteResponse(ERROR_REVISE_QUOTE, getData.data));
    }
}

export function* reviseQuoteWatcher() {
    yield takeLatest(REVISE_QUOTE, reviseQuoteRequest);
}

// Get Accepted Quote
function* getAcceptedQuoteRequest() {
    let getData = yield getAcceptedQuoteApi();
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getAcceptedQuoteResponse(SUCCESS_GET_ACCEPTED_QUOTE, getData.data));
    } else {
        yield put(getAcceptedQuoteResponse(ERROR_GET_ACCEPTED_QUOTE, getData.data));
    }
}

export function* getAcceptedQuoteWatcher() {
    yield takeLatest(GET_ACCEPTED_QUOTE, getAcceptedQuoteRequest);
}

// Delete Quote 
function* deleteQuoteRequest(data) {
    let getData = yield deleteQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(deleteQuoteResponse(SUCCESS_DELETE_QUOTE, getData.data));
    } else {
        yield put(deleteQuoteResponse(ERROR_DELETE_QUOTE, getData.data));
    }
}

export function* deleteQuoteWatcher() {
    yield takeLatest(DELETE_QUOTE, deleteQuoteRequest);
}

// List Quote Template
function* listQuoteTemplateRequest(data) {
    let getData = yield listQuoteTemplateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(listQuoteTemplateResponse(SUCCESS_LIST_QUOTE_TEMPLATE, getData.data));
    } else {
        yield put(listQuoteTemplateResponse(ERROR_LIST_QUOTE_TEMPLATE, getData.data));
    }
}

export function* listQuoteTemplateWatcher() {
    yield takeLatest(LIST_QUOTE_TEMPLATE, listQuoteTemplateRequest);
}

// Update Basic Quote
function* updateBasicQuoteRequest(data) {
    let getData = yield updateBasicQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(updateBasicQuoteResponse(SUCCESS_UPDATE_BASIC_QUOTE, getData.data));
    } else {
        yield put(updateBasicQuoteResponse(ERROR_UPDATE_BASIC_QUOTE, getData.data));
    }
}

export function* updateBasicQuoteWatcher() {
    yield takeLatest(UPDATE_BASIC_QUOTE, updateBasicQuoteRequest);
}


// Update Quote Message Body
function* updateMessageBodyQuoteRequest(data) {
    let getData = yield updateMessageBodyQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateMessageBodyResponse(SUCCESS_UPDATE_MESSAGE_BODY_QUOTE, getData.data));
    } else {
        yield put(updateMessageBodyResponse(ERROR_UPDATE_MESSAGE_BODY_QUOTE, getData.data));
    }
}

export function* updateMessageBodyQuoteWatcher() {
    yield takeLatest(UPDATE_MESSAGE_BODY_QUOTE, updateMessageBodyQuoteRequest);
}

// get Quote Message Body
function* getMessageBodyQuoteRequest(data) {
    let getData = yield getMessageBodyQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(getMessageBodyResponse(SUCCESS_GET_MESSAGE_BODY_QUOTE, getData.data));
    } else {
        yield put(getMessageBodyResponse(ERROR_GET_MESSAGE_BODY_QUOTE, getData.data));
    }
}

export function* getMessageBodyQuoteWatcher() {
    yield takeLatest(GET_MESSAGE_BODY_QUOTE, getMessageBodyQuoteRequest);
}

// Attachments (Optional) Browse file data
function* attachmentsBrowseFileQuoteRequest(data) {
    let getData = yield attachmentsBrowseFileQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(attachmentsBrowseFileResponse(SUCCESS_ATTACHMENTS_BROWSE_FILE_QUOTE, getData.data));
    } else {
        yield put(attachmentsBrowseFileResponse(ERROR_ATTACHMENTS_BROWSE_FILE_QUOTE, getData.data));
    }
}

export function* attachmentsBrowseFileQuoteWatcher() {
    yield takeLatest(ATTACHMENTS_BROWSE_FILE_QUOTE, attachmentsBrowseFileQuoteRequest);
}

// Attachments (Optional) Browse file delete data
function* attachmentsBrowseFileDeleteQuoteRequest(data) {
    let getData = yield attachmentsBrowseFileDeleteQuoteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(attachmentsBrowseFileDeleteResponse(SUCCESS_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, getData.data));
    } else {
        yield put(attachmentsBrowseFileDeleteResponse(ERROR_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, getData.data));
    }
}

export function* attachmentsBrowseFileDeleteQuoteWatcher() {
    yield takeLatest(ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, attachmentsBrowseFileDeleteQuoteRequest);
}
