import { all } from "redux-saga/effects";
import {
  registrationWatcher,
  loginWatcher,
  logoutWatcher,
  forgotPasswordWatcher,
  resetPasswordWatcher,
  verifyTokenWatcher,
  resendEmailVerificationWatcher,
  countryListWatcher,
  logi2FAWatcher,
} from "../duck/auth/auth.saga";

import {
  addContactWatcher,
  listContactWatcher,
  deleteContactWatcher,
  getContactByIdWatcher,
  updateContactWatcher,
  addContactNoteWatcher,
  listContactNoteWatcher,
  deleteContactNoteWatcher,
  updateContactNoteWatcher,
  addContactTaskWatcher,
  contactImportWatcher,
  listContactTaskWatcher,
  deleteContactTaskWatcher,
  updateContactTaskWatcher,
  appointmentListWatcher,
  getAllGmailMessagesByContactEmailWatcher,
  getContactGmailMessagesByIdWatcher,
} from "../duck/contact/contact.saga";

import {
  addBookingWatcher,
  listBookingsWatcher,
  deleteBookingWatcher,
  getBookingByIdWatcher,
  updateBookingWatcher,
  addBookingNoteWatcher,
  listBookingNoteWatcher,
  deleteBookingNoteWatcher,
  updateBookingNoteWatcher,
  addBookingTaskWatcher,
  listBookingTaskWatcher,
  deleteBookingTaskWatcher,
  updateBookingTaskWatcher,
  createAppointmentWatcher,
  bookingListViewWatcher,
  deleteAppointmentWatcher,
  updateAppointmentWatcher,
  createBookingPaymentWatcher,
} from "../duck/booking/booking.saga";

import {
  addServiceWatcher,
  listServiceWithSourceWatcher,
  addSourceWatcher,
  listLeadStatusWatcher,
  addLeadWatcher,
  getLeadByIdWatcher,
  deleteLeadWatcher,
  updateLeadWatcher,
  addLeadNoteWatcher,
  listLeadNoteWatcher,
  updateLeadNoteWatcher,
  deleteLeadNoteWatcher,
  listLeadWatcher,
  addLeadTaskWatcher,
  updateLeadTaskWatcher,
  deleteLeadTaskWatcher,
  listLeadTaskWatcher,
  updateLeadStatusWatcher,
  listLeadWithPositionWatcher,
  markLeadStatusWatcher,
  listLostReasonWatcher,
  addLostReasonWatcher,
  getAllCompletedLeadsWatcher,
  customizeLeadStageWatcher,
  getAllGmailMessagesByLeadEmailWatcher,
  getGmailMessagesByIdWatcher,
} from "../duck/lead/lead.saga";

import {
  getProfileDetailWatcher,
  updateOwnerProfileDetailWatcher,
  updatePasswordWatcher,
  updateBusinessProfileDetailWatcher,
  getMenuCountWatcher,
  connectWithPaypalWatcher,
  makeTestPaymentWatcher,
  deletePaymentAccountWatcher,
  getSubscriptionPlanWatcher,
  createSubscriptionPlanWatcher,
  planApplyCouponWatcher,
  getStateTaxWatcher,
  updateSubscriptionPlanWatcher,
  cancelSubscriptionPlanWatcher,
  addProfileServiceWatcher,
  deleteAllDummyDataRequestWatcher,
  getStateWatcher,
  setUp2FAWatcher,
  getVerifyEnableWatcher,
  getVerifyDisableWatcher,
  updateAutoReplyDataWatcher,
  getAutoReplyDataWatcher,
  deleteHeaderImageWatcher,
  // Manage colunms List Configuration
  addConfigurationWatcher,
} from "../duck/profile/profile.saga";

import {
  addDashboardTaskWatcher,
  updateDashboardTaskWatcher,
  listDashboardTaskWatcher,
  deleteDashboardTaskWatcher,
  getBusinessSnapshotWatcher,
  listDashboardNotificationWatcher,
  deleteDashboardNotificationWatcher,
  deleteAllNotificationWatcher,
  markAsReadNotificationWatcher,
  userLoginStatusWatcher,
} from "../duck/dashboard/dashboard.saga";

import {
  listQuoteWatcher,
  addQuoteWatcher,
  addQuoteTemplateWatcher,
  getQuoteTemplateByIdWatcher,
  getQuoteByIdWatcher,
  updateQuoteCustomerWatcher,
  updateQuoteWatcher,
  sendToCustomerQuoteWatcher,
  viewCustomerQuoteWatcher,
  changeQuoteStatusWatcher,
  reviseQuoteWatcher,
  getAcceptedQuoteWatcher,
  deleteQuoteWatcher,
  listQuoteTemplateWatcher,
  updateBasicQuoteWatcher,
  updateMessageBodyQuoteWatcher,
  getMessageBodyQuoteWatcher,
  attachmentsBrowseFileQuoteWatcher,
  attachmentsBrowseFileDeleteQuoteWatcher,
} from "../duck/quote/quote.saga";

import {
  listInvoiceWatcher,
  addInvoiceWatcher,
  getInvoiceByIdWatcher,
  updateInvoiceWatcher,
  sendToCustomerInvoiceWatcher,
  viewCustomerInvoiceWatcher,
  markPaidInvoiceWatcher,
  invoiceMakePaymentWatcher,
  getPaidInvoiceWatcher,
  deleteInvoiceWatcher,
  updateBasicInvoiceWatcher,
  listInvoiceTemplateWatcher,
  addInvoiceTemplateWatcher,
  getInvoiceTemplateByIdWatcher,
  getMessageBodyInvoiceWatcher,
  updateMessageBodyInvoiceWatcher,
} from "../duck/invoice/invoice.saga";

import { contactUsFormWatcher } from "../duck/website/website.saga";

import {
  getGmailAuthLinkWatcher,
  getGmailAuthTokenWatcher,
  getGmailValidTokenWatcher,
  getAllGmailMessagesWatcher,
  getGmailMessageWatcher,
  deleteGmailMessageWatcher,
  modifyGmailMessageWatcher,
  getGmailMessageByIdWatcher,
  sendGmailMessageWatcher,
  sendGmailMessageWithAttachmentWatcher,
  getGmailMessageAttachmentByIdWatcher,
  resetEmailStateWatcher,
  setGmailMessageWatcher,
  getAllDraftGmailMessagesWatcher,
  getDraftGmailMessageByIdWatcher,
  getDraftGmailMessageWatcher,
  createDraftGmailMessageWatcher,
  deleteDraftGmailMessageWatcher,
  markGmailMessageAsUnreadWatcher,
  markGmailMessageAsTrashWatcher,
  markGmailMessageAsSpamWatcher,
  markGmailMessageAsInboxWatcher,
  saveSocialAuthTokenWatcher,
  updateDraftGmailMessageWatcher,
  emptyEmailStateWatcher,
  gmailAccountDeleteWatcher,
} from "../duck/email/google/google.saga";

import {
  getCalendarEventListWatcher,
  deleteCalendarEventListWatcher,
  deleteBatchCalendarEventListWatcher,
  editCalendarEventListWatcher,
  getMicrosoftEventListWatcher,
  deleteMicrosoftEventWatcher,
  editMicrosoftEventListWatcher,
} from "../duck/calendar/calendar.saga"

import {
  getOutlookAuthLinkWatcher,
  getAllOutlookMailFoldersWatcher,
  getAllOutlookMessagesWatcher,
  getOutlookMessageByIdWatcher,
  getOutlookAuthTokenWatcher,
  getOutlookMessageByIdAttachmentsWatcher,
  sendReplyToOutlookMessageByIdWatcher,
  sendReplyToAllOutlookMessageByIdWatcher,
  sendOutlookMessageWatcher,
  sendForwardOutlookMessageByIdWatcher,
  createOutlookDraftMessageWatcher,
  updateOutlookMessageWatcher,
  deleteOutlookMessageWatcher,
  moveOutlookMessageWatcher,
  markAsReadOutlookMessageWatcher,
  markAsUnreadOutlookMessageWatcher,
} from "../duck/email/microsoft/microsoft.saga";

import {
  categoryListWatcher,
  categoryCreateWatcher,
  expenseListWatcher,
  expenseCreateWatcher,
  expenseUpdateWatcher,
  expenseDeleteWatcher,
  expenseDownloadWatcher,
} from "../duck/expenses/expenses.saga"

import {
  createBusinessFormWatcher,
  viewPublicFormWatcher,
  viewBusinessFormWatcher,
  publishBusinessFormWatcher,
} from "../duck/businessForm/businessForm.saga"

export function* rootSaga() {
  yield all([
    registrationWatcher(),
    loginWatcher(),
    logi2FAWatcher(),
    logoutWatcher(),
    forgotPasswordWatcher(),
    countryListWatcher(),
    resetPasswordWatcher(),
    verifyTokenWatcher(),
    addContactWatcher(),
    listContactWatcher(),
    deleteContactWatcher(),
    getContactByIdWatcher(),
    updateContactWatcher(),
    addContactNoteWatcher(),
    listContactNoteWatcher(),
    deleteContactNoteWatcher(),
    updateContactNoteWatcher(),
    addContactTaskWatcher(),
    contactImportWatcher(),
    listContactTaskWatcher(),
    deleteContactTaskWatcher(),
    updateContactTaskWatcher(),
    resendEmailVerificationWatcher(),
    addBookingWatcher(),
    listBookingsWatcher(),
    deleteBookingWatcher(),
    getBookingByIdWatcher(),
    updateBookingWatcher(),
    addServiceWatcher(),
    addSourceWatcher(),
    addLeadWatcher(),
    listServiceWithSourceWatcher(),
    listLeadStatusWatcher(),
    getLeadByIdWatcher(),
    deleteLeadWatcher(),
    updateLeadWatcher(),
    addLeadNoteWatcher(),
    listLeadNoteWatcher(),
    updateLeadNoteWatcher(),
    deleteLeadNoteWatcher(),
    listLeadWatcher(),
    addLeadTaskWatcher(),
    updateLeadTaskWatcher(),
    deleteLeadTaskWatcher(),
    listLeadTaskWatcher(),
    updateLeadStatusWatcher(),
    listLeadWithPositionWatcher(),
    markLeadStatusWatcher(),
    listLostReasonWatcher(),
    addLostReasonWatcher(),
    getAllCompletedLeadsWatcher(),
    customizeLeadStageWatcher(),
    addBookingNoteWatcher(),
    listBookingNoteWatcher(),
    deleteBookingNoteWatcher(),
    updateBookingNoteWatcher(),
    addBookingTaskWatcher(),
    listBookingTaskWatcher(),
    deleteBookingTaskWatcher(),
    updateBookingTaskWatcher(),
    getProfileDetailWatcher(),
    updateOwnerProfileDetailWatcher(),
    updatePasswordWatcher(),
    updateBusinessProfileDetailWatcher(),
    addDashboardTaskWatcher(),
    updateDashboardTaskWatcher(),
    listDashboardTaskWatcher(),
    deleteDashboardTaskWatcher(),
    getBusinessSnapshotWatcher(),
    listDashboardNotificationWatcher(),
    deleteDashboardNotificationWatcher(),
    deleteAllNotificationWatcher(),
    markAsReadNotificationWatcher(),
    getMenuCountWatcher(),
    listQuoteWatcher(),
    addQuoteWatcher(),
    addQuoteTemplateWatcher(),
    getQuoteTemplateByIdWatcher(),
    getQuoteByIdWatcher(),
    updateQuoteCustomerWatcher(),
    updateQuoteWatcher(),
    sendToCustomerQuoteWatcher(),
    viewCustomerQuoteWatcher(),
    changeQuoteStatusWatcher(),
    reviseQuoteWatcher(),
    getAcceptedQuoteWatcher(),
    deleteQuoteWatcher(),
    listQuoteTemplateWatcher(),
    updateBasicQuoteWatcher(),
    connectWithPaypalWatcher(),
    makeTestPaymentWatcher(),
    addInvoiceWatcher(),
    listInvoiceWatcher(),
    getInvoiceByIdWatcher(),
    updateInvoiceWatcher(),
    sendToCustomerInvoiceWatcher(),
    viewCustomerInvoiceWatcher(),
    markPaidInvoiceWatcher(),
    invoiceMakePaymentWatcher(),
    getPaidInvoiceWatcher(),
    deleteInvoiceWatcher(),
    updateBasicInvoiceWatcher(),
    listInvoiceTemplateWatcher(),
    addInvoiceTemplateWatcher(),
    getInvoiceTemplateByIdWatcher(),
    contactUsFormWatcher(),
    deletePaymentAccountWatcher(),
    userLoginStatusWatcher(),
    getSubscriptionPlanWatcher(),
    createSubscriptionPlanWatcher(),
    planApplyCouponWatcher(),
    getStateTaxWatcher(),
    updateSubscriptionPlanWatcher(),
    cancelSubscriptionPlanWatcher(),
    addProfileServiceWatcher(),
    createAppointmentWatcher(),
    bookingListViewWatcher(),
    deleteAppointmentWatcher(),
    updateAppointmentWatcher(),
    appointmentListWatcher(),
    deleteAllDummyDataRequestWatcher(),
    createBookingPaymentWatcher(),
    getStateWatcher(),
    setUp2FAWatcher(),
    getVerifyEnableWatcher(),
    getVerifyDisableWatcher(),
    updateMessageBodyQuoteWatcher(),
    getMessageBodyQuoteWatcher(),
    attachmentsBrowseFileQuoteWatcher(),
    attachmentsBrowseFileDeleteQuoteWatcher(),
    getMessageBodyInvoiceWatcher(),
    updateMessageBodyInvoiceWatcher(),
    updateAutoReplyDataWatcher(),
    getAutoReplyDataWatcher(),
    getGmailAuthLinkWatcher(),
    getGmailAuthTokenWatcher(),
    getGmailValidTokenWatcher(),
    getAllGmailMessagesWatcher(),
    getGmailMessageWatcher(),
    deleteGmailMessageWatcher(),
    modifyGmailMessageWatcher(),
    getGmailMessageByIdWatcher(),
    sendGmailMessageWatcher(),
    sendGmailMessageWithAttachmentWatcher(),
    getGmailMessageAttachmentByIdWatcher(),
    resetEmailStateWatcher(),
    setGmailMessageWatcher(),
    getAllDraftGmailMessagesWatcher(),
    getDraftGmailMessageWatcher(),
    getDraftGmailMessageByIdWatcher(),
    createDraftGmailMessageWatcher(),
    deleteDraftGmailMessageWatcher(),
    markGmailMessageAsUnreadWatcher(),
    markGmailMessageAsTrashWatcher(),
    markGmailMessageAsSpamWatcher(),
    markGmailMessageAsInboxWatcher(),
    saveSocialAuthTokenWatcher(),
    updateDraftGmailMessageWatcher(),
    getCalendarEventListWatcher(),
    deleteCalendarEventListWatcher(),
    deleteBatchCalendarEventListWatcher(),
    editCalendarEventListWatcher(),
    getAllGmailMessagesByLeadEmailWatcher(),
    getGmailMessagesByIdWatcher(),
    getAllGmailMessagesByContactEmailWatcher(),
    getContactGmailMessagesByIdWatcher(),
    getOutlookAuthLinkWatcher(),
    getAllOutlookMailFoldersWatcher(),
    getAllOutlookMessagesWatcher(),
    getOutlookMessageByIdWatcher(),
    emptyEmailStateWatcher(),
    getOutlookAuthTokenWatcher(),
    getOutlookMessageByIdAttachmentsWatcher(),
    sendReplyToOutlookMessageByIdWatcher(),
    sendReplyToAllOutlookMessageByIdWatcher(),
    sendOutlookMessageWatcher(),
    sendForwardOutlookMessageByIdWatcher(),
    createOutlookDraftMessageWatcher(),
    updateOutlookMessageWatcher(),
    deleteOutlookMessageWatcher(),
    moveOutlookMessageWatcher(),
    markAsReadOutlookMessageWatcher(),
    markAsUnreadOutlookMessageWatcher(),
    getMicrosoftEventListWatcher(),
    deleteMicrosoftEventWatcher(),
    editMicrosoftEventListWatcher(),
    categoryListWatcher(),
    categoryCreateWatcher(),
    expenseListWatcher(),
    expenseCreateWatcher(),
    expenseUpdateWatcher(),
    expenseDeleteWatcher(),
    expenseDownloadWatcher(),
    deleteHeaderImageWatcher(),
    gmailAccountDeleteWatcher(),
    // Manage colunms List Configuration
    addConfigurationWatcher(),
    createBusinessFormWatcher(),
    viewPublicFormWatcher(),
    viewBusinessFormWatcher(),
    publishBusinessFormWatcher(),
  ]);
}
