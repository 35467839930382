import {
  UPDATE_GOOGLE_LABEL,
  UPDATE_MICROSOFT_LABEL,
  RESET_STATE,
  UPDATE_ACCORDION_LABEL,
  RESET_ACCORDION_STATE,
} from "./email.action";
import { DEFAULT_STATE } from "./email.state";

export const emailReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case UPDATE_GOOGLE_LABEL:
      const googleLabel = action.data;
      return { ...state, googleLabel };
    case UPDATE_MICROSOFT_LABEL:
      const microsoftLabel = action.data;
      return { ...state, microsoftLabel };
    case UPDATE_ACCORDION_LABEL:
      const accordionLabel = action.data;
      return { ...state, accordionLabel };
    case RESET_ACCORDION_STATE:
      return {
        ...state,
        googleLabel: "",
        microsoftLabel: {},
        accordionLabel: "gmail"
      };
    case RESET_STATE:
      return {
        ...state,
        googleLabel: "",
        microsoftLabel: {}
      };
    default:
      return state;
  }
};
