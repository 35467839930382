import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
// import Swal from "sweetalert2";
import { getUserDetails } from "../../../../storage/user";
// import { EmailHeader } from "../../../component/frontend/email/EmailHeader";
import { Footer } from "../../../component/frontend/footer/footer";
import { Header } from "../../../component/frontend/header/header";
import { Loader } from "../../../component/frontend/loader/loader";
import { setImagePath, usePrevious } from "../../../../common/custom";
import REFRESH_ICON from "../../../../assets/images/sync_FILL0_wght400_GRAD0_opsz24.svg";
import COMPOSE_ICON from "../../../../assets/images/edit_FILL0_wght400_GRAD0_opsz24.svg";
import DELETE_ICON from "../../../../assets/images/delete_FILL0_wght400_GRAD0_opsz24.svg";
import MORE_ICON from "../../../../assets/images/more_horiz_FILL0_wght400_GRAD0_opsz24.svg";
import SEARCH_ICON from "../../../../assets/images/search_FILL0_wght400_GRAD0_opsz48.svg";
import INBOX_ICON from "../../../../assets/images/inbox_FILL0_wght400_GRAD0_opsz24.svg";
import JUNK_ICON from "../../../../assets/images/report-ico.svg";
import MAIL_LABEL_ICON from "../../../../assets/images/mail-icon.svg";
import STAR_LABEL_ICON from "../../../../assets/images/star_label.svg";
import IMPORTANT_LABEL_ICON from "../../../../assets/images/important_label.svg";
import CROSS_ICON from "../../../../assets/images/Close_round_light.svg";
import UNREAD_ICON from "../../../../assets/images/drafts_FILL0_wght400_GRAD0_opsz48.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSocialDetails } from "../../../../routing/authService";
import {
  deleteDraftGmailMessage,
  deleteGmailMessage,
  emptyEmailState,
  getAllDraftGmailMessages,
  getAllGmailMessages,
  getDraftGmailMessageById,
  getGmailAuthLink,
  getGmailAuthToken,
  getGmailValidToken,
  gmailAccountDelete,
  markGmailMessageAsInbox,
  markGmailMessageAsSpam,
  markGmailMessageAsTrash,
  markGmailMessageAsUnread,
  modifyGmailMessage,
  resetEmailState,
  saveSocialAuthToken,
  setGmailMessage,
} from "../../../../duck/email/google/google.action";
import {
  COMPOSE_EMAIL,
  EMAIL_AUTH,
  EMAIL_LIST,
} from "../../../../routing/routeContants";
import { EmailHeader } from "../../../component/frontend/email/EmailHeader";
import _ from "lodash";
import { SubscriptionPlan } from "../profile/subscriptionPlans";
import { EmailSidebar } from "../../../component/frontend/email/EmailSidebar";
import { isTokenExpired } from "../../../../common/constants";
import {
  getGmailDraftMessageByIdApi,
  getGmailMessageById,
} from "../../../../../api/index";
import { GoogleTableList } from "../../../component/frontend/email/google/GoogleTableList";
import { User } from "../../../../storage";
import { getAllOutlookMailFolders, getAllOutlookMessages, getOutlookAuthLink, getOutlookAuthToken, markAsReadOutlookMessage, markAsUnreadOutlookMessage, moveOutlookMessage, resetMicrosoftState } from "../../../../duck/email/microsoft/microsoft.action";
import { MicrosoftTableList } from "../../../component/frontend/email/microsoft/MicrosoftTableList";
import { resetAllState, updateAccordionLabel, updateGoogleLabel, updateMicrosoftLabel } from "../../../../duck/email/email.action";

export const ListEmailsPage = (props) => {
  let authCode, errorCode, urlQuery, msal_access_token, msal_refresh_token, msal_expires_in, msal_social_user_id, msal_social_user_name, msal_social_email, login_state;
  if (props.location && _.has(props.location, "search")) {
    urlQuery = new URLSearchParams(props.location.search);
    authCode = urlQuery.get("code");
    errorCode = urlQuery.get("error");
    msal_access_token = urlQuery.get("accessToken");
    msal_refresh_token = urlQuery.get("refreshToken");
    msal_expires_in = urlQuery.get("expires_in");
    msal_social_user_name = urlQuery.get("social_user_name");
    msal_social_user_id = urlQuery.get("social_user_id");
    msal_social_email = urlQuery.get("social_email");
    login_state = urlQuery.get("login_state");
  }
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const userSocialData = getUserSocialDetails();
  const {
    loading,
    getAllGmailMessagesData,
    getGmailMessageData,
    getGmailAuthTokenData,
    getAllDraftGmailMessagesData,
    getDraftGmailMessageData,
    errorTokenData,
    getGmailTokenData,
  } = useSelector((state) => state.google);
  const { loading: msalLoading, getAllOutlookMailFoldersData, getAllOutlookMessagesData, microsoftErrorData, getOutlookAuthTokenData } = useSelector((state) => state.microsoft);
  const {
    googleLabel,
    microsoftLabel,
    accordionLabel
  } = useSelector((state) => state.email);
  const currentPlan = userData && userData.planData;
  const getGmailAuthLinkData = useSelector((state) => state.google.getGmailAuthLinkData);
  const prevGetGmailAuthLinkData = usePrevious({ getGmailAuthLinkData });
  const getOutlookAuthLinkData = useSelector((state) => state.microsoft.getOutlookAuthLinkData);
  const prevGetOutlookAuthLinkData = usePrevious({ getOutlookAuthLinkData });
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [allCheckedValue, setAllCheckedValue] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
  const [search, setSearch] = useState("");

  // Create Function
  // const createFunction = (e, path) => {
  //   e.preventDefault();
  //   // Add Free trial expire then working is blocked
  //   //if(currentPlan && currentPlan.plan_is_active === 0){
  //   // Free trial expire then working is fine
  //   if (
  //     currentPlan &&
  //     currentPlan.plan_is_active === 0 &&
  //     (path === "/user/add-quote" || path === "/user/add-invoice/")
  //   ) {
  //     let buttonMsg =
  //       currentPlan.subscription_product_id === 1 ? "View Plans" : "Renew Plan";
  //     let warMsg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Free Trial Expired"
  //         : "Subscription Expired";
  //     let msg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Your free trial has expired. Please subscribe to a plan to access the application. "
  //         : "Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ";
  //     Swal.fire({
  //       title: warMsg,
  //       html: msg,
  //       showCancelButton: true,
  //       confirmButtonText: buttonMsg,
  //       cancelButtonText: "Close",
  //       reverseButtons: true,
  //       showCloseButton: true,
  //       customClass: "mycustom-alert",
  //       cancelButtonClass: "cancel-alert-note",
  //     }).then((result) => {
  //       if (result.value) {
  //         setSubscriptionModalShow(true);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       }
  //     });
  //   } else {
  //     props.history.push(path);
  //   }
  // };


  useEffect(() => {
    if (!userSocialData) {
      props.history.push(EMAIL_AUTH);
    }
  }, [userSocialData])

  useEffect(() => {
    if (authCode) {
      dispatch(getGmailAuthToken(authCode));
    } else if (errorCode) {
      dispatch(emptyEmailState());
    }
  }, [dispatch, authCode, errorCode]);

  useEffect(() => {
    if (msal_access_token && msal_refresh_token && msal_expires_in && msal_social_user_id && msal_social_user_name && msal_social_email && login_state) {
      let setMsalSocialDetails = {
        ...userSocialData,
        microsoft: {
          access_token: msal_access_token || null,
          refresh_token: msal_refresh_token || null,
          expire_in: msal_expires_in || null,
          social_email: msal_social_email || null,
          social_user_id: msal_social_user_id || null,
          social_user_name: msal_social_user_name || null,
        }
      }
      User.setSocialUserDetails(setMsalSocialDetails);
      const updateMicrosoftData = {
        loginType: 2, authToken: setMsalSocialDetails?.microsoft?.access_token,
        refreshToken: setMsalSocialDetails?.microsoft?.refresh_token,
        expiresIn: setMsalSocialDetails?.microsoft?.expire_in,
        social_email: setMsalSocialDetails?.microsoft?.social_email,
        social_user_id: setMsalSocialDetails?.microsoft?.social_user_id,
        social_user_name: setMsalSocialDetails?.microsoft?.social_user_name,
      }
      dispatch(saveSocialAuthToken(updateMicrosoftData));
      dispatch(updateAccordionLabel(login_state))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      userSocialData &&
      _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
      &&
      userSocialData.google.expire_in &&
      userSocialData.google.refresh_token &&
      isTokenExpired(userSocialData.google.expire_in)
    ) {
      const refreshtoken = userSocialData.google.refresh_token;
      dispatch(getGmailValidToken(refreshtoken));
    }
    if (userSocialData &&
      _.has(userSocialData, "microsoft") && Object.keys(userSocialData.microsoft).length > 0
      &&
      userSocialData.microsoft.expire_in &&
      userSocialData.microsoft.refresh_token &&
      isTokenExpired(userSocialData.microsoft.expire_in)
    ) {
      const refreshtoken = userSocialData.microsoft.refresh_token;
      dispatch(getOutlookAuthToken(refreshtoken));
      // User.setSocialUserDetails({...userSocialData, microsoft: {...userSocialData?.microsoft, social_email: null }});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAllGmailMessagesApi = async () => {
    const labelId = (googleLabel && !googleLabel || (Object.values(googleLabel) && Object.values(googleLabel).length <= 0)) ? "INBOX" : googleLabel;
    await dispatch(resetEmailState());
    await dispatch(updateGoogleLabel(labelId));
    if (labelId === "DRAFT") {
      await dispatch(
        getAllDraftGmailMessages({ nextPageToken: "", search: "" })
      );
    } else {
      await dispatch(
        getAllGmailMessages({ labelId, nextPageToken: "", search: "" })
      );
    }
  };

  useEffect(() => {
    if (
      userSocialData &&
      _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
      && errorTokenData && Object.keys(errorTokenData).length > 0 && accordionLabel === "gmail"
    ) {
      if (errorTokenData.code === 401) {
        const refreshToken = userSocialData?.google?.refresh_token
        dispatch(getGmailValidToken(refreshToken));
      } else if (errorTokenData.code === 403) {
        dispatch(emptyEmailState());
        props.history.push(EMAIL_AUTH);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorTokenData, userSocialData, props, accordionLabel])

  useEffect(() => {
    if (
      userSocialData &&
      _.has(userSocialData, "microsoft") && Object.keys(userSocialData.microsoft).length > 0
      && microsoftErrorData && Object.keys(microsoftErrorData).length > 0 && accordionLabel === "microsoft"
    ) {
      if (microsoftErrorData.code === "InvalidAuthenticationToken") {
        const refreshToken = userSocialData?.microsoft?.refresh_token
        dispatch(getOutlookAuthToken(refreshToken));
        User.setSocialUserDetails({ ...userSocialData, microsoft: { ...userSocialData?.microsoft, social_email: null } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, microsoftErrorData, accordionLabel])

  useEffect(() => {
    if (
      (errorTokenData && Object.keys(errorTokenData).length === 0 && ((getGmailAuthTokenData && getGmailAuthTokenData.access_token) ||
        (userSocialData &&
          _.has(userSocialData, "google") &&
          Object.keys(userSocialData.google).length > 0 &&
          userSocialData.google.access_token)) && accordionLabel === "gmail")
    )
      handleAllGmailMessagesApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, googleLabel, getGmailAuthTokenData, errorTokenData, accordionLabel]);

  useEffect(() => {
    if ((accordionLabel === "gmail" && ((getGmailTokenData && Object.keys(getGmailTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "google") &&
      Object.keys(userSocialData.google).length > 0 &&
      userSocialData.google.access_token)))) {
      const updateGmailAuthTokenData = {
        loginType: 1, authToken: getGmailTokenData?.access_token, refreshToken: getGmailTokenData?.refresh_token, expiresIn: getGmailTokenData?.expire_in,
        social_email: getGmailTokenData?.social_email,
        social_user_id: getGmailTokenData?.social_user_id,
        social_user_name: getGmailTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 1, authToken: userSocialData?.google?.access_token, refreshToken: userSocialData?.google?.refresh_token, expiresIn: userSocialData?.google?.expire_in,
        social_email: userSocialData?.google?.social_email,
        social_user_id: userSocialData?.google?.social_user_id,
        social_user_name: userSocialData?.google?.social_user_name,
      }
      const updatedData = getGmailTokenData && Object.keys(getGmailTokenData).length > 0 ? updateGmailAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getGmailTokenData, accordionLabel])

  useEffect(() => {
    if ((getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "microsoft") &&
      Object.keys(userSocialData.microsoft).length > 0 &&
      userSocialData.microsoft.access_token)) {
      const updateOutlookAuthTokenData = {
        loginType: 2, authToken: getOutlookAuthTokenData?.access_token, refreshToken: getOutlookAuthTokenData?.refresh_token, expiresIn: getOutlookAuthTokenData?.expire_in,
        social_email: getOutlookAuthTokenData?.social_email,
        social_user_id: getOutlookAuthTokenData?.social_user_id,
        social_user_name: getOutlookAuthTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 2, authToken: userSocialData?.microsoft?.access_token, refreshToken: userSocialData?.microsoft?.refresh_token, expiresIn: userSocialData?.microsoft?.expire_in,
        social_email: userSocialData?.microsoft?.social_email,
        social_user_id: userSocialData?.microsoft?.social_user_id,
        social_user_name: userSocialData?.microsoft?.social_user_name,
      }
      const updatedData = getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0 ? updateOutlookAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getOutlookAuthTokenData])

  useEffect(() => {
    if (getAllGmailMessagesData?.messages?.length && accordionLabel === "gmail") {
      var promises = getAllGmailMessagesData?.messages?.map(async function (
        obj
      ) {
        const emailData = await getGmailMessageById(obj.id);
        return emailData.data;
      });
      Promise.all(promises).then(function (results) {
        dispatch(setGmailMessage({ success: true, data: results }));
      }).catch((err) => {
        dispatch(setGmailMessage({ success: false, data: [] }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getAllGmailMessagesData]);

  useEffect(() => {
    if (getAllDraftGmailMessagesData?.drafts?.length && accordionLabel === "gmail") {
      var promises = getAllDraftGmailMessagesData?.drafts?.map(async function (
        obj
      ) {
        const emailData = await getGmailDraftMessageByIdApi(obj.id);
        return emailData.data;
      });
      Promise.all(promises).then(function (results) {
        dispatch(getDraftGmailMessageById({ success: true, data: results }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getAllDraftGmailMessagesData]);

  const checkedAllCheckbox = (data) => {
    if (data) {
      let allCheck =
        googleLabel === "DRAFT"
          ? _.map(getDraftGmailMessageData, "draftId")
          : _.map(getGmailMessageData, "id");
      setAllCheckedValue(allCheck);
    } else {
      setAllCheckedValue([]);
    }
    setCheckedAll(data);
  };
  // On change select checkbox value
  const onCheckedValue = (id) => {
    let newArr,
      arrLength =
        googleLabel === "DRAFT"
          ? getDraftGmailMessageData.length
          : getGmailMessageData.length;
    if (_.includes(allCheckedValue, id)) {
      newArr = _.filter(allCheckedValue, (data) => data !== id);
    } else {
      newArr = [...allCheckedValue, id];
    }
    if (newArr.length === arrLength) {
      setCheckedAll(true);
      setAllCheckedValue(newArr);
    } else {
      setCheckedAll(false);
      setAllCheckedValue(newArr);
    }
  };
  const handleDeleteMessage = async () => {
    if (allCheckedValue.length > 0) {
      _.map(allCheckedValue, async (id) => {
        if (googleLabel === "DRAFT") {
          await dispatch(deleteDraftGmailMessage(id));
        } else {
          await dispatch(
            markGmailMessageAsTrash({
              messageId: id,
              addLabelIds: ["TRASH"],
              removeLabelIds: [],
            })
          );
        }
      });
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  };
  const handleForeverDeleteMessage = async () => {
    if (allCheckedValue.length > 0) {
      _.map(
        allCheckedValue,
        async (id) => await dispatch(deleteGmailMessage(id))
      );
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  };

  const handleGoogleLabels = (label) => {
    if (googleLabel !== label) {
      dispatch(updateGoogleLabel(label));
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  };

  const handleAccordionClick = (key) => {
    if (props.match.path !== EMAIL_LIST) {
      props.history.push(EMAIL_LIST);
    }
    dispatch(resetAllState());
    dispatch(updateAccordionLabel(key));
    setSearch("");
    setCheckedAll(false);
    setAllCheckedValue([]);
  }

  const handleGmailScroll = (e) => {
    const labelId = googleLabel || "INBOX";
    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.clientHeight + 1;
    if (bottom && getAllGmailMessagesData?.nextPageToken) {
      dispatch(
        getAllGmailMessages({
          labelId,
          nextPageToken: getAllGmailMessagesData?.nextPageToken,
          search: search ? search : "",
        })
      );
    }
  };

  const handleSearchOnChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length === 0) {
      if (accordionLabel === "gmail") {
        handleAllGmailMessagesApi();
      } else if (accordionLabel === "microsoft") {
        handleAllOutlookMessagesApi();
      }
    }
  };

  const handleSearchOnEnter = async () => {
    if (search.length > 0) {
      if (accordionLabel === "gmail") {
        const labelId = googleLabel || "INBOX";
        await dispatch(resetEmailState());
        await dispatch(
          getAllGmailMessages({ labelId, nextPageToken: "", search })
        );
      } else if (accordionLabel === "microsoft") {
        await dispatch(
          getAllOutlookMessages({ mailFolderId: microsoftLabel.id, nextLink: "", search })
        );
      }
    }
  };

  const handleModifyMessage = (type) => {
    switch (type) {
      case "UNREAD":
        if (allCheckedValue.length > 0) {
          _.map(
            allCheckedValue,
            async (id) =>
              await dispatch(
                markGmailMessageAsUnread({
                  messageId: id,
                  addLabelIds: [],
                  removeLabelIds: ["UNREAD"],
                })
              )
          );
          setCheckedAll(false);
          setAllCheckedValue([]);
        }
        break;
      case "SPAM":
        if (allCheckedValue.length > 0) {
          _.map(
            allCheckedValue,
            async (id) =>
              await dispatch(
                markGmailMessageAsSpam({
                  messageId: id,
                  addLabelIds: ["SPAM"],
                  removeLabelIds: [],
                })
              )
          );
          setCheckedAll(false);
          setAllCheckedValue([]);
        }
        break;
      case "IMPORTANT":
        if (allCheckedValue.length > 0) {
          _.map(
            allCheckedValue,
            async (id) =>
              await dispatch(
                modifyGmailMessage({
                  messageId: id,
                  addLabelIds: ["IMPORTANT"],
                  removeLabelIds: [],
                })
              )
          );
          setCheckedAll(false);
          setAllCheckedValue([]);
        }
        break;
      case "STARRED":
        if (allCheckedValue.length > 0) {
          _.map(
            allCheckedValue,
            async (id) =>
              await dispatch(
                modifyGmailMessage({
                  messageId: id,
                  addLabelIds: ["STARRED"],
                  removeLabelIds: [],
                })
              )
          );
          setCheckedAll(false);
          setAllCheckedValue([]);
        }
        break;
      case "INBOX":
        if (allCheckedValue.length > 0) {
          _.map(
            allCheckedValue,
            async (id) =>
              await dispatch(
                markGmailMessageAsInbox({
                  messageId: id,
                  addLabelIds: ["INBOX"],
                  removeLabelIds: [],
                })
              )
          );
          setCheckedAll(false);
          setAllCheckedValue([]);
        }
        break;
      default:
        break;
    }
  };

  const handleUnTrashMessage = () => {
    if (allCheckedValue.length > 0) {
      _.map(
        allCheckedValue,
        async (id) =>
          await dispatch(
            markGmailMessageAsTrash({
              messageId: id,
              addLabelIds: [],
              removeLabelIds: ["TRASH"],
            })
          )
      );
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  };

  const handleRowOnClick = (id) => {
  };

  const handleMarkStarred = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: ["STARRED"],
        removeLabelIds: [],
      })
    );
  };

  const handleMarkImportant = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: ["IMPORTANT"],
        removeLabelIds: [],
      })
    );
  };

  const handleUnmarkStarred = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: [],
        removeLabelIds: ["STARRED"],
      })
    );
  };

  const handleUnmarkImportant = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: [],
        removeLabelIds: ["IMPORTANT"],
      })
    );
  };

  useEffect(() => {
    if (prevGetGmailAuthLinkData && prevGetGmailAuthLinkData.getGmailAuthLinkData !== getGmailAuthLinkData) {
      if (getGmailAuthLinkData && _.has(getGmailAuthLinkData, "url") && getGmailAuthLinkData.url !== "") {
        window.location.replace(getGmailAuthLinkData.url);
      }
    }
  }, [prevGetGmailAuthLinkData, getGmailAuthLinkData])

  const handleSiginWithGoogle = () => {
    dispatch(getGmailAuthLink());
  }

  // Microsoft Outlook Api's

  const checkedAllMicrosoftCheckbox = (data) => {
    if (data) {
      let allCheck = _.map(getAllOutlookMessagesData?.value, "id");
      setAllCheckedValue(allCheck);
    } else {
      setAllCheckedValue([]);
    }
    setCheckedAll(data);
  };

  // On change select checkbox value
  const onMicrosoftCheckedValue = (id) => {
    let newArr,
      arrLength = getAllOutlookMessagesData?.value?.length;
    if (_.includes(allCheckedValue, id)) {
      newArr = _.filter(allCheckedValue, (data) => data !== id);
    } else {
      newArr = [...allCheckedValue, id];
    }
    if (newArr.length === arrLength) {
      setCheckedAll(true);
      setAllCheckedValue(newArr);
    } else {
      setCheckedAll(false);
      setAllCheckedValue(newArr);
    }
  };

  const handleMicrosoftLabels = (mailLabelObj) => {
    dispatch(updateMicrosoftLabel(mailLabelObj));
  }

  const handleAllOutlookMessagesApi = () => {
    dispatch(getAllOutlookMailFolders())
  }

  useEffect(() => {
    if (getAllOutlookMailFoldersData && getAllOutlookMailFoldersData?.value && getAllOutlookMailFoldersData?.value?.length && accordionLabel === "microsoft") {
      if (microsoftLabel && Object.keys(microsoftLabel).length) {
        dispatch(resetMicrosoftState());
        dispatch(getAllOutlookMessages({ mailFolderId: microsoftLabel.id, nextLink: "", search: "" }))
      } else {
        getAllOutlookMailFoldersData.value.map((obj) => {
          if (obj.displayName === "Inbox") {
            dispatch(resetMicrosoftState());
            dispatch(updateMicrosoftLabel(obj));
            dispatch(getAllOutlookMessages({ mailFolderId: obj.id, nextLink: "", search: "" }))
          }
        })
      }
    }
  }, [dispatch, getAllOutlookMailFoldersData, microsoftLabel, accordionLabel])

  useEffect(() => {
    if ((userSocialData &&
      _.has(userSocialData, "microsoft") &&
      Object.keys(userSocialData.microsoft).length > 0 &&
      userSocialData.microsoft.access_token) && accordionLabel === "microsoft") {
      handleAllOutlookMessagesApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, accordionLabel]);

  const handleOutlookScroll = (e) => {
    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.clientHeight + 1;
    if (bottom && getAllOutlookMessagesData?.["@odata.nextLink"]) {
      dispatch(getAllOutlookMessages({ mailFolderId: "", nextLink: getAllOutlookMessagesData?.["@odata.nextLink"], search: search ? search : "" }));
    }
  };

  const handleMoveMessageToDeletedItems = () => {
    if (allCheckedValue.length > 0) {
      _.map(
        allCheckedValue,
        async (id) =>
          await dispatch(moveOutlookMessage({ mailFolderId: microsoftLabel.id, messageId: id, body: { destinationId: "deleteditems" } }))
      );
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  }

  const handleDeleteMessageWithMicrosoft = () => {
    if (allCheckedValue.length > 0) {
      _.map(
        allCheckedValue,
        async (id) =>
          await dispatch(moveOutlookMessage({ mailFolderId: microsoftLabel.id, messageId: id, body: { destinationId: "inbox" } }))
      );
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  }

  const handleMoveMessageToJunkFolder = () => {
    if (allCheckedValue.length > 0) {
      _.map(
        allCheckedValue,
        async (id) =>
          await dispatch(moveOutlookMessage({ mailFolderId: microsoftLabel.id, messageId: id, body: { destinationId: "junkemail" } }))
      );
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  }

  const handleMarkMessageAsRead = () => {
    if (allCheckedValue.length > 0) {
      _.map(
        allCheckedValue,
        async (id) =>
          await dispatch(markAsReadOutlookMessage({ messageId: id, body: { isRead: true } }))
      );
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  }

  const handleMarkMessageAsUnread = () => {
    if (allCheckedValue.length > 0) {
      _.map(
        allCheckedValue,
        async (id) =>
          await dispatch(markAsUnreadOutlookMessage({ messageId: id, body: { isRead: false } }))
      );
      setCheckedAll(false);
      setAllCheckedValue([]);
    }
  }

  useEffect(() => {
    if (prevGetOutlookAuthLinkData && prevGetOutlookAuthLinkData.getOutlookAuthLinkData !== getOutlookAuthLinkData) {
      if (getOutlookAuthLinkData && _.has(getOutlookAuthLinkData, "url") && getOutlookAuthLinkData.url !== "") {
        window.location.replace(getOutlookAuthLinkData.url);
      }
    }
  }, [prevGetOutlookAuthLinkData, getOutlookAuthLinkData])

  const handleSiginWithMicrosoft = () => {
    dispatch(getOutlookAuthLink());
  }

  const handleSocialSignout = () => {
    dispatch(emptyEmailState());
    dispatch(gmailAccountDelete());
    props.history.push(EMAIL_AUTH);
    console.log("CLICKED REMOVE ACCOUNT>>>")
  }

  return (
    <>
      <Loader loader={loading || msalLoading} />
      <div className="main-site fixed--header fixed-email-header">
        <Header getMainRoute={"email"} />
        <main className="site-body bg-white">
          {/* <EmailHeader createFunction={createFunction} /> */}
          <EmailHeader removeAccount={true} noBack={true} handleSocialSignout={handleSocialSignout} />
          <section className="middle-section email-template-design">
            <div className="container-fluid">
              <div className="row no-gutters-mbl">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="email-template">
                        <div className="email-head d-flex">
                          <div className="top-btn-left">
                            <button
                              className="email-btn"
                              onClick={() => setRefresh(!refresh)}
                            >
                              <img
                                src={setImagePath(REFRESH_ICON)}
                                alt="REFRESH_ICON"
                              />{" "}
                              <span className="btn-text">Refresh</span>
                            </button>
                            <Link to={COMPOSE_EMAIL} className="email-btn">
                              <img
                                src={setImagePath(COMPOSE_ICON)}
                                alt="COMPOSE_ICON"
                              />
                              <span className="btn-text">Compose</span>
                            </Link>
                            {accordionLabel === "microsoft" ?
                              <button className={`${allCheckedValue.length
                                ? "email-btn"
                                : "email-btn btn-disabled"
                                }`}
                                onClick={microsoftLabel.displayName === "Deleted Items" ? handleDeleteMessageWithMicrosoft : handleMoveMessageToDeletedItems}>
                                {microsoftLabel.displayName === "Deleted Items" ? "Move To Inbox" : "Delete"}
                              </button>
                              : !(googleLabel === "TRASH") ? <button
                                className={`${allCheckedValue.length
                                  ? "email-btn"
                                  : "email-btn btn-disabled"
                                  }`}
                                onClick={
                                  googleLabel === "TRASH"
                                    ? handleForeverDeleteMessage
                                    : handleDeleteMessage
                                }
                              >
                                <img
                                  src={setImagePath(DELETE_ICON)}
                                  alt="DELETE_ICON"
                                />{" "}
                                <span className="btn-text">
                                  {googleLabel === "TRASH"
                                    ? "Delete Forever"
                                    : "Delete"}
                                </span>
                              </button> : <></>}
                            {accordionLabel === "gmail" && googleLabel === "TRASH" ? (
                              <button
                                className={`${allCheckedValue.length
                                  ? "email-btn"
                                  : "email-btn btn-disabled"
                                  }`}
                                onClick={handleUnTrashMessage}
                              >
                                <img
                                  src={setImagePath(DELETE_ICON)}
                                  alt="DELETE_ICON"
                                />{" "}
                                <span className="btn-text">
                                  {"Discard From Trash"}
                                </span>
                              </button>
                            ) : <></>}
                            {((accordionLabel === "microsoft" && microsoftLabel.displayName !== "Drafts") || (accordionLabel === "gmail" && googleLabel !== "DRAFT")) ?
                              <div className="dropdown custom-dropdown ml-2">
                                <button
                                  className={`${allCheckedValue.length
                                    ? "email-btn w-100 h-100"
                                    : "email-btn w-100 h-100 btn-disabled "
                                    }`}
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img
                                    src={setImagePath(MORE_ICON)}
                                    alt="MORE_ICON"
                                  />{" "}
                                  <span className="btn-text">More</span>
                                </button>
                                {accordionLabel === "microsoft" ?
                                  <div className="dropdown-menu dropdown-menu-sm-right">
                                    <button
                                      className="dropdown-item"
                                      onClick={handleMarkMessageAsRead}
                                    >
                                      <img
                                        src={setImagePath(MAIL_LABEL_ICON)}
                                        alt="MARK_ICON"
                                      />{" "}
                                      <span className="btn-text">
                                        Mark as Read
                                      </span>
                                    </button>
                                    <button
                                      className="dropdown-item"
                                      onClick={handleMarkMessageAsUnread}
                                    >
                                      <img
                                        src={setImagePath(UNREAD_ICON)}
                                        alt="UNREAD_ICON"
                                      />{" "}
                                      <span className="btn-text">
                                        Mark as Unread
                                      </span>
                                    </button>
                                    {microsoftLabel.displayName === "Junk Email" ? (
                                      <button
                                        className="dropdown-item"
                                        onClick={handleDeleteMessageWithMicrosoft}
                                      >
                                        <img
                                          src={setImagePath(INBOX_ICON)}
                                          alt="INBOX_ICON"
                                        />
                                        <span className="btn-text">
                                          Discard From Spam
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                        className="dropdown-item"
                                        onClick={handleMoveMessageToJunkFolder}
                                      >
                                        <img
                                          src={setImagePath(JUNK_ICON)}
                                          alt="JUNK_ICON"
                                        />
                                        <span className="btn-text">
                                          Mark as Junk
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  :
                                  <div className="dropdown-menu dropdown-menu-sm-right">
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleModifyMessage("UNREAD")}
                                    >
                                      <img
                                        src={setImagePath(MAIL_LABEL_ICON)}
                                        alt="MARK_ICON"
                                      />{" "}
                                      <span className="btn-text">
                                        Mark as Read
                                      </span>
                                    </button>
                                    {googleLabel === "SPAM" ? (
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleModifyMessage("INBOX")}
                                      >
                                        <img
                                          src={setImagePath(INBOX_ICON)}
                                          alt="INBOX_ICON"
                                        />
                                        <span className="btn-text">
                                          Discard From Spam
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleModifyMessage("SPAM")}
                                      >
                                        <img
                                          src={setImagePath(JUNK_ICON)}
                                          alt="JUNK_ICON"
                                        />
                                        <span className="btn-text">
                                          Mark as Junk
                                        </span>
                                      </button>
                                    )}
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleModifyMessage("IMPORTANT")
                                      }
                                    >
                                      <img
                                        src={setImagePath(IMPORTANT_LABEL_ICON)}
                                        alt="MARK_ICON"
                                      />{" "}
                                      <span className="btn-text">
                                        Mark as Important
                                      </span>
                                    </button>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleModifyMessage("STARRED")}
                                    >
                                      <img
                                        src={setImagePath(STAR_LABEL_ICON)}
                                        alt="MARK_ICON"
                                      />{" "}
                                      <span className="btn-text">
                                        Mark as Starred
                                      </span>
                                    </button>
                                  </div>}
                              </div> : <></>}
                          </div>
                          <div className="top-right ml-auto">
                            <select className="d-none">
                              <option defaultValue>All</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                            <div className="search-box">
                              <img
                                className="search_icn"
                                src={setImagePath(SEARCH_ICON)}
                                alt="SEARCH_ICON"
                              />
                              <input
                                type="search"
                                name="search"
                                placeholder="Search"
                                value={search}
                                onChange={handleSearchOnChange}
                                onKeyUp={(event) =>
                                  event.key === "Enter"
                                    ? handleSearchOnEnter(event)
                                    : null
                                }
                              />
                              {search !== "" && (
                                <span
                                  className="search_cross_icon"
                                  onClick={() => {
                                    setSearch("");
                                    accordionLabel === "gmail" ?
                                      handleAllGmailMessagesApi()
                                      : handleAllOutlookMessagesApi()
                                  }}
                                >
                                  <img
                                    src={setImagePath(CROSS_ICON)}
                                    alt="CROSS_ICON"
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="email-content">
                          <EmailSidebar
                            googleErrorData={errorTokenData}
                            microsoftErrorData={microsoftErrorData}
                            handleSiginWithGoogle={handleSiginWithGoogle}
                            handleSiginWithMicrosoft={handleSiginWithMicrosoft}
                            accordionState={accordionLabel}
                            labelId={googleLabel || "INBOX"}
                            userSocialData={userSocialData}
                            handleGoogleLabels={handleGoogleLabels}
                            msalLabel={microsoftLabel}
                            labelsList={getAllOutlookMailFoldersData && getAllOutlookMailFoldersData?.value}
                            handleMicrosoftLabels={handleMicrosoftLabels}
                            handleAccordionClick={handleAccordionClick}
                          />
                          <div className="col-sm-10">
                            <div className="bg-white">
                              <div
                                className="table-responsive"
                                onScroll={accordionLabel === "gmail" ? handleGmailScroll : handleOutlookScroll}
                              >
                                {accordionLabel === "gmail" ?
                                  <GoogleTableList
                                    onCheckedValue={onCheckedValue}
                                    loading={loading}
                                    search={search}
                                    getGmailMessageData={
                                      getDraftGmailMessageData &&
                                        getDraftGmailMessageData.length
                                        ? getDraftGmailMessageData
                                        : getGmailMessageData
                                    }
                                    handleRowOnClick={handleRowOnClick}
                                    handleUnmarkImportant={handleUnmarkImportant}
                                    handleUnmarkStarred={handleUnmarkStarred}
                                    handleMarkImportant={handleMarkImportant}
                                    handleMarkStarred={handleMarkStarred}
                                    getActiveLabel={googleLabel}
                                    allCheckedValue={allCheckedValue}
                                    checkedAll={checkedAll}
                                    checkedAllCheckbox={checkedAllCheckbox}
                                  />
                                  :
                                  <MicrosoftTableList
                                    loading={msalLoading}
                                    search={search}
                                    msalLabel={microsoftLabel}
                                    emailListData={getAllOutlookMessagesData}
                                    onCheckedValue={onMicrosoftCheckedValue}
                                    allCheckedValue={allCheckedValue}
                                    checkedAll={checkedAll}
                                    checkedAllCheckbox={checkedAllMicrosoftCheckbox}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        {/* Subscription Modal*/}
        <SubscriptionPlan
          loader={(data) => setLoader(data)}
          openSubscriptionModal={subscriptionModalShow}
          closeSubscriptionModal={() => setSubscriptionModalShow(false)}
          updatePlanDetail={(data) => {
            setSubscriptionModalShow(false);
            setLoader(false);
          }}
          currentPlan={currentPlan}
        />
      </div>
    </>
  );
};

export const ListEmail = withRouter(ListEmailsPage);
