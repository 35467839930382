import React from "react";
import moment from "moment";
import { setImagePath } from "../../../../../common/custom";
import MORE_ICON from "../../../../../assets/images/more_horiz_FILL0_wght400_GRAD0_opsz24.svg";
import SEARCH_ICON from "../../../../../assets/images/search_FILL0_wght400_GRAD0_opsz48.svg";
import ATTACHMENT_ICON from "../../../../../assets/images/attach_file_FILL0_wght400_GRAD0_opsz24.svg";
import STAR_ICON from "../../../../../assets/images/start.svg";
import UNREAD_STAR_ICON from "../../../../../assets/images/starred.svg";
import STAR_FILLED_ICON from "../../../../../assets/images/starred_filled.svg";
import UNREAD_IMPORTANT_ICON from "../../../../../assets/images/label_important.svg";
import IMPORTANT_ICON from "../../../../../assets/images/important.svg";
import IMPORTANT_FILLED_ICON from "../../../../../assets/images/label-filled.svg";
import JUNK_ICON from "../../../../../assets/images/report_FILL0_wght400_GRAD0_opsz24.svg";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  EMAIL_DRAFT_VIEW_BASE,
  EMAIL_VIEW_BASE,
} from "../../../../../routing/routeContants";
import { ConvertTimeFromUTCToIST } from "../../../../../common/constants";

export function GoogleTableList(props) {
  const {
    onCheckedValue,
    loading,
    search,
    getGmailMessageData,
    handleRowOnClick,
    handleUnmarkImportant,
    handleUnmarkStarred,
    handleMarkImportant,
    handleMarkStarred,
    getActiveLabel,
    allCheckedValue,
    checkedAll,
    checkedAllCheckbox,
  } = props;
  return (
    <table className="table table-bordered wktable">
      <thead>
        <tr>
          <th className="p-0 wk-custom-label" width="10">
            <div className="custom-control custom-checkbox d-block">
              <input
                type="checkbox"
                name="check_all"
                onChange={(e) => checkedAllCheckbox(e.target.checked)}
                checked={checkedAll}
                className="custom-control-input"
                id={"checkAll"}
              />
              <label
                className="custom-control-label custom-cursor"
                htmlFor={"checkAll"}
              ></label>
            </div>
          </th>
          <th className="p-0 w30"><div className="list-icons-div"></div></th>
          <th className="p-0 w30"><div className="list-icons-div"></div></th>
          <th>Subject</th>
          <th>{["SENT", "DRAFT"].includes(getActiveLabel) ? "To" : "From"}</th>
          <th width="142px">Date</th>
          <th>Size</th>
          <th width="30">
            <img
              className="h-18"
              src={setImagePath(ATTACHMENT_ICON)}
              alt="ATTACHMENT_ICON"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {getGmailMessageData && getGmailMessageData.length ? (
          _.map(getGmailMessageData, (message, index) => {
            const hasStarred =
              message &&
              message.labelIds &&
              message.labelIds.length &&
              message.labelIds.includes("STARRED");
            const isImportant =
              message &&
              message.labelIds &&
              message.labelIds.length &&
              message.labelIds.includes("IMPORTANT");
            const hasAttachmentId =
              message &&
              message.payload &&
              message.payload?.parts?.length &&
              message.payload?.parts.map((data) =>
                _.has(data.body, "attachmentId")
              );
            const isUnread = message && message.labelIds &&
              message.labelIds.length && message.labelIds.includes("UNREAD");
            const From =
              message &&
              message.payload &&
              message.payload?.headers?.length &&
              message.payload?.headers.find((obj) => obj.name === "From");
            const To =
              message &&
              message.payload &&
              message.payload?.headers?.length &&
              message.payload?.headers.find((obj) => obj.name === "To");
            const EmailDate =
              message &&
              message.payload &&
              message.payload.headers.length &&
              message.payload.headers.find((obj) => obj.name === "Date");
            const Subject =
              message &&
              message.payload &&
              message.payload?.headers?.length &&
              message.payload?.headers.find((obj) => obj.name === "Subject");
            return (
              <tr
                key={index}
                className="custom-email-tr"
              // onClick={() => {
              //   getActiveLabel === "DRAFT"
              //     ? handleRowOnClick(
              //         message?.draftId
              //       )
              //     : handleRowOnClick(
              //         message.threadId
              //       );
              // }}
              >
                <td className="p-0 wk-custom-label">
                  <div className="custom-control custom-checkbox d-block">
                    <input
                      type="checkbox"
                      name={
                        getActiveLabel === "DRAFT"
                          ? message.draftId
                          : message.id
                      }
                      onChange={(e) => {
                        getActiveLabel === "DRAFT"
                          ? onCheckedValue(message.draftId, e.target.checked)
                          : onCheckedValue(message.id, e.target.checked);
                      }}
                      checked={
                        getActiveLabel === "DRAFT"
                          ? _.includes(allCheckedValue, message.draftId)
                          : _.includes(allCheckedValue, message.id)
                      }
                      className="custom-control-input"
                      id={
                        "check" + getActiveLabel === "DRAFT"
                          ? message.draftId
                          : message.id
                      }
                    />
                    <label
                      className="custom-control-label custom-cursor"
                      htmlFor={
                        "check" + getActiveLabel === "DRAFT"
                          ? message.draftId
                          : message.id
                      }
                    ></label>
                  </div>
                </td>
                <td className="p-0">
                  {hasStarred ? (
                    <button
                      className="action-btn"
                      onClick={() =>
                        handleUnmarkStarred(
                          message.id
                        )
                      }
                    >
                      <img
                        src={setImagePath(STAR_FILLED_ICON)}
                        alt="STAR_ICON"
                      />
                    </button>
                  ) : isUnread ? (
                    <button
                      className="action-btn"
                      onClick={() =>
                        handleMarkStarred(
                          message.id
                        )
                      }
                    >
                      <img
                        src={setImagePath(UNREAD_STAR_ICON)}
                        alt="STAR_ICON"
                      />
                    </button>
                  ) : (
                    <button
                      className="action-btn"
                      onClick={() =>
                        handleMarkStarred(
                          message.id
                        )
                      }
                    >
                      <img src={setImagePath(STAR_ICON)} alt="STAR_ICON" />
                    </button>
                  )}
                </td>
                <td className="p-0">
                  {isImportant ? (
                    <button
                      className="action-btn wkicon"
                      onClick={() =>
                        handleUnmarkImportant(
                          message.id
                        )
                      }
                    >
                      <img
                        src={setImagePath(IMPORTANT_FILLED_ICON)}
                        alt="IMPORTANT_ICON"
                      />
                    </button>
                  ) : isUnread ? (
                    <button
                      className="action-btn wkicon"
                      onClick={() =>
                        handleMarkImportant(
                          message.id
                        )
                      }
                    >
                      <img
                        src={setImagePath(UNREAD_IMPORTANT_ICON)}
                        alt="IMPORTANT_ICON"
                      />
                    </button>
                  ) : (
                    <button
                      className="action-btn wkicon"
                      onClick={() =>
                        handleMarkImportant(
                          message.id
                        )
                      }
                    >
                      <img
                        src={setImagePath(IMPORTANT_ICON)}
                        alt="IMPORTANT_ICON"
                      />
                    </button>
                  )}
                </td>
                <td
                  className={`${isUnread
                    ? "font-weight-bold cursor-pointer"
                    : "cursor-pointer"
                    }`}
                >
                  {getActiveLabel === "DRAFT" ? (
                    <Link
                      to={{ pathname: EMAIL_DRAFT_VIEW_BASE + message?.draftId, state: { uniqueViewKey: "google" } }}
                      className="custom-link-text custom-link-text-new"
                      title={Subject?.value || "-"}
                    >
                      {Subject?.value || "-"}
                    </Link>
                  ) : (
                    <Link
                      to={{ pathname: EMAIL_VIEW_BASE + message.threadId, state: { uniqueViewKey: "google" } }}
                      className="custom-link-text custom-link-text-new"
                      title={Subject?.value || "-"}
                    >
                      {Subject?.value || "-"}
                    </Link>
                  )}
                </td>
                <td className={`${isUnread ? "font-weight-bold" : ""}`}>
                  <div className="ellipss-text" title={(["SENT", "DRAFT"].includes(getActiveLabel)
                    ? To?.value
                    : From?.value) || "-"}> {(["SENT", "DRAFT"].includes(getActiveLabel)
                      ? To?.value
                      : From?.value) || "-"}
                  </div>
                </td>
                <td className={`${isUnread ? "font-weight-bold" : ""}`}>
                  {ConvertTimeFromUTCToIST(EmailDate?.value, "list") || "-"}
                </td>
                <td className={`${isUnread ? "font-weight-bold" : ""}`}>
                  {message.sizeEstimate || "-"}
                </td>
                <td className="text-center">
                  {hasAttachmentId && hasAttachmentId.includes(true) ? (
                    <div className="action-btn p-0">
                      <img
                        src={setImagePath(ATTACHMENT_ICON)}
                        alt="ATTACHMENT_ICON"
                      />
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={8} className="text-center">
              {!loading
                ? search
                  ? "No Searched Results"
                  : "No Emails Found!"
                : "..."}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
