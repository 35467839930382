// List Quote
export const LIST_QUOTE = 'LIST_QUOTE';
export const listQuote = (data) => ({ type: LIST_QUOTE, data });
export const SUCCESS_LIST_QUOTE = 'SUCCESS_LIST_QUOTE';
export const ERROR_LIST_QUOTE = 'ERROR_LIST_QUOTE';
export const listQuoteResponse = (type, data) => ({ type, data });

// Add Quote
export const ADD_QUOTE = 'ADD_QUOTE';
export const addQuote = (data) => ({ type: ADD_QUOTE, data });
export const SUCCESS_ADD_QUOTE = 'SUCCESS_ADD_QUOTE';
export const ERROR_ADD_QUOTE = 'ERROR_ADD_QUOTE';
export const addQuoteResponse = (type, data) => ({ type, data });

// Add Quote Template
export const ADD_QUOTE_TEMPLATE = 'ADD_QUOTE_TEMPLATE';
export const addQuoteTemplate = (data) => ({ type: ADD_QUOTE_TEMPLATE, data });
export const SUCCESS_ADD_QUOTE_TEMPLATE = 'SUCCESS_ADD_QUOTE_TEMPLATE';
export const ERROR_ADD_QUOTE_TEMPLATE = 'ERROR_ADD_QUOTE_TEMPLATE';
export const addQuoteTemplateResponse = (type, data) => ({ type, data });

// List Quote Template
export const GET_QUOTE_TEMPLATE_BY_ID = 'GET_QUOTE_TEMPLATE_BY_ID';
export const getQuoteTemplateById = (data) => ({ type: GET_QUOTE_TEMPLATE_BY_ID, data });
export const SUCCESS_GET_QUOTE_TEMPLATE_BY_ID = 'SUCCESS_GET_QUOTE_TEMPLATE_BY_ID';
export const ERROR_GET_QUOTE_TEMPLATE_BY_ID = 'ERROR_GET_QUOTE_TEMPLATE_BY_ID';
export const getQuoteTemplateByIdResponse = (type, data) => ({ type, data });

// View Quote By ID
export const GET_QUOTE_BY_ID = 'GET_QUOTE_BY_ID';
export const getQuoteById = (data) => ({ type: GET_QUOTE_BY_ID, data });
export const SUCCESS_GET_QUOTE_BY_ID = 'SUCCESS_GET_QUOTE_BY_ID';
export const CLEAR_GET_QUOTE_BY_ID = 'CLEAR_GET_QUOTE_BY_ID';
export const ERROR_GET_QUOTE_BY_ID = 'ERROR_GET_QUOTE_BY_ID';
export const clearQuoteById = () => ({ type: CLEAR_GET_QUOTE_BY_ID });
export const getQuoteByIdResponse = (type, data) => ({ type, data });

// Update Quote Customer
export const UPDATE_QUOTE_CUSTOMER = 'UPDATE_QUOTE_CUSTOMER';
export const updateQuoteCustomer = (data) => ({ type: UPDATE_QUOTE_CUSTOMER, data });
export const SUCCESS_UPDATE_QUOTE_CUSTOMER = 'SUCCESS_UPDATE_QUOTE_CUSTOMER';
export const ERROR_UPDATE_QUOTE_CUSTOMER = 'ERROR_UPDATE_QUOTE_CUSTOMER';
export const updateQuoteCustomerResponse = (type, data) => ({ type, data });

// Update Quote
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const updateQuote = (data) => ({ type: UPDATE_QUOTE, data });
export const SUCCESS_UPDATE_QUOTE = 'SUCCESS_UPDATE_QUOTE';
export const ERROR_UPDATE_QUOTE = 'ERROR_UPDATE_QUOTE';
export const updateQuoteResponse = (type, data) => ({ type, data });

// Update Quote
export const SEND_TO_CUSTOMER_QUOTE = 'SEND_TO_CUSTOMER_QUOTE';
export const sendToCustomerQuote = (data) => ({ type: SEND_TO_CUSTOMER_QUOTE, data });
export const SUCCESS_SEND_TO_CUSTOMER_QUOTE = 'SUCCESS_SEND_TO_CUSTOMER_QUOTE';
export const ERROR_SEND_TO_CUSTOMER_QUOTE = 'ERROR_SEND_TO_CUSTOMER_QUOTE';
export const sendToCustomerQuoteResponse = (type, data) => ({ type, data });

// View Customer View
export const VIEW_CUSTOMER_QUOTE = 'VIEW_CUSTOMER_QUOTE';
export const viewCustomerQuote = (data) => ({ type: VIEW_CUSTOMER_QUOTE, data });
export const SUCCESS_VIEW_CUSTOMER_QUOTE = 'SUCCESS_VIEW_CUSTOMER_QUOTE';
export const ERROR_VIEW_CUSTOMER_QUOTE = 'ERROR_VIEW_CUSTOMER_QUOTE';
export const viewCustomerQuoteResponse = (type, data) => ({ type, data });

// Change Quote Status
export const CHANGE_QUOTE_STATUS = 'CHANGE_QUOTE_STATUS';
export const changeQuoteStatus = (data) => ({ type: CHANGE_QUOTE_STATUS, data });
export const SUCCESS_CHANGE_QUOTE_STATUS = 'SUCCESS_CHANGE_QUOTE_STATUS';
export const ERROR_CHANGE_QUOTE_STATUS = 'ERROR_CHANGE_QUOTE_STATUS';
export const changeQuoteStatusResponse = (type, data) => ({ type, data });

// Revise Quote
export const REVISE_QUOTE = 'REVISE_QUOTE';
export const reviseQuote = (data) => ({ type: REVISE_QUOTE, data });
export const SUCCESS_REVISE_QUOTE = 'SUCCESS_REVISE_QUOTE';
export const ERROR_REVISE_QUOTE = 'ERROR_REVISE_QUOTE';
export const reviseQuoteResponse = (type, data) => ({ type, data });

// Get Accepted Quote
export const GET_ACCEPTED_QUOTE = 'GET_ACCEPTED_QUOTE';
export const getAcceptedQuote = (data) => ({ type: GET_ACCEPTED_QUOTE, data });
export const SUCCESS_GET_ACCEPTED_QUOTE = 'SUCCESS_GET_ACCEPTED_QUOTE';
export const ERROR_GET_ACCEPTED_QUOTE = 'ERROR_GET_ACCEPTED_QUOTE';
export const getAcceptedQuoteResponse = (type, data) => ({ type, data });

//Delete Quote Data 
export const DELETE_QUOTE = 'DELETE_QUOTE';
export const deleteQuote = (data) => ({ type: DELETE_QUOTE, data });
export const SUCCESS_DELETE_QUOTE = 'SUCCESS_DELETE_QUOTE';
export const ERROR_DELETE_QUOTE = 'ERROR_DELETE_QUOTE';
export const deleteQuoteResponse = (type, data) => ({ type, data });

// List Quote Template
export const LIST_QUOTE_TEMPLATE = 'LIST_QUOTE_TEMPLATE';
export const listQuoteTemplate = (data) => ({ type: LIST_QUOTE_TEMPLATE, data });
export const SUCCESS_LIST_QUOTE_TEMPLATE = 'SUCCESS_LIST_QUOTE_TEMPLATE';
export const ERROR_LIST_QUOTE_TEMPLATE = 'ERROR_LIST_QUOTE_TEMPLATE';
export const listQuoteTemplateResponse = (type, data) => ({ type, data });

// Update Basic Quote
export const UPDATE_BASIC_QUOTE = 'UPDATE_BASIC_QUOTE';
export const updateBasicQuote = (data) => ({ type: UPDATE_BASIC_QUOTE, data });
export const SUCCESS_UPDATE_BASIC_QUOTE = 'SUCCESS_UPDATE_BASIC_QUOTE';
export const ERROR_UPDATE_BASIC_QUOTE = 'ERROR_UPDATE_BASIC_QUOTE';
export const updateBasicQuoteResponse = (type, data) => ({ type, data });

// Update Quote User Email Body
export const UPDATE_MESSAGE_BODY_QUOTE = 'UPDATE_MESSAGE_BODY_QUOTE';
export const updateMessageBodyQuote = (data) => ({ type: UPDATE_MESSAGE_BODY_QUOTE, data });
export const SUCCESS_UPDATE_MESSAGE_BODY_QUOTE = 'SUCCESS_UPDATE_MESSAGE_BODY_QUOTE';
export const ERROR_UPDATE_MESSAGE_BODY_QUOTE = 'ERROR_UPDATE_MESSAGE_BODY_QUOTE';
export const updateMessageBodyResponse = (type, data) => ({ type, data });

// get Quote User Email Body
export const GET_MESSAGE_BODY_QUOTE = 'GET_MESSAGE_BODY_QUOTE';
export const getMessageBodyQuote = (data) => ({ type: GET_MESSAGE_BODY_QUOTE, data });
export const SUCCESS_GET_MESSAGE_BODY_QUOTE = 'SUCCESS_GET_MESSAGE_BODY_QUOTE';
export const ERROR_GET_MESSAGE_BODY_QUOTE = 'ERROR_GET_MESSAGE_BODY_QUOTE';
export const getMessageBodyResponse = (type, data) => ({ type, data });

// Attachments (Optional) Browse file data
export const ATTACHMENTS_BROWSE_FILE_QUOTE = 'ATTACHMENTS_BROWSE_FILE_QUOTE';
export const attachmentsBrowseFileQuote = (data) => ({ type: ATTACHMENTS_BROWSE_FILE_QUOTE, data });
export const SUCCESS_ATTACHMENTS_BROWSE_FILE_QUOTE = 'SUCCESS_ATTACHMENTS_BROWSE_FILE_QUOTE';
export const ERROR_ATTACHMENTS_BROWSE_FILE_QUOTE = 'ERROR_ATTACHMENTS_BROWSE_FILE_QUOTE';
export const attachmentsBrowseFileResponse = (type, data) => ({ type, data });

// Attachments (Optional) Browse file delete data
export const ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE = 'ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE';
export const attachmentsBrowseFileDeleteQuote = (data) => ({ type: ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE, data });
export const SUCCESS_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE = 'SUCCESS_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE';
export const ERROR_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE = 'ERROR_ATTACHMENTS_BROWSE_FILE_DELETE_QUOTE';
export const attachmentsBrowseFileDeleteResponse = (type, data) => ({ type, data });
