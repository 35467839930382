import React, { useState, useEffect, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import { EmailHeader } from "../../../component/frontend/email/EmailHeader";
import { EmailSidebar } from "../../../component/frontend/email/EmailSidebar";
import { Footer } from "../../../component/frontend/footer/footer";
import { Header } from "../../../component/frontend/header/header";
import { Loader } from "../../../component/frontend/loader/loader";
import { SubscriptionPlan } from "../profile/subscriptionPlans";
import { decodeToString, setImagePath, usePrevious } from "../../../../common/custom";
import COMPOSE_ICON from "../../../../assets/images/edit_FILL0_wght400_GRAD0_opsz24.svg";
import USER_ICON from "../../../../assets/images/image_avatar.png";
// import UPLOAD_ICON from "../../../../assets/images/attach_file_FILL0_wght400_GRAD0_opsz48.svg";
// import Swal from "sweetalert2";
import { getUserDetails } from "../../../../storage/user";
import { getUserSocialDetails } from "../../../../routing/authService";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { COMPOSE_EMAIL, EMAIL_LIST } from "../../../../routing/routeContants";
import { createDraftGmailMessage, getGmailAuthLink, getGmailValidToken, saveSocialAuthToken, sendGmailMessage, sendGmailMessageWithAttachment } from "../../../../duck/email/google/google.action";
import { constants, ConvertTimeFromUTCToIST, tinyEmailConfig } from "../../../../common/constants";
import { Editor } from "@tinymce/tinymce-react";
import TagsInput from "../../../component/frontend/input/TagsInput";
// import { useRef } from "react";
import { errorNotification } from "../../../../common/notification-alert";
import { deleteOutlookMessage, getOutlookAuthLink, getOutlookAuthToken, sendForwardOutlookMessageById, sendOutlookMessage, sendReplyToAllOutlookMessageById, sendReplyToOutlookMessageById, updateOutlookMessage } from "../../../../duck/email/microsoft/microsoft.action";
import { resetAllState, updateAccordionLabel, updateGoogleLabel, updateMicrosoftLabel } from "../../../../duck/email/email.action";
import { User } from "../../../../storage";

export const EmailReplyToPage = (props) => {
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const userSocialData = getUserSocialDetails();
  const { loading, sendEmailResponseData, errorTokenData, getGmailTokenData, createDraftMessageData } = useSelector((state) => state.google);
  const { loading: msalLoading, getAllOutlookMailFoldersData, microsoftErrorData, getOutlookAuthTokenData, updatedMessageData, draftCreatedData, isOutlookMessageSent } = useSelector((state) => state.microsoft);
  const {
    googleLabel,
    microsoftLabel,
    accordionLabel
  } = useSelector((state) => state.email);
  const currentPlan = userData && userData.planData;
  const getGmailAuthLinkData = useSelector((state) => state.google.getGmailAuthLinkData);
  const prevGetGmailAuthLinkData = usePrevious({ getGmailAuthLinkData });
  const getOutlookAuthLinkData = useSelector((state) => state.microsoft.getOutlookAuthLinkData);
  const prevGetOutlookAuthLinkData = usePrevious({ getOutlookAuthLinkData });
  const [ccActive, setCcActive] = useState(false);
  const [bccActive, setBccActive] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [outlookAttachmentData, setOutlookAttachmentData] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  // const [uploadFileError, setUploadFileError] = useState("");
  const [component, setComponent] = useState("");
  const [uniqueViewKey, setUniqueViewKey] = useState("");
  // const fileInput = useRef(null);
  const [state, setState] = useState({
    threadId: "",
    replyTo: "",
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    body: "",
    bodyText: "",
    replyToBody: "",
  });

  const [microsoftData, setMicrosoftData] = useState({
    toRecipients: [],
    ccRecipients: [],
    bccRecipients: [],
    subject: ""
  });
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);

  // Create Function
  // const createFunction = (e, path) => {
  //   e.preventDefault();
  //   // Add Free trial expire then working is blocked
  //   //if(currentPlan && currentPlan.plan_is_active === 0){
  //   // Free trial expire then working is fine
  //   if (
  //     currentPlan &&
  //     currentPlan.plan_is_active === 0 &&
  //     (path === "/user/add-quote" || path === "/user/add-invoice/")
  //   ) {
  //     let buttonMsg = currentPlan.subscription_product_id === 1 ? "View Plans" : "Renew Plan";
  //     let warMsg = currentPlan.subscription_product_id === 1 ? "Free Trial Expired" : "Subscription Expired";
  //     let msg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Your free trial has expired. Please subscribe to a plan to access the application. "
  //         : "Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ";
  //     Swal.fire({
  //       title: warMsg,
  //       html: msg,
  //       showCancelButton: true,
  //       confirmButtonText: buttonMsg,
  //       cancelButtonText: "Close",
  //       reverseButtons: true,
  //       showCloseButton: true,
  //       customClass: "mycustom-alert",
  //       cancelButtonClass: "cancel-alert-note",
  //     }).then((result) => {
  //       if (result.value) {
  //         setSubscriptionModalShow(true);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       }
  //     });
  //   } else {
  //     props.history.push(path);
  //   }
  // };

  useEffect(() => {
    dispatch(updateGoogleLabel(""));
    dispatch(updateMicrosoftLabel({}));
  }, [dispatch]);

  useEffect(() => {
    if (props.history.location && props.history.location.state && props.history.location.state.uniqueViewKey) {
      setUniqueViewKey(props.history.location.state.uniqueViewKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.history.location && props.history.location.state && props.history.location.state.messageDataState) {
      const viewMessageData = props.history.location.state.messageDataState;
      console.log(' ### viewMessageData ', viewMessageData);
      const hasAttachmentId =
        viewMessageData &&
        viewMessageData.payload &&
        viewMessageData.payload?.parts?.length &&
        viewMessageData.payload?.parts.filter((data) => _.has(data.body, "attachmentId"));
      const componentKey = props.history.location.state.key;
      setComponent(componentKey);
      const fromHeader =
        viewMessageData.payload.headers.length && viewMessageData.payload.headers.find((obj) => obj.name === "From");
      const toHeader =
        viewMessageData.payload.headers.length && viewMessageData.payload.headers.find((obj) => obj.name === "To");
      const ccHeader =
        viewMessageData.payload.headers.length && viewMessageData.payload.headers.find((obj) => obj.name === "Cc");
      const bccHeader =
        viewMessageData.payload.headers.length && viewMessageData.payload.headers.find((obj) => obj.name === "Bcc");
      const subjectHeader =
        viewMessageData.payload.headers.length && viewMessageData.payload.headers.find((obj) => obj.name === "Subject");
      const dateHeader =
        viewMessageData.payload.headers.length && viewMessageData.payload.headers.find((obj) => obj.name === "Date");
      const replyToHeader =
        viewMessageData.payload.headers.length &&
        viewMessageData.payload.headers.find((obj) => obj.name === "Message-ID" || obj.name === "Message-Id");
      const HTMLBodyPart =
        viewMessageData && viewMessageData.payload?.body && viewMessageData.payload?.body?.size > 0
          ? viewMessageData.payload
          : viewMessageData &&
            viewMessageData.payload &&
            viewMessageData.payload?.parts?.length &&
            viewMessageData.payload?.parts.find((obj) => obj.mimeType === "multipart/related")
            ? viewMessageData &&
            viewMessageData.payload &&
            viewMessageData.payload?.parts?.length &&
            viewMessageData.payload?.parts.find((obj) => obj.mimeType === "multipart/related")
            : viewMessageData &&
              viewMessageData.payload &&
              viewMessageData.payload?.parts?.length &&
              viewMessageData.payload?.parts.find((obj) => obj.mimeType === "multipart/alternative")
              ? viewMessageData &&
              viewMessageData.payload &&
              viewMessageData.payload?.parts?.length &&
              viewMessageData.payload?.parts.find((obj) => obj.mimeType === "multipart/alternative")
              : viewMessageData &&
                viewMessageData.payload &&
                viewMessageData.payload?.parts?.length &&
                viewMessageData.payload?.parts.find((obj) => obj.mimeType === "text/html")
                ? viewMessageData &&
                viewMessageData.payload &&
                viewMessageData.payload?.parts?.length &&
                viewMessageData.payload?.parts.find((obj) => obj.mimeType === "text/html")
                : viewMessageData &&
                viewMessageData.payload &&
                viewMessageData.payload?.parts?.length &&
                viewMessageData.payload?.parts.find((obj) => obj.mimeType === "text/plain");
      let bodyData = "";
      if (HTMLBodyPart && HTMLBodyPart.mimeType === "multipart/alternative") {
        let AlternativeMimetype = HTMLBodyPart && HTMLBodyPart?.parts.find((obj) => obj.mimeType === "text/html");
        if (AlternativeMimetype && AlternativeMimetype?.body && AlternativeMimetype?.body?.size > 0) {
          bodyData = decodeToString(AlternativeMimetype?.body?.data);
        }
      } else {
        if (HTMLBodyPart && HTMLBodyPart?.body && HTMLBodyPart?.body?.size > 0) {
          bodyData = decodeToString(HTMLBodyPart?.body?.data);
        }
      }
      let replyToBody =
        componentKey === "forward"
          ? `-------Forwarded message---------<br/>From: ${(fromHeader && fromHeader?.value) || "N/A"}<br/>Date: ${dateHeader && ConvertTimeFromUTCToIST(dateHeader.value, "reply")}<br/>Subject: ${(subjectHeader && subjectHeader.value) || "N/A"}<br/>To: ${(toHeader && toHeader?.value) || "N/A"}<br/><br/>${bodyData}`
          : `On ${(dateHeader && ConvertTimeFromUTCToIST(dateHeader.value, "reply")) + ", " + ((fromHeader && fromHeader?.value) || "N/A")} wrote: <br/><br/>` + bodyData;
      if (componentKey === "reply") {
        setState({
          ...state,
          threadId: viewMessageData.threadId,
          replyTo: replyToHeader.value,
          to: fromHeader && Object.keys(fromHeader).length > 0 && fromHeader.value !== "" ? [...state.to, ...fromHeader.value.split(",")] : [],
          cc: [],
          bcc: [],
          subject: `Re: ${subjectHeader.value}`,
          replyToBody: replyToBody,
        });
        setAttachmentData([...attachmentData, ...(hasAttachmentId && hasAttachmentId.length ? hasAttachmentId : [])]);
      } else if (componentKey === "reply-all") {
        setState({
          ...state,
          threadId: viewMessageData.threadId,
          replyTo: replyToHeader.value,
          to: toHeader && Object.keys(toHeader).length > 0 && toHeader.value !== "" ? [...state.to, ...toHeader.value.split(",")] : [],
          cc: ccHeader && Object.keys(ccHeader).length > 0 && ccHeader.value !== "" ? [...state.cc, ...ccHeader.value.split(",")] : [],
          bcc: bccHeader && Object.keys(bccHeader).length > 0 && bccHeader.value !== "" ? [...state.bcc, ...bccHeader.value.split(",")] : [],
          subject: `Re: ${subjectHeader.value}`,
          replyToBody: replyToBody,
        });
        setCcActive(ccHeader && Object.keys(ccHeader).length > 0 && ccHeader.value !== "" ? true : false);
        setBccActive(bccHeader && Object.keys(bccHeader).length > 0 && bccHeader.value !== "" ? true : false);
        setAttachmentData([...attachmentData, ...(hasAttachmentId && hasAttachmentId.length ? hasAttachmentId : [])]);
      } else if (componentKey === "forward") {
        setState({
          ...state,
          threadId: viewMessageData.threadId,
          replyTo: replyToHeader.value,
          to: [],
          cc: [],
          bcc: [],
          subject: `Fwd: ${subjectHeader.value}`,
          replyToBody: replyToBody,
        });
        setAttachmentData([...attachmentData, ...(hasAttachmentId.length ? hasAttachmentId : [])]);
      }
    } else if (props.history.location && props.history.location.state && props.history.location.state.microsoftMessageState) {
      const viewMicrosoftData = props.history.location.state.microsoftMessageState;
      const viewMicrosoftAttachmentData = props.history.location.state?.microsoftAttachmentState || [];
      const componentKey = props.history.location.state.key;
      setComponent(componentKey);
      // const viewKey = props.history.location.state.uniqueViewKey;
      // setUniqueViewKey(viewKey);
      if (componentKey === "reply") {
        const replyToRecipients = viewMicrosoftData?.replyTo?.length && viewMicrosoftData?.replyTo?.map(obj => obj.emailAddress.address);
        setState({
          ...state,
          threadId: viewMicrosoftData?.id,
          replyTo: "",
          to: replyToRecipients || [viewMicrosoftData?.from?.emailAddress?.address] || [],
          cc: [],
          bcc: [],
          subject: `Re: ${viewMicrosoftData?.subject}`,
          replyToBody: viewMicrosoftData?.body?.content,
        });
        setMicrosoftData({ ...microsoftData, subject: `Re: ${viewMicrosoftData?.subject}` });
        setAttachmentData([]);
        setOutlookAttachmentData([...outlookAttachmentData, ...(viewMicrosoftData?.hasAttachments && viewMicrosoftAttachmentData.length ? viewMicrosoftAttachmentData : [])]);
      } else if (componentKey === "reply-all") {
        const replyToRecipients = viewMicrosoftData?.replyTo?.length && viewMicrosoftData?.replyTo?.map(obj => obj.emailAddress.address);
        const ccRecipients = viewMicrosoftData?.ccRecipients?.length && viewMicrosoftData?.ccRecipients?.map(obj => obj.emailAddress.address);
        const bccRecipients = viewMicrosoftData?.bccRecipients?.length && viewMicrosoftData?.bccRecipients?.map(obj => obj.emailAddress.address);
        setState({
          ...state,
          threadId: viewMicrosoftData?.id,
          replyTo: "",
          to: replyToRecipients || [viewMicrosoftData?.from?.emailAddress?.address] || [],
          cc: ccRecipients || [],
          bcc: bccRecipients || [],
          subject: `Re: ${viewMicrosoftData?.subject}`,
          replyToBody: viewMicrosoftData?.body?.content,
        })
        setCcActive(ccRecipients.length ? true : false);
        setBccActive(bccRecipients.length ? true : false);
        setOutlookAttachmentData([...outlookAttachmentData, ...(viewMicrosoftData?.hasAttachments && viewMicrosoftAttachmentData.length ? viewMicrosoftAttachmentData : [])]);
      } else if (componentKey === "forward") {
        setState({
          ...state,
          threadId: viewMicrosoftData?.id,
          replyTo: "",
          to: [],
          cc: [],
          bcc: [],
          subject: `Re: ${viewMicrosoftData?.subject}`,
          replyToBody: viewMicrosoftData?.body?.content,
        });
        setOutlookAttachmentData([...outlookAttachmentData, ...(viewMicrosoftData?.hasAttachments && viewMicrosoftAttachmentData.length ? viewMicrosoftAttachmentData : [])]);
      } else if (componentKey === "editDraft") {
        const toRecipients = viewMicrosoftData?.toRecipients?.length && viewMicrosoftData?.toRecipients?.map(obj => obj.emailAddress.address);
        const ccRecipients = viewMicrosoftData?.ccRecipients?.length && viewMicrosoftData?.ccRecipients?.map(obj => obj.emailAddress.address);
        const bccRecipients = viewMicrosoftData?.bccRecipients?.length && viewMicrosoftData?.bccRecipients?.map(obj => obj.emailAddress.address);
        setState({
          ...state,
          threadId: viewMicrosoftData?.id,
          replyTo: "",
          to: toRecipients || [],
          cc: ccRecipients || [],
          bcc: bccRecipients || [],
          subject: viewMicrosoftData?.subject,
          body: viewMicrosoftData?.body?.content || "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValidRefreshToken = useCallback(async () => {
    if (
      userSocialData &&
      _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
      && errorTokenData && Object.keys(errorTokenData).length > 0
    ) {
      if (errorTokenData.code === 401) {
        const refreshToken = userSocialData?.google?.refresh_token
        dispatch(getGmailValidToken(refreshToken));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorTokenData])

  const saveRefreshToken = useCallback(async () => {
    if ((getGmailTokenData && Object.keys(getGmailTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "google") &&
      Object.keys(userSocialData.google).length > 0 &&
      userSocialData.google.access_token)) {
      const updateGmailAuthTokenData = {
        loginType: 1, authToken: getGmailTokenData?.access_token, refreshToken: getGmailTokenData?.refresh_token, expiresIn: getGmailTokenData?.expire_in,
        social_email: getGmailTokenData?.social_email,
        social_user_id: getGmailTokenData?.social_user_id,
        social_user_name: getGmailTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 1, authToken: userSocialData?.google?.access_token, refreshToken: userSocialData?.google?.refresh_token, expiresIn: userSocialData?.google?.expire_in,
        social_email: userSocialData?.google?.social_email,
        social_user_id: userSocialData?.google?.social_user_id,
        social_user_name: userSocialData?.google?.social_user_name,
      }
      const updatedData = getGmailTokenData && Object.keys(getGmailTokenData).length > 0 ? updateGmailAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getGmailTokenData])

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSendEmail = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      if (uploadFile.length > 0) {
        dispatch(sendGmailMessageWithAttachment({ raw: uploadFile, size: uploadFile[0].size, threadId: "" }));
      } else {
        const message =
          "headers: " +
          "{" +
          "In-Reply-To: " +
          state.replyTo +
          "\r\n" +
          "References: " +
          state.replyTo +
          "\r\n" +
          "}" +
          "\r\n" +
          "From: " +
          userSocialData?.google?.social_email +
          "\r\n" +
          "To: " +
          state.to +
          "\r\n" +
          "Cc: " +
          state.cc +
          "\r\n" +
          "Bcc: " +
          state.bcc +
          "\r\n" +
          "Subject: " +
          state.subject +
          "\r\n\r\n" +
          (component === "forward" ? state.bodyText + "\r\n" + state.replyToBody : state.bodyText);
        // The body needs to be base64url encoded.
        const encodedMessage = btoa(unescape(encodeURIComponent(message))).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
        if (errorTokenData && errorTokenData?.code === 401) {
          await getValidRefreshToken();
          await saveRefreshToken();
          await dispatch(sendGmailMessage({ raw: encodedMessage, threadId: state.threadId }));
        } else {
          await dispatch(sendGmailMessage({ raw: encodedMessage, threadId: state.threadId }));
        }
      }
    }
  };

  const handleCreateDraft = async () => {
    let decodedMessage =
      "From: " +
      userSocialData?.google?.social_email +
      "\r\n" +
      "To: " +
      state.to +
      "\r\n" +
      "Cc: " +
      state.cc +
      "\r\n" +
      "Bcc: " +
      state.bcc +
      "\r\n" +
      "Subject: " +
      state.subject +
      "\r\n\r\n" +
      (state.replyToBody ? state.bodyText + "\r\n" + state.replyToBody : state.bodyText);

    // The body needs to be base64url encoded.
    const encodedMessage = btoa(unescape(encodeURIComponent(decodedMessage))).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    if (errorTokenData && errorTokenData?.code === 401) {
      await getValidRefreshToken();
      await saveRefreshToken();
      await dispatch(createDraftGmailMessage({ message: { raw: encodedMessage }, threadId: "" }));
    } else {
      await dispatch(createDraftGmailMessage({ message: { raw: encodedMessage }, threadId: "" }));
    }
  }

  useEffect(() => {
    if (createDraftMessageData && createDraftMessageData?.message && createDraftMessageData?.message?.labelIds?.includes('DRAFT')) {
      props.history.push(EMAIL_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDraftMessageData]);

  useEffect(() => {
    if (sendEmailResponseData && sendEmailResponseData?.labelIds?.includes("SENT")) {
      props.history.push(EMAIL_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmailResponseData]);

  const handleGoogleLabels = (label) => {
    if (googleLabel !== label) {
      dispatch(updateGoogleLabel(label));
    }
  };

  useEffect(() => {
    if (prevGetGmailAuthLinkData && prevGetGmailAuthLinkData.getGmailAuthLinkData !== getGmailAuthLinkData) {
      if (getGmailAuthLinkData && _.has(getGmailAuthLinkData, "url") && getGmailAuthLinkData.url !== "") {
        window.location.replace(getGmailAuthLinkData.url);
      }
    }
  }, [prevGetGmailAuthLinkData, getGmailAuthLinkData])

  const handleSiginWithGoogle = () => {
    dispatch(getGmailAuthLink());
  }

  useEffect(() => {
    if (prevGetOutlookAuthLinkData && prevGetOutlookAuthLinkData.getOutlookAuthLinkData !== getOutlookAuthLinkData) {
      if (getOutlookAuthLinkData && _.has(getOutlookAuthLinkData, "url") && getOutlookAuthLinkData.url !== "") {
        window.location.replace(getOutlookAuthLinkData.url);
      }
    }
  }, [prevGetOutlookAuthLinkData, getOutlookAuthLinkData])

  const handleSiginWithMicrosoft = () => {
    dispatch(getOutlookAuthLink());
  }

  const handleMicrosoftLabels = () => { };
  const handleAccordionClick = (key) => {
    if (props.match.path !== EMAIL_LIST) {
      props.history.push(EMAIL_LIST);
    }
    dispatch(resetAllState());
    dispatch(updateAccordionLabel(key));
  }

  // const triggerInputFile = () => {
  //   fileInput.current.click();
  // };

  // const handleRequestDocuments = (event) => {
  //   const { files } = event.target;
  //   if (files) {
  //     const fileData = _.map(files, (file) => file);
  //     const fixSizeExceeded = fileData.find((data) => data.size > 25 * 1024000);
  //     if (fixSizeExceeded) {
  //       errorNotification("Size is greater than 25mb");
  //       return;
  //     }
  //     if ([...uploadFile, ...fileData].length) {
  //       setUploadFile([...uploadFile, ...fileData]);
  //       setUploadFileError("");
  //     }
  //   }
  //   event.target.files = null;
  // };

  const handleRemoveFile = (index) => {
    if (index !== -1) {
      uploadFile.splice(index, 1);
      setUploadFile([...uploadFile]);
    }
  };

  const selectedTo = (tags) => {
    setState({ ...state, to: tags });
  };

  const selectedCc = (tags) => {
    setState({ ...state, cc: tags });
  };

  const selectedBcc = (tags) => {
    setState({ ...state, bcc: tags });
  };

  const handleEditorOnChange = (event, editor) => {
    setState({ ...state, body: event, bodyText: editor?.getContent({ format: 'text' }) });
  };

  // Microsoft Functions
  const getOutlookValidRefreshToken = useCallback(async () => {
    if (
      userSocialData &&
      _.has(userSocialData, "microsoft") && Object.keys(userSocialData.microsoft).length > 0
      && microsoftErrorData && Object.keys(microsoftErrorData).length > 0
    ) {
      if (microsoftErrorData.code === "InvalidAuthenticationToken") {
        const refreshToken = userSocialData?.microsoft?.refresh_token
        dispatch(getOutlookAuthToken(refreshToken));
        User.setSocialUserDetails({ ...userSocialData, microsoft: { ...userSocialData?.microsoft, social_email: null } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, microsoftErrorData])

  const saveOutlookRefreshToken = useCallback(async () => {
    if ((getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "microsoft") &&
      Object.keys(userSocialData.microsoft).length > 0 &&
      userSocialData.microsoft.access_token)) {
      const updateOutlookAuthTokenData = {
        loginType: 2, authToken: getOutlookAuthTokenData?.access_token, refreshToken: getOutlookAuthTokenData?.refresh_token, expiresIn: getOutlookAuthTokenData?.expire_in,
        social_email: getOutlookAuthTokenData?.social_email,
        social_user_id: getOutlookAuthTokenData?.social_user_id,
        social_user_name: getOutlookAuthTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 2, authToken: userSocialData?.microsoft?.access_token, refreshToken: userSocialData?.microsoft?.refresh_token, expiresIn: userSocialData?.microsoft?.expire_in,
        social_email: userSocialData?.microsoft?.social_email,
        social_user_id: userSocialData?.microsoft?.social_user_id,
        social_user_name: userSocialData?.microsoft?.social_user_name,
      }
      const updatedData = getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0 ? updateOutlookAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getOutlookAuthTokenData])

  const handleUpdateDraft = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      let toRecipients = state.to.map((email) => {
        return {
          emailAddress: {
            address: email,
          }
        }
      });
      let ccRecipients = state.cc.map((email) => {
        return {
          emailAddress: {
            address: email,
          }
        }
      });
      let bccRecipients = state.bcc.map((email) => {
        return {
          emailAddress: {
            address: email,
          }
        }
      });
      const bodyData = {
        toRecipients,
        ccRecipients,
        bccRecipients,
        subject: state.subject,
        importance: "Low",
        body: {
          contentType: "TEXT",
          content: state.bodyText
        }
      };
      if (microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") {
        await getOutlookValidRefreshToken();
        await saveOutlookRefreshToken();
        dispatch(updateOutlookMessage({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      } else {
        dispatch(updateOutlookMessage({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      }
    }
  }

  const handleSendReplyToEmailWithMicrosoft = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      let toRecipients = state.to.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let ccRecipients = state.cc.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let bccRecipients = state.bcc.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      const bodyData = {
        message: {
          toRecipients,
          ccRecipients,
          bccRecipients
        },
        comment: state.bodyText
      };
      if (microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") {
        await getOutlookValidRefreshToken();
        await saveOutlookRefreshToken();
        dispatch(sendReplyToOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      } else {
        dispatch(sendReplyToOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      }
    }
  }

  const handleSendReplyToAllEmailWithMicrosoft = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      let toRecipients = state.to.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let ccRecipients = state.cc.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let bccRecipients = state.bcc.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      const bodyData = {
        message: {
          toRecipients,
          ccRecipients,
          bccRecipients
        },
        comment: state.bodyText
      };
      if (microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") {
        await getOutlookValidRefreshToken();
        await saveOutlookRefreshToken();
        dispatch(sendReplyToAllOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      } else {
        dispatch(sendReplyToAllOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      }
    }
  }

  const handleSendForwardEmailWithMicrosoft = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      let toRecipients = state.to.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let ccRecipients = state.cc.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let bccRecipients = state.bcc.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      const bodyData = {
        message: {
          toRecipients,
          ccRecipients,
          bccRecipients
        },
        comment: state.bodyText
      };
      if (microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") {
        await getOutlookValidRefreshToken();
        await saveOutlookRefreshToken();
        dispatch(sendForwardOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      } else {
        dispatch(sendForwardOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: state.threadId, body: bodyData }));
      }
    }
  }

  const handleSendEmailWithMicrosoft = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      let toRecipients = state.to.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let ccRecipients = state.cc.map((email) => {
        return {
          emailAddress: {
            address: email,
            name: ''
          }
        }
      });
      let bccRecipients = state.bcc.map((email) => {
        return {
          emailAddress: {
            address: email,
          }
        }
      });
      const body = {
        message: {
          toRecipients,
          ccRecipients,
          bccRecipients,
          subject: state.subject,
          body: {
            contentType: "Text",
            content: state.bodyText
          }
        },
      };
      if (microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") {
        await getOutlookValidRefreshToken();
        await saveOutlookRefreshToken();
        await dispatch(sendOutlookMessage({ body }));
        await dispatch(deleteOutlookMessage({ messageId: state.threadId }));
      } else {
        await dispatch(sendOutlookMessage({ body }));
        await dispatch(deleteOutlookMessage({ messageId: state.threadId }));
      }
    }
  }

  const handleSendEmailOnBasisOfKey = () => {
    console.log(' ### UNIQUE_VIEW_KEY ===> ', uniqueViewKey)
    if (uniqueViewKey === "google") {
      handleSendEmail();
    } else if (uniqueViewKey === "microsoft") {
      if (component === "reply") {
        handleSendReplyToEmailWithMicrosoft();
      } else if (component === "reply-all") {
        handleSendReplyToAllEmailWithMicrosoft()
      } else if (component === "forward") {
        handleSendForwardEmailWithMicrosoft();
      } else if (component === "editDraft") {
        handleSendEmailWithMicrosoft();
      }
    }
  }

  useEffect(() => {
    if (((updatedMessageData && Object.keys(updatedMessageData).length) || (draftCreatedData && Object.keys(draftCreatedData).length) || (isOutlookMessageSent))) {
      props.history.push(EMAIL_LIST);
    }
  }, [props.history, updatedMessageData, draftCreatedData, isOutlookMessageSent])

  return (
    <>
      <Loader loader={loading || msalLoading} />
      <div className="main-site fixed--header fixed-email-header">
        <Header getMainRoute={"email"} />
        <main className="site-body bg-white">
          {/* <EmailHeader createFunction={createFunction} /> */}
          <EmailHeader />
          <section className="middle-section email-template-design">
            <div className="container-fluid">
              <div className="row no-gutters-mbl">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="email-template">
                        <div className="email-head d-flex">
                          <div className="top-btn-left">
                            <Link to={COMPOSE_EMAIL} className="email-btn">
                              <img src={setImagePath(COMPOSE_ICON)} alt="COMPOSE_ICON" />
                              <span className="btn-text">Compose</span>
                            </Link>
                          </div>
                          <div className="create-btns ml-auto">
                            <span className="btn-text font-weight-bold">Note: Hit 'Enter' or 'Return' after typing an email address.</span>
                            {component === "editDraft" ?
                              <button className="btn btn-secondary" onClick={handleUpdateDraft} disabled={!(state.to.length || state.cc.length || state.bcc.length || state.body.length || state?.subject?.length)}>
                                Update Draft
                              </button>
                              :
                              <button className="btn btn-secondary" onClick={handleCreateDraft} disabled={!(state.to.length || state.cc.length || state.bcc.length || state.body.length || state?.subject?.length)}>
                                Save Draft
                              </button>}
                            <button
                              // disabled={
                              //   state.to.length > 0 && state.subject.length > 0 && state.body.length > 0 ? false : true
                              // }
                              className="btn btn-primary"
                              onClick={handleSendEmailOnBasisOfKey}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                        <div className="email-content">
                          <EmailSidebar
                            googleErrorData={errorTokenData}
                            microsoftErrorData={microsoftErrorData}
                            handleSiginWithGoogle={handleSiginWithGoogle}
                            handleSiginWithMicrosoft={handleSiginWithMicrosoft}
                            accordionState={accordionLabel}
                            labelId={googleLabel}
                            userSocialData={userSocialData}
                            handleGoogleLabels={handleGoogleLabels}
                            msalLabel={microsoftLabel}
                            labelsList={getAllOutlookMailFoldersData && getAllOutlookMailFoldersData?.value}
                            handleMicrosoftLabels={handleMicrosoftLabels}
                            handleAccordionClick={handleAccordionClick}
                          />
                          <div className="col-sm-10">
                            <div className="bg-white boxShadow h-100 p-3">
                              <div className="compose-head">
                                <div className="comp-inner">
                                  <div className="avatar">
                                    <img src={setImagePath(USER_ICON)} alt="user_icon" />
                                  </div>
                                  <div className="email-from">
                                    <div className="input-box">
                                      <label>To</label>
                                      <TagsInput selectedTags={selectedTo} inputTags={state.to} />
                                    </div>
                                    {!(ccActive && bccActive) && (
                                      <div className="show-cc-bc">
                                        {!ccActive && <span onClick={() => setCcActive(!ccActive)}>Cc</span>}
                                        {!bccActive && <span onClick={() => setBccActive(!bccActive)}>Bcc</span>}
                                      </div>
                                    )}
                                    {ccActive && (
                                      <div className="input-box">
                                        <label>Cc</label>
                                        <TagsInput selectedTags={selectedCc} inputTags={state.cc} />
                                      </div>
                                    )}
                                    {bccActive && (
                                      <div className="input-box">
                                        <label>Bcc</label>
                                        <TagsInput selectedTags={selectedBcc} inputTags={state.bcc} />
                                      </div>
                                    )}

                                    <div className="input-box">
                                      <input
                                        placeholder="Subject"
                                        className="form-control bg-transparent border-0"
                                        type="text"
                                        name="subject"
                                        value={state.subject}
                                        onChange={handleOnChange}
                                      />
                                    </div>

                                    {/* <label
                                      htmlFor="file_upload"
                                      className="drop-attach"
                                      onClick={() => triggerInputFile()}
                                    >
                                      <img
                                        src={setImagePath(UPLOAD_ICON)}
                                        alt="upload_icon"
                                      />{" "}
                                      Click or Drop attachment here
                                      <input
                                        ref={fileInput}
                                        type="file"
                                        multiple={true}
                                        className="hiddenInputTypeFile"
                                        onChange={(e) =>
                                          handleRequestDocuments(e)
                                        }
                                        accept="*"
                                        style={{ display: "none" }}
                                      />
                                    </label> */}
                                    <div className="drop-attach-name mt-2">
                                      {attachmentData.length > 0 &&
                                        _.map(attachmentData, (doc, index) => {
                                          return <span key={index}>{doc.filename}</span>;
                                        })}
                                      {outlookAttachmentData.length > 0 &&
                                        _.map(outlookAttachmentData, (doc, index) => {
                                          return <span key={index}>{doc.name}</span>;
                                        })}
                                      {uploadFile.length > 0 &&
                                        _.map(uploadFile, (docF, docI) => {
                                          return (
                                            <span key={docI}>
                                              {docF.name}
                                              {
                                                <button onClick={() => handleRemoveFile(docI)}>
                                                  <i className="far fa-times-circle ms-2"></i>
                                                </button>
                                              }
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <Editor
                                  apiKey={constants.tinyAapiKey}
                                  init={tinyEmailConfig}
                                  name="body"
                                  value={state.body !== "" ? state.body : ""}
                                  onEditorChange={(data, editor) => handleEditorOnChange(data, editor)}
                                />
                                {component !== "editDraft" && <div
                                  style={{
                                    border: "1px solid #ccc",
                                    borderTop: "none",
                                    padding: "5px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: state.replyToBody,
                                  }}
                                ></div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        {/* Subscription Modal*/}
        <SubscriptionPlan
          loader={loading || msalLoading}
          openSubscriptionModal={subscriptionModalShow}
          closeSubscriptionModal={() => setSubscriptionModalShow(false)}
          updatePlanDetail={(data) => {
            setSubscriptionModalShow(false);
          }}
          currentPlan={currentPlan}
        />
      </div>
    </>
  );
};

export const EmailReplyTo = withRouter(EmailReplyToPage);
