import { sortArrInCustomOrder } from "../../../common/custom";
import { User } from "../../../storage";
import {
    SUCCESS_GET_OUTLOOK_AUTH_LINK,
    ERROR_GET_OUTLOOK_AUTH_LINK,
    SUCCESS_GET_ALL_OUTLOOK_MESSAGES,
    ERROR_GET_ALL_OUTLOOK_MESSAGES,
    SUCCESS_GET_ALL_OUTLOOK_MAILFOLDERS,
    ERROR_GET_ALL_OUTLOOK_MAILFOLDERS,
    GET_ALL_OUTLOOK_MAILFOLDERS,
    GET_ALL_OUTLOOK_MESSAGES,
    GET_OUTLOOK_AUTH_LINK,
    GET_OUTLOOK_MESSAGE_BY_ID,
    SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID,
    ERROR_GET_OUTLOOK_MESSAGE_BY_ID,
    GET_OUTLOOK_AUTH_TOKEN,
    SUCCESS_GET_OUTLOOK_AUTH_TOKEN,
    ERROR_GET_OUTLOOK_AUTH_TOKEN,
    GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS,
    ERROR_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS,
    SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS,
    SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID,
    SUCCESS_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID,
    ERROR_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID,
    SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID,
    SUCCESS_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID,
    ERROR_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID,
    SEND_OUTLOOK_MESSAGE,
    SUCCESS_SEND_OUTLOOK_MESSAGE,
    ERROR_SEND_OUTLOOK_MESSAGE,
    SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID,
    SUCCESS_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID,
    ERROR_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID,
    CREATE_OUTLOOK_DRAFT_MESSAGE,
    UPDATE_OUTLOOK_MESSAGE,
    SUCCESS_UPDATE_OUTLOOK_MESSAGE,
    ERROR_UPDATE_OUTLOOK_MESSAGE,
    DELETE_OUTLOOK_MESSAGE,
    SUCCESS_DELETE_OUTLOOK_MESSAGE,
    ERROR_DELETE_OUTLOOK_MESSAGE,
    MOVE_OUTLOOK_MESSAGE,
    SUCCESS_MOVE_OUTLOOK_MESSAGE,
    ERROR_MOVE_OUTLOOK_MESSAGE,
    MARK_AS_READ_OUTLOOK_MESSAGE,
    SUCCESS_MARK_AS_READ_OUTLOOK_MESSAGE,
    ERROR_MARK_AS_READ_OUTLOOK_MESSAGE,
    MARK_AS_UNREAD_OUTLOOK_MESSAGE,
    SUCCESS_MARK_AS_UNREAD_OUTLOOK_MESSAGE,
    ERROR_MARK_AS_UNREAD_OUTLOOK_MESSAGE,
    SUCCESS_CREATE_OUTLOOK_DRAFT_MESSAGE,
    ERROR_CREATE_OUTLOOK_DRAFT_MESSAGE,
    RESET_MICROSOFT_STATE,
} from "./microsoft.action";
import { DEFAULT_STATE } from "./microsoft.state";
import _ from 'lodash';

export const microsoftReducer = (
    state = DEFAULT_STATE,
    action = {
        type: {},
        data: {},
    }
) => {
    switch (action.type) {
        case GET_OUTLOOK_AUTH_LINK:
            return { ...state, loading: true };
        case SUCCESS_GET_OUTLOOK_AUTH_LINK:
            const getOutlookAuthLinkData = action.data;
            return {
                ...state,
                loading: false,
                getOutlookAuthLinkData,
                microsoftErrorData: {}
            };
        case ERROR_GET_OUTLOOK_AUTH_LINK:
            const errorGetOutlookAuthLinkData = action.data;
            return {
                ...state,
                loading: false,
                microsoftErrorData: errorGetOutlookAuthLinkData &&
                    Object.keys(errorGetOutlookAuthLinkData).length > 0
                    ? errorGetOutlookAuthLinkData.error
                    : {},
                getOutlookAuthLinkData: {},
            };
        case GET_OUTLOOK_AUTH_TOKEN:
            return { ...state, loading: true };
        case SUCCESS_GET_OUTLOOK_AUTH_TOKEN:
            const getOutlookAuthTokenData = action.data;
            let setOutlookAuthTokenData = state.getOutlookAuthTokenData;
            if (
                getOutlookAuthTokenData &&
                getOutlookAuthTokenData.accessToken &&
                getOutlookAuthTokenData.refreshToken &&
                getOutlookAuthTokenData.expires_in
            ) {
                let setSocialData = {
                    ...User.getSocialUserDetails(),
                    microsoft: {
                        ...User.getSocialUserDetails()?.microsoft,
                        access_token: getOutlookAuthTokenData.accessToken,
                        refresh_token: getOutlookAuthTokenData.refreshToken,
                        expire_in: getOutlookAuthTokenData.expires_in,
                    }
                };
                User.setSocialUserDetails(setSocialData);
                setOutlookAuthTokenData = setSocialData.microsoft;
            }
            return {
                ...state,
                loading: false,
                getOutlookAuthTokenData: setOutlookAuthTokenData,
                // microsoftErrorData: {},
            };
        case ERROR_GET_OUTLOOK_AUTH_TOKEN:
            const errorGetGmailTokenData = action.data;
            return {
                ...state,
                loading: false,
                getOutlookAuthTokenData: {},
                microsoftErrorData: errorGetGmailTokenData &&
                    Object.keys(errorGetGmailTokenData).length > 0
                    ? errorGetGmailTokenData.error
                    : {},
            };
        // case CHANGE_OUTLOOK_LABEL:
        //     const msalLabel = action.data;
        //     return { ...state, 
        //         msalLabel,
        //         getAllOutlookMessagesData: {},
        //         microsoftErrorData: {},
        //         getOutlookAuthLinkData: {},
        //         getOutlookAuthTokenData: {},
        //         getOutlookMessageData: {},
        //         getOutlookMessageAttachmentsData: {},
        //         draftCreatedData: {},
        //         updatedMessageData: {},
        //         isOutlookMessageSent: null
        //     };
        case GET_ALL_OUTLOOK_MAILFOLDERS:
            return { ...state, loading: true };
        case SUCCESS_GET_ALL_OUTLOOK_MAILFOLDERS:
            const getAllOutlookMailFoldersData = action.data;
            let updatedOutlookMailFoldersData = {};
            const labelsArr = getAllOutlookMailFoldersData?.value || [];
            const sortOrder = ["Inbox", "Drafts", "Sent Items","Junk Email", "Deleted Items"];
            let filterLabelsArr = labelsArr.filter((obj) => {
                if(sortOrder.includes(obj.displayName)){
                  return {
                    ...obj,
                  }
                } else {
                    return null;
                }
            })
            const fetchAllLabelNames = filterLabelsArr.map(obj => obj.displayName);
            const newDisplayNames = sortArrInCustomOrder(fetchAllLabelNames, sortOrder);
            const filteredLabels = newDisplayNames.map((obj) => filterLabelsArr.filter((label) => {
                if(label.displayName === obj){
                    return{
                        ...label,
                        displayName: obj
                    }
                } else {
                    return null;
                }
            }))
            let finalResult = [].concat.apply([], filteredLabels);
            updatedOutlookMailFoldersData = {...getAllOutlookMailFoldersData, value: finalResult}
            return {
                ...state,
                loading: false,
                getAllOutlookMailFoldersData: updatedOutlookMailFoldersData,
                draftCreatedData: {},
                updatedMessageData: {},
                microsoftErrorData: {}
            }
        case ERROR_GET_ALL_OUTLOOK_MAILFOLDERS:
            const errorGetAllOutlookMailFoldersData = action.data;
            return {
                ...state,
                loading: false,
                microsoftErrorData: errorGetAllOutlookMailFoldersData &&
                    Object.keys(errorGetAllOutlookMailFoldersData).length > 0
                    ? errorGetAllOutlookMailFoldersData.error
                    : {},
                draftCreatedData: {},
                updatedMessageData: {},
                getAllOutlookMailFoldersData: {}
            }
        case GET_ALL_OUTLOOK_MESSAGES:
            const outlookMessagesRequestData = action.data;
            return { ...state, loading: true, outlookMessagesRequestData };
        case SUCCESS_GET_ALL_OUTLOOK_MESSAGES:
            const getAllOutlookMessagesData = action.data;
            const searchReq = {...state.outlookMessagesRequestData};
            let getAllOutlookMessagesState = {};
            if (getAllOutlookMessagesData?.['@odata.nextLink'] || searchReq?.search) {
                getAllOutlookMessagesState = { ...getAllOutlookMessagesData, value: _.uniqBy(getAllOutlookMessagesData?.value || [], "id") };
            } else {
                getAllOutlookMessagesState = { ...getAllOutlookMessagesData, value: _.uniqBy([...(state.getAllOutlookMessagesData?.value || []), ...(getAllOutlookMessagesData?.value || [])], "id") };
            }
            return {
                ...state,
                loading: false,
                draftCreatedData: {},
                updatedMessageData: {},
                outlookMessagesRequestData: {},
                getAllOutlookMessagesData: getAllOutlookMessagesState,
                microsoftErrorData: {}
            }
        case ERROR_GET_ALL_OUTLOOK_MESSAGES:
            const errorGetAllOutlookMessagesData = action.data;
            return {
                ...state,
                loading: false,
                microsoftErrorData: errorGetAllOutlookMessagesData &&
                    Object.keys(errorGetAllOutlookMessagesData).length > 0
                    ? errorGetAllOutlookMessagesData.error
                    : {},
                getAllOutlookMessagesData: {}
            }
        case GET_OUTLOOK_MESSAGE_BY_ID:
            return { ...state, loading: true };
        case SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID:
            const getOutlookMessageData = action.data;
            return {
                ...state,
                loading: false,
                getOutlookMessageData,
                microsoftErrorData: {}
            }
        case ERROR_GET_OUTLOOK_MESSAGE_BY_ID:
            const errorGetOutlookMessageData = action.data;
            return {
                ...state,
                loading: false,
                microsoftErrorData: errorGetOutlookMessageData &&
                    Object.keys(errorGetOutlookMessageData).length > 0
                    ? errorGetOutlookMessageData.error
                    : {},
                getOutlookMessageData: {}
            }
        case GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS:
            return { ...state, loading: true };
        case SUCCESS_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS:
            const getOutlookMessageAttachmentsData = action.data;
            return {
                ...state,
                loading: false,
                getOutlookMessageAttachmentsData,
                microsoftErrorData: {}
            }
        case ERROR_GET_OUTLOOK_MESSAGE_BY_ID_ATTACHMENTS:
            const errorGetOutlookMessageAttachmentsData = action.data;
            return {
                ...state,
                loading: false,
                microsoftErrorData: errorGetOutlookMessageAttachmentsData &&
                    Object.keys(errorGetOutlookMessageAttachmentsData).length > 0
                    ? errorGetOutlookMessageAttachmentsData.error
                    : {},
                getOutlookMessageAttachmentsData: {}
            }
        case SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID:
            return { ...state, loading: true };
        case SUCCESS_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID:
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: true,
                microsoftErrorData: {}
            }
        case ERROR_SEND_REPLY_TO_OUTLOOK_MESSAGE_BY_ID:
            const errorReplyMessageByIdData = action.data;
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: false,
                microsoftErrorData: errorReplyMessageByIdData &&
                    Object.keys(errorReplyMessageByIdData).length > 0
                    ? errorReplyMessageByIdData.error
                    : {},
            }
        case SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID:
            return { ...state, loading: true };
        case SUCCESS_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID:
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: true,
                microsoftErrorData: {}
            }
        case ERROR_SEND_REPLY_TO_ALL_OUTLOOK_MESSAGE_BY_ID:
            const errorReplyAllMessageByIdData = action.data;
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: false,
                microsoftErrorData: errorReplyAllMessageByIdData &&
                    Object.keys(errorReplyAllMessageByIdData).length > 0
                    ? errorReplyAllMessageByIdData.error
                    : {},
            }
        case SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID:
            return { ...state, loading: true };
        case SUCCESS_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID:
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: true,
                microsoftErrorData: {}
            }
        case ERROR_SEND_FORWARD_OUTLOOK_MESSAGE_BY_ID:
            const errorForwardMessageByIdData = action.data;
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: false,
                microsoftErrorData: errorForwardMessageByIdData &&
                    Object.keys(errorForwardMessageByIdData).length > 0
                    ? errorForwardMessageByIdData.error
                    : {},
            }
        case SEND_OUTLOOK_MESSAGE:
            return { ...state, loading: true };
        case SUCCESS_SEND_OUTLOOK_MESSAGE:
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: true,
                microsoftErrorData: {}
            }
        case ERROR_SEND_OUTLOOK_MESSAGE:
            const errorSendOutlookMessageData = action.data;
            return {
                ...state,
                loading: false,
                isOutlookMessageSent: false,
                microsoftErrorData: errorSendOutlookMessageData &&
                    Object.keys(errorSendOutlookMessageData).length > 0
                    ? errorSendOutlookMessageData.error
                    : {},
            }
        case CREATE_OUTLOOK_DRAFT_MESSAGE:
            return { ...state, loading: true };
        case SUCCESS_CREATE_OUTLOOK_DRAFT_MESSAGE:
            const draftCreatedData = action.data;
            return { ...state, loading: false, draftCreatedData, microsoftErrorData: {} }
        case ERROR_CREATE_OUTLOOK_DRAFT_MESSAGE:
            const errorCreateDraftMessage = action.data;
            return {
                ...state, 
                loading: false,
                draftCreatedData: {},
                microsoftErrorData: errorCreateDraftMessage &&
                    Object.keys(errorCreateDraftMessage).length > 0
                    ? errorCreateDraftMessage.error
                    : {}
            };
        case UPDATE_OUTLOOK_MESSAGE:
            return { ...state, loading: true };
        case SUCCESS_UPDATE_OUTLOOK_MESSAGE:
            const updatedMessageData = action.data;
            return { ...state, loading: false, updatedMessageData, microsoftErrorData: {} }
        case ERROR_UPDATE_OUTLOOK_MESSAGE:
            const errorUpdateOutlookMessage = action.data;
            return {
                ...state, 
                loading: false,
                updatedMessageData: {},
                microsoftErrorData: errorUpdateOutlookMessage &&
                    Object.keys(errorUpdateOutlookMessage).length > 0
                    ? errorUpdateOutlookMessage.error
                    : {}
            };
        case DELETE_OUTLOOK_MESSAGE:
            return { ...state, loading: true };
        case SUCCESS_DELETE_OUTLOOK_MESSAGE:
            return { ...state, loading: false, microsoftErrorData: {} }
        case ERROR_DELETE_OUTLOOK_MESSAGE:
            const errorDeleteOutlookMessage = action.data;
            return {
                ...state, 
                loading: false, 
                microsoftErrorData: errorDeleteOutlookMessage &&
                    Object.keys(errorDeleteOutlookMessage).length > 0
                    ? errorDeleteOutlookMessage.error
                    : {}
            };
        case MOVE_OUTLOOK_MESSAGE:
            return { ...state, loading: true };
        case SUCCESS_MOVE_OUTLOOK_MESSAGE:
            const deleteMessageId = action.data?.prevMessageId || "";
            let getAllOutlookMessagesList = { ...state.getAllOutlookMessagesData };
            getAllOutlookMessagesList = { ...getAllOutlookMessagesList, value: getAllOutlookMessagesList?.value?.length && getAllOutlookMessagesList?.value?.filter(obj => obj.id !== deleteMessageId) }
            return { ...state, getAllOutlookMessagesData: getAllOutlookMessagesList, loading: false, microsoftErrorData: {} }
        case ERROR_MOVE_OUTLOOK_MESSAGE:
            const errorMoveOutlookMessage = action.data;
            return {
                ...state, 
                loading: false, 
                microsoftErrorData: errorMoveOutlookMessage &&
                    Object.keys(errorMoveOutlookMessage).length > 0
                    ? errorMoveOutlookMessage.error
                    : {}
            };
        case MARK_AS_READ_OUTLOOK_MESSAGE:
            return { ...state, loading: true };
        case SUCCESS_MARK_AS_READ_OUTLOOK_MESSAGE:
            const markAsReadMessageId = action.data?.id || "";
            let unReadedOutlookMessageObj = { ...state.getAllOutlookMessagesData };
            let getListOfAllUnreadedOutlookMessages = unReadedOutlookMessageObj?.value?.length ? [...unReadedOutlookMessageObj?.value] : [];
            const findUnreadMessageIndex = getListOfAllUnreadedOutlookMessages.findIndex(obj => obj.id === markAsReadMessageId);
            getListOfAllUnreadedOutlookMessages[findUnreadMessageIndex] = { ...getListOfAllUnreadedOutlookMessages[findUnreadMessageIndex], isRead: true };
            unReadedOutlookMessageObj = { ...unReadedOutlookMessageObj, value: getListOfAllUnreadedOutlookMessages }
            return { ...state, getAllOutlookMessagesData: unReadedOutlookMessageObj, loading: false, microsoftErrorData: {} }
        case ERROR_MARK_AS_READ_OUTLOOK_MESSAGE:
            const errorMarkAsReadOutlookMessage = action.data;
            return {
                ...state, 
                loading: false, 
                microsoftErrorData: errorMarkAsReadOutlookMessage &&
                    Object.keys(errorMarkAsReadOutlookMessage).length > 0
                    ? errorMarkAsReadOutlookMessage.error
                    : {}
            };
        case MARK_AS_UNREAD_OUTLOOK_MESSAGE:
            return { ...state, loading: true };
        case SUCCESS_MARK_AS_UNREAD_OUTLOOK_MESSAGE:
            const markAsUnreadMessageId = action.data?.id || "";
            let readedOutlookMessageObj = { ...state.getAllOutlookMessagesData };
            let getListOfAllReadedOutlookMessages = readedOutlookMessageObj?.value?.length ? [...readedOutlookMessageObj?.value] : [];
            const findReadMessageIndex = getListOfAllReadedOutlookMessages.findIndex(obj => obj.id === markAsUnreadMessageId);
            getListOfAllReadedOutlookMessages[findReadMessageIndex] = { ...getListOfAllReadedOutlookMessages[findReadMessageIndex], isRead: false };
            readedOutlookMessageObj = { ...readedOutlookMessageObj, value: getListOfAllReadedOutlookMessages }
            return { ...state, getAllOutlookMessagesData: readedOutlookMessageObj, loading: false, microsoftErrorData: {} }
        case ERROR_MARK_AS_UNREAD_OUTLOOK_MESSAGE:
            const errorMarkAsUnreadOutlookMessage = action.data;
            return {
                ...state, 
                loading: false, 
                microsoftErrorData: errorMarkAsUnreadOutlookMessage &&
                    Object.keys(errorMarkAsUnreadOutlookMessage).length > 0
                    ? errorMarkAsUnreadOutlookMessage.error
                    : {}
            };
        case RESET_MICROSOFT_STATE:
            return {
                ...state,
                getAllOutlookMessagesData: {},
                microsoftErrorData: {},
                getOutlookAuthLinkData: {},
                getOutlookAuthTokenData: {},
                getOutlookMessageData: {},
                getOutlookMessageAttachmentsData: {},
                draftCreatedData: {},
                updatedMessageData: {},
                isOutlookMessageSent: null,
                outlookMessagesRequestData: {},
            }
        default:
            return state;
    }
}