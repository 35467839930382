export const DEFAULT_STATE = {
    loading: false,
    getCalendarEventListData: {},
    getDeleteCalendarEventListData: {},
    editCalendarEventData: {},
    tokenErrorData: {},
    getMicrosoftEventListData: {},
    getDeleteMicrosoftEventListData: {},
    editMicrosoftEventData: {},
    getDeleteBatchCalendarEventListData: {},
    };