import React, { useState, useEffect } from "react";
import CROSS_ICON from "../../../../assets/images/cross-icn.svg";
import { NavLink } from "react-router-dom";
import {
  DASHBOARD,
  LIST_BOOKINGS,
  LIST_LEADS,
  LIST_CONTACTS,
  VIEW_PROFILE,
  LIST_QUOTES,
  LIST_CONTRACTS,
  LIST_INVOICES,
  EMAIL_AUTH,
  VIEW_EXPENSES,
} from "../../../../routing/routeContants";
import { setImagePath } from "../../../../common/custom";

export const Navbar = (props) => {
  const menuCountData = props.entityData;
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkViewportSize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint
    };
    checkViewportSize()
    window.addEventListener('resize', checkViewportSize);
    return () => {
      window.removeEventListener('resize', checkViewportSize);
    };
  }, []);
  return (
    <>
      {isMobileView ? (
        <>
          <nav className="site-navbar navbar-expand-lg navbar navbar-light">
            <div className="container">
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className={(props && props.getMainRoute === 'dashboard') ? "nav-item active" : "nav-item"}>
                    <NavLink to={DASHBOARD} className="nav-link">Dashboard</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'leads') ? "nav-item active" : "nav-item"}>
                    <NavLink to={LIST_LEADS} className="nav-link">Leads {<span className="count">({menuCountData && menuCountData.leads ? menuCountData.leads : 0})</span>}</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'quotes') ? "nav-item active" : "nav-item"}>
                    <NavLink to={LIST_QUOTES} className="nav-link">Quotes {<span className="count">({menuCountData && menuCountData.quotes ? menuCountData.quotes : 0})</span>}</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'contracts') ? "nav-item active" : "nav-item"}>
                    <NavLink to={LIST_CONTRACTS} className="nav-link">Contracts {<span className="count">({menuCountData && menuCountData.contracts ? menuCountData.contracts : 0})</span>}</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'invoices') ? "nav-item active" : "nav-item"}>
                    <NavLink to={LIST_INVOICES} className="nav-link">Invoices {<span className="count">({menuCountData && menuCountData.invoices ? menuCountData.invoices : 0})</span>}</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'bookings') ? "nav-item active" : "nav-item"}>
                    <NavLink to={LIST_BOOKINGS} className="nav-link">Calendar {<span className="count">({menuCountData && menuCountData.bookings ? menuCountData.bookings : 0})</span>}</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'contacts') ? "nav-item active" : "nav-item"}>
                    <NavLink to={LIST_CONTACTS} className="nav-link">Contacts <span className="count">({menuCountData && menuCountData.contacts ? menuCountData.contacts : 0})</span></NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'email') ? "nav-item active" : "nav-item"}>
                    {/* <NavLink to={EMAIL_AUTH} className="nav-link"> Email <span className="highlighted-note">(New)</span></NavLink> */}
                    <NavLink to={EMAIL_AUTH} className="nav-link"> Email</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'expenses') ? "nav-item active" : "nav-item"}>
                    <NavLink to={VIEW_EXPENSES} className="nav-link">Expenses</NavLink>
                  </li>
                  <li className={(props && props.getMainRoute === 'profile') ? "nav-item active" : "nav-item"}>
                    <NavLink to={VIEW_PROFILE} className="nav-link">My Profile</NavLink>
                  </li>
                </ul>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <img src={setImagePath(CROSS_ICON)} alt="" />
                </button>
              </div>
            </div>
          </nav>
        </>) : (
        <>
          <div id="app-sidepanel" class="app-sidepanel">
            <div id="sidepanel-drop" class="sidepanel-drop"></div>
            <div class="sidepanel-inner d-flex flex-column">
              <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
                <ul class="app-menu list-unstyled accordion" id="menu-accordion">
                  <li className="nav-item ">
                    <NavLink
                      className={
                        props && props.getMainRoute === "dashboard"
                          ? "nav-link active "
                          : "nav-link"
                      }
                      to={DASHBOARD}
                    >
                      <span class="nav-icon ">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="vuesax/linear/element-4">
                            <g id="element-4">
                              <path
                                id="Vector"
                                d="M22 10.9V4.1C22 2.6 21.36 2 19.77 2H15.73C14.14 2 13.5 2.6 13.5 4.1V10.9C13.5 12.4 14.14 13 15.73 13H19.77C21.36 13 22 12.4 22 10.9Z"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                id="Vector_2"
                                d="M22 19.9V18.1C22 16.6 21.36 16 19.77 16H15.73C14.14 16 13.5 16.6 13.5 18.1V19.9C13.5 21.4 14.14 22 15.73 22H19.77C21.36 22 22 21.4 22 19.9Z"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                id="Vector_3"
                                d="M10.5 13.1V19.9C10.5 21.4 9.86 22 8.27 22H4.23C2.64 22 2 21.4 2 19.9V13.1C2 11.6 2.64 11 4.23 11H8.27C9.86 11 10.5 11.6 10.5 13.1Z"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                id="Vector_4"
                                d="M10.5 4.1V5.9C10.5 7.4 9.86 8 8.27 8H4.23C2.64 8 2 7.4 2 5.9V4.1C2 2.6 2.64 2 4.23 2H8.27C9.86 2 10.5 2.6 10.5 4.1Z"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      Dashboard
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "leads"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={LIST_LEADS}
                    >
                      <span class="nav-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9.71745 10.917L7.31744 13.317C7.21345 13.4174 7.17175 13.5661 7.20836 13.706C7.24497 13.8458 7.35418 13.9551 7.49404 13.9917C7.63389 14.0283 7.78261 13.9866 7.88304 13.8826L10.2831 11.4826C10.4346 11.3256 10.4325 11.0761 10.2782 10.9218C10.1239 10.7676 9.8744 10.7654 9.71745 10.917Z"
                            fill="white"
                          />
                          <path
                            d="M7.60117 11.5998C7.82209 11.5998 8.00117 11.4207 8.00117 11.1998C8.00117 10.9789 7.82209 10.7998 7.60117 10.7998C7.38026 10.7998 7.20117 10.9789 7.20117 11.1998C7.20117 11.4207 7.38026 11.5998 7.60117 11.5998Z"
                            fill="white"
                          />
                          <path
                            d="M9.99961 14.0002C10.2205 14.0002 10.3996 13.8211 10.3996 13.6002C10.3996 13.3793 10.2205 13.2002 9.99961 13.2002C9.7787 13.2002 9.59961 13.3793 9.59961 13.6002C9.59961 13.8211 9.7787 14.0002 9.99961 14.0002Z"
                            fill="white"
                          />
                          <path
                            d="M11.6 21.2004C11.6 19.654 10.3464 18.4004 8.80001 18.4004C7.25361 18.4004 6 19.654 6 21.2004C6 22.7468 7.25361 24.0004 8.80001 24.0004C10.3456 23.9984 11.598 22.746 11.6 21.2004ZM6.8 21.2004C6.8 20.0958 7.69544 19.2004 8.80001 19.2004C9.90458 19.2004 10.8 20.0958 10.8 21.2004C10.8 22.305 9.90458 23.2004 8.80001 23.2004C7.69599 23.1991 6.80133 22.3044 6.8 21.2004Z"
                            fill="white"
                          />
                          <path
                            d="M18.4001 6.10606e-07C15.9253 0.0037624 13.7456 1.6292 13.036 4.00001H1.2C0.53726 4.00001 0 4.53727 0 5.20002V6.80002C0 7.46277 0.53726 8.00003 1.2 8.00003H1.4572L5.30762 11.15C5.49323 11.3014 5.60066 11.5285 5.60002 11.768V16.4001C5.60178 17.0621 6.13801 17.5983 6.80002 17.6001C6.96222 17.5999 7.12274 17.5671 7.27202 17.5037L11.272 15.7885C11.7146 15.6 12.0013 15.165 12 14.684V11.768C11.9994 11.5283 12.107 11.3011 12.2928 11.1496L14.3556 9.46283C16.1166 11.3062 18.9008 11.7267 21.1276 10.4855C23.3544 9.24427 24.461 6.65507 23.8192 4.18783C23.1774 1.72059 20.9494 -0.00118372 18.4001 6.10606e-07ZM11.7872 10.5296C11.4147 10.8327 11.199 11.2878 11.2 11.768V14.684C11.1999 14.8435 11.1058 14.9879 10.96 15.0524L6.96002 16.7677C6.83563 16.8214 6.69241 16.8082 6.58002 16.7325C6.46726 16.6596 6.39941 16.5343 6.40002 16.4001V11.768C6.40179 11.2873 6.1861 10.8315 5.81322 10.528L2.72001 8.00003H4.00001C4.22093 8.00003 4.40001 7.82094 4.40001 7.60002C4.40001 7.37911 4.22093 7.20002 4.00001 7.20002H1.2C0.979089 7.20002 0.800003 7.02094 0.800003 6.80002V5.20002C0.800003 4.9791 0.979089 4.80002 1.2 4.80002H12.864C12.7388 5.60272 12.7976 6.42338 13.036 7.20002H5.60002C5.3791 7.20002 5.20002 7.37911 5.20002 7.60002C5.20002 7.82094 5.3791 8.00003 5.60002 8.00003H13.348C13.4889 8.29616 13.6554 8.57938 13.8456 8.84643L11.7872 10.5296ZM18.4001 10.4C15.7491 10.4 13.6 8.25099 13.6 5.60002C13.6 2.94904 15.7491 0.800003 18.4001 0.800003C21.051 0.800003 23.2001 2.94904 23.2001 5.60002C23.1972 8.24981 21.0498 10.3972 18.4001 10.4Z"
                            fill="white"
                          />
                          <path
                            d="M20.1162 3.71775L17.199 6.63496L16.6818 6.11776C16.5248 5.96617 16.2754 5.96834 16.1211 6.12263C15.9668 6.27693 15.9646 6.52641 16.1162 6.68336L16.9162 7.48337C17.0724 7.63952 17.3256 7.63952 17.4818 7.48337L20.6818 4.28336C20.8334 4.1264 20.8312 3.87692 20.6769 3.72263C20.5226 3.56833 20.2732 3.56616 20.1162 3.71775Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Leads
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "quotes"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={LIST_QUOTES}
                    >
                      <span class="nav-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 2V8H20"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16 13H8"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16 17H8"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 9H9H8"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Quotes
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "contracts"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={LIST_CONTRACTS}
                    >
                      <span class="nav-icon">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.19995 13.8H12.4V14.6H7.19995V13.8Z" fill="white" />
                          <path d="M7.19995 11.7999H11.6V12.5999H7.19995V11.7999Z" fill="white" />
                          <path d="M22.8 3H18.4V1.4C18.4 1.1791 18.2209 1 18 1H6C5.7791 1 5.6 1.1791 5.6 1.4V3H1.2C0.537305 3 0 3.5373 0 4.2V16.2C0 16.8627 0.537305 17.4 1.2 17.4H10V19.8H6.4C6.1791 19.8 6 19.9791 6 20.2V22.2H5.2V23H18.8V22.2H18V20.2C18 19.9791 17.8209 19.8 17.6 19.8H14V17.4H22.8C23.4627 17.4 24 16.8627 24 16.2V4.2C24 3.5373 23.4627 3 22.8 3ZM22.8 3.8C23.0209 3.8 23.2 3.9791 23.2 4.2V14.2H18.4V9.69199L20.3393 7.38125C20.8248 6.80039 20.8367 5.95879 20.368 5.36426C19.8992 4.76992 19.0781 4.58535 18.4 4.92207V3.8H22.8ZM15.3557 12.0756L14.1301 11.0473L17.7301 6.75762L18.9557 7.78594L15.3557 12.0756ZM13.8504 11.8568L14.6068 12.4916L13.7 12.8045L13.8504 11.8568ZM19.7264 6.8668L19.4691 7.17285L18.2436 6.14434L18.5008 5.83809C18.785 5.49961 19.2896 5.45547 19.6279 5.73965C19.9664 6.02363 20.0105 6.52832 19.7264 6.8668ZM6.4 1.8H17.6V5.66719L13.26 10.84C13.2135 10.8955 13.1826 10.9625 13.1713 11.034L12.8049 13.3377C12.783 13.4762 12.8355 13.616 12.9432 13.7063C13.0508 13.7963 13.1977 13.8234 13.3305 13.7775L15.5352 13.0176C15.6037 12.9943 15.6646 12.9529 15.7111 12.8977L17.6 10.6455V15.4H6.4V1.8ZM1.2 3.8H5.6V14.2H0.8V4.2C0.8 3.9791 0.979102 3.8 1.2 3.8ZM17.2 22.2H6.8V20.6H17.2V22.2ZM13.2 19.8H10.8V17.4H13.2V19.8ZM22.8 16.6H1.2C0.979102 16.6 0.8 16.4209 0.8 16.2V15H5.6V15.8C5.6 16.0209 5.7791 16.2 6 16.2H18C18.2209 16.2 18.4 16.0209 18.4 15.8V15H23.2V16.2C23.2 16.4209 23.0209 16.6 22.8 16.6Z" fill="white" />
                          <path d="M21.6 15.4H22.4V16.2H21.6V15.4Z" fill="white" />
                          <path d="M7.19995 2.59998H8.79995V3.39998H7.19995V2.59998Z" fill="white" />
                          <path d="M9.59998 2.59998H16.4V3.39998H9.59998V2.59998Z" fill="white" />
                          <path d="M7.19995 4.59998H16.4V5.39997H7.19995V4.59998Z" fill="white" />
                          <path d="M7.19995 6.59998H7.99995V7.39998H7.19995V6.59998Z" fill="white" />
                          <path d="M8.79993 6.59998H9.59993V7.39998H8.79993V6.59998Z" fill="white" />
                          <path d="M10.4 6.59998H14.8V7.39998H10.4V6.59998Z" fill="white" />
                          <path d="M7.19995 8.59998H9.59995V9.39998H7.19995V8.59998Z" fill="white" />
                          <path d="M10.4 8.59998H13.2V9.39998H10.4V8.59998Z" fill="white" />
                        </svg>
                      </span>
                      Contracts
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "invoices"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={LIST_INVOICES}
                    >
                      <span class="nav-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_11447_303)">
                            <path
                              d="M18.389 3.97886C18.3994 3.97962 18.4099 3.98038 18.4205 3.98038C18.4385 3.98038 18.4566 3.97886 18.4742 3.97639C18.9468 3.98607 19.325 4.37149 19.3256 4.84406C19.3256 5.05879 19.4997 5.2329 19.7144 5.2329C19.9291 5.2329 20.1032 5.05879 20.1032 4.84406C20.1023 4.07113 19.5642 3.40244 18.8093 3.23612V2.86247C18.8093 2.64774 18.6352 2.47363 18.4205 2.47363C18.2058 2.47363 18.0317 2.64774 18.0317 2.86247V3.25378C17.2335 3.46718 16.7171 4.23935 16.8247 5.0586C16.9324 5.87786 17.6307 6.49035 18.4569 6.49035C18.9367 6.49035 19.3256 6.87938 19.3256 7.35916C19.3256 7.83875 18.9367 8.22778 18.4569 8.22778C17.9772 8.22778 17.5883 7.83875 17.5883 7.35916C17.5883 7.14443 17.4142 6.97032 17.1995 6.97032C16.9848 6.97032 16.8107 7.14443 16.8107 7.35916C16.8116 8.10418 17.3121 8.75597 18.0317 8.94944V9.37549C18.0317 9.59023 18.2058 9.76433 18.4205 9.76433C18.6352 9.76433 18.8093 9.59023 18.8093 9.37549V8.96729C19.6305 8.78749 20.1843 8.01779 20.0937 7.18202C20.0034 6.34625 19.2977 5.71287 18.4569 5.71306C17.9905 5.71325 17.6071 5.34491 17.5887 4.8788C17.5705 4.41269 17.9238 4.01531 18.389 3.97905V3.97886Z"
                              fill="white"
                            />
                            <path
                              d="M18.4798 0.572266C16.0878 0.572266 14.0464 2.13312 13.2816 4.22312H3.63073C3.41694 4.22312 3.24341 4.41583 3.24189 4.62942L3.15 18.2699H0.39909C0.184356 18.2699 0 18.4254 0 18.6405V20.3714C0.00493639 22.001 1.2884 23.3397 2.91628 23.4134V23.422H14.9216V23.4106C15.0188 23.4142 15.0589 23.422 15.1204 23.422H15.1288C16.8136 23.4193 18.1781 22.0527 18.1781 20.3678V11.616C18.2753 11.6213 18.3783 11.6241 18.4789 11.6241C21.5229 11.6241 24.0001 9.14225 24.0001 6.09801C24.0001 3.05395 21.5241 0.572266 18.4798 0.572266ZM3.06418 22.6443H3.06171C1.80388 22.6426 0.78337 21.6255 0.777674 20.3675V19.0475H12.054V20.3619C12.0545 21.1734 12.3786 21.9513 12.9543 22.5233C12.9951 22.5642 13.0458 22.5957 13.0885 22.6443H3.06418ZM17.4005 20.3675C17.4007 21.6232 16.3839 22.6418 15.1284 22.6443H15.121C13.8603 22.642 12.8379 21.6228 12.8316 20.3623V18.6405C12.8349 18.541 12.7969 18.4447 12.7264 18.3745C12.656 18.3042 12.5597 18.2664 12.4604 18.2699H3.92767L4.01691 5.00079H13.0657C12.9968 5.35678 12.9611 5.71828 12.9592 6.08073C12.9585 6.8472 13.1183 7.60513 13.4286 8.30591H9.75586C9.54094 8.30591 9.36703 8.48001 9.36703 8.69475C9.36703 8.90948 9.54094 9.08358 9.75586 9.08358H13.8504C14.6526 10.345 15.9349 11.2245 17.4005 11.5188V20.3675ZM18.4798 10.8466C15.8605 10.8466 13.7369 8.72323 13.7371 6.1037C13.7371 3.48418 15.8605 1.36076 18.48 1.36076C21.0994 1.36076 23.2228 3.48437 23.2228 6.1037C23.2199 8.7219 21.0982 10.8438 18.4798 10.8466Z"
                              fill="white"
                            />
                            <path
                              d="M7.26017 10.8945C6.57382 10.8945 6.01562 11.4351 6.01562 12.0996C6.01562 12.7641 6.57382 13.3046 7.26017 13.3046C7.94652 13.3046 8.5049 12.7641 8.5049 12.0996C8.5049 11.4351 7.94652 10.8945 7.26017 10.8945ZM7.26017 12.527C7.00253 12.527 6.7933 12.3352 6.7933 12.0996C6.7933 11.8638 7.00272 11.6722 7.26017 11.6722C7.51762 11.6722 7.72723 11.864 7.72723 12.0996C7.72723 12.3352 7.51762 12.527 7.26017 12.527Z"
                              fill="white"
                            />
                            <path
                              d="M9.36719 12.0978C9.36719 12.3126 9.5411 12.4867 9.75602 12.4867H14.94C15.1547 12.4867 15.3288 12.3126 15.3288 12.0978C15.3288 11.8831 15.1547 11.709 14.94 11.709H9.75602C9.5411 11.709 9.36719 11.8831 9.36719 12.0978Z"
                              fill="white"
                            />
                            <path
                              d="M7.26017 7.51465C6.57382 7.51465 6.01562 8.05538 6.01562 8.71989C6.01562 9.38441 6.57382 9.92495 7.26017 9.92495C7.94652 9.92495 8.5049 9.38441 8.5049 8.71989C8.5049 8.05538 7.94652 7.51465 7.26017 7.51465ZM7.26017 9.14727C7.00253 9.14727 6.7933 8.95551 6.7933 8.71989C6.7933 8.48427 7.00272 8.29232 7.26017 8.29232C7.51762 8.29232 7.72723 8.48408 7.72723 8.71989C7.72723 8.95551 7.51762 9.14746 7.26017 9.14746V9.14727Z"
                              fill="white"
                            />
                            <path
                              d="M7.26017 14.2734C6.57382 14.2734 6.01562 14.814 6.01562 15.4785C6.01562 16.143 6.57382 16.6835 7.26017 16.6835C7.94652 16.6835 8.5049 16.143 8.5049 15.4785C8.5049 14.814 7.94652 14.2734 7.26017 14.2734ZM7.26017 15.9059C7.00253 15.9059 6.7933 15.7143 6.7933 15.4785C6.7933 15.2429 7.00272 15.0511 7.26017 15.0511C7.51762 15.0511 7.72723 15.2429 7.72723 15.4785C7.72723 15.7141 7.51762 15.9059 7.26017 15.9059Z"
                              fill="white"
                            />
                            <path
                              d="M14.94 15.1104H9.75602C9.5411 15.1104 9.36719 15.2845 9.36719 15.4992C9.36719 15.7139 9.5411 15.888 9.75602 15.888H14.94C15.1547 15.888 15.3288 15.7139 15.3288 15.4992C15.3288 15.2845 15.1547 15.1104 14.94 15.1104Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_11447_303">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Invoices
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "bookings"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={LIST_BOOKINGS}
                    >
                      <span class="nav-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M8 2V5"
                            stroke="white"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16 2V5"
                            stroke="white"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.5 9.08997H20.5"
                            stroke="white"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                            stroke="white"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.6947 13.7H15.7037"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.6947 16.7H15.7037"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9955 13.7H12.0045"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9955 16.7H12.0045"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.29431 13.7H8.30329"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.29431 16.7H8.30329"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Bookings
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "expenses"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={VIEW_EXPENSES}
                    >
                      <span class="nav-icon">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 512 512"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Receipt">
                            <path
                              d="m273.5488 234.5911h-58.75a8 8 0 0 1 0-16h58.75a15.6484 15.6484 0 1 0 0-31.2968h-35.1015a31.6485 31.6485 0 1 1 0-63.2969h58.7539a8 8 0 0 1 0 16h-58.7539a15.6485 15.6485 0 1 0 0 31.2969h35.1015a31.6484 31.6484 0 1 1 0 63.2968z"
                              fill="white"
                            />
                            <path
                              d="m255.998 255.2318a7.998 7.998 0 0 1 -8-8v-135.875a8 8 0 0 1 16 0v135.875a7.9979 7.9979 0 0 1 -8 8z"
                              fill="white"
                            />
                            <path
                              d="m413.5215 463.9974a7.9983 7.9983 0 0 1 -5.6563-2.3438l-25.8476-25.8436-25.8476 25.8436a7.9971 7.9971 0 0 1 -11.3125 0l-25.8475-25.8436-25.8477 25.8437a7.9971 7.9971 0 0 1 -11.3125 0l-25.8478-25.8437-25.852 25.8436a7.9971 7.9971 0 0 1 -11.3125 0l-25.8475-25.8436-25.8476 25.8437a7.9971 7.9971 0 0 1 -11.3125 0l-25.8475-25.8437-25.8476 25.8437a7.9993 7.9993 0 0 1 -13.6563-5.6562v-400a7.9979 7.9979 0 0 1 8-8h315.043a7.9979 7.9979 0 0 1 8 8v400a8 8 0 0 1 -8 8zm-220.5313-47.5a7.9741 7.9741 0 0 1 5.6563 2.3437l25.8476 25.8438 25.8516-25.8438a7.9971 7.9971 0 0 1 11.3125 0l25.8477 25.8438 25.8476-25.8438a7.9971 7.9971 0 0 1 11.3125 0l25.8477 25.8438 25.8476-25.8438a7.9971 7.9971 0 0 1 11.3125 0l17.8477 17.8438v-372.6875h-299.043v372.6875l17.8477-17.8438a7.9971 7.9971 0 0 1 11.3125 0l25.8476 25.8438 25.8477-25.8438a7.974 7.974 0 0 1 5.656-2.3437z"
                              fill="white"
                            />
                            <path
                              d="m349.5215 316.9974h-187.043a8 8 0 0 1 0-16h187.043a8 8 0 0 1 0 16z"
                              fill="white"
                            />
                            <path
                              d="m349.5215 369.3333h-187.043a8 8 0 1 1 0-16h187.043a8 8 0 1 1 0 16z"
                              fill="white"
                            />
                          </g>
                        </svg>
                      </span>
                      Expenses
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "contacts"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={LIST_CONTACTS}
                    >
                      <span class="nav-icon">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 75 75"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M59.8212 2.27686H20.262C15.6399 2.27895 11.893 6.02529 11.8915 10.6474V14.2635H7.95731C7.03237 14.2635 6.2832 15.0131 6.2832 15.9376C6.2832 16.862 7.03237 17.6117 7.95731 17.6117H11.8742V28.5938H7.95731C7.03237 28.5938 6.2832 29.343 6.2832 30.2679C6.2832 31.1923 7.03237 31.942 7.95731 31.942H11.8742V42.9242H7.95731C7.03237 42.9242 6.2832 43.6739 6.2832 44.5983C6.2832 45.5232 7.03237 46.2724 7.95731 46.2724H11.8742V57.2545H7.95731C7.03237 57.2545 6.2832 58.0042 6.2832 58.9286C6.2832 59.8531 7.03237 60.6027 7.95731 60.6027H11.8742V64.2188C11.8763 68.8409 15.6226 72.5873 20.2447 72.5894H59.8039C64.426 72.5873 68.1734 68.8409 68.1744 64.2188V10.6474C68.1812 6.02842 64.4401 2.27999 59.8212 2.27686ZM64.8435 64.2188C64.8351 66.9889 62.5913 69.2333 59.8212 69.2411H20.262C17.4919 69.2333 15.2475 66.9889 15.2397 64.2188V60.6027H19.1566C20.0815 60.6027 20.8307 59.8531 20.8307 58.9286C20.8307 58.0042 20.0815 57.2545 19.1566 57.2545H15.2397V46.2724H19.1566C20.0815 46.2724 20.8307 45.5232 20.8307 44.5983C20.8307 43.6739 20.0815 42.9242 19.1566 42.9242H15.2397V31.942H19.1566C20.0815 31.942 20.8307 31.1923 20.8307 30.2679C20.8307 29.343 20.0815 28.5938 19.1566 28.5938H15.2397V17.6117H19.1566C20.0815 17.6117 20.8307 16.862 20.8307 15.9376C20.8307 15.0131 20.0815 14.2635 19.1566 14.2635H15.2397V10.6474C15.2475 7.87727 17.4919 5.63292 20.262 5.62507H59.8212C62.5913 5.63292 64.8351 7.87727 64.8435 10.6474V64.2188Z"
                            fill="white"
                          />
                          <path
                            d="M40.0335 36.9473C44.5175 36.9473 48.1529 33.3124 48.1529 28.8279C48.1529 24.3434 44.5175 20.7085 40.0335 20.7085C35.549 20.7085 31.9141 24.3434 31.9141 28.8279C31.9182 33.3103 35.5505 36.9432 40.0335 36.9473ZM40.0335 24.04C42.6681 24.04 44.8047 26.1765 44.8047 28.8112C44.8047 31.4458 42.6681 33.5824 40.0335 33.5824C37.3983 33.5824 35.2623 31.4458 35.2623 28.8112C35.267 26.1786 37.3999 24.0447 40.0335 24.04Z"
                            fill="white"
                          />
                          <path
                            d="M40.0337 39.6431C33.0694 39.6431 27.6113 45.2848 27.6113 52.5002C27.6139 53.4236 28.3621 54.1717 29.2854 54.1743H50.7815C51.7049 54.1717 52.453 53.4236 52.4556 52.5002C52.4556 45.2848 46.9975 39.6431 40.0337 39.6431ZM31.0935 50.8261C31.8139 46.2558 35.4132 42.9913 40.0337 42.9913C44.6537 42.9913 48.2703 46.2558 48.9735 50.8261H31.0935Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Contacts
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "email"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={EMAIL_AUTH}
                    >
                      <span class="nav-icon">
                        <svg
                          width="16"
                          height="16"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 128 128"
                        >
                          <path
                            d="M123.25 24.192c0-.018 0-.034-.005-.052s-.007-.063-.009-.094a1.734 1.734 0 0 0-.083-.408c-.006-.018 0-.037-.011-.055s-.01-.015-.013-.023a1.734 1.734 0 0 0-.227-.407c-.021-.028-.043-.053-.066-.08a1.755 1.755 0 0 0-.31-.294c-.012-.009-.022-.02-.034-.028a1.744 1.744 0 0 0-.414-.2c-.034-.012-.068-.022-.1-.032a1.733 1.733 0 0 0-.474-.073H6.5a1.733 1.733 0 0 0-.474.073c-.035.01-.068.02-.1.032a1.744 1.744 0 0 0-.414.2c-.012.008-.022.019-.034.028a1.755 1.755 0 0 0-.31.294c-.022.027-.045.052-.066.08a1.734 1.734 0 0 0-.227.407c0 .008-.01.015-.013.023s-.005.037-.011.055a1.734 1.734 0 0 0-.083.408c0 .032-.009.063-.009.094s-.005.034-.005.052v79.615c0 .023.006.045.007.068a1.737 1.737 0 0 0 .019.188c.008.051.015.1.027.152a1.74 1.74 0 0 0 .056.179c.017.047.033.094.054.139a1.729 1.729 0 0 0 .093.172c.024.04.048.081.075.119a1.743 1.743 0 0 0 .125.152c.033.036.066.072.1.106.021.019.037.042.059.061s.036.017.052.03a1.736 1.736 0 0 0 .452.263c.035.014.071.022.107.033a1.732 1.732 0 0 0 .488.085c.012 0 .023.006.035.006H121.501c.012 0 .023-.006.034-.006a1.732 1.732 0 0 0 .489-.085c.035-.011.07-.019.1-.033a1.736 1.736 0 0 0 .453-.263c.016-.013.036-.017.052-.03s.038-.042.059-.061c.036-.034.069-.069.1-.106a1.743 1.743 0 0 0 .125-.152c.027-.038.051-.078.075-.119a1.729 1.729 0 0 0 .093-.172c.021-.045.037-.092.054-.139a1.74 1.74 0 0 0 .056-.179c.012-.05.019-.1.027-.152a1.737 1.737 0 0 0 .019-.188c0-.023.007-.045.007-.068zM45.8 60.316l17.058 14.677a1.751 1.751 0 0 0 2.283 0L82.2 60.316l35.512 41.741H10.289zM8.25 99.052V28.007l34.9 30.026zm76.6-41.019 34.9-30.026v71.045zm31.931-32.091L81.276 56.493c-.006.005-.014.008-.02.014l-.019.02L64 71.358 46.763 56.527l-.019-.02-.02-.014-35.507-30.551z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      {/* Email <span className="highlighted-note">(New)</span> */}
                      Email
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={
                        props && props.getMainRoute === "profile"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={VIEW_PROFILE}
                    >
                      <span class="nav-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.5 2H20V22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2V2Z"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.7464 13.7145V12.7462C15.7464 12.2326 15.5424 11.74 15.1792 11.3768C14.816 11.0136 14.3234 10.8096 13.8098 10.8096H9.9366C9.42299 10.8096 8.9304 11.0136 8.56722 11.3768C8.20403 11.74 8 12.2326 8 12.7462V13.7145"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.8722 8.87321C12.9417 8.87321 13.8088 8.00616 13.8088 6.9366C13.8088 5.86705 12.9417 5 11.8722 5C10.8026 5 9.93555 5.86705 9.93555 6.9366C9.93555 8.00616 10.8026 8.87321 11.8722 8.87321Z"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Profile
                    </NavLink>
                  </li>
                </ul >
              </nav >
            </div >
          </div >
        </>
      )}
    </>
  );
};
