import { put, takeLatest, all } from 'redux-saga/effects';
import {
    createBusinessFormResponse, SUCCESS_CREATE_BUSINESS_FORM, ERROR_CREATE_BUSINESS_FORM, CREATE_BUSINESS_FORM,
    viewPublicFormResponse, SUCCESS_VIEW_PUBLIC_FORM, ERROR_VIEW_PUBLIC_FORM, VIEW_PUBLIC_FORM,
    viewBusinessFormResponse, SUCCESS_VIEW_BUSINESS_FORM, ERROR_VIEW_BUSINESS_FORM, VIEW_BUSINESS_FORM,
    publishBusinessFormResponse, SUCCESS_PUBLISH_BUSINESS_FORM, ERROR_PUBLISH_BUSINESS_FORM, PUBLISH_BUSINESS_FORM,
} from './businessForm.action';
import {
    createBusinessApi,
    publishBusinessApi,
    getBusinessDetailApi,
    getBusinessListApi
} from '../../../api/index';
import { successNotification, errorNotification } from '../../common/notification-alert';
import _ from 'lodash';

// Create Business Form
function* createBusinessFormRequest({ data }) {
    try {
        const response = yield createBusinessApi(data);
        if (response.success && _.has(response, 'data')) {
            successNotification(response.data.message);
            yield put(createBusinessFormResponse(SUCCESS_CREATE_BUSINESS_FORM, response.data));
        } else {
            errorNotification(response.error_message || 'Failed to create business form');
            yield put(createBusinessFormResponse(ERROR_CREATE_BUSINESS_FORM, response.data));
        }
    } catch (error) {
        errorNotification(error.message);
        yield put(createBusinessFormResponse(ERROR_CREATE_BUSINESS_FORM, error.message));
    }
}

export function* createBusinessFormWatcher() {
    yield takeLatest(CREATE_BUSINESS_FORM, createBusinessFormRequest);
}

// View Public Form
function* viewPublicFormRequest({ data }) {
    try {
        const response = yield getBusinessListApi(data);
        if (response.success && _.has(response, 'data')) {
            yield put(viewPublicFormResponse(SUCCESS_VIEW_PUBLIC_FORM, response.data));
        } else {
            yield put(viewPublicFormResponse(ERROR_VIEW_PUBLIC_FORM, response.data));
        }
    } catch (error) {
        yield put(viewPublicFormResponse(ERROR_VIEW_PUBLIC_FORM, error.message));
    }
}

export function* viewPublicFormWatcher() {
    yield takeLatest(VIEW_PUBLIC_FORM, viewPublicFormRequest);
}

// View Business Form
function* viewBusinessFormRequest({ data }) {
    try {
        const response = yield getBusinessDetailApi(data);
        if (response.success && _.has(response, 'data')) {
            yield put(viewBusinessFormResponse(SUCCESS_VIEW_BUSINESS_FORM, response.data));
        } else {
            yield put(viewBusinessFormResponse(ERROR_VIEW_BUSINESS_FORM, response.data));
        }
    } catch (error) {
        yield put(viewBusinessFormResponse(ERROR_VIEW_BUSINESS_FORM, error.message));
    }
}

export function* viewBusinessFormWatcher() {
    yield takeLatest(VIEW_BUSINESS_FORM, viewBusinessFormRequest);
}

// Publish Business Form
function* publishBusinessFormRequest({ data }) {
    try {
        const response = yield publishBusinessApi(data);
        if (response.success && _.has(response, 'data')) {
            successNotification(response.data.message);
            yield put(publishBusinessFormResponse(SUCCESS_PUBLISH_BUSINESS_FORM, response.data));
        } else {
            errorNotification(response.error_message || 'Failed to publish business form');
            yield put(publishBusinessFormResponse(ERROR_PUBLISH_BUSINESS_FORM, response.data));
        }
    } catch (error) {
        errorNotification(error.message);
        yield put(publishBusinessFormResponse(ERROR_PUBLISH_BUSINESS_FORM, error.message));
    }
}

export function* publishBusinessFormWatcher() {
    yield takeLatest(PUBLISH_BUSINESS_FORM, publishBusinessFormRequest);
}

// Export all sagas for business form
export default function* businessFormSaga() {
    yield all([
        createBusinessFormWatcher(),
        viewPublicFormWatcher(),
        viewBusinessFormWatcher(),
        publishBusinessFormWatcher(),
    ]);
}