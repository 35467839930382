import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import Modal from "react-bootstrap/Modal";
import { constants, selectStyle } from "../../../../common/constants";
import { usePrevious, setImagePath} from '../../../../common/custom';
import { validateInputs } from '../../../../common/validation';
import _ from 'lodash';

import Select from "react-select";
import makeAnimated from "react-select/animated";


export const ShowPaymentOptionMessage = props => {

    return (
        <Modal show={props.showModel}  className="" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                    Online Payment Not Available
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="paypal-text-msg1 show-payment-option-model"> <strong>Important:</strong> Your customers can receive, review and accept invoices online. However, online payments in your country is not available at this time.</div>
                </Modal.Body>
                <Modal.Footer className="button-align-center">
                    <button type="button"  className="btn btn-primary" onClick={props.closeMessagePopup} >Ok</button>
                </Modal.Footer>
            </Modal>
    );
}

export const ShowPaymentOptionMessageModel =  withRouter(ShowPaymentOptionMessage);