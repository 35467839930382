import React from 'react';
import { Link } from 'react-router-dom';
import REPLY_ICON from "../../../../../assets/images/reply_FILL0_wght400_GRAD0_opsz24.svg";
import REPLY_ALL_ICON from "../../../../../assets/images/reply_all_FILL0_wght400_GRAD0_opsz24.svg";
import FORWARD_ICON from "../../../../../assets/images/forward_FILL0_wght400_GRAD0_opsz24.svg";
import DELETE_ICON from "../../../../../assets/images/delete_FILL0_wght400_GRAD0_opsz24.svg";
import MARK_ICON from "../../../../../assets/images/sell_FILL0_wght400_GRAD0_opsz24.svg";
import USER_ICON from "../../../../../assets/images/image_avatar.png";
import DOWNLOAD_ICON from "../../../../../assets/images/download_FILL0_wght400_GRAD0_opsz48.svg";
import MORE_ICON from "../../../../../assets/images/more_horiz_FILL0_wght400_GRAD0_opsz24.svg";
import UNREAD_STAR_ICON from "../../../../../assets/images/starred.svg";
import STAR_FILLED_ICON from "../../../../../assets/images/starred_filled.svg";
import UNREAD_IMPORTANT_ICON from "../../../../../assets/images/label_important.svg";
import IMPORTANT_FILLED_ICON from "../../../../../assets/images/label-filled.svg";
import { ConvertTimeFromUTCToIST } from '../../../../../common/constants';
import { decodeToString, setImagePath, validateFileSize } from '../../../../../common/custom';
import { validateInputs } from '../../../../../common/validation';
import { EMAIL_DRAFT, REPLY_TO_EMAIL } from '../../../../../routing/routeContants';
import CustomIframe from '../../iframe/CustomIframe';
import _ from 'lodash';


export function GoogleViewEmail(props) {
    const {
        uniqueViewKey,
        getActiveLabel,
        getGmailMessageByIdData,
        getGmailMessageAttachmentData,
        attachmentData,
        createLeadState,
        handleaAtoB,
        setCreateLeadState,
        handleUnmarkStarred,
        handleMarkStarred,
        handleUnmarkImportant,
        handleMarkImportant,
        handleDeleteMessage,
        handleMarkMessageAsImp,
    } = props
    return (
        <div className="col-sm-10">
            <div className="bg-white boxShadow h-100 p-3">
                {getGmailMessageByIdData &&
                    getGmailMessageByIdData?.messages &&
                    getGmailMessageByIdData?.messages?.length > 0 ? (
                    _.map(
                        getGmailMessageByIdData.messages,
                        (msg, msgI) => {
                            const hasStarred =
                                msg &&
                                msg.labelIds &&
                                msg.labelIds.length &&
                                msg.labelIds.includes("STARRED");
                            const isImportant =
                                msg &&
                                msg.labelIds &&
                                msg.labelIds.length &&
                                msg.labelIds.includes("IMPORTANT");
                            const Label =
                                msg &&
                                msg.labelIds &&
                                msg.labelIds?.length &&
                                msg.labelIds.includes("DRAFT");
                            const From =
                                msg &&
                                msg.payload &&
                                msg.payload?.headers?.length &&
                                msg.payload?.headers.find(
                                    (obj) => obj.name === "From"
                                );
                            const To =
                                msg &&
                                msg.payload &&
                                msg.payload?.headers?.length &&
                                msg.payload?.headers.find(
                                    (obj) => obj.name === "To"
                                );
                            const Cc =
                                msg &&
                                msg.payload &&
                                msg.payload?.headers?.length &&
                                msg.payload?.headers.find(
                                    (obj) => obj.name === "Cc"
                                );
                            const Bcc =
                                msg &&
                                msg.payload &&
                                msg.payload?.headers?.length &&
                                msg.payload?.headers.find(
                                    (obj) => obj.name === "Bcc"
                                );
                            const EmailDate =
                                msg &&
                                msg.payload &&
                                msg.payload.headers.length &&
                                msg.payload.headers.find(
                                    (obj) => obj.name === "Date"
                                );
                            const Subject =
                                msg &&
                                msg.payload &&
                                msg.payload?.headers?.length &&
                                msg.payload?.headers.find(
                                    (obj) => obj.name === "Subject"
                                );
                            const HTMLBodyPart =
                                msg &&
                                    msg.payload &&
                                    msg.payload?.body &&
                                    msg.payload?.body?.size > 0
                                    ? msg.payload
                                    : msg &&
                                        msg.payload &&
                                        msg.payload?.parts?.length &&
                                        msg.payload?.parts.find(
                                            (obj) =>
                                                obj.mimeType === "multipart/related"
                                        )
                                        ? msg.payload &&
                                        msg.payload?.parts?.length &&
                                        msg.payload?.parts.find(
                                            (obj) =>
                                                obj.mimeType === "multipart/related"
                                        )
                                        : msg &&
                                            msg.payload &&
                                            msg.payload?.parts?.length &&
                                            msg.payload?.parts.find(
                                                (obj) =>
                                                    obj.mimeType === "multipart/mixed"
                                            )
                                            ? msg.payload &&
                                            msg.payload?.parts?.length &&
                                            msg.payload?.parts.find(
                                                (obj) =>
                                                    obj.mimeType === "multipart/mixed"
                                            )
                                            : msg &&
                                                msg.payload &&
                                                msg.payload?.parts?.length &&
                                                msg.payload?.parts.find(
                                                    (obj) =>
                                                        obj.mimeType ===
                                                        "multipart/alternative"
                                                )
                                                ? msg &&
                                                msg.payload &&
                                                msg.payload?.parts?.length &&
                                                msg.payload?.parts.find(
                                                    (obj) =>
                                                        obj.mimeType ===
                                                        "multipart/alternative"
                                                )
                                                : msg &&
                                                    msg.payload &&
                                                    msg.payload?.parts?.length &&
                                                    msg.payload?.parts.find(
                                                        (obj) =>
                                                            obj.mimeType === "text/html"
                                                    )
                                                    ? msg.payload &&
                                                    msg.payload?.parts?.length &&
                                                    msg.payload?.parts.find(
                                                        (obj) =>
                                                            obj.mimeType === "text/html"
                                                    )
                                                    : msg &&
                                                    msg.payload &&
                                                    msg.payload?.parts?.length &&
                                                    msg.payload?.parts.find(
                                                        (obj) =>
                                                            obj.mimeType === "text/plain"
                                                    );
                            const HtmlBodyDataObj =
                                HTMLBodyPart &&
                                    HTMLBodyPart.mimeType ===
                                    "multipart/related"
                                    ? HTMLBodyPart &&
                                    HTMLBodyPart?.parts.find(
                                        (obj) =>
                                            obj.mimeType === "text/html"
                                    )?.body
                                    : HTMLBodyPart &&
                                        HTMLBodyPart.mimeType ===
                                        "multipart/alternative"
                                        ? HTMLBodyPart &&
                                        HTMLBodyPart?.parts.find(
                                            (obj) =>
                                                obj.mimeType === "text/html"
                                        )?.body
                                        : HTMLBodyPart &&
                                        HTMLBodyPart?.body &&
                                        HTMLBodyPart?.body?.size > 0 &&
                                        HTMLBodyPart?.body;
                            const HtmlBodyData =
                                HtmlBodyDataObj &&
                                HtmlBodyDataObj.size > 0 &&
                                decodeToString(HtmlBodyDataObj.data);
                            const ModifyHtmlBody = HtmlBodyData && HtmlBodyData.replace(/href/g, "target='_blank' href");
                            const hasAttachmentId =
                                msg &&
                                msg.payload &&
                                msg.payload?.parts?.length &&
                                msg.payload?.parts.filter((data) =>
                                    _.has(data.body, "attachmentId")
                                );
                            let hasAttachmentDataFetched = getGmailMessageAttachmentData && getGmailMessageAttachmentData.length > 0 && _.filter(
                                getGmailMessageAttachmentData,
                                (x) => {
                                    return hasAttachmentId && hasAttachmentId.map((dd) => {
                                        return x.size === dd?.body?.size
                                    })
                                }
                            );
                            hasAttachmentDataFetched = _.uniqBy(hasAttachmentDataFetched, "size")
                            const createLeadDescriptionBodyPart =
                                msg &&
                                    msg.payload &&
                                    msg.payload?.body &&
                                    msg.payload?.body?.size > 0
                                    ? msg.payload
                                    : msg &&
                                        msg.payload &&
                                        msg.payload?.parts?.length &&
                                        msg.payload?.parts.find(
                                            (obj) =>
                                                obj.mimeType === "multipart/related"
                                        )
                                        ? msg.payload &&
                                        msg.payload?.parts?.length &&
                                        msg.payload?.parts.find(
                                            (obj) =>
                                                obj.mimeType === "multipart/related"
                                        )
                                        : msg &&
                                            msg.payload &&
                                            msg.payload?.parts?.length &&
                                            msg.payload?.parts.find(
                                                (obj) =>
                                                    obj.mimeType === "multipart/mixed"
                                            )
                                            ? msg.payload &&
                                            msg.payload?.parts?.length &&
                                            msg.payload?.parts.find(
                                                (obj) =>
                                                    obj.mimeType === "multipart/mixed"
                                            )
                                            : msg &&
                                                msg.payload &&
                                                msg.payload?.parts?.length &&
                                                msg.payload?.parts.find(
                                                    (obj) =>
                                                        obj.mimeType ===
                                                        "multipart/alternative"
                                                )
                                                ? msg &&
                                                msg.payload &&
                                                msg.payload?.parts?.length &&
                                                msg.payload?.parts.find(
                                                    (obj) =>
                                                        obj.mimeType ===
                                                        "multipart/alternative"
                                                )
                                                : msg &&
                                                msg.payload &&
                                                msg.payload?.parts?.length &&
                                                msg.payload?.parts.find(
                                                    (obj) =>
                                                        obj.mimeType === "text/plain"
                                                );
                            const createLeadDescriptionBodyDataObj =
                                createLeadDescriptionBodyPart &&
                                    createLeadDescriptionBodyPart.mimeType ===
                                    "multipart/alternative"
                                    ? createLeadDescriptionBodyPart &&
                                    createLeadDescriptionBodyPart?.parts.find(
                                        (obj) =>
                                            obj.mimeType === "text/plain"
                                    )?.body
                                    : createLeadDescriptionBodyPart &&
                                    createLeadDescriptionBodyPart?.body &&
                                    createLeadDescriptionBodyPart?.body?.size > 0 &&
                                    createLeadDescriptionBodyPart?.body;
                            const LeadBodyDescription =
                                createLeadDescriptionBodyDataObj &&
                                createLeadDescriptionBodyDataObj.size > 0 &&
                                decodeToString(createLeadDescriptionBodyDataObj.data);
                            const splitFromValue = From?.value?.split(" ");
                            const firstNameForLead = splitFromValue.length > 0 && splitFromValue[0] && validateInputs("email", splitFromValue[0]) ? "" : splitFromValue[0];
                            const myEmailSubString = splitFromValue.length > 0 && validateInputs("email", splitFromValue[0]) ? splitFromValue[0] : From?.value.substring(
                                From?.value.indexOf("<") + 1,
                                From?.value.lastIndexOf(">")
                            );
                            const lastNameForLead = splitFromValue.length > 1 && splitFromValue[1]?.indexOf("<") === -1 ? splitFromValue[1] : "";
                            return (
                                <div
                                    className="email-thread-wrap"
                                    key={msgI}
                                    onLoad={() => { setCreateLeadState({ ...createLeadState, firstName: firstNameForLead || "", lastName: lastNameForLead || "", leadName: Subject?.value || "", leadDesc: LeadBodyDescription, email: myEmailSubString || "" }); }}
                                >
                                    <div className="compose-head">
                                        <div className="d-flex justify-content-between">
                                            <h4>{Subject && Subject.value}</h4>
                                            <div className="d-flex flex-wrap" style={{ columnGap: '8px' }}>
                                                {Label ? (
                                                    <Link
                                                        to={{
                                                            pathname:
                                                                EMAIL_DRAFT + msg.threadId,
                                                            state: {
                                                                messageDataState: msg,
                                                                attachmentDataState: hasAttachmentDataFetched,
                                                                key: "editDraft",
                                                                uniqueViewKey
                                                            },
                                                        }}
                                                        className="email-btn"
                                                    >
                                                        <img
                                                            src={setImagePath(REPLY_ICON)}
                                                            alt="REPLY_ICON"
                                                        />{" "}
                                                        <span className="btn-text">
                                                            Edit Draft
                                                        </span>
                                                    </Link>
                                                ) : (
                                                    <>
                                                        {hasStarred ? (
                                                            <button
                                                                className="action-btn"
                                                                onClick={() =>
                                                                    handleUnmarkStarred(
                                                                        getActiveLabel === "DRAFT"
                                                                            ? msg.draftId
                                                                            : msg.id
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={setImagePath(STAR_FILLED_ICON)}
                                                                    alt="STAR_ICON"
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="action-btn"
                                                                onClick={() =>
                                                                    handleMarkStarred(
                                                                        getActiveLabel === "DRAFT"
                                                                            ? msg.draftId
                                                                            : msg.id
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={setImagePath(UNREAD_STAR_ICON)}
                                                                    alt="STAR_ICON"
                                                                />
                                                            </button>
                                                        )}
                                                        {isImportant ? (
                                                            <button
                                                                className="action-btn"
                                                                onClick={() =>
                                                                    handleUnmarkImportant(
                                                                        getActiveLabel === "DRAFT"
                                                                            ? msg.draftId
                                                                            : msg.id
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={setImagePath(IMPORTANT_FILLED_ICON)}
                                                                    alt="IMPORTANT_ICON"
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="action-btn"
                                                                onClick={() =>
                                                                    handleMarkImportant(
                                                                        getActiveLabel === "DRAFT"
                                                                            ? msg.draftId
                                                                            : msg.id
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={setImagePath(UNREAD_IMPORTANT_ICON)}
                                                                    alt="IMPORTANT_ICON"
                                                                />
                                                            </button>
                                                        )}
                                                        <Link
                                                            to={{
                                                                pathname: REPLY_TO_EMAIL,
                                                                state: {
                                                                    messageDataState: msg,
                                                                    attachmentDataState:
                                                                        hasAttachmentDataFetched,
                                                                    key: "reply",
                                                                    uniqueViewKey
                                                                },
                                                            }}
                                                            className="email-btn"
                                                        >
                                                            <img
                                                                src={setImagePath(
                                                                    REPLY_ICON
                                                                )}
                                                                alt="REPLY_ICON"
                                                            />{" "}
                                                            <span className="btn-text">
                                                                Reply
                                                            </span>
                                                        </Link>
                                                        <div className="dropdown custom-dropdown">
                                                            <button
                                                                className="email-btn w-100 h-100"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <img
                                                                    src={setImagePath(
                                                                        MORE_ICON
                                                                    )}
                                                                    alt="MORE_ICON"
                                                                />{" "}
                                                                <span className="btn-text">
                                                                    More
                                                                </span>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-sm-right">
                                                                <Link
                                                                    to={{
                                                                        pathname:
                                                                            REPLY_TO_EMAIL,
                                                                        state: {
                                                                            messageDataState: msg,
                                                                            attachmentDataState:
                                                                                hasAttachmentDataFetched,
                                                                            key: "reply-all",
                                                                            uniqueViewKey
                                                                        },
                                                                    }}
                                                                    className="dropdown-item"
                                                                >
                                                                    <img
                                                                        src={setImagePath(
                                                                            REPLY_ALL_ICON
                                                                        )}
                                                                        alt="REPLY_ICON"
                                                                    />{" "}
                                                                    <span className="btn-text">
                                                                        Reply All
                                                                    </span>
                                                                </Link>
                                                                <Link
                                                                    to={{
                                                                        pathname:
                                                                            REPLY_TO_EMAIL,
                                                                        state: {
                                                                            messageDataState: msg,
                                                                            attachmentDataState:
                                                                                hasAttachmentDataFetched,
                                                                            key: "forward",
                                                                            uniqueViewKey
                                                                        },
                                                                    }}
                                                                    className="dropdown-item"
                                                                >
                                                                    <img
                                                                        src={setImagePath(
                                                                            FORWARD_ICON
                                                                        )}
                                                                        alt="FORWARD_ICON"
                                                                    />{" "}
                                                                    <span className="btn-text">
                                                                        Forward
                                                                    </span>
                                                                </Link>
                                                                {!(getActiveLabel === "TRASH") && <button
                                                                    className="dropdown-item d-none"
                                                                    onClick={
                                                                        handleDeleteMessage
                                                                    }
                                                                >
                                                                    <img
                                                                        src={setImagePath(
                                                                            DELETE_ICON
                                                                        )}
                                                                        alt="DELETE_ICON"
                                                                    />{" "}
                                                                    <span className="btn-text">
                                                                        {"Delete"}
                                                                    </span>
                                                                </button>}
                                                                {!["TRASH", "SPAM", "SENT"].includes(getActiveLabel) && <button
                                                                    className="dropdown-item"
                                                                    onClick={
                                                                        handleMarkMessageAsImp
                                                                    }
                                                                >
                                                                    <img
                                                                        src={setImagePath(
                                                                            MARK_ICON
                                                                        )}
                                                                        alt="MARK_ICON"
                                                                    />{" "}
                                                                    <span className="btn-text">
                                                                        Mark as Important
                                                                    </span>
                                                                </button>}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="comp-inner">
                                            <div className="avatar">
                                                <img
                                                    src={setImagePath(USER_ICON)}
                                                    alt="user_icon"
                                                />
                                            </div>
                                            <div className="email-from email_from">
                                                <ul>
                                                    <li>
                                                        <span>From</span>{" "}
                                                        <strong>
                                                            {" "}
                                                            {From && From.value}
                                                        </strong>{" "}
                                                    </li>
                                                    <li>
                                                        <span>To</span>{" "}
                                                        <strong>
                                                            {To && To.value}
                                                        </strong>{" "}
                                                    </li>
                                                    {Cc && Cc.value && (
                                                        <li>
                                                            <span>Cc</span>{" "}
                                                            <strong>
                                                                {Cc && Cc.value}
                                                            </strong>{" "}
                                                        </li>
                                                    )}
                                                    {Bcc && Bcc.value && (
                                                        <li>
                                                            <span>Bcc</span>{" "}
                                                            <strong>
                                                                {Bcc && Bcc.value}
                                                            </strong>{" "}
                                                        </li>
                                                    )}
                                                    <li>
                                                        <span>Date</span>{" "}
                                                        <strong>
                                                            {EmailDate
                                                                ? ConvertTimeFromUTCToIST(EmailDate?.value, "view")
                                                                : "-"}
                                                        </strong>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {HtmlBodyData && (
                                        <div className="border p-2">
                                            <CustomIframe>
                                                <div className="w-100"
                                                    dangerouslySetInnerHTML={{
                                                        __html: ModifyHtmlBody,
                                                    }}
                                                ></div>
                                            </CustomIframe>
                                            {attachmentData &&
                                                attachmentData.length > 0 &&
                                                getGmailMessageAttachmentData &&
                                                getGmailMessageAttachmentData.length > 0 &&
                                                hasAttachmentDataFetched && hasAttachmentDataFetched?.length > 0 &&
                                                <div className="border-top my-2 p-2">
                                                    {
                                                        hasAttachmentDataFetched.map((dataFetched, index) => {
                                                            return (
                                                                hasAttachmentId[index] ?
                                                                    <div className="attachment_box" key={index}>
                                                                        <div> <a
                                                                            href={handleaAtoB(
                                                                                decodeToString(
                                                                                    dataFetched && dataFetched?.data
                                                                                )
                                                                            )}
                                                                            id="link"
                                                                            download={
                                                                                (hasAttachmentId[index] &&
                                                                                    hasAttachmentId[index].filename) ||
                                                                                ""
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={setImagePath(
                                                                                    DOWNLOAD_ICON
                                                                                )}
                                                                                alt="down"
                                                                            />
                                                                        </a>
                                                                        </div>

                                                                        <div className="wk-pdf">
                                                                            <p title={(hasAttachmentId[index] &&
                                                                                hasAttachmentId[index].filename) ||
                                                                                ""}>

                                                                                {(hasAttachmentId[index] &&
                                                                                    hasAttachmentId[index].filename) ||
                                                                                    ""}

                                                                            </p>
                                                                            <span>
                                                                                {validateFileSize(
                                                                                    dataFetched && dataFetched?.size
                                                                                ) || ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    : "")
                                                        })}
                                                </div>}
                                        </div>
                                    )}
                                </div>
                            );
                        }
                    )
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
}