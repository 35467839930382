import React from 'react';
import { Link } from 'react-router-dom';
import REPLY_ICON from "../../../../../assets/images/reply_FILL0_wght400_GRAD0_opsz24.svg";
import REPLY_ALL_ICON from "../../../../../assets/images/reply_all_FILL0_wght400_GRAD0_opsz24.svg";
import FORWARD_ICON from "../../../../../assets/images/forward_FILL0_wght400_GRAD0_opsz24.svg";
import USER_ICON from "../../../../../assets/images/image_avatar.png";
import DOWNLOAD_ICON from "../../../../../assets/images/download_FILL0_wght400_GRAD0_opsz48.svg";
import MORE_ICON from "../../../../../assets/images/more_horiz_FILL0_wght400_GRAD0_opsz24.svg";
import { ConvertTimeFromUTCToIST } from '../../../../../common/constants';
import { decodeFromBase64, setImagePath, validateFileSize } from '../../../../../common/custom';
import { validateInputs } from '../../../../../common/validation';
import { REPLY_TO_EMAIL } from '../../../../../routing/routeContants';
import CustomIframe from '../../iframe/CustomIframe';

export function MicrosoftViewEmail(props) {
    const {
        userSocialData,
        uniqueViewKey,
        getOutlookMessageData,
        attachmentData,
        createLeadState,
        handleaAtoB,
        setCreateLeadState,
    } = props;
    const fromEmail = userSocialData?.microsoft?.social_email || "N/A";
    const toRecipients = getOutlookMessageData?.toRecipients?.length && getOutlookMessageData?.toRecipients?.map(obj => obj.emailAddress.address).join(",");
    const ccRecipients = getOutlookMessageData?.ccRecipients?.length && getOutlookMessageData?.ccRecipients?.map(obj => obj.emailAddress.address).join(",");
    const bccRecipients = getOutlookMessageData?.bccRecipients?.length && getOutlookMessageData?.bccRecipients?.map(obj => obj.emailAddress.address).join(",");
    const HtmlBodyData =
        getOutlookMessageData && getOutlookMessageData?.body?.content
    const ModifyHtmlBody = HtmlBodyData && HtmlBodyData.replace(/href/g, "target='_blank' href");
    const splitName = getOutlookMessageData?.from?.emailAddress?.name ? getOutlookMessageData?.from?.emailAddress?.name?.split(" ") : [];
    const firstName = splitName.length ? splitName[0] : "";
    const lastName = splitName.length > 1 ? splitName[1] : "";
    const fromAddress = getOutlookMessageData?.from?.emailAddress?.address || "";
    const validateFromAddress = fromAddress && validateInputs("email", fromAddress) ? fromAddress : "";
    return (
        <div className="col-sm-10">
            <div className="bg-white boxShadow h-100 p-3">
                <div
                    className="email-thread-wrap"
                    onLoad={() => { setCreateLeadState({ ...createLeadState, firstName: firstName, lastName: lastName, leadName: getOutlookMessageData?.subject || "", leadDesc: HtmlBodyData || "", email: validateFromAddress || "" }); }}
                >
                    <div className="compose-head">
                        <div className="d-flex justify-content-between">
                            <h4>{getOutlookMessageData?.subject || "N/A"}</h4>
                            <div className="d-flex flex-wrap" style={{ columnGap: '8px' }}>
                                {getOutlookMessageData?.isDraft 
                                ?
                                <Link
                                to={{
                                    pathname: REPLY_TO_EMAIL,
                                    state: {
                                        microsoftMessageState: getOutlookMessageData || {},
                                        microsoftAttachmentState:
                                            attachmentData,
                                        key: "editDraft",
                                        uniqueViewKey
                                    },
                                }}
                                className="email-btn"
                            >
                                <img
                                    src={setImagePath(
                                        REPLY_ICON
                                    )}
                                    alt="REPLY_ICON"
                                />{" "}
                                <span className="btn-text">
                                    Edit Draft
                                </span>
                            </Link>
                                : <>
                                <Link
                                    to={{
                                        pathname: REPLY_TO_EMAIL,
                                        state: {
                                            microsoftMessageState: getOutlookMessageData || {},
                                            microsoftAttachmentState:
                                                attachmentData,
                                            key: "reply",
                                            uniqueViewKey
                                        },
                                    }}
                                    className="email-btn"
                                >
                                    <img
                                        src={setImagePath(
                                            REPLY_ICON
                                        )}
                                        alt="REPLY_ICON"
                                    />{" "}
                                    <span className="btn-text">
                                        Reply
                                    </span>
                                </Link>
                                <div className="dropdown custom-dropdown">
                                    <button
                                        className="email-btn w-100 h-100"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            src={setImagePath(
                                                MORE_ICON
                                            )}
                                            alt="MORE_ICON"
                                        />{" "}
                                        <span className="btn-text">
                                            More
                                        </span>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-sm-right">
                                        <Link
                                            to={{
                                                pathname:
                                                    REPLY_TO_EMAIL,
                                                state: {
                                                    microsoftMessageState: getOutlookMessageData || {},
                                                    microsoftAttachmentState:
                                                        attachmentData,
                                                    key: "reply-all",
                                                    uniqueViewKey
                                                },
                                            }}
                                            className="dropdown-item"
                                        >
                                            <img
                                                src={setImagePath(
                                                    REPLY_ALL_ICON
                                                )}
                                                alt="REPLY_ICON"
                                            />{" "}
                                            <span className="btn-text">
                                                Reply All
                                            </span>
                                        </Link>
                                        <Link
                                            to={{
                                                pathname:
                                                    REPLY_TO_EMAIL,
                                                state: {
                                                    microsoftMessageState: getOutlookMessageData || {},
                                                    microsoftAttachmentState:
                                                        attachmentData,
                                                    key: "forward",
                                                    uniqueViewKey
                                                },
                                            }}
                                            className="dropdown-item"
                                        >
                                            <img
                                                src={setImagePath(
                                                    FORWARD_ICON
                                                )}
                                                alt="FORWARD_ICON"
                                            />{" "}
                                            <span className="btn-text">
                                                Forward
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                </>}
                            </div>
                        </div>
                        <div className="comp-inner">
                            <div className="avatar">
                                <img
                                    src={setImagePath(USER_ICON)}
                                    alt="user_icon"
                                />
                            </div>
                            <div className="email-from email_from">
                                <ul>
                                    <li>
                                        <span>From</span>
                                        <strong>
                                            {getOutlookMessageData?.from?.emailAddress?.address || fromEmail}
                                        </strong>
                                    </li>
                                    <li>
                                        <span>To</span>
                                        <strong>
                                            {toRecipients || "N/A"}
                                        </strong>
                                    </li>
                                    {ccRecipients ?
                                        <li>
                                            <span>Cc</span>
                                            <strong>
                                                {ccRecipients}
                                            </strong>
                                        </li>
                                        : ""}
                                    {bccRecipients ?
                                        <li>
                                            <span>Bcc</span>
                                            <strong>
                                                {bccRecipients}
                                            </strong>
                                        </li>
                                        : ""}
                                    <li>
                                        <span>Date</span>
                                        <strong>
                                            {getOutlookMessageData?.receivedDateTime
                                                ? ConvertTimeFromUTCToIST(getOutlookMessageData?.receivedDateTime, "microsoft-view")
                                                : "-"}
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {HtmlBodyData && (
                        <div className="border p-2">
                            <CustomIframe>
                                <div className="w-100"
                                    dangerouslySetInnerHTML={{
                                        __html: ModifyHtmlBody,
                                    }}
                                ></div>
                            </CustomIframe>
                            {attachmentData &&
                                attachmentData.length > 0 &&
                                <div className="border-top my-2 p-2">
                                    {
                                        attachmentData.map((dataFetched, index) => {
                                            return (
                                                attachmentData[index] ?
                                                    <div className="attachment_box" key={index}>
                                                        <div> <a
                                                            href={handleaAtoB(
                                                                decodeFromBase64(
                                                                    dataFetched && dataFetched?.contentBytes
                                                                )
                                                            )}
                                                            id="link"
                                                            download={
                                                                (dataFetched &&
                                                                    dataFetched.name) ||
                                                                ""
                                                            }
                                                        >
                                                            <img
                                                                src={setImagePath(
                                                                    DOWNLOAD_ICON
                                                                )}
                                                                alt="down"
                                                            />
                                                        </a>
                                                        </div>

                                                        <div className="wk-pdf">
                                                            <p title={(dataFetched &&
                                                                dataFetched.name) ||
                                                                ""}>

                                                                {(dataFetched &&
                                                                    dataFetched.name) ||
                                                                    ""}

                                                            </p>
                                                            <span>
                                                                {validateFileSize(
                                                                    dataFetched && dataFetched?.size
                                                                ) || ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    : "")
                                        })}
                                </div>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}