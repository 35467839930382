import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { EmailHeader } from "../../../component/frontend/email/EmailHeader";
import { Footer } from "../../../component/frontend/footer/footer";
import { Header } from "../../../component/frontend/header/header";
import { Loader } from "../../../component/frontend/loader/loader";
import { setImagePath, usePrevious } from "../../../../common/custom";
import USER_ICON from "../../../../assets/images/image_avatar.png";
// import UPLOAD_ICON from "../../../../assets/images/attach_file_FILL0_wght400_GRAD0_opsz48.svg";
// import Swal from "sweetalert2";
import { getUserDetails } from "../../../../storage/user";
import { getUserSocialDetails } from "../../../../routing/authService";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { EMAIL_LIST } from "../../../../routing/routeContants";
import {
  createDraftGmailMessage,
  getGmailAuthLink,
  getGmailValidToken,
  saveSocialAuthToken,
  sendGmailMessage,
  sendGmailMessageWithAttachment,
} from "../../../../duck/email/google/google.action";
import { SubscriptionPlan } from "../profile/subscriptionPlans";
import { EmailSidebar } from "../../../component/frontend/email/EmailSidebar";
import { constants, tinyEmailConfig } from "../../../../common/constants";
import { Editor } from "@tinymce/tinymce-react";
import TagsInput from "../../../component/frontend/input/TagsInput";
// import { useRef } from "react";
import { errorNotification } from "../../../../common/notification-alert";
import { createOutlookDraftMessage, getOutlookAuthLink, getOutlookAuthToken, sendOutlookMessage } from "../../../../duck/email/microsoft/microsoft.action";
import { resetAllState, updateAccordionLabel, updateGoogleLabel, updateMicrosoftLabel } from "../../../../duck/email/email.action";
import { User } from "../../../../storage";

export const ComposeEmailPage = (props) => {
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const userSocialData = getUserSocialDetails();
  const { loading, sendEmailResponseData, createDraftMessageData, errorTokenData, getGmailTokenData } = useSelector((state) => state.google);
  const { loading: msalLoading, getAllOutlookMailFoldersData, microsoftErrorData, getOutlookAuthTokenData, isOutlookMessageSent, draftCreatedData } = useSelector((state) => state.microsoft);
  const {
    googleLabel,
    microsoftLabel,
    accordionLabel
  } = useSelector((state) => state.email);
  const currentPlan = userData && userData.planData;
  const getGmailAuthLinkData = useSelector((state) => state.google.getGmailAuthLinkData);
  const prevGetGmailAuthLinkData = usePrevious({ getGmailAuthLinkData });
  const getOutlookAuthLinkData = useSelector((state) => state.microsoft.getOutlookAuthLinkData);
  const prevGetOutlookAuthLinkData = usePrevious({ getOutlookAuthLinkData });
  const [ccActive, setCcActive] = useState(false);
  const [bccActive, setBccActive] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  // const [uploadFileError, setUploadFileError] = useState("");
  // const fileInput = useRef(null);
  const [state, setState] = useState({
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    body: "",
    bodyText: "",
  });
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);

  // Create Function
  // const createFunction = (e, path) => {
  //   e.preventDefault();
  //   // Add Free trial expire then working is blocked
  //   //if(currentPlan && currentPlan.plan_is_active === 0){
  //   // Free trial expire then working is fine
  //   if (
  //     currentPlan &&
  //     currentPlan.plan_is_active === 0 &&
  //     (path === "/user/add-quote" || path === "/user/add-invoice/")
  //   ) {
  //     let buttonMsg = currentPlan.subscription_product_id === 1 ? "View Plans" : "Renew Plan";
  //     let warMsg = currentPlan.subscription_product_id === 1 ? "Free Trial Expired" : "Subscription Expired";
  //     let msg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Your free trial has expired. Please subscribe to a plan to access the application. "
  //         : "Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ";
  //     Swal.fire({
  //       title: warMsg,
  //       html: msg,
  //       showCancelButton: true,
  //       confirmButtonText: buttonMsg,
  //       cancelButtonText: "Close",
  //       reverseButtons: true,
  //       showCloseButton: true,
  //       customClass: "mycustom-alert",
  //       cancelButtonClass: "cancel-alert-note",
  //     }).then((result) => {
  //       if (result.value) {
  //         setSubscriptionModalShow(true);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       }
  //     });
  //   } else {
  //     props.history.push(path);
  //   }
  // };

  useEffect(() => {
    dispatch(updateGoogleLabel(""));
    dispatch(updateMicrosoftLabel({}));
  }, [dispatch]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const getValidRefreshToken = useCallback(async () => {
    if (
      userSocialData &&
      _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
      && errorTokenData && Object.keys(errorTokenData).length > 0
    ) {
      if (errorTokenData.code === 401) {
        const refreshToken = userSocialData?.google?.refresh_token
        dispatch(getGmailValidToken(refreshToken));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorTokenData])

  const saveRefreshToken = useCallback(async () => {
    if ((getGmailTokenData && Object.keys(getGmailTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "google") &&
      Object.keys(userSocialData.google).length > 0 &&
      userSocialData.google.access_token)) {
      const updateGmailAuthTokenData = {
        loginType: 1, authToken: getGmailTokenData?.access_token, refreshToken: getGmailTokenData?.refresh_token, expiresIn: getGmailTokenData?.expire_in,
        social_email: getGmailTokenData?.social_email,
        social_user_id: getGmailTokenData?.social_user_id,
        social_user_name: getGmailTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 1, authToken: userSocialData?.google?.access_token, refreshToken: userSocialData?.google?.refresh_token, expiresIn: userSocialData?.google?.expire_in,
        social_email: userSocialData?.google?.social_email,
        social_user_id: userSocialData?.google?.social_user_id,
        social_user_name: userSocialData?.google?.social_user_name,
      }
      const updatedData = getGmailTokenData && Object.keys(getGmailTokenData).length > 0 ? updateGmailAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getGmailTokenData]);

  const getOutlookValidRefreshToken = useCallback(async () => {
    if (
      userSocialData &&
      _.has(userSocialData, "microsoft") && Object.keys(userSocialData.microsoft).length > 0
      && microsoftErrorData && Object.keys(microsoftErrorData).length > 0
    ) {
      if (microsoftErrorData.code === "InvalidAuthenticationToken") {
        const refreshToken = userSocialData?.microsoft?.refresh_token
        dispatch(getOutlookAuthToken(refreshToken));
        User.setSocialUserDetails({ ...userSocialData, microsoft: { ...userSocialData?.microsoft, social_email: null } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, microsoftErrorData])

  const saveOutlookRefreshToken = useCallback(async () => {
    if ((getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "microsoft") &&
      Object.keys(userSocialData.microsoft).length > 0 &&
      userSocialData.microsoft.access_token)) {
      const updateOutlookAuthTokenData = {
        loginType: 2, authToken: getOutlookAuthTokenData?.access_token, refreshToken: getOutlookAuthTokenData?.refresh_token, expiresIn: getOutlookAuthTokenData?.expire_in,
        social_email: getOutlookAuthTokenData?.social_email,
        social_user_id: getOutlookAuthTokenData?.social_user_id,
        social_user_name: getOutlookAuthTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 2, authToken: userSocialData?.microsoft?.access_token, refreshToken: userSocialData?.microsoft?.refresh_token, expiresIn: userSocialData?.microsoft?.expire_in,
        social_email: userSocialData?.microsoft?.social_email,
        social_user_id: userSocialData?.microsoft?.social_user_id,
        social_user_name: userSocialData?.microsoft?.social_user_name,
      }
      const updatedData = getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0 ? updateOutlookAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getOutlookAuthTokenData])

  const handleSendGmailMessage = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      if (uploadFile.length > 0) {
        dispatch(sendGmailMessageWithAttachment({ raw: uploadFile, size: uploadFile[0].size, threadId: "" }));
      } else {
        let decodedMessage =
          "From: " +
          userSocialData?.google?.social_email +
          "\r\n" +
          "To: " +
          state.to +
          "\r\n" +
          "Cc: " +
          state.cc +
          "\r\n" +
          "Bcc: " +
          state.bcc +
          "\r\n" +
          "Subject: " +
          state.subject +
          "\r\n\r\n" +
          state.bodyText;

        // The body needs to be base64url encoded.
        const encodedMessage = btoa(decodedMessage).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
        if (errorTokenData && errorTokenData?.code === 401) {
          await getValidRefreshToken();
          await saveRefreshToken();
          await dispatch(sendGmailMessage({ raw: encodedMessage, threadId: "" }));
        } else {
          await dispatch(sendGmailMessage({ raw: encodedMessage, threadId: "" }));
        }
      }
    }
  };

  const handleSendOutlookMessage = async () => {
    if (state.to.length === 0) {
      errorNotification("Please specify at least one recipient.");
    } else if (state.subject.length === 0 && state.body.length === 0) {
      errorNotification("Please specify at least subject or text body.");
    } else {
      let toRecipients = state.to.map((email) => {
        return {
          emailAddress: {
            address: email,
          }
        }
      });
      let ccRecipients = state.cc.map((email) => {
        return {
          emailAddress: {
            address: email,
          }
        }
      });
      let bccRecipients = state.bcc.map((email) => {
        return {
          emailAddress: {
            address: email,
          }
        }
      });
      const bodyData = {
        "message": {
          "subject": state.subject,
          "body": {
            "contentType": "Text",
            "content": state.bodyText
          },
          toRecipients,
          ccRecipients,
          bccRecipients,
        },
      };
      if (microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") {
        await getOutlookValidRefreshToken();
        await saveOutlookRefreshToken();
        dispatch(sendOutlookMessage({ body: bodyData }));
      } else {
        dispatch(sendOutlookMessage({ body: bodyData }));
      }
    }
  }

  const handleSendEmail = async () => {
    if (accordionLabel === "gmail") {
      handleSendGmailMessage();
    } else if (accordionLabel === "microsoft") {
      handleSendOutlookMessage();
    }
  };

  const handleCreateGmailDraft = async () => {
    let decodedMessage =
      "From: " +
      userSocialData?.google?.social_email +
      "\r\n" +
      "To: " +
      state.to +
      "\r\n" +
      "Cc: " +
      state.cc +
      "\r\n" +
      "Bcc: " +
      state.bcc +
      "\r\n" +
      "Subject: " +
      state.subject +
      "\r\n\r\n" +
      state.bodyText;

    // The body needs to be base64url encoded.
    const encodedMessage = btoa(unescape(encodeURIComponent(decodedMessage))).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    if (errorTokenData && errorTokenData?.code === 401) {
      await getValidRefreshToken();
      await saveRefreshToken();
      await dispatch(createDraftGmailMessage({ message: { raw: encodedMessage }, threadId: "" }));
    } else {
      await dispatch(createDraftGmailMessage({ message: { raw: encodedMessage }, threadId: "" }));
    }
  }

  const handleCreateOutlookDraft = async () => {
    let toRecipients = state.to.map((email) => {
      return {
        emailAddress: {
          address: email,
        }
      }
    });
    let ccRecipients = state.cc.map((email) => {
      return {
        emailAddress: {
          address: email,
        }
      }
    });
    let bccRecipients = state.bcc.map((email) => {
      return {
        emailAddress: {
          address: email,
        }
      }
    });
    const bodyData = {
      toRecipients,
      ccRecipients,
      bccRecipients,
      subject: state.subject,
      importance: "Low",
      body: {
        contentType: "TEXT",
        content: state.bodyText
      }
    };
    if (microsoftErrorData && microsoftErrorData.code === "InvalidAuthenticationToken") {
      await getOutlookValidRefreshToken();
      await saveOutlookRefreshToken();
      dispatch(createOutlookDraftMessage({ mailFolderId: microsoftLabel.id, body: bodyData }));
    } else {
      dispatch(createOutlookDraftMessage({ mailFolderId: microsoftLabel.id, body: bodyData }));
    }
  }

  const handleCreateDraft = async () => {
    if (accordionLabel === "gmail") {
      handleCreateGmailDraft();
    } else if (accordionLabel === "microsoft") {
      handleCreateOutlookDraft()
    }
  }

  useEffect(() => {
    if (createDraftMessageData && createDraftMessageData?.message && createDraftMessageData?.message?.labelIds?.includes('DRAFT')) {
      props.history.push(EMAIL_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDraftMessageData]);

  useEffect(() => {
    if (sendEmailResponseData && sendEmailResponseData?.labelIds?.includes("SENT")) {
      props.history.push(EMAIL_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmailResponseData]);

  const handleGoogleLabels = (label) => {
    if (googleLabel !== label) {
      dispatch(updateGoogleLabel(label));
    }
  };

  useEffect(() => {
    if (prevGetGmailAuthLinkData && prevGetGmailAuthLinkData.getGmailAuthLinkData !== getGmailAuthLinkData) {
      if (getGmailAuthLinkData && _.has(getGmailAuthLinkData, "url") && getGmailAuthLinkData.url !== "") {
        window.location.replace(getGmailAuthLinkData.url);
      }
    }
  }, [prevGetGmailAuthLinkData, getGmailAuthLinkData])

  const handleSiginWithGoogle = () => {
    dispatch(getGmailAuthLink());
  }

  useEffect(() => {
    if (prevGetOutlookAuthLinkData && prevGetOutlookAuthLinkData.getOutlookAuthLinkData !== getOutlookAuthLinkData) {
      if (getOutlookAuthLinkData && _.has(getOutlookAuthLinkData, "url") && getOutlookAuthLinkData.url !== "") {
        window.location.replace(getOutlookAuthLinkData.url);
      }
    }
  }, [prevGetOutlookAuthLinkData, getOutlookAuthLinkData])

  const handleSiginWithMicrosoft = () => {
    dispatch(getOutlookAuthLink());
  }

  const handleMicrosoftLabels = () => { };
  const handleAccordionClick = (key) => {
    if (props.match.path !== EMAIL_LIST) {
      props.history.push(EMAIL_LIST);
    }
    dispatch(resetAllState());
    dispatch(updateAccordionLabel(key));
  }

  useEffect(() => {
    if ((isOutlookMessageSent) || (draftCreatedData && Object.keys(draftCreatedData).length)) {
      props.history.push(EMAIL_LIST);
    }
  }, [isOutlookMessageSent, props.history, draftCreatedData])

  // const triggerInputFile = () => {
  //   fileInput.current.click();
  // };

  // const handleRequestDocuments = (event) => {
  //   const { files } = event.target;
  //   if (files) {
  //     const fileData = _.map(files, (file) => file);
  //     const fixSizeExceeded = fileData.find((data) => data.size > 25 * 1024000);
  //     if (fixSizeExceeded) {
  //       errorNotification("Attachments are not allowed greater than 25MB");
  //       return;
  //     }
  //     if ([...uploadFile, ...fileData].length) {
  //       setUploadFile([...uploadFile, ...fileData]);
  //       setUploadFileError("");
  //     }
  //   }
  //   event.target.files = null;
  // };

  const handleRemoveFile = (index) => {
    if (index !== -1) {
      uploadFile.splice(index, 1);
      setUploadFile([...uploadFile]);
    }
  };

  const selectedTo = (tags) => {
    setState({ ...state, to: tags });
  };

  const selectedCc = (tags) => {
    setState({ ...state, cc: tags });
  };

  const selectedBcc = (tags) => {
    setState({ ...state, bcc: tags });
  };

  const handleEditorOnChange = (event, editor) => {
    setState({ ...state, body: event, bodyText: editor?.getContent({ format: 'text' }) });
  };

  return (
    <>
      <Loader loader={loading || msalLoading} />
      <div className="main-site fixed--header fixed-email-header">
        <Header getMainRoute={"email"} />
        <main className="site-body bg-white">
          {/* <EmailHeader createFunction={createFunction} /> */}
          <EmailHeader />
          <section className="middle-section email-template-design">
            <div className="container-fluid">
              <div className="row no-gutters-mbl">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="email-template">
                        <div className="email-head d-flex">
                          <div className="create-btns ml-auto">
                            <span className="btn-text font-weight-bold">Note: Hit 'Enter' or 'Return' after typing an email address.</span>
                            <button className="btn btn-secondary" onClick={handleCreateDraft} disabled={!(state.to.length || state.cc.length || state.bcc.length || state.body.length || state.subject.length)}>
                              Save Draft
                            </button>
                            <button className="btn btn-primary" onClick={handleSendEmail}>
                              Send
                            </button>
                          </div>
                        </div>
                        <div className="email-content">
                          <EmailSidebar
                            googleErrorData={errorTokenData}
                            microsoftErrorData={microsoftErrorData}
                            handleSiginWithGoogle={handleSiginWithGoogle}
                            handleSiginWithMicrosoft={handleSiginWithMicrosoft}
                            accordionState={accordionLabel}
                            labelId={googleLabel}
                            userSocialData={userSocialData}
                            handleGoogleLabels={handleGoogleLabels}
                            msalLabel={microsoftLabel}
                            labelsList={getAllOutlookMailFoldersData && getAllOutlookMailFoldersData?.value}
                            handleMicrosoftLabels={handleMicrosoftLabels}
                            handleAccordionClick={handleAccordionClick}
                          />
                          <div className="col-sm-10">
                            <div className="bg-white boxShadow h-100 p-3">
                              <div className="compose-head">
                                <div className="comp-inner">
                                  <div className="avatar">
                                    <img src={setImagePath(USER_ICON)} alt="user_icon" />
                                  </div>
                                  <div className="email-from">
                                    <div className="input-box">
                                      <label>To</label>
                                      <TagsInput selectedTags={selectedTo} inputTags={state.to} />
                                    </div>
                                    {!(ccActive && bccActive) && (
                                      <div className="show-cc-bc">
                                        {!ccActive && <span onClick={() => setCcActive(!ccActive)}>Cc</span>}
                                        {!bccActive && <span onClick={() => setBccActive(!bccActive)}>Bcc</span>}
                                      </div>
                                    )}
                                    {ccActive && (
                                      <div className="input-box">
                                        <label>Cc</label>
                                        <TagsInput selectedTags={selectedCc} inputTags={state.cc} />
                                      </div>
                                    )}
                                    {bccActive && (
                                      <div className="input-box">
                                        <label>Bcc</label>
                                        <TagsInput selectedTags={selectedBcc} inputTags={state.bcc} />
                                      </div>
                                    )}

                                    <div className="input-box">
                                      <input
                                        placeholder="Subject"
                                        className="form-control bg-transparent border-0"
                                        type="text"
                                        name="subject"
                                        value={state.subject}
                                        onChange={handleOnChange}
                                      />
                                    </div>

                                    {/* <label
                                      htmlFor="file_upload"
                                      className="drop-attach"
                                      onClick={() => triggerInputFile()}
                                    >
                                      <img
                                        src={setImagePath(UPLOAD_ICON)}
                                        alt="upload_icon"
                                      />{" "}
                                      Click or Drop attachment here
                                      <input
                                        ref={fileInput}
                                        type="file"
                                        multiple={true}
                                        className="hiddenInputTypeFile"
                                        onChange={(e) =>
                                          handleRequestDocuments(e)
                                        }
                                        accept="*"
                                        style={{ display: "none" }}
                                      />
                                    </label> */}
                                    <div className="drop-attach-name mt-2">
                                      {uploadFile.length > 0 &&
                                        _.map(uploadFile, (docF, docI) => {
                                          return (
                                            <span key={docI}>
                                              {docF.name}
                                              <button onClick={() => handleRemoveFile(docI)}>
                                                <i className="far fa-times-circle ms-2"></i>
                                              </button>
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <Editor
                                  apiKey={constants.tinyAapiKey}
                                  init={tinyEmailConfig}
                                  name="body"
                                  value={state.body !== "" ? state.body : ""}
                                  onEditorChange={(data, editor) => handleEditorOnChange(data, editor)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        {/* Subscription Modal*/}
        <SubscriptionPlan
          loader={loading || msalLoading}
          openSubscriptionModal={subscriptionModalShow}
          closeSubscriptionModal={() => setSubscriptionModalShow(false)}
          updatePlanDetail={(data) => {
            setSubscriptionModalShow(false);
          }}
          currentPlan={currentPlan}
        />
      </div>
    </>
  );
};

export const ComposeEmail = withRouter(ComposeEmailPage);
