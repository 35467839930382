import { put, takeLatest } from 'redux-saga/effects';
import {
    categoryListResponse, SUCCESS_CATEGORY_LIST, ERROR_CATEGORY_LIST, CATEGORY_LIST,
    categoryCreateResponse, SUCCESS_CATEGORY_CREATE, ERROR_CATEGORY_CREATE, CATEGORY_CREATE,
    expenseListResponse, SUCCESS_EXPENSE_LIST, ERROR_EXPENSE_LIST, EXPENSE_LIST,
    expenseCreateResponse, SUCCESS_EXPENSE_CREATE, ERROR_EXPENSE_CREATE, EXPENSE_CREATE,
    expenseUpdateResponse, SUCCESS_EXPENSE_UPDATE, ERROR_EXPENSE_UPDATE, EXPENSE_UPDATE,
    expenseDeleteResponse, SUCCESS_EXPENSE_DELETE, ERROR_EXPENSE_DELETE, EXPENSE_DELETE,
    expenseDownloadResponse, SUCCESS_EXPENSE_DOWNLOAD, ERROR_EXPENSE_DOWNLOAD, EXPENSE_DOWNLOAD,
} from './expenses.action';
import {
    categoryListApi,
    categoryCreateApi,
    expenseListApi,
    expenseCreateApi,
    expenseUpdateApi,
    expenseDeleteApi,
    expenseDownloadApi
} from '../../../api/index';
import _ from 'lodash'
import { successNotification } from '../../common/notification-alert';

// Category List Data
function* categoryListRequest(data) {
    let getData = yield categoryListApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(categoryListResponse(SUCCESS_CATEGORY_LIST, getData.data));
    } else {
        yield put(categoryListResponse(ERROR_CATEGORY_LIST, getData.data));
    }
}

export function* categoryListWatcher() {
    yield takeLatest(CATEGORY_LIST, categoryListRequest);
}

// Category Create Data
function* categoryCreateRequest(data) {
    let getData = yield categoryCreateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(categoryCreateResponse(SUCCESS_CATEGORY_CREATE, getData.data));
    } else {
        yield put(categoryCreateResponse(ERROR_CATEGORY_CREATE, getData.data));
    }
}

export function* categoryCreateWatcher() {
    yield takeLatest(CATEGORY_CREATE, categoryCreateRequest);
}

// Expenses List
function* expenseListRequest(data) {
    let getData = yield expenseListApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(expenseListResponse(SUCCESS_EXPENSE_LIST, getData.data));
    } else {
        yield put(expenseListResponse(ERROR_EXPENSE_LIST, getData.data));
    }
}

export function* expenseListWatcher() {
    yield takeLatest(EXPENSE_LIST, expenseListRequest);
}

// Expense Create Data
function* expenseCreateRequest(data) {
    let getData = yield expenseCreateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(expenseCreateResponse(SUCCESS_EXPENSE_CREATE, getData.data));
    } else {
        yield put(expenseCreateResponse(ERROR_EXPENSE_CREATE, getData.data));
    }
}

export function* expenseCreateWatcher() {
    yield takeLatest(EXPENSE_CREATE, expenseCreateRequest);
}

// Expense Update Data
function* expenseUpdateRequest(data) {
    let getData = yield expenseUpdateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(expenseUpdateResponse(SUCCESS_EXPENSE_UPDATE, getData.data));
    } else {
        yield put(expenseUpdateResponse(ERROR_EXPENSE_UPDATE, getData.data));
    }
}

export function* expenseUpdateWatcher() {
    yield takeLatest(EXPENSE_UPDATE, expenseUpdateRequest);
}

// Expenses Delete
function* expenseDeleteRequest(data) {
    let getData = yield expenseDeleteApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(expenseDeleteResponse(SUCCESS_EXPENSE_DELETE, getData.data));
    } else {
        yield put(expenseDeleteResponse(ERROR_EXPENSE_DELETE, getData.data));
    }
}

export function* expenseDeleteWatcher() {
    yield takeLatest(EXPENSE_DELETE, expenseDeleteRequest);
}

// Expenses Download
function* expenseDownloadRequest(data) {
    let getData = yield expenseDownloadApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(expenseDownloadResponse(SUCCESS_EXPENSE_DOWNLOAD, getData.data));
    } else {
        yield put(expenseDownloadResponse(ERROR_EXPENSE_DOWNLOAD, getData.data));
    }
}

export function* expenseDownloadWatcher() {
    yield takeLatest(EXPENSE_DOWNLOAD, expenseDownloadRequest);
}
