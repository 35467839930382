import {
    SUCCESS_GET_PROFILE_DETAIL, ERROR_GET_PROFILE_DETAIL,
    SUCCESS_UPDATE_OWNER_PROFILE_DETAIL, ERROR_UPDATE_OWNER_PROFILE_DETAIL,
    SUCCESS_UPDATE_PASSWORD, ERROR_UPDATE_PASSWORD,
    SUCCESS_UPDATE_BUSINESS_PROFILE_DETAIL, ERROR_UPDATE_BUSINESS_PROFILE_DETAIL,
    SUCCESS_GET_MENU_COUNT, ERROR_GET_MENU_COUNT,
    SUCCESS_CONNECT_WITH_PAYPAL, ERROR_CONNECT_WITH_PAYPAL,
    SUCCESS_MAKE_TEST_PAYMENT, ERROR_MAKE_TEST_PAYMENT,
    SUCCESS_DELETE_PAYMENT_ACCOUNT, ERROR_DELETE_PAYMENT_ACCOUNT,
    SUCCESS_GET_SUBSCRIPTION_PLAN, ERROR_GET_SUBSCRIPTION_PLAN,
    SUCCESS_CREATE_SUBSCRIPTION_PLAN, ERROR_CREATE_SUBSCRIPTION_PLAN,
    SUCCESS_PLAN_APPLY_COUPON, ERROR_PLAN_APPLY_COUPON,
    SUCCESS_GET_STATE_TAX, ERROR_GET_STATE_TAX,
    SUCCESS_UPDATE_SUBSCRIPTION_PLAN, ERROR_UPDATE_SUBSCRIPTION_PLAN,
    SUCCESS_CANCEL_SUBSCRIPTION_PLAN, ERROR_CANCEL_SUBSCRIPTION_PLAN,
    SUCCESS_ADD_PROFILE_SERVICE, ERROR_ADD_PROFILE_SERVICE,
    SUCCESS_DELETE_ALL_DUMMY_DATA,
    SUCCESS_GET_STATE, ERROR_GET_STATE,
    UPDATE_AUTO_REPLY_DATA, SUCCESS_UPDATE_AUTO_REPLY_DATA, ERROR_UPDATE_AUTO_REPLY_DATA,
    GET_AUTO_REPLY_DATA, SUCCESS_GET_AUTO_REPLY_DATA, ERROR_GET_AUTO_REPLY_DATA,
    SUCCESS_DISABLE_2FA, ERROR_DISABLE_2FA,
    SUCCESS_VERIFY_2FA, ERROR_VERIFY_2FA,
    SUCCESS_SETUP_2FA, ERROR_SETUP_2FA, ERROR_DELETE_HEADER_IMAGE, SUCCESS_DELETE_HEADER_IMAGE,
    SUCCESS_ADD_CONFIGURATION, ERROR_ADD_CONFIGURATION,
} from './profile.action';
import { DEFAULT_STATE } from './profile.state';


export const profileReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case SUCCESS_GET_PROFILE_DETAIL:
            const getProfileDetailData = action.data;
            return { ...state, getProfileDetailData }
        case ERROR_GET_PROFILE_DETAIL:
            const errorGetProfileDetailData = action.data;
            return { ...state, getProfileDetailData: errorGetProfileDetailData }
        case SUCCESS_UPDATE_OWNER_PROFILE_DETAIL:
            const ownerProfileDetailData = action.data;
            return { ...state, ownerProfileDetailData }
        case ERROR_UPDATE_OWNER_PROFILE_DETAIL:
            const errorOwnerProfileDetailData = action.data;
            return { ...state, ownerProfileDetailData: errorOwnerProfileDetailData }
        case SUCCESS_UPDATE_PASSWORD:
            const updatePasswordData = action.data;
            return { ...state, updatePasswordData }
        case ERROR_UPDATE_PASSWORD:
            const errorUpdatePasswordData = action.data;
            return { ...state, updatePasswordData: errorUpdatePasswordData }
        case SUCCESS_UPDATE_BUSINESS_PROFILE_DETAIL:
            const businessProfileDetailData = action.data;
            return { ...state, businessProfileDetailData }
        case ERROR_UPDATE_BUSINESS_PROFILE_DETAIL:
            const errorBusinessProfileDetailData = action.data;
            return { ...state, businessProfileDetailData: errorBusinessProfileDetailData }
        case SUCCESS_GET_MENU_COUNT:
            const getMenuCountData = action.data;
            return { ...state, getMenuCountData }
        case ERROR_GET_MENU_COUNT:
            const errorGetMenuCountData = action.data;
            return { ...state, getMenuCountData: errorGetMenuCountData }
        case SUCCESS_CONNECT_WITH_PAYPAL:
            const connectWithPaypalData = action.data;
            return { ...state, connectWithPaypalData }
        case ERROR_CONNECT_WITH_PAYPAL:
            const errorConnectWithPaypalData = action.data;
            return { ...state, connectWithPaypalData: errorConnectWithPaypalData }
        case SUCCESS_MAKE_TEST_PAYMENT:
            const makeTestPaymentData = action.data;
            return { ...state, makeTestPaymentData }
        case ERROR_MAKE_TEST_PAYMENT:
            const errorMakeTestPayment = action.data;
            return { ...state, makeTestPaymentData: errorMakeTestPayment }
        case SUCCESS_DELETE_PAYMENT_ACCOUNT:
            const deletePaymentAccountData = action.data;
            return { ...state, deletePaymentAccountData }
        case ERROR_DELETE_PAYMENT_ACCOUNT:
            const errorDeletePaymentAccount = action.data;
            return { ...state, deletePaymentAccountData: errorDeletePaymentAccount }
        case SUCCESS_GET_SUBSCRIPTION_PLAN:
            const getSubscriptionPlanData = action.data;
            return { ...state, getSubscriptionPlanData }
        case ERROR_GET_SUBSCRIPTION_PLAN:
            const errorSubscriptionPlan = action.data;
            return { ...state, getSubscriptionPlanData: errorSubscriptionPlan }
        case SUCCESS_CREATE_SUBSCRIPTION_PLAN:
            const createSubscriptionPlanData = action.data;
            return { ...state, createSubscriptionPlanData }
        case ERROR_CREATE_SUBSCRIPTION_PLAN:
            const errorCreateSubscriptionPlan = action.data;
            return { ...state, createSubscriptionPlanData: errorCreateSubscriptionPlan }
        case SUCCESS_UPDATE_SUBSCRIPTION_PLAN:
            const updateSubscriptionPlanData = action.data;
            return { ...state, createSubscriptionPlanData: updateSubscriptionPlanData }
        case ERROR_UPDATE_SUBSCRIPTION_PLAN:
            const errorUpdateSubscriptionPlan = action.data;
            return { ...state, createSubscriptionPlanData: errorUpdateSubscriptionPlan }
        case SUCCESS_PLAN_APPLY_COUPON:
            const planApplyCouponData = action.data;
            return { ...state, planApplyCouponData }
        case ERROR_PLAN_APPLY_COUPON:
            const errorPlanApplyCoupon = action.data;
            return { ...state, planApplyCouponData: errorPlanApplyCoupon }
        case SUCCESS_GET_STATE_TAX:
            const getStateTaxData = action.data;
            return { ...state, getStateTaxData }
        case ERROR_GET_STATE_TAX:
            const errorGetStateTaxData = action.data;
            return { ...state, getStateTaxData: errorGetStateTaxData }
        case SUCCESS_CANCEL_SUBSCRIPTION_PLAN:
            const cancelSubscriptionPlanData = action.data;
            return { ...state, cancelSubscriptionPlanData }
        case ERROR_CANCEL_SUBSCRIPTION_PLAN:
            const errorCancelSubscriptionPlanData = action.data;
            return { ...state, cancelSubscriptionPlanData: errorCancelSubscriptionPlanData }
        case SUCCESS_ADD_PROFILE_SERVICE:
            const profileServiceData = action.data;
            return { ...state, profileServiceData }
        case ERROR_ADD_PROFILE_SERVICE:
            const errorProfileServiceData = action.data;
            return { ...state, profileServiceData: errorProfileServiceData }
        case SUCCESS_DELETE_ALL_DUMMY_DATA:
            return { ...state, dummyDeleteData: action.data }
        case SUCCESS_GET_STATE:
            const state_data = action.data;
            return { ...state, state_data }
        case ERROR_GET_STATE:
            const errorState = action.data;
            return { ...state, state_data: errorState }
        case SUCCESS_DISABLE_2FA:
            const disableAuthentication2FAData = action.data;
            return { ...state, disableAuthentication2FAData }
        case ERROR_DISABLE_2FA:
            const errorDisableAuthentication2FAData = action.data;
            return { ...state, disableAuthentication2FAData: errorDisableAuthentication2FAData }
        case SUCCESS_VERIFY_2FA:
            const verifyAuthentication2FAData = action.data;
            return { ...state, verifyAuthentication2FAData }
        case ERROR_VERIFY_2FA:
            const errorVerifyAuthentication2FAData = action.data;
            return { ...state, verifyAuthentication2FAData: errorVerifyAuthentication2FAData }
        case SUCCESS_SETUP_2FA:
            const setupAuthentication2FAData = action.data;
            return { ...state, setupAuthentication2FAData }
        case ERROR_SETUP_2FA:
            const errorSetupAuthentication2FAData = action.data;
            return { ...state, setupAuthentication2FAData: errorSetupAuthentication2FAData }
        case UPDATE_AUTO_REPLY_DATA:
            return { ...state, auto_reply_data: action.data }
        case SUCCESS_UPDATE_AUTO_REPLY_DATA:
            const success_auto_reply_data = action.data;
            return { ...state, auto_reply_data: success_auto_reply_data }
        case ERROR_UPDATE_AUTO_REPLY_DATA:
            const errorAutoUpdate = action.data;
            return { ...state, auto_reply_data: errorAutoUpdate }
        case GET_AUTO_REPLY_DATA:
            return { ...state, auto_reply_data: action.data }
        case SUCCESS_GET_AUTO_REPLY_DATA:
            const success_get_auto_reply_data = action.data;
            return { ...state, auto_reply_data: success_get_auto_reply_data }
        case ERROR_GET_AUTO_REPLY_DATA:
            const errorGetAutoUpdate = action.data;
            return { ...state, auto_reply_data: errorGetAutoUpdate }
        case SUCCESS_DELETE_HEADER_IMAGE:
            const deleteHeaderImageData = action.data;
            return { ...state, deleteHeaderImageData }
        case ERROR_DELETE_HEADER_IMAGE:
            const errorDeleteHeaderImage = action.data;
            return { ...state, deleteHeaderImageData: errorDeleteHeaderImage }

        // Manage colunms List Configuration
        case SUCCESS_ADD_CONFIGURATION:
            const addConfigurationData = action.data;
            return { ...state, addConfigurationData }
        case ERROR_ADD_CONFIGURATION:
            const addConfiguration = action.data;
            return { ...state, addConfigurationData: addConfiguration }
        default:
            return state;
    }
}