import React, { useState, useEffect, useRef } from 'react'
import { fieldValidator, setImagePath } from '../../../../common/custom'
import { Loader } from '../../../component/frontend/loader/loader'
import { Header } from '../../../component/frontend/header/header'
import { Footer } from '../../../component/frontend/footer/footer'
import CALENDAR from "../../../../assets/images/calendar.png";
import ORANGE_ARROW from "../../../../assets/images/orange-arrow.svg";
import { dashboardSelectStyle } from '../../../../common/constants'
import {
  selectStyle,
  constants
} from "../../../../common/constants";
import { usePrevious } from '../../../../common/custom';
import { validateInputs } from "../../../../common/validation";
import { errorPopUp } from '../../../../common/notification-alert';
import { categoryList, expenseList, categoryCreate, expenseDelete, expenseCreate, expenseUpdate, expenseDownload } from "../../../../duck/expenses/expenses.action";
import { PaginationFooter } from '../../../../common/paginationFooter'
import { SubscriptionPlan } from '../profile/subscriptionPlans';
import { getUserDetails } from '../../../../storage/user';
import { ADD_EXPENSES_BASE } from '../../../../routing/routeContants';
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link, withRouter } from 'react-router-dom'
import Select, { components } from "react-select";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import _ from 'lodash';

const ViewExpensesPage = (props) => {
  const datepickerRef = useRef();
  const focusOutSearch = useRef();
  const dispatch = useDispatch();
  const userData = getUserDetails();

  const categoryListData = useSelector((state) => state?.expense?.categoryListData?.data);
  const expenseListData = useSelector((state) => state?.expense?.expenseListData?.data);
  const expenseData = useSelector((state) => state?.expense?.expenseListData);
  const expenseDownloadData = useSelector((state) => state?.expense?.expenseDownloadData);
  const expenseListViewData = usePrevious({ expenseListData });
  const categoryCreateteData = useSelector((state) => state?.expense?.expenseCreateData);
  const categoryListOptionModal = [
    { value: "allCategories", label: "All Categories", id: "0" },
    ...(categoryListData?.map((item) => ({ value: item?.name, label: item?.name, id: item?.id })) || []),
  ];
  const categoryListOption = [
    ...(categoryListData ? categoryListData.map((item) => ({ value: item?.name, label: item?.name, id: item?.id })) : []),
  ];

  const currentPlan = userData && userData.planData;
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [addNewExpense, setAddNewExpense] = useState(false)
  const [updateExpense, setUpdateExpense] = useState(false)
  const [expenseCategoryModalShow, setExpenseCategoryModalShow] = useState(false)
  const [dashboardSanpSelect, setDashboardSanpSelect] = useState("");
  const [expensesMessage, setExpensesMessage] = useState("");
  const [limit] = useState(constants.PAGE_LIMIT);
  let [bookingList, setBookingList] = useState([]);
  const [page, setPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState('ASC');
  const [sortingField, setSortingField] = useState('expense_date');
  const [fetchList, setfetchList] = useState(true);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [listView, setListView] = useState("list-view");
  const [totalRecords, setTotalRecords] = useState(0);
  let [eventListData, setEventListData] = useState([])

  const [state, setState] = useState({
    wrongInput: constants.WRONG_INPUT,
    amount: "",
    amountErr: "",
    amountCls: "",
    date: "",
    dateErr: "",
    dateCls: "",
    dateFrom: "",
    dateFromErr: "",
    dateFromCls: "",
    dateTo: "",
    dateToErr: "",
    dateToCls: "",
    category: "",
    paidTo: "",
    description: "",
    durationValue: "",
    durationErr: "",
    durationCls: "",
    selectedOptions: "",
    durationOptions: categoryListOption,
    paymentMethodValue: "",
    receiptOnRecord: 0,
  });

  const [editState, setEditState] = useState({
    wrongInput: constants.WRONG_INPUT,
    id: "",
    amount: "",
    amountErr: "",
    amountCls: "",
    expense_date: "",
    dateErr: "",
    dateCls: "",
    expenses_category_id: "",
    durationValue: "",
    durationErr: "",
    durationCls: "",
    paid_to: "",
    description: "",
    durationOptions: categoryListOption,
    method_of_payment: "",
    receipt_on_record: 0,
  });

  const PaymentMethodTypeOption = [
    { value: "Credit Card", label: "Credit Card" },
    { value: "Cash", label: "Cash" },
    { value: "Bank Check", label: "Bank Check" },
    { value: "Square", label: "Square" },
    { value: "Stripe", label: "Stripe" },
    { value: "Venmo", label: "Venmo" },
    { value: "PayPal", label: "PayPal" },
  ]

  useEffect(() => {
    if (categoryListOption) {
      setState({ ...state, durationOptions: categoryListOption })
    }
  }, [])

  //Custom filter opetion
  const snapshotOptions = [
    { value: 'This Month', label: 'This Month' },
    { value: 'Last 3 Months', label: 'Last 3 Months' },
    { value: 'YTD', label: 'YTD' },
    { value: 'Last Year', label: 'Last Year' },
    { value: 'Custom', label: 'Custom' },
  ];

  const onChangeMonthAndCustomFilter = (data) => {
    setDashboardSanpSelect(data)
    let filterParamsOnChange = {};

    if (data?.value === 'This Month') {
      filterParamsOnChange = {
        from_date: moment().startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
        sortingOrder,
      };
    } else if (data?.value === 'Last 3 Months') {
      filterParamsOnChange = {
        from_date: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
        sortingOrder,
      };
    } else if (data?.value === 'YTD') {
      filterParamsOnChange = {
        from_date: moment().startOf('year').format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
        sortingOrder,
      };
    } else if (data?.value === 'Last Year') {
      filterParamsOnChange = {
        from_date: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        to_date: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
        sortingOrder,
      };
    } else if (data?.value === "Custom") return
    // expenseList api
    dispatch(expenseList(filterParamsOnChange));
  }

  // add category
  const [categoryState, setCategoryState] = useState({
    wrongInput: constants.WRONG_INPUT,
    category: '', categoryCls: '', categoryErr: ''
  });

  //Add Expenses
  const addExpenses = (e) => {
    e.preventDefault();
    setState({ ...state, duration: '', durationCls: '', durationErr: '', })
    if (currentPlan && currentPlan.plan_is_active === 0) {
      // Free trial expire then working is fine
      //if (currentPlan && currentPlan.plan_is_active === 0 && currentPlan.subscription_product_id !== 1) {
      let buttonMsg =
        currentPlan.subscription_product_id === 1 ? "View Plans" : "Renew Plan";
      let warMsg =
        currentPlan.subscription_product_id === 1
          ? "Free Trial Expired"
          : "Subscription Expired";
      let msg =
        currentPlan.subscription_product_id === 1
          ? "Your free trial has expired. Please subscribe to a plan to access the application. "
          : "Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ";
      Swal.fire({
        title: warMsg,
        html: msg,
        showCancelButton: true,
        confirmButtonText: buttonMsg,
        cancelButtonText: "Close",
        reverseButtons: true,
        showCloseButton: true,
        customClass: "mycustom-alert",
        cancelButtonClass: "cancel-alert-note",
      }).then((result) => {
        if (result.value) {
          setSubscriptionModalShow(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // console.log('cancel')
        }
      });
    } else {
      props.history.push(ADD_EXPENSES_BASE);
      setAddNewExpense(true)
    }
  }

  useEffect(() => {
    if (categoryCreateteData) {
      setState({ ...state, durationValue: categoryCreateteData })
    }
  }, [categoryCreateteData])

  const addExpensesCategory = ({ innerRef, innerProps, isDisabled, children }) =>
    !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
        {children}
        <button
          type="button"
          className="btn text-link text-left btn-sm btn-block"
          onClick={(e) => showSourceModal()}
        >Add New Category</button>
      </div>
    ) : null;

  const showSourceModal = () => {
    setAddNewExpense(true)
    setExpenseCategoryModalShow(true);
  }

  const dateToBookingFrom = (dateFrom) => {
    if (dateFrom === null) {
      setState({
        ...state,
        dateFrom: "",
        dateFromCls: state.wrongInput,
        dateFromErr: "Please select from date",
      });
    } else {
      setState({ ...state, dateFrom: dateFrom, dateFromCls: "", dateFromErr: "" });
      setExpensesMessage("");
    }
  };

  const dateToBookingTo = (dateTo) => {
    if (dateTo === null) {
      setState({
        ...state,
        dateTo: "",
        dateToCls: state.wrongInput,
        dateToErr: "Please select to date",
      });
    } else {
      setState({ ...state, dateTo: dateTo, dateToCls: "", dateToErr: "" });
      setExpensesMessage("");
    }
  };
  const weekend = (date) => new Date(state.dateFrom) < date;

  // Set The Input Values
  const setInputValue = (e, type, maxLength, minLength) => {
    let error = checkValidation(
      e.target.name,
      e.target.value,
      type,
      maxLength,
      minLength
    );
    setState({
      ...state,
      [e.target.name]: e.target.value,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
    setExpensesMessage("");
  };

  // Set The Input Values
  const setEditeInputValue = (e, type, maxLength, minLength) => {
    let error = checkValidation(
      e.target.name,
      e.target.value,
      type,
      maxLength,
      minLength
    );
    setEditState({
      ...editState,
      [e.target.name]: e.target.value,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
    setExpensesMessage("");
  };

  // Check Validation Function
  const checkValidation = (
    field,
    value,
    type,
    maxLength,
    minLength,
    fieldType
  ) => {
    return fieldValidator(
      field,
      value,
      type,
      null,
      maxLength,
      minLength,
      fieldType
    );
  };

  const dateForBooking = (date) => {
    if (date === null) {
      setState({
        ...state,
        date: "",
        dateCls: state.wrongInput,
        dateErr: "Please select date",
      });
    } else {
      setState({ ...state, date: date, dateCls: "", dateErr: "" });
      setExpensesMessage("");
    }
  };

  const editDateForBooking = (date) => {
    if (date === null) {
      setEditState({
        ...editState,
        expense_date: "",
        dateCls: editState.wrongInput,
        dateErr: "Please select date",
      });
    } else {
      setEditState({ ...editState, expense_date: date, dateCls: "", dateErr: "" });
      setExpensesMessage("");
    }
  };

  const showTimeSelection = () => {
    setTimeout(function () {
      datepickerRef.current.setOpen(true);
    }, 100);
  };

  //All Category input with checkbox
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const props = {
      ...innerProps,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <div className="custom-control custom-checkbox" >
          <input
            type="checkbox"
            checked={isSelected}
            className="custom-control-input"
            style={{ width: "100%" }}
          />
          <label className="custom-control-label" style={{ fontSize: "14px" }}> {children}</label>
        </div>
      </components.Option>
    );
  };

  // Sort The Booking List
  const sortTheData = (e, field, orderValue) => {
    let order = orderValue === 'DESC' ? 'ASC' : (orderValue === 'ASC' ? "DESC" : 'DESC');
    e.preventDefault();
    setPage(1);
    setSortingField(field);
    setSortingOrder(order);

    let filterParamsOnChange = {};
    if (dashboardSanpSelect?.value === 'This Month') {
      filterParamsOnChange = {
        from_date: moment().startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        page: 1,
        limit, search_key: search,
        sortingField: field,
        sortingOrder: order,
      };
    } else if (dashboardSanpSelect?.value === 'Last 3 Months') {
      filterParamsOnChange = {
        from_date: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        page: 1,
        limit, search_key: search,
        sortingField: field,
        sortingOrder: order,
      };
    } else if (dashboardSanpSelect?.value === 'YTD') {
      filterParamsOnChange = {
        from_date: moment().startOf('year').format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        page: 1,
        limit, search_key: search,
        sortingField: field,
        sortingOrder: order,
      };
    }
    else if (dashboardSanpSelect?.value === 'Last Year') {
      filterParamsOnChange = {
        from_date: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        to_date: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        page: 1,
        limit,
        search_key: search,
        sortingField: field,
        sortingOrder: order,
      };
    }
    else if (dashboardSanpSelect?.value === "Custom") {
      filterParamsOnChange = {
        from_date: state?.dateFrom ? moment(state?.dateFrom)?.format("YYYY-MM-DD") : "",
        to_date: state?.dateTo ? moment(state?.dateTo)?.format("YYYY-MM-DD") : "",
        expenses_category_id: state?.selectedOptions?.toString(),
        page: 1,
        limit, search_key: search,
        sortingField: field,
        sortingOrder: order,
      };
    } else {
      filterParamsOnChange = {
        from_date: "",
        to_date: "",
        expenses_category_id: state?.selectedOptions?.toString(),
        page: 1,
        limit, search_key: search,
        sortingField: field,
        sortingOrder: order,
      };
    }
    dispatch(expenseList(filterParamsOnChange))
  }

  //Data Get By Search
  const onSearchResult = (search) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setPage(1);
    setSearch(search);
    setTypingTimeout(setTimeout(function () {

      let filterParamsOnChange = {};

      if (dashboardSanpSelect?.value === 'This Month') {
        filterParamsOnChange = {
          from_date: moment().startOf('month').format('YYYY-MM-DD'),
          to_date: moment().endOf('month').format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingField,
          sortingOrder,
          sortingField: "created_at",
        };
      } else if (dashboardSanpSelect?.value === 'Last 3 Months') {
        filterParamsOnChange = {
          from_date: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
          to_date: moment().endOf('month').format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingField,
          sortingOrder,
          sortingField: "created_at",
        };
      } else if (dashboardSanpSelect?.value === 'YTD') {
        filterParamsOnChange = {
          from_date: moment().startOf('year').format('YYYY-MM-DD'),
          to_date: moment().format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingField,
          sortingOrder,
          sortingField: "created_at",
        };
      }
      else if (dashboardSanpSelect?.value === 'Last Year') {
        filterParamsOnChange = {
          from_date: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          to_date: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingField,
          sortingOrder,
          sortingField: "created_at",
        };
      }
      else if (dashboardSanpSelect?.value === "Custom") {
        filterParamsOnChange = {
          from_date: state?.dateFrom ? moment(state?.dateFrom)?.format("YYYY-MM-DD") : "",
          to_date: state?.dateTo ? moment(state?.dateTo)?.format("YYYY-MM-DD") : "",
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingField,
          sortingOrder,
          sortingField: "created_at",
        };
      }
      else if (dashboardSanpSelect?.value === undefined) {
        filterParamsOnChange = {
          expenses_category_id: state?.selectedOptions?.toString(),
          from_date: "",
          to_date: "",
          page,
          search_key: search,
          sortingField,
          sortingOrder,
          sortingField: "created_at",
        };
      }
      dispatch(expenseList(filterParamsOnChange))
    }, 300))
  }

  const keyPressDownEvent = (e) => {
    if (e.key === "Enter") {
      focusOutSearch.current.blur();
    }
  }

  //filter Button
  const handelFilter = () => {
    // expenseList API call
    dispatch(expenseList({
      from_date: state?.dateFrom ? moment(state?.dateFrom)?.format("YYYY-MM-DD") : "",
      to_date: state?.dateTo ? moment(state?.dateTo)?.format("YYYY-MM-DD") : "",
      expenses_category_id: state?.selectedOptions?.toString(),
      search_key: search,
      sortingOrder,
    }));
  };

  // expenseDelete api
  const handelDeleteData = (id) => {
    // e.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the expense record?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it',
      cancelButtonText: 'No, keep it',
      reverseButtons: true,
      showCloseButton: true,
      customClass: "mycustom-alert",
      cancelButtonClass: 'cancel-alert-note',
    }).then((result) => {
      if (result.value) {
        // expenseDelete api
        dispatch(expenseDelete({ id: id }))
        // expenseList api
        dispatch(expenseList())
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const saveExpensesData = (data) => {
    let error = categoryState?.wrongInput,
      categoryErr = "",
      categoryCls = "",
      getError = false;

    if (validateInputs("required", categoryState?.category) === "empty") {
      categoryErr = "Please add category";
      categoryCls = error;
      getError = true;
    }
    setCategoryState({
      ...categoryState,
      categoryErr,
      categoryCls,
    });
    if (
      getError === false &&
      categoryErr === ""
    ) {
    }
    // Category Create api
    if (!getError) {
      setExpenseCategoryModalShow(false)
      dispatch(categoryCreate({ name: data }));
      setState({ ...state, durationValue: categoryCreateteData })
      setCategoryState({ category: '', categoryCls: '', categoryErr: '' })
    }
  }

  //Add New Expense Save and Add Another
  const saveAndOnOtherHandelClick = () => {
    let error = state.wrongInput;
    let date = state.date,
      dateErr = "",
      dateCls = "",
      amountErr = "",
      amountCls = "",
      durationErr = "",
      durationCls = "",
      getError = false;

    if (
      validateInputs(
        "required",
        date !== "" ? date?.getDate() + " " + date?.getMonth() : ""
      ) === "empty"
    ) {
      dateErr = "Please select date.";
      dateCls = error;
      getError = true;
    }

    if (validateInputs("required", state?.amount) === "empty") {
      amountErr = "Add Amount";
      amountCls = error;
      getError = true;
    }

    if (validateInputs("required", state?.durationValue) === "empty") {
      durationErr = "Please select category";
      durationCls = error;
      getError = true;
    }
    setState({
      ...state,
      dateErr,
      dateCls,
      amountErr,
      amountCls,
      durationErr,
      durationCls,
    });
    if (
      getError === false &&
      dateErr === "" &&
      amountErr === "" &&
      durationErr === ""
    ) {
      let datetimeA;
      if (state?.tbd === true) {
        datetimeA = null;
      } else {
        let getdate = moment(state.date)?.format("YYYY-MM-DD");
        let time = state.timeValue
          ? moment(state.timeValue)?.format("HH:mm:ss")
          : moment()?.format("00:00:00");
        datetimeA = moment(getdate + " " + time);
      }
    }

    // Expense Create api
    if (!getError) {
      dispatch(expenseCreate({
        amount: state?.amount,
        expense_date: moment(state?.date)?.format("YYYY-MM-DD"),
        expenses_category_id: state?.durationValue?.id,
        receipt_on_record: 0,
        paid_to: state?.paidTo,
        method_of_payment: state?.paymentMethodValue?.value,
        description: state?.description,
        receipt_on_record: state?.receiptOnRecord ? 1 : 0
      }));

      setState({
        amount: "",
        date: "",
        dateFrom: "",
        dateTo: "",
        category: "",
        paidTo: "",
        description: "",
        durationValue: "",
        selectedOptions: "",
        paymentMethodValue: "",
        receiptOnRecord: 0,
      });
    }
  }

  //Add New Expense save
  const saveExpensesDetail = () => {
    let error = state?.wrongInput;
    let date = state?.date,
      dateErr = "",
      dateCls = "",
      amountErr = "",
      amountCls = "",
      durationErr = "",
      durationCls = "",
      getError = false;

    if (
      validateInputs(
        "required",
        date !== "" ? date?.getDate() + " " + date?.getMonth() : ""
      ) === "empty"
    ) {
      dateErr = "Please select date.";
      dateCls = error;
      getError = true;
    }

    if (validateInputs("required", state?.amount) === "empty") {
      amountErr = "Add Amount";
      amountCls = error;
      getError = true;
    }

    if (validateInputs("required", state?.durationValue) === "empty") {
      durationErr = "Please select category";
      durationCls = error;
      getError = true;
    }
    setState({
      ...state,
      dateErr,
      dateCls,
      amountErr,
      amountCls,
      durationErr,
      durationCls,
    });
    if (
      getError === false &&
      dateErr === "" &&
      amountErr === "" &&
      durationErr === ""
    ) {
      let datetimeA;
      if (state?.tbd === true) {
        datetimeA = null;
      } else {
        let getdate = moment(state?.date)?.format("YYYY-MM-DD");
        let time = state?.timeValue
          ? moment(state?.timeValue)?.format("HH:mm:ss")
          : moment()?.format("00:00:00");
        datetimeA = moment(getdate + " " + time);
      }
    }
    // Expense Create api
    if (!getError) {
      dispatch(expenseCreate({
        amount: state?.amount,
        expense_date: moment(state?.date)?.format("YYYY-MM-DD"),
        expenses_category_id: state?.durationValue?.id,
        receipt_on_record: 0,
        paid_to: state?.paidTo,
        method_of_payment: state?.paymentMethodValue?.value,
        description: state?.description,
        receipt_on_record: state?.receiptOnRecord ? 1 : 0
      }));

      setAddNewExpense(false);
      setState({
        amount: "",
        date: "",
        dateFrom: "",
        dateTo: "",
        category: "",
        paidTo: "",
        description: "",
        durationValue: "",
        selectedOptions: "",
        paymentMethodValue: "",
        receiptOnRecord: 0,
      });
    }
  }

  useEffect(() => {
    // categoryList api
    dispatch(categoryList())

    // expenseList api
    setLoader(true)
    dispatch(expenseList({ page, limit, filter: search, sortingOrder, sortingField, }))

    return () => {
      dispatch({ type: 'RESET_EXPANCE_LIST' });
    }
  }, [])

  useEffect(() => {
    if (expenseListData) {
      setLoader(false)
    }
  }, [expenseListData])

  useEffect(() => {
    if (listView === "list-view")
      setEventListData([]);
  }, [listView])

  // List expense Data
  useEffect(() => {
    if (expenseListViewData && expenseListViewData?.expenseListData !== expenseListData) {

      if (expenseData && _.has(expenseData, 'data') && expenseData.success === true) {
        const totalEventsRecords = eventListData?.length > 0 ? expenseData?.total + eventListData?.length : expenseData?.total;
        setLoader(false)
        setBookingList(expenseListData)
        setTotalRecords(totalEventsRecords)
        setfetchList(false);
      }
      if (expenseListData && _.has(expenseListData, 'message') && expenseListData.success === false) {
        setLoader(false)
        setfetchList(false);
        errorPopUp(expenseListData.message)
      }
    }
  }, [expenseListData, expenseListViewData])

  useEffect(() => {
    setState({
      amount: "",
      date: "",
      dateFrom: "",
      dateTo: "",
      category: "",
      paidTo: "",
      description: "",
      durationValue: "",
      selectedOptions: "",
      paymentMethodValue: "",
      receiptOnRecord: 0,
    });
  }, [])

  useEffect(() => {
    if (state?.selectedOptions) {
      let filterParamsOnChange = {};
      if (dashboardSanpSelect?.value === 'This Month') {
        filterParamsOnChange = {
          from_date: moment().startOf('month').format('YYYY-MM-DD'),
          to_date: moment().endOf('month').format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingOrder: sortingOrder,
          sortingField: "created_at",
        };
      } else if (dashboardSanpSelect?.value === 'Last 3 Months') {
        filterParamsOnChange = {
          from_date: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
          to_date: moment().endOf('month').format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingOrder: sortingOrder,
          sortingField: "created_at",
        };
      } else if (dashboardSanpSelect?.value === 'YTD') {
        filterParamsOnChange = {
          from_date: moment().startOf('year').format('YYYY-MM-DD'),
          to_date: moment().format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingOrder: sortingOrder,
          sortingField: "created_at",
        };
      }
      else if (dashboardSanpSelect?.value === 'Last Year') {
        filterParamsOnChange = {
          from_date: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          to_date: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingOrder: sortingOrder,
          sortingField: "created_at",
        };
      }
      else if (dashboardSanpSelect?.value === "Custom") {
        filterParamsOnChange = {
          from_date: state?.dateFrom ? moment(state?.dateFrom)?.format("YYYY-MM-DD") : "",
          to_date: state?.dateTo ? moment(state?.dateTo)?.format("YYYY-MM-DD") : "",
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingOrder: sortingOrder,
          sortingField: "created_at",
        };
      } else {
        filterParamsOnChange = {
          from_date: "",
          to_date: "",
          expenses_category_id: state?.selectedOptions?.toString(),
          page,
          search_key: search,
          sortingOrder: sortingOrder,
          sortingField: "created_at",
        };
      }
      dispatch(expenseList(filterParamsOnChange))
    }
  }, [state?.selectedOptions, dispatch]);

  const handelExpenseEditeModal = (data) => {
    setEditState(data)
    setUpdateExpense(true)
  }

  const editeExpensesDetail = () => {
    dispatch(expenseUpdate({
      id: editState?.id,
      amount: editState?.amount,
      expense_date: moment(editState?.expense_date)?.format("YYYY-MM-DD"),
      expenses_category_id: editState?.expenses_category_id,
      paid_to: editState?.paid_to,
      method_of_payment: editState?.method_of_payment,
      description: editState?.description,
      receipt_on_record: editState?.receipt_on_record ? 1 : 0
    }))
    setUpdateExpense(false)

    // expenseList api
    dispatch(expenseList())
  }

  // Data Get By Pagination
  const getPageData = (page) => {
    setPage(page);
    setLoader(true)
    dispatch(expenseList({ page, limit, filter: search, sortingOrder, sortingField, }))
  }

  let disabledval = state.tbd === true ? true : false;
  const maxDate = moment();

  //Expense Download Report
  const handleDownloadReport = async () => {
    let filterParamsOnChange = {};
    if (dashboardSanpSelect?.value === 'This Month') {
      filterParamsOnChange = {
        from_date: moment().startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
      };
    } else if (dashboardSanpSelect?.value === 'Last 3 Months') {
      filterParamsOnChange = {
        from_date: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
      };
    } else if (dashboardSanpSelect?.value === 'YTD') {
      filterParamsOnChange = {
        from_date: moment().startOf('year').format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
      };
    }
    else if (dashboardSanpSelect?.value === 'Last Year') {
      filterParamsOnChange = {
        from_date: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        to_date: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
      };
    }
    else if (dashboardSanpSelect?.value === "Custom") {
      filterParamsOnChange = {
        from_date: state?.dateFrom ? moment(state?.dateFrom)?.format("YYYY-MM-DD") : "",
        to_date: state?.dateTo ? moment(state?.dateTo)?.format("YYYY-MM-DD") : "",
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
      };
    } else {
      filterParamsOnChange = {
        from_date: "",
        to_date: "",
        expenses_category_id: state?.selectedOptions?.toString(),
        search_key: search,
      };
    }

    dispatch(expenseDownload(filterParamsOnChange))
  }

  useEffect(() => {
    if (expenseDownloadData instanceof Blob) {
      downloadPdf(expenseDownloadData);
    }
  }, [expenseDownloadData]);

  const downloadPdf = (data) => {
    const blob = new Blob([data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'expenseReport.pdf';
    link.click();
  };

  return (
    <>
      <Loader loader={loader} />
      <div className="main-site fixed--header">
        <Header getMainRoute={'expenses'} />
        <main className="site-body">
          <section className="page-title contact--header Quotesheader">
            <div className="container">
              <div className="row">
                <div className="col-lg-auto title--col">
                  <div>
                    <h2 className="title">Expenses</h2>
                    {/* <p className="m-0">Total <span>{totalRecords}</span></p> */}
                  </div>
                </div>
                <div className="col-auto ml-auto d-flex align-items-center title-elems">
                  <button className="btn text-link mr-15" onClick={handleDownloadReport}>Download Report</button>
                  <button
                    onClick={(e) => addExpenses(e)}
                    className="btn btn-primary"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="vuesax/bold/add-square">
                        <g id="add-square">
                          <path
                            id="Vector"
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z"
                            fill="white"
                          />
                        </g>
                      </g>
                    </svg>{" "}
                    Add Expenses
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section className="middle-section">
            <div className="container">
              <div className="row no-gutters-mbl">
                <div className="col-12">
                  <div className="main-card">
                    <div className="card">
                      <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#ExpensesCollapse" aria-expanded="true" aria-controls="ExpensesCollapse">Expenses <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                      <div className="card-body pt-0 card-headers py-4 d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap">
                        <div className='d-flex categorySlect' style={{ gap: "5px" }}>
                          <h2 className="mt-2">View:</h2>
                          <div>
                            <Select
                              isDisabled={disabledval}
                              styles={dashboardSelectStyle}
                              className="task-view-filters"
                              isMulti={true}
                              placeholder={state?.selectedOptions?.length === categoryListOptionModal?.length ? "All Categories" : state?.selectedOptions?.length > 0 ? `Selected ${state?.selectedOptions?.length}` : "All Categories"}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={false}
                              options={categoryListOptionModal}
                              controlShouldRenderValue={false}
                              value={categoryListOptionModal?.filter((option) => state?.selectedOptions?.includes(option?.id))}
                              onChange={(options, value) => {
                                if (value.action === "select-option" && value.option.value === "allCategories") {
                                  setState({ ...state, selectedOptions: categoryListOptionModal?.map((opt) => opt.id) });
                                } else if (
                                  value.action === "deselect-option" &&
                                  value.option.value === "allCategories"
                                ) {
                                  setState({ ...state, selectedOptions: [] });
                                } else if (value?.action === "deselect-option") {
                                  const updatedOptions = state?.selectedOptions?.filter((o) => o !== "0");
                                  const newUpdated = updatedOptions?.filter((opt) => opt !== value?.option?.id)
                                  setState({ ...state, selectedOptions: newUpdated?.map((opt) => opt) });
                                } else if (value?.length === options?.length - 1) {
                                  setState({ ...state, selectedOptions: categoryListOptionModal?.map((opt) => opt?.id) });
                                } else {
                                  setState({ ...state, selectedOptions: options?.map((opt) => opt?.id) });
                                }
                              }}
                              components={{
                                Option: InputOption
                              }}
                            />
                          </div>
                          <div>
                            <Select
                              styles={dashboardSelectStyle}
                              className="task-view-filter"
                              placeholder="This Month"
                              value={dashboardSanpSelect}
                              defaultValue={dashboardSanpSelect}
                              options={snapshotOptions}
                              onChange={(data) => onChangeMonthAndCustomFilter(data)}
                            />
                          </div>
                          {dashboardSanpSelect?.value === 'Custom' &&
                            <>
                              <div className='d-flex' style={{ gap: "10px", alignItems: "center" }}>
                                <div className="floating-label mb-0">
                                  <DatePicker
                                    disabled={disabledval}
                                    type="text"
                                    name="dateFrom"
                                    className={
                                      state?.dateFromCls
                                        ? "floating-input " + state?.dateFromCls
                                        : "floating-input"
                                    }
                                    selected={state?.dateFrom}
                                    onChange={(date) => dateToBookingFrom(date)}
                                    placeholderText="From"
                                    ref={datepickerRef}
                                    autoComplete="off"
                                    maxDate={maxDate.toDate()}
                                  />
                                  <div
                                    onClick={() => showTimeSelection()}
                                    className="input-calendar-icon"
                                  >
                                    <img
                                      src={CALENDAR}
                                      alt=""
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                  {state?.dateFromErr ? (
                                    <span className="errorValidationMessage">
                                      {" "}
                                      {state?.dateFromErr}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="floating-label mb-0">
                                  <DatePicker
                                    filterDate={weekend}
                                    disabled={disabledval}
                                    type="text"
                                    name="dateTo"
                                    className={
                                      state?.dateToCls
                                        ? "floating-input " + state?.dateToCls
                                        : "floating-input"
                                    }
                                    selected={state?.dateTo}
                                    onChange={(date) => dateToBookingTo(date)}
                                    placeholderText="To"
                                    ref={datepickerRef}
                                    autoComplete="off"
                                  />
                                  <div
                                    onClick={() => showTimeSelection()}
                                    className="input-calendar-icon"
                                  >
                                    <img
                                      src={CALENDAR}
                                      alt=""
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                  {state?.dateToErr ? (
                                    <span className="errorValidationMessage">
                                      {" "}
                                      {state?.dateToErr}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div>
                                <button className="btn btn-secondary" onClick={handelFilter}>Filter</button>
                              </div>
                            </>
                          }
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3 mt-sm-0">
                          <div className="floating-label mb-0">
                            <input
                              placeholder='Search...'
                              type="text"
                              name="Search"
                              value={search}
                              ref={focusOutSearch}
                              onKeyDown={(e) => keyPressDownEvent(e)}
                              onChange={(e) => onSearchResult(e.target.value)}
                              className="floating-input"
                            />
                            <label class="bold-text">Search</label>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <div className="bookingResp_table table-responsive">
                          {listView == "list-view" ? <table className={(bookingList && bookingList.length > 0) ? "table table-lg table-striped contacts--table smart-table" : "table table-lg table-striped contacts--table smart-table empty-booking-table"}>
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="table--sorting">
                                    Expense Date
                                    <div className="sorting-icn">
                                      <a href="#desc" className={sortingField === 'expense_date' ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, 'expense_date', sortingOrder)}>
                                        <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                        </svg>
                                      </a>
                                    </div>
                                  </div>
                                </th>
                                <th >
                                  <div className="table--sorting">
                                    Category
                                    <div className="sorting-icn">
                                      <a href="#desc" className={sortingField === 'expenses_category_id' ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, 'expenses_category_id', sortingOrder)}>
                                        <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                        </svg>
                                      </a>
                                    </div>
                                  </div>
                                </th>
                                <th scope="col">Paid to</th>
                                <th scope="col">Method of payment</th>
                                <th scope="col">Description/Notes</th>
                                <th scope="col">Receipt</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {expenseListData && expenseListData.length > 0 ? (
                                _.map(expenseListData, (data) => (
                                  <tr key={data.id}>
                                    <td className='d-lg-none'>
                                      <div className="custom-control custom-checkbox">
                                      </div>
                                    </td>
                                    <td className="text-link" onClick={() => handelExpenseEditeModal(data)}>
                                      <Link>
                                        {moment(data?.expense_date).format("ddd, MMM DD, YYYY")}
                                      </Link>
                                    </td>
                                    <td className="">
                                      {data?.expenses_category_id ? categoryListData?.filter((val, key) => val?.id == data?.expenses_category_id).map((data) => data?.name)[0] : ''}
                                    </td>
                                    <td className="">{data?.paid_to}</td>
                                    <td className="">
                                      {data?.method_of_payment}
                                    </td>
                                    <td className="">
                                      {data?.description}
                                    </td >
                                    <td className="">
                                      {data?.receipt_on_record === 1 && (
                                        <>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17"
                                            height="17"
                                            viewBox="0 0 14 11"
                                            fill="none"
                                          >
                                            <mask
                                              id="mask0_541_3205"
                                              style={{ maskType: "luminance" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="14"
                                              height="11"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4.34778 8.31286L1.10444 5.03643L0 6.14429L4.34778 10.5364L13.6811 1.10786L12.5844 0L4.34778 8.31286Z"
                                                fill="white"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_541_3205)">
                                              <rect
                                                x="-12.7637"
                                                y="-14.6055"
                                                width="38.8889"
                                                height="39.2857"
                                                fill="#3BCFCE"
                                              />
                                              <mask
                                                id="mask1_541_3205"
                                                style={{ maskType: "luminance" }}
                                                maskUnits="userSpaceOnUse"
                                                x="-13"
                                                y="-15"
                                                width="40"
                                                height="40"
                                              >
                                                <rect
                                                  x="-12.7637"
                                                  y="-14.6055"
                                                  width="38.8889"
                                                  height="39.2857"
                                                  fill="white"
                                                />
                                              </mask>
                                              <g mask="url(#mask1_541_3205)"></g>
                                            </g>
                                          </svg>
                                        </>
                                      )}
                                    </td>
                                    <td className="">
                                      {data?.amount}
                                    </td>
                                    <td className="text-right table-action">
                                      <div onClick={() => handelDeleteData(data.id)}>
                                        <a href="#deleteTask" data-toggle="tooltip" data-placement="top" title="Delete" className="close-icn">
                                          <svg width="17px" height="17px" fill="var(--danger)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 174.239 174.239" style={{ "enableBackground": "new 0 0 174.239 174.239" }} xmlSpace="preserve">
                                            <g>
                                              <path d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
                                                        c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
                                              />
                                              <path d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
                                                        s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
                                                        c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
                                                        c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"/>
                                            </g>
                                          </svg>
                                        </a>
                                      </div>
                                    </td>
                                  </tr >
                                ))
                              ) : fetchList ? (
                                <tr>
                                  <td colSpan="8" className="bg-white">
                                    <div className="no--contacts">
                                      {(search && search !== '') || (dashboardSanpSelect?.value === 'Last Year') ? (
                                        <>
                                          <h5>You don’t have any expenses matching the criteria.</h5>
                                          <h5> Track all your business expenses and generate reports.</h5>
                                        </>
                                      ) : null}
                                      <p>
                                        <a href="https://www.clientlook.com/export-your-google-contacts" rel="noopener noreferrer" target="_blank">
                                        </a>
                                      </p>
                                      <button className="btn btn-primary mt-5" onClick={(e) => addExpenses(e)}>Add Expenses</button>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td colSpan="8" className="bg-white">
                                    <div className="no--contacts">
                                      <h5>You don’t have any expenses matching the criteria.</h5>
                                      <h5> Track all your business expenses and generate reports.</h5>
                                      <p>
                                        <a href="https://www.clientlook.com/export-your-google-contacts" rel="noopener noreferrer" target="_blank">
                                        </a>
                                      </p>
                                      <button className="btn btn-primary mt-5" onClick={(e) => addExpenses(e)}>Add Expenses</button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table > :
                            <div className="calender-list">
                            </div>
                          }
                        </div>
                        {totalRecords && listView == "list-view" ? <PaginationFooter getPageData={(data) => getPageData(data)} pageNo={page} totalRecords={totalRecords} limit={limit} /> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        {/* Add Expenses Modal */}
        {updateExpense ?
          (
            <>
              <Modal show={updateExpense} onHide={() => setUpdateExpense(false)} size="lg" className="appoitment-form" centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Edit Expense
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="row">
                      <div className="form-group col-md-3">
                        <div className="floating-label">
                          <input
                            placeholder='00.00'
                            type="number"
                            name="amount"
                            value={editState?.amount || ''}
                            onChange={(e) => setEditeInputValue(e, "string", null, null)}
                            className="floating-input"
                          />
                          <label class="bold-text">Amount</label>
                          {editState?.amountErr ? (
                            <span className="errorValidationMessage">
                              {" "}
                              {editState?.amountErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <div className="floating-label">
                          <DatePicker
                            disabled={disabledval}
                            type="text"
                            name="date"
                            className={
                              editState?.dateCls
                                ? "floating-input " + editState?.dateCls
                                : "floating-input"
                            }
                            selected={new Date(editState?.expense_date)}
                            onChange={(expense_date) => editDateForBooking(expense_date)}
                            placeholderText="Expense Date"
                            ref={datepickerRef}
                            autoComplete="off"
                          />
                          <div
                            onClick={() => showTimeSelection()}
                            className="input-calendar-icon"
                          >
                            <img
                              src={CALENDAR}
                              alt=""
                              width="20"
                              height="20"
                            />
                          </div>
                          {editState?.dateErr ? (
                            <span className="errorValidationMessage">
                              {" "}
                              {editState?.dateErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="floating-label">
                          <Select
                            styles={selectStyle}
                            className="floating-select"
                            components={{ MenuList: addExpensesCategory, NoOptionsMessage: () => null }}
                            value={categoryListOption.filter((value) => value?.id === editState?.expenses_category_id) || editState?.expenses_category_id}
                            isSearchable={false}
                            options={categoryListOption}
                            placeholder="Category"
                            onChange={data => {
                              setEditState({ ...editState, expenses_category_id: data?.id, id: editState?.id, durationErr: "", durationCls: "" }); setExpensesMessage('')
                            }}
                          />
                          {editState?.durationErr ? (
                            <span className="errorValidationMessage">
                              {" "}
                              {state?.durationErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group col-md-7">
                        <div className="floating-label">
                          <input
                            placeholder='Person or Business Name'
                            type="text"
                            name="paid_to"
                            value={editState?.paid_to || ''}
                            onChange={(e) =>
                              setEditeInputValue(e, "paid_to", null, null)
                            }
                            className="floating-input"
                          />
                          <label class="bold-text">Paid To (Optional)</label>
                        </div>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="floating-label">
                          <Select
                            styles={selectStyle}
                            className="floating-select"
                            value={PaymentMethodTypeOption.filter((value) => value?.value === editState?.method_of_payment)}
                            isSearchable={false}
                            options={PaymentMethodTypeOption}
                            placeholder="Method of Payment (Optional)"
                            onChange={data => { setEditState({ ...editState, method_of_payment: data.value }); setExpensesMessage('') }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group col-md-7">
                        <div className="floating-label">
                          <input
                            placeholder='Input your description'
                            type="text"
                            name="description"
                            value={editState?.description || ''}
                            onChange={(e) =>
                              setEditeInputValue(e, "description", null, null)
                            }
                            className="floating-input"
                          />
                          <label class="bold-text">Description (Optional)</label>
                        </div>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setEditState({
                                ...editState,
                                receiptOnRecord: e.target.checked,
                                tbd: false,
                              })
                            }
                            checked={editState?.receipt_on_record}
                            className="custom-control-input"
                            id="customCheck3"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck3"
                          >
                            {" "}
                            <strong>Receipt on Record</strong>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn btn-dark" onClick={() => setUpdateExpense(false)}>Cancel</button>
                  <button type="button" onClick={(e) => editeExpensesDetail(e)} className="btn btn-primary">Save</button>
                </Modal.Footer>
              </Modal>
            </>
          ) :
          (
            <>
              <Modal show={addNewExpense} onHide={() => setAddNewExpense(false)} size="lg" className="appoitment-form" centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Add New Expense
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="row">
                      <div className="form-group col-md-3">
                        <div className="floating-label">
                          <input
                            placeholder='00.00'
                            type="number"
                            name="amount"
                            value={state?.amount || ''}
                            onChange={(e) => setInputValue(e, "string", null, null)}
                            className="floating-input"
                          />
                          <label class="bold-text">Amount</label>
                          {state?.amountErr ? (
                            <span className="errorValidationMessage">
                              {" "}
                              {state?.amountErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <div className="floating-label">
                          <DatePicker
                            disabled={disabledval}
                            type="text"
                            name="date"
                            className={
                              state?.dateCls
                                ? "floating-input " + state?.dateCls
                                : "floating-input"
                            }
                            selected={state?.date}
                            onChange={(date) => dateForBooking(date)}
                            placeholderText="Expense Date"
                            ref={datepickerRef}
                            autoComplete="off"
                          />
                          <div
                            onClick={() => showTimeSelection()}
                            className="input-calendar-icon"
                          >
                            <img
                              src={CALENDAR}
                              alt=""
                              width="20"
                              height="20"
                            />
                          </div>
                          {state?.dateErr ? (
                            <span className="errorValidationMessage">
                              {" "}
                              {state?.dateErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="floating-label">
                          <Select
                            styles={selectStyle}
                            className="floating-select"
                            components={{ MenuList: addExpensesCategory, NoOptionsMessage: () => null }}
                            value={state?.durationValue}
                            isSearchable={false}
                            options={categoryListOption}
                            placeholder="Category"
                            onChange={data => { setState({ ...state, durationValue: data, durationErr: "", durationCls: "" }); setExpensesMessage('') }}
                          />
                          {state?.durationErr ? (
                            <span className="errorValidationMessage">
                              {" "}
                              {state?.durationErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group col-md-7">
                        <div className="floating-label">
                          <input
                            placeholder='Person or Business Name'
                            type="text"
                            name="paidTo"
                            value={state?.paidTo || ''}
                            onChange={(e) =>
                              setInputValue(e, "paidTo", null, null)
                            }
                            className="floating-input"
                          />
                          <label class="bold-text">Paid To (Optional)</label>
                        </div>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="floating-label">
                          <Select
                            defaultValue={state?.paymentMethodValue}
                            styles={selectStyle}
                            className="floating-select"
                            value={state?.paymentMethodValue}
                            isSearchable={false}
                            options={PaymentMethodTypeOption}
                            placeholder="Method of Payment (Optional)"
                            onChange={data => { setState({ ...state, paymentMethodValue: data }); setExpensesMessage('') }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group col-md-7">
                        <div className="floating-label">
                          <input
                            placeholder='Input your description'
                            type="text"
                            name="description"
                            value={state?.description || ''}
                            onChange={(e) =>
                              setInputValue(e, "description", null, null)
                            }
                            className="floating-input"
                          />
                          <label class="bold-text">Description (Optional)</label>
                        </div>
                      </div>
                      <div className="form-group col-md-5">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setState({
                                ...state,
                                receiptOnRecord: e.target.checked,
                                tbd: false,
                              })
                            }
                            checked={state?.receiptOnRecord}
                            className="custom-control-input"
                            id="customCheck3"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck3"
                          >
                            {" "}
                            <strong>Receipt on Record</strong>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn btn-dark" onClick={() => setAddNewExpense(false)}>Cancel</button>
                  <button type="button" className="btn btn-secondary" onClick={(e) => saveAndOnOtherHandelClick(e)}>Save and Add Another</button>
                  <button type="button" onClick={(e) => saveExpensesDetail(e)} className="btn btn-primary">Save</button>
                </Modal.Footer>
              </Modal>
            </>
          )
        }

        {/* Add New Expense Category Modal*/}
        <Modal show={expenseCategoryModalShow} onHide={() => setExpenseCategoryModalShow(false)} className="appoitment-form" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              Add New Expense Category
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="floating-label">
                <textarea className="new-input floating-textarea floating-input" name="service" value={categoryState.category || ''} placeholder="Category Name" onChange={(e) => setCategoryState({ ...categoryState, category: e.target.value, categoryCls: '', categoryErr: '' })}></textarea>
                {categoryState?.categoryErr ? (
                  <span className="errorValidationMessage">
                    {" "}
                    {categoryState?.categoryErr}
                  </span>
                ) : (
                  ""
                )}
                <label class="bold-text">Category Name</label>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-dark" onClick={() => setExpenseCategoryModalShow(false)}>Cancel</button>
            <button type="button" onClick={() => saveExpensesData(categoryState.category)} className="btn btn-primary">Save</button>
          </Modal.Footer>
        </Modal>
      </div >

      {/* Subscription Modal*/}
      < SubscriptionPlan
        loader={(data) => setLoader(data)}
        openSubscriptionModal={subscriptionModalShow}
        closeSubscriptionModal={() => setSubscriptionModalShow(false)}
        updatePlanDetail={(data) => {
          setSubscriptionModalShow(false);
          setLoader(false);
        }}
        currentPlan={currentPlan}
      />
    </>
  )
}

export const ViewExpenses = withRouter(ViewExpensesPage)
