export const DEFAULT_STATE = {
    loading: false,
    microsoftErrorData: {},
    getOutlookAuthLinkData: {},
    getOutlookAuthTokenData: {},
    getAllOutlookMailFoldersData: {},
    outlookMessagesRequestData: {},
    getAllOutlookMessagesData: {},
    getOutlookMessageData: {},
    getOutlookMessageAttachmentsData: {},
    draftCreatedData: {},
    updatedMessageData: {},
    isOutlookMessageSent: null
};