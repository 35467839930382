export const DEFAULT_STATE = {
  addDashboardTaskData: {},
  listDashboardTaskData: {},
  deleteDashboardTaskData: {},
  getBusinessSnapshotData: {},
  listDashboardNotificationData: {},
  deleteDashboardNotificationData: {},
  deleteAllNotificationData: {},
  markAsReadNotificationData: {},
  loginStatusData: {},
};