import axios from "axios";
import { request } from "../request/axios.request";

// GET GOOGLE EVENT LIST

export async function getCalenderEventsListApi(data) {
  const loggedInUserEmail = data.data;
    const response = await request({
      url: `https://www.googleapis.com/calendar/v3/calendars/${loggedInUserEmail}/events`,
      method: "get",
    });
      return response;
    }

// DELETE GOOGLE EVENT     

export async function deleteCalenderEventListApi(data) {
  let loggedInUserEmail = data.data.socialUserEmail;
  let eventId = data.data.eventId;
  const response = await request({
    url : `https://www.googleapis.com/calendar/v3/calendars/${loggedInUserEmail}/events/${eventId}`,
    method:"DELETE",
  });
  return response;
}
export async function deleteBatchCalendarEventListApi(data) {
  let loggedInUserEmail = data.data.socialUserEmail;
  let eventIds = data.data.eventIds;
  let token = data.data.token;

  // Use a unique boundary string
  const boundary = 'batch_boundary';
  let batchRequestBody = '';

  // Build the batch request body
  for (let i = 0; i < eventIds.length; i++) {
    const eventId = eventIds[i].id;
    batchRequestBody += `--${boundary}\r\n`;
    batchRequestBody += 'Content-Type: application/http\r\n';
    batchRequestBody += 'Content-Transfer-Encoding: binary\r\n';
    batchRequestBody += '\r\n';
    batchRequestBody += `DELETE /calendar/v3/calendars/${loggedInUserEmail}/events/${eventId} HTTP/1.1\r\n`;
    batchRequestBody += 'Host: www.googleapis.com\r\n'; // Include Host header
    batchRequestBody += '\r\n';
  }

  // End the batch request body
  batchRequestBody += `--${boundary}--\r\n`;

  const contentLength = Buffer.byteLength(batchRequestBody, 'utf8');

  try {
    const response = await axios.post(
      'https://www.googleapis.com/batch/calendar/v3',
      batchRequestBody,
      {
        headers: {
          'Content-Type': `multipart/mixed; boundary=${boundary}`,
          'Authorization': `Bearer ${token}`,
          'Content-Length': contentLength, // Correct Content-Length header
        },
      }
    );
    // Process the batch response
    return { success: true, data: [] };
  } catch (error) {
    return { success: false, error: error.message };
  }
}


// export async function deleteBatchCalendarEventListApi(data) {
//   let loggedInUserEmail = data.data.socialUserEmail;
//   let eventIds = data.data.eventIds; 

//   const batchRequests = eventIds.map(eventId => ({
//     method: 'DELETE',
//     url: `https://www.googleapis.com/calendar/v3/calendars/${loggedInUserEmail}/events/${eventId}`,
//   }));

//   try {
//     const response = await axios.post(
//       'https://www.googleapis.com/batch',
//       batchRequests,
//       {
//         headers: {
//           'Content-Type': 'application/http',
//         },
//       }
//     );
//     // Process the batch response
//     return response.data;
//   } catch (error) {
//     return { success: false, error: error.message };
//   }
// }

// EDIT GOOGLE EVENT 

export async function editCalenderEventListApi(data) {

  let loggedInUserEmail = data.data.email;
  let eventId = data.data.eventId;

  let editedData = {
    "start": {
      "dateTime": data.data.start
    },
    "end": {
      "dateTime": data.data.end
    },
    'summary' : data.data.summary,
    'location': data.data.location,
    'attendees': data.data.attendees,
    'description': data.data.description,
    'reminders': data.data.reminders,
  }
  
  const response = await request({
    url : `https://www.googleapis.com/calendar/v3/calendars/${loggedInUserEmail}/events/${eventId}`,
    method:"PUT",
    data:editedData
  });
  return response;
}


// GET MICROSOFT EVENT LIST

export async function getMicrosoftEventsListApi() {
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/events`,
    method: "get",
  });
  return response;
  }    

  
// DELETE MICROSOFT EVENT 

export async function deleteMicrosoftEventApi(data) {
  let eventId = data.data;
  const response = await request({
    url : `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
    method:"DELETE",
  });
  return response;
}

// EDIT MICROSOFT EVENT 

export async function editMicrosoftEventApi(data) {

  let eventId = data.data.eventId;

  // let editedData = {
  //   "start": {
  //     "dateTime": data.data.start
  //   },
  //   "end": {
  //     "dateTime": data.data.end
  //   },
  //   'summary' : data.data.summary,
  //   'location': data.data.location,
  //   'attendees': data.data.attendees,
  //   'description': data.data.description,
  //   'reminders': data.data.reminders,
  // }

  let editedData = {
    "subject": data.data.summary,
    "start": {
          "dateTime": data.data.start,
          "timeZone": 'UTC'
        },
        "end": {
          "dateTime": data.data.end,
          "timeZone": 'UTC'
        },
  }

  const response = await request({
    url : `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
    method:"PATCH",
    data:editedData
  });
  return response;
}
 