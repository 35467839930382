import { put, takeLatest } from 'redux-saga/effects';
import {
    listInvoiceResponse, SUCCESS_LIST_INVOICE, ERROR_LIST_INVOICE, LIST_INVOICE,
    addInvoiceResponse, SUCCESS_ADD_INVOICE, ERROR_ADD_INVOICE, ADD_INVOICE,
    getInvoiceByIdResponse, SUCCESS_GET_INVOICE_BY_ID, ERROR_GET_INVOICE_BY_ID, GET_INVOICE_BY_ID,
    updateInvoiceResponse, SUCCESS_UPDATE_INVOICE, ERROR_UPDATE_INVOICE, UPDATE_INVOICE,
    sendToCustomerInvoiceResponse, SUCCESS_SEND_TO_CUSTOMER_INVOICE, ERROR_SEND_TO_CUSTOMER_INVOICE, SEND_TO_CUSTOMER_INVOICE,
    viewCustomerInvoiceResponse, SUCCESS_VIEW_CUSTOMER_INVOICE, ERROR_VIEW_CUSTOMER_INVOICE, VIEW_CUSTOMER_INVOICE,
    markPaidInvoiceResponse, SUCCESS_MARK_PAID_INVOICE, ERROR_MARK_PAID_INVOICE, MARK_PAID_INVOICE,
    invoiceMakePaymentResponse, SUCCESS_INVOICE_MAKE_PAYMENT, ERROR_INVOICE_MAKE_PAYMENT, INVOICE_MAKE_PAYMENT,
    getPaidInvoiceResponse, SUCCESS_GET_PAID_INVOICE, ERROR_GET_PAID_INVOICE, GET_PAID_INVOICE,
    deleteInvoiceResponse, SUCCESS_DELETE_INVOICE, ERROR_DELETE_INVOICE, DELETE_INVOICE,
    updateBasicInvoiceResponse, SUCCESS_UPDATE_BASIC_INVOICE, ERROR_UPDATE_BASIC_INVOICE, UPDATE_BASIC_INVOICE,
    listInvoiceTemplateResponse, SUCCESS_LIST_INVOICE_TEMPLATE, ERROR_LIST_INVOICE_TEMPLATE, LIST_INVOICE_TEMPLATE,
    addInvoiceTemplateResponse, SUCCESS_ADD_INVOICE_TEMPLATE, ERROR_ADD_INVOICE_TEMPLATE, ADD_INVOICE_TEMPLATE,
    getInvoiceTemplateByIdResponse, SUCCESS_GET_INVOICE_TEMPLATE_BY_ID, ERROR_GET_INVOICE_TEMPLATE_BY_ID, GET_INVOICE_TEMPLATE_BY_ID,
    getMessageBodyResponse, SUCCESS_GET_MESSAGE_BODY_INVOICE, ERROR_GET_MESSAGE_BODY_INVOICE, GET_MESSAGE_BODY_INVOICE, UPDATE_MESSAGE_BODY_INVOICE,
    updateMessageBodyResponse, SUCCESS_UPDATE_MESSAGE_BODY_INVOICE, ERROR_UPDATE_MESSAGE_BODY_INVOICE,
} from './invoice.action';
import {
    listInvoiceApi, addInvoiceApi, getInvoiceByIdApi, updateInvoiceApi, sendToCustomerInvoiceApi, viewCustomerInvoiceApi,
    markPaidInvoiceApi, invoiceMakePaymentApi, getPaidInvoiceApi, deleteInvoiceApi, updateBasicInvoiceApi, listInvoiceTemplateApi,
    getInvoiceTemplateByIdApi, addInvoiceTemplateApi ,getMessageBodyInvoiceApi ,updateMessageBodyInvoiceApi
} from '../../../api/index';
import _ from 'lodash'
import { successNotification } from '../../common/notification-alert';

// List Invoice Data
function* listInvoiceRequest(data) {
    let getData = yield listInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(listInvoiceResponse(SUCCESS_LIST_INVOICE, getData.data));
    } else {
        yield put(listInvoiceResponse(ERROR_LIST_INVOICE, getData.data));
    }
}

export function* listInvoiceWatcher() {
    yield takeLatest(LIST_INVOICE, listInvoiceRequest);
}

// Add Invoice Data
function* addInvoiceRequest(data) {
    let getData = yield addInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(addInvoiceResponse(SUCCESS_ADD_INVOICE, getData.data));
    } else {
        yield put(addInvoiceResponse(ERROR_ADD_INVOICE, getData.data));
    }
}

export function* addInvoiceWatcher() {
    yield takeLatest(ADD_INVOICE, addInvoiceRequest);
}

// Get Invoice By Id
function* getInvoiceByIdRequest(data) {
    let getData = yield getInvoiceByIdApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getInvoiceByIdResponse(SUCCESS_GET_INVOICE_BY_ID, getData.data));
    } else {
        yield put(getInvoiceByIdResponse(ERROR_GET_INVOICE_BY_ID, getData.data));
    }
}

export function* getInvoiceByIdWatcher() {
    yield takeLatest(GET_INVOICE_BY_ID, getInvoiceByIdRequest);
}

// Update Invoice
function* updateInvoiceRequest(data) {
    let getData = yield updateInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(updateInvoiceResponse(SUCCESS_UPDATE_INVOICE, getData.data));
    } else {
        yield put(updateInvoiceResponse(ERROR_UPDATE_INVOICE, getData.data));
    }
}

export function* updateInvoiceWatcher() {
    yield takeLatest(UPDATE_INVOICE, updateInvoiceRequest);
}

// Send To Customer Invoice
function* sendToCustomerInvoiceRequest(data) {
    let getData = yield sendToCustomerInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(sendToCustomerInvoiceResponse(SUCCESS_SEND_TO_CUSTOMER_INVOICE, getData.data));
    } else {
        yield put(sendToCustomerInvoiceResponse(ERROR_SEND_TO_CUSTOMER_INVOICE, getData.data));
    }
}

export function* sendToCustomerInvoiceWatcher() {
    yield takeLatest(SEND_TO_CUSTOMER_INVOICE, sendToCustomerInvoiceRequest);
}

// View Customer Invoice
function* viewCustomerInvoiceRequest(data) {
    let getData = yield viewCustomerInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(viewCustomerInvoiceResponse(SUCCESS_VIEW_CUSTOMER_INVOICE, getData.data));
    } else {
        yield put(viewCustomerInvoiceResponse(ERROR_VIEW_CUSTOMER_INVOICE, getData.data));
    }
}

export function* viewCustomerInvoiceWatcher() {
    yield takeLatest(VIEW_CUSTOMER_INVOICE, viewCustomerInvoiceRequest);
}

// Mark Paid Invoice
function* markPaidInvoiceRequest(data) {
    let getData = yield markPaidInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(markPaidInvoiceResponse(SUCCESS_MARK_PAID_INVOICE, getData.data));
    } else {
        yield put(markPaidInvoiceResponse(ERROR_MARK_PAID_INVOICE, getData.data));
    }
}

export function* markPaidInvoiceWatcher() {
    yield takeLatest(MARK_PAID_INVOICE, markPaidInvoiceRequest);
}

// Revise Invoice
function* invoiceMakePaymentRequest(data) {
    let getData = yield invoiceMakePaymentApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(invoiceMakePaymentResponse(SUCCESS_INVOICE_MAKE_PAYMENT, getData.data));
    } else {
        yield put(invoiceMakePaymentResponse(ERROR_INVOICE_MAKE_PAYMENT, getData.data));
    }
}

export function* invoiceMakePaymentWatcher() {
    yield takeLatest(INVOICE_MAKE_PAYMENT, invoiceMakePaymentRequest);
}

// Get Paid Invoice
function* getPaidInvoiceRequest() {
    let getData = yield getPaidInvoiceApi();
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getPaidInvoiceResponse(SUCCESS_GET_PAID_INVOICE, getData.data));
    } else {
        yield put(getPaidInvoiceResponse(ERROR_GET_PAID_INVOICE, getData.data));
    }
}

export function* getPaidInvoiceWatcher() {
    yield takeLatest(GET_PAID_INVOICE, getPaidInvoiceRequest);
}

// Delete Invoice 
function* deleteInvoiceRequest(data) {
    let getData = yield deleteInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(deleteInvoiceResponse(SUCCESS_DELETE_INVOICE, getData.data));
    } else {
        yield put(deleteInvoiceResponse(ERROR_DELETE_INVOICE, getData.data));
    }
}

export function* deleteInvoiceWatcher() {
    yield takeLatest(DELETE_INVOICE, deleteInvoiceRequest);
}

// Update Basic Invoice
function* updateBasicInvoiceRequest(data) {
    let getData = yield updateBasicInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(updateBasicInvoiceResponse(SUCCESS_UPDATE_BASIC_INVOICE, getData.data));
    } else {
        yield put(updateBasicInvoiceResponse(ERROR_UPDATE_BASIC_INVOICE, getData.data));
    }
}

export function* updateBasicInvoiceWatcher() {
    yield takeLatest(UPDATE_BASIC_INVOICE, updateBasicInvoiceRequest);
}

// List Invoice Template
function* listInvoiceTemplateRequest(data) {
    let getData = yield listInvoiceTemplateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(listInvoiceTemplateResponse(SUCCESS_LIST_INVOICE_TEMPLATE, getData.data));
    } else {
        yield put(listInvoiceTemplateResponse(ERROR_LIST_INVOICE_TEMPLATE, getData.data));
    }
}

export function* listInvoiceTemplateWatcher() {
    yield takeLatest(LIST_INVOICE_TEMPLATE, listInvoiceTemplateRequest);
}

// Invoice Template By Id
function* getInvoiceTemplateByIdRequest(data) {
    let getData = yield getInvoiceTemplateByIdApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getInvoiceTemplateByIdResponse(SUCCESS_GET_INVOICE_TEMPLATE_BY_ID, getData.data));
    } else {
        yield put(getInvoiceTemplateByIdResponse(ERROR_GET_INVOICE_TEMPLATE_BY_ID, getData.data));
    }
}

export function* getInvoiceTemplateByIdWatcher() {
    yield takeLatest(GET_INVOICE_TEMPLATE_BY_ID, getInvoiceTemplateByIdRequest);
}

// Add Invoice Template Data
function* addInvoiceTemplateRequest(data) {
    let getData = yield addInvoiceTemplateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(addInvoiceTemplateResponse(SUCCESS_ADD_INVOICE_TEMPLATE, getData.data));
    } else {
        yield put(addInvoiceTemplateResponse(ERROR_ADD_INVOICE_TEMPLATE, getData.data));
    }
}

export function* addInvoiceTemplateWatcher() {
    yield takeLatest(ADD_INVOICE_TEMPLATE, addInvoiceTemplateRequest);
}


// Update Invoice Message Body
function* updateMessageBodyInvoiceRequest(data) {  
    let getData = yield updateMessageBodyInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
         successNotification(getData.data.message) 
        yield put(updateMessageBodyResponse(SUCCESS_UPDATE_MESSAGE_BODY_INVOICE, getData.data));
    } else {
        yield put(updateMessageBodyResponse(ERROR_UPDATE_MESSAGE_BODY_INVOICE, getData.data));
    }
}

export function* updateMessageBodyInvoiceWatcher() {
    yield takeLatest(UPDATE_MESSAGE_BODY_INVOICE, updateMessageBodyInvoiceRequest);
}
// get Invoice Message Body
function* getMessageBodyInvoiceRequest(data) {  
    let getData = yield getMessageBodyInvoiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        /* successNotification(getData.data.message) */
        yield put(getMessageBodyResponse(SUCCESS_GET_MESSAGE_BODY_INVOICE, getData.data));
    } else {
        yield put(getMessageBodyResponse(ERROR_GET_MESSAGE_BODY_INVOICE, getData.data));
    }
}

export function* getMessageBodyInvoiceWatcher() {
    yield takeLatest(GET_MESSAGE_BODY_INVOICE,getMessageBodyInvoiceRequest);
}