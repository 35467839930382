import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from '../../../component/frontend/header/header'
import { Footer } from '../../../component/frontend/footer/footer'
import { Link, withRouter } from "react-router-dom";
import { Loader } from '../../../component/frontend/loader/loader'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CALENDAR from "../../../../assets/images/calendar.png"
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import { constants, selectStyle, CustomValueContainer } from "../../../../common/constants";
import { fieldValidator, usePrevious, setImagePath } from '../../../../common/custom';
import { addInvoice, updateBasicInvoice, deleteInvoice } from '../../../../duck/invoice/invoice.action';
import { listServiceWithSource } from '../../../../duck/lead/lead.action';
import { LIST_INVOICES, ADD_INVOICE_BASE } from "../../../../routing/routeContants";
import ORANGE_ARROW from '../../../../assets/images/orange-arrow.svg'
import { validateInputs } from '../../../../common/validation';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import AsyncSelect from 'react-select/async';
import { getContactListOptionValue } from '../../../../../api/sdk/contact';
import Swal from 'sweetalert2'
import moment from 'moment'
import _ from 'lodash';
import Select from "react-select";
import { AddOrganization } from './addOrganization'
import { AddService } from './addService'

export const NewAddBasicInvoice = props => {

    const dispatch = useDispatch();
    const datepickerRef = useRef();
    const dueDatepickerRef = useRef();
    const [loader, setLoader] = useState(false);
    const [isCollapse, setIsCollapse] = useState('');
    const [serviceMessage, setServiceMessage] = useState('');
    const [serviceModalShow, setServiceModalShow] = useState(false);
    const phoneTypeOptions = [{ value: 'Mobile', label: 'Mobile' }, { value: 'Work', label: 'Work' }, { value: 'Home', label: 'Home' }];
    const [state, setState] = useState({
        correctInput: '', wrongInput: constants.WRONG_INPUT, invoiceName: 'invoice:', timeValue: '', location: '', internalNotes: '',
        timeShiftValue: { value: 'AM', label: "AM" }, timeShiftOptions: [{ value: "AM", label: "AM" }, { value: "PM", label: "PM" }],
        durationValue: '', durationOptions: [{ value: "1 Hours", label: "1 Hours" }, { value: "2 Hours", label: "2 Hours" },
        { value: "3 Hours", label: "3 Hours" }, { value: "Half Day", label: "Half Day" }, { value: "Full Day", label: "Full Day" }, { value: "Custom", label: "Custom" },
        { value: "N/A", label: "N/A" }], customDuration: '', lat_long: '', totalAmount: 0, deposite: 0, date: '', dateErr: '', dateCls: '',
        dueDate: '', dueDateErr: '', dueDateCls: '', firstName: '', lastName: '', email: '', phone: '', organization: '', title: '', phoneType: { value: 'Mobile', label: 'Mobile' },
        firstNameCls: '', emailCls: '', phoneCls: '', firstNameErr: '', emailErr: '', phoneErr: '',
        invoiceSerialNo: '', selectTemplate: '', redirectPage: false, itemHeadingDisabled: true, itemNameDisabled: true, itemDiscriptionDisabled: true,
        itemChargeDisabled: true, depositRequired: 0, depositOnline: 0, invoiceNameCls: '', invoiceNameErr: '',
        servicesListOptions: [], selectService: '', selectServiceErr: '', selectServiceCls: '', new_contact: '1',
        invoiceId: '', quoteId: '',
        address_line_1: "",
        addressOneErr: "",
        addressOneCls: "",
        address_line_2: "",
        addressTwoErr: "",
        addressTwoCls: "",
        city: "",
        cityErr: "",
        cityCls: "",
        state: "",
        statesErr: "",
        statesCls: "",
        zip: "",
        zipErr: "",
        zipCls: "",
        website: "",
        websiteErr: "",
        websiteCls: "",
        other_1: "",
        other_1Err: "",
        other_1Cls: "",
        other_2: "",
        other_2Err: "",
        other_2Cls: "",
        other_3: "",
        other_3Err: "",
        other_3Cls: "",
        birthday: "",
        birthdayErr: "",
        birthdayCls: "",
        selectDisabled: false
    });

    const addInvoiceData = useSelector(state => state.invoice.addInvoiceData);
    const prevAddInvoiceData = usePrevious({ addInvoiceData });
    const deleteInvoiceData = useSelector(state => state.invoice.deleteInvoiceData);
    const prevDeleteInvoiceData = usePrevious({ deleteInvoiceData });
    const listServiceWithSourceData = useSelector(state => state.lead.listServiceWithSourceData);
    const prevListServiceWithSourceData = usePrevious({ listServiceWithSourceData });
    // Organization Check 
    const [businessProfileModalShow, setBusinessProfileModalShow] = useState(false);

    const addExtraService = ({ innerRef, innerProps, isDisabled, children }) =>
        !isDisabled ? (
            <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
                {children}
                <button
                    type="button"
                    className="btn text-link text-left btn-sm btn-block"
                    onClick={(e) => setServiceModalShow(true)}
                >Add New Service</button>
            </div>
        ) : null;

    // Set Mobile View
    useEffect(() => {
        const resizeListener = () => {
            // change width from the state object
            if (window.innerWidth < 991) {
                setIsCollapse('collapse')
            } else {
                setIsCollapse('')
            }
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);
        resizeListener();
        if (props.history.location && props.history.location.state && props.history.location.state.invoiceDataState) {
            const InvoiceData = props.history.location.state.invoiceDataState.invoice;
            const ServiceType = props.history.location.state.invoiceDataState.service_types;
            let services = _.map(ServiceType, (data) => { return { value: data.id, label: data.name } })
            _.remove(services, function (opt) {
                return opt.label === "Magic and Comedy Show (sample)";
            });
            let durationValue, customDuration;
            if (_.some(state.durationOptions, { value: InvoiceData.event_duration, label: InvoiceData.event_duration })) {
                durationValue = { value: InvoiceData.event_duration, label: InvoiceData.event_duration };
                customDuration = ''
            } else {
                if (InvoiceData.event_duration !== null) {
                    durationValue = { value: 'Custom', label: 'Custom' }
                    customDuration = InvoiceData.event_duration
                } else {
                    durationValue = ''
                }
            }
            setState({
                ...state, new_contact: '0', contactSelectValue: InvoiceData.contact, invoiceName: InvoiceData.name,
                selectTemplate: InvoiceData.template && InvoiceData.template.id ? { value: InvoiceData.template.id, label: InvoiceData.template.name } : '',
                location: InvoiceData.event_location !== null ? InvoiceData.event_location : '',
                lat_long: InvoiceData.event_lat_long !== null ? InvoiceData.event_lat_long : '',
                date: InvoiceData.event_date !== null ? moment(InvoiceData.event_date).toDate() : '',
                dueDate: InvoiceData.due_date !== null ? moment(InvoiceData.due_date).toDate() : '',
                timeValue: InvoiceData.event_date !== null ? moment(InvoiceData.event_date).toDate() : '',
                durationValue, customDuration, invoiceId: InvoiceData.id,
                firstName: InvoiceData.contact && InvoiceData.contact.first_name ? InvoiceData.contact.first_name : '',
                lastName: InvoiceData.contact && InvoiceData.contact.last_name ? InvoiceData.contact.last_name : '',
                email: InvoiceData.contact && InvoiceData.contact.email ? InvoiceData.contact.email : '',
                phone: InvoiceData.contact && InvoiceData.contact.phone ? InvoiceData.contact.phone : '',
                phoneType: InvoiceData.contact && InvoiceData.contact.phone_type ? { value: InvoiceData.contact.phone_type, label: InvoiceData.contact.phone_type } : '',
                organization: InvoiceData.contact && InvoiceData.contact.organization ? InvoiceData.contact.organization : '',
                title: InvoiceData.contact && InvoiceData.contact.title ? InvoiceData.contact.title : '',
                servicesListOptions: services,
                selectService: InvoiceData.service_type && InvoiceData.service_type.id ? { value: InvoiceData.service_type.id, label: InvoiceData.service_type.name } : '',

                address_line_1: InvoiceData.contact && InvoiceData.contact.address_line_1 ? InvoiceData.contact.address_line_1 : "",
                address_line_2: InvoiceData.contact && InvoiceData.contact.address_line_2 ? InvoiceData.contact.address_line_2 : "",
                city: InvoiceData.contact && InvoiceData.contact.city ? InvoiceData.contact.city : "",
                state: InvoiceData.contact && InvoiceData.contact.state ? InvoiceData.contact.state : "",
                zip: InvoiceData.contact && InvoiceData.contact.zip ? InvoiceData.contact.zip : "",
                birthday: InvoiceData.contact && InvoiceData.contact.birthday ? InvoiceData.contact.birthday : "",
                website: InvoiceData.contact && InvoiceData.contact.website ? InvoiceData.contact.website : "",
                other_1: InvoiceData.contact && InvoiceData.contact.other_1 ? InvoiceData.contact.other_1 : "",
                other_2: InvoiceData.contact && InvoiceData.contact.other_2 ? InvoiceData.contact.other_2 : "",
                other_3: InvoiceData.contact && InvoiceData.contact.other_3 ? InvoiceData.contact.other_3 : "",
            })
        } else if (props.history.location && props.history.location.state && props.history.location.state.invoiceQuoteData) {
            const quoteData = props.history.location.state.invoiceQuoteData;
            const ServiceType = props.history.location.state.invoiceQuoteData.service_type;
            let services = _.map(ServiceType, (data) => { return { value: data.id, label: data.name } })
            _.remove(services, function (opt) {
                return opt.label === "Magic and Comedy Show (sample)";
            });
            let durationValue, customDuration;
            if (_.some(state.durationOptions, { value: quoteData.event_duration, label: quoteData.event_duration })) {
                durationValue = { value: quoteData.event_duration, label: quoteData.event_duration };
                customDuration = ''
            } else {
                if (quoteData.event_duration !== null) {
                    durationValue = { value: 'Custom', label: 'Custom' }
                    customDuration = quoteData.event_duration
                } else {
                    durationValue = ''
                }
            }
            setState({
                ...state, new_contact: '0', contactSelectValue: quoteData.contact, quoteId: quoteData.id, invoiceName: 'invoice:' + quoteData.name,
                firstName: quoteData.contact && quoteData.contact.first_name ? quoteData.contact.first_name : '',
                lastName: quoteData.contact && quoteData.contact.last_name ? quoteData.contact.last_name : '',
                email: quoteData.contact && quoteData.contact.email ? quoteData.contact.email : '',
                phone: quoteData.contact && quoteData.contact.phone ? quoteData.contact.phone : '',
                phoneType: quoteData.contact && quoteData.contact.phone_type ? { value: quoteData.contact.phone_type, label: quoteData.contact.phone_type } : '',
                organization: quoteData.contact && quoteData.contact.organization ? quoteData.contact.organization : '',
                title: quoteData.contact && quoteData.contact.title ? quoteData.contact.title : '',
                location: quoteData.event_location !== null ? quoteData.event_location : '',
                lat_long: quoteData.event_lat_long !== null ? quoteData.event_lat_long : '',
                date: quoteData.start_date !== null ? moment(quoteData.start_date).toDate() : '',
                timeValue: quoteData.start_date !== null ? moment(quoteData.start_date).toDate() : '',
                durationValue, customDuration, selectDisabled: true,
                servicesListOptions: services,
                selectService: quoteData.service_type && quoteData.service_type.id ? { value: quoteData.service_type.id, label: quoteData.service_type.name } : '',

                address_line_1: quoteData.contact && quoteData.contact.address_line_1 ? quoteData.contact.address_line_1 : "",
                address_line_2: quoteData.contact && quoteData.contact.address_line_2 ? quoteData.contact.address_line_2 : "",
                city: quoteData.contact && quoteData.contact.city ? quoteData.contact.city : "",
                state: quoteData.contact && quoteData.contact.state ? quoteData.contact.state : "",
                zip: quoteData.contact && quoteData.contact.zip ? quoteData.contact.zip : "",
                birthday: quoteData.contact && quoteData.contact.birthday ? quoteData.contact.birthday : "",
                website: quoteData.contact && quoteData.contact.website ? quoteData.contact.website : "",
                other_1: quoteData.contact && quoteData.contact.other_1 ? quoteData.contact.other_1 : "",
                other_2: quoteData.contact && quoteData.contact.other_2 ? quoteData.contact.other_2 : "",
                other_3: quoteData.contact && quoteData.contact.other_3 ? quoteData.contact.other_3 : "",
            })
            setLoader(true)
            dispatch(listServiceWithSource());
        } else if (props.history.location && props.history.location.state && props.history.location.state.invoiceBookingData) {
            const invoiceBookingData = props.history.location.state.invoiceBookingData;
            const ServiceType = props.history.location.state.invoiceBookingData.service_type;
            let services = _.map(ServiceType, (data) => { return { value: data.id, label: data.name } })
            _.remove(services, function (opt) {
                return opt.label === "Magic and Comedy Show (sample)";
            });
            let durationValue, customDuration;
            if (_.some(state.durationOptions, { value: invoiceBookingData.duration, label: invoiceBookingData.duration })) {
                durationValue = { value: invoiceBookingData.duration, label: invoiceBookingData.duration };
                customDuration = ''
            } else {
                if (invoiceBookingData.duration !== null) {
                    durationValue = { value: 'Custom', label: 'Custom' }
                    customDuration = invoiceBookingData.duration
                } else {
                    durationValue = ''
                }
            }
            setState({
                ...state, new_contact: '0', contactSelectValue: invoiceBookingData.contact, quoteId: '', invoiceName: 'invoice:' + invoiceBookingData.name,
                firstName: invoiceBookingData.contact && invoiceBookingData.contact.first_name ? invoiceBookingData.contact.first_name : '',
                lastName: invoiceBookingData.contact && invoiceBookingData.contact.last_name ? invoiceBookingData.contact.last_name : '',
                email: invoiceBookingData.contact && invoiceBookingData.contact.email ? invoiceBookingData.contact.email : '',
                phone: invoiceBookingData.contact && invoiceBookingData.contact.phone ? invoiceBookingData.contact.phone : '',
                phoneType: invoiceBookingData.contact && invoiceBookingData.contact.phone_type ? { value: invoiceBookingData.contact.phone_type, label: invoiceBookingData.contact.phone_type } : '',
                organization: invoiceBookingData.contact && invoiceBookingData.contact.organization ? invoiceBookingData.contact.organization : '',
                title: invoiceBookingData.contact && invoiceBookingData.contact.title ? invoiceBookingData.contact.title : '',
                location: invoiceBookingData.event_lat_long !== null ? invoiceBookingData.location : '',
                lat_long: invoiceBookingData.event_lat_long !== null ? invoiceBookingData.event_lat_long : '',
                date: invoiceBookingData.start_date !== null ? moment(invoiceBookingData.start_date).toDate() : '',
                timeValue: invoiceBookingData.start_date !== null ? moment(invoiceBookingData.start_date).toDate() : '',
                durationValue, customDuration, selectDisabled: true,
                servicesListOptions: services,
                selectService: invoiceBookingData.service_type && invoiceBookingData.service_type.id ? { value: invoiceBookingData.service_type.id, label: invoiceBookingData.service_type.name } : '',

                address_line_1: invoiceBookingData.contact && invoiceBookingData.contact.address_line_1 ? invoiceBookingData.contact.address_line_1 : "",
                address_line_2: invoiceBookingData.contact && invoiceBookingData.contact.address_line_2 ? invoiceBookingData.contact.address_line_2 : "",
                city: invoiceBookingData.contact && invoiceBookingData.contact.city ? invoiceBookingData.contact.city : "",
                state: invoiceBookingData.contact && invoiceBookingData.contact.state ? invoiceBookingData.contact.state : "",
                zip: invoiceBookingData.contact && invoiceBookingData.contact.zip ? invoiceBookingData.contact.zip : "",
                birthday: invoiceBookingData.contact && invoiceBookingData.contact.birthday ? invoiceBookingData.contact.birthday : "",
                website: invoiceBookingData.contact && invoiceBookingData.contact.website ? invoiceBookingData.contact.website : "",
                other_1: invoiceBookingData.contact && invoiceBookingData.contact.other_1 ? invoiceBookingData.contact.other_1 : "",
                other_2: invoiceBookingData.contact && invoiceBookingData.contact.other_2 ? invoiceBookingData.contact.other_2 : "",
                other_3: invoiceBookingData.contact && invoiceBookingData.contact.other_3 ? invoiceBookingData.contact.other_3 : "",
            })
            setLoader(true)
            dispatch(listServiceWithSource());
        } else {
            setLoader(true)
            dispatch(listServiceWithSource());
        }
        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevListServiceWithSourceData && prevListServiceWithSourceData.listServiceWithSourceData !== listServiceWithSourceData) {
            if (listServiceWithSourceData && _.has(listServiceWithSourceData, 'data') && listServiceWithSourceData.success === true) {
                setLoader(false)
                let serviceOption = _.map(listServiceWithSourceData.data.service_types, (data) => { return { value: data.id, label: data.name } })
                _.remove(serviceOption, function (opt) {
                    return opt.label === "Magic and Comedy Show (sample)";
                });
                setState({ ...state, servicesListOptions: serviceOption })
            }
            if (listServiceWithSourceData && _.has(listServiceWithSourceData, 'message') && listServiceWithSourceData.success === false) {
                setLoader(false)
                setServiceMessage(listServiceWithSourceData.message)
            }
        }
        if (prevAddInvoiceData && prevAddInvoiceData.addInvoiceData !== addInvoiceData) {
            if (addInvoiceData && _.has(addInvoiceData, 'data') && addInvoiceData.success === true) {
                props.history.push(ADD_INVOICE_BASE + addInvoiceData.data.id)
                setLoader(false)
            }
            if (addInvoiceData && _.has(addInvoiceData, 'message') && addInvoiceData.success === false) {
                setLoader(false)
                if (addInvoiceData.message === 'Please add organization first.') {
                    setBusinessProfileModalShow(true)
                } else {
                    setServiceMessage(addInvoiceData.message)
                }
            }
        }
        if (prevDeleteInvoiceData && prevDeleteInvoiceData.deleteInvoiceData !== deleteInvoiceData) {
            if (deleteInvoiceData && _.has(deleteInvoiceData, 'data') && deleteInvoiceData.success === true) {
                setLoader(false)
                props.history.push(LIST_INVOICES)
            }
            if (deleteInvoiceData && _.has(deleteInvoiceData, 'message') && deleteInvoiceData.success === false) {
                setLoader(false)
                setServiceMessage(deleteInvoiceData.message)
            }
        }
    }, [listServiceWithSourceData, prevListServiceWithSourceData, prevAddInvoiceData, addInvoiceData, prevDeleteInvoiceData, deleteInvoiceData])// eslint-disable-line react-hooks/exhaustive-deps

    //Set Address 
    const handleChangeGoogle = address => {
        setState({ ...state, location: address })
    };

    const handleSelectGoogle = async (address) => {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        let data = { lat: latLng.lat, lng: latLng.lng, place_id: results[0].place_id };
        //setState({ ...state, location: results[0].formatted_address, lat_long: JSON.stringify(data) })
        setState({ ...state, location: address, lat_long: JSON.stringify(data) })
    };

    // Set The Input Values
    const setInputValue = (e, type, maxLength, minLength) => {
        let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength)
        setState({ ...state, [e.target.name]: e.target.value, [error.fieldNameErr]: error.errorMsg, [error.fieldCls]: error.setClassName });
        setServiceMessage('');
    }

    // Check Validation Function 
    const checkValidation = (field, value, type, maxLength, minLength, fieldType) => {
        return fieldValidator(field, value, type, null, maxLength, minLength, fieldType)
    }

    // set date for booking 
    const dateForBooking = (date) => {
        if (date === null) {
            setState({ ...state, date: '', dateCls: state.wrongInput, dateErr: 'Please select date' })
        } else {
            setState({ ...state, date: date, dateCls: '', dateErr: '' })
            setServiceMessage('')
        }
    }

    // set due date 
    const dueDateSet = (date) => {
        if (date === null) {
            setState({ ...state, dueDate: '', dueDateCls: state.wrongInput, dueDateErr: 'Please select payment due date' })
        } else {
            setState({ ...state, dueDate: date, dueDateCls: '', dueDateErr: '' })
            setServiceMessage('')
        }
    }

    const showTimeSelection = () => {
        setTimeout(function () { datepickerRef.current.setOpen(true); }, 100);
    };

    const dueTimeSelection = () => {
        setTimeout(function () { dueDatepickerRef.current.setOpen(true); }, 100);
    };

    // Save Invoice Function 
    const saveInvoiceData = () => {
        let error = state.wrongInput;
        let date = state.date, dateErr = '', dateCls = '', dueDateCls = '', dueDateErr = '',
            dueDate = state.dueDate, invoiceName = state.invoiceName, invoiceNameErr = '', invoiceNameCls = '',
            new_contact = state.new_contact, contactSelectValue = state.contactSelectValue, firstName = state.firstName, lastName = state.lastName, email = state.email,
            phone = state.phone, organization = state.organization, title = state.title, phoneType = state.phoneType.value, firstNameCls = '', emailCls = '',
            firstNameErr = '', emailErr = '', organizationErr = '', contactSelectErr = '', contactSelectCls = '',
            selectServiceCls = "", selectServiceErr = "", selectService = state.selectService,
            address_line_1 = state.address_line_1,
            addressOneErr = "",
            addressOneCls = "",
            address_line_2 = state.address_line_2,
            addressTwoErr = "",
            addressTwoCls = "",
            city = state.city,
            cityErr = "",
            cityCls = "",
            state_name = state.state,
            statesErr = "",
            statesCls = "",
            zip = state.zip,
            zipErr = "",
            zipCls = "",
            website = state.website,
            websiteErr = "",
            websiteCls = "",
            other_1 = state.other_1,
            other_1Err = "",
            other_1Cls = "",
            other_2 = state.other_2,
            other_2Err = "",
            other_2Cls = "",
            other_3 = state.other_3,
            other_3Err = "",
            other_3Cls = "",
            birthday = state.birthday,
            birthdayErr = "",
            birthdayCls = "",
            getError = false;

        if (validateInputs('required', (date !== '' ? (date.getDate() + ' ' + date.getMonth()) : '')) === 'empty') {
            dateErr = 'Please select  date.';
            dateCls = error
            getError = true;
        }
        if (validateInputs('required', (dueDate !== '' ? (dueDate.getDate() + ' ' + dueDate.getMonth()) : '')) === 'empty') {
            dueDateErr = 'Please select  due date.';
            dueDateCls = error
            getError = true;
        }
        if (validateInputs('string', invoiceName) === 'empty') {
            invoiceNameErr = 'Please enter invoice name.';
            invoiceNameCls = error
            getError = true;
        } else if (validateInputs('string', invoiceName) === false) {
            invoiceNameErr = 'Please enter valid invoice name.';
            invoiceNameCls = error
            getError = true;
        } else if (invoiceName.length > 50) {
            invoiceNameErr = 'Please enter maximum 50 characters.';
            invoiceNameCls = error
            getError = true;
        }

        if (validateInputs('string', firstName) === 'empty') {
            firstNameErr = 'Please enter first name.';
            firstNameCls = error
            getError = true;
        } else if (validateInputs('string', firstName) === false) {
            firstNameErr = 'Please enter valid first name.';
            firstNameCls = error
            getError = true;
        } else if (firstName.length > 50) {
            firstNameErr = 'Please enter maximum 50 characters.';
            firstNameCls = error
            getError = true;
        }

        if (validateInputs('email', email) === 'empty') {
            emailErr = 'Please enter email.';
            emailCls = error
            getError = true;
        } else if (validateInputs('email', email) === false) {
            emailErr = 'Please enter valid email.';
            emailCls = error
            getError = true;
        }

        // if (validateInputs('phoneNumberHyphon', phone) === false) {
        //     phoneErr = 'Please enter valid phone.';
        //     phoneCls = error
        //     getError = true;
        // }
        // if (phone && phone.length > 1 && phone.length > 15) {
        //     phoneErr = 'Please enter maximum 15 digits.';
        //     phoneCls = error
        //     getError = true;
        // }
        if (new_contact === "1") {

        } else {
            if (!(contactSelectValue && contactSelectValue.id)) {
                contactSelectErr = 'Please select contact.';
                contactSelectCls = error
                getError = true;
            }
        }

        if (validateInputs('required', state.selectService) === 'empty') {
            selectServiceErr = "Please select Interested In.";
            selectServiceCls = error;
            getError = true;
        }

        setState({
            ...state, dateErr, dateCls, dueDateErr, dueDateCls, invoiceNameErr, invoiceNameCls, selectServiceCls, selectServiceErr,
            firstNameCls, emailCls, firstNameErr, emailErr, organizationErr, contactSelectCls, contactSelectErr,
            addressOneErr,
            addressOneCls,
            addressTwoErr,
            addressTwoCls,
            cityErr,
            cityCls,
            statesErr,
            statesCls,
            zipErr,
            zipCls,
            websiteErr,
            websiteCls,
            other_1Err,
            other_1Cls,
            other_2Err,
            other_2Cls,
            other_3Err,
            other_3Cls,
            birthdayErr,
            birthdayCls,
        })

        if (getError === false && dateErr === "" && dueDateErr === "" && emailErr === '' && firstNameErr === '' && invoiceNameErr === '' && contactSelectErr === '' && selectServiceErr === "") {
            setLoader(true);
            let getdate = moment(state.date).format("YYYY-MM-DD")
            let time = state.timeValue ? moment(state.timeValue).format("HH:mm:ss") : moment().format("00:00:00")
            let datetimeA = moment(getdate + " " + time);
            //console.log(moment(datetimeA).format("YYYY-MM-DD HH:mm:ss"),'datetimeA')
            const invoiceData = {
                name: invoiceName, new_contact, event_location: state.location, event_lat_long: state.lat_long,
                first_name: firstName, last_name: lastName, phone_type: phoneType, organization: organization, phone: phone, title: title, email: email,
                address_line_1: address_line_1,
                address_line_2: address_line_2,
                city: city,
                state_name: state.state,
                zip: zip,
                website: website,
                other_1: other_1,
                other_2: other_2,
                other_3: other_3,
                birthday: birthday,
            };
            if (new_contact === '1') {

            } else {
                if (contactSelectValue && contactSelectValue.id) {
                    invoiceData.contact_id = contactSelectValue.id
                }
            }
            if (selectService && selectService.value) {
                invoiceData.service_type_id = selectService.value
            }
            if (state.date !== '') {
                invoiceData.event_date = moment(datetimeA).format("YYYY-MM-DD HH:mm:ss")
            }
            if (dueDate !== '') {
                invoiceData.due_date = moment(dueDate).format("YYYY-MM-DD")
            }
            if (state.selectTemplate && state.selectTemplate.value) {
                invoiceData.invoice_template_id = state.selectTemplate.value
            }
            if (state.durationValue && state.durationValue.value !== 'Custom') {
                invoiceData.event_duration = state.durationValue.value
            } else {
                invoiceData.event_duration = state.customDuration
            }
            if (props.history.location && props.history.location.state && props.history.location.state.invoiceBookingData) {
                invoiceData.booking_id = props.history.location && props.history.location.state && props.history.location.state.invoiceBookingData.id
            }
            //console.log(invoiceData)
            if (state.invoiceId !== '') {
                invoiceData.id = state.invoiceId
                dispatch(updateBasicInvoice(invoiceData))
            } else {
                if (state.quoteId !== '') {
                    invoiceData.quote_id = state.quoteId
                }
                dispatch(addInvoice(invoiceData))
            }
        } else {
            setServiceMessage('Please enter all required details.')
        }
    }

    // On Cancel
    const CancelForm = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: ' You will lose all the changes if you navigate away',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showCloseButton: true,
            customClass: "mycustom-alert",
            cancelButtonClass: 'cancel-alert-note',
        }).then((result) => {
            if (result.value) {
                props.history.push(LIST_INVOICES)
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
            }
        })
    }

    // handle selection
    const handleChange = value => {
        setServiceMessage('')
        setTimeout(() => {
            setState({
                ...state, contactSelectValue: value,
                firstName: value && value.first_name ? value.first_name : '',
                lastName: value && value.last_name ? value.last_name : '',
                email: value && value.email ? value.email : '',
                phone: value && value.phone ? value.phone : '',
                organization: value && value.organization ? value.organization : '',
                title: value && value.title ? value.title : '',
                firstNameCls: '', emailCls: '', firstNameErr: '', emailErr: '',

                address_line_1: value && value.address_line_1 ? value.address_line_1 : "",
                address_line_2: value && value.address_line_2 ? value.address_line_2 : "",
                city: value && value.city ? value.city : "",
                state: value && value.state ? value.state : "",
                zip: value && value.zip ? value.zip : "",
                birthday: value && value.birthday ? value.birthday : "",
                website: value && value.website ? value.website : "",
                other_1: value && value.other_1 ? value.other_1 : "",
                other_2: value && value.other_2 ? value.other_2 : "",
                other_3: value && value.other_3 ? value.other_3 : "",
            })
        }, 0)
    }

    // load options using API call
    const loadOptions = async () => {
        let data = [];
        let listOption = await getContactListOptionValue({ searchField: 'first_name,last_name', fields: 'id,first_name,last_name,organization,title,email,phone', filter: state.contactSelect })
        if (listOption && listOption.data && _.has(listOption.data, 'data') && listOption.success === true) {
            data = listOption.data.data
        }
        return data;
    };

    // handle input change event
    const handleInputChange = value => {
        setState({ ...state, contactSelect: value, contactSelectErr: '', contactSelectCls: '' })
    };

    // Delete Invoice Data 
    const deleteInvoiceFunction = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this invoice?',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showCloseButton: true,
            customClass: "mycustom-alert",
            cancelButtonClass: 'cancel-alert-note',
        }).then((result) => {
            if (result.value) {
                setLoader(true)
                dispatch(deleteInvoice({ id: state.invoiceId }))
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
            }
        })
    }

    // On Change Contact 
    const onchangeContact = (status) => {
        if (status === '1') {
            setState({ ...state, new_contact: status, firstName: '', lastName: '', email: '', phone: '', organization: '', title: '', firstNameCls: '', emailCls: '', firstNameErr: '', emailErr: '' })
        } else {
            setState({
                ...state, new_contact: status,
                firstName: state.contactSelectValue && state.contactSelectValue.first_name ? state.contactSelectValue.first_name : '',
                lastName: state.contactSelectValue && state.contactSelectValue.last_name ? state.contactSelectValue.last_name : '',
                email: state.contactSelectValue && state.contactSelectValue.email ? state.contactSelectValue.email : '',
                phone: state.contactSelectValue && state.contactSelectValue.phone ? state.contactSelectValue.phone : '',
                phoneType: state.contactSelectValue && state.contactSelectValue.phone_type ? { value: state.contactSelectValue.phone_type, label: state.contactSelectValue.phone_type } : '',
                organization: state.contactSelectValue && state.contactSelectValue.organization ? state.contactSelectValue.organization : '',
                title: state.contactSelectValue && state.contactSelectValue.title ? state.contactSelectValue.title : '',
                firstNameCls: '', emailCls: '', phoneCls: '', firstNameErr: '', emailErr: '', phoneErr: '',

                address_line_1: state.contactSelectValue && state.contactSelectValue.address_line_1 ? state.contactSelectValue.address_line_1 : "",
                address_line_2: state.contactSelectValue && state.contactSelectValue.address_line_2 ? state.contactSelectValue.address_line_2 : "",
                city: state.contactSelectValue && state.contactSelectValue.city ? state.contactSelectValue.city : "",
                state: state.contactSelectValue && state.contactSelectValue.state ? state.contactSelectValue.state : "",
                zip: state.contactSelectValue && state.contactSelectValue.zip ? state.contactSelectValue.zip : "",
                birthday: state.contactSelectValue && state.contactSelectValue.birthday ? state.contactSelectValue.birthday : "",
                website: state.contactSelectValue && state.contactSelectValue.website ? state.contactSelectValue.website : "",
                other_1: state.contactSelectValue && state.contactSelectValue.other_1 ? state.contactSelectValue.other_1 : "",
                other_2: state.contactSelectValue && state.contactSelectValue.other_2 ? state.contactSelectValue.other_2 : "",
                other_3: state.contactSelectValue && state.contactSelectValue.other_3 ? state.contactSelectValue.other_3 : "",

                addressOneErr: "",
                addressOneCls: "",
                addressTwoErr: "",
                addressTwoCls: "",
                cityErr: "",
                cityCls: "",
                statesErr: "",
                statesCls: "",
                zipErr: "",
                zipCls: "",
                websiteErr: "",
                websiteCls: "",
                other_1Err: "",
                other_1Cls: "",
                other_2Err: "",
                other_2Cls: "",
                other_3Err: "",
                other_3Cls: "",
                birthdayErr: "",
                birthdayCls: "",
            })
        }
    }

    // Add New Service Data 
    const addNewServiceData = (data) => {
        let allOption = state.servicesListOptions;
        allOption.push(data)
        setState({ ...state, servicesListOptions: allOption })
        setState({ ...state, selectService: data, selectServiceCls: '', selectServiceErr: '' })
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="main-site fixed--header lead-page-hdr unfixed-page-title">
                <Header getMainRoute={'invoices'} />
                <main className="site-body">
                    <section className="page-title addbasicInvoiceheader">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-auto title--col">
                                    <div>
                                        <ol className="breadcrumb d-none d-lg-flex">
                                            <li className="breadcrumb-item"><Link to={LIST_INVOICES}>Invoices</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Create New Invoice</li>
                                        </ol>
                                        <h2 className="title">New Invoice</h2>
                                    </div>
                                </div>
                                {state.invoiceId !== '' ?
                                    <div className="col-auto ml-auto d-flex align-items-center title-elems">
                                        <button type="button" onClick={(e) => deleteInvoiceFunction(e)} className="btn btn-danger mr-15 trashbtn">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g id="vuesax/bold/trash">
                                                    <g id="trash">
                                                        <path
                                                            id="Vector"
                                                            d="M21.0699 5.23C19.4599 5.07 17.8499 4.95 16.2299 4.86V4.85L16.0099 3.55C15.8599 2.63 15.6399 1.25 13.2999 1.25H10.6799C8.34991 1.25 8.12991 2.57 7.96991 3.54L7.75991 4.82C6.82991 4.88 5.89991 4.94 4.96991 5.03L2.92991 5.23C2.50991 5.27 2.20991 5.64 2.24991 6.05C2.28991 6.46 2.64991 6.76 3.06991 6.72L5.10991 6.52C10.3499 6 15.6299 6.2 20.9299 6.73C20.9599 6.73 20.9799 6.73 21.0099 6.73C21.3899 6.73 21.7199 6.44 21.7599 6.05C21.7899 5.64 21.4899 5.27 21.0699 5.23Z"
                                                            fill="#DB0200"
                                                        />
                                                        <path
                                                            id="Vector_2"
                                                            d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z"
                                                            fill="#DB0200"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>Delete</button>
                                        <button onClick={(e) => CancelForm(e)} className="btn btn-dark mr-15">
                                            {/* <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="vuesax/linear/add">
                        <g id="add">
                          <g id="Group 33824">
                            <path
                              id="Vector"
                              d="M5.00098 5L19 18.9991"
                              stroke="#818186"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M4.99996 18.9991L18.999 5"
                              stroke="#818186"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </g>
                    </svg> */}
                                            Cancel</button>
                                        <div className="btn-divider mr-15"></div>
                                        {/* <button onClick={() => saveInvoiceData()} className="btn btn-secondary mr-15">Save for Later</button> */}
                                        <button onClick={() => saveInvoiceData()} className="btn btn-primary">Continue</button>
                                        {/*  <div className="dropdown d-lg-none custom-dropdown dropdown-toggle--mbl">
                                            <button className="btn dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src={setImagePath(MENU_DOTTED)} alt="" />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" href="#cancel" onClick={(e) => CancelForm(e)}>Cancel</a>
                                                <a className="dropdown-item" href="#delete" onClick={(e) => deleteInvoiceFunction(e)}>Delete</a>
                                            </div>
                                        </div> */}
                                    </div>
                                    :
                                    <div className="col-auto ml-auto d-flex align-items-center title-elems">
                                        <button onClick={(e) => CancelForm(e)} className="btn btn-dark mr-15">
                                            {/* <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="vuesax/linear/add">
                        <g id="add">
                          <g id="Group 33824">
                            <path
                              id="Vector"
                              d="M5.00098 5L19 18.9991"
                              stroke="#818186"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M4.99996 18.9991L18.999 5"
                              stroke="#818186"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </g>
                    </svg> */}
                                            Cancel</button>
                                        <button onClick={() => saveInvoiceData()} className="btn btn-primary">Continue</button>
                                        {/* <div className="dropdown d-lg-none custom-dropdown dropdown-toggle--mbl">
                                            <button className="btn dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src={setImagePath(MENU_DOTTED)} alt="" />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" href="#cancel" onClick={(e) => CancelForm(e)}>Cancel</a>
                                            </div>
                                        </div> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </section>

                    <section className="middle-section pt-0">
                        <div className="container">
                            {serviceMessage ? <div className="errorCls errCommonCls mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{serviceMessage}</div> : ''}
                            <div className="row no-gutters-mbl mb-lg-4">
                                <div className="col-12">
                                    <div className="main-card">
                                        <div className="card w-100">
                                            <div className="card-body p-0">
                                                <div className="new-lead-timeline">
                                                    <div className="timeline_row">
                                                        <div className="timeline-cols active"><h5><em className="d-none d-lg-flex"><p>1</p> Basic Details</em> <i className="d-lg-none">1</i></h5><span></span></div>
                                                        <div className="timeline-cols"><h5><em className="d-none d-lg-flex"><p>2</p> Invoice Details</em> <i className="d-lg-none">2</i></h5><span></span></div>
                                                        <div className="timeline-cols"><h5><em className="d-none d-lg-flex"><p>3</p> Preview Invoice</em> <i className="d-lg-none">3</i></h5><span></span></div>
                                                        <div className="timeline-cols"><h5><em className="d-none d-lg-flex"><p>4</p> Message to Customer</em> <i className="d-lg-none">4</i></h5><span></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row no-gutters-mbl mt-3">
                                <div className="col-lg-6">
                                    <div className="main-card create-qoute--cards">
                                        <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#InvoiceCollapse" aria-expanded="true" aria-controls="InvoiceCollapse">Invoice Details <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                                        <div className={"card main-card--collapse show " + isCollapse} id="InvoiceCollapse">
                                            <div className="card-header py-3 d-none d-lg-flex justify-content-between align-items-center">
                                                <h5>Invoice Setup</h5>
                                            </div>
                                            <div className="card-body pt-lg-0 pb-0">
                                                <form className="p-lg-2  px-4 py-lg-2">
                                                    <div className="row">
                                                        <div className="form-group col-md-10">
                                                            <div className="floating-label">
                                                                <input placeholder="Event, Service, Customer name etc." type="text" name="invoiceName" value={state.invoiceName || ''} onChange={(e) => setInputValue(e, 'string', 50, null)} className="floating-input" />
                                                                <label class="bold-text">Invoice Name *</label>
                                                                {state.invoiceNameErr ? <span className="errorValidationMessage"> {state.invoiceNameErr}</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-8">
                                                            <div className={"floating-label " + state.selectServiceCls}>
                                                                {/* <label>Interested in </label> */}
                                                                <Select
                                                                    styles={selectStyle}
                                                                    className="floating-select"
                                                                    placeholder="Service/Package Name *"
                                                                    components={{ MenuList: addExtraService, ValueContainer: CustomValueContainer, NoOptionsMessage: () => null }}
                                                                    options={state.servicesListOptions}
                                                                    value={state.selectService}
                                                                    /* menuIsOpen={true} */
                                                                    onChange={(data) => setState({ ...state, selectService: data, selectServiceCls: '', selectServiceErr: '' })}
                                                                />
                                                                {state.selectServiceErr ? <span className="errorValidationMessage"> {state.selectServiceErr}</span> : ''}
                                                            </div>
                                                            <p className="mb-0" ><strong>Note:</strong> For your purpose only, not shared with customer.</p>
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <div className={"floating-label " + state.dueDateCls}>
                                                                <label class="bold-text">Payment Due by *</label>
                                                                <DatePicker
                                                                    type="text"
                                                                    name="dueDate"
                                                                    className={state.dueDateCls ? "floating-input " + state.dueDateCls : "floating-input"}
                                                                    selected={state.dueDate}
                                                                    onChange={(date) => dueDateSet(date)}
                                                                    minDate={moment().toDate()}
                                                                    placeholderText="Payment Due by *"
                                                                    ref={dueDatepickerRef}
                                                                    autoComplete="off"
                                                                />
                                                                <div onClick={() => dueTimeSelection()} className="input-calendar-icon"><img src={CALENDAR} alt="" width="20" height="20" /></div>
                                                                {state.dueDateErr ? <span className="errorValidationMessage"> {state.dueDateErr}</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="main-card create-qoute--cards">
                                        <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#EventCollapse" aria-expanded="true" aria-controls="EventCollapse">Service Date & Location <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                                        <div className={"card main-card--collapse show " + isCollapse} id="EventCollapse">
                                            <div className="card-header py-3 d-none d-lg-flex justify-content-between align-items-center">
                                                <h5>Service Date & Location</h5>
                                            </div>
                                            <div className="card-body pt-lg-0 pb-0">
                                                <form className="p-lg-2 px-4 py-lg-2">
                                                    <div className="row">
                                                        <div className="form-group col-md-4">
                                                            <div className={"floating-label " + state.dateCls}>
                                                                <DatePicker
                                                                    type="text"
                                                                    name="date"
                                                                    className={state.dateCls ? "floating-input " + state.dateCls : "floating-input"}
                                                                    selected={state.date}
                                                                    onChange={(date) => dateForBooking(date)}
                                                                    // minDate={moment().toDate()}
                                                                    placeholderText="Date *"
                                                                    ref={datepickerRef}
                                                                    autoComplete="off"
                                                                />
                                                                <div onClick={() => showTimeSelection()} className="input-calendar-icon"><img src={CALENDAR} alt="" width="20" height="20" /></div>
                                                                {state.dateErr ? <span className="errorValidationMessage"> {state.dateErr}</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <div className="floating-label my-custom-time-input">
                                                                <DatePicker
                                                                    selected={state.timeValue}
                                                                    onChange={date => setState({ ...state, timeValue: date })}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    fixedHeight
                                                                    timeIntervals={30}
                                                                    dateFormat="h:mm a"
                                                                    className="floating-input"
                                                                    onKeyDown={e => e.preventDefault()}
                                                                    placeholderText="Start Time"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <div className="floating-label">
                                                                <Select
                                                                    styles={selectStyle}
                                                                    className="floating-select"
                                                                    components={{ ValueContainer: CustomValueContainer }}
                                                                    value={state.durationValue}
                                                                    isSearchable={false}
                                                                    options={state.durationOptions}
                                                                    placeholder="Duration"
                                                                    onChange={(data) => {
                                                                        setState({ ...state, durationValue: data });
                                                                    }}
                                                                />
                                                                {/* <label>Duration</label> */}
                                                            </div>
                                                        </div>
                                                        {state.durationValue && state.durationValue.value === "Custom" ?
                                                            <div className="form-group col-md-5">
                                                                <div >
                                                                    <label class="bold-text">Custom Duration</label>
                                                                    <input
                                                                        placeholder="Custom Duration"
                                                                        type="text"
                                                                        name="customDuration"
                                                                        className="new-input"
                                                                        value={state.customDuration || ""}
                                                                        onChange={(e) => setState({ ...state, customDuration: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div> : ''}
                                                        <div className={state.durationValue.value === "Custom" ? "form-group col-md-7" : "form-group col-md-12"}>
                                                            <PlacesAutocomplete
                                                                value={state.location}
                                                                onChange={handleChangeGoogle}
                                                                onSelect={handleSelectGoogle}
                                                            >
                                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                    <div className="floating-label" >
                                                                        <input
                                                                            {...getInputProps({
                                                                                placeholder: 'Name of the location, Extract address etc.',
                                                                                className: 'location-search-input floating-input',
                                                                            })}
                                                                        />
                                                                        <label class="bold-text">Location</label>
                                                                        <div className="autocomplete-dropdown-container">
                                                                            {loading && <div>Loading...</div>}
                                                                            {suggestions.map((suggestion, key) => {
                                                                                const className = suggestion.active
                                                                                    ? 'suggestion-item--active'
                                                                                    : 'suggestion-item';
                                                                                // inline style for demonstration purpose
                                                                                const style = suggestion.active
                                                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                                return (
                                                                                    <div
                                                                                        {...getSuggestionItemProps(suggestion, {
                                                                                            className,
                                                                                            style,
                                                                                        })}
                                                                                        key={key}
                                                                                    >
                                                                                        <span>{suggestion.description}</span>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </PlacesAutocomplete>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row no-gutters-mbl mt-lg-4">
                                <div className="col-lg-12">
                                    <div className="main-card">
                                        <button className="btn btn-block btn--card-collapse" type="button" data-toggle="collapse" data-target="#contactDetail" aria-expanded="true" aria-controls="contactDetail">Contact Details <img src={setImagePath(ORANGE_ARROW)} alt="" /></button>
                                        <div className={"card main-card--collapse show " + isCollapse} id="contactDetail">
                                            <div className="card-header py-4 padd0 d-flex justify-content-between align-items-center">
                                                <h2>Customer</h2>
                                            </div>
                                            <div className="card-body pt-0 pb-0">
                                                <form className="px-3">
                                                    <div className="row">
                                                        <div className="form-group  col-lg-4 col-md-6 mb-lg-5">
                                                            {/* <label className="single-label">Event Type</label> */}
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input className="custom-control-input" onChange={() => onchangeContact('1')} checked={state.new_contact === '1' ? true : false} type="radio" name="new_contact" id="newRadio" value="1" />
                                                                <label className="custom-control-label" htmlFor="newRadio">New</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input className="custom-control-input" onChange={() => onchangeContact('0')} checked={state.new_contact === '0' ? true : false} type="radio" name="new_contact" id="existingRadio" value="0" />
                                                                <label className="custom-control-label" htmlFor="existingRadio">Select Existing</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {state.new_contact === '1'
                                                        ?
                                                        ''
                                                        :
                                                        <>
                                                            <div className="row">
                                                                <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                                                                    <div>
                                                                        <label class="bold-text">Type here to search</label>
                                                                        <AsyncSelect
                                                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            className="floating-input"
                                                                            styles={selectStyle}
                                                                            onFocus={e => {
                                                                                if (e.target.autocomplete) {
                                                                                    e.target.autocomplete = "nope";
                                                                                }
                                                                            }}
                                                                            isClearable
                                                                            placeholder=""
                                                                            noOptionsMessage={() => state.contactSelect ? "No results found" : "Results will appear here"}
                                                                            value={state.contactSelectValue}
                                                                            getOptionLabel={e => e.first_name + (e && e.last_name ? " " + e.last_name : '')}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(e) => loadOptions(e)}
                                                                            onInputChange={(e) => handleInputChange(e)}
                                                                            onChange={(e) => handleChange(e)}
                                                                        />
                                                                        {state.contactSelectErr ? <span className="errorValidationMessage"> {state.contactSelectErr}</span> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-lg-5" style={{ borderBottom: "1px solid" }}></div>
                                                        </>
                                                    }
                                                    <div className="row">
                                                        <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="First Name" type="text" name="firstName" value={state.firstName || ''} onChange={(e) => setInputValue(e, 'string', 50, null)} className="floating-input" />
                                                                <label class="bold-text">First Name *</label>
                                                                {state.firstNameErr ? <span className="errorValidationMessage"> {state.firstNameErr}</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Last Name" type="text" name="lastName" value={state.lastName || ''} onChange={(e) => { setState({ ...state, lastName: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">Last Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Email Address" type="email" name="email" value={state.email || ''} onChange={(e) => setInputValue(e, 'email', null, null)} className=" floating-input" />
                                                                <label class="bold-text">Email Address *</label>
                                                                {state.emailErr ? <span className="errorValidationMessage"> {state.emailErr}</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-6 mb-lg-4">
                                                            <div className="form-row">
                                                                <div className="col-5">
                                                                    <div style={{ marginTop: '0px' }} className="floating-label">
                                                                        <Select
                                                                            styles={selectStyle}
                                                                            className="floating-select"
                                                                            value={state.phoneType}
                                                                            isSearchable={false}
                                                                            components={{ ValueContainer: CustomValueContainer }}
                                                                            defaultValue={state.phoneType}
                                                                            options={phoneTypeOptions}
                                                                            placeholder="Type"
                                                                            onChange={data => { setState({ ...state, phoneType: data }); setServiceMessage('') }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-7">
                                                                    <div className="floating-label">
                                                                        <input placeholder="000-000-0000" type="text" name="phone" value={state.phone || ''} onChange={(e) => { setState({ ...state, phone: e.target.value }); setServiceMessage('') }} className=" floating-input" />
                                                                        <label class="bold-text">Phone Number</label>
                                                                        {/* {state.phoneErr ? <span className="errorValidationMessage"> {state.phoneErr}</span> : ''} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Organization Name" type="text" name="organization" value={state.organization || ''} onChange={(e) => { setState({ ...state, organization: e.target.value }); setServiceMessage('') }} className=" floating-input" />
                                                                <label class="bold-text">Organization Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-6 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Sr. Director" type="text" name="title" value={state.title || ''} onChange={(e) => { setState({ ...state, title: e.target.value }); setServiceMessage('') }} className=" floating-input" />
                                                                <label class="bold-text">Title</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 row mb-lg-5 p-0 m-0">
                                                            <div className="form-group col-md-6 mb-lg-0">
                                                                <div className="floating-label">
                                                                    <input placeholder="Address Line 1" type="text" name="address_line_1" value={state?.address_line_1 || ''} onChange={(e) => { setState({ ...state, address_line_1: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                    <label class="bold-text">Address Line 1</label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-6 mb-lg-0">
                                                                <div className="floating-label">
                                                                    <input placeholder="Address Line 2" type="text" name="address_line_2" value={state?.address_line_2 || ''} onChange={(e) => { setState({ ...state, address_line_2: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                    <label class="bold-text">Address Line 2</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-2 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="City" type="text" name="city" value={state?.city || ''} onChange={(e) => { setState({ ...state, city: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">City</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-2 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="State" type="text" name="state" value={state?.state || ''} onChange={(e) => { setState({ ...state, state: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">State</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-2 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Zip" type="text" name="zip" value={state?.zip || ''} onChange={(e) => { setState({ ...state, zip: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">Zip</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Birthday" type="text" name="birthday" value={state?.birthday || ''} onChange={(e) => { setState({ ...state, birthday: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">Birthday</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Website" type="text" name="website" value={state?.website || ''} onChange={(e) => { setState({ ...state, website: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">Website</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4 mb-lg-5">
                                                            <div className="floating-label">
                                                                <input placeholder="Other 1" type="text" name="other_1" value={state?.other_1 || ''} onChange={(e) => { setState({ ...state, other_1: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">Other 1</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4 mb-lg-">
                                                            <div className="floating-label">
                                                                <input placeholder="Other 2" type="text" name="other_2" value={state?.other_2 || ''} onChange={(e) => { setState({ ...state, other_2: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">Other 2</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4 mb-lg-2">
                                                            <div className="floating-label">
                                                                <input placeholder="Other 3" type="text" name="other_3" value={state.other_3 || ''} onChange={(e) => { setState({ ...state, other_3: e.target.value }); setServiceMessage('') }} className="floating-input" />
                                                                <label class="bold-text">Other 3</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Add Organization*/}
                    <AddOrganization loader={(data) => setLoader(data)}
                        openOrg={businessProfileModalShow}
                        sentToCustomerCall={(data) => saveInvoiceData()}
                        closeOrg={() => setBusinessProfileModalShow(false)}
                    />

                    {/* Add Service Modal*/}
                    <AddService loader={(data) => setLoader(data)}
                        openService={serviceModalShow}
                        addServiceInList={(data) => addNewServiceData(data)}
                        closeService={() => setServiceModalShow(false)}
                    />
                </main>
                <Footer />
            </div >
        </>
    );
}

export const AddBasicInvoice = withRouter(NewAddBasicInvoice)
