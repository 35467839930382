import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
// import { EmailHeader } from "../../../../component/frontend/email/EmailHeader";
import { Footer } from "../../../../component/frontend/footer/footer";
import { Header } from "../../../../component/frontend/header/header";
import { Loader } from "../../../../component/frontend/loader/loader";
import {
  decodeToString,
  setImagePath,
  validateFileSize,
} from "../../../../../common/custom";
import COMPOSE_ICON from "../../../../../assets/images/edit_FILL0_wght400_GRAD0_opsz24.svg";
import REPLY_ICON from "../../../../../assets/images/reply_FILL0_wght400_GRAD0_opsz24.svg";
// import REPLY_ALL_ICON from "../../../../../assets/images/reply_all_FILL0_wght400_GRAD0_opsz24.svg";
// import FORWARD_ICON from "../../../../../assets/images/forward_FILL0_wght400_GRAD0_opsz24.svg";
import DELETE_ICON from "../../../../../assets/images/delete_FILL0_wght400_GRAD0_opsz24.svg";
// import MARK_ICON from "../../../../../assets/images/sell_FILL0_wght400_GRAD0_opsz24.svg";
import USER_ICON from "../../../../../assets/images/image_avatar.png";
import DOWNLOAD_ICON from "../../../../../assets/images/download_FILL0_wght400_GRAD0_opsz48.svg";
// import MORE_ICON from "../../../../../assets/images/more_horiz_FILL0_wght400_GRAD0_opsz24.svg";
// import STAR_ICON from "../../../../../assets/images/start.svg";
import UNREAD_STAR_ICON from "../../../../../assets/images/starred.svg";
import STAR_FILLED_ICON from "../../../../../assets/images/starred_filled.svg";
import UNREAD_IMPORTANT_ICON from "../../../../../assets/images/label_important.svg";
// import IMPORTANT_ICON from "../../../../../assets/images/important.svg";
import IMPORTANT_FILLED_ICON from "../../../../../assets/images/label-filled.svg";
// import Swal from "sweetalert2";
import { getUserDetails } from "../../../../../storage/user";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDraftGmailMessage,
  getDraftGmailMessage,
  getGmailMessageAttachmentById,
  getGmailValidToken,
  modifyGmailMessage,
  saveSocialAuthToken,
} from "../../../../../duck/email/google/google.action";
import _ from "lodash";
import moment from "moment";
import {
  ADD_CONTACT,
  ADD_LEAD,
  COMPOSE_EMAIL,
  EMAIL_DRAFT,
  EMAIL_LIST,
} from "../../../../../routing/routeContants";
import { SubscriptionPlan } from "../../profile/subscriptionPlans";
import { EmailSidebar } from "../../../../component/frontend/email/EmailSidebar";
import { EmailHeader } from "../../../../component/frontend/email/EmailHeader";
import { getUserSocialDetails } from "../../../../../routing/authService";
import { resetAllState, updateAccordionLabel, updateGoogleLabel, updateMicrosoftLabel } from "../../../../../duck/email/email.action";

export const ViewDraftEmailPage = (props) => {
  let draftId;
  if (
    props.match &&
    _.has(props.match, "params") &&
    _.has(props.match.params, "id")
  ) {
    draftId = props.match.params.id;
  }
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const userSocialData = getUserSocialDetails();
  const {
    loading,
    getDraftGmailMessageByIdData,
    getGmailMessageAttachmentData,
    errorTokenData,
    getGmailTokenData,
  } = useSelector((state) => state.google);
  const {
    getAllOutlookMailFoldersData
  } = useSelector((state) => state.microsoft);
  const {
    googleLabel,
    microsoftLabel,
    accordionLabel
  } = useSelector((state) => state.email);
  const currentPlan = userData && userData.planData;
  const [attachmentData, setAttachmentData] = useState({});
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
  const [editDraftData, setEditDraftData] = useState({});
  const [draftData, setDraftData] = useState({
    messageId: "",
    subject: "",
    from: "",
    to: "",
    cc: "",
    bcc: "",
    emailDate: "",
    draftBody: "",
    isImportant: false,
    isStarred: false,
  });
  // Create Function
  // const createFunction = (e, path) => {
  //   e.preventDefault();
  // Add Free trial expire then working is blocked
  //if(currentPlan && currentPlan.plan_is_active === 0){
  // Free trial expire then working is fine
  //   if (
  //     currentPlan &&
  //     currentPlan.plan_is_active === 0 &&
  //     (path === "/user/add-quote" || path === "/user/add-invoice/")
  //   ) {
  //     let buttonMsg =
  //       currentPlan.subscription_product_id === 1 ? "View Plans" : "Renew Plan";
  //     let warMsg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Free Trial Expired"
  //         : "Subscription Expired";
  //     let msg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Your free trial has expired. Please subscribe to a plan to access the application. "
  //         : "Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ";
  //     Swal.fire({
  //       title: warMsg,
  //       html: msg,
  //       showCancelButton: true,
  //       confirmButtonText: buttonMsg,
  //       cancelButtonText: "Close",
  //       reverseButtons: true,
  //       showCloseButton: true,
  //       customClass: "mycustom-alert",
  //       cancelButtonClass: "cancel-alert-note",
  //     }).then((result) => {
  //       if (result.value) {
  //         setSubscriptionModalShow(true);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       }
  //     });
  //   } else {
  //     props.history.push(path);
  //   }
  // };

  // useEffect(() => {
  //   dispatch(updateGoogleLabel(""));
  // dispatch(updateMicrosoftLabel({}));
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getDraftGmailMessage(draftId));
  }, [dispatch, draftId]);

  useEffect(() => {
    if (
      getDraftGmailMessageByIdData &&
      Object.keys(getDraftGmailMessageByIdData).length > 0 &&
      getDraftGmailMessageByIdData?.message
    ) {
      const msg = getDraftGmailMessageByIdData?.message;
      const isStarred =
        msg &&
        msg.labelIds &&
        msg.labelIds.length &&
        msg.labelIds.includes("STARRED");
      const isImportant =
        msg &&
        msg.labelIds &&
        msg.labelIds.length &&
        msg.labelIds.includes("IMPORTANT");
      const from =
        msg &&
        msg.payload &&
        msg.payload?.headers?.length &&
        msg.payload?.headers.find((obj) => obj.name === "From");
      const to =
        msg &&
        msg.payload &&
        msg.payload?.headers?.length &&
        msg.payload?.headers.find((obj) => obj.name === "To");
      const cc =
        msg &&
        msg.payload &&
        msg.payload?.headers?.length &&
        msg.payload?.headers.find((obj) => obj.name === "Cc");
      const bcc =
        msg &&
        msg.payload &&
        msg.payload?.headers?.length &&
        msg.payload?.headers.find((obj) => obj.name === "Bcc");
      const emailDate =
        msg &&
        msg.payload &&
        msg.payload.headers.length &&
        msg.payload.headers.find((obj) => obj.name === "Date");
      const subject =
        msg &&
        msg.payload &&
        msg.payload?.headers?.length &&
        msg.payload?.headers.find((obj) => obj.name === "Subject");
      const HTMLBodyPart =
        msg && msg.payload && msg.payload?.body && msg.payload?.body?.size > 0
          ? msg.payload
          : msg &&
            msg.payload &&
            msg.payload?.parts?.length &&
            msg.payload?.parts.find((obj) => obj.mimeType === "multipart/mixed")
            ? msg.payload &&
            msg.payload?.parts?.length &&
            msg.payload?.parts.find((obj) => obj.mimeType === "multipart/mixed")
            : msg &&
              msg.payload &&
              msg.payload?.parts?.length &&
              msg.payload?.parts.find((obj) => obj.mimeType === "text/plain")
              ? msg.payload &&
              msg.payload?.parts?.length &&
              msg.payload?.parts.find((obj) => obj.mimeType === "text/plain")
              : msg &&
                msg.payload &&
                msg.payload?.parts?.length &&
                msg.payload?.parts.find(
                  (obj) => obj.mimeType === "multipart/alternative"
                )
                ? msg &&
                msg.payload &&
                msg.payload?.parts?.length &&
                msg.payload?.parts.find(
                  (obj) => obj.mimeType === "multipart/alternative"
                )
                : msg &&
                msg.payload &&
                msg.payload?.parts?.length &&
                msg.payload?.parts.find((obj) => obj.mimeType === "text/html");
      const HtmlBodyDataObj =
        HTMLBodyPart && HTMLBodyPart.mimeType === "multipart/alternative"
          ? HTMLBodyPart &&
          HTMLBodyPart?.parts.find((obj) => obj.mimeType === "text/html")
            ?.body
          : HTMLBodyPart &&
          HTMLBodyPart?.body &&
          HTMLBodyPart?.body?.size > 0 &&
          HTMLBodyPart?.body;
      const HtmlBodyData =
        HtmlBodyDataObj &&
        HtmlBodyDataObj.size > 0 &&
        decodeToString(HtmlBodyDataObj.data);
      setEditDraftData(msg);
      setDraftData({
        messageId: msg.id,
        subject,
        from,
        to,
        cc,
        bcc,
        emailDate,
        draftBody: HtmlBodyData,
        isImportant,
        isStarred,
      });
    }
  }, [getDraftGmailMessageByIdData]);

  useEffect(() => {
    if (
      getDraftGmailMessageByIdData &&
      Object.keys(getDraftGmailMessageByIdData).length > 0 &&
      _.has(getDraftGmailMessageByIdData, "message")
    ) {
      const msg = getDraftGmailMessageByIdData?.message;
      const attachment =
        msg.payload &&
        msg.payload?.parts?.length &&
        msg.payload?.parts.find((data) => _.has(data.body, "attachmentId"));
      if (attachment?.body?.attachmentId) {
        dispatch(
          getGmailMessageAttachmentById({
            messageId: msg.id,
            attachmentId: attachment.body.attachmentId,
          })
        );
      }
      setAttachmentData(attachment);
    } else {
      setAttachmentData({});
    }
  }, [dispatch, draftId, getDraftGmailMessageByIdData]);


  const handleDeleteMessage = () => {
    if (draftId) {
      dispatch(deleteDraftGmailMessage(draftId));
    }
  };
  // Create Contact
  const handleCreateContact = (e) => {
    e.preventDefault();
    props.history.push(ADD_CONTACT);
  };
  // Create Lead
  const handleCreateLead = (e) => {
    e.preventDefault();
    props.history.push(ADD_LEAD);
  };
  const handleaAtoB = (input) => {
    if (input) {
      return URL.createObjectURL(new Blob([input], { type: "text/plain" }));
    } else {
      return "";
    }
  };

  const handleGoogleLabels = (label) => {
    if (googleLabel !== label) {
      dispatch(updateGoogleLabel(label));
    }
  };

  const handleMicrosoftLabels = () => { };
  const handleAccordionClick = (key) => {
    if (props.match.path !== EMAIL_LIST) {
      props.history.push(EMAIL_LIST);
    }
    dispatch(resetAllState());
    dispatch(updateAccordionLabel(key));
  }

  const handleMarkStarred = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: ["STARRED"],
        removeLabelIds: [],
      })
    );
  };

  const handleMarkImportant = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: ["IMPORTANT"],
        removeLabelIds: [],
      })
    );
  };

  const handleUnmarkStarred = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: [],
        removeLabelIds: ["STARRED"],
      })
    );
  };

  const handleUnmarkImportant = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: [],
        removeLabelIds: ["IMPORTANT"],
      })
    );
  };

  useEffect(() => {
    if (
      userSocialData &&
      _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
      && errorTokenData && Object.keys(errorTokenData).length > 0
    ) {
      if (errorTokenData.code === 401) {
        const refreshToken = userSocialData?.google?.refresh_token
        dispatch(getGmailValidToken(refreshToken));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorTokenData])

  useEffect(() => {
    if ((getGmailTokenData && Object.keys(getGmailTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "google") &&
      Object.keys(userSocialData.google).length > 0 &&
      userSocialData.google.access_token)) {
      const updateGmailAuthTokenData = {
        loginType: 1, authToken: getGmailTokenData?.access_token, refreshToken: getGmailTokenData?.refresh_token, expiresIn: getGmailTokenData?.expire_in,
        social_email: getGmailTokenData?.social_email,
        social_user_id: getGmailTokenData?.social_user_id,
        social_user_name: getGmailTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 1, authToken: userSocialData?.google?.access_token, refreshToken: userSocialData?.google?.refresh_token, expiresIn: userSocialData?.google?.expire_in,
        social_email: userSocialData?.google?.social_email,
        social_user_id: userSocialData?.google?.social_user_id,
        social_user_name: userSocialData?.google?.social_user_name,
      }
      const updatedData = getGmailTokenData && Object.keys(getGmailTokenData).length > 0 ? updateGmailAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
      if (errorTokenData && Object.keys(errorTokenData).length === 0)
        dispatch(getDraftGmailMessage(draftId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getGmailTokenData, errorTokenData.length])

  return (
    <>
      <Loader loader={loading} />
      <div className="main-site fixed--header fixed-email-header">
        <Header getMainRoute={"email"} />
        <main className="site-body bg-white">
          {/* <EmailHeader createFunction={createFunction} /> */}
          <EmailHeader />
          <section className="middle-section email-template-design">
            <div className="container-fluid">
              <div className="row no-gutters-mbl">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="email-template">
                        <div className="email-head d-flex">
                          <div className="top-btn-left">
                            <Link to={COMPOSE_EMAIL} className="email-btn">
                              <img
                                src={setImagePath(COMPOSE_ICON)}
                                alt="COMPOSE_ICON"
                              />
                              <span className="btn-text">Compose</span>
                            </Link>
                            <button
                              className={"email-btn"}
                              onClick={handleDeleteMessage}
                            >
                              <img src={setImagePath(DELETE_ICON)} alt="DELETE_ICON" />{" "}
                              <span className="btn-text">{"Delete Draft"}</span>
                            </button>
                          </div>
                          <div className="create-btns ml-auto d-none">
                            <button
                              className="btn btn-secondary"
                              onClick={handleCreateContact}
                            >
                              Create Contact
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={handleCreateLead}
                            >
                              Create Lead
                            </button>
                          </div>
                        </div>
                        <div className="email-content">
                          <EmailSidebar
                            accordionState={accordionLabel}
                            labelId={googleLabel}
                            userSocialData={userSocialData}
                            handleGoogleLabels={handleGoogleLabels}
                            msalLabel={microsoftLabel}
                            labelsList={getAllOutlookMailFoldersData && getAllOutlookMailFoldersData?.value}
                            handleMicrosoftLabels={handleMicrosoftLabels}
                            handleAccordionClick={handleAccordionClick}
                          />
                          <div className="col-sm-10">
                            <div className="bg-white boxShadow h-100 p-3">
                              <div className="email-thread-wrap">
                                <div className="compose-head">
                                  <div className="d-flex justify-content-between">
                                    <h4>{draftData.subject && draftData.subject.value}</h4>
                                    <div className="d-flex flex-wrap" style={{ columnGap: '8px' }}>
                                      {draftData.isStarred ? (
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            handleUnmarkStarred(
                                              draftData.messageId
                                            )
                                          }
                                        >
                                          <img
                                            src={setImagePath(STAR_FILLED_ICON)}
                                            alt="STAR_ICON"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            handleMarkStarred(
                                              draftData.messageId
                                            )
                                          }
                                        >
                                          <img
                                            src={setImagePath(UNREAD_STAR_ICON)}
                                            alt="STAR_ICON"
                                          />
                                        </button>
                                      )}
                                      {draftData.isImportant ? (
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            handleUnmarkImportant(
                                              draftData.messageId
                                            )
                                          }
                                        >
                                          <img
                                            src={setImagePath(IMPORTANT_FILLED_ICON)}
                                            alt="IMPORTANT_ICON"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            handleMarkImportant(
                                              draftData.messageId
                                            )
                                          }
                                        >
                                          <img
                                            src={setImagePath(UNREAD_IMPORTANT_ICON)}
                                            alt="IMPORTANT_ICON"
                                          />
                                        </button>
                                      )}
                                      <Link
                                        to={{
                                          pathname: EMAIL_DRAFT + draftId,
                                          state: {
                                            messageDataState: editDraftData,
                                            attachmentDataState: getGmailMessageAttachmentData,
                                            key: "editDraft",
                                          },
                                        }}
                                        className="email-btn"
                                      >
                                        <img src={setImagePath(REPLY_ICON)} alt="REPLY_ICON" />{" "}
                                        <span className="btn-text">Edit Draft</span>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="comp-inner">
                                    <div className="avatar">
                                      <img src={setImagePath(USER_ICON)} alt="user_icon" />
                                    </div>
                                    <div className="email-from">
                                      <ul>
                                        <li>
                                          <span>From</span> <strong> {draftData.from && draftData.from.value}</strong>{" "}
                                        </li>
                                        <li>
                                          <span>To</span> <strong>{draftData.to && draftData.to.value}</strong>{" "}
                                        </li>
                                        {draftData.cc && draftData.cc.value && <li>
                                          <span>Cc</span> <strong>{draftData.cc && draftData.cc.value}</strong>{" "}
                                        </li>}
                                        {draftData.bcc && draftData.bcc.value && <li>
                                          <span>Bcc</span> <strong>{draftData.bcc && draftData.bcc.value}</strong>{" "}
                                        </li>}
                                        <li>
                                          <span>Date</span>{" "}
                                          <strong>
                                            {draftData.emailDate ? moment(draftData.emailDate.value).format("YYYY-MM-DD HH:MM") : "-"}
                                          </strong>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                {draftData.draftBody && (
                                  <div className="border p-2">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: draftData.draftBody,
                                      }}
                                    ></div>
                                    {attachmentData &&
                                      Object.keys(attachmentData).length > 0 &&
                                      getGmailMessageAttachmentData &&
                                      (getGmailMessageAttachmentData).length > 0 && (
                                        <div className="border-top my-2 p-2">
                                          <div className="attachment_box">
                                            <div> <a
                                              href={handleaAtoB(decodeToString(getGmailMessageAttachmentData && getGmailMessageAttachmentData?.[0]?.data))}
                                              id="link"
                                              download={(attachmentData && attachmentData.filename) || ""}
                                            >
                                              <img
                                                src={setImagePath(
                                                  DOWNLOAD_ICON
                                                )}
                                                alt="down"
                                              />
                                            </a>
                                            </div>
                                            <div className="wk-pdf">
                                              <p title={(attachmentData && attachmentData.filename) || ""}>
                                                {(attachmentData && attachmentData.filename) || ""}
                                              </p>
                                              <span>
                                                {validateFileSize(getGmailMessageAttachmentData && getGmailMessageAttachmentData?.[0]?.size) || ""}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        {/* Subscription Modal*/}
        <SubscriptionPlan
          loader={loading}
          openSubscriptionModal={subscriptionModalShow}
          closeSubscriptionModal={() => setSubscriptionModalShow(false)}
          updatePlanDetail={(data) => {
            setSubscriptionModalShow(false);
          }}
          currentPlan={currentPlan}
        />
      </div>
    </>
  );
};

export const ViewDraftEmail = withRouter(ViewDraftEmailPage);
