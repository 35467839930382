import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    getProfileDetailResponse, SUCCESS_GET_PROFILE_DETAIL, ERROR_GET_PROFILE_DETAIL, GET_PROFILE_DETAIL,
    updateOwnerProfileDetailResponse, SUCCESS_UPDATE_OWNER_PROFILE_DETAIL, ERROR_UPDATE_OWNER_PROFILE_DETAIL, UPDATE_OWNER_PROFILE_DETAIL,
    updatePasswordResponse, SUCCESS_UPDATE_PASSWORD, ERROR_UPDATE_PASSWORD, UPDATE_PASSWORD,
    updateBusinessProfileDetailResponse, SUCCESS_UPDATE_BUSINESS_PROFILE_DETAIL, ERROR_UPDATE_BUSINESS_PROFILE_DETAIL, UPDATE_BUSINESS_PROFILE_DETAIL,
    getMenuCountResponse, SUCCESS_GET_MENU_COUNT, ERROR_GET_MENU_COUNT, GET_MENU_COUNT,
    connectWithPaypalResponse, SUCCESS_CONNECT_WITH_PAYPAL, ERROR_CONNECT_WITH_PAYPAL, CONNECT_WITH_PAYPAL,
    makeTestPaymentResponse, SUCCESS_MAKE_TEST_PAYMENT, ERROR_MAKE_TEST_PAYMENT, MAKE_TEST_PAYMENT,
    deletePaymentAccountResponse, SUCCESS_DELETE_PAYMENT_ACCOUNT, ERROR_DELETE_PAYMENT_ACCOUNT, DELETE_PAYMENT_ACCOUNT,
    getSubscritpionPlanResponse, SUCCESS_GET_SUBSCRIPTION_PLAN, ERROR_GET_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_PLAN,
    createSubscritpionPlanResponse, SUCCESS_CREATE_SUBSCRIPTION_PLAN, ERROR_CREATE_SUBSCRIPTION_PLAN, CREATE_SUBSCRIPTION_PLAN,
    planApplyCouponResponse, SUCCESS_PLAN_APPLY_COUPON, ERROR_PLAN_APPLY_COUPON, PLAN_APPLY_COUPON,
    getStateTaxResponse, SUCCESS_GET_STATE_TAX, ERROR_GET_STATE_TAX, GET_STATE_TAX,
    updateSubscritpionPlanResponse, UPDATE_SUBSCRIPTION_PLAN, SUCCESS_UPDATE_SUBSCRIPTION_PLAN, ERROR_UPDATE_SUBSCRIPTION_PLAN,
    cancelSubscritpionPlanResponse, CANCEL_SUBSCRIPTION_PLAN, SUCCESS_CANCEL_SUBSCRIPTION_PLAN, ERROR_CANCEL_SUBSCRIPTION_PLAN,
    addProfileServiceResponse, SUCCESS_ADD_PROFILE_SERVICE, ERROR_ADD_PROFILE_SERVICE, ADD_PROFILE_SERVICE,
    deleteAllDummyDataResponse, SUCCESS_DELETE_ALL_DUMMY_DATA, ERROR_DELETE_ALL_DUMMY_DATA, DELETE_ALL_DUMMY_DATA,
    GET_STATE, SUCCESS_GET_STATE, ERROR_GET_STATE, getStateResponse,
    updateAutoReplyDataResponse, SUCCESS_UPDATE_AUTO_REPLY_DATA, ERROR_UPDATE_AUTO_REPLY_DATA, UPDATE_AUTO_REPLY_DATA,
    getAutoReplyDataResponse, SUCCESS_GET_AUTO_REPLY_DATA, ERROR_GET_AUTO_REPLY_DATA, GET_AUTO_REPLY_DATA,
    getVerifyResponse, SUCCESS_VERIFY_2FA, ERROR_VERIFY_2FA, VERIFY_2FA,
    setUp2FAResponse, SUCCESS_SETUP_2FA, ERROR_SETUP_2FA, SETUP_2FA,
    getDisableVerifyResponse, SUCCESS_DISABLE_2FA, ERROR_DISABLE_2FA, DISABLE_2FA, SUCCESS_DELETE_HEADER_IMAGE, ERROR_DELETE_HEADER_IMAGE, deleteHeaderImageResponse, DELETE_HEADER_IMAGE,
    addConfigurationResponse, SUCCESS_ADD_CONFIGURATION, ERROR_ADD_CONFIGURATION, ADD_CONFIGURATION,
} from "./profile.action";
import {
    getProfileDetailApi, updateOwnerProfileDetailApi, updatePasswordApi, updateBusinessProfileDetailApi, getMenuCountApi,
    connectWithPaypalApi, makeTestPaymentApi, deletePaymentAccountApi, getSubscriptionPlanApi, createSubscriptionPlanApi, planApplyCouponApi, getStateTaxApi,
    updateSubscriptionPlanApi, cancelSubscriptionPlanApi, addProfileServiceApi, deleteAllDummyDataApi, getStateApi, setUp2FAApi, getVerifyEnableApi, getVerifyDisableApi, updateAutoReplyDataApi, getAutoReplyDataApi,
    addConfigurationApi
} from '../../../api/index';
import _ from 'lodash';
import { successNotification, errorNotification } from '../../common/notification-alert';
import { deleteHeaderImageApi } from '../../../api/sdk/profile';

// Get Owner Profile Detail
function* getProfileDetailRequest() {
    let getData = yield getProfileDetailApi();
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getProfileDetailResponse(SUCCESS_GET_PROFILE_DETAIL, getData.data));
    } else {
        yield put(getProfileDetailResponse(ERROR_GET_PROFILE_DETAIL, getData.data));
    }
}

export function* getProfileDetailWatcher() {
    yield takeLatest(GET_PROFILE_DETAIL, getProfileDetailRequest);
}

// Update Owner Detail
function* updateOwnerProfileDetailRequest(data) {
    let getData = yield updateOwnerProfileDetailApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateOwnerProfileDetailResponse(SUCCESS_UPDATE_OWNER_PROFILE_DETAIL, getData.data));
    } else {
        yield put(updateOwnerProfileDetailResponse(ERROR_UPDATE_OWNER_PROFILE_DETAIL, getData.data));
    }
}

export function* updateOwnerProfileDetailWatcher() {
    yield takeLatest(UPDATE_OWNER_PROFILE_DETAIL, updateOwnerProfileDetailRequest);
}

// Update Password
function* updatePasswordRequest(data) {
    let getData = yield updatePasswordApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updatePasswordResponse(SUCCESS_UPDATE_PASSWORD, getData.data));
    } else {
        yield put(updatePasswordResponse(ERROR_UPDATE_PASSWORD, getData.data));
    }
}

export function* updatePasswordWatcher() {
    yield takeLatest(UPDATE_PASSWORD, updatePasswordRequest);
}

// Update Business Detail
function* updateBusinessProfileDetailRequest(data) {
    let getData = yield updateBusinessProfileDetailApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateBusinessProfileDetailResponse(SUCCESS_UPDATE_BUSINESS_PROFILE_DETAIL, getData.data));
    } else {
        yield put(updateBusinessProfileDetailResponse(ERROR_UPDATE_BUSINESS_PROFILE_DETAIL, getData.data));
    }
}

export function* updateBusinessProfileDetailWatcher() {
    yield takeLatest(UPDATE_BUSINESS_PROFILE_DETAIL, updateBusinessProfileDetailRequest);
}

// Get Menu Count
function* getMenuCountRequest(data) {
    let getData = yield getMenuCountApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getMenuCountResponse(SUCCESS_GET_MENU_COUNT, getData.data));
    } else {
        yield put(getMenuCountResponse(ERROR_GET_MENU_COUNT, getData.data));
    }
}

export function* getMenuCountWatcher() {
    yield takeLatest(GET_MENU_COUNT, getMenuCountRequest);
}

// Connect With Paypal
function* connectWithPaypalRequest(data) {
    let getData = yield connectWithPaypalApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(connectWithPaypalResponse(SUCCESS_CONNECT_WITH_PAYPAL, getData.data));
    } else {
        yield put(connectWithPaypalResponse(ERROR_CONNECT_WITH_PAYPAL, getData.data));
    }
}

export function* connectWithPaypalWatcher() {
    yield takeLatest(CONNECT_WITH_PAYPAL, connectWithPaypalRequest);
}

// Make test Payment
function* makeTestPaymentRequest(data) {
    let getData = yield makeTestPaymentApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(makeTestPaymentResponse(SUCCESS_MAKE_TEST_PAYMENT, getData.data));
    } else {
        yield put(makeTestPaymentResponse(ERROR_MAKE_TEST_PAYMENT, getData.data));
    }
}

export function* makeTestPaymentWatcher() {
    yield takeLatest(MAKE_TEST_PAYMENT, makeTestPaymentRequest);
}

// Delete Payment Account
function* deletePaymentAccountRequest(data) {
    let getData = yield deletePaymentAccountApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(deletePaymentAccountResponse(SUCCESS_DELETE_PAYMENT_ACCOUNT, getData.data));
    } else {
        yield put(deletePaymentAccountResponse(ERROR_DELETE_PAYMENT_ACCOUNT, getData.data));
    }
}

export function* deletePaymentAccountWatcher() {
    yield takeLatest(DELETE_PAYMENT_ACCOUNT, deletePaymentAccountRequest);
}

// Get Subscription Plan
function* getSubscriptionPlanRequest(data) {
    let getData = yield getSubscriptionPlanApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getSubscritpionPlanResponse(SUCCESS_GET_SUBSCRIPTION_PLAN, getData.data));
    } else {
        yield put(getSubscritpionPlanResponse(ERROR_GET_SUBSCRIPTION_PLAN, getData.data));
    }
}

export function* getSubscriptionPlanWatcher() {
    yield takeLatest(GET_SUBSCRIPTION_PLAN, getSubscriptionPlanRequest);
}

// Create Subscription Plan
function* createSubscriptionPlanRequest(data) {
    let getData = yield createSubscriptionPlanApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(createSubscritpionPlanResponse(SUCCESS_CREATE_SUBSCRIPTION_PLAN, getData.data));
    } else {
        yield put(createSubscritpionPlanResponse(ERROR_CREATE_SUBSCRIPTION_PLAN, getData.data));
    }
}

export function* createSubscriptionPlanWatcher() {
    yield takeLatest(CREATE_SUBSCRIPTION_PLAN, createSubscriptionPlanRequest);
}

// Plan Apply Coupon
function* planApplyCouponRequest(data) {
    let getData = yield planApplyCouponApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(planApplyCouponResponse(SUCCESS_PLAN_APPLY_COUPON, getData.data));
    } else {
        yield put(planApplyCouponResponse(ERROR_PLAN_APPLY_COUPON, getData.data));
    }
}

export function* planApplyCouponWatcher() {
    yield takeLatest(PLAN_APPLY_COUPON, planApplyCouponRequest);
}

// Get Tax State
function* getTaxStateRequest(data) {
    let getData = yield getStateTaxApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getStateTaxResponse(SUCCESS_GET_STATE_TAX, getData.data));
    } else {
        yield put(getStateTaxResponse(ERROR_GET_STATE_TAX, getData.data));
    }
}

export function* getStateTaxWatcher() {
    yield takeLatest(GET_STATE_TAX, getTaxStateRequest);
}

// Update Subscription Plan
function* updateSubscriptionPlanRequest(data) {
    let getData = yield updateSubscriptionPlanApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(updateSubscritpionPlanResponse(SUCCESS_UPDATE_SUBSCRIPTION_PLAN, getData.data));
    } else {
        yield put(updateSubscritpionPlanResponse(ERROR_UPDATE_SUBSCRIPTION_PLAN, getData.data));
    }
}

export function* updateSubscriptionPlanWatcher() {
    yield takeLatest(UPDATE_SUBSCRIPTION_PLAN, updateSubscriptionPlanRequest);
}

// Cancel Subscription Plan
function* cancelSubscriptionPlanRequest(data) {
    let getData = yield cancelSubscriptionPlanApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(cancelSubscritpionPlanResponse(SUCCESS_CANCEL_SUBSCRIPTION_PLAN, getData.data));
    } else {
        yield put(cancelSubscritpionPlanResponse(ERROR_CANCEL_SUBSCRIPTION_PLAN, getData.data));
    }
}

export function* cancelSubscriptionPlanWatcher() {
    yield takeLatest(CANCEL_SUBSCRIPTION_PLAN, cancelSubscriptionPlanRequest);
}

// Add Service Profile
function* addProfileService(data) {
    let getData = yield addProfileServiceApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(addProfileServiceResponse(SUCCESS_ADD_PROFILE_SERVICE, getData.data));
    } else {
        yield put(addProfileServiceResponse(ERROR_ADD_PROFILE_SERVICE, getData.data));
    }
}

export function* addProfileServiceWatcher() {
    yield takeLatest(ADD_PROFILE_SERVICE, addProfileService);
}

//delete all dummy data
function* deleteAllDummyDataRequest(data) {
    let getData = yield deleteAllDummyDataApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(deleteAllDummyDataResponse(SUCCESS_DELETE_ALL_DUMMY_DATA, getData.data));
    } else {
        yield put(deleteAllDummyDataResponse(ERROR_DELETE_ALL_DUMMY_DATA, getData.data));
    }
}

export function* deleteAllDummyDataRequestWatcher() {
    yield takeLatest('DELETE_ALL_DUMMY_DATA', deleteAllDummyDataRequest)
}


// Get State
function* getStateRequest(data) {
    let getData = yield getStateApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getStateResponse(SUCCESS_GET_STATE, getData.data));
    } else {
        errorNotification(getData.data.error_message)
        yield put(getStateResponse(ERROR_GET_STATE, getData.data));
    }
}

export function* getStateWatcher() {
    yield takeLatest(GET_STATE, getStateRequest);
}

// 2FA Get Qr Code State
function* getSetUp2FA(data) {
    let getData = yield setUp2FAApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(setUp2FAResponse(SUCCESS_SETUP_2FA, getData.data));
    } else {
        yield put(setUp2FAResponse(ERROR_SETUP_2FA, getData.data));
    }
}

export function* setUp2FAWatcher() {
    yield takeLatest(SETUP_2FA, getSetUp2FA);
}

// 2FA Get State Enable
function* getVerifyEnable(data) {
    let getData = yield getVerifyEnableApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(getVerifyResponse(SUCCESS_VERIFY_2FA, getData.data));
    } else {
        errorNotification(getData.data.error_message)
        yield put(getVerifyResponse(ERROR_VERIFY_2FA, getData.data));
    }
}

export function* getVerifyEnableWatcher() {
    yield takeLatest(VERIFY_2FA, getVerifyEnable);
}

// 2FA Get State Disable
function* getVerifyDisable(data) {
    let getData = yield getVerifyDisableApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(getDisableVerifyResponse(SUCCESS_DISABLE_2FA, getData.data));
    } else {
        errorNotification(getData.data.error_message)
        yield put(getDisableVerifyResponse(ERROR_DISABLE_2FA, getData.data));
    }
}

export function* getVerifyDisableWatcher() {
    yield takeLatest(DISABLE_2FA, getVerifyDisable);
}


// Get State
function* updateAutoReplyDataRequest(data) {
    let getData = yield updateAutoReplyDataApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(updateAutoReplyDataResponse(SUCCESS_UPDATE_AUTO_REPLY_DATA, getData.data));
    } else {
        yield put(updateAutoReplyDataResponse(ERROR_UPDATE_AUTO_REPLY_DATA, getData.data));
    }
}

export function* updateAutoReplyDataWatcher() {
    yield takeLatest(UPDATE_AUTO_REPLY_DATA, updateAutoReplyDataRequest);
}

// Get State
function* getAutoReplyDataRequest() {
    let getData = yield getAutoReplyDataApi();
    if (getData.success && _.has(getData, 'data.data')) {
        yield put(getAutoReplyDataResponse(SUCCESS_GET_AUTO_REPLY_DATA, getData.data));
    } else {
        yield put(getAutoReplyDataResponse(ERROR_GET_AUTO_REPLY_DATA, getData.data));
    }
}

export function* getAutoReplyDataWatcher() {
    yield takeLatest(GET_AUTO_REPLY_DATA, getAutoReplyDataRequest);
}

// Delete Business Banner
function* deleteHeaderImageRequest(data) {
    let getData = yield deleteHeaderImageApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(deleteHeaderImageResponse(SUCCESS_DELETE_HEADER_IMAGE, getData.data));
    } else {
        yield put(deleteHeaderImageResponse(ERROR_DELETE_HEADER_IMAGE, getData.data));
    }
}

export function* deleteHeaderImageWatcher() {
    yield takeLatest(DELETE_HEADER_IMAGE, deleteHeaderImageRequest);
}

// Manage colunms List Configuration
function* addConfigurationRequest(data) {
    let getData = yield addConfigurationApi(data);
    if (getData.success && _.has(getData, 'data.data')) {
        successNotification(getData.data.message)
        yield put(addConfigurationResponse(SUCCESS_ADD_CONFIGURATION, getData.data));
    } else {
        yield put(addConfigurationResponse(ERROR_ADD_CONFIGURATION, getData.data));
    }
}

export function* addConfigurationWatcher() {
    yield takeLatest(ADD_CONFIGURATION, addConfigurationRequest);
}