import React, { useEffect, useState } from "react";
import { Loader } from "../../../component/frontend/loader/loader";
import { Header } from "../../../component/frontend/header/header";
import { Link } from "react-router-dom";
import { Footer } from "../../../component/frontend/footer/footer";
import Select from "react-select";
import { setImagePath } from "../../../../common/custom";
import BIZZ_LOGO from "../../../../assets/images/business-logo-optional.png";
import { constants, selectStyle, baseUrl } from "../../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetail } from "../../../../duck/profile/profile.action";
import { PreviewContactForm } from "./PreviewContactForm";
import { createBusinessForm, viewBusinessForm } from "../../../../duck/businessForm/businessForm.action";
const CustomizeContact = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState(false);
  const [allAddress, setAllAddress] = useState("-");

  const profileDetail = useSelector((state) => state.profile.getProfileDetailData.data);
  const viewBusinessFormData = useSelector((state) => state.businessForm.viewBusinessFormData);

  const [businessInfo, setBusinessInfo] = useState({
    name: true,
    email: true,
    phone: false,
    website: true,
    address: false,
    logo: true,
    banner: true,
    license: true,
  });

  const [formDetails, setFormDetails] = useState({
    serviceInterested: { label: "Service Interested In", required: true, visible: true },
    firstName: { label: "First Name", required: true, visible: true },
    lastName: { label: "Last Name", required: true, visible: true },
    email: { label: "Email", required: true, visible: true},
    phone: { label: "Phone Number", required: false, visible: false },
    title: { label: "Title", required: false, visible: false },
    zipCode: { label: "Zip Code", required: false, visible: false },
    city: { label: "City", required: false, visible: false },
    address1: { label: "Street Address 1", required: false, visible: false },
    address2: { label: "Street Address 2", required: false, visible: false },
    additionalInfo: { label: "Additional Info", required: false, visible: false },
  });

  const [visibleFields, setVisibleFields] = useState({
    serviceInterested: { label: "Service Interested In", required: true },
    firstName: { label: "First Name", required: true },
    lastName: { label: "Last Name", required: true },
    email: { label: "Email", required: true },
  });

const [services, setServices] = useState([
  { value: "service1", label: "Service Name 1" },
  { value: "service2", label: "Service Name 2" },
  { value: "service3", label: "Service Name 3" },
]);

  // Handle toggling business info visibility
  const handleBusinessInfoChange = (field) => {
    setBusinessInfo((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // Handle form detail updates
  const handleFormDetailChange = (field, info, value) => {
    setFormDetails((prev) => ({
      ...prev,
      [field]: { ...prev[field], visible: !prev[field].visible}
    }));
    if(!info.visible===true){
      setVisibleFields((prev) => {
        return { ...prev,[field]:info};
      });
    }else if(info.visible== true){
      setVisibleFields((prev) => {
        const newVisibleFields = { ...prev };
        delete newVisibleFields[field]; 
        return newVisibleFields;
      });
    }
  };

   // Handle the change in the Select dropdown
  const handleSelectChange = (field, info, selectedOption) => {
    const newRequiredState = selectedOption.value === "required";

    setFormDetails((prev) => ({
      ...prev,
      [field]: { ...prev[field], required: newRequiredState },
    }));

    // Update visible fields only if the field is required
    if(info.visible==true){
      setVisibleFields((prev) => {
        return { ...prev,[field]: { ...info, required: newRequiredState }};
      });
    }
  };

  // Combine profile address into a single string
  const formatAddress = (organization) => {
    let addressParts = [];
    const addressFields = ["street_address", "city", "state", "country", "zip"];
    addressFields.forEach((field) => {
      if (organization[field]) addressParts.push(organization[field]);
    });
    return addressParts.join(", ");
  };

  // Fetch and format profile details
  useEffect(() => {
    setLoader(true);
    dispatch(viewBusinessForm());
    if (profileDetail) {
      const organization = profileDetail?.data?.organization;
      if (organization && organization.id !== 1) {
        const formattedAddress = formatAddress(organization);
        setAllAddress(formattedAddress || "-");
      }
      setLoader(false);
    } else {
      dispatch(getProfileDetail());
    }
  }, [profileDetail, dispatch]);

  const renderBusinessInfo = () => {
    const infoFields = ["name", "email", "phone", "website", "address", "logo", "banner", "license"];
    return infoFields.map((info) => (
      <div className="form-group col-lg-3 col-md-6 mb-lg-5" key={info}>
        <label className="custom-control custom-checkbox">
          <input
            type="checkbox"
            checked={businessInfo[info]}
            onChange={() => handleBusinessInfoChange(info)}
            className="custom-control-input"
          />
          <span className="custom-control-label">
            {info.charAt(0).toUpperCase() + info.slice(1).replace(/_/g, " ")}
          </span>
        </label>
        <div>
          {(info === "logo" || info === "banner") && profileDetail?.organization?.name !== "Default" ? (
            <div className="logo_imgg">
              <img
                src={profileDetail?.organization?.[info] ? setImagePath(baseUrl[0] + profileDetail?.organization?.[info]) : setImagePath(BIZZ_LOGO)}
                alt=""
                style={{ height: 50, width: 50, marginLeft: "40px" }}
              />
            </div>
          ) : (
            <div style={{ marginLeft: "40px" }} className="field-text">
              {profileDetail?.organization?.name !== "Default" ? profileDetail?.organization?.[info] : "-"}
            </div>
          )}
          {info === "address" && profileDetail?.organization?.name !== "Default" && (
            <>
              {allAddress}
              <a href={`https://www.google.com/maps/search/?api=1&query=${allAddress}`} rel="noopener noreferrer" target="_blank" className="text-link" style={{ marginLeft: "40px" }}>
                (Map)
              </a>
            </>
          )}
        </div>
      </div>
    ));
  };

  const renderFormDetails = () => {
    return (
      <>
      <div className="row">
        <div className="form-group col-lg-2 col-md-2 mb-lg-2">
          <label className="custom-control custom-checkbox">
            <input
              type="checkbox"
              checked={formDetails.serviceInterested.visible}
              onChange={() => handleFormDetailChange("serviceInterested", formDetails.serviceInterested)}
              className="custom-control-input"
            />
            <span className="custom-control-label">{formDetails.serviceInterested.label}</span>
          </label>
        </div>
          <>
            <div className="form-group col-lg-2 col-md-2 mb-lg-2">
              <Select
                styles={selectStyle}
                className="floating-select"
                isSearchable={false}
                value={{
                  value: formDetails.serviceInterested.required ? "required" : "optional",
                  label: formDetails.serviceInterested.required ? "Required" : "Optional",
                }}
                onChange={(selectedOption) =>
                  handleSelectChange("serviceInterested", formDetails.serviceInterested, selectedOption)
                }
                options={[
                  { value: "required", label: "Required" },
                  { value: "optional", label: "Optional" },
                ]}
              />
            </div>
            <div className="form-group col-lg-2 col-md-2 mb-lg-2">
              <Select
                styles={selectStyle}
                className="floating-select"
                value={services[0]}
                options={services}
                // onChange={(selectedOptions) => setServices(selectedOptions)}
              />
            </div>
          </>
      </div>
      <div className="row">
        {Object.keys(formDetails).map((detail) => (
          <React.Fragment key={detail}>
            {detail!=="serviceInterested" && (
            <>
            <div className="form-group col-lg-2 col-md-2 mb-lg-2">
              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  checked={formDetails[detail].visible}
                  onChange={() => handleFormDetailChange(detail, formDetails[detail],!formDetails[detail].required)}
                  className="custom-control-input"
                />
                <span className="custom-control-label">{formDetails[detail].label}</span>
              </label>
            </div>
            <div className="form-group col-lg-2 col-md-2 mb-lg-2">
            <Select
                styles={selectStyle}
                className="floating-select"
                isSearchable={false}
                value={{ value: formDetails[detail].required ? "required" : "optional", label: formDetails[detail].required ? "Required" : "Optional" }}
                onChange={(selectedOption) => handleSelectChange(detail, formDetails[detail], selectedOption)}
                options={[{ value: "required", label: "Required" }, { value: "optional", label: "Optional" }]}
              />
            </div>
            </>
            )}
          </React.Fragment>
        ))}
      </div>
      </>
    );
  };

  const handleSaveDraft = async () => {
    setLoader(true); // Start the loader while saving the draft
    const businessDetails = [];
    if (businessInfo.name) businessDetails.push('name');
    if (businessInfo.email) businessDetails.push('email');
    if (businessInfo.phone) businessDetails.push('phone');
    if (businessInfo.website) businessDetails.push('website');
    if (businessInfo.address) businessDetails.push('address');
    if (businessInfo.logo) businessDetails.push('logo');
    if (businessInfo.banner) businessDetails.push('banner');
    if (businessInfo.license) businessDetails.push('license');
    
    const contactForm = {};
    Object.keys(visibleFields).forEach((field) => {
      contactForm[field] = visibleFields[field].required ? 'required' : 'optional';
    });
    const formData = new FormData();
    formData.append('contact_form', JSON.stringify({"business_details":businessDetails,"contact_form":visibleFields,"services":services}));
  
    // Append contact form fields
    // Object.keys(contactForm).forEach((field) => {
    //   formData.append(`contact_form`, contactForm[field]);
    // });
    // formData.append('contact_form[sevice_interested_in]', 123)
    try {
      // Make the API call using the correct payload format (multipart/form-data)
      await dispatch(createBusinessForm(formData)); // Using FormData directly
      setLoader(false);  // Stop loader after success
    } catch (error) {
      setLoader(false);  // Stop loader in case of error
      console.error("Error saving draft:", error);
    }
  };

  return (
    <>
      <Loader loader={loader} />
      {!preview && (
        <div className="main-site fixed--header lead-page-hdr unfixed-page-title">
          <Header getMainRoute={"profile"} />
          <main className="site-body">
            <section className="page-title addbasicquoteheader">
              <div className="container">
                <div className="row">
                  <div className="col-lg-auto title--col">
                    <h2 className="title">Customize Contact Form</h2>
                  </div>
                  <div className="col-auto ml-auto d-flex align-items-center title-elems">
                    <Link to={"view-profile"}><button className="btn btn-dark mr-15">Back</button></Link>
                    <button onClick={()=> handleSaveDraft()}className="btn btn-secondary mr-15">Save as Draft</button>
                    <button onClick={() => setPreview(true)} className="btn btn-primary">
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <section className="middle-section pt-0">
              <div className="container">
                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <div className="card w-100">
                        <section className="business-info">
                          <div className="card-header py-4 padd0">
                            <h2>Business Information</h2>
                            <p>Choose the business information you’d like to include on the contact form.</p>
                          </div>
                          <div className="card-body p-0">
                            <div className="business-info-grid row">{renderBusinessInfo()}</div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <div className="card w-100">
                        <section className="contact-form-details">
                          <div className="card-header py-4 padd0 mt-3">
                            <h2>Contact Form Details</h2>
                            <p>Choose the information you’d like to gather from potential clients or customers.</p>
                          </div>
                          <div className="card-body">
                            <div className="form-details-grid">{renderFormDetails()}</div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </div>
      )}

      {preview && 
        <PreviewContactForm 
          setPreview={()=>setPreview()}
          formDetails={visibleFields}
          businessInfo={businessInfo}
          services={services}
          handleSaveDraft={handleSaveDraft}
        />
      }
    </>
  );
};

export const CustomizeContactForm = CustomizeContact;