// Get Gmail Auth Link
export const GET_GMAIL_AUTH_LINK = 'GET_GMAIL_AUTH_LINK';
export const getGmailAuthLink = (data) => ({ type: GET_GMAIL_AUTH_LINK, data });
export const SUCCESS_GET_GMAIL_AUTH_LINK = 'SUCCESS_GET_GMAIL_AUTH_LINK';
export const ERROR_GET_GMAIL_AUTH_LINK = 'ERROR_GET_GMAIL_AUTH_LINK';
export const getGmailAuthLinkResponse = (type, data) => ({ type, data });

// Get Gmail Auth Token
export const GET_GMAIL_AUTH_TOKEN = 'GET_GMAIL_AUTH_TOKEN';
export const getGmailAuthToken = (data) => ({ type: GET_GMAIL_AUTH_TOKEN, data });
export const SUCCESS_GET_GMAIL_AUTH_TOKEN = 'SUCCESS_GET_GMAIL_AUTH_TOKEN';
export const ERROR_GET_GMAIL_AUTH_TOKEN = 'ERROR_GET_GMAIL_AUTH_TOKEN';
export const getGmailAuthTokenResponse = (type, data) => ({ type, data });

// Get Gmail Valid Token
export const GET_GMAIL_VALID_TOKEN = 'GET_GMAIL_VALID_TOKEN';
export const getGmailValidToken = (data) => ({ type: GET_GMAIL_VALID_TOKEN, data });
export const SUCCESS_GET_GMAIL_VALID_TOKEN = 'SUCCESS_GET_GMAIL_VALID_TOKEN';
export const ERROR_GET_GMAIL_VALID_TOKEN = 'ERROR_GET_GMAIL_VALID_TOKEN';
export const getGmailValidTokenResponse = (type, data) => ({ type, data });

// Get All Gmail Messages
export const GET_ALL_GMAIL_MESSAGES = 'GET_ALL_GMAIL_MESSAGES';
export const getAllGmailMessages = (data) => ({ type: GET_ALL_GMAIL_MESSAGES, data });
export const SUCCESS_GET_ALL_GMAIL_MESSAGES = 'SUCCESS_GET_ALL_GMAIL_MESSAGES';
export const ERROR_GET_ALL_GMAIL_MESSAGES = 'ERROR_GET_ALL_GMAIL_MESSAGES';
export const getAllGmailMessagesResponse = (type, data) => ({ type, data });

// Get Single Gmail Message
export const GET_GMAIL_MESSAGE = 'GET_GMAIL_MESSAGE';
export const getGmailMessage = (data) => ({ type: GET_GMAIL_MESSAGE, data });
export const SUCCESS_GET_GMAIL_MESSAGE = 'SUCCESS_GET_GMAIL_MESSAGE';
export const ERROR_GET_GMAIL_MESSAGE = 'ERROR_GET_GMAIL_MESSAGE';
export const getGmailMessageResponse = (type, data) => ({ type, data });

// Get Single Gmail Message By Id
export const GET_GMAIL_MESSAGE_BY_ID = 'GET_GMAIL_MESSAGE_BY_ID';
export const getGmailMessageById = (data) => ({ type: GET_GMAIL_MESSAGE_BY_ID, data });
export const SUCCESS_GET_GMAIL_MESSAGE_BY_ID = 'SUCCESS_GET_GMAIL_MESSAGE_BY_ID';
export const ERROR_GET_GMAIL_MESSAGE_BY_ID = 'ERROR_GET_GMAIL_MESSAGE_BY_ID';
export const getGmailMessageByIdResponse = (type, data) => ({ type, data });

// Delete Gmail Message
export const DELETE_GMAIL_MESSAGE = 'DELETE_GMAIL_MESSAGE';
export const deleteGmailMessage = (data) => ({ type: DELETE_GMAIL_MESSAGE, data });
export const SUCCESS_DELETE_GMAIL_MESSAGE = 'SUCCESS_DELETE_GMAIL_MESSAGE';
export const ERROR_DELETE_GMAIL_MESSAGE = 'ERROR_DELETE_GMAIL_MESSAGE';
export const deleteGmailMessageResponse = (type, data) => ({ type, data });

// Modify Gmail Message
export const MODIFY_GMAIL_MESSAGE = 'MODIFY_GMAIL_MESSAGE';
export const modifyGmailMessage = (data) => ({ type: MODIFY_GMAIL_MESSAGE, data });
export const SUCCESS_MODIFY_GMAIL_MESSAGE = 'SUCCESS_MODIFY_GMAIL_MESSAGE';
export const ERROR_MODIFY_GMAIL_MESSAGE = 'ERROR_MODIFY_GMAIL_MESSAGE';
export const modifyGmailMessageResponse = (type, data) => ({ type, data });

// Send Gmail Message
export const SEND_GMAIL_MESSAGE = 'SEND_GMAIL_MESSAGE';
export const sendGmailMessage = (data) => ({ type: SEND_GMAIL_MESSAGE, data });
export const SUCCESS_SEND_GMAIL_MESSAGE = 'SUCCESS_SEND_GMAIL_MESSAGE';
export const ERROR_SEND_GMAIL_MESSAGE = 'ERROR_SEND_GMAIL_MESSAGE';
export const sendGmailMessageResponse = (type, data) => ({ type, data });

// Modify Gmail Message as Spam
export const MARK_AS_SPAM = 'MARK_AS_SPAM';
export const markGmailMessageAsSpam = (data) => ({ type: MARK_AS_SPAM, data });
export const SUCCESS_MARK_AS_SPAM = 'SUCCESS_MARK_AS_SPAM';
export const ERROR_MARK_AS_SPAM = 'ERROR_MARK_AS_SPAM';
export const markGmailMessageAsSpamResponse = (type, data) => ({ type, data });

export const MARK_AS_TRASH = 'MARK_AS_TRASH';
export const markGmailMessageAsTrash = (data) => ({ type: MARK_AS_TRASH, data });
export const SUCCESS_MARK_AS_TRASH = 'SUCCESS_MARK_AS_TRASH';
export const ERROR_MARK_AS_TRASH = 'ERROR_MARK_AS_TRASH';
export const markGmailMessageAsTrashResponse = (type, data) => ({ type, data });

export const MARK_AS_UNREAD = 'MARK_AS_UNREAD';
export const markGmailMessageAsUnread = (data) => ({ type: MARK_AS_UNREAD, data });
export const SUCCESS_MARK_AS_UNREAD = 'SUCCESS_MARK_AS_UNREAD';
export const ERROR_MARK_AS_UNREAD = 'ERROR_MARK_AS_UNREAD';
export const markGmailMessageAsUnreadResponse = (type, data) => ({ type, data });

export const MARK_AS_INBOX = 'MARK_AS_INBOX';
export const markGmailMessageAsInbox = (data) => ({ type: MARK_AS_INBOX, data });
export const SUCCESS_MARK_AS_INBOX = 'SUCCESS_MARK_AS_INBOX';
export const ERROR_MARK_AS_INBOX = 'ERROR_MARK_AS_INBOX';
export const markGmailMessageAsInboxResponse = (type, data) => ({ type, data });

// Send Gmail Message With Attachment
export const SEND_GMAIL_MESSAGE_WITH_ATTACHMENT = 'SEND_GMAIL_MESSAGE_WITH_ATTACHMENT';
export const sendGmailMessageWithAttachment = (data) => ({ type: SEND_GMAIL_MESSAGE_WITH_ATTACHMENT, data });
export const SUCCESS_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT = 'SUCCESS_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT';
export const ERROR_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT = 'ERROR_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT';
export const sendGmailMessageWithAttachmentResponse = (type, data) => ({ type, data });

// Get Gmail Message Attachment By Id
export const GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID = 'GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID';
export const getGmailMessageAttachmentById = (data) => ({ type: GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID, data });
export const SUCCESS_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID = 'SUCCESS_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID';
export const ERROR_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID = 'ERROR_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID';
export const getGmailMessageAttachmentByIdResponse = (type, data) => ({ type, data });

// RESET Email State
export const RESET_EMAIL_STATE = 'RESET_EMAIL_STATE';
export const resetEmailState = (data) => ({ type: RESET_EMAIL_STATE, data });
export const SUCCESS_RESET_EMAIL_STATE = 'SUCCESS_RESET_EMAIL_STATE';
export const ERROR_RESET_EMAIL_STATE = 'ERROR_RESET_EMAIL_STATE';
export const resetEmailStateResponse = (type, data) => ({ type, data });

// set Gmail Messages
export const SET_GMAIL_MESSAGES = 'SET_GMAIL_MESSAGES';
export const setGmailMessage = (data) => ({ type: SET_GMAIL_MESSAGES, data });
export const SUCCESS_SET_GMAIL_MESSAGE = 'SUCCESS_SET_GMAIL_MESSAGE';
export const ERROR_SET_GMAIL_MESSAGE = 'ERROR_SET_GMAIL_MESSAGE';
export const setGmailMessageResponse = (type, data) => ({ type, data });

// Get All Draft Gmail Messages
export const GET_ALL_DRAFT_GMAIL_MESSAGES = 'GET_ALL_DRAFT_GMAIL_MESSAGES';
export const getAllDraftGmailMessages = (data) => ({ type: GET_ALL_DRAFT_GMAIL_MESSAGES, data });
export const SUCCESS_GET_ALL_DRAFT_GMAIL_MESSAGES = 'SUCCESS_GET_ALL_DRAFT_GMAIL_MESSAGES';
export const ERROR_GET_ALL_DRAFT_GMAIL_MESSAGES = 'ERROR_GET_ALL_DRAFT_GMAIL_MESSAGES';
export const getAllDraftGmailMessagesResponse = (type, data) => ({ type, data });

// Get Draft Gmail Message By Id
export const GET_DRAFT_GMAIL_MESSAGE = 'GET_DRAFT_GMAIL_MESSAGE';
export const getDraftGmailMessage = (data) => ({ type: GET_DRAFT_GMAIL_MESSAGE, data });
export const SUCCESS_GET_DRAFT_GMAIL_MESSAGE = 'SUCCESS_GET_DRAFT_GMAIL_MESSAGE';
export const ERROR_GET_DRAFT_GMAIL_MESSAGE = 'ERROR_GET_DRAFT_GMAIL_MESSAGE';
export const getDraftGmailMessageResponse = (type, data) => ({ type, data });

// Get Single Draft Gmail Message By Id
export const GET_DRAFT_GMAIL_MESSAGE_BY_ID = 'GET_DRAFT_GMAIL_MESSAGE_BY_ID';
export const getDraftGmailMessageById = (data) => ({ type: GET_DRAFT_GMAIL_MESSAGE_BY_ID, data });
export const SUCCESS_GET_DRAFT_GMAIL_MESSAGE_BY_ID = 'SUCCESS_GET_DRAFT_GMAIL_MESSAGE_BY_ID';
export const ERROR_GET_DRAFT_GMAIL_MESSAGE_BY_ID = 'ERROR_GET_DRAFT_GMAIL_MESSAGE_BY_ID';
export const getDraftGmailMessageByIdResponse = (type, data) => ({ type, data });

// Create Draft Gmail Message
export const CREATE_DRAFT_GMAIL_MESSAGE = 'CREATE_DRAFT_GMAIL_MESSAGE';
export const createDraftGmailMessage = (data) => ({ type: CREATE_DRAFT_GMAIL_MESSAGE, data });
export const SUCCESS_CREATE_DRAFT_GMAIL_MESSAGE = 'SUCCESS_CREATE_DRAFT_GMAIL_MESSAGE';
export const ERROR_CREATE_DRAFT_GMAIL_MESSAGE = 'ERROR_CREATE_DRAFT_GMAIL_MESSAGE';
export const createDraftGmailMessageResponse = (type, data) => ({ type, data });

// Update Draft Gmail Message
export const UPDATE_DRAFT_GMAIL_MESSAGE = 'UPDATE_DRAFT_GMAIL_MESSAGE';
export const updateDraftGmailMessage = (data) => ({ type: UPDATE_DRAFT_GMAIL_MESSAGE, data });
export const SUCCESS_UPDATE_DRAFT_GMAIL_MESSAGE = 'SUCCESS_UPDATE_DRAFT_GMAIL_MESSAGE';
export const ERROR_UPDATE_DRAFT_GMAIL_MESSAGE = 'ERROR_UPDATE_DRAFT_GMAIL_MESSAGE';
export const updateDraftGmailMessageResponse = (type, data) => ({ type, data });

// Delete Draft Gmail Message
export const DELETE_DRAFT_GMAIL_MESSAGE = 'DELETE_DRAFT_GMAIL_MESSAGE';
export const deleteDraftGmailMessage = (data) => ({ type: DELETE_DRAFT_GMAIL_MESSAGE, data });
export const SUCCESS_DELETE_DRAFT_GMAIL_MESSAGE = 'SUCCESS_DELETE_DRAFT_GMAIL_MESSAGE';
export const ERROR_DELETE_DRAFT_GMAIL_MESSAGE = 'ERROR_DELETE_DRAFT_GMAIL_MESSAGE';
export const deleteDraftGmailMessageResponse = (type, data) => ({ type, data });

// Save Tokens to database
export const SAVE_SOCIAL_AUTH_TOKEN = 'SAVE_SOCIAL_AUTH_TOKEN';
export const saveSocialAuthToken = (data) => ({ type: SAVE_SOCIAL_AUTH_TOKEN, data });
export const SUCCESS_SAVE_SOCIAL_AUTH_TOKEN = 'SUCCESS_SAVE_SOCIAL_AUTH_TOKEN';
export const ERROR_SAVE_SOCIAL_AUTH_TOKEN = 'ERROR_SAVE_SOCIAL_AUTH_TOKEN';
export const saveSocialAuthTokenResponse = (type, data) => ({ type, data });

// Empty Email State
export const EMPTY_EMAIL_STATE = 'EMPTY_EMAIL_STATE';
export const emptyEmailState = (data) => ({ type: EMPTY_EMAIL_STATE, data });

// Gmail Delete
export const GMAIL_ACCOUNT_DELETE = 'GMAIL_ACCOUNT_DELETE';
export const gmailAccountDelete = (data) => ({ type: GMAIL_ACCOUNT_DELETE, data });
export const SUCCESS_GMAIL_ACCOUNT_DELETE = 'SUCCESS_GMAIL_ACCOUNT_DELETE';
export const ERROR_GMAIL_ACCOUNT_DELETE = 'ERROR_GMAIL_ACCOUNT_DELETE';
export const gmailAccountDeleteResponse = (type, data) => ({ type, data });