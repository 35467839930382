import React, { useEffect, useState } from "react";
import { Loader } from "../../../component/frontend/loader/loader";
import { Header } from "../../../component/frontend/header/header";
import { Link } from "react-router-dom"; // Removed withRouter as it's not needed for functional components in React Router v6
import { Footer } from '../../../component/frontend/footer/footer';
import Select from "react-select";
import { setImagePath } from "../../../../common/custom";
import { constants, selectStyle, baseUrl } from "../../../../common/constants";
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetail } from '../../../../duck/profile/profile.action';
import { publishBusinessForm, viewPublicForm } from "../../../../duck/businessForm/businessForm.action";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom.min";

const BusinessFormComp = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [allAddress, setAllAddress] = useState('-');
  const { id } = useParams();
  const viewBusinessFormData = useSelector((state) => state.businessForm.viewBusinessFormData);
  const viewPublicFormData = useSelector((state) => state.businessForm.viewPublicFormData);
  const contactForm = viewPublicFormData?.contact_form 
  ? JSON.parse(viewPublicFormData.contact_form) 
  : null;
  const formData = contactForm?.contact_form || {}
  const services = contactForm?.contact_form || {}
  const businessDetails = contactForm?.business_details;
  useEffect(() => {
    setLoader(true);
    // Replace with the actual business form ID you need to fetch
    dispatch(viewPublicForm(id));
  }, [dispatch]);

  // Handle publishing the form
  const handlePublishForm = async () => {
    try {
      setLoader(true);
      await dispatch(publishBusinessForm(null)); // Using await to handle async action
      setLoader(false);
    } catch (error) {
      setLoader(false); // Stop loader in case of error
      console.error("Error publishing form:", error);
    }
  };
  useEffect(() => {
  console.log("viewPublicFormData:", viewPublicFormData);
}, [viewPublicFormData]);

  return (
    <>
      {loader && <Loader />} {/* Display loader if it's true */}
      <div className="login_signup main-site">
        {/* <Header /> */}
        <main className="site-body">
          {/* <section className="page-title addbasicquoteheader">
            <div className="container">
              <div className="row">
                <div className="col-lg-auto title--col">
                  <div>
                    <ol className="breadcrumb d-none d-lg-flex">
                      <li className="breadcrumb-item">
                        <Link to="">My Profile</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Contact Form Setup
                      </li>
                    </ol>
                    <h2 className="title">Preview Contact Form</h2>
                  </div>
                </div>
                <div className="col-auto ml-auto d-flex align-items-center title-elems">
                  <button className="btn btn-dark mr-15" onClick={() => setPreview(false)}>Back</button>
                  <button className="btn btn-secondary mr-15" onClick={() => handleSaveDraft()}>Save as Draft</button>
                  <button className="btn btn-primary" onClick={handlePublishForm} disabled={viewBusinessFormData?.key ? false : true}>Publish Contact Form</button>
                </div>
              </div>
            </div>
          </section>
         */}

          <section className="middle-section pt-0">
            <div className="container">
              {viewBusinessFormData?.organization?.header_image && (
                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <img
                        src={setImagePath(baseUrl[0] + viewBusinessFormData?.organization?.header_image)}
                        alt="Business Header"
                      />
                    </div>
                  </div>
                </div>
                )}
                {viewBusinessFormData?.organization && (
                <div className="row no-gutters-mbl mt-lg-4">
                    <div className="col-lg-12">
                    <div className="main-card">
                        <div className="card w-100">
                        <section className="business-info">
                            <div className="py-4 d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                {viewBusinessFormData?.organization?.company_logo && (
                                <img
                                    src={setImagePath(baseUrl[0] + viewBusinessFormData?.organization?.company_logo)}
                                    alt="Company Logo"
                                />
                                )}
                                <h3>{viewBusinessFormData?.organization?.name}</h3>
                            </div>
                            <div className="m-0 pr-5">
                                {viewBusinessFormData?.organization?.email && (
                                <p className="title">Email: {viewBusinessFormData?.organization?.email}</p>
                                )}
                                {viewBusinessFormData?.organization?.phone && (
                                <p className="title">Phone: {viewBusinessFormData?.organization?.phone}</p>
                                )}
                                {viewBusinessFormData?.organization?.address && (
                                <p className="title">Address: {allAddress}</p>
                                )}
                                {viewBusinessFormData?.organization?.licence && (
                                <p className="title">Licence: {viewBusinessFormData?.organization?.licence}</p>
                                )}
                                {viewBusinessFormData?.organization?.website && (
                                <p className="title">
                                    {viewBusinessFormData?.organization?.website || 'www.google.com'}
                                </p>
                                )}
                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
                </div>
                )}
              <div className="row no-gutters-mbl mt-lg-4">
                <div className="col-lg-12">
                  <div className="main-card">
                    <div className="card w-100">
                      <section className="contact-form-details">
                        <div className="card-header py-4 mt-3">
                          <h2>Please fill out the form below, and we will get back to you as soon as possible.</h2>
                        </div>
                        <div className="card-body">
                          <div className="form-details-grid">
                            {formData?.serviceInterested && (
                              <div className="row">
                                <div className="form-group col-lg-3 col-md-3 mb-lg-3">
                                  <label className="form-label">Service Interested In  <span className="text-danger">*</span></label>
                                  <Select
                                    styles={selectStyle}
                                    className="floating-select"
                                    isSearchable={false}
                                    options={services || {}}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="row">
                              {Object.entries(formData || {}).map(([key, { label, required }]) => (
                                key !== "serviceInterested" && (
                                  <div key={key} className="form-group col-lg-4 col-md-4 mb-lg-4">
                                    <label className="form-label">{label} {required && <span className="text-danger">*</span>}</label>
                                    <input
                                      placeholder={label}
                                      type="text"
                                      name={key}
                                      className="form-control form-control-lg"
                                    />
                                  </div>
                                )
                              ))}
                            </div>

                            <button className="btn btn-primary">Submit</button>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main> 
        {/* <Footer /> */}
      </div>
    </>
  );
};
export const BusinessForm = withRouter(BusinessFormComp);