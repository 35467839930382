import { User } from "../../../storage";
import {
  GET_GMAIL_AUTH_LINK,
  SUCCESS_GET_GMAIL_AUTH_LINK,
  ERROR_GET_GMAIL_AUTH_LINK,
  SUCCESS_GET_ALL_GMAIL_MESSAGES,
  ERROR_GET_ALL_GMAIL_MESSAGES,
  SUCCESS_GET_GMAIL_MESSAGE,
  ERROR_GET_GMAIL_MESSAGE,
  GET_ALL_GMAIL_MESSAGES,
  GET_GMAIL_MESSAGE,
  SUCCESS_RESET_EMAIL_STATE,
  ERROR_RESET_EMAIL_STATE,
  SUCCESS_DELETE_GMAIL_MESSAGE,
  ERROR_DELETE_GMAIL_MESSAGE,
  GET_GMAIL_MESSAGE_BY_ID,
  SUCCESS_GET_GMAIL_MESSAGE_BY_ID,
  ERROR_GET_GMAIL_MESSAGE_BY_ID,
  DELETE_GMAIL_MESSAGE,
  SEND_GMAIL_MESSAGE,
  SUCCESS_SEND_GMAIL_MESSAGE,
  ERROR_SEND_GMAIL_MESSAGE,
  GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
  SUCCESS_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
  ERROR_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID,
  MODIFY_GMAIL_MESSAGE,
  SUCCESS_MODIFY_GMAIL_MESSAGE,
  ERROR_MODIFY_GMAIL_MESSAGE,
  SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
  SUCCESS_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
  ERROR_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT,
  GET_GMAIL_AUTH_TOKEN,
  SUCCESS_GET_GMAIL_AUTH_TOKEN,
  ERROR_GET_GMAIL_AUTH_TOKEN,
  GET_GMAIL_VALID_TOKEN,
  SUCCESS_GET_GMAIL_VALID_TOKEN,
  ERROR_GET_GMAIL_VALID_TOKEN,
  SUCCESS_SET_GMAIL_MESSAGE,
  ERROR_SET_GMAIL_MESSAGE,
  SET_GMAIL_MESSAGES,
  CREATE_DRAFT_GMAIL_MESSAGE,
  SUCCESS_CREATE_DRAFT_GMAIL_MESSAGE,
  ERROR_CREATE_DRAFT_GMAIL_MESSAGE,
  DELETE_DRAFT_GMAIL_MESSAGE,
  SUCCESS_DELETE_DRAFT_GMAIL_MESSAGE,
  ERROR_DELETE_DRAFT_GMAIL_MESSAGE,
  GET_ALL_DRAFT_GMAIL_MESSAGES,
  ERROR_GET_ALL_DRAFT_GMAIL_MESSAGES,
  SUCCESS_GET_ALL_DRAFT_GMAIL_MESSAGES,
  GET_DRAFT_GMAIL_MESSAGE_BY_ID,
  SUCCESS_GET_DRAFT_GMAIL_MESSAGE_BY_ID,
  ERROR_GET_DRAFT_GMAIL_MESSAGE_BY_ID,
  SUCCESS_GET_DRAFT_GMAIL_MESSAGE,
  ERROR_GET_DRAFT_GMAIL_MESSAGE,
  GET_DRAFT_GMAIL_MESSAGE,
  MARK_AS_SPAM,
  SUCCESS_MARK_AS_SPAM,
  ERROR_MARK_AS_SPAM,
  ERROR_MARK_AS_TRASH,
  SUCCESS_MARK_AS_TRASH,
  MARK_AS_TRASH,
  MARK_AS_UNREAD,
  SUCCESS_MARK_AS_UNREAD,
  ERROR_MARK_AS_UNREAD,
  MARK_AS_INBOX,
  SUCCESS_MARK_AS_INBOX,
  ERROR_MARK_AS_INBOX,
  SUCCESS_SAVE_SOCIAL_AUTH_TOKEN,
  ERROR_SAVE_SOCIAL_AUTH_TOKEN,
  ERROR_UPDATE_DRAFT_GMAIL_MESSAGE,
  SUCCESS_UPDATE_DRAFT_GMAIL_MESSAGE,
  UPDATE_DRAFT_GMAIL_MESSAGE,
  EMPTY_EMAIL_STATE,
  RESET_EMAIL_STATE,
  SUCCESS_GMAIL_ACCOUNT_DELETE,
  ERROR_GMAIL_ACCOUNT_DELETE,
  GMAIL_ACCOUNT_DELETE,
} from "./google.action";
import { DEFAULT_STATE } from "./google.state";

export const googleReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case GET_GMAIL_AUTH_LINK:
      return {
        ...state, loading: true,
        getAllGmailMessagesData: {},
        getGmailMessageData: [],
        getGmailMessageByIdData: {},
        sendEmailResponseData: {},
        getGmailMessageAttachmentData: {},
        getAllDraftGmailMessagesData: {},
        getDraftGmailMessageData: [],
        getDraftGmailMessageByIdData: {},
        createDraftMessageData: {},
        deleteDraftMessageData: {},
        deleteGmailMessageData: {},
        updateDraftMessageData: {},
        saveSocialAuthTokenData: {},
      };
    case SUCCESS_GET_GMAIL_AUTH_LINK:
      const getGmailAuthLinkData = action.data;
      return {
        ...state,
        loading: false,
        getGmailAuthLinkData,
      };
    case ERROR_GET_GMAIL_AUTH_LINK:
      const errorGetGmailAuthLinkData = action.data;
      return {
        ...state,
        loading: false,
        getGmailAuthLinkData: errorGetGmailAuthLinkData,
      };
    case GET_GMAIL_AUTH_TOKEN:
      return {
        ...state, loading: true,
        getAllGmailMessagesData: {},
        getGmailMessageData: [],
        getGmailMessageByIdData: {},
        sendEmailResponseData: {},
        getGmailMessageAttachmentData: {},
        getAllDraftGmailMessagesData: {},
        getDraftGmailMessageData: [],
        getDraftGmailMessageByIdData: {},
        createDraftMessageData: {},
        deleteDraftMessageData: {},
        deleteGmailMessageData: {},
        updateDraftMessageData: {},
        saveSocialAuthTokenData: {},
      };
    case SUCCESS_GET_GMAIL_AUTH_TOKEN:
      let getGmailAuthTokenData = action.data;
      if (
        getGmailAuthTokenData &&
        getGmailAuthTokenData.accessToken &&
        getGmailAuthTokenData.refreshToken &&
        getGmailAuthTokenData.expiry_date
      ) {
        let socialData = {
          ...User.getSocialUserDetails(),
          google: {
            access_token: getGmailAuthTokenData.accessToken,
            refresh_token: getGmailAuthTokenData.refreshToken,
            expire_in: getGmailAuthTokenData.expiry_date,
            social_email: getGmailAuthTokenData.social_email,
            social_user_id: getGmailAuthTokenData.social_user_id,
            social_user_name: getGmailAuthTokenData.social_user_name,
          }
        };
        User.setSocialUserDetails(socialData);
        getGmailAuthTokenData = socialData.google;
        return {
          ...state,
          loading: false,
          getGmailAuthTokenData,
          getGmailAuthLinkData: {}
        };
      } else {
        return {
          ...state,
          loading: false,
          getGmailAuthTokenData: {},
          getGmailAuthLinkData: {}
        };
      }
    case ERROR_GET_GMAIL_AUTH_TOKEN:
      const errorGetGmailAuthTokenData = action.data;
      return {
        ...state,
        loading: false,
        getGmailAuthTokenData: {},
      };
    case GET_GMAIL_VALID_TOKEN:
      return { ...state, loading: true, errorTokenData: {} };
    case SUCCESS_GET_GMAIL_VALID_TOKEN:
      const getGmailTokenData = action.data;
      let setGmailAuthTokenData = state.getGmailAuthTokenData;
      if (
        getGmailTokenData &&
        getGmailTokenData.accessToken &&
        getGmailTokenData.expires_in
      ) {
        let setSocialData = {
          ...User.getSocialUserDetails(),
          google: {
            ...User.getSocialUserDetails()?.google,
            access_token: getGmailTokenData.accessToken,
            expire_in: getGmailTokenData.expires_in,
          }
        };
        User.setSocialUserDetails(setSocialData);
        setGmailAuthTokenData = setSocialData.google;
      }
      return {
        ...state,
        loading: false,
        getGmailAuthTokenData: setGmailAuthTokenData,
        getGmailTokenData: setGmailAuthTokenData,
        errorTokenData: {},
      };
    case ERROR_GET_GMAIL_VALID_TOKEN:
      const errorGetGmailTokenData = action.data;
      return {
        ...state,
        loading: false,
        getGmailAuthTokenData: errorGetGmailTokenData,
        setGmailAuthTokenData: {},
        errorTokenData: {},
      };
    case GET_ALL_GMAIL_MESSAGES:
      return { ...state, loading: true };
    case SUCCESS_GET_ALL_GMAIL_MESSAGES:
      const getAllGmailMessagesData = action.data;
      if (getAllGmailMessagesData.resultSizeEstimate > 0) {
        return {
          ...state,
          loading: false,
          getAllGmailMessagesData,
        };
      } else {
        return {
          ...state,
          loading: false,
          getAllGmailMessagesData,
        };
      }
    case ERROR_GET_ALL_GMAIL_MESSAGES:
      const errorGetAllGmailMessagesData = action.data;
      return {
        ...state,
        loading: false,
        getAllGmailMessagesData: [],
        errorTokenData:
          errorGetAllGmailMessagesData &&
            Object.keys(errorGetAllGmailMessagesData).length > 0
            ? errorGetAllGmailMessagesData.error
            : {},
      };
    case GET_GMAIL_MESSAGE:
      return { ...state, loading: true };
    case SUCCESS_GET_GMAIL_MESSAGE:
      const getGmailMessageData = action.data;
      return { ...state, loading: false, getGmailMessageData };
    case ERROR_GET_GMAIL_MESSAGE:
      const errorGetGmailMessageData = action.data;
      return {
        ...state,
        loading: false,
        getGmailMessageData: [],
        errorTokenData:
          errorGetGmailMessageData &&
            Object.keys(errorGetGmailMessageData).length > 0
            ? errorGetGmailMessageData.error
            : {},
      };
    case GET_GMAIL_MESSAGE_BY_ID:
      return { ...state, loading: true };
    case SUCCESS_GET_GMAIL_MESSAGE_BY_ID:
      const getGmailMessageByIdData = action.data;
      return {
        ...state,
        loading: false,
        getAllGmailMessagesData: {},
        getGmailMessageData: [],
        getGmailMessageByIdData,
      };
    case ERROR_GET_GMAIL_MESSAGE_BY_ID:
      const errorGetGmailMessageByIdData = action.data;
      return {
        ...state,
        loading: false,
        getAllGmailMessagesData: {},
        getGmailMessageData: [],
        getGmailMessageByIdData: {},
        errorTokenData:
          errorGetGmailMessageByIdData &&
            Object.keys(errorGetGmailMessageByIdData).length > 0
            ? errorGetGmailMessageByIdData.error
            : {},
      };
    case DELETE_GMAIL_MESSAGE:
      const deleteGmailMessageId = action.data;
      return {
        ...state,
        loading: true,
        deleteGmailMessageData: deleteGmailMessageId,
      };
    case SUCCESS_DELETE_GMAIL_MESSAGE:
      const deletedGmailMessageId = state.deleteGmailMessageData;
      let gmailMessageData = [...state.getGmailMessageData];
      gmailMessageData = gmailMessageData.filter(
        (data) => data.id !== deletedGmailMessageId
      );
      return {
        ...state,
        loading: false,
        deleteGmailMessageData: {},
        getGmailMessageData: gmailMessageData,
      };
    case ERROR_DELETE_GMAIL_MESSAGE:
      const errorDeleteGmailMessage = action.data;
      return {
        ...state,
        loading: false,
        errorTokenData:
          errorDeleteGmailMessage &&
            Object.keys(errorDeleteGmailMessage).length > 0
            ? errorDeleteGmailMessage.error
            : {},
      };
    case MODIFY_GMAIL_MESSAGE:
      return { ...state, loading: true };
    case SUCCESS_MODIFY_GMAIL_MESSAGE:
      const payload = action.data;
      if (payload && payload?.labelIds && payload?.labelIds?.length) {
        let gmailMessageData = [...state.getGmailMessageData];
        let gmailMessageDataById = state.getGmailMessageByIdData;
        let gmailDraftMessageData = [...state.getDraftGmailMessageData];
        let gmailDraftMessageDataById = state.getDraftGmailMessageByIdData;

        if (gmailDraftMessageData.length) {
          const findIndex = gmailDraftMessageData.findIndex(
            (data) => data.id === payload.id
          );
          if (
            gmailDraftMessageData[findIndex]?.labelIds?.includes("IMPORTANT") &&
            !payload?.labelIds?.includes("IMPORTANT")
          ) {
            gmailDraftMessageData[findIndex] = {
              ...gmailDraftMessageData[findIndex],
              labelIds: payload?.labelIds,
            };
          }
          if (payload?.labelIds?.includes("IMPORTANT")) {
            gmailDraftMessageData[findIndex] = {
              ...gmailDraftMessageData[findIndex],
              labelIds: [
                ...gmailDraftMessageData[findIndex].labelIds,
                "IMPORTANT",
              ],
            };
          }
          if (
            gmailDraftMessageData[findIndex]?.labelIds?.includes("STARRED") &&
            !payload?.labelIds?.includes("STARRED")
          ) {
            gmailDraftMessageData[findIndex] = {
              ...gmailDraftMessageData[findIndex],
              labelIds: payload?.labelIds,
            };
          }
          if (payload?.labelIds?.includes("STARRED")) {
            let findIndex = gmailDraftMessageData.findIndex(
              (data) => data.id === payload.id
            );
            gmailDraftMessageData[findIndex] = {
              ...gmailDraftMessageData[findIndex],
              labelIds: [
                ...gmailDraftMessageData[findIndex]?.labelIds,
                "STARRED",
              ],
            };
          }
          // if (gmailDraftMessageData[findIndex]?.labelIds?.includes("TRASH") &&
          // !payload?.labelIds?.includes("TRASH")) {
          //   gmailDraftMessageData = gmailDraftMessageData.filter(
          //     (data) => data.id !== payload.id
          //   );
          // }
          // if (gmailDraftMessageData[findIndex]?.labelIds?.includes("SPAM") &&
          // !payload?.labelIds?.includes("SPAM")) {
          //   gmailDraftMessageData = gmailDraftMessageData.filter(
          //     (data) => data.id !== payload.id
          //   );
          // }
          // if (payload?.labelIds?.includes("SPAM")) {
          //   gmailDraftMessageData = gmailDraftMessageData.filter(
          //     (data) => data.id !== payload.id
          //   );
          // }
          return {
            ...state,
            loading: false,
            getDraftGmailMessageData: gmailDraftMessageData,
          };
        } else {
          if (
            gmailDraftMessageDataById &&
            Object.keys(gmailDraftMessageDataById).length > 0
          ) {
            if (
              gmailDraftMessageDataById?.message?.labelIds?.includes(
                "STARRED"
              ) &&
              !payload?.labelIds?.includes("STARRED")
            ) {
              gmailDraftMessageDataById = {
                ...gmailDraftMessageDataById,
                message: {
                  ...gmailDraftMessageDataById?.message,
                  labelIds: payload?.labelIds,
                },
              };
            }
            if (payload?.labelIds?.includes("STARRED")) {
              gmailDraftMessageDataById = {
                ...gmailDraftMessageDataById,
                message: {
                  ...gmailDraftMessageDataById?.message,
                  labelIds: [
                    ...gmailDraftMessageDataById?.message?.labelIds,
                    "STARRED",
                  ],
                },
              };
            }
            if (
              gmailDraftMessageDataById?.message?.labelIds?.includes(
                "IMPORTANT"
              ) &&
              !payload?.labelIds?.includes("IMPORTANT")
            ) {
              gmailDraftMessageDataById = {
                ...gmailDraftMessageDataById,
                message: {
                  ...gmailDraftMessageDataById?.message,
                  labelIds: payload?.labelIds,
                },
              };
            }
            if (payload?.labelIds?.includes("IMPORTANT")) {
              gmailDraftMessageDataById = {
                ...gmailDraftMessageDataById,
                message: {
                  ...gmailDraftMessageDataById?.message,
                  labelIds: [
                    ...gmailDraftMessageDataById?.message?.labelIds,
                    "IMPORTANT",
                  ],
                },
              };
            }
            return {
              ...state,
              loading: false,
              getDraftGmailMessageByIdData: gmailDraftMessageDataById,
            };
          }
        }

        if (gmailMessageData.length) {
          const findIndex = gmailMessageData.findIndex(
            (data) => data.id === payload.id
          );
          if (
            gmailMessageData[findIndex]?.labelIds?.includes("IMPORTANT") &&
            !payload?.labelIds?.includes("IMPORTANT")
          ) {
            gmailMessageData[findIndex] = {
              ...gmailMessageData[findIndex],
              labelIds: payload?.labelIds,
            };
          }
          if (payload?.labelIds?.includes("IMPORTANT")) {
            gmailMessageData[findIndex] = {
              ...gmailMessageData[findIndex],
              labelIds: [...gmailMessageData[findIndex].labelIds, "IMPORTANT"],
            };
          }
          if (
            gmailMessageData[findIndex]?.labelIds?.includes("STARRED") &&
            !payload?.labelIds?.includes("STARRED")
          ) {
            gmailMessageData[findIndex] = {
              ...gmailMessageData[findIndex],
              labelIds: payload?.labelIds,
            };
          }
          if (payload?.labelIds?.includes("STARRED")) {
            let findIndex = gmailMessageData.findIndex(
              (data) => data.id === payload.id
            );
            gmailMessageData[findIndex] = {
              ...gmailMessageData[findIndex],
              labelIds: [...gmailMessageData[findIndex]?.labelIds, "STARRED"],
            };
          }
          // if (gmailMessageData[findIndex]?.labelIds?.includes("TRASH") &&
          // !payload?.labelIds?.includes("TRASH")) {
          //   gmailMessageData = gmailMessageData.filter(
          //     (data) => data.id !== payload.id
          //   );
          // }
          // if (gmailMessageData[findIndex]?.labelIds?.includes("SPAM") &&
          // !payload?.labelIds?.includes("SPAM")) {
          //   gmailMessageData = gmailMessageData.filter(
          //     (data) => data.id !== payload.id
          //   );
          // }
          // if (payload?.labelIds?.includes("SPAM")) {
          //   gmailMessageData = gmailMessageData.filter(
          //     (data) => data.id !== payload.id
          //   );
          // }
          return {
            ...state,
            loading: false,
            getGmailMessageData: gmailMessageData,
          };
        } else {
          if (
            gmailMessageDataById &&
            Object.keys(gmailMessageDataById).length > 0
          ) {
            const findIndex = gmailMessageDataById.messages.findIndex(
              (data) => data.id === payload.id
            );
            let dataUpdated = [...gmailMessageDataById?.messages];
            if (
              gmailMessageDataById?.messages[findIndex]?.labelIds?.includes(
                "STARRED"
              ) &&
              !payload?.labelIds?.includes("STARRED")
            ) {
              dataUpdated[findIndex] = {
                ...dataUpdated[findIndex],
                labelIds: payload?.labelIds,
              }
              gmailMessageDataById = {
                ...gmailMessageDataById,
                messages: dataUpdated
              };
            }
            if (payload?.labelIds?.includes("STARRED")) {
              dataUpdated[findIndex] = {
                ...dataUpdated[findIndex],
                labelIds: [
                  ...dataUpdated[findIndex]?.labelIds,
                  "STARRED",
                ],
              };
              gmailMessageDataById = {
                ...gmailMessageDataById,
                messages: dataUpdated,
              };
            }
            if (
              gmailMessageDataById?.messages[findIndex]?.labelIds?.includes(
                "IMPORTANT"
              ) &&
              !payload?.labelIds?.includes("IMPORTANT")
            ) {
              dataUpdated[findIndex] = {
                ...dataUpdated[findIndex],
                labelIds: payload?.labelIds,
              }
              gmailMessageDataById = {
                ...gmailMessageDataById,
                messages: dataUpdated
              };
            }
            if (payload?.labelIds?.includes("IMPORTANT")) {
              dataUpdated[findIndex] = {
                ...dataUpdated[findIndex],
                labelIds: [
                  ...dataUpdated[findIndex]?.labelIds,
                  "IMPORTANT",
                ],
              };
              gmailMessageDataById = {
                ...gmailMessageDataById,
                messages: dataUpdated,
              };
            }
            return {
              ...state,
              loading: false,
              getGmailMessageByIdData: gmailMessageDataById,
            };
          }
        }
      }
      return {
        ...state,
        loading: false,
      };
    case ERROR_MODIFY_GMAIL_MESSAGE:
      const errorModifyGmailMessageData = action.data;
      return {
        ...state,
        loading: false,
        errorTokenData:
          errorModifyGmailMessageData &&
            Object.keys(errorModifyGmailMessageData).length > 0
            ? errorModifyGmailMessageData.error
            : {},
      };
    case MARK_AS_SPAM:
      return { ...state, loading: true };
    case SUCCESS_MARK_AS_SPAM:
      const markAsSpamPayload = action.data;
      if (
        markAsSpamPayload &&
        markAsSpamPayload?.labelIds &&
        markAsSpamPayload?.labelIds?.length
      ) {
        let gmailMessageData = [...state.getGmailMessageData];
        if (gmailMessageData.length) {
          const findIndex = gmailMessageData.findIndex(
            (data) => data.id === markAsSpamPayload.id
          );
          if (
            gmailMessageData[findIndex]?.labelIds?.includes("SPAM") &&
            !markAsSpamPayload?.labelIds?.includes("SPAM")
          ) {
            gmailMessageData = gmailMessageData.filter(
              (data) => data.id !== markAsSpamPayload.id
            );
          }
          if (markAsSpamPayload?.labelIds?.includes("SPAM")) {
            gmailMessageData = gmailMessageData.filter(
              (data) => data.id !== markAsSpamPayload.id
            );
          }
          return {
            ...state,
            loading: false,
            getGmailMessageData: gmailMessageData,
          };
        }
      }
      return {
        ...state,
        loading: false,
      };
    case ERROR_MARK_AS_SPAM:
      const errorMarkAsSpamData = action.data;
      return {
        ...state,
        loading: false,
        errorTokenData:
          errorMarkAsSpamData &&
            Object.keys(errorMarkAsSpamData).length > 0
            ? errorMarkAsSpamData.error
            : {},
      };
    case MARK_AS_TRASH:
      return { ...state, loading: true };
    case SUCCESS_MARK_AS_TRASH:
      const markAsTrashPayload = action.data;
      if (
        markAsTrashPayload &&
        markAsTrashPayload?.labelIds &&
        markAsTrashPayload?.labelIds?.length
      ) {
        let gmailMessageData = [...state.getGmailMessageData];
        if (gmailMessageData.length) {
          const findIndex = gmailMessageData.findIndex(
            (data) => data.id === markAsTrashPayload.id
          );

          if (
            gmailMessageData[findIndex]?.labelIds?.includes("TRASH") &&
            !markAsTrashPayload?.labelIds?.includes("TRASH")
          ) {
            gmailMessageData = gmailMessageData.filter(
              (data) => data.id !== markAsTrashPayload.id
            );
          }
          if (markAsTrashPayload?.labelIds?.includes("TRASH")) {
            gmailMessageData = gmailMessageData.filter(
              (data) => data.id !== markAsTrashPayload.id
            );
          }
          return {
            ...state,
            loading: false,
            getGmailMessageData: gmailMessageData,
          };
        }
      }
      return {
        ...state,
        loading: false,
      };
    case ERROR_MARK_AS_TRASH:
      const errorMarkAsTrashData = action.data;
      return {
        ...state,
        loading: false,
        errorTokenData:
          errorMarkAsTrashData &&
            Object.keys(errorMarkAsTrashData).length > 0
            ? errorMarkAsTrashData.error
            : {},
      };
    case MARK_AS_UNREAD:
      return { ...state, loading: true };
    case SUCCESS_MARK_AS_UNREAD:
      const markAsUnreadPayload = action.data;
      if (
        markAsUnreadPayload &&
        markAsUnreadPayload?.labelIds &&
        markAsUnreadPayload?.labelIds?.length
      ) {
        let gmailMessageData = [...state.getGmailMessageData];

        if (gmailMessageData.length) {
          const findIndex = gmailMessageData.findIndex(
            (data) => data.id === markAsUnreadPayload.id
          );
          if (
            gmailMessageData[findIndex]?.labelIds?.includes("UNREAD") &&
            !markAsUnreadPayload?.labelIds?.includes("UNREAD")
          ) {
            gmailMessageData[findIndex] = {
              ...gmailMessageData[findIndex],
              labelIds: markAsUnreadPayload?.labelIds,
            };
          }
          if (markAsUnreadPayload?.labelIds?.includes("UNREAD")) {
            let findIndex = gmailMessageData.findIndex(
              (data) => data.id === markAsUnreadPayload.id
            );
            gmailMessageData[findIndex] = {
              ...gmailMessageData[findIndex],
              labelIds: [...gmailMessageData[findIndex]?.labelIds, "UNREAD"],
            };
          }
          return {
            ...state,
            loading: false,
            getGmailMessageData: gmailMessageData,
          };
        }
      }
      return {
        ...state,
        loading: false,
      };
    case ERROR_MARK_AS_UNREAD:
      const errorMarkAsUnreadData = action.data;
      return {
        ...state,
        loading: false,
        errorTokenData:
          errorMarkAsUnreadData &&
            Object.keys(errorMarkAsUnreadData).length > 0
            ? errorMarkAsUnreadData.error
            : {},
      };
    case MARK_AS_INBOX:
      return { ...state, loading: true };
    case SUCCESS_MARK_AS_INBOX:
      const markAsInboxPayload = action.data;
      if (
        markAsInboxPayload &&
        markAsInboxPayload?.labelIds &&
        markAsInboxPayload?.labelIds?.length
      ) {
        let gmailMessageData = [...state.getGmailMessageData];
        if (gmailMessageData.length) {
          const findIndex = gmailMessageData.findIndex(
            (data) => data.id === markAsInboxPayload.id
          );

          if (
            gmailMessageData[findIndex]?.labelIds?.includes("INBOX") &&
            !markAsInboxPayload?.labelIds?.includes("INBOX")
          ) {
            gmailMessageData = gmailMessageData.filter(
              (data) => data.id !== markAsInboxPayload.id
            );
          }
          if (markAsInboxPayload?.labelIds?.includes("INBOX")) {
            gmailMessageData = gmailMessageData.filter(
              (data) => data.id !== markAsInboxPayload.id
            );
          }
          return {
            ...state,
            loading: false,
            getGmailMessageData: gmailMessageData,
          };
        }
      }
      return {
        ...state,
        loading: false,
      };
    case ERROR_MARK_AS_INBOX:
      const errorMarkAsInboxData = action.data;
      return {
        ...state,
        loading: false,
        errorTokenData:
          errorMarkAsInboxData &&
            Object.keys(errorMarkAsInboxData).length > 0
            ? errorMarkAsInboxData.error
            : {},
      };
    case SEND_GMAIL_MESSAGE:
      return { ...state, loading: true };
    case SUCCESS_SEND_GMAIL_MESSAGE:
      const sendEmailResponseData = action.data;
      return { ...state, loading: false, sendEmailResponseData };
    case ERROR_SEND_GMAIL_MESSAGE:
      const errorSendEmailResponseData = action.data;
      return {
        ...state,
        loading: false,
        sendEmailResponseData: {},
        errorTokenData:
          errorSendEmailResponseData &&
            Object.keys(errorSendEmailResponseData).length > 0
            ? errorSendEmailResponseData.error
            : {},
      };
    case SEND_GMAIL_MESSAGE_WITH_ATTACHMENT:
      return { ...state, loading: true };
    case SUCCESS_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT:
      const sendEmailWithAttachmentResponseData = action.data;
      return {
        ...state,
        loading: false,
        sendEmailResponseData: sendEmailWithAttachmentResponseData,
      };
    case ERROR_SEND_GMAIL_MESSAGE_WITH_ATTACHMENT:
      const errorSendEmailWithAttachmentResponseData = action.data;
      return {
        ...state,
        loading: false,
        sendEmailResponseData: {},
        errorTokenData:
          errorSendEmailWithAttachmentResponseData &&
            Object.keys(errorSendEmailWithAttachmentResponseData).length > 0
            ? errorSendEmailWithAttachmentResponseData.error
            : {},
      };
    case GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID:
      return { ...state, loading: true };
    case SUCCESS_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID:
      const getGmailMessageAttachmentData = action.data;
      return { ...state, loading: false, getGmailMessageAttachmentData };
    case ERROR_GET_GMAIL_MESSAGE_ATTACHMENT_BY_ID:
      const errorGetGmailMessageAttachmentData = action.data;
      return {
        ...state,
        loading: false,
        getGmailMessageAttachmentData: {},
        errorTokenData:
          errorGetGmailMessageAttachmentData &&
            Object.keys(errorGetGmailMessageAttachmentData).length > 0
            ? errorGetGmailMessageAttachmentData.error
            : {},
      };
    case RESET_EMAIL_STATE:
      return {
        ...state,
        getAllGmailMessagesData: {},
        getGmailMessageData: [],
        getGmailMessageByIdData: {},
        sendEmailResponseData: {},
        getGmailMessageAttachmentData: {},
        getAllDraftGmailMessagesData: {},
        getDraftGmailMessageData: [],
        getDraftGmailMessageByIdData: {},
        createDraftMessageData: {},
        deleteDraftMessageData: {},
        deleteGmailMessageData: {},
        updateDraftMessageData: {},
        saveSocialAuthTokenData: {},
      }
    case SUCCESS_RESET_EMAIL_STATE:
      return {
        ...state,
      };
    case ERROR_RESET_EMAIL_STATE:
      return {
        ...state,
      };
    case SET_GMAIL_MESSAGES:
      return { ...state, loading: true };
    case SUCCESS_SET_GMAIL_MESSAGE:
      const setGmailMessageData = action.data;
      return {
        ...state,
        loading: false,
        getGmailMessageData: [
          ...(state.getGmailMessageData ?? []),
          ...setGmailMessageData,
        ],
      };
    case ERROR_SET_GMAIL_MESSAGE:
      const errorSetGmailMessageData = action.data;
      return {
        ...state,
        loading: false,
        getGmailMessageData: [],
        errorTokenData:
          errorSetGmailMessageData &&
            Object.keys(errorSetGmailMessageData).length > 0
            ? errorSetGmailMessageData.error
            : {},
      };
    case GET_ALL_DRAFT_GMAIL_MESSAGES:
      return { ...state, loading: true };
    case SUCCESS_GET_ALL_DRAFT_GMAIL_MESSAGES:
      const getAllDraftGmailMessagesData = action.data;
      if (getAllDraftGmailMessagesData.resultSizeEstimate > 0) {
        return {
          ...state,
          loading: false,
          getAllDraftGmailMessagesData,
        };
      } else {
        return {
          ...state,
          loading: false,
          getAllDraftGmailMessagesData,
        };
      }
    case ERROR_GET_ALL_DRAFT_GMAIL_MESSAGES:
      const errorGetAllDraftGmailMessagesData = action.data;
      return {
        ...state,
        loading: false,
        getAllDraftGmailMessagesData: {},
        errorTokenData:
          errorGetAllDraftGmailMessagesData &&
            Object.keys(errorGetAllDraftGmailMessagesData).length > 0
            ? errorGetAllDraftGmailMessagesData.error
            : {},
      };
    case GET_DRAFT_GMAIL_MESSAGE:
      return { ...state, loading: true };
    case SUCCESS_GET_DRAFT_GMAIL_MESSAGE:
      const getDraftGmailMessageData = action.data;
      return {
        ...state,
        loading: false,
        getDraftGmailMessageData: [],
        getDraftGmailMessageByIdData: getDraftGmailMessageData,
        updateDraftMessageData: {}
      };
    case ERROR_GET_DRAFT_GMAIL_MESSAGE:
      const errorGetDraftGmailMessageData = action.data;
      return {
        ...state,
        loading: false,
        getDraftGmailMessageData: [],
        getDraftGmailMessageByIdData: {},
        updateDraftMessageData: {},
        errorTokenData:
          errorGetDraftGmailMessageData &&
            Object.keys(errorGetDraftGmailMessageData).length > 0
            ? errorGetDraftGmailMessageData.error
            : {},
      };
    case GET_DRAFT_GMAIL_MESSAGE_BY_ID:
      return { ...state, loading: true, };
    case SUCCESS_GET_DRAFT_GMAIL_MESSAGE_BY_ID:
      const getDraftGmailMessageDataById = action.data;
      return {
        ...state,
        loading: false,
        getAllDraftGmailMessagesData: {},
        getDraftGmailMessageData: [
          ...(state.getDraftGmailMessageData ?? []),
          ...getDraftGmailMessageDataById.map((data) => {
            return { ...data.message, draftId: data.id };
          }),
        ],
        getDraftGmailMessageByIdData: {},
        updateDraftMessageData: {}
      };
    case ERROR_GET_DRAFT_GMAIL_MESSAGE_BY_ID:
      const errorGetDraftGmailMessageDataById = action.data;
      return {
        ...state,
        loading: false,
        getDraftGmailMessageData: [],
        getDraftGmailMessageByIdData: {},
        updateDraftMessageData: {},
        errorTokenData:
          errorGetDraftGmailMessageDataById &&
            Object.keys(errorGetDraftGmailMessageDataById).length > 0
            ? errorGetDraftGmailMessageDataById.error
            : {},
      };
    case CREATE_DRAFT_GMAIL_MESSAGE:
      return { ...state, loading: true };
    case SUCCESS_CREATE_DRAFT_GMAIL_MESSAGE:
      const createDraftMessageData = action.data;
      return { ...state, loading: false, createDraftMessageData };
    case ERROR_CREATE_DRAFT_GMAIL_MESSAGE:
      const errorCreateDraftMessageData = action.data;
      return {
        ...state,
        loading: false,
        createDraftMessageData: {},
        errorTokenData:
          errorCreateDraftMessageData &&
            Object.keys(errorCreateDraftMessageData).length > 0
            ? errorCreateDraftMessageData.error
            : {},
      };
    case UPDATE_DRAFT_GMAIL_MESSAGE:
      return { ...state, loading: true };
    case SUCCESS_UPDATE_DRAFT_GMAIL_MESSAGE:
      const updateDraftMessageData = action.data;
      return { ...state, loading: false, updateDraftMessageData };
    case ERROR_UPDATE_DRAFT_GMAIL_MESSAGE:
      const errorUpdateDraftMessageData = action.data;
      return {
        ...state,
        loading: false,
        updateDraftMessageData: {},
        errorTokenData:
          errorUpdateDraftMessageData &&
            Object.keys(errorUpdateDraftMessageData).length > 0
            ? errorUpdateDraftMessageData.error
            : {},
      };
    case DELETE_DRAFT_GMAIL_MESSAGE:
      const deleteDraftMessageId = action.data;
      return { ...state, loading: true, deleteDraftMessageData: deleteDraftMessageId };
    case SUCCESS_DELETE_DRAFT_GMAIL_MESSAGE:
      const deleteDraftMessageData = state.deleteDraftMessageData;
      if (deleteDraftMessageData) {
        let gmailMessageData = [...state.getDraftGmailMessageData];
        gmailMessageData = gmailMessageData.filter(
          (data) => data.draftId !== deleteDraftMessageData
        );
        return {
          ...state,
          loading: false,
          getDraftGmailMessageData: gmailMessageData,
          deleteDraftMessageData: '',
        };
      } else {
        return { ...state, loading: false, deleteDraftMessageData };
      }
    case ERROR_DELETE_DRAFT_GMAIL_MESSAGE:
      const errorDeleteDraftMessageData = action.data;
      return {
        ...state,
        loading: false,
        deleteDraftMessageData: {},
        errorTokenData:
          errorDeleteDraftMessageData &&
            Object.keys(errorDeleteDraftMessageData).length > 0
            ? errorDeleteDraftMessageData.error
            : {},
      };
    case SUCCESS_SAVE_SOCIAL_AUTH_TOKEN:
      const saveSocialAuthTokenData = action.data;
      return {
        ...state,
        loading: false,
        saveSocialAuthTokenData,
      };
    case ERROR_SAVE_SOCIAL_AUTH_TOKEN:
      const errorSaveSocialAuthTokenData = action.data;
      return {
        ...state,
        loading: false,
        saveSocialAuthTokenData: errorSaveSocialAuthTokenData,
      };
    case EMPTY_EMAIL_STATE:
      return {
        ...state,
        loading: false,
        errorTokenData: {},
        getGmailAuthLinkData: {},
        getGmailAuthTokenData: {},
        getGmailTokenData: {},
        getAllGmailMessagesData: {},
        getGmailMessageData: [],
        getGmailMessageByIdData: {},
        sendEmailResponseData: {},
        getGmailMessageAttachmentData: {},
        deleteGmailMessageData: {},
        getAllDraftGmailMessagesData: {},
        getDraftGmailMessageData: [],
        getDraftGmailMessageByIdData: {},
        createDraftMessageData: {},
        updateDraftMessageData: {},
        deleteDraftMessageData: {},
        saveSocialAuthTokenData: {},
      }

    // Gmail Delete
    case GMAIL_ACCOUNT_DELETE:
      return { ...state }
    case SUCCESS_GMAIL_ACCOUNT_DELETE:
      const gmailAccountDeleteData = action.data;
      return { ...state, gmailAccountDeleteData }
    case ERROR_GMAIL_ACCOUNT_DELETE:
      const errorGmailAccountDeleteData = action.data;
      return { ...state, gmailAccountDeleteData: errorGmailAccountDeleteData }
    default:
      return state;
  }
};
