import { request } from "../request/axios.request";
import { MICROSOFT_AUTH_TOKEN, MICROSOFT_SIGNIN_URL } from "../routing/route";

export async function getMicrosoftAuthLinkApi() {
  const response = await request({
    url: MICROSOFT_SIGNIN_URL,
    method: 'get'
  });
  return response;
}

export async function getMicrosoftAuthTokenApi(data) {
  const response = await request({
    url: MICROSOFT_AUTH_TOKEN,
    method: 'post',
    data: { refreshToken: data.data }
  });
  return response;
}

export async function getAllOutlookMailFoldersApi() {
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/mailFolders`,
    method: "get",
  });
  return response;
}

export async function getAllOutlookMessagesApi(data) {
  const { mailFolderId, nextLink, search } = data.data;
  const orderby = (`createdDateTime DESC`);
  const top = 25;
  const skip = 0;
  const response = await request({
    url: `${nextLink ? nextLink : `https://graph.microsoft.com/v1.0/me/mailFolders/${mailFolderId}/messages?${search ? `&search=${search}`: `$orderby=${orderby}&top=${top}&skip=${skip}`}`}`,
    method: "get",
  });
  return response;
}

export async function getOutlookMessageByIdApi(data) {
  const { mailFolderId, messageId } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/mailFolders/${mailFolderId}/messages/${messageId}`,
    method: "get",
  });
  return response;
}

export async function getOutlookMessageByIdAttachmentsApi(data) {
  const { mailFolderId, messageId } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/mailFolders/${mailFolderId}/messages/${messageId}/attachments`,
    method: "get",
  });
  return response;
}

export async function sendReplyToOutlookMessageByIdApi(data) {
  const { mailFolderId, messageId, body } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/mailFolders/${mailFolderId}/messages/${messageId}/reply`,
    method: "post",
    data: body
  });
  return response;
}
export async function sendReplyToAllOutlookMessageByIdApi(data) {
  const { mailFolderId, messageId, body } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/mailFolders/${mailFolderId}/messages/${messageId}/replyAll`,
    method: "post",
    data: body
  });
  return response;
}
export async function sendForwardOutlookMessageByIdApi(data) {
  const { mailFolderId, messageId, body } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/mailFolders/${mailFolderId}/messages/${messageId}/forward`,
    method: "post",
    data: body
  });
  return response;
}
export async function sendOutlookMessageApi(data) {
  const { body } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/sendMail`,
    method: "post",
    data: body
  });
  return response;
}

export async function createOutlookDraftMessageApi(data) {
  const { body } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/messages`,
    method: "post",
    data: body
  });
  return response;
}

export async function updateOutlookMessageApi(data) {
  const { messageId, body } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/messages/${messageId}`,
    method: "patch",
    data: body
  });
  return response;
}

export async function deleteOutlookMessageApi(data) {
  const { messageId } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/messages/${messageId}`,
    method: "delete"
  });
  return response;
}

export async function moveOutlookMessageApi(data) {
  const { mailFolderId, messageId, body } = data.data;
  const response = await request({
    url: `https://graph.microsoft.com/v1.0/me/mailFolders/${mailFolderId}/messages/${messageId}/move`,
    method: "post",
    data: body
  });
  let newResponse;
  if(response.success && response.data){
    newResponse = {...response, data: {...response.data, prevMessageId: messageId}}
  }
  return newResponse;
}