import { request } from "../request/axios.request";
import { GOOGLE_SIGNIN_URL, GET_GOOGLE_AUTH_TOKEN, GET_GOOGLE_VALID_TOKEN, SAVE_AUTH_TOKEN, DELETE_GMAIL_ACCOUNT } from "../routing/route";
let
  getAllGmailMessageArr = [],
  getAllMessageAttachmentArr = [];

export async function getGoogleAuthLinkApi() {
  const response = await request({
    url: GOOGLE_SIGNIN_URL,
    method: 'post'
  });
  return response;
}
export async function getGoogleAuthTokenApi(data) {
  const response = await request({
    url: GET_GOOGLE_AUTH_TOKEN,
    method: 'post',
    data: { code: data.data }
  });
  return response;
}
export async function getGoogleValidTokenApi(data) {
  const response = await request({
    url: GET_GOOGLE_VALID_TOKEN,
    method: 'post',
    data: { refreshToken: data.data }
  });
  return response;
}
export async function getAllGmailMessagesApi(data) {
  const limit = 25;
  let { labelId, nextPageToken, search } = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/messages?labelIds=${labelId}&maxResults=${limit}${nextPageToken && `&pageToken=${nextPageToken}`}${search && `&q=${search}`}`,
    method: "get",
  });
  return response;
}
export async function getGmailMessageApi(data) {
  let getMessageId = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/messages/${getMessageId}`,
    method: "get",
  });
  if (response.success) {
    getAllGmailMessageArr.push(response.data);
    return { success: response.success, data: getAllGmailMessageArr };
  } else {
    return response;
  }
}
export async function getGmailMessageById(messageId) {
  let getMessageId = messageId;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/messages/${getMessageId}`,
    method: "get",
  });
  return response;
}
export async function getGmailMessageByIdApi(data) {
  let getMessageId = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/threads/${getMessageId}`,
    method: "get",
  });
  return response;
}
export async function deleteGmailMessageApi(data) {
  let messageId = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/messages/${messageId}`,
    method: "delete",
  });
  return response;
}

export async function modifyGmailMessageApi(data) {
  let bodyData = data.data;
  const { addLabelIds, removeLabelIds, messageId } = bodyData;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/messages/${messageId}/modify`,
    method: "post",
    data: addLabelIds.length ? { addLabelIds } : removeLabelIds.length ? { removeLabelIds } : { addLabelIds, removeLabelIds }
  });
  return response;
}

export async function composeEmailApi(data) {
  let body = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/messages/send`,
    method: "post",
    data: body.threadId.length > 0 ? { raw: body.raw, threadId: body.threadId } : { raw: body.raw }
  });
  return response;
}

export async function composeEmailWithAttachmentApi(data) {
  let body = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/upload/gmail/v1/users/me/messages/send?uploadType=media`,
    method: "post",
    headers: {
      "Host": "www.googleapis.com",
      "Content-Type": "message/rfc822",
      "Content-Length": body.size
    },
    data: { raw: body.raw }
  });
  return response;
}

export async function getGmailMessageAttachmentApi(data) {
  let { messageId, attachmentId } = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/messages/${messageId}/attachments/${attachmentId}`,
    method: "get"
  });
  if (response.success) {
    getAllMessageAttachmentArr.push(response.data);
    return { success: response.success, data: getAllMessageAttachmentArr };
  } else {
    return response;
  }
}

export function resetEmailStateApi() {
  getAllGmailMessageArr = [];
  getAllMessageAttachmentArr = [];
  return { success: true }
}

export async function getAllGmailDraftMessagesApi(data) {
  const limit = 25;
  let { nextPageToken, search } = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/drafts?maxResults=${limit}${nextPageToken && `&pageToken=${nextPageToken}`}${search && `&q=${search}`}`,
    method: "get",
  });
  return response;
}

export async function getGmailDraftMessageApi(data) {
  const draftId = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/drafts/${draftId}`,
    method: "get",
  });
  return response;
}

export async function getGmailDraftMessageByIdApi(draftId) {
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/drafts/${draftId}`,
    method: "get",
  });
  return response;
}

export async function createGmailDraftMessageApi(data) {
  let body = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/drafts`,
    method: "post",
    data: body.threadId.length > 0 ? { message: body.message, threadId: body.threadId } : { message: body.message }
  });
  return response;
}


export async function updateGmailDraftMessageApi(data) {
  let { body, draftId } = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/drafts/${draftId}`,
    method: "put",
    data: body.threadId.length > 0 ? { message: body.message, threadId: body.threadId } : { message: body.message }
  });
  return response;
}

export async function deleteGmailDraftMessageApi(data) {
  let messageId = data.data;
  const response = await request({
    url: `https://gmail.googleapis.com/gmail/v1/users/me/drafts/${messageId}`,
    method: "delete"
  });
  return response;
}
export async function saveSocialAuthTokenApi(data) {
  const authTokenData = data.data;
  const response = await request({
    url: `${SAVE_AUTH_TOKEN}?login_type=${authTokenData?.loginType}&auth_token=${authTokenData?.authToken}&refresh_token=${authTokenData?.refreshToken}&expire_in=${authTokenData?.expiresIn}&social_user_id=${authTokenData?.social_user_id}&social_email=${authTokenData?.social_email}&social_user_name=${authTokenData?.social_user_name}`,
    method: 'post',
    data: authTokenData
  });
  return response;
}

// Gmail Delete
export async function gmailAccountDeleteApi(data) {
  let gmailAccountDeleteData = data.data;
  return request({ url: DELETE_GMAIL_ACCOUNT, method: 'post', data: gmailAccountDeleteData })
}
