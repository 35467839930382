import {
    SUCCESS_LIST_INVOICE, ERROR_LIST_INVOICE,
    SUCCESS_ADD_INVOICE, ERROR_ADD_INVOICE,
    SUCCESS_GET_INVOICE_BY_ID, ERROR_GET_INVOICE_BY_ID,
    SUCCESS_UPDATE_INVOICE, ERROR_UPDATE_INVOICE,
    SUCCESS_SEND_TO_CUSTOMER_INVOICE, ERROR_SEND_TO_CUSTOMER_INVOICE,
    SUCCESS_VIEW_CUSTOMER_INVOICE, ERROR_VIEW_CUSTOMER_INVOICE,
    SUCCESS_MARK_PAID_INVOICE, ERROR_MARK_PAID_INVOICE,
    SUCCESS_INVOICE_MAKE_PAYMENT, ERROR_INVOICE_MAKE_PAYMENT,
    SUCCESS_GET_PAID_INVOICE, ERROR_GET_PAID_INVOICE,
    SUCCESS_DELETE_INVOICE, ERROR_DELETE_INVOICE,
    SUCCESS_UPDATE_BASIC_INVOICE, ERROR_UPDATE_BASIC_INVOICE,
    SUCCESS_LIST_INVOICE_TEMPLATE, ERROR_LIST_INVOICE_TEMPLATE,
    SUCCESS_ADD_INVOICE_TEMPLATE, ERROR_ADD_INVOICE_TEMPLATE,
    SUCCESS_GET_INVOICE_TEMPLATE_BY_ID, ERROR_GET_INVOICE_TEMPLATE_BY_ID,
    SUCCESS_UPDATE_MESSAGE_BODY_INVOICE, ERROR_UPDATE_MESSAGE_BODY_INVOICE,
    SUCCESS_GET_MESSAGE_BODY_INVOICE, ERROR_GET_MESSAGE_BODY_INVOICE,    
} from './invoice.action';
import { DEFAULT_STATE } from "./invoice.state";

export const invoiceReducer = (state = DEFAULT_STATE, action = {
    type: {},
    data: {}
}) => {
    switch (action.type) {
        case SUCCESS_LIST_INVOICE:
            const listInvoiceData = action.data;
            return { ...state, listInvoiceData }
        case ERROR_LIST_INVOICE:
            const errorListInvoiceData = action.data;
            return { ...state, listInvoiceData: errorListInvoiceData }
        case SUCCESS_ADD_INVOICE:
            const addInvoiceData = action.data;
            return { ...state, addInvoiceData }
        case ERROR_ADD_INVOICE:
            const errorAddInvoiceData = action.data;
            return { ...state, addInvoiceData: errorAddInvoiceData }
        case SUCCESS_GET_INVOICE_BY_ID:
            const getInvoiceByIdData = action.data;
            return { ...state, getInvoiceByIdData }
        case ERROR_GET_INVOICE_BY_ID:
            const errorGetInvoiceByIdData = action.data;
            return { ...state, getInvoiceByIdData: errorGetInvoiceByIdData }
        case SUCCESS_UPDATE_INVOICE:
            const updateInvoiceData = action.data;
            return { ...state, updateInvoiceData }
        case ERROR_UPDATE_INVOICE:
            const errorUpdateInvoiceData = action.data;
            return { ...state, updateInvoiceData: errorUpdateInvoiceData }
        case SUCCESS_SEND_TO_CUSTOMER_INVOICE:
            const sendToCustomerInvoiceData = action.data;
            return { ...state, sendToCustomerInvoiceData }
        case ERROR_SEND_TO_CUSTOMER_INVOICE:
            const errorSendToCustomerData = action.data;
            return { ...state, sendToCustomerInvoiceData: errorSendToCustomerData }
        case SUCCESS_VIEW_CUSTOMER_INVOICE:
            const viewCustomerInvoiceData = action.data;
            return { ...state, viewCustomerInvoiceData }
        case ERROR_VIEW_CUSTOMER_INVOICE:
            const errorViewCustomerInvoiceData = action.data;
            return { ...state, viewCustomerInvoiceData: errorViewCustomerInvoiceData }
        case SUCCESS_MARK_PAID_INVOICE:
            const markPaidInvoiceData = action.data;
            return { ...state, markPaidInvoiceData }
        case ERROR_MARK_PAID_INVOICE:
            const errorMarkPaidInvoiceData = action.data;
            return { ...state, markPaidInvoiceData: errorMarkPaidInvoiceData }
        case SUCCESS_INVOICE_MAKE_PAYMENT:
            const invoiceMakePaymentData = action.data;
            return { ...state, invoiceMakePaymentData }
        case ERROR_INVOICE_MAKE_PAYMENT:
            const errorInvoiceMakePaymentData = action.data;
            return { ...state, invoiceMakePaymentData: errorInvoiceMakePaymentData }
        case SUCCESS_GET_PAID_INVOICE:
            const getPaidInvoiceData = action.data;
            return { ...state, getPaidInvoiceData }
        case ERROR_GET_PAID_INVOICE:
            const errorGetPaidInvoiceData = action.data;
            return { ...state, getPaidInvoiceData: errorGetPaidInvoiceData }
        case SUCCESS_DELETE_INVOICE:
            const deleteInvoiceData = action.data;
            return { ...state, deleteInvoiceData }
        case ERROR_DELETE_INVOICE:
            const errorDeleteInvoiceData = action.data;
            return { ...state, deleteInvoiceData: errorDeleteInvoiceData }
        case SUCCESS_UPDATE_BASIC_INVOICE:
            const updateBasicInvoiceData = action.data;
            return { ...state, addInvoiceData: updateBasicInvoiceData }
        case ERROR_UPDATE_BASIC_INVOICE:
            const errorUpdateBasicInvoiceData = action.data;
            return { ...state, addInvoiceData: errorUpdateBasicInvoiceData }
        case SUCCESS_LIST_INVOICE_TEMPLATE:
            const listInvoiceTemplateData = action.data;
            return { ...state, listInvoiceTemplateData }
        case ERROR_LIST_INVOICE_TEMPLATE:
            const errorListInvoiceTemplateData = action.data;
            return { ...state, listInvoiceTemplateData: errorListInvoiceTemplateData }
        case SUCCESS_ADD_INVOICE_TEMPLATE:
            const addInvoiceTemplateData = action.data;
            return { ...state, addInvoiceTemplateData }
        case ERROR_ADD_INVOICE_TEMPLATE:
            const errorAddInvoiceTemplateData = action.data;
            return { ...state, addInvoiceTemplateData: errorAddInvoiceTemplateData }
        case SUCCESS_GET_INVOICE_TEMPLATE_BY_ID:
            const getInvoiceTemplateByIdData = action.data;
            return { ...state, getInvoiceTemplateByIdData }
        case ERROR_GET_INVOICE_TEMPLATE_BY_ID:
            const errorGetInvoiceTemplateByIdData = action.data;
            return { ...state, getInvoiceTemplateByIdData: errorGetInvoiceTemplateByIdData }
        case SUCCESS_UPDATE_MESSAGE_BODY_INVOICE:
            const messageBodyData = action.data;
            return { ...state, messageBodyData:messageBodyData }
        case ERROR_UPDATE_MESSAGE_BODY_INVOICE: //console.log('sds')
            const errorMessageBodyData = action.data;
            return { ...state, messageBodyData: errorMessageBodyData }   
        case SUCCESS_GET_MESSAGE_BODY_INVOICE:
            const getMessageBodyData = action.data;
            return { ...state, messageBodyData:getMessageBodyData }
        case ERROR_GET_MESSAGE_BODY_INVOICE: //console.log('sds')
            const errorGetMessageBodyData = action.data;
            return { ...state, messageBodyData: errorGetMessageBodyData }               
        default:
            return state;
    }
};