import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { VIEW_BOOKING_BASE } from "../../../../routing/routeContants";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import _ from 'lodash';

const CalenderGridView = (props) => {
  let { microsoftEventListData, gmailEventListData, eventListData, getModalStatusProp, getEventModalStatusProp, getMicrosoftEventModalStatusProp } = props;
  const history = useHistory();

  eventListData = [...(eventListData || []), ...(gmailEventListData || []), ...(microsoftEventListData || [])]

  const localizer = momentLocalizer(moment)

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [defaultView, setDefaultView] = React.useState('month')
  const handleResize = () => {
    if (window.innerWidth <= 360) {
      setDefaultView('day')
    } else {
      setDefaultView('month')
    }
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  React.useEffect(() => {
    console.log('defaultView->' + defaultView)

  }, [defaultView])

  return (
    <>
      <Calendar
        localizer={localizer}
        messages={{ previous: "Previous" }}
        events={eventListData && eventListData.map((listItem) => {

          if (listItem.type === 'booking' && listItem.duration) {
            listItem.end_date = ''
            let duration = listItem.duration.trim().split(" ")
            let dt = new Date(listItem.start_date);
            switch (duration[1]) {
              case 'Hours':
                dt.setHours(dt.getHours() + parseInt(duration[0]));
                listItem.end_date = dt
                break;
              case 'Hrs':
                dt.setHours(dt.getHours() + parseInt(duration[0]));
                listItem.end_date = dt
                break;
              case 'hr':
                dt.setHours(dt.getHours() + parseInt(duration[0]));
                listItem.end_date = dt
                break;
              case 'Half Day':
                dt.setHours(dt.getHours() + parseInt('6'));
                listItem.end_date = dt
                break;
              case 'Full Day':
                dt.setHours(dt.getHours() + parseInt('12'));
                listItem.end_date = dt
                break;
            }

          }
          return (
            {
              'title': listItem.name, 'start': new Date(listItem.start_date),
              'end': listItem.type == "booking" ? new Date(listItem.end_date) : new Date(listItem.end_date), 'type': listItem.type, 'data': listItem
            }
          )
        })
        }
        onSelectEvent={(e) => {
          if (e.type == "appointment") {
            getModalStatusProp(true, e.data, false, "calendar");
          } else if (e.type == "booking") {
            history.push(VIEW_BOOKING_BASE + e.data.id)
          } else if (e.type == "googleEvents") {
            getEventModalStatusProp(true, e.data, false, "calendar");
          } else if (e.type == "microsoftEvents") {
            getMicrosoftEventModalStatusProp(true, e.data, false, "calendar")
          }
        }}
        style={{ height: 500 }}

        eventPropGetter={(data) => {
          const backgroundColor = data.colorEvento ? data.colorEvento : 'green';
          const color = data.color ? data.color : 'white';
          if (data.data.key == 'googleEvent') {
            return { style: { backgroundColor, color } }
          }
          else if (data.data.key == 'microsoftEvent') {
            return { style: { backgroundColor: '#0a365c', color: 'white' } }
          }
        }}

      />

    </>
  )
}
export const CalenderView = withRouter(CalenderGridView)
